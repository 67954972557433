import React, { Component } from 'react';
import { ajaxPost } from '../../ajax.js';
import { Message, Card, Icon, Header, Divider, Button, List, Modal, Label, Loader } from 'semantic-ui-react';
import OvertimeList from '../../TA/Transactions/OvertimeList';
import OBList from '../../TA/Transactions/OfficialBusinessList';
import DTRCorrectionList from '../../TA/Transactions/DTRCorrectionList';
import LeaveList from '../../TA/Transactions/LeaveList';
import CompensatoryTimeOffList from '../../TA/Transactions/CompensatoryTimeOffList';
import ChangeScheduleList from '../../TA/Transactions/ChangeScheduleList';
import ChangeOfRestdayList from '../../TA/Transactions/ChangeOfRestdayList';
import OfficialBusinessList from '../../TA/Transactions/OfficialBusinessList';

var { isTaskAvailable } = require('../../utils');

class PendingCountTrail extends Component {
    state = {
        dashboardItems: [],
        loading: false
    }

    styles =
        {
            cards:
            {
                margin: '20px'
            },
            browsePending: {
                textDecoration: 'underline black',
                textAlign: 'center'
            }
        }

    componentDidMount() {
        this.onLoadPendingTransactions();
    }

    onLoadPendingTransactions = () => {
        ajaxPost({
            url: 'api/PendingCount/GetTransactionPendingCounts',
            data: {
                UserId: global.UserId,
                DisplayCount: 4
            },
            onSuccess: data => {
                var items = this.state.dashboardItems;
                data.map(x => {
                    const transacName = x["TransacName"];
                    var module = null;
                    if (transacName == "Overtime") {
                        module = OvertimeList;
                    } else if (transacName == "Official Business") {
                        module = OfficialBusinessList;
                    } else if (transacName == "DTR Correction") {
                        module = DTRCorrectionList;
                    } else if (transacName == "Leaves") {
                        module = LeaveList;
                    } else if (transacName == "Offset") {
                        module = CompensatoryTimeOffList;
                    } else if (transacName == "Change of Schedule") {
                        module = ChangeScheduleList;
                    } else if (transacName == "Change of Restday") {
                        module = ChangeOfRestdayList;
                    }

                    items.push({ Header: transacName, Color: "black", Count: x["Count"], Module: module, StartDate: x["StartDate"], EndDate: x["EndDate"] });
                });

                this.setState({ dashboardItems: items });
                this.setState({ loading: false });
            },
            finally: () => { }
        });
    }

    render() {
        return (
            <div>
                <Header>
                    <Icon name='calendar check outline' disabled />
                    <Header.Content>
                        Pending Approval/s
                    </Header.Content>
                </Header>
                <Divider />
                <Card.Group itemsPerRow={7} stackable style={this.styles.cards}>
                    {
                        this.state.dashboardItems.map(item => {
                            return (
                                <Card color={item.Color}
                                    key={item.Header}
                                >
                                    <Message attached color={item.Color}>
                                        <Message.Header>
                                            <p style={{ textAlign: 'center', fontSize: '15px' }}>{item.Header}</p>
                                        </Message.Header>
                                    </Message>
                                    <Card.Content>
                                        <Card.Header textAlign='center'>
                                            <Header size='large' textAlign='center'>
                                                {/* <Icon name='users' /> */}
                                            </Header>
                                        </Card.Header>
                                        <Card.Description>
                                            <Header size='huge' textAlign='center'>{item.Count}</Header>
                                        </Card.Description>
                                        <Card.Content extra>
                                            <Header textAlign='center' size='tiny'>
                                                <a style={{ textAlign: 'center' }} onClick={this.props.onModuleSelect.bind(this, item.Module, this, this, false, false, item.StartDate, item.EndDate, true)}>Pending Request/s</a>
                                            </Header>
                                        </Card.Content>
                                    </Card.Content>
                                </Card>
                            )
                        })
                    }
                </Card.Group>
            </div>
        )
    }
}

PendingCountTrail.getViewID = () => { return "PendingCountModule" };
PendingCountTrail.getCaption = () => { return "Pending Approval/s" };
PendingCountTrail.getViewAccessKey = () => { return isTaskAvailable("TRANSACTION_PENDING_VIEW") };

export default PendingCountTrail;
import React, { Component } from 'react';
import moment from 'moment';
import { Dropdown, Modal, Button, Input, Form, TextArea, Header } from 'semantic-ui-react';
import { ajaxPost } from '../../../ajax';
//import Select from 'react-select';
import SelectEmployees from '../../../Commons/SelectEmployees';
import ErrorMessage from '../../../Commons/ErrorMessage';
import MessageBoxYesNo from '../../../Commons/MessageBoxYesNo';
import LeaveTypeForm from '../../Leaves/LeaveTypeForm';
import { isTaskAvailable, checkDate, handleNumeric, checkYear, minDate, maxDate, checkInt32, handleNumericOnKeyDown, handleNumericOnKeyPress } from '../../../utils';

const leaveGrantTypes = [
    { key: "Grants", text: "Grants", value: "Grants" },
    { key: "Deductions", text: "Deductions", value: "Deductions" }
];

var delayTimer = null;
const someMarginNoPadding = { "margin": "0px 0px 10px 0px", "padding": "0px" }
const noMarginNoPadding = { "margin": "0px", "padding": "0px" }
const semanticErrorStyle = { 'background-color': '#FFF6F6', 'border-color': '#E0B4B4', 'color': '#9F3A38', '-webkit-box-shadow': 'none', 'box-shadow': 'none' }

class ChangeScheduleForm extends Component {

    constructor(props) {
        super(props);
        this.state = {
            _id: null,
            Date: moment().format("YYYY-MM-DD"),
            LeaveTypeId: "",
            LeaveTypeCode: "",
            LeaveGrantType: "",
            EffectivityYear: Number(moment().format("YYYY")),
            //Count: "Whole Day",
            Count: 1.00,
            Remarks: "",
            RequesterId: "",
            RequestDate: moment().format("YYYY-MM-DD"),

            employees: [],
            selectedEmployees: [],
            employeeSelectionEnabled: true,
            displayedName: "",
            leaveTypeData: [],
            isLoading: false,
            showLeaveTypeForm: false
        }
        this.modalRef = React.createRef();
    }

    getContent = () => {
        return {
            _id: this.state._id,
            Date: moment(this.state.Date).toJSON(),
            LeaveTypeId: this.state.LeaveTypeId,
            LeaveTypeCode: this.state.LeaveTypeCode,
            LeaveGrantType: this.state.LeaveGrantType,
            EffectivityYear: Number(this.state.EffectivityYear),
            RequesterId: this.state.RequesterId,
            RequestDate: moment().toJSON(),
            // Count: this.state.Count === "Half Day" ? 0.5 : 1,
            Count: this.state.Count,
            Remarks: this.state.Remarks,
            //employeeIds: this.state.selectedEmployees.map(employee => { return employee.key }),
            employeeIds: this.state.selectedEmployees,
        };
    }

    setContent = (data) => {
        if (data.LeaveTypeId) {
            this.onLeaveTypeKeyPress(this, { searchQuery: data.LeaveTypeCode });
        }

        let selectedEmployees = [];
        if (data.EmployeeId) {
            selectedEmployees.push(data.EmployeeId);
        }
        else if (data.userId && !isTaskAvailable("VIEW_ALL_EMPLOYEES")) {
            selectedEmployees.push(data.userId);
        }
        // if(data.userId && !data.hasSubordinates) {
        //     selectedEmployees.push(data.userId);

        this.setState({
            _id: data._id,
            Date: moment(data.Date).format("YYYY-MM-DD"),
            LeaveTypeId: data.LeaveTypeId,
            LeaveTypeCode: data.LeaveTypeCode,
            LeaveGrantType: data.LeaveGrantType,
            EffectivityYear: data.EffectivityYear,
            //Count: data.Count !== 1 ? "Half Day" : "Whole Day",
            Count: data.Count,
            Remarks: data.Remarks,
            RequesterId: data.userId,
            employees: [],
            selectedEmployees: selectedEmployees,
            employeeSelectionEnabled: !data._id && (isTaskAvailable("VIEW_ALL_EMPLOYEES") || data.hasSubordinates),
            displayedName: !data._id && !data.hasSubordinates ? data.name : data.Employee
        });
    }

    onEmployeeSearchChange = (event, data) => {
        var self = this;
        clearTimeout(delayTimer);
        delayTimer = setTimeout(() => {
            var employeeSearchParameter = {
                data: { needle: data.searchQuery === undefined ? "" : data.searchQuery, itemCount: 20 },
                url: "api/Overtime/searchEmployees",
                onSuccess: (data, sender) => {
                    var newOptions = data.map((model, index, array) => { return { key: model._id, text: model.LastName + ", " + model.FirstName + " " + model.MiddleName, value: model._id } });
                    self.setState({ employees: newOptions });
                },
                onError: (error) => {

                },
                finally: () => { }
            };
            ajaxPost(employeeSearchParameter);
        }, 200);
    };

    onLeaveTypeChange = (event, data) => {
        this.setState({
            LeaveTypeId: data.value,
            LeaveTypeCode: this.state.leaveTypeData.find(item => item.value === data.value).code
        });
    }

    onLeaveTypeKeyPress = (event, data) => {
        this.setState({ isLoading: true });
        var self = this;
        clearTimeout(delayTimer);
        delayTimer = setTimeout(() => {
            self.loadLeaveTypes(data);
        }, 200);
    };


    onCreateNewItemClick = () => {
        this.setState({ showAskCloseDialog: true });
    }

    saveFromModal = (model) => {
        let self = this;
        let isValid = false;
        isValid = this.validate(model);
        if (!isValid) {
            return;
        }

        var parameter = {
            data: { model: JSON.stringify(model) },
            url: "api/LeaveType/save",
            onSuccess: (data, sender) => {
                if (data.hasOwnProperty("errors") && data.errors.length > 0) {
                    isValid = false;
                    this.setState({
                        showRequiredField: true,
                        errTitle: "Invalid",
                        errCaption: data.errors.map(err => { return (<p>{err}</p>) })
                    });
                    return;
                }
                if (isValid) {
                    this.setState({ showLeaveTypeForm: false });
                    this.setState({ LeaveTypeId: data.content["_id"] });
                    this.onLeaveTypeKeyPress(null);
                }
            },
            onError: (error) => {
                isValid = false;
                var errMessage = "An error has occured";
                if (error) {
                    errMessage += "\r\n" + JSON.stringify(error);
                }
                this.messageBoxOkOnlyOpen(true, "Error", errMessage);
            },
            finally: () => {
                // close modal 
            }
        }
        ajaxPost(parameter);
    }



    validate = (data) => {
        var errTitle = "", errCaption = "";
        if (this.isNullOrEmpty(data.Code)
            || this.isNullOrEmpty(data.Name)
            // || this.isNullOrEmpty(data.Description)  // requested to remove description as required, Bug 8935: Leave Type Required Fields
        ) {
            errTitle = "Invalid"
            errCaption = "Fill up all required fields."
        }
        else {
            this.setState({ showRequiredField: false });
            return true;
        }
        this.setState({ showRequiredField: true, errTitle, errCaption });
        return false;
    };

    onCreateTypeClick = () => {
        this.setState({ showLeaveTypeForm: !this.state.showLeaveTypeForm })
    }

    isNullOrEmpty(val) {
        if (val === null || val === undefined || val === "" || val.length === 0) return true;
        else return false;
    }

    loadLeaveTypes = (data) => {
        var self = this;

        var parameter = {
            search: data !== undefined && data !== null ? data.searchQuery : "",
            includeDeleted: false,
            startIndex: 0,
            itemCount: 15
        };
        var searchParameter = {
            data: parameter,
            url: "api/LeaveType/search",
            onError: (error) => {
                //self.setState({ leaveTypeData: [] });
            },
            onSuccess: (data, sender) => {
                if (data.content.length === 0) {
                    self.setState({ LeaveTypeId: "" });
                    return;
                }
                var newOptions = data.content.map((item) => {
                    return {
                        key: item._id,
                        text: item.Name + " (" + item.Code + ")",
                        value: item._id,
                        code: item.Code,
                    }
                });
                self.setState({ leaveTypeData: newOptions });
            },
            finally: () => {
                self.setState({ isLoading: false });
            }

        }
        ajaxPost(searchParameter);
    }

    render() {
        let self = this;
        return (
            <Modal size='tiny' open={this.props.open}>
                <Modal.Header>
                    Leave Adjustment
                </Modal.Header>
                <Modal.Content>
                    {
                        this.props.showRequiredField &&
                        <div className="ui one column grid" style={noMarginNoPadding}>
                            <div className="column" style={someMarginNoPadding}>
                                <ErrorMessage
                                    title={this.props.errTitle}
                                    caption={this.props.errCaption}
                                    open={this.props.showRequiredField}
                                />
                            </div>
                        </div>
                    }
                    {
                        !this.state.employeeSelectionEnabled &&
                        <div className="ui one column grid" style={noMarginNoPadding}>
                            <div className="column" style={someMarginNoPadding}>
                                <div>Employee</div>
                                <Header size='small' style={{ "margin": "0" }}>{this.state.displayedName}</Header>
                                {/* <Input value={this.state.displayedName} disabled={true} /> */}
                            </div>
                        </div>
                    }
                    {
                        this.state.employeeSelectionEnabled &&
                        <div className="ui one column grid" style={noMarginNoPadding}>
                            <div className="column" style={someMarginNoPadding}>
                                <div>Employee</div>
                                <SelectEmployees
                                    Ids={this.state.selectedEmployees}
                                    update={(Ids) => this.setState({ selectedEmployees: Ids })}
                                    width="100%"
                                    disabled={this.props.isBusy}
                                />
                                {
                                    isTaskAvailable("EMPLOYEE_ADD") &&
                                    <a as='a' href='#' onClick={this.onCreateNewItemClick.bind(this)}>Create New</a>
                                }
                            </div>
                        </div>
                    }
                    <div className="ui one column grid" style={noMarginNoPadding}>
                        <div className="column" style={someMarginNoPadding}>
                            <div>Date</div>
                            <Input
                                fluid
                                type="date"
                                required={true}
                                min={minDate}
                                max={maxDate}
                                value={this.state.Date}
                                disabled={this.props.isBusy}
                                error={!checkDate(this.state.Date).Result}
                                onChange={(event, data) => { self.setState({ Date: data.value }); }} />
                        </div>
                    </div>
                    <div className="ui one column grid" style={noMarginNoPadding}>
                        <div className="column" style={someMarginNoPadding}>
                            <div>Leave Type</div>
                            <Dropdown
                                fluid
                                search
                                selection
                                value={this.state.LeaveTypeId}
                                disabled={this.props.isBusy}
                                options={this.state.leaveTypeData}
                                loading={this.state.isLoading}
                                error={this.props.showRequiredField && !this.state.LeaveTypeId}
                                onClick={this.onLeaveTypeKeyPress}
                                onSearchChange={this.onLeaveTypeKeyPress}
                                onChange={this.onLeaveTypeChange}
                            />
                            {
                                !this.state.showLeaveTypeForm && isTaskAvailable("LEAVE_TYPE_CREATE") &&
                                <a as='a' href='#' onClick={this.onCreateTypeClick.bind(this)}>Create New</a>
                            }
                            {
                                <LeaveTypeForm
                                    ref={this.modalRef}
                                    open={this.state.showLeaveTypeForm}
                                    errTitle={"Error"}
                                    errCaption={this.state.errCaption}
                                    showRequiredField={this.state.showRequiredField}
                                    onCancel={() => { this.setState({ showLeaveTypeForm: false }) }}
                                    onSave={() => {
                                        self.saveFromModal(
                                            self.modalRef.current.getContent()
                                        );
                                    }}
                                >
                                </LeaveTypeForm>
                            }
                        </div>
                    </div>
                    <div className="ui three column grid" style={noMarginNoPadding}>
                        <div className="column" style={{ "margin": "0px 0px 10px 0px", "padding": "0px 5px 0px 0px" }}>
                            <div>Leave Grant Type</div>
                            <Dropdown
                                fluid
                                selection
                                value={this.state.LeaveGrantType}
                                disabled={this.props.isBusy}
                                error={!this.state.LeaveGrantType}
                                options={leaveGrantTypes}
                                onChange={(event, data) => { this.setState({ LeaveGrantType: data.value }); }}
                            />
                        </div>
                        <div className="column" style={{ "margin": "0px 0px 10px 0px", "padding": "0px 5px 0px 5px" }}>
                            <div>Day Count</div>
                            <Input
                                fluid
                                value={this.state.Count}
                                disabled={this.props.isBusy}
                                error={this.state.Count === 0 || !checkInt32(this.state.Count, false).Result}
                                onChange={(event) => { self.setState({ Count: handleNumeric(event) }); }}
                                onKeyPress={(e) => { handleNumericOnKeyPress(e) }}
                                onKeyDown={(e) => { handleNumericOnKeyDown(e) }}
                            />
                            {/* <Dropdown selection fluid disabled={this.props.isBusy} options={[{key: "Whole Day", value: "Whole Day", text: "Whole Day"}, {key: "Half Day", value: "Half Day", text: "Half Day"}]} value={this.state.Count} onChange={
                                (event, data) => { this.setState({ Count: data.value }); } }
                            /> */}
                        </div>
                        <div className="column" style={{ "margin": "0px 0px 10px 0px", "padding": "0px 0px 0px 5px" }}>
                            <div>Effectivity Year</div>
                            <Input
                                fluid
                                value={this.state.EffectivityYear}
                                disabled={this.props.isBusy}
                                error={!checkYear(this.state.EffectivityYear).Result}
                                //onChange={(event, data) => { this.setState({ EffectivityYear: data.value }); } } 
                                onChange={(event) => { self.setState({ EffectivityYear: handleNumeric(event, true) }); }}
                                onKeyPress={(e) => { handleNumericOnKeyPress(e) }}
                                onKeyDown={(e) => { handleNumericOnKeyDown(e) }}
                            />
                        </div>
                    </div>
                    <div className="ui one column grid" style={noMarginNoPadding}>
                        <div className="column" style={someMarginNoPadding}>
                            <div>Reasons</div>
                            <Form>
                                <TextArea
                                    fluid
                                    value={this.state.Remarks}
                                    disabled={this.props.isBusy}
                                    style={this.props.showRequiredField && !this.state.Remarks ? semanticErrorStyle : []}
                                    onChange={(event, data) => { this.setState({ Remarks: data.value }); }}
                                />
                            </Form>
                        </div>
                    </div>
                </Modal.Content>
                <Modal.Actions>
                    <Button basic icon='save' content="Submit" disabled={this.props.isBusy} onClick={this.props.onSave}></Button>
                    <Button basic icon='cancel' content="Cancel" disabled={this.props.isBusy} onClick={this.props.onCancel}></Button>
                </Modal.Actions>
                {
                    this.state.showAskCloseDialog &&
                    <MessageBoxYesNo title="Create New Employee"
                        action="Yes"
                        onClose={() => { this.setState({ showAskCloseDialog: false }) }}
                        onAction={() => { this.props.onCreateEmpClick() }}
                        caption="All unsaved changes will be lost. Do you wish to continue?"
                    />
                }
            </Modal>
        );
    }

}

export default ChangeScheduleForm;
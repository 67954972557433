import PdfMake from 'pdfmake/build/pdfmake';
import vfsFonts from 'pdfmake/build/vfs_fonts';
import moment from 'moment';

function toAddress(data) {
    var add = "";
    add += data.Address != "" && data.Address + ", ";
    add += data.City != "" && data.City + ", ";
    add += data.Province != "" && data.Province + ", ";
    add += data.Country != "" && data.Country + ", ";
    add += data.Zip_Code != "" && data.Zip_Code;
    return add;
}

export default (data) => {
    const { vfs } = vfsFonts.pdfMake;
    PdfMake.vfs = vfs;

    var addresses = data.Eligibilities.content.filter(x => x.Type === "ADDRESS");
    var adrss = "";
    if (addresses.length == 1) {
        if (addresses[0].Details.Type != "BIRTHPLACE")
            adrss = toAddress(addresses[0].Details);
    }

    if (addresses.length > 1) {
        var permanent = addresses.filter(x => x.Details.Type == "PERMANENT_ADDRESS");
        var current = addresses.filter(x => x.Details.Type == "CURRENT_ADDRESS");
        var relocated = addresses.filter(x => x.Details.Type == "RELOCATED_ADDRESS");

        if (permanent.length > 0) {
            adrss = toAddress(permanent[0].Details);
        }
        else {
            if (current.length > 0) {
                adrss = toAddress(current[0].Details);
            } else {
                if (relocated.length > 0)
                    adrss = toAddress(relocated[0].Details);
            }
        }
    }



    const pdfDoc = {
        pageSize: 'letter',
        pageOrientation: 'portrait',
        margin: [25, 25, 25, 25],
        content: [
            {
                columns: [
                    {
                        image: 'data:' + data.Employee.ImageURI,
                        width: 100,
                        height: 100
                    },
                    {
                        width: "*",
                        layout: 'noBorders',
                        table: {
                            headerRows: 1,
                            widths: [100, '*'],
                            body: [
                                [
                                    {
                                        text: data.Employee.FullName.trim(),
                                        fontSize: 25,
                                        bold: true,
                                        colSpan: 2
                                    },
                                    {
                                        text: ''
                                    }
                                ],
                                [
                                    {
                                        text: 'Address',
                                        fontSize: 11,
                                    },
                                    {
                                        text: ": " + adrss,
                                        fontSize: 11,
                                    },
                                ],
                                [
                                    {
                                        text: 'Contact Number',
                                        fontSize: 11,
                                    },
                                    {
                                        text: ": " + (data.Employee.TelephoneNo == "" ? data.Employee.TelephoneNo : data.Employee.MobileNo),
                                        fontSize: 11,
                                    },
                                ],
                                [
                                    {
                                        text: 'Email',
                                        fontSize: 11,
                                    },
                                    {
                                        text: ": " + data.Employee.Email,
                                        fontSize: 11,
                                    },
                                ],
                            ]
                        }
                    }
                ],
                columnGap: 10,
            },
            //line break
            {
                layout: 'headerLineOnly',
                table: {
                    headerRows: 1,
                    widths: ["*"],
                    body: [
                        [''],
                        ['']
                    ]
                },
            },
            //Personal info
            {
                width: "*",
                layout: 'noBorders',
                table: {
                    headerRows: 1,
                    widths: [10, 120, '*'],
                    body: [
                        [
                            {
                                text: "PERSONAL INFORMATION",
                                fontSize: 15,
                                bold: true,
                                colSpan: 3
                            },
                            {
                                text: ''
                            },
                            {
                                text: ''
                            }
                        ],
                        [
                            '',
                            {
                                text: 'Nickname',
                                fontSize: 11,
                            },
                            {
                                text: ": " + data.Employee.Nickname,
                                fontSize: 11,
                            },
                        ],
                        [
                            '',
                            {
                                text: 'Gender',
                                fontSize: 11,
                            },
                            {
                                text: ": " + data.Employee.Gender,
                                fontSize: 11,
                            },
                        ],
                        [
                            '',
                            {
                                text: 'Civil Status',
                                fontSize: 11,
                            },
                            {
                                text: ": " + data.Employee.CivilStatus,
                                fontSize: 11,
                            },
                        ],
                        [
                            '',
                            {
                                text: 'Height (cm)',
                                fontSize: 11,
                            },
                            {
                                text: ": " + (data.Employee.Height == 0 ? "" : data.Employee.Height),
                                fontSize: 11,
                            },
                        ],
                        [
                            '',
                            {
                                text: 'Weight (kg)',
                                fontSize: 11,
                            },
                            {
                                text: ": " + (data.Employee.Weight == 0 ? "" : data.Employee.Weight),
                                fontSize: 11,
                            },
                        ],
                        [
                            '',
                            {
                                text: 'Religion',
                                fontSize: 11,
                            },
                            {
                                text: ": " + data.Employee.Religion,
                                fontSize: 11,
                            },
                        ],
                        [
                            '',
                            {
                                text: 'Nationality',
                                fontSize: 11,
                            },
                            {
                                text: ": " + data.Employee.Nationality,
                                fontSize: 11,
                            },
                        ],
                        [
                            '',
                            {
                                text: 'Blood Type',
                                fontSize: 11,
                            },
                            {
                                text: ": " + data.Employee.Nickname,
                                fontSize: 11,
                            },
                        ],
                        [
                            '',
                            {
                                text: 'BirthDate',
                                fontSize: 11,
                            },
                            {
                                text: ": " + (data.Employee.BirthDate === null ? "" : (moment(data.Employee.BirthDate)).format("MMMM DD, YYYY")),
                                fontSize: 11,
                            },
                        ]
                    ]
                }
            },
            //line break
            {
                layout: 'headerLineOnly',
                table: {
                    headerRows: 1,
                    widths: ["*"],
                    body: [
                        [''],
                        ['']
                    ]
                },
            },
            //HR Info
            {
                width: "*",
                layout: 'noBorders',
                table: {
                    headerRows: 1,
                    widths: [10, 120, '*'],
                    body: [
                        [
                            {
                                text: "HR INFORMATION",
                                fontSize: 15,
                                bold: true,
                                colSpan: 3
                            },
                            {
                                text: ''
                            },
                            {
                                text: ''
                            }
                        ],
                        [
                            '',
                            {
                                text: 'Employment Status',
                                fontSize: 11,
                            },
                            {
                                text: ": " + data.Employee.EmploymentStatus,
                                fontSize: 11,
                            },
                        ],
                        [
                            '',
                            {
                                text: 'Date Hired',
                                fontSize: 11,
                            },
                            {
                                text: ": " + (data.Employee.DateHired == null ? "" : moment(data.Employee.DateHired).format("MMMM DD, YYYY")),
                                fontSize: 11,
                            },
                        ],
                        [
                            '',
                            {
                                text: 'Regularization Date',
                                fontSize: 11,
                            },
                            {
                                text: ": " + (data.Employee.RegularizationDate == null ? "" : moment(data.Employee.RegularizationDate).format("MMMM DD, YYYY")),
                                fontSize: 11,
                            },
                        ],
                        [
                            '',
                            {
                                text: 'Resignation Date',
                                fontSize: 11,
                            },
                            {
                                text: ": " + (data.Employee.ResignationDate == null ? "" : moment(data.Employee.ResignationDate).format("MMMM DD, YYYY")),
                                fontSize: 11,
                            },
                        ]
                    ]
                },

            },
            //line break
            {
                layout: 'headerLineOnly',
                table: {
                    headerRows: 1,
                    widths: ["*"],
                    body: [
                        [''],
                        ['']
                    ]
                },
            },
            //Groups and Position Info
            {
                width: "*",
                layout: 'noBorders',
                table: {
                    headerRows: 1,
                    widths: [10, 120, '*'],
                    body: [
                        [
                            {
                                text: "POSITION INFORMATION",
                                fontSize: 15,
                                bold: true,
                                colSpan: 3
                            },
                            {
                                text: ''
                            },
                            {
                                text: ''
                            }
                        ],
                        [
                            '',
                            {
                                text: 'Position',
                                fontSize: 11,
                            },
                            {
                                text: ": " + (data.Position.content.length > 0 ? data.Position.content[0].PositionProfile.Name:""),
                                fontSize: 11,
                            },
                        ],
                        [
                            '',
                            {
                                text: 'Division',
                                fontSize: 11,
                            },
                            {
                                text: ": " + (data.Position.content.length > 0 ? data.Position.content[0].PositionProfile.Division!=null? data.Position.content[0].PositionProfile.Division.Name: "" : ""),
                                fontSize: 11,
                            },
                        ],
                        [
                            '',
                            {
                                text: 'Department',
                                fontSize: 11,
                            },
                            {
                                text: ": " + (data.Position.content.length > 0 ? data.Position.content[0].PositionProfile.Department!=null? data.Position.content[0].PositionProfile.Department.Name: "" : ""),
                                fontSize: 11,
                            },
                        ],
                        
                        [
                            '',
                            {
                                text: 'Cost Center',
                                fontSize: 11,
                            },
                            {
                                text: ": " + (data.Position.content.length > 0 ? data.Position.content[0].PositionProfile.CostCenter!=null? data.Position.content[0].PositionProfile.CostCenter.Name: "" : ""),
                                fontSize: 11,
                            },
                        ],
                        // [
                        //     '',
                        //     {
                        //         text: 'Job Level',
                        //         fontSize: 11,
                        //     },
                        //     {
                        //         text: ": " + (data.Position.content.length > 0 ? data.Position.content[0].PositionProfile.JobLevel!=null? data.Position.content[0].PositionProfile.JobLevel.Name: "" : ""),
                        //         fontSize: 11,
                        //     },
                        // ],
                    ]
                },

            }

        ]
    };
    PdfMake.createPdf(pdfDoc).open();
}
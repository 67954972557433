import React, { Component } from 'react';
import 'semantic-ui-css/semantic.min.css';
import { Modal, Button, Checkbox } from '../../../../node_modules/semantic-ui-react';
import Select from 'react-select';
import { ajaxPost } from '../../../ajax';
import { empty_id } from '../../../utils';

const moment = require('moment');

function posList(list){
    var positions = [];
    //var unique = list.map(x=>x.Name).filter(onlyUnique); 
    if(list !=null){
        list.map(x =>{
           return(
            positions.push({label: x.Name, value: x._id})
           )
       })
    }
    return positions;
}

function onlyUnique(value, index, self) { 
    return self.indexOf(value) === index;
}
class EmployeeReportModalGroupByPosition extends Component {

    constructor(props) {
        super(props);
        this.state = {
            includeDeleted:false,
            selectedPosition:null,
            listPosition:[],
            includeInactive:false,
        }
    }
    getParameters = () => {
        var selectedPosition = this.state.selectedPosition !== null? this.state.selectedPosition.value: "";
        var pos = this.state.listPosition.find(x=> x._id==selectedPosition);
        return {
            message: "from employee report group by position modal",
            PositionID: selectedPosition,
            includeDeleted:this.state.includeInactive,
            startingIndex:0,
            itemCount:20,
            FilterDetails: "Position: " + (selectedPosition === ""?"ALL":pos===undefined ? "ALL":pos["Name"])
        };
    }
    componentDidMount(){
        this.loadPositions();
    }


    loadPositions = () => {
        ajaxPost({
            url: "api/Group/search",
            data: {
                "Search": "",
                "Types":["POSITION"],
                "includeDeleted": false,
                "startingIndex": 0,
                "itemCount": 1000
            },
            onSuccess: data => {
                this.setState({
                    listPosition: data.content,
                });
            },
            finally: () => { }
        })
    }

    handleComboChange = (value) => {
        this.setState({ selectedPosition : value})
    }
    onChkCheckChange = (e) => {
        this.setState({ includeInactive: !this.state.includeInactive })
    }
    render() {
        return (
            <Modal open={this.props.open} size="tiny">
                <Modal.Header>
                    <p>Employee Report Group By Position Filters</p>
                </Modal.Header>
                <Modal.Content>
                    <div class="ui grid">
                        <div class="left floated left aligned sixteen wide column">
                        <label>Position</label>
                            <Select placeholder='Position' name="PositionList"
                                onChange={this.handleComboChange.bind(this)}  
                                options={posList(this.state.listPosition)}                     
                                value={this.state.selectedPosition}
                                isClearable/>
                            <Checkbox size='mini' 
                                label="Include Resigned" 
                                onChange={this.onChkCheckChange.bind(this)} 
                                checked={this.state.includeInactive} />
                        </div>
                    </div>
                </Modal.Content>
                <Modal.Actions>
                    <Button basic content="Generate" onClick={this.props.onSave}></Button>
                    <Button basic content="Cancel" onClick={this.props.onCancel}></Button>
                </Modal.Actions>
            </Modal>
        );
    }

}

export default EmployeeReportModalGroupByPosition;
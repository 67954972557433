import React, { Component } from 'react';
import { Button, Input, Image, Divider, Container, Header, Checkbox, Grid, Popup, Icon } from 'semantic-ui-react';
import 'semantic-ui-css/semantic.min.css';
import { ajaxPost } from '../ajax';
import MessageBoxOkOnly from './MessageBoxOkOnly'
import { empty_id } from '../utils';

const { isTaskAvailable } = require('../utils');


const BlankItem = {
    DatabaseServer: "",
    DatabaseName: "",
    Port: 0,
    SMTPServer: "",
    SMTPPort: "",
    UseSSL: false,
    Username: "",
    Password: "",
    MasterEmail: "",
    ApplicationUrl: ""
}


class EmailSettings extends Component {
    state = {
        loading: false,
        isValid: true,
        isSuccess: false,
        emailSettings: {
            DatabaseServer: "",
            Port: "",
            DatabaseName: "",
            SMTPServer: "",
            SMTPPort: "",
            UseSSL: "",
            Username: "",
            Password: "",
            MasterMail: "",
            ApplicationUrl: ""
        },
        showPassword: false,
    }
    componentDidMount() {
        ajaxPost({
            url: 'api/EmailSetting/loadSetting',
            onSuccess: data => {
                if (data != null) {
                    this.setState({ emailSettings: data });
                }
                else {
                    this.setState({ emailSettings: JSON.parse(JSON.stringify(BlankItem)) });
                }
            },
            finally: () => { }
        });
    }

    componentWillUnmount = () => {
        this.props.unmountCallback(this.state, "EmailModule");
    }

    handleChange = name => event => {
        this.setState({ isValid: event.target.value != null });
        var current = this.state.emailSettings;
        current[name] = event.target.value;
        this.setState({ emailSettings: current });
    }

    handleCheckChange = (e, { checked }) => {
        var current = this.state.emailSettings;
        current["UseSSL"] = checked;
        this.setState({ emailSettings: current });
    }

    onSave() {

        ajaxPost({
            url: 'api/EmailSetting/save',
            data: this.state.emailSettings,
            onSuccess: data => {
                this.setState({ emailSettings: data.content });
                this.setState({ isSuccess: true });
            },
            finally: () => { }
        });

    }

    render() {
        return (
            <div>
                <Container>
                    <div style={{ color: "#606060", marginTop: '1%' }}>
                        <h2>Emailer Settings</h2>
                    </div>
                    <br />
                    <Button basic content="Save" size="mini" icon="save" onClick={this.onSave.bind(this)} />
                </Container>
                {/* <Divider /> */}
                {/* <Container>
                    <Header>MongoDB Connection</Header>
                    <Grid style={{ width: '400px' }}>
                        <Grid.Row>
                            <Grid.Column>
                                <Input fluid placeholder='Database Server'
                                    value={this.state.emailSettings.DatabaseServer}
                                    onChange={this.handleChange("DatabaseServer").bind(this)} />
                            </Grid.Column>
                        </Grid.Row>
                        <Grid.Row>
                            <Grid.Column>
                                <Input fluid placeholder='Database Port'
                                    value={this.state.emailSettings.DatabasePort}
                                    onChange={this.handleChange("DatabasePort").bind(this)} />
                            </Grid.Column>
                        </Grid.Row>
                        <Grid.Row>
                            <Grid.Column>
                                <Input fluid placeholder='Database Name'
                                    value={this.state.emailSettings.DatabaseName}
                                    onChange={this.handleChange("DatabaseName").bind(this)} />
                            </Grid.Column>
                        </Grid.Row>
                    </Grid>
                </Container> */}
                <Divider />
                <Container>
                    {/* <Header>SMTP Configuration</Header> */}
                    <Grid style={{ width: '400px' }}>
                        <Grid.Row>
                            <Grid.Column>
                                <Input fluid placeholder='SMTP Server'
                                    value={this.state.emailSettings.SMTPServer}
                                    onChange={this.handleChange("SMTPServer").bind(this)} />
                            </Grid.Column>
                        </Grid.Row>
                        <Grid.Row>
                            <Grid.Column>
                                <Input fluid placeholder='SMTP Port'
                                    value={this.state.emailSettings.SMTPPort}
                                    onChange={this.handleChange("SMTPPort").bind(this)} />
                            </Grid.Column>
                        </Grid.Row>
                        <Grid.Row>
                            <Grid.Column>
                                <Input fluid placeholder='User Name'
                                    value={this.state.emailSettings.Username}
                                    onChange={this.handleChange("Username").bind(this)} />
                            </Grid.Column>
                        </Grid.Row>
                        <Grid.Row>
                            <Grid.Column>
                                <Input 
                                    type={this.state.showPassword ? 'text' : 'password'} 
                                    fluid 
                                    placeholder='Password'
                                    value={this.state.emailSettings.Password}
                                    onChange={this.handleChange("Password").bind(this)} 
                                    action
                                    icon={
                                        <Icon
                                            name={'eye'}
                                            onMouseDown={()=> this.setState({showPassword: true}) }
                                            onMouseUp={()=> this.setState({showPassword: false}) }
                                            link/>
                                    }
                                >
                                    {/* <Popup 
                                        trigger={
                                            <Button 
                                                icon={this.state.showPassword ? 'eye slash' : 'eye'}
                                                onClick={
                                                    (evt, data) => {
                                                        this.setState({ showPassword: !this.state.showPassword })
                                                    }
                                                }
                                            />
                                        }
                                        content={(this.state.showPassword ? 'Hide' : 'Show') + ' password'}
                                    /> */}
                                </Input>
                            </Grid.Column>
                        </Grid.Row>
                        {/* <Grid.Row>
                            <Grid.Column>
                                <Input fluid placeholder='Master Email'
                                    value={this.state.emailSettings.MasterMail}
                                    onChange={this.handleChange("MasterMail").bind(this)} />
                            </Grid.Column>
                        </Grid.Row> */}
                        <Grid.Row>
                            <Grid.Column>
                                <Input fluid placeholder='Application Installation URL'
                                    value={this.state.emailSettings.ApplicationURL}
                                    onChange={this.handleChange("ApplicationURL").bind(this)} />
                            </Grid.Column>
                        </Grid.Row>
                        <Grid.Row>
                            <Grid.Column>
                                <Checkbox toggle label="Use SSL"
                                    checked={this.state.emailSettings.UseSSL}
                                    onChange={this.handleCheckChange.bind(this)}></Checkbox>
                            </Grid.Column>
                        </Grid.Row>
                    </Grid>
                </Container>
                {
                    this.state.isSuccess &&
                    <MessageBoxOkOnly title="Emailer Settings"
                        action="OK"
                        onClick={() => this.setState({ isSuccess: false })}
                        caption="Setup successfully saved"
                    />
                }
            </div>
        )
    }
}
EmailSettings.getViewID = () => { return "EmailModule" };
EmailSettings.getCaption = () => { return "Emailer Settings" }
EmailSettings.getViewAccessKey = () => {
    return global.userId == empty_id;
}
EmailSettings.getIcon = () => {
    return (<Image style={{ "height": "24px", "width": "24px" }} src='Icons/Emailer.png' avatar />);
}
EmailSettings.getIconSrc = (size) => {
    return 'Icons/Emailer.png';
}
export default EmailSettings
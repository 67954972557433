import React, { Component } from 'react';
import 'semantic-ui-css/semantic.min.css';
import { Modal, Button, Input, Menu, Dropdown } from '../../../../node_modules/semantic-ui-react';
import Select from 'react-select';
import moment from 'moment';
import { ajaxPost } from '../../../ajax';
import { fnSearchEmployee, checkDate, checkYear, handleNumeric,handleNumericOnKeyDown,handleNumericOnKeyPress } from '../../../utils';
import ErrorMessage from '../../../Commons/ErrorMessage';
import SelectEmployees from '../../../Commons/SelectEmployees';

var delayTimer = null;
const someMarginNoPadding = { "margin": "0px 0px 10px 0px", "padding" : "0px" }
const noMarginNoPadding = { "margin": "0px", "padding" : "0px" }

class LeaveSummaryReportModal extends Component {

    constructor(props) {
        super(props);

        var months = [];
        for (let x = 0; x<=11; x++) {
            months.push(
                { key: x, text: moment().month(x).format("MMMM"), value: x }
            );
        }        
        this.monthOption = months;

        this.state = {
            //asOfDate: moment().startOf('day').format("YYYY-MM"),
            asOfDate: moment().startOf('day').format("YYYY-MM-DD"),
            selectedEmployees:[],
            employeeList: [],
            errTitle: "",
            errCaption: "",
            showRequiredField: false,
            
            month_month: moment().month(),
            month_year: moment().year(),
        }
    }

    generateReport = () => {
        // var checkDateParam = checkDate(this.state.asOfDate);
        // if(!checkDateParam.Result) {
        //     this.setState({
        //         errTitle: checkDateParam.Title,
        //         errCaption: checkDateParam.Message,
        //         showRequiredField: true
        //     });
        // }
        var checkYearParam = checkYear(this.state.month_year);
        if(!checkYearParam.Result) {
            this.setState({
                errTitle: checkYearParam.Title,
                errCaption: checkYearParam.Message,
                showRequiredField: true
            });
        }
        else if(this.state.month_year > moment().year()) {
            this.setState({
                errTitle: "Invalid",
                errCaption: "Selected year must not be greater than current year.",
                showRequiredField: true
            });
        }
        else {
            this.setState({
                showRequiredField: false
            },
                () => { this.props.onSave(); }
            );
        }
    }

    getParameters = () => {
        //let daysInMonth = moment(this.state.asOfDate, "YYYY-MM").daysInMonth();
        return {
            //asOfDate: moment(this.state.asOfDate + "-" + daysInMonth).format("YYYY-MM-DD"),
            asOfDate: this.state.asOfDate,
            //EmployeeIds: this.state.selectedEmployees.map(employee => { return employee.key })
            EmployeeIds: this.state.selectedEmployees
        };
    }

    getEmployeeData = (input, callback) => {
        fnSearchEmployee(input, data => {
            var emps = [];
            data.content.map(item => {
                var fullName = item.LastName + ", " + item.FirstName + " " + item.MiddleName;
                if(fullName.length > 45)
                    fullName = fullName.slice(0,45) + "..." + " (" + item.EmployeeNo + ")";
                else
                    fullName += " (" + item.EmployeeNo + ")"

                emps.push({
                    key: item._id,
                    FullName: fullName, EmployeeNo: item.EmployeeNo, AccessNumber: item.AccessNumber
                });
            })
            //this.setState({ subordinates: data.content });
            callback(null, {
                options: emps
            });
        }, 0, 10, false);
    }


    employeeSearchChange = (event, data) => {
        var self = this;
        clearTimeout(delayTimer);
        delayTimer = setTimeout(() => {
            var employeeSearchParameter = {
                data: { needle: data.searchQuery == null ? "" : data.searchQuery, itemCount: 20 },
                url: "api/DTRCorrection/searchEmployees",
                onSuccess: (data, sender) => {
                    var newOptions = data.map((model, index, array) => {
                        var fullName = model.LastName + ", " + model.FirstName + " " + model.MiddleName;
                        if(fullName.length > 45)
                            fullName = fullName.slice(0,45) + "...";

                        return { key: model._id, text: fullName, value: model._id } 
                    });
                    self.setState({ employeeList: newOptions });
                },
                onError: (error) => {

                },
                finally: () => { }
            };
            ajaxPost(employeeSearchParameter);
        }, 300);
    };

    handleDateChange = (event) =>
    {
        var selectedDate = "";
        this.setState({ ["month_" + event.target.name]: event.target.value});
        if(event.target.name === 'year') {
            selectedDate = event.target.value + "-" + (this.state.month_month + 1);
            selectedDate += "-" + moment(selectedDate, "YYYY-MM").daysInMonth();
        }
        else if(event.target.name === 'month'){
            selectedDate = this.state.month_year + "-" + (event.target.value + 1);
            selectedDate += "-" + moment(selectedDate, "YYYY-MM").daysInMonth();
        }
        else {
            selectedDate = event.target.value;
        }
        
        this.setState({
            asOfDate: selectedDate,
        });
    }

    render() {
        return (
            <Modal open={this.props.open} size="mini">
                <Modal.Header>
                    <p>Leave Summary Report Filters</p>
                </Modal.Header>
                <Modal.Content>
                    <div className="ui one column grid" 
                        // style={noMarginNoPadding}
                    >
                        {
                            this.state.showRequiredField &&
                            <div className="column" 
                                // style={someMarginNoPadding}
                            >
                                <ErrorMessage  
                                    title={this.state.errTitle}
                                    caption={this.state.errCaption}
                                    open={this.state.showRequiredField}
                                />
                            </div>
                        }
                    </div>
                    <div className="ui one column grid" 
                        // style={noMarginNoPadding}
                    >
                        {/* <div class="column" style={{ "margin": "0px 0px 10px 0px", "padding" : "0px 5px 0px 0px" }}>
                            <span>As of date:</span>
                            <Dropdown 
                                fluid
                                name='month'
                                selection
                                options={this.monthOption} 
                                disabled={this.props.isBusy} 
                                value={this.state.month_month} 
                                onChange={ (evt, data) => { 
                                    data.target = data;
                                    this.handleDateChange(data);
                                } }
                            />
                        </div> */}
                        <div className="column"
                            // style={{ "margin": "0px 0px 10px 0px", "padding" : "0px 0px 0px 5px" }}
                        >
                            <span>Year</span>
                            <Input 
                                name='year'
                                fluid 
                                required={true}
                                error={ !checkYear(this.state.month_year).Result }
                                disabled={this.props.isBusy} 
                                value={this.state.month_year} 
                                //onChange={this.handleDateChange} 
                                onChange={(event) => {  
                                    event.target.value =handleNumeric(event, true);
                                    this.handleDateChange(event);
                                }} 
                                onKeyPress={(e) => { handleNumericOnKeyPress(e)} }
                                onKeyDown={(e) => { handleNumericOnKeyDown(e)} }
                            />
                        </div>
                    </div>
                    {/* <Input type="month" size="mini" fluid value={this.state.asOfDate} onChange={(event, data)=>{this.setState({asOfDate:data.value})}} /> */}
                    {/* <Input type="date" size="mini" fluid value={this.state.asOfDate} onChange={(event, data)=>{this.setState({asOfDate:data.value})}} /> */}
                    <div className="ui one column grid" 
                        // style={noMarginNoPadding}
                    >
                        <div className="column" 
                            // style={someMarginNoPadding}
                        >
                            <span>Employees</span>
                            <SelectEmployees 
                                Ids={this.state.selectedEmployees}
                                update={(Ids) => this.setState({ selectedEmployees: Ids },
                                    // () => { this.search(); }
                                )} 
                                style={{ minWidth: '50px', maxWidth: '100%', width: '100%' }} 
                            />
                        </div>
                    </div>
                </Modal.Content>
                <Modal.Actions>
                    <Button positive content="Generate Report" onClick={this.generateReport}></Button>
                    <Button negative content="Cancel" onClick={this.props.onCancel}></Button>
                </Modal.Actions>
            </Modal>
        );
    }

}

export default LeaveSummaryReportModal;
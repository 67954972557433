import React, { Component } from 'react';
import { customStyles, isTaskAvailable, empty_id, handleNumericOnKeyDown, handleNumericOnKeyPress } from '../../utils';
import { Button, Menu, Icon, Table, Pagination, ButtonGroup, Modal, Image, TextArea, Label, Input } from 'semantic-ui-react';
import Select from 'react-select';
import { ajaxPost } from '../../ajax';
import modStyles from './AlphanumericTaxCodeProfile.module.css';

const blankATCProfile = {
    _id: empty_id,
    NatureOfIncome: '',
    TaxRate: 0.00,
    TaxCode: '',
    IsDeleted: false
}

class AlphanumericTaxCodeProfile extends Component {

    constructor() {
        super();

        this.state = {
            canAdd: false,
            canEdit: false,
            canDelete: false,

            search: '',
            taxCodeProfiles: [],
            selectedTaxCodeProfile: null,
            selectedTaxCodeOptions: [],
            taxCodeOptions: [],
            profileCount: 0,

            activePage: 1,
            isLoading: false,
            isSearchLoading: false,
            isEditLoading: false,
            isDeleteLoading: false,

            isSaving: false,
            isModalOpen: false,
        };
    }

    componentWillUnmount = () => {
        this.props.unmountCallback(this.state, "ATCProfileModule");
    }

    componentDidMount() {
        this.setState({
            canAdd: isTaskAvailable("ATCPROFILE_ADD"),
            canEdit: isTaskAvailable("ATCPROFILE_EDIT"),
            canDelete: isTaskAvailable("ATCPROFILE_DELETE")
        });

        this.doSearch();
        this.loadSearchboxOptions();
    }

    loadSearchboxOptions = () => {
        this.setState({ isSearchLoading: true });
        ajaxPost({
            url: 'api/alphanumericTaxCodeProfile/searchFiltered',
            data: {
                search: this.state.search,
                includeDeleted: false,
            },
            onSuccess: (data) => {
                this.setState({
                    taxCodeOptions: data
                });
            },
            onError: (err) => {
                alert('Failed to retrieve data.\n' + err);
            },
            finally: () => {
                this.setState({
                    isSearchLoading: false
                });
            }
        });
    }

    doSearch = () => {
        this.setState({ isLoading: true });
        ajaxPost({
            url: 'api/alphanumericTaxCodeProfile/searchWithPagination',
            data: {
                search: '',
                startingIndex: (this.state.activePage - 1) * 30,
                itemCount: 30,
                includeDeleted: false,
                selectedTaxCode: this.state.selectedTaxCodeOptions.map(x => { return (x.value) })
            },
            onSuccess: (data) => {
                this.setState({
                    taxCodeProfiles: data.content,
                    profileCount: data.count
                });
            },
            onError: (err) => {
                alert('Failed to retrieve data.\n' + err);
            },
            finally: () => {
                this.setState({
                    isLoading: false
                });
            }
        });
    }

    createProfile = () => {
        this.setState({
            selectedTaxCodeProfile: JSON.parse(JSON.stringify(blankATCProfile)),
            isModalOpen: true
        });
    }

    loadProfile = (atcProfile) => {
        this.setState({
            selectedTaxCodeProfile: JSON.parse(JSON.stringify(atcProfile)),
            isModalOpen: true
        });
    }

    deleteProfile = (atcProfile) => {
        var { taxCodeProfiles } = this.state;

        this.setState({ isDeleteLoading: true })
        ajaxPost({
            url: 'api/alphanumericTaxCodeProfile/delete',
            data: atcProfile._id,
            onSuccess: (data) => {
                if ('errors' in data && data.errors.length > 0) {
                    alert(data.errors[0]);
                }
                else {
                    taxCodeProfiles = taxCodeProfiles.filter(x => x._id !== atcProfile._id)
                    this.setState({ taxCodeProfiles });
                }
            },
            onError: (err) => {
                alert('Failed to delete data.\n' + err);
            },
            finally: () => {
                this.setState({
                    isDeleteLoading: false
                })
            }
        })
    }

    handlePaginationChange = (e, { activePage }) => {
        this.setState({ activePage }, this.doSearch);
    }

    handleTaxRateChange = (e) => {
        var text = e.target.value;
        var { selectedTaxCodeProfile } = this.state;
        if (text > 100.00)
            text = 100.00;

        selectedTaxCodeProfile.TaxRate = text;
        this.setState({ selectedTaxCodeProfile });
    }

    handleTaxCodeChange = (e) => {
        var text = e.target.value;
        var { selectedTaxCodeProfile } = this.state;

        if (text.length > 10)
            text = text.substring(0, 10);

        selectedTaxCodeProfile.TaxCode = text;
        this.setState({ selectedTaxCodeProfile });
    }

    handleNatureOfIncomeChange = (e) => {
        var text = e.target.value;
        var { selectedTaxCodeProfile } = this.state;

        selectedTaxCodeProfile.NatureOfIncome = text;
        this.setState({ selectedTaxCodeProfile });
    }

    handleOnSave = () => {
        var { taxCodeProfiles, selectedTaxCodeProfile } = this.state;

        selectedTaxCodeProfile.TaxCode = selectedTaxCodeProfile.TaxCode.trim()
        if (selectedTaxCodeProfile.TaxCode === '') {
            alert('Required fields must have a value');
            return;
        }

        this.setState({ isSaving: true });

        ajaxPost({
            url: 'api/alphanumericTaxCodeProfile/save',
            data: selectedTaxCodeProfile,
            onSuccess: (data) => {
                if ('errors' in data && data.errors.length > 0) {
                    alert(data.errors);
                }
                else {
                    var index = taxCodeProfiles.map(x => { return (x._id) }).indexOf(selectedTaxCodeProfile._id)
                    if (index !== -1)
                        taxCodeProfiles[index] = data.content;
                    else
                        taxCodeProfiles.push(data.content);

                    this.setState({
                        taxCodeProfiles,
                        selectedTaxCodeProfile: null,
                        isModalOpen: false
                    })
                }
            },
            onError: (err) => {
                alert('Failed to save.\n' + err);
            },
            finally: () => {
                this.setState({
                    isSaving: false,
                })
            }
        })
    }

    handleOnCancel = () => {
        this.setState({
            selectedTaxCodeProfile: null,
            isModalOpen: false
        });
    }

    onSearch = text => {
        clearTimeout(this.searchTimer);
        this.setState({ search: text });
        this.searchTimer = setTimeout(this.loadSearchboxOptions, 400, text);
    }

    onSearchChanged = (value) => {
        var { selectedTaxCodeOptions } = this.state;
        // var index = selectedTaxCodeOptions.map(x=> {return(x._id)}).indexOf(value.value)
        // if(index === -1)
        selectedTaxCodeOptions = value;

        this.setState({ selectedTaxCodeOptions });
    }

    render() {
        return (
            <div className={modStyles.module}>
                    <div className='w3-row' style={{ color: "#606060", marginTop: '1%', width: '100%' }}>
                        <h2>Alphanumeric Tax Code Profile</h2>
                    </div>
                    <div className='w3-row' style={{ paddingTop: '15px' }}>
                        <Pagination
                            floated='right'
                            activePage={this.state.activePage}
                            defaultActivePage={1}
                            pointing
                            secondary
                            firstItem={{ content: <Icon name='angle double left' />, icon: true }}
                            lastItem={{ content: <Icon name='angle double right' />, icon: true }}
                            prevItem={{ content: <Icon name='angle left' />, icon: true }}
                            nextItem={{ content: <Icon name='angle right' />, icon: true }}
                            size='mini'
                            siblingRange={2}
                            boundaryRange={0}
                            totalPages={Math.ceil(this.state.profileCount / 10)}
                            onPageChange={this.handlePaginationChange}
                        />
                    </div>
                    <div className='w3-row' style={{ paddingTop: '8px' }}>
                        <div className='w3-col l4 m4 s12'>
                            <div style={{ display: 'flex', width: '100%' }}>
                                <Button floated='left' size='mini' style={{ 'max-height': '31px' }}
                                    fluid={window.innerWidth < 768}
                                    onClick={this.createProfile.bind(this)} disabled={!this.state.canAdd}>
                                    <Icon name='plus' />New
                                </Button>
                                <div className='w3-hide-small'>
                                    <Button floated='left' style={{ border: 'none', 'background-color': 'transparent', cursor: 'arrow', 'max-height': '31px' }}
                                        size='tiny' loading={this.state.isLoading} fluid={window.innerWidth < 768}>
                                    </Button>
                                </div>
                            </div>
                        </div>
                        <div className='w3-col l8 m8 s12'>
                            <Menu secondary size='mini' style={{ width: '100%' }}>
                                <div style={window.innerWidth > 500 ? { display: 'flex', justifyContent: 'flex-end', width: '100%' } : { width: '100%' }}>
                                    <div style={{ width: '100%', maxWidth: 300 }}>
                                        <Select styles={customStyles}
                                            isLoading={this.state.isSearchLoading}
                                            options={this.state.taxCodeOptions}
                                            onInputChange={this.onSearch}
                                            onChange={this.onSearchChanged.bind(this)}
                                            isMulti
                                        />
                                    </div>
                                    <Button size='mini' fluid={window.innerWidth < 768} type='submit' onClick={() => this.setState({ activePage: 1 }, this.doSearch)} style={window.innerWidth >= 768 ? { 'max-height': '30px', marginLeft: '10px' } : { 'max-height': '30px' }} content='Search' />
                                </div>
                            </Menu>
                        </div>
                    </div>
                    <div  className={modStyles.content}>
                        {this.state.taxCodeProfiles != null &&
                            <Table fixed striped padded size='small'>
                                <Table.Header>
                                    <Table.Row style={{ 'backgroundColor': 'gainsboro', 'fontSize': '11px', padding: '0px' }}>
                                        <Table.HeaderCell style={{ width: '55%' }}>Nature of Income</Table.HeaderCell>
                                        <Table.HeaderCell style={{ width: '15%' }}>Tax Rate</Table.HeaderCell>
                                        <Table.HeaderCell style={{ width: '15%' }}>Tax Code</Table.HeaderCell>
                                        <Table.HeaderCell textAlign='center' style={{ width: '15%' }}>Action</Table.HeaderCell>
                                    </Table.Row>
                                </Table.Header>
                                <Table.Body>
                                    {this.state.taxCodeProfiles.map(list => {
                                        return (
                                            <Table.Row selected key={list._id} negative={list.IsDeleted} >
                                                <Table.Cell verticalAlign='top' style={{ width: '300px', overflowWrap: 'break-word', whiteSpace: 'pre' }}>{list.NatureOfIncome}</Table.Cell>
                                                <Table.Cell verticalAlign='top'>{list.TaxRate}</Table.Cell>
                                                <Table.Cell verticalAlign='top'>{list.TaxCode}</Table.Cell>
                                                <Table.Cell verticalAlign='top' textAlign='center'>
                                                    <ButtonGroup size='mini' vertical>
                                                        <Button size='mini' onClick={this.loadProfile.bind(this, list)} disabled={!this.state.canEdit}>Edit</Button>
                                                        <Button size='mini' onClick={this.deleteProfile.bind(this, list)} disabled={!this.state.canDelete}>Delete</Button>
                                                    </ButtonGroup>
                                                </Table.Cell>
                                            </Table.Row>
                                        )
                                    })}
                                </Table.Body>
                            </Table>
                        }
                    </div>
                {this.state.isModalOpen && <Modal size='mini' open={true}>
                    <Modal.Header>
                        Alphanumeric Tax Code Profile
                    </Modal.Header>
                    <Modal.Content>
                        <div>
                            <Label>Tax Code *</Label>
                        </div>
                        <div>
                            <Input onChange={this.handleTaxCodeChange.bind(this)} value={this.state.selectedTaxCodeProfile.TaxCode}></Input>
                        </div>
                        <div>
                            <Label>Tax Rate *</Label>
                        </div>
                        <div>
                            <Input value={this.state.selectedTaxCodeProfile.TaxRate}
                                onChange={this.handleTaxRateChange.bind(this)}
                                onKeyPress={(e) => { handleNumericOnKeyPress(e) }}
                                onKeyDown={(e) => { handleNumericOnKeyDown(e) }}>
                            </Input> %
                        </div>
                        <div>
                            <Label>Nature of Income</Label>
                        </div>
                        <div>
                            <TextArea style={{ minHeight: 200, maxHeight: 200, minWidth: '100%', maxWidth: '100%' }} onChange={this.handleNatureOfIncomeChange.bind(this)} value={this.state.selectedTaxCodeProfile.NatureOfIncome}></TextArea>
                        </div>
                    </Modal.Content>
                    <Modal.Actions>
                        <Button type='submit' loading={this.state.isSaving} onClick={this.handleOnSave.bind(this)}>Save</Button>
                        <Button onClick={this.handleOnCancel.bind(this)}>Cancel</Button>
                    </Modal.Actions>
                </Modal>}
            </div>
        );
    }

}

AlphanumericTaxCodeProfile.getViewID = () => { return "ATCProfileModule" };
AlphanumericTaxCodeProfile.getCaption = () => { return "ATC Profile" }
AlphanumericTaxCodeProfile.getViewAccessKey = () => {
    return isTaskAvailable("ATCPROFILE_VIEW") || isTaskAvailable("ATCPROFILE_ADD");
}
AlphanumericTaxCodeProfile.getIcon = () => {
    return (<Image style={{ "height": "24px", "width": "24px" }} src='/Icons/ATC.png' avatar />);
}
AlphanumericTaxCodeProfile.getIconSrc = (size) => {
    return '/Icons/ATC.png';
}

export default AlphanumericTaxCodeProfile;
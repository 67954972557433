import React, { Component } from 'react';
import 'semantic-ui-css/semantic.min.css';
import { Modal, Button, Checkbox, Input,Dropdown, Label } from 'semantic-ui-react';
import SelectEmployees from '../../../Commons/SelectEmployees';
import moment from 'moment';

// function employeeSearchlist(list){
//     var employees = [];
//     if(list !=null){
//        list.map(x =>{
//            return(
//             employees.push({label:x.LastName + ", " + x.FirstName + " " + x.MiddleName,value:x._id})
//            )
//        })
//     }
//     return employees;
// }
class MonthlyFilterModal extends Component {

    constructor(props) {
        super(props);
        this.state = {
            startYear:new Date().getFullYear(),
            startMonth: new Date().getMonth(),
            endYear:new Date().getFullYear(),
            endMonth: new Date().getMonth(),
            EmployeeIds:[],
            includeEmptyEntries:true,
            searchQuery:'',
            Search: "",
            Employeelist:[]
        }
    }

    getParameters = () => {
        return {
            startYear: this.state.startYear,
            startMonth: this.state.startMonth,
            endYear: this.state.endYear,
            endMonth: this.state.endMonth,
            message: "",
            EmployeeIds:this.state.EmployeeIds,
            includeEmptyEntries: this.state.includeEmptyEntries,
            Search: "",
        };
    }

    updateEmployeeIds = (ids) =>{
        this.setState({ EmployeeIds: ids});
    }
    handleCheckChange = (e) =>{
        var includeEmptyEntries  = this.state.includeEmptyEntries;
        includeEmptyEntries = includeEmptyEntries === false ? true : false;
        this.setState({includeEmptyEntries:includeEmptyEntries});
    }
    render() {
        const months = [
            { key: 1, text: 'January', value: 1 },
            { key: 2, text: 'Febuary', value: 2 },
            { key: 3, text: 'March', value: 3 },
            { key: 4, text: 'April', value: 4 },
            { key: 5, text: 'May', value: 5 },
            { key: 6, text: 'June', value: 6 },
            { key: 7, text: 'July', value: 7 },
            { key: 8, text: 'August', value: 8 },
            { key: 9, text: 'September', value: 9 },
            { key: 10, text: 'October', value: 10 },
            { key: 11, text: 'November', value: 11 },
            { key: 12, text: 'December', value: 12 },
        ];
        return (
            <Modal open={this.props.open} size="tiny">
                <Modal.Header>
                    <p>Report Filters</p>
                </Modal.Header>
                <Modal.Content>
                    <div class="ui grid">
                        <div class="left floated left aligned sixteen wide column">
                            <label>Start Date</label>
                            <div class="ui grid">
                                <div class="left floated left aligned eight wide column">
                                    <Input fluid size="mini" style={{ "height": "100%"}}  value={this.state.startYear} onChange={(event, data)=>{this.setState({startYear:data.value})}} />
                                </div>
                                <div class="left floated left aligned eight wide column">                                  
                                    <Dropdown size="mini" fluid selection options={months} placeholder="Month"
                                                value={this.state.startMonth} name="Month"
                                                onChange={(event, data)=>{this.setState({startMonth:data.value})}}/>
                                </div>
                            </div>
                        </div>                        
                        <div class="left floated left aligned sixteen wide column">
                            <label >End Date</label>
                            <div class="ui grid">
                                <div class="left floated left aligned eight wide column">
                                    <Input  size="mini" fluid style={{ "height": "100%"}}  value={this.state.endYear} onChange={(event, data)=>{this.setState({endYear:data.value})}} />
                                </div>
                                <div class="left floated left aligned eight wide column">
                                    <Dropdown  size="mini" fluid  selection options={months} placeholder="Month"
                                                value={this.state.endMonth} name="Month"
                                                onChange={(event, data)=>{this.setState({endMonth:data.value})}}/>
                                </div>
                            </div>                            
                        </div>
                    </div>
                    <div class="ui grid">
                        <div class="left floated left aligned sixteen wide column">
                        <label>Employee</label>
                            <SelectEmployees Ids={this.state.EmployeeIds} update={this.updateEmployeeIds.bind(this)}/>
                        </div>
                    </div>
                    <div class="ui grid">
                        <div class="left floated left aligned sixteen wide column">
                        <Checkbox toggle fluid label="Include empty entries" 
                                    checked={this.state.includeEmptyEntries}
                                    onClick={this.handleCheckChange.bind(this)}/>
                        </div>
                    </div>
                </Modal.Content>
                <Modal.Actions>
                    <Button basic content="Generate" onClick={this.props.onSave}></Button>
                    <Button basic content="Cancel" onClick={this.props.onCancel}></Button>
                </Modal.Actions>
            </Modal>
        );
    }

}

export default MonthlyFilterModal;
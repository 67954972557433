import React, { Component } from 'react';
import Select from 'react-select'
import { Tab, Input, Button, Table, Checkbox } from 'semantic-ui-react';
import { ajaxPost } from '../../ajax';
import { handleNumericOnKeyPress, handleNumericOnKeyDown, numToWord } from '../../utils';
import PdfMake from 'pdfmake/build/pdfmake';
import vfsFonts from 'pdfmake/build/vfs_fonts';

const moment = require('moment');

const payorOptions = [
    { label: 'Business Employer', value: 'Business_Employer' },
    { label: 'Household Employer', value: 'Household_Employer' }
]

export default class SSSR5 extends Component {
    constructor(props) {
        super(props);

        this.state = {
            selectedCompany: null,
            selectedPayor: null,
            year: moment().format('YYYY'),

            isCompanyLoading: false,
            isGenerateLoading: false,
            isExportLoading: false,

            isCashSelected: false,
            isPostalMoneySelected: false,
            isCheckSelected: false,

            cashAmount: 0,
            postalMoneyAmount: 0,
            checkAmount: 0,
            checkNumber: '',
            checkDate: '',
            bankName: '',

            companyList: [],
            employeeList: [],

            generatedData: {
                EmployerNo: '',
                UnitNo: '',
                BlockNo: '',
                District: '',
                City: '',
                ZipCode: '',
                TelephoneNo: '',
                EmployerName: '',
                BuildingName: '',
                StreetName: '',
                Subdivision: '',
                Province: '',
                TIN: '',
                MobileNo: '',
                EmailAddress: '',
                Website: '',
                ContributionList: []
            },

            totalFields: {
                subTotalContriSSS: 0,
                subTotalContriSSSEC: 0,
                subTotalContri: 0,
                subTotalAdjustmentSSS: 0,
                subTotalAdjustmentSSSEC: 0,
                subTotalAdjustment: 0,
                totalAmountPayment: 0,

                totalAmountPaid: '0.00',
            },

            contributionList: [],
            adjustmentList: []
        }

        for (let index = 1; index <= 12; index++) {
            const month = moment('2019-' + index + '-' + '1').format('MMMM');
            const year = this.state.year;
            this.state.contributionList.push({ month, year, sss: 0, sssEC: 0, total: 0 });
        }

        for (let x = 0; x < 3; x++) {
            const year = this.state.year;
            const adjTypes = ['Under Payment', 'Penalty', 'Interest'];
            for (let y = 0; y < 3; y++) {
                this.state.adjustmentList.push({ adjType: adjTypes[x], year, sss: 0, sssEC: 0, total: 0 });
            }
        }

        this.loadCompanies();
    }

    componentWillMount = () => {
        if(this.props.prevState !== null)
            this.state = this.props.prevState;
    }

    componentWillUnmount = () => {
        this.props.unmountCallback(this.state, 'SSSR5');
    }

    computeTotalFields() {
        var { totalFields, adjustmentList, contributionList } = this.state;
        var totalContriSSS = 0;
        var totalContriEC = 0;
        var totalAdjustmentSSS = 0;
        var totalAdjustmentEC = 0;

        contributionList.forEach((x) => {
            var sss = x.sss !== '' ? parseFloat(x.sss) : 0
            var sssEC = x.sssEC !== '' ? parseFloat(x.sssEC) : 0
            totalContriSSS += sss;
            totalContriEC += sssEC;
        });

        adjustmentList.forEach((x) => {
            var sss = x.sss !== '' ? parseFloat(x.sss) : 0
            var sssEC = x.sssEC !== '' ? parseFloat(x.sssEC) : 0
            totalAdjustmentSSS += sss;
            totalAdjustmentEC += sssEC;
        })

        totalFields.subTotalContriSSS = parseFloat(totalContriSSS).toFixed(2);
        totalFields.subTotalContriSSSEC = parseFloat(totalContriEC).toFixed(2);
        totalFields.subTotalContri = parseFloat(totalContriSSS + totalContriEC).toFixed(2);
        totalFields.subTotalAdjustmentSSS = parseFloat(totalAdjustmentSSS).toFixed(2);
        totalFields.subTotalAdjustmentSSSEC = parseFloat(totalAdjustmentEC).toFixed(2);
        totalFields.subTotalAdjustment = parseFloat(totalAdjustmentSSS + totalAdjustmentEC).toFixed(2);
        totalFields.totalAmountPayment = parseFloat(totalContriSSS + totalContriEC + totalAdjustmentSSS + totalAdjustmentEC).toFixed(2);

        const { cashAmount, postalMoneyAmount, checkAmount } = this.state;
        var valCashAmount = cashAmount !== '' ? parseFloat(cashAmount) : 0;
        var valPostalAmount = postalMoneyAmount !== '' ? parseFloat(postalMoneyAmount) : 0;
        var valCheckAmount = checkAmount !== '' ? parseFloat(checkAmount) : 0;
        totalFields.totalAmountPaid = parseFloat(valCashAmount + valPostalAmount + valCheckAmount).toFixed(2);

        this.setState({ totalFields });
    }

    loadCompanies() {
        this.setState({ isCompanyLoading: true })
        ajaxPost({
            url: 'api/group/search',
            data: {
                Search: '',
                startingIndex: 0,
                itemCount: 999,
                Types: ['COMPANY']
            },
            onSuccess: (data) => {
                this.setState({
                    companyList: data.content.map(x => { return ({ label: x.Name, value: x._id }) }),
                    isCompanyLoading: false
                });
            },
            finally: () => { }
        })
    }

    checkStatus(processId, callback) {
        ajaxPost({
            data: { processId: processId },
            url: 'api/BackgroundProcess/GetStatus',
            onSuccess: data => {
                if (data.Status != "PROCESSING") {
                    callback(processId)
                }
                else {
                    this.checkStatus(processId, callback);
                }
            },
            finally: () => { }
        })
    }

    onExportPDFClick = () => {
        if (this.state.isExportLoading)
            return;

        if (this.state.selectedCompany === null) {
            alert("Please select an employee");
            return;
        }

        if (this.state.selectedPayor === null) {
            alert("Please select a payor");
            return;
        }

        this.setState({ isExportLoading: true });

        const { generatedData, contributionList, adjustmentList, totalFields } = this.state;
        var contriSummary = JSON.parse(JSON.stringify(contributionList));
        contriSummary.push({ year: '', sss: totalFields.subTotalContriSSS, sssEC: totalFields.subTotalContriSSSEC, total: totalFields.subTotalContri });
        ajaxPost({
            url: 'api/governmentforms/SSSR5ExportToPDF',
            data: {},
            onSuccess: (data) => {
                const { vfs } = vfsFonts.pdfMake;
                PdfMake.vfs = vfs;

                var employerNo = generatedData.EmployerNo.replace(/-/g, '');
                var zipCode = generatedData.ZipCode;
                var tin = generatedData.TIN.replace(/-/g, '');;
                var telephoneNo = generatedData.TelephoneNo.replace(/-/g, '');;
                var mobileNo = generatedData.MobileNo.replace(/-/g, '');;

                if (employerNo.length > 13)
                    employerNo = employerNo.substring(0, 13);

                if (zipCode.length > 4)
                    zipCode = zipCode.substring(0, 3);

                if (tin.length > 12)
                    tin = tin.substring(0, 12);

                if (telephoneNo.length > 10)
                    telephoneNo = telephoneNo.substring(0, 10);

                if (mobileNo.length > 11)
                    mobileNo = mobileNo.substring(0, 11);

                var pdfData = {
                    pageSize: 'LETTER',
                    pageOrientation: 'portrait',
                    content: [
                        {
                            image: data.Image,
                            width: 607,
                            height: 650,
                            absolutePosition: { x: 3, y: 22 }
                        },
                        {
                            text: generatedData.EmployerName.toUpperCase(),
                            style: { fontSize: 8 },
                            absolutePosition: { x: 236, y: 177 }
                        },
                        employerNo.split('').map((x, index) => {
                            return (
                                {
                                    text: x,
                                    style: { fontSize: 7 },
                                    absolutePosition: { x: 32 + index * 14.75, y: 177 }
                                }
                            )
                        }),
                        {
                            absolutePosition: { x: 50, y: 200 },
                            table: {
                                widths: [163, 113, 93, 143],
                                heights: [15],
                                body: [
                                    [
                                        { text: generatedData.UnitNo.toUpperCase() + ' ' + generatedData.BuildingName.toUpperCase(), style: { fontSize: 6 }, alignment: 'center' },
                                        { text: generatedData.BlockNo.toUpperCase(), style: { fontSize: 6 }, alignment: 'center' },
                                        { text: generatedData.StreetName.toUpperCase(), style: { fontSize: 6 }, alignment: 'center' },
                                        { text: generatedData.District.toUpperCase(), style: { fontSize: 6 }, alignment: 'center' },
                                    ]
                                ]
                            },
                            layout: 'noBorders'
                        },
                        {
                            absolutePosition: { x: 25, y: 227 },
                            table: {
                                widths: [83, 113, 121],
                                heights: [15],
                                body: [
                                    [
                                        { text: generatedData.Subdivision.toUpperCase(), style: { fontSize: 6 }, alignment: 'center' },
                                        { text: generatedData.City.toUpperCase(), style: { fontSize: 6 }, alignment: 'center' },
                                        { text: generatedData.Province.toUpperCase(), style: { fontSize: 6 }, alignment: 'center' },
                                    ]
                                ]
                            },
                            layout: 'noBorders'
                        },
                        zipCode.split('').map((x, index) => {
                            return (
                                {
                                    text: x,
                                    style: { fontSize: 7 },
                                    absolutePosition: { x: 356 + index * 14.75, y: 234 },
                                }
                            )
                        }),
                        tin.split('').map((x, index) => {
                            return (
                                {
                                    text: x,
                                    style: { fontSize: 7 },
                                    absolutePosition: { x: 415 + index * 14.75, y: 234 },
                                }
                            )
                        }),
                        telephoneNo.split('').map((x, index) => {
                            return (
                                {
                                    text: x,
                                    style: { fontSize: 7 },
                                    absolutePosition: { x: 32 + index * 14.75, y: 263 },
                                }
                            )
                        }),
                        mobileNo.split('').map((x, index) => {
                            return (
                                {
                                    text: x,
                                    style: { fontSize: 7 },
                                    absolutePosition: { x: 179 + index * 14.75, y: 263 },
                                }
                            )
                        }),
                        {
                            text: generatedData.EmailAddress,
                            style: { fontSize: 7 },
                            absolutePosition: { x: 342, y: 263 },
                        },
                        {
                            text: generatedData.Website,
                            style: { fontSize: 7 },
                            absolutePosition: { x: 463, y: 263 },
                        },
                        {
                            text: 'X',
                            style: { fontSize: 7 },
                            absolutePosition: { x: this.state.selectedPayor.value === 'Business_Employer' ? 393 : 485, y: 297 },
                        },
                        {
                            text: this.state.isCashSelected? 'X': '',
                            style: { fontSize: 7 },
                            absolutePosition: { x: 393, y: 331 },
                        },
                        {
                            text: this.state.isCashSelected? parseFloat(this.state.cashAmount).toFixed(2): '',
                            style: { fontSize: 7 },
                            absolutePosition: { x: 513, y: 332 },
                        },
                        {
                            text: this.state.isPostalMoneySelected? 'X': '',
                            style: { fontSize: 7 },
                            absolutePosition: { x: 393, y: 345 },
                        },
                        {
                            text: this.state.isPostalMoneySelected? parseFloat(this.state.postalMoneyAmount).toFixed(2): '',
                            style: { fontSize: 7 },
                            absolutePosition: { x: 513, y: 345 },
                        },
                        {
                            text: this.state.isCheckSelected? 'X': '',
                            style: { fontSize: 7 },
                            absolutePosition: { x: 393, y: 358 },
                        },
                        {
                            text: this.state.isCheckSelected? parseFloat(this.state.checkAmount).toFixed(2): '',
                            style: { fontSize: 7 },
                            absolutePosition: { x: 513, y: 358 },
                        },
                        {
                            text: this.state.isCheckSelected? this.state.checkNumber: '',
                            style: { fontSize: 7 },
                            absolutePosition: { x: 467, y: 372 },
                        },
                        {
                            text: this.state.isCheckSelected? this.state.checkDate: '',
                            style: { fontSize: 7 },
                            absolutePosition: { x: 467, y: 385 },
                        },
                        {
                            text: this.state.isCheckSelected? this.state.bankName: '',
                            style: { fontSize: 7 },
                            absolutePosition: { x: 467, y: 398 },
                        },
                        {
                            text: this.state.totalFields.totalAmountPaid,
                            style: { fontSize: 7 },
                            absolutePosition: { x: 513, y: 425 },
                        },
                        {
                            text: numToWord(totalFields.totalAmountPaid).toUpperCase() + ' ONLY',
                            style: { fontSize: 7 },
                            absolutePosition: { x: 400, y: 470 },   
                        },
                        {
                            absolutePosition: { x: 86, y: 315 },
                            table: {
                                widths: [40, 75, 68, 80],
                                heights: 9.6,
                                body:
                                    contriSummary.map(x => {
                                        return (
                                            [
                                                { text: x.year, style: { fontSize: 6 }, alignment: 'center' },
                                                { text: parseFloat(x.sss).toFixed(2), style: { fontSize: 6 }, alignment: 'right' },
                                                { text: parseFloat(x.sssEC).toFixed(2), style: { fontSize: 6 }, alignment: 'right' },
                                                { text: parseFloat(x.total).toFixed(2), style: { fontSize: 6 }, alignment: 'right' },
                                            ]
                                        )
                                    })
                            },
                            layout: 'noBorders'
                        },
                        {
                            absolutePosition: { x: 86, y: 493 },
                            table: {
                                widths: [40, 75, 68, 80],
                                heights: 9.6,
                                body:
                                    adjustmentList.filter(x => x.adjType === 'Under Payment').map(x => {
                                        return (
                                            [
                                                { text: x.year, style: { fontSize: 6 }, alignment: 'center' },
                                                { text: parseFloat(x.sss).toFixed(2), style: { fontSize: 6 }, alignment: 'right' },
                                                { text: parseFloat(x.sssEC).toFixed(2), style: { fontSize: 6 }, alignment: 'right' },
                                                { text: parseFloat(x.total).toFixed(2), style: { fontSize: 6 }, alignment: 'right' },
                                            ]
                                        )
                                    })
                            },
                            layout: 'noBorders'
                        },
                        {
                            absolutePosition: { x: 86, y: 535 },
                            table: {
                                widths: [40, 75, 68, 80],
                                heights: 9.6,
                                body:
                                    adjustmentList.filter(x => x.adjType === 'Penalty').map(x => {
                                        return (
                                            [
                                                { text: x.year, style: { fontSize: 6 }, alignment: 'center' },
                                                { text: parseFloat(x.sss).toFixed(2), style: { fontSize: 6 }, alignment: 'right' },
                                                { text: parseFloat(x.sssEC).toFixed(2), style: { fontSize: 6 }, alignment: 'right' },
                                                { text: parseFloat(x.total).toFixed(2), style: { fontSize: 6 }, alignment: 'right' },
                                            ]
                                        )
                                    })
                            },
                            layout: 'noBorders'
                        },
                        {
                            absolutePosition: { x: 86, y: 575 },
                            table: {
                                widths: [40, 75, 68, 80],
                                heights: 9.6,
                                body:
                                    adjustmentList.filter(x => x.adjType === 'Interest').map(x => {
                                        return (
                                            [
                                                { text: x.year, style: { fontSize: 6 }, alignment: 'center' },
                                                { text: parseFloat(x.sss).toFixed(2), style: { fontSize: 6 }, alignment: 'right' },
                                                { text: parseFloat(x.sssEC).toFixed(2), style: { fontSize: 6 }, alignment: 'right' },
                                                { text: parseFloat(x.total).toFixed(2), style: { fontSize: 6 }, alignment: 'right' },
                                            ]
                                        )
                                    })
                            },
                            layout: 'noBorders'
                        },
                        {
                            absolutePosition: { x: 86, y: 615 },
                            table: {
                                widths: [40, 75, 68, 80],
                                heights: 9.6,
                                body:
                                    [
                                        [{ text: '', style: { fontSize: 6 }, alignment: 'center' },
                                        { text: totalFields.subTotalAdjustmentSSS, style: { fontSize: 6 }, alignment: 'right' },
                                        { text: totalFields.subTotalAdjustmentSSSEC, style: { fontSize: 6 }, alignment: 'right' },
                                        { text: totalFields.subTotalAdjustment, style: { fontSize: 6 }, alignment: 'right' }]
                                    ]
                            },
                            layout: 'noBorders'
                        },
                        {
                            absolutePosition: { x: 86, y: 633 },
                            table: {
                                widths: [40, 75, 68, 80],
                                heights: 9.6,
                                body:
                                    [
                                        [{ text: '', style: { fontSize: 6 }, alignment: 'center' },
                                        { text: '', style: { fontSize: 6 }, alignment: 'right' },
                                        { text: '', style: { fontSize: 6 }, alignment: 'right' },
                                        { text: totalFields.totalAmountPayment, style: { fontSize: 8 }, alignment: 'right' }]
                                    ]
                            },
                            layout: 'noBorders'
                        },
                    ]
                }

                PdfMake.createPdf(pdfData).open();
            },
            finally: () => { this.setState({ isExportLoading: false }) }
        })

    }

    onGenerateClick = () => {
        if (this.state.isGenerateLoading)
            return;

        this.setState({ isGenerateLoading: true });
        this.generateSSSR5((processId) => {
            ajaxPost({
                url: 'api/governmentforms/GetSSSR5',
                data: processId,
                onSuccess: (data) => {
                    var contri = [];
                    data.content.ContributionList.forEach(x => {
                        contri.push({ month: x.Month, year: x.Year, sss: x.SSS, sssEC: x.SSSEC, total: x.Total })
                    });

                    this.setState({ generatedData: data.content, contributionList: contri, isGenerateLoading: false }, () => this.computeTotalFields());
                },
                finally: () => { }
            })
        })
    }

    generateSSSR5 = (callback) => {
        if (this.state.selectedCompany === null) {
            alert("No Company to be Generated");
            return;
        }

        ajaxPost({
            url: 'api/governmentforms/GenerateSSSR5',
            data: {
                companyId: this.state.selectedCompany.value,
                year: this.state.year
            },
            onSuccess: (value) => {
                this.checkStatus(value, callback);
            },
            finally: () => { }
        })
    }

    onTextChange = (field, e) => {
        this.setState({ [field]: e.target.value }, () => this.computeTotalFields());
    }

    onInfoChange = (field, e) => {
        var { generatedData } = this.state;
        generatedData[field] = e.target.value;

        this.setState({ generatedData });
    }

    onContributionChange = (index, field, e) => {
        var { contributionList } = this.state;
        contributionList[index][field] = e.target.value;

        var sss = contributionList[index].sss === '' ? 0 : parseFloat(contributionList[index].sss);
        var sssEC = contributionList[index].sssEC === '' ? 0 : parseFloat(contributionList[index].sssEC);
        contributionList[index].total = sss + sssEC;

        this.setState({ contributionList }, () => this.computeTotalFields());
    }

    onAdjustmentChange = (index, field, e) => {
        var { adjustmentList } = this.state;
        adjustmentList[index][field] = e.target.value;

        var sss = adjustmentList[index].sss === '' ? 0 : parseFloat(adjustmentList[index].sss);
        var sssEC = adjustmentList[index].sssEC === '' ? 0 : parseFloat(adjustmentList[index].sssEC);
        adjustmentList[index].total = sss + sssEC;

        this.setState({ adjustmentList }, () => this.computeTotalFields());
    }

    createTextField(label, field, isNumericOnly) {
        var { generatedData } = this.state;
        return (
            <div className="w3-col s12 m5 l5" style={{ paddingTop: '10px' }}>
                <div className="w3-col s12 m5 l5" style={{ paddingLeft: '10px' }}>
                    <label> {label} </label>
                </div>
                {isNumericOnly === false && <div className="w3-col s12 m7 l7">
                    <Input style={{ border: '1px solid gray' }} fluid
                        value={generatedData[field]} onChange={this.onInfoChange.bind(this, field)} />
                </div>}
                {isNumericOnly === true && <div className="w3-col s12 m7 l7">
                    <Input style={{ border: '1px solid gray' }} fluid
                        value={generatedData[field]} onChange={this.onInfoChange.bind(this, field)}
                        onKeyPress={(e) => handleNumericOnKeyPress(e)}
                        onKeyDown={(e) => handleNumericOnKeyDown(e)}
                    />
                </div>}
            </div>
        )
    }

    onCashCheckedChanged = (e, { checked }) => {
        if (!checked)
            this.setState({ cashAmount: 0 }, () => {this.computeTotalFields()})

        this.setState({ isCashSelected: checked });
    }

    onPostalCheckedChanged = (e, { checked }) => {
        if (!checked)
            this.setState({ postalMoneyAmount: 0 }, () => {this.computeTotalFields()})

        this.setState({ isPostalMoneySelected: checked });
    }

    onCheckCheckedChanged = (e, { checked }) => {
        if (!checked)
            this.setState({ checkAmount: 0, checkNumber: '', checkDate: '', bankName: '' }, () => {this.computeTotalFields()})

        this.setState({ isCheckSelected: checked });
    }

    render() {
        const { generatedData, contributionList, adjustmentList, totalFields } = this.state;

        const tabSelection = [
            {
                menuItem: 'General Information', pane:
                    <Tab.Pane attached={false} style={{ border: '0px none', width: '100%' }}>
                        <div className="w3-row" style={{ width: '100%' }}>
                            {this.createTextField('Employer No', 'EmployerNo', false)}
                            {this.createTextField('Employer Name', 'EmployerName', false)}
                            {this.createTextField('Unit No.', 'UnitNo', false)}
                            {this.createTextField('Building Name', 'BuildingName', false)}
                            {this.createTextField('Block No.', 'BlockNo', false)}
                            {this.createTextField('Street Name', 'StreetName', false)}
                            {this.createTextField('District', 'District', false)}
                            {this.createTextField('Subdivision', 'Subdivision', false)}
                            {this.createTextField('City', 'City', false)}
                            {this.createTextField('Province', 'Province', false)}
                            {this.createTextField('Zip Code', 'ZipCode', false)}
                            {this.createTextField('TIN', 'TIN', false)}
                            {this.createTextField('Telephone No.', 'TelephoneNo', false)}
                            {this.createTextField('Mobile / Cell No', 'MobileNo', false)}
                            {this.createTextField('E-mail Address', 'EmailAddress', false)}
                            {this.createTextField('Website', 'Website', false)}
                        </div>
                    </Tab.Pane>
            },
            {
                menuItem: 'Contribution', pane:
                    <Tab.Pane attached={false} style={{ border: '0px none', width: '100%' }}>
                        <Table fixed striped padded size='small'>
                            <Table.Header>
                                <Table.Row style={{ 'backgroundColor': 'gainsboro', 'fontSize': '11px', padding: '0px' }}>
                                    <Table.HeaderCell style={{ width: '20%' }}>Month</Table.HeaderCell>
                                    <Table.HeaderCell style={{ width: '20%' }}>Year</Table.HeaderCell>
                                    <Table.HeaderCell style={{ width: '20%' }}>Social Security Contribution</Table.HeaderCell>
                                    <Table.HeaderCell style={{ width: '20%' }}>Employees' Compensation</Table.HeaderCell>
                                    <Table.HeaderCell style={{ width: '20%' }}>Total</Table.HeaderCell>
                                </Table.Row>
                            </Table.Header>
                            <Table.Body>
                                {contributionList.map(list => {
                                    return (
                                        <Table.Row>
                                            <Table.Cell verticalAlign='top'>{list.month}</Table.Cell>
                                            <Table.Cell verticalAlign='top'>{list.year}</Table.Cell>
                                            <Table.Cell verticalAlign='top'><Input value={list.sss}
                                                onChange={this.onContributionChange.bind(this, contributionList.indexOf(list), 'sss')}
                                                onKeyPress={(e) => handleNumericOnKeyPress(e)}
                                                onKeyDown={(e) => handleNumericOnKeyDown(e)} />
                                            </Table.Cell>
                                            <Table.Cell verticalAlign='top'><Input value={list.sssEC}
                                                onChange={this.onContributionChange.bind(this, contributionList.indexOf(list), 'sssEC')}
                                                onKeyPress={(e) => handleNumericOnKeyPress(e)}
                                                onKeyDown={(e) => handleNumericOnKeyDown(e)} />
                                            </Table.Cell>
                                            <Table.Cell verticalAlign='top'>{list.total}</Table.Cell>
                                        </Table.Row>
                                    )
                                })}
                                <Table.Row>
                                    <Table.Cell verticalAlign='top'>Total</Table.Cell>
                                    <Table.Cell verticalAlign='top'> <span /> </Table.Cell>
                                    <Table.Cell verticalAlign='top'>{totalFields.subTotalContriSSS}</Table.Cell>
                                    <Table.Cell verticalAlign='top'>{totalFields.subTotalContriSSSEC}</Table.Cell>
                                    <Table.Cell verticalAlign='top'>{totalFields.subTotalContri}</Table.Cell>
                                </Table.Row>
                            </Table.Body>
                        </Table>
                    </Tab.Pane>
            },
            {
                menuItem: 'Adjustments', pane:
                    <Tab.Pane attached={false} style={{ border: '0px none', width: '100%' }}>
                        <Table fixed striped padded size='small'>
                            <Table.Header>
                                <Table.Row style={{ 'backgroundColor': 'gainsboro', 'fontSize': '11px', padding: '0px' }}>
                                    <Table.HeaderCell style={{ width: '20%' }}> <span /> </Table.HeaderCell>
                                    <Table.HeaderCell style={{ width: '20%' }}> <span /> </Table.HeaderCell>
                                    <Table.HeaderCell style={{ width: '20%' }}>Social Security Contribution</Table.HeaderCell>
                                    <Table.HeaderCell style={{ width: '20%' }}>Employees' Compensation</Table.HeaderCell>
                                    <Table.HeaderCell style={{ width: '20%' }}>Total</Table.HeaderCell>
                                </Table.Row>
                            </Table.Header>
                            <Table.Body>
                                {adjustmentList.map(list => {
                                    return (
                                        <Table.Row>
                                            <Table.Cell verticalAlign='top'>{list.adjType}</Table.Cell>
                                            <Table.Cell verticalAlign='top'>{list.year}</Table.Cell>
                                            <Table.Cell verticalAlign='top'><Input value={list.sss}
                                                onChange={this.onAdjustmentChange.bind(this, adjustmentList.indexOf(list), 'sss')}
                                                onKeyPress={(e) => handleNumericOnKeyPress(e)}
                                                onKeyDown={(e) => handleNumericOnKeyDown(e)} />
                                            </Table.Cell>
                                            <Table.Cell verticalAlign='top'><Input value={list.sssEC}
                                                onChange={this.onAdjustmentChange.bind(this, adjustmentList.indexOf(list), 'sssEC')}
                                                onKeyPress={(e) => handleNumericOnKeyPress(e)}
                                                onKeyDown={(e) => handleNumericOnKeyDown(e)} />
                                            </Table.Cell>
                                            <Table.Cell verticalAlign='top'>{list.total}</Table.Cell>
                                        </Table.Row>
                                    )
                                })}
                                <Table.Row>
                                    <Table.Cell verticalAlign='top'>Total</Table.Cell>
                                    <Table.Cell verticalAlign='top'> <span /> </Table.Cell>
                                    <Table.Cell verticalAlign='top'>{totalFields.subTotalAdjustmentSSS}</Table.Cell>
                                    <Table.Cell verticalAlign='top'>{totalFields.subTotalAdjustmentSSSEC}</Table.Cell>
                                    <Table.Cell verticalAlign='top'>{totalFields.subTotalAdjustment}</Table.Cell>
                                </Table.Row>
                            </Table.Body>
                        </Table>
                    </Tab.Pane>
            },
            {
                menuItem: 'Form of Payment', pane:
                    <Tab.Pane attached={false} style={{ border: '0px none', width: '100%' }}>
                        <div className="w3-row" style={{ width: '100%' }}>
                            <div className='w3-col s12 m6 l6'>
                                <Checkbox checked={this.state.isCashSelected} label='Cash' onChange={this.onCashCheckedChanged.bind(this)} />
                            </div>
                            <div className='w3-col s12 m6 l6'>
                                <div style={{ width: '100%', maxWidth: '400px' }}>
                                    <Input style={{ border: '1px solid gray' }} fluid
                                        disabled={!this.state.isCashSelected}
                                        value={this.state.cashAmount} onChange={this.onTextChange.bind(this, 'cashAmount')}
                                        onKeyPress={(e) => handleNumericOnKeyPress(e)}
                                        onKeyDown={(e) => handleNumericOnKeyDown(e)}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="w3-row" style={{ width: '100%', marginTop: '5px' }}>
                            <div className='w3-col s12 m6 l6'>
                                <Checkbox checked={this.state.isPostalMoneySelected} label='Postal Money Order' onChange={this.onPostalCheckedChanged.bind(this)} />
                            </div>
                            <div className='w3-col s12 m6 l6'>
                                <div style={{ width: '100%', maxWidth: '400px' }}>
                                    <Input style={{ border: '1px solid gray' }} fluid
                                        disabled={!this.state.isPostalMoneySelected}
                                        value={this.state.postalMoneyAmount} onChange={this.onTextChange.bind(this, 'postalMoneyAmount')}
                                        onKeyPress={(e) => handleNumericOnKeyPress(e)}
                                        onKeyDown={(e) => handleNumericOnKeyDown(e)}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="w3-row" style={{ width: '100%', marginTop: '5px' }}>
                            <div className='w3-col s12 m6 l6'>
                                <Checkbox checked={this.state.isCheckSelected} label='Check' onChange={this.onCheckCheckedChanged.bind(this)} />
                            </div>
                            <div className='w3-col s12 m6 l6'>
                                <div style={{ width: '100%', maxWidth: '400px' }}>
                                    <Input style={{ border: '1px solid gray' }} fluid
                                        disabled={!this.state.isCheckSelected}
                                        value={this.state.checkAmount} onChange={this.onTextChange.bind(this, 'checkAmount')}
                                        onKeyPress={(e) => handleNumericOnKeyPress(e)}
                                        onKeyDown={(e) => handleNumericOnKeyDown(e)}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="w3-row" style={{ width: '100%', marginTop: '5px' }}>
                            <div className='w3-col s12 m6 l6'>
                                &nbsp; &nbsp; &nbsp; &nbsp; <label>Check Number</label>
                            </div>
                            <div className='w3-col s12 m6 l6'>
                                <div style={{ width: '100%', maxWidth: '400px' }}>
                                    <Input style={{ border: '1px solid gray' }} fluid
                                        disabled={!this.state.isCheckSelected}
                                        value={this.state.checkNumber} onChange={this.onTextChange.bind(this, 'checkNumber')}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="w3-row" style={{ width: '100%', marginTop: '5px' }}>
                            <div className='w3-col s12 m6 l6'>
                                &nbsp; &nbsp; &nbsp; &nbsp; <label>Check Date</label>
                            </div>
                            <div className='w3-col s12 m6 l6'>
                                <div style={{ width: '100%', maxWidth: '400px' }}>
                                    <Input style={{ border: '1px solid gray' }} fluid
                                        disabled={!this.state.isCheckSelected}
                                        type='date'
                                        min='1900-01-01' max='2999-12-31'
                                        value={this.state.checkDate} onChange={this.onTextChange.bind(this, 'checkDate')}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="w3-row" style={{ width: '100%', marginTop: '5px' }}>
                            <div className='w3-col s12 m6 l6'>
                                &nbsp; &nbsp; &nbsp; &nbsp; <label>Bank Name</label>
                            </div>
                            <div className='w3-col s12 m6 l6'>
                                <div style={{ width: '100%', maxWidth: '400px' }}>
                                    <Input style={{ border: '1px solid gray' }} fluid
                                        disabled={!this.state.isCheckSelected}
                                        value={this.state.bankName} onChange={this.onTextChange.bind(this, 'bankName')}
                                    />
                                </div>
                            </div>
                        </div>

                        <div className="w3-row" style={{ width: '100%', marginTop: '15px' }}>
                            <div className='w3-col s12 m6 l6'>
                                <label>TOTAL AMOUNT</label>
                            </div>
                            <div className='w3-col s12 m6 l6'>
                                <div style={{ width: '100%', maxWidth: '400px' }}>
                                    <Input style={{ border: '1px solid gray' }} fluid
                                        disabled={true}
                                        value={this.state.totalFields.totalAmountPaid}
                                    />
                                </div>
                            </div>
                        </div>
                    </Tab.Pane>
            },
        ]

        return (
            <div>
                <h1>SSS R5</h1>

                <div style={{ display: 'flex', overflowY: 'auto', height: window.innerHeight - 203 }}>
                    <div style={{ marginRight: '10px', width: 300 }}>
                        <div>
                            <label>Company</label>
                            <Select value={this.state.selectedCompany} isLoading={this.state.isCompanyLoading} options={this.state.companyList} onChange={(e) => this.setState({ selectedCompany: e })} />
                        </div>

                        <div style={{ marginTop: 5 }}>
                            <label>Type Of Payor</label>
                            <Select value={this.state.selectedPayor} options={payorOptions} onChange={(e) => this.setState({ selectedPayor: e })} />
                        </div>

                        <div style={{ marginTop: 5 }}>
                            <label>Year</label>
                            <Input fluid value={this.state.year}
                                onChange={(e) => this.setState({ year: e.target.value })}
                                onKeyPress={(e) => handleNumericOnKeyPress(e)}
                                onKeyDown={(e) => handleNumericOnKeyDown(e)}
                            />
                        </div>

                        <div style={{ display: 'flex', float: 'right', right: 0 }}>
                            <Button style={{ marginTop: 10 }} content='Generate' floated='right' loading={this.state.isGenerateLoading} onClick={this.onGenerateClick.bind(this)} />
                            <Button style={{ marginTop: 10 }} content='Export To PDF' floated='right' loading={this.state.isExportLoading} onClick={this.onExportPDFClick.bind(this)} />
                        </div>
                    </div>

                    <div style={{ width: window.innerWidth - 600, height: window.innerHeight - 203, overflowX: 'auto', overflowY: 'auto' }}>
                        <Tab renderActiveOnly={false} style={{ minWidth: 700 }} menu={{ color: 'green', tabular: false, attached: false }}
                            panes={tabSelection} activeIndex={this.state.activeIndex} onTabChange={this.handleTabChange} />
                    </div>
                </div>
            </div>
        )
    }
}
import React, { Component } from 'react';
import { ajaxPost } from '../../ajax';
import ErrorMessage from '../../Commons/ErrorMessage';
import { Modal, Button, Input, Dropdown, Popup, Table } from 'semantic-ui-react';
import 'semantic-ui-css/semantic.min.css';

var typeOptions = [
    { key: 'FIXED_AMOUNT', text: 'Fixed Amount', value: 'FIXED_AMOUNT' },
    { key: 'PERCENTAGE', text: 'Percentage', value: 'PERCENTAGE' },
]

export default class GovernmentTableBase extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loadedData: [],
            headers: this.props.headers,
            isResetting: false,
            eeSuffix: this.props.eeSuffix,
            erSuffix: this.props.erSuffix,
            resetModalOpen: false,
            form: {
                isOpen: false,
                isSaving: false,
                isAdd: true,
                year: this.props.year,
                action: '',
                modelCollection: [],
                errorCollection: {}
            },
            warningModal: {
                isOpen: false,
                title: 'Warning',
                description: '',
            },
            errorMessage: {
                isOpen: false,
                errCaption: '',
                errTitle: ''
            }
        }
    }

    componentDidMount() {
        this.loadData();
    }

    loadData() {
        var resetButtonEvent = this.props.resetButtonEnable;
        ajaxPost({
            url: this.props.loadTableUrl,
            data: {
                year: this.props.year,
                type: this.props.type
            },
            onSuccess: (data) => {
                if( this.props.loadDataCallback !== undefined ){
                    this.props.loadDataCallback( data );
                }
                this.setState({ loadedData: data.Content }, () => resetButtonEvent(data.HasDefaultValue))
            },
            finally: () => {
            }
        })
    }

    saveNewTable = (ignoreWarning) => {
        var {form,errorMessage} = this.state; 
        form.isSaving = true;
        this.setState({ form })
        ajaxPost({
            url: this.props.saveTableUrl,
            data: {
                prevYear: this.props.year,
                currYear: form.year,
                isAdd: form.isAdd,
                data: form.modelCollection,
                ignoreWarning: ignoreWarning,
                type: this.props.type
            },
            onSuccess: (data) => {
                if ('warning' in data) {
                    var { warningModal } = this.state;
                    warningModal.isOpen = true;
                    warningModal.description = data['warning'];
                }
                else if('error' in data){
                    errorMessage.isOpen = true;
                    errorMessage.errTitle = 'Invalid';
                    errorMessage.errCaption = data['error'];
                    this.setState({errorMessage});
                    return;
                }
                else {
                    this.loadData();
                    form.isOpen = false;
                    if (ignoreWarning) {
                        var { warningModal } = this.state;
                        warningModal.isOpen = false;
                        this.setState({ warningModal })
                    }
                }

            },
            finally: () => {
                form.isSaving = false;
                this.setState({ form });
            }
        })
    }

    handleModalSave = (ignoreWarning, e) => {
        var { form, errorMessage } = this.state;
        var hasError = false;

        if (form.modelCollection.length == 0) {
            errorMessage.isOpen = true;
            errorMessage.errTitle = 'Invalid';
            errorMessage.errCaption = 'Please add atleast 1 row.'
            this.setState({ errorMessage });
            return;
        }

        form.modelCollection.forEach((x, index) => {
            Object.keys(x).forEach(y => {
                if (x[y] === null || (x[y] === '')) {
                    form.errorCollection[y + index] = true;
                    hasError = true;
                }
            });
        })

        if (hasError) {
            errorMessage.isOpen = true;
            errorMessage.errTitle = 'Invalid';
            errorMessage.errCaption = 'Please fill up all fields.';
            this.setState({ form, errorMessage });
            return;
        }

        form.modelCollection.forEach(x => {
            x['Year'] = this.state.form.year;
        });

        this.setState({ form }, () => this.saveNewTable(ignoreWarning))
    }

    reset = () => {
        this.setState({ isResetting: true })
        ajaxPost({
            url: this.props.resetTableUrl,
            data: {
                year: this.props.year,
                type: this.props.type
            },
            onSuccess: (data) => {
                alert("Successfully Reset");
                this.loadData();
            },
            finally: () => {
                this.setState({ isResetting: false, resetModalOpen: false })
            }
        })
    }

    handleReset = () => {
        this.setState({ resetModalOpen: true })
    }

    createDefaulValue() {
        var model = {};
        this.state.headers.forEach(x => {
            if (x === "Type")
                model[x] = 'FIXED_AMOUNT'
            else
                model[x] = 0
        })

        return model;
    }

    createTaxDefaultValue() {
        return {
            Type: this.props.type,
            Code: 'CL',
            Range: 0,
            BaseAmount: 0,
            BracketExcessPercentage: 0,
        };
    }

    createNewTable = () => {
        var { form, errorMessage } = this.state;
        form.isOpen = true;
        form.year = this.props.year;
        form.modelCollection = [];
        if (this.props.overridingHeaders === undefined)
            form.modelCollection.push(this.createDefaulValue());
        else {
            for (let index = 0; index < 6; index++) {
                const header = this.props.overridingHeaders[index];
                var item = this.createTaxDefaultValue();
                item.BaseAmount = header["additional"];
                item.BracketExcessPercentage = header["percentage"];
                form.modelCollection.push(item);
            }
        }
        form.action = 'Create New';
        form.isAdd = true;

        Object.keys(form.errorCollection).forEach(x => {
            form.errorCollection[x] = false;
        })

        errorMessage.isOpen = false;
        this.setState({ form, errorMessage });
    }

    editTable = () => {
        var { form, loadedData, errorMessage } = this.state;
        form.isOpen = true;
        form.year = this.props.year;
        form.modelCollection = JSON.parse(JSON.stringify(loadedData));
        form.action = 'Edit Table';
        form.isAdd = false;

        Object.keys(form.errorCollection).forEach(x => {
            form.errorCollection[x] = false;
        })

        errorMessage.isOpen = false;
        this.setState({ form, errorMessage });
    }

    handleModalAction = (isOpen) => {
        var { form } = this.state;
        form.isOpen = isOpen;
        this.setState({ form });
    }

    handleYearChanged = (e) => {
        var { form } = this.state;
        form.year = e.target.value;
        this.setState({ form });
    }

    onCollectionChanged = (fieldName, index, e) => {
        var { form } = this.state;

        if (e.target.value.length > 10) {
            form.errorCollection[fieldName + index] = true; 
            this.setState({ form });
            return;
        }
 
        form.errorCollection[fieldName + index] = false; //return false the cell that has an error
        form.modelCollection[index][fieldName] = e.target.value;
        this.setState({ form });
    }

    onTypeChanged = (index, e, val) => {
        var { form } = this.state;
        form['Type' + index] = false; //return false the cell that has an error
        form.modelCollection[index]['Type'] = val.value;
        this.setState({ form });
    }

    onAddRow = (index) => {
        var { form } = this.state;
        form.modelCollection.splice(index + 1, 0, this.createDefaulValue());
        this.setState({ form });
    }

    onDeleteRow = (index) => {
        var { form } = this.state;
        form.modelCollection.splice(index, 1);
        this.setState({ form });
    }

    handleWarningAction = (isOpen) => {
        var { form } = this.state;
        if (!isOpen) {
            form.modelCollection.forEach(x => {
                delete x["Year"]
            });
        }

        var { warningModal } = this.state;
        warningModal.isOpen = isOpen;
        this.setState({ warningModal, form })
    }

    render() {
        return (
            <div>
                <Table celled inverted selectable>
                    <Table.Header>
                        <Table.Row>
                            {this.props.overridingHeaders !== undefined && <Table.HeaderCell />}
                            {this.props.overridingHeaders === undefined && this.state.headers.map(x => {
                                return (
                                    <Table.HeaderCell>{x}</Table.HeaderCell>
                                )
                            })}
                            {this.props.overridingHeaders !== undefined && this.props.overridingHeaders.map((x, index) => {
                                return (
                                    <Table.Cell textAlign='right'>
                                        <div>{index + 1}</div>
                                        <div>{x['additional'].toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,')}</div>
                                        <div>+{x['percentage']}% over</div>
                                    </Table.Cell>
                                )
                            })}
                        </Table.Row>
                    </Table.Header>

                    <Table.Body>
                        {this.props.overridingHeaders === undefined && this.state.loadedData !== undefined && this.state.loadedData.map(x => {
                            return (<Table.Row>
                                {Object.keys(x).filter(x => x !== "_id").map(y => {
                                    return (
                                        <Table.Cell textAlign='right'>{x[y]} {y === "EE" ? this.state.eeSuffix : y === "ER" ? this.state.erSuffix : ''}</Table.Cell>
                                    )
                                })}
                            </Table.Row>)
                        })}

                        {this.props.overridingHeaders !== undefined && <Table.Row>
                            {this.state.loadedData !== undefined && this.state.loadedData.length > 0 && <Table.Cell textAlign='right'>{this.state.loadedData[0]['Code']}</Table.Cell>}
                            {this.state.loadedData !== undefined && this.state.loadedData.map(x => {
                                return (
                                    <Table.Cell textAlign='right'>{x['Range']}</Table.Cell>
                                )
                            })}
                        </Table.Row>}
                    </Table.Body>
                </Table>

                {this.state.form.isOpen && <Modal open closeOnDimmerClick={false} onClose={() => this.handleModalAction(false)}>
                    <Modal.Header>{this.state.form.action}</Modal.Header>
                    <Modal.Content>
                        {this.state.errorMessage.isOpen &&
                            <div style={{ marginBottom: '10px' }}>
                                <ErrorMessage
                                    title={this.state.errorMessage.errTitle}
                                    caption={this.state.errorMessage.errCaption}
                                    open={this.state.errorMessage.isOpen}
                                />
                            </div>
                        }
                        <Input label='Year' value={this.state.form.year} onChange={this.handleYearChanged} />
                        <Table>
                            <Table.Header>
                                <Table.Row>
                                    {this.state.headers.map(x => {
                                        return (
                                            <Table.HeaderCell>{x}</Table.HeaderCell>
                                        )
                                    })}
                                    {this.props.overridingHeaders === undefined && <Table.HeaderCell>Action</Table.HeaderCell>}
                                </Table.Row>
                            </Table.Header>
                            {this.props.overridingHeaders === undefined && this.state.form.modelCollection.map((x, index) => {
                                return (
                                    <Table.Row>
                                        {Object.keys(x).filter(x => x !== "_id").map(y => {
                                            return (                                                
                                                <Table.Cell>
                                                    {y === 'Type' && <Dropdown selection fluid options={typeOptions} value={x[y]} error={this.state.form.errorCollection[y + index]} onChange={this.onTypeChanged.bind(this, index)} />}
                                                    {y !== 'Type' && <Input type='number' style={{width:'8em'}} fluid value={x[y]} error={this.state.form.errorCollection[y + index]} onChange={this.onCollectionChanged.bind(this, y, index)} />}
                                                </Table.Cell>
                                            )
                                        })}
                                        {this.props.overridingHeaders === undefined && <Table.Cell>
                                            <Popup content='Add after this row' trigger={<Button basic size='mini' icon='add' onClick={() => this.onAddRow(index)} />} />
                                            {index > 0 && <Popup content='Delete this row' trigger={<Button basic size='mini' icon='delete' onClick={() => this.onDeleteRow(index)} />} />}
                                        </Table.Cell>}
                                    </Table.Row>
                                )
                            })}
                            {this.props.overridingHeaders !== undefined && <Table.Row>
                                {this.state.form.modelCollection.map((x, index) => {
                                    return (
                                        <Table.Cell width='1' textAlign='right'>
                                            {this.props.overridingHeaders !== null &&
                                                <div>
                                                    <Input type='number' error={this.state.form.errorCollection['BaseAmount' + index]} fluid value={x['BaseAmount']} onChange={this.onCollectionChanged.bind(this, 'BaseAmount', index)} />
                                                    <Input type='number' error={this.state.form.errorCollection['BracketExcessPercentage' + index]} fluid value={x['BracketExcessPercentage']} labelPosition='right' label="% over" onChange={this.onCollectionChanged.bind(this, 'BracketExcessPercentage', index)} />
                                                </div>
                                            }
                                            <Input type='number' error={this.state.form.errorCollection['Range' + index]} fluid value={x['Range']} onChange={this.onCollectionChanged.bind(this, 'Range', index)} />
                                        </Table.Cell>
                                    )
                                })}
                            </Table.Row>}
                        </Table>
                    </Modal.Content>
                    <Modal.Actions>
                        <Button basic onClick={this.handleModalSave.bind(this, false)} loading={this.state.form.isSaving} content='Save' />
                        <Button basic onClick={() => this.handleModalAction(false)} content='Cancel' />
                    </Modal.Actions>
                </Modal>}

                {this.state.warningModal.isOpen && <Modal open closeOnDimmerClick={false} onClose={() => this.handleWarningAction(false)}>
                    <Modal.Header>{this.state.warningModal.title}</Modal.Header>
                    <Modal.Content>
                        {this.state.warningModal.description}
                    </Modal.Content>
                    <Modal.Actions>
                        <Button basic onClick={this.handleModalSave.bind(this, true)} loading={this.state.form.isSaving} content='Save' />
                        <Button basic onClick={() => this.handleWarningAction(false)} content='Cancel' />
                    </Modal.Actions>
                </Modal>}

                {this.state.resetModalOpen && <Modal open closeOnDimmerClick={false} onClose={() => this.setState({ resetModalOpen: false })}>
                    <Modal.Header>Reset Table</Modal.Header>
                    <Modal.Content>
                        Any changes in this year will be revert to it's original value. Do you want to proceed?
                    </Modal.Content>
                    <Modal.Actions>
                        <Button basic onClick={this.reset} loading={this.state.form.isResetting} content='Save' />
                        <Button basic onClick={() => this.setState({ resetModalOpen: false })} content='Cancel' />
                    </Modal.Actions>
                </Modal>}
            </div>

        )
    }
}
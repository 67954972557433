import React, { Component } from 'react';
import 'semantic-ui-css/semantic.min.css';
import { Modal, Button, Checkbox, Input } from 'semantic-ui-react';
import Select from 'react-select';

const moment = require('moment');

function monthList(){
    var Month = [];
    Month.push({label:"January",value:"1"});
    Month.push({label:"February",value:"2"});
    Month.push({label:"March",value:"3"});
    Month.push({label:"April",value:"4"});
    Month.push({label:"May",value:"5"});
    Month.push({label:"June",value:"6"});
    Month.push({label:"July",value:"7"});
    Month.push({label:"August",value:"8"});
    Month.push({label:"September",value:"9"});
    Month.push({label:"October",value:"10"});
    Month.push({label:"Novemebr",value:"11"});
    Month.push({label:"December",value:"12"});
    return Month;
}
class TenureReportModal extends Component {

    constructor(props) {
        super(props);
        this.state = {
            includeDeleted:false,
            selectedMonth:null,
            selectedYear:"",
            tenure:"",
            includeInactive:false,
        }
    }
    getParameters = () => {
        const monthNames = ["January", "February", "March", "April", "May", "June",
            "July", "August", "September", "October", "November", "December", "All Months","Unspecified"
        ];
        return {
            message: "from promoted employee report modal",
            Month: this.state.selectedMonth==null? "": this.state.selectedMonth.value,
            Year:this.state.selectedYear,
            Tenure: this.state.tenure,
            includeDeleted:this.state.includeInactive,
            startingIndex:0,
            itemCount:20,
            FilterDetails: "As of " + (this.state.selectedMonth==null?"":monthNames[this.state.selectedMonth.value-1] + " "+ this.state.selectedYear) + "\n"+ "Tenure Year: " + (this.state.tenure == null?"": this.state.tenure)
        };
    }

    handleComboChange = (value) => {
        this.setState({ selectedMonth : value})
    }
    handleTextChange = (value) => {
        this.setState({ selectedYear : value.target.value})
    }
    handleTenureChange = (value) => {
        this.setState({ tenure : value.target.value})
    }

    onChkCheckChange = (e) => {
        this.setState({ includeInactive: !this.state.includeInactive })
    }
    render() {
        return (
            <Modal open={this.props.open} size="tiny">
                <Modal.Header>
                    <p>Employee Tenure Report</p>
                </Modal.Header>
                <Modal.Content>
                    <div class="ui grid">
                        <div class="left floated left aligned sixteen wide column">
                        <label>Month</label>
                            <Select fluid placeholder='Month' name="MonthList"
                                onChange={this.handleComboChange.bind(this)}  
                                options={monthList()}                      
                                value={this.state.selectedMonth}/>
                            <Input fluid placeholder='Year' size="mini"
                                onChange={this.handleTextChange.bind(this)}                        
                                value={this.state.selactedYear}
                                type ='number'
                                style={{margin:"10px"}}/>
                            <Input fluid placeholder='Tenure Year' size="mini"
                                onChange={this.handleTenureChange.bind(this)}                        
                                value={this.state.tenure}
                                type ='number'
                                style={{margin:"10px"}}/>
                            <Checkbox size='mini' 
                                label="Include Resigned" 
                                onChange={this.onChkCheckChange.bind(this)} 
                                checked={this.state.includeInactive} />
                        </div>
                    </div>
                </Modal.Content>
                <Modal.Actions>
                    <Button basic content="Generate" onClick={this.props.onSave}></Button>
                    <Button basic content="Cancel" onClick={this.props.onCancel}></Button>
                </Modal.Actions>
            </Modal>
        );
    }

}

export default TenureReportModal;
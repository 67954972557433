export function selectionList(empList, fields, value) {
    var list = [];
    if (empList != null) {
      empList.map((x) => {
        return list.push({
          label: fields.map((z) => {
            return x[z] + " ";
          }),
          value: x[value],
        });
      });
    }
    return list;
  }
  


import React, { Component } from 'react';
import { Button, Input, Modal,Dropdown } from 'semantic-ui-react';
import 'semantic-ui-css/semantic.min.css';
import ErrorMessage from '../../Commons/ErrorMessage';
import CustomSelect from '../../Commons/CustomSelect';
import Select from 'react-select';
import SelectEmployees from '../../Commons/SelectEmployees';
import moment from 'moment';
import { ajaxPost } from '../../ajax';
import { empty_id,checkDate,customStyles,errorStyles } from '../../utils';
const someMarginNoPadding = { "margin": "0px 0px 10px 0px", "padding" : "0px" }
const noMarginNoPadding = { "margin": "0px", "padding" : "0px" }

function dropdownMappingBank(list) {
    var profiles = [];
    if (list != null) {
        list.map(x => {
            return (
                profiles.push({ label: x.Description, value: x.id })
            )
        })
    }
    return profiles;
}

class BankForm extends Component{
    constructor(props){
        super(props);
        this.state = {
            action: this.props.action,
            Model: this.getDefaultValues(this.props.action,this.props.CutoffId),
            Banks:[],
            selectedBank:null
        }

        
    }
    componentDidMount(){
        this.loadPayrollBanks( data =>{
            this.setState({Banks:data,
                selectedBank: this.state.Model.BankId !== empty_id? dropdownMappingBank(data).find(x=> x.value === this.state.Model.BankId): null
            });
        });
    }
    loadPayrollBanks =  (callback) =>{
        ajaxPost({
            url: 'api/PayrollBank/payrollBankNames',
            data: {
                "Description": 1,
                "_id": 1,
            },
            onSuccess: data => {
                callback(data);
            },
            finally: () => { }
        })
    }
    getDefaultValues = (action,cutoffId) => {
        if(action =="BANK_REMITTANCE")
        {
            return {
                BankId:empty_id,
                CutoffId:cutoffId,
                EffectivityDate:  moment().format("YYYY-MM-DD"),
                BatchNumber:"",
                CeilingAmount: "",
                EmployeeIds: [],  
            }
        }
        else {
            return {
                BankId:empty_id,
                CutoffId:cutoffId,
                BankLetterDate:  moment().format("YYYY-MM-DD"),
                EmployeeIds: [],  
            }
        }
    }

    setContent = (data) => {
        this.setState({
            Model: data === null || data === undefined ? this.getDefaultValues() : data
        });
    }

    getContent = () => {
        if(this.state.action =="BANK_REMITTANCE"){
            return {
                BankId: this.state.selectedBank == null ? empty_id : this.state.selectedBank.value,// this.state.Model.BankId,
                EffectivityDate: this.state.Model.EffectivityDate,
                EmployeeIds: this.state.Model.EmployeeIds,
                BatchNumber:"",
                CeilingAmount:"",
                CutoffId: this.state.Model.CutoffId
            };
        }else{

            return {
                BankId:this.state.selectedBank == null ? empty_id : this.state.selectedBank.value,// this.state.Model.BankId,
                BankLetterDate: this.state.Model.BankLetterDate,
                EmployeeIds: this.state.Model.EmployeeIds,
                CutoffId: this.state.Model.CutoffId
            };
        }
    }

    handleChange = name => event => {
        var current = this.state.Model;
        current[name] = event.target.value;
        this.setState({ Model: current });
    };

    handleComboChange = (name, val) => {
        var current = this.state.Model;
        current[val.name] = val.value;
        this.setState({ Model: current });
    }
    handleSelectChange = (name, evt) => {
        var current = this.state.Model;
        if (!(evt === undefined || evt === null)) {
            current[name] = evt.value;
        }
        else {
            current[name] = null;
        }
        this.setState({ Model: current });
    }
    handleValueChange = (name, errName, evt) => {
        if (!(evt === undefined || evt === null))
            this.setState({ [errName]: false });
        else
            this.setState({ [errName]: true });
        
        this.setState({[name]: evt});
    }
    updateEmployeeIds = (ids) => {
        var current = this.state.Model;
        current.EmployeeIds = ids;
        this.setState({ Model: current });
    }
  
    render(){
        var data = this.state;
        var {selectedBank} = this.state;
        return(
            <Modal size="tiny" open={this.props.open}>
                <Modal.Header>
                    Bank Parameters
                </Modal.Header>
                <Modal.Content>
                    {
                        this.props.showRequiredField &&
                        <div className="ui one column grid" style={noMarginNoPadding}>
                            <div className="column" style={someMarginNoPadding}>
                                <ErrorMessage  
                                    title={this.props.errTitle}
                                    caption={this.props.errCaption}
                                    open={this.props.showRequiredField}
                                />
                            </div>
                        </div>
                    }
                    <div className="ui one column grid" style={noMarginNoPadding}>
                        <div className="column" style={someMarginNoPadding}>
                            <div>Bank</div>
                                   <Select size='mini' fluid
                                    placeholder="Bank"
                                    value={selectedBank}
                                    disabled={this.props.isBusy} 
                                    styles={this.props.showRequiredField && !selectedBank ? errorStyles : customStyles}
                                    options={dropdownMappingBank(data.Banks)}
                                    onChange={this.handleValueChange.bind(this, 'selectedBank','')}
                                />
                        </div>
                    </div>
                   {this.state.action =="BANK_SUMMARY" && <div className="ui one column grid" style={noMarginNoPadding}>
                        <div className="column" style={someMarginNoPadding}>
                            <div>Bank Letter Date</div>
                            <Input 
                                fluid 
                                value={data.Model.BankLetterDate} 
                                disabled={this.props.isBusy} 
                                type="date"
                                min="1900-01-01"
                                max="2999-12-31"
                                error={this.props.showRequiredField && !checkDate(data.Model.BankLetterDate).Result}
                                onChange={this.handleChange("BankLetterDate").bind(this)} 
                            />
                        </div>
                    </div>
                   }                 
                     {this.state.action =="BANK_REMITTANCE" && <div className="ui one column grid" style={noMarginNoPadding}>
                        <div className="column" style={someMarginNoPadding}>
                            <div>Effectivity Date</div>
                            <Input 
                                fluid 
                                value={data.Model.EffectivityDate} 
                                disabled={this.props.isBusy} 
                                type="date"
                                min="1900-01-01"
                                max="2999-12-31"
                                error={this.props.showRequiredField && !checkDate(data.Model.EffectivityDate).Result}
                                onChange={this.handleChange("EffectivityDate").bind(this)} 
                            />
                        </div>
                    </div>
                   }
                      {this.state.action =="BANK_REMITTANCE" && <div className="ui one column grid" style={noMarginNoPadding}>
                        <div className="column" style={someMarginNoPadding}>
                            <div>BatchNumber</div>
                            <Input 
                                fluid 
                                value={data.Model.BatchNumber} 
                                disabled={this.props.isBusy}  
                                onChange={this.handleChange("BatchNumber").bind(this)} 
                            />
                        </div>
                    </div>
                   }
                   {this.state.action =="BANK_REMITTANCE" && <div className="ui one column grid" style={noMarginNoPadding}>
                        <div className="column" style={someMarginNoPadding}>
                            <div>CeilingAmount</div>
                            <Input 
                                fluid 
                                value={data.Model.CeilingAmount} 
                                disabled={this.props.isBusy}  
                                onChange={this.handleChange("CeilingAmount").bind(this)} 
                            />
                        </div>
                    </div>
                   }
                    <div className="ui one column grid" style={noMarginNoPadding}>
                        <div className="column" style={someMarginNoPadding}>
                            <div>Employees</div>
                           <SelectEmployees fluid={true} Ids={this.state.Model.EmployeeIds} disabled={this.props.isBusy} update={this.updateEmployeeIds.bind(this)}
                                />
                        </div>
                    </div>
                </Modal.Content>
                <Modal.Actions>
                    <Button basic icon='arrow alternate circle right outline' content=" Proceed" disabled={this.props.isBusy} loading={this.props.isBusy} onClick={this.props.onSave}></Button>
                    <Button basic icon='cancel' content="Cancel" disabled={this.props.isBusy} onClick={this.props.onCancel}></Button>
                </Modal.Actions>
            </Modal>
        );
    }

}
export default BankForm
import React, { Component } from 'react';
import { Modal, Button, Table, Input, Form, TextArea, Message } from 'semantic-ui-react';
import 'semantic-ui-css/semantic.min.css';
import { ajaxPost } from '../../ajax';
import { checkInt32, } from '../../utils';
import Update from 'immutability-helper';
// import { Editors } from "react-data-grid";
import {ValidateNumber} from '../../utils';

import modStyles from './BreakScheduleForm.module.css'
import RowEditor from './RowEditor';

var moment = require('moment');

const defaultBreak = {
    BreakStart:"12:00 AM",
    MinutesBreak:0,
    Paid: "YES",
    IsDeleted:false
}

function DetailsDisplayFormat(details) {
    var list = [];
    if (details.length !== 0) {
        details.map(x => {
            return (
                list.push({
                BreakStart:  moment("1992-08-22 " + x.BreakStart).format("hh:mm A"),
                MinutesBreak: x.MinutesBreak,
                Paid: x.Paid ? "YES" : "NO",
                IsDeleted:false
                })
            )
        })
    }
    return list;
}
const paidTypes = [
  { id: "YES", value: "YES" },
  { id: "NO", value: "NO" },
];

class BreakScheduleForm extends Component {
    state = {
        breaks: null,
        breakIndexes: [],
        modalOpen: true,
        errorApproved: false,
        errorRemark: false,
        showConflict: false,
        conflict: []
    }
    timeFormatter = ({value,row}) =>{ 
    
        return(
            <div style={{width:'100%'}}>
            <Input fluid type="time" value={value} style={{height:30}} />
            </div>)
    };
    constructor(props) {
        super(props);
        this.state.breaks = this.props.breaks != undefined ? DetailsDisplayFormat(this.props.breaks): [];
        this.state.modalOpen = this.props.modalOpen;

        this.Column = [
            {
                key: 'BreakStart',
                name: 'Break Start'
            },
            {
                key: 'MinutesBreak',
                name: 'Break Minutes'
            },{
                key: 'Paid',
                name:'Paid Break'
            }];
    }

    handleClose = () => {
        this.props.onClose();
    }
    handleSave = () => {
        var breaks = this.state.breaks;
        let conflict = [];
        for(let i = 0; i <= breaks.length -1;i++){
            
           if(breaks[i].MinutesBreak ==="") //empty 
                conflict.push("row: "+ (i +1) + " Break Minutes is empty");
            else
                breaks[i].MinutesBreak = parseInt(breaks[i].MinutesBreak);
            if(breaks[i].BreakStart ==="Invalid date")
                breaks[i].BreakStart = null;
            else{
                var today = moment(new Date()).format("YYYY-MM-DD");           
                breaks[i].BreakStart = moment(today + " " + breaks[i].BreakStart).format("HH:mm:ss")
            }
            breaks[i].Paid =breaks[i].Paid =="YES" ? true :false;
        }
        if(conflict.length > 0){
            this.setState({showConflict:true,conflict:conflict});
        }else{
            this.setState({ conflict: [], showConflict: false });
            this.props.onClose();
             this.props.onCallback(breaks);

        }
        
    
    }
    /*
    handleRowsUpdated = ({ fromRow, toRow, updated }) => {
        let breaks = this.state.breaks.slice();
        var value = updated.BreakStart !=undefined ? updated.BreakStart :
                    updated.MinutesBreak !=undefined ? updated.MinutesBreak:0;
        if(updated.BreakStart !=undefined)
            updated.BreakStart = value;
        if(updated.MinutesBreak !=undefined){
            updated.MinutesBreak =ValidateNumber(value);
        }
        for (let i = fromRow; i <= toRow; i++) {
            let rowToUpdate = breaks[i];
            let updateRow = Update(rowToUpdate, { $merge: updated });
            breaks[i] = updateRow;
        }
        this.setState({ breaks: breaks });
    };
    */
    handleAdd = (event) => {
        let breaks = this.state.breaks;
        breaks.push({
            BreakStart:"12:00 AM",
            MinutesBreak:0,
            Paid: "YES",
            IsDeleted:false
        });
        this.setState({ breaks: breaks });
    }

    onRemove = (index) => {
        var breaks = this.state.breaks;
        breaks.splice( index, 1);
        this.setState({breaks});
    }

    isNumber = (n) => {
        return !isNaN(parseFloat(n)) && isFinite(n);
      }    

    onUpdateRow = (i,values) => {
        if( this.isNumber(values[1]) === false){
            return "Invalid value for minutes break"
        }
        if( parseInt(values[1]) < 0 ){
            return "Minutes break must be non-negative"
        }
        var breaks = [...this.state.breaks];
        var updated = {
            BreakStart:  values[0],
            MinutesBreak: values[1],
            Paid: values[2],
            IsDeleted:false
        }
        breaks.splice( i, 1, updated);
        this.setState({breaks});

        return "";
    }

    render() {
        return (
            <Modal open={this.props.modalOpen} size='tiny' closeOnDimmerClick={false} onClose={this.handleClose}>
                {this.props.title && <Modal.Header>{this.props.title}</Modal.Header>}
                <Modal.Content>
                    <Modal.Description>
                        <div>
                            {this.state.showConflict &&
                                <Message negative>
                                    <Message.Header>Conflict !</Message.Header>
                                    <Message.Content>{this.state.conflict.map(n => {
                                        return (<p>{n}</p>)
                                    })}</Message.Content>
                                </Message>}
                        </div>
                        <div>
                            <Button basic icon="add" content="Add" onClick={this.handleAdd.bind(this)} />
                        </div>
                        <div>
                            <div className={modStyles.table}>
                                <div className={modStyles.table_header}>
                                    <div>Break Start</div>
                                    <div>Break Minutes</div>
                                    <div>Paid Break</div>
                                </div>
                                <div className={modStyles.table_content}>
                                    {
                                        this.state.breaks.map( (x,i)=> {
                                            return <RowEditor 
                                            values={[
                                                moment('1992-08-22 ' + x.BreakStart).format('HH:mm'),
                                                x.MinutesBreak.toString(),
                                                x.Paid
                                            ]}
                                            widths={['8em', '8em', '8em']}
                                            type={['TIME','TEXT','CHECKBOX']}
                                            onRemove={this.onRemove.bind(this,i)}
                                            onSave={this.onUpdateRow.bind(this,i)}
                                            key={`AdditionalBreak${i}`}
                                            />
                                        })
                                    }
                                </div>
                            </div>       
                         </div>

                    </Modal.Description>
                </Modal.Content>
                <Modal.Actions>
                    <Button basic icon='check' content='Save' onClick={this.handleSave.bind(this)}></Button>
                    <Button basic icon='cancel' content='Cancel' onClick={this.handleClose.bind(this)}></Button>
                </Modal.Actions>
            </Modal>
        )
    }
}

export default BreakScheduleForm
import React from 'react';
import 'semantic-ui-css/semantic.min.css';
import ReportModelBase from '../ReportModelBase';
import { Table } from 'semantic-ui-react';
const moment = require('moment');

class EmployeeReportModelGroupByCivilStatus extends ReportModelBase {

    constructor(props){
        super(props);
        this.searchUrl = "api/Reports/employeeReportGroupByCivilStatus";
        this.title = "Employee Report Group by CivilStatus";
        this.exportToExcelUrl = "api/Reports/ExportEmployeeReportGroupByCivilStatusToExcel";
        this.exportToPdfUrl = "api/Reports/ExportEmployeeReportGroupByCivilStatusToPDF";
    }

    toTableRow = (rowData) => {
        return (
            <Table.Row>
                <Table.Cell>{rowData["Employee No"]}</Table.Cell>
                <Table.Cell>{rowData["Employee"]}</Table.Cell>
                <Table.Cell>{rowData["Civil Status"]}</Table.Cell>
            </Table.Row>
        );
    }

    createColumnHeaders = () => {
        return (
            <Table.Row>
                <Table.HeaderCell>Employee No</Table.HeaderCell>
                <Table.HeaderCell>Employee</Table.HeaderCell>
                <Table.HeaderCell>Civil Status</Table.HeaderCell>
            </Table.Row>
        );
    }

}
export default EmployeeReportModelGroupByCivilStatus;
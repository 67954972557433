import React, { Component } from 'react';
import 'semantic-ui-css/semantic.min.css';
import { Modal, Button, Checkbox, Input } from 'semantic-ui-react';
import Select from 'react-select';
import SelectEmployees from '../../../Commons/SelectEmployees';

function empSearchList(empList) {
    var list = [];
    if (empList !== undefined) {
        empList.map(x => {
            var fullname = x.LastName + ', ' + x.FirstName + ' ' + (x.MiddleName === "" ? "" : x.MiddleName[0]);
            
            if(fullname.length > 45)
                fullname = fullname.slice(0,45) + "..." + " (" + x.EmployeeNo + ")";
            else
                fullname = fullname + " (" + x.EmployeeNo + ")";

            return (
                list.push({ label: fullname, value: x._id }))
        })
    }
    return list;
}

class EmploymentHistoryReportModal extends Component {

    constructor(props) {
        super(props);
        this.state = {
            filterEmpIds: [],
        }
    }

    getParameters = () => {
        return {
            employeeIds: this.state.filterEmpIds
        };
    }

    updateEmpIds = (ids) => {
		this.setState({ filterEmpIds: ids });
	}

    render() {
        return (
            <Modal open={this.props.open} size="tiny">
                <Modal.Header>
                    <p>Employee </p>
                </Modal.Header>
                <Modal.Content>
                    <SelectEmployees Ids={this.state.filterEmpIds} update={this.updateEmpIds.bind(this)} style={{ minWidth: '50px', maxWidth: '100%', width: '100%' }} />
                </Modal.Content>
                <Modal.Actions>
                    <Button basic content="Generate" onClick={() => this.props.callback(this.state.filterEmpIds)}></Button>
                    <Button basic content="Cancel" onClick={this.props.onCancel}></Button>
                </Modal.Actions>
            </Modal>
        );
    }

}

export default EmploymentHistoryReportModal;
import React, { Component } from 'react';
// import { Checkbox, Radio, Modal, Menu } from 'semantic-ui-react';
import 'semantic-ui-css/semantic.min.css';
import Select from 'react-select';
import { fnSearchEmployeeFilter, customStyles, errorStyles } from '../utils';
import { ajaxPost } from '../ajax';
import { TableCell } from 'semantic-ui-react';
//import CustomSelect from './CustomSelect';

function employeeSearchlist(list) {
    var employees = [];
    if (list != null) {
        list.map(x => {
            return (
                employees.push({ label: x.EmployeeName, value: x._id })
            )
        })
    }
    return employees;
}

class CutoffEmployeeSelection extends Component {
    state = {
        Employeelist: [],
        EmployeeIds: [],
        searchQuery: '',
        errorStyle: false,
        isMulti:true,
        DepartmentIds: [],
        PositionIds: [],
        LocationIds: [],
        CostCenterIds: [],
        isNew: false
    }
    constructor(props) {
        super(props);
        this.state = {
            // loadedIds: this.props.Ids,
            EmployeeIds: [],
            isLoading: false,
            includeDeleted: this.props.includeDeleted !== undefined ? this.props.includeDeleted : false,
            errorStyle: this.props.errorStyle !== undefined ? this.props.errorStyle : false,
            isMulti: this.props.isMulti !== undefined ? this.props.isMulti :true,
            DepartmentIds: props.departmentIds != undefined ? props.departmentIds : [],
            PositionIds: props.positionIds != undefined ? props.positionIds : [],
            LocationIds: props.locationIds != undefined ? props.locationIds : [],
            CostCenterIds: props.costcenterIds != undefined ? props.costcenterIds : [],
            isNew : props.isNew !== undefined ? props.isNew : false
        }
        this.props.refreshSelection(this.doSearch);
    }
    componentWillMount() {
        this.setState({ isLoading: true });
        this.doSearch("", this.state.loadedIds);

        this.searchTimer = null;
    }
    handleSearchChange = (text) => {
        clearTimeout(this.searchTimer);

        if(this.state.searchQuery !== text){
            this.setState({ searchQuery: text, includeDeleted: this.props.includeDeleted !== undefined ? this.props.includeDeleted : false })
            this.searchTimer = setTimeout(this.doSearch, 400, text);
        }
    }
    doSearch = (str, initialIds) => {
        const { EmployeeIds, Employeelist } = this.state;
        var selectedEmpIds = [];
         if (EmployeeIds !== null && EmployeeIds !== undefined && Employeelist !== undefined)
            selectedEmpIds = Employeelist.filter(x => EmployeeIds.map(x => x.value).includes(x._id)).map(x => x._id);
            

        this.setState({ searchQuery: str, isLoading: true },
            () =>{
                ajaxPost({
                    url: "api/Employee/searchCutoffEmployeeSelection",
                    data: {
                        "Search": str,
                        "StartingIndex": 0,
                        "ItemCount": 10,
                        "Type": this.props.type,
                        "Status": this.props.status,
                        "CompanyId": this.props.companyId,
                        "CutoffId": this.props.cutoffId,
                        "ExcludedEmployeeIds": selectedEmpIds,
                        "DepartmentIDs": this.props.departmentIds,
                        "PositionIDs": this.props.positionIds,
                        "CostCenterIDs": this.props.costcenterIds,
                        "CityIDs": this.props.locationIds,
                        "ShowNewEmployee": this.state.isNew
                    },
                    onSuccess: data => {
                        this.setState({ Employeelist: data.content, isLoading: false });
                        if(initialIds !== undefined && initialIds.length > 0)
                            this.setState({EmployeeIds: employeeSearchlist(data.content)}, () => this.doSearch(""))
                    },
                    finally: () => { }
                })
            }
        )
    }

    handleSearchEmployeeChange = (value) => {
        var ids = [];
        var values = [];
        if(this.state.isMulti){
            if (value != null && !(value === "" || value.length === 0))
                ids = value.map(x => x.value);
            values = value;
        }else{
            ids.push(value.value);
            values.push(value);
        }

        this.setState({ EmployeeIds: values, searchQuery: "" }, () => this.doSearch(""));
        this.props.update(ids);
    }

    render() {
        return (
            <div style={{width: this.props.width}}>
                <Select placeholder='Employees'
                    onChange={this.handleSearchEmployeeChange.bind(this)}
                    options={employeeSearchlist(this.state.Employeelist)}
                    onInputChange={this.handleSearchChange.bind(this)}
                    styles={this.props.errorStyle ? errorStyles : customStyles}
                    value={this.state.isMulti ? this.state.EmployeeIds : this.state.EmployeeIds[0]}
                    isMulti={this.state.isMulti}
                    isDisabled={this.props.disabled === undefined ? false : this.props.disabled}
                    isLoading={this.state.isLoading}
                    searchQuery={this.props.searchQuery}
                />
            </div>
            
        )
    }

}
CutoffEmployeeSelection.defaultProps = {
    errorStyle: false
}
export default CutoffEmployeeSelection
import React, {Component } from 'react';
import { Loader,Card,Table,Grid } from 'semantic-ui-react';
import 'semantic-ui-css/semantic.min.css';
// import "react-table/react-table.css";
import { ajaxPost } from '../../../ajax';

import { isTaskAvailable,ToRound } from '../../../utils';

class LeaveConversionList extends Component{
    state = {
        Parameters: {
            CutoffId: "-1",
            needle: "",
            startingIndex: 0,
            itemCount: 20,  
            totalPages: 1          
        },
        loading: false,
        initialLoad: true,
        ActivePage: 1,
        PageSize: 100,
        LeaveConversionSummary:[],
        Payroll:[],
        Restrict_Column: false
    }

    constructor(props) {
        super(props);
        this.state.Parameters = this.props.Parameters;  
        this.state.initialLoad = this.props.initialLoad;
        this.state.Payroll = this.props.Payroll;
        // this.state.DTRSummary = this.props.DTRSummary; 
        // var showRestrictColumns =isTaskAvailable("CUTOFF_DTR_RESTRICT_COLUMN");
        // this.setState({Restrict_Column:showRestrictColumns});
        // this._columns = [
        //     { key: 'EmployeeNumber', name: 'Employee No', width: 100, resizable: true },
        //     { key: 'Employee', name: 'Employee', width: 150, resizable: true },
        //     { key: 'LeaveType', name: 'Leave Type', width: 150},
        //     { key: 'Count', name: 'Count', width: 70},
        //     { key: 'Amount', name: 'Amount', width: 80},
        //     { key: 'Month', name: 'Month', width: 110},
        //     { key: 'Year', name: 'Year', width: 70},
        //     // { key: 'Posted', name: 'Posted', width: 150},        
        //     { key: 'Remarks', name: 'Remarks'},        
        // ];
    }

    componentWillMount() {
        if (this.state.initialLoad === true) {
            var param = this.state.Parameters;
            this.onSearch(param);
        }
    }

    rowGetter = (i) => {
        return this.state.LeaveConversionSummary[i];
    };

    onSearch = (parameter) => {
        this.setState({ loading: true }, () =>{
            this.LoadCutoffSummaries(data => {
                var totalPages = data.Count / this.state.Parameters.itemCount;
                totalPages -= totalPages % 1;
                if (data.Count > totalPages * this.state.Parameters.itemCount) {
                    totalPages++;
                }
                var parameters = this.state.Parameters;
                parameters.totalPages = totalPages;
                this.setState({
                    Payroll: data.Content,
                    loading: false,
                    initialLoad: false,
                    Parameters: parameters,
                });
                this.props.updateState(this.state, "PayrollList");
            }, parameter);
        });
       
    }
    LoadCutoffSummaries = (callback, parameters, startIndex, itemCount) => {
        ajaxPost({
            url: 'api/Cutoff/LoadLeaveConversionSummary',
            data: {
            "Parameters": JSON.stringify(parameters)
        },
        onSuccess: data => {
            callback(data);
        },
            finally: () => { }
        })
    }

    mapData = (summaries) => {
        var SummaryViews = []
        var rowCount =0;
        summaries.forEach( sum => {            
            var summary = {
                employeeNumber: sum.EmployeeNo,
                employee: sum.Employee,
                leaveType: sum.LeaveType,
                count: sum.Convertibles,
                amount: sum.Amount,
                month: sum.Month,
                year: sum.Year,
                remarks: sum.Remarks
            }
            SummaryViews.push(summary);      
        });
        this.setState({ LeaveConversionSummary : SummaryViews});
    }

    handleChangePage = (event,page) => {
        const startIndex = page.activePage - 1;
        this.setState({Page:startIndex});
        this.setState({ActivePage:page.ActivePage});
    }
    render() {
        return (
            <div>
                <Loader active={this.state.loading} inline='centered' />

                <Card.Group stackable>
                    {this.state.Payroll != null && this.state.Payroll.map(i => {
                        return (
                            <Card color='olive' fluid style={{ width: '100%' }}>
                                <Card.Content>
                                    <Card.Header>{i.Employee}</Card.Header>
                                    <Card.Description>


                                        <Grid columns={3} divided>
                                            <Grid.Row>
                                                <Grid.Column>
                                                    <Table basic='very'>
                                                        {i.Incomes.map(entry => {
                                                            return (
                                                                <Table.Row>
                                                                    <Table.Cell>
                                                                        {entry.Description}
                                                                    </Table.Cell>
                                                                    <Table.Cell>
                                                                        {entry.DisplayQuantity}
                                                                    </Table.Cell>
                                                                    <Table.Cell>
                                                                        <label>{ToRound(entry.Amount)}</label>
                                                                    </Table.Cell>
                                                                </Table.Row>
                                                            )
                                                        })
                                                        }
                                                    </Table>
                                                </Grid.Column>
                                                <Grid.Column>
                                                    <Table basic='very'>
                                                        {i.Deductions.map(entry => {
                                                            return (
                                                                <Table.Row>
                                                                    <Table.Cell>
                                                                        {entry.Description}
                                                                    </Table.Cell>
                                                                    <Table.Cell>
                                                                        {entry.DisplayQuantity}
                                                                    </Table.Cell>
                                                                    <Table.Cell>
                                                                        <label>{ToRound(entry.Amount)}</label>
                                                                    </Table.Cell>
                                                                </Table.Row>
                                                            )
                                                        })
                                                        }
                                                    </Table>
                                                </Grid.Column>
                                                <Grid.Column>
                                                    <Table basic='very'>
                                                        {i.Summaries.map(entry => {
                                                            return (
                                                                <Table.Row>
                                                                    <Table.Cell>
                                                                        {entry.Description}
                                                                    </Table.Cell>
                                                                    <Table.Cell>
                                                                        {entry.DisplayQuantity}
                                                                    </Table.Cell>
                                                                    <Table.Cell>
                                                                        <label>{ToRound(entry.Amount)}</label>
                                                                    </Table.Cell>
                                                                </Table.Row>
                                                            )
                                                        })
                                                        }
                                                    </Table>
                                                </Grid.Column>
                                            </Grid.Row>
                                        </Grid>
                                    </Card.Description>
                                </Card.Content>
                            </Card>)
                    })}
                </Card.Group>
            </div>
        )
    }
}

export default LeaveConversionList
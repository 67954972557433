import React, { Component } from 'react';
import { Image } from 'semantic-ui-react';
import 'semantic-ui-css/semantic.min.css';


class SimpleTemplate extends Component {

    styles = 
    {
        topMenu:
        {
            background: "black",
            height: "50px",
            position: "fixed",
            top: "0px",
            width: "100%",
        },
        logo:
        {
            height: "30px",
            display: "inline-block",
            marginLeft: "10px",
            marginTop: "5px",
        },
    }

    render = () =>
    {
        return (
        <div style={this.styles.topMenu}>
            <a href='/'><Image src={global.light_logo_url} style={this.styles.logo} /></a>
            <div style={{"margin-top":"10px"}} >
                {this.state.child}
            </div>
        </div>
        )
    }
}

export default SimpleTemplate;
import React, { Component } from 'react';
import { Modal, Button, Input, Checkbox, Form, TextArea, Header, Label } from 'semantic-ui-react';
import moment from 'moment';
import { ajaxPost } from '../../../ajax';
//import Select from 'react-select';
import SelectEmployees from '../../../Commons/SelectEmployees';
import ErrorMessage from '../../../Commons/ErrorMessage';
import MessageBoxYesNo from '../../../Commons/MessageBoxYesNo';
import { isTaskAvailable, checkDate, checkTime, handleNumeric, minDate, maxDate, fnSearchEmployeeFilter, checkInt32, handleNumericOnKeyDown, handleNumericOnKeyPress, } from '../../../utils';
import Select from 'react-select';
// import CustomSelect from '../../../Commons/CustomSelect';

var delayTimer = null;
//const someMarginNoPadding = { "margin": "0px", "padding": "0px, 0px, 5px, 0px" }
const someMarginNoPadding = { "margin": "0px 0px 10px 0px", "padding": "0px" }
const noMarginNoPadding = { "margin": "0px", "padding": "0px" }
const semanticErrorStyle = { 'background-color': '#FFF6F6', 'border-color': '#E0B4B4', 'color': '#9F3A38', '-webkit-box-shadow': 'none', 'box-shadow': 'none' }
const attachLabel = { 'width': '100%', 'display': 'flex', 'justifyContent': 'center', 'alignItems': 'center', 'border': 'solid 1px #e8e8e8', 'borderRadius': '0 4px 4px 0px',
                        'wordWrap': 'break-word', 'wordBreak': 'break-all', 'overflow': 'hidden'}


function empList(empList) {
    var list = [];
    if (empList != null) {
        empList.map(x => {
            return (
                list.push({ label: x.LastName + ', ' + x.FirstName + ' ' + (x.MiddleName === "" ? "" : x.MiddleName[0]) + "(" + x.EmployeeNo + ")", value: x._id }))
        })
    }
    return list;
}

class OvertimeForm extends Component {

    constructor(props) {
        super(props);
        this.state = {
            _id: null,
            EmployeeId: null,
            Date: moment().format("YYYY-MM-DD"),
            StartTime: moment().format("HH:mm"),
            NextDay: false,
            Hours: "00:00", 
            HoursBreak: 1.00,
            Remarks: "",
            RequesterId: "",
            RequestDate: moment().format("YYYY-MM-DD"),
            preComputeOT: "00:00",
            IsPrecomputing: false,
            
            employees: [],
            selectedEmployees: [],

            employeeSelectionEnabled: true,
            displayedName: "",
            showAskCloseDialog: false,

            isError: false,
            details: "",
            AttachmentDetail: {
                Source: "",
                File: ""
            }
        }
    }

    loadEmployee = () => {
        fnSearchEmployeeFilter("", data => {
            this.setState({ employees: data, isLoading: false });
        }, 0, 20, false)
    }

    onSelectChange = (value) => {
        var ids = [];
        if (value != null && !(value === "" || value.length === 0))
            ids = [value.value];

        var selectedEmployees = ids;
        this.setState({ selectedEmployees: ids },
            () => {
                if (selectedEmployees.length > 0 && this.state.Date.length > 0) {
                    this.onPreComputeOT(this.state.Date);
                }
            }
        );
    }

    onEmpSearch = text => {
        clearTimeout(this.searchTimer);
        this.setState({ empSearch: text });
        this.searchTimer = setTimeout(this.doEmpSearch, 400, text);
    }

    doEmpSearch = (str, source) => {
        const { employees } = this.state;
        if (source != null && str != source.value) {
            setTimeout(this.doEmpSearch, 200, source.value, source);
            return;
        }
        this.setState({ loading: true });

        var selected = this.state.selectedEmployees ? this.state.selectedEmployees[0] : "";
        var ids = (selected === "" || selected === undefined) ? null : selected.toString().split(',');

        var selectedEmpIds = [];
        if (ids != null && employees != undefined)
            selectedEmpIds = employees.filter(x => ids.includes(x._id));

        fnSearchEmployeeFilter(str, data => {
            selectedEmpIds.forEach(x => { if (!data.includes(x["_id"])) data.push(x) })
            this.setState({ employees: data, isLoading: false });
        }, 0, 20, false, ids)
    }

    // Input OT Hours
    handleTimeInput = (event) => {
        const input = event.target;
        let value = input.value;
        const cursorPos = input.selectionStart; // Track the cursor position
    
        // Only allow digits
        value = value.replace(/[^0-9]/g, '');
    
        // If empty, set to --:--
        if (value.length === 0) {
            this.setState({ Hours: '--:--' });
            return;
        }
    
        // Split the value into hours and minutes
        let hours = value.slice(0, 2);
        let minutes = value.slice(2, 4);
    
        // Adjust hours and minutes
        if (value.length <= 2) {
            hours = hours.padEnd(2, '_');
            minutes = '__';
        } else {
            minutes = minutes.padEnd(2, '_');
        }
    
        const formattedValue = `${hours}:${minutes}`.replace(/_/g, '-');
    
        // Set state with formatted time
        this.setState({ Hours: formattedValue }, () => {
            // Update input value and restore cursor position
            input.value = formattedValue;
            input.setSelectionRange(cursorPos, cursorPos);
        });
    }
    

    getContent = () => {

        const hoursToMinutes = (time) => {
            if (time === "--:--") return 0;
            const [hours, minutes] = time.split(':').map(Number);
            return hours * 60 + minutes;
        };

        return {
            _id: this.state._id,
            Date: moment(this.state.Date).toJSON(),
            StartTime: moment(this.state.StartTime, "HH:mm A").toJSON(),
            NextDay: this.state.NextDay,
            Minutes: hoursToMinutes(this.state.Hours),
            // Minutes: Math.round(this.state.Hours * 60),
            MinutesBreak: Math.round(this.state.HoursBreak * 60),
            RequesterId: this.state.RequesterId,
            RequestDate: moment().toJSON(),
            Remarks: this.state.Remarks,
            //employeeIds: this.state.selectedEmployees.map(employee => { return employee.key }),
            employeeIds: this.state.selectedEmployees,
            // preComputeOT: Math.round(this.state.preComputeOT * 60)
            AttachmentDetail: this.state.AttachmentDetail,
            preComputeOT: this.state.preComputeOT 

        }
    }

    // ATTACHMENT
    onDocChangedHandler = (event) => {
        var img = event.target.files[0];
        if (img === undefined) return;
        if (img.size > 3145728) {
            alert("File too large");
            return;
        } else if (img.size === 0) {
            alert("Image too small. File must not be 0MB");
            return;
        } else {
            this.docReadDataUrl(img, this.docOnload.bind(this));
        }
    };

    docReadDataUrl(img, callback) {
        var reader = new FileReader();
        reader.onload = function () {
            callback(reader.result, img.name);
        };
        reader.onerror = function (error) {
            console.log("Error: ", error);
        };
        reader.readAsDataURL(img);
    }

    docOnload(dataURL, name) {
        var current = { ...this.state.AttachmentDetail };
        current["Source"] = dataURL;
        current["File"] = name;

        this.setState({ AttachmentDetail: current, details: name });
    }
    
    setContent = (data) => {

        const minutesToHours = (minutes) => {
            if (minutes === 0) return "00:00";
            const hours = Math.floor(minutes / 60).toString().padStart(2, '0');
            const mins = (minutes % 60).toString().padStart(2, '0');
            return `${hours}:${mins}`;
        };

        let selectedEmployees = [];
        if (data.EmployeeId) {
            selectedEmployees.push(data.EmployeeId);
        }
        else if (data.userId && !isTaskAvailable("VIEW_ALL_EMPLOYEES")) {
            selectedEmployees.push(data.userId);
        }
        // if(data.userId && !data.hasSubordinates) {
        //     selectedEmployees.push(data.userId);

        this.setState(
            {
                _id: data._id,
                Date: moment(data.Date).format("YYYY-MM-DD"),
                StartTime: moment(data.StartTime).format("HH:mm"),
                NextDay: data.NextDay,
                Hours: minutesToHours(data.Minutes),
                // Hours: (data.Minutes / 60).toFixed(2),
                HoursBreak: (data.MinutesBreak / 60).toFixed(2),
                RequesterId: data.userId,
                Remarks: data.Remarks,
                details: !data._id ? "" : data.AttachmentDetail.File,
                AttachmentDetail: !data._id ? {
                    Source: "",
                    File: ""
                } : data.AttachmentDetail,
                employees: [],
                selectedEmployees: selectedEmployees,
                employeeSelectionEnabled: !data._id && (isTaskAvailable("VIEW_ALL_EMPLOYEES") || data.hasSubordinates),
                displayedName: !data._id && !data.hasSubordinates ? data.name : data.Employee,
            },
            //() => { this.onPreComputeOT(data.Date) }
            () => {
                this.onPreComputeOT(data.Date);
                if (this.state.employeeSelectionEnabled) {
                    this.loadEmployee();
                }
            }
        );
    }

    onEmployeeSearchChange = (event, data) => {
        var self = this;
        clearTimeout(delayTimer);
        delayTimer = setTimeout(() => {
            var employeeSearchParameter = {
                data: { needle: data.searchQuery === undefined ? "" : data.searchQuery, itemCount: 20 },
                url: "api/Overtime/searchEmployees",
                onSuccess: (data, sender) => {
                    var newOptions = data.map((model, index, array) => { return { key: model._id, text: model.LastName + ", " + model.FirstName + " " + model.MiddleName, value: model._id } });
                    self.setState({ employees: newOptions },
                        //() => { self.onPreComputeOT(self.state.Date)}
                    );
                },
                onError: (error) => {

                },
                finally: () => { }
            };
            ajaxPost(employeeSearchParameter);
        }, 200);
    };

    onPreComputeOT = (value) => {
        var self = this;
        if (this.state.selectedEmployees === null ||
            this.state.selectedEmployees === undefined ||
            this.state.selectedEmployees.length !== 1 ||
            !checkDate(value).Result
        ) {
            this.setState({
                preComputeOT: "00:00",
                NextDay: false,
                //Hours: 0 
            });

            return;
        }
        this.setState({
            preComputeOT: "computing...",
            IsPrecomputing: true
        });
        clearTimeout(delayTimer);
        delayTimer = setTimeout(() => {
            var items = {
                fromDate: moment(value),
                toDate: moment(value),
                EmployeeIds: self.state.selectedEmployees,
                //includeDeleted: this.state.includeDeleted,
                startingIndex: 0,
                itemsPerPage: 7
            };
            var parameter = {
                data: items,
                url: "api/Overtime/loadPreComputeOT",
                onSuccess: (data, sender) => {
                    if (!data || data.List.length === 0 || data.List[0].DTRData.length === 0
                        || data.List[0].DTRData[0].IsInLieuOff
                        // || (data.List[0].DTRData[0].IsInLieuOff !== undefined && 
                        //     data.List[0].DTRData[0].IsInLieuOff)
                        || data.List[0].DTRData[0].ScheduleHrsWork === 0
                        || data.List[0].DTRData[0].IsAbsent
                    ) {
                        self.setState({
                            preComputeOT: "00:00",
                            NextDay: false,
                            //Hours: 0 
                        });
                        return;
                    }
                    const empData = data.List[0].DTRData[0];
                    const oTstartTime = empData.IsRestday || empData.DayStatus != "REGULAR" ? empData.TimeIn : empData.OTE > 0 ? empData.TimeIn : empData.TimeEnd;
                    // const oTendTime = empData.TimeOut;

                    var minutesOT = empData.IsRestday || empData.DayStatus != "REGULAR" ? empData.RH + empData.OT : empData.OT;
                    //var minutesOT = empData.IsRestday ? empData.OTA : empData.OT;
                    //var minutesOT = empData.IsRestday ? moment.duration(oTendTime).asMinutes() - moment.duration(oTstartTime).asMinutes() : empData.OT;
                    const date = new Date(self.state.Date)
                    const time = moment.duration(oTstartTime);
                        date.setHours(time.asHours()) 
                        var duration = isNaN(minutesOT) || minutesOT < 0 ? 0 : minutesOT;

                        // Convert duration to HH:MM format
                        const hours = Math.floor(duration / 60);
                        const minutes = Math.round((duration % 60));
                        const formattedOT = `${String(hours).padStart(2, '0')}:${String(minutes).padStart(2, '0')}`;

                        self.setState({
                            StartTime: oTstartTime != null ? moment(date).format("HH:mm") : moment().format("HH:mm"),
                    //      Hours: Number(duration.toFixed(2)),
                    //      preComputeOT: Number(duration.toFixed(2)),
                            preComputeOT: formattedOT,
                            NextDay: oTstartTime !== null && moment.duration(oTstartTime).asHours() >= 24
                        });

                },
                onError: (error) => {
                    self.setState({ preComputeOT: "00:00", NextDay: false });
                },
                finally: () => {
                    self.setState({ IsPrecomputing: false });
                    //clearTimeout(delayTimer);
                }
            }
            ajaxPost(parameter);
        }, 500); // timeout
    }

    onCreateNewItemClick = () => {
        this.setState({ showAskCloseDialog: true });
    }

    

    render() {

        const {
            AttachmentDetail,
          } = this.state;
        
        let self = this;
        return (
            <Modal size="mini" open={this.props.open}>
                <Modal.Header>
                    Overtime Application
                </Modal.Header>
                <Modal.Content>
                    {
                        this.props.showRequiredField &&
                        <div className="ui one column grid" style={noMarginNoPadding}>
                            <div className="column" style={someMarginNoPadding}>
                                <ErrorMessage
                                    title={this.props.errTitle}
                                    caption={this.props.errCaption}
                                    open={this.props.showRequiredField}
                                />
                            </div>
                        </div>
                    }
                    {
                        !this.state.employeeSelectionEnabled &&
                        <div className="ui one column grid" style={noMarginNoPadding}>
                            <div className="column" style={someMarginNoPadding}>
                                <div>Employee</div>
                                <Header size='small' style={{ "margin": "0" }}>{this.state.displayedName}</Header>
                                {/* <Input value={this.state.displayedName} disabled={true} /> */}
                            </div>
                        </div>
                    }
                    {
                        this.state.employeeSelectionEnabled &&
                        <div className="ui one column grid" style={noMarginNoPadding}>
                            <div className="column" style={someMarginNoPadding}>
                                <div>Employees</div>
                                <Select
                                    isDisabled={this.props.isBusy}
                                    options={empList(this.state.employees)}
                                    value={this.state.employees.filter(x => x.value === this.state.selectedEmployees[0] )[0]}
                                    onChange={this.onSelectChange}
                                    onInputChange={this.onEmpSearch}
                                    isLoading={this.state.isLoading}
                                />
                                {
                                    isTaskAvailable("EMPLOYEE_ADD") &&
                                    <a as='a' href='#' onClick={this.onCreateNewItemClick.bind(this)}>Create New</a>
                                }
                            </div>
                        </div>
                    }
                    <div className="ui one column grid" style={noMarginNoPadding}>
                        <div className="column" style={someMarginNoPadding}>
                            <div>Date</div>
                            <Input
                                fluid
                                type="date"
                                min={minDate.toString()}
                                max={maxDate.toString()}
                                disabled={this.props.isBusy}
                                required={true}
                                error={!checkDate(this.state.Date).Result}
                                value={this.state.Date}
                                onChange={(event, data) => {
                                    self.setState({ Date: data.value },
                                        () => { self.onPreComputeOT(data.value) }
                                    );
                                }}
                            />
                        </div>
                    </div>
                    <div className="ui two column grid" style={noMarginNoPadding}>
                        <div className="column" style={{ "margin": "0px 0px 10px 0px", "padding": "0px 5px 0px 0px" }}>
                            <div>Start Time</div>
                            <Input fluid type="time" disabled={this.props.isBusy} value={this.state.StartTime}
                                required={true}
                                error={!checkTime(this.state.StartTime).Result}
                                onChange={(event, data) => { self.setState({ StartTime: data.value }); }}
                            />
                        </div>
                        <div className="column" style={{ "margin": "0px 0px 10px 0px", "padding": "0px 0px 0px 5px" }}>
                            <div>&nbsp;</div>
                            <Checkbox
                                fluid
                                disabled={this.props.isBusy}
                                style={{ marginTop: 10 }}
                                label="Next Day"
                                checked={this.state.NextDay}
                                onChange={(event, data) => { self.setState({ NextDay: data.checked }); }}
                            />
                        </div>
                    </div>
                    <div className="ui two column grid" style={noMarginNoPadding}>
                        {/* <div className="column">
                            <div>OT Hours</div>
                            <Input fluid disabled={this.props.isBusy} value={this.state.Hours} 
                                onChange={(event) => { self.setState({ Hours: handleNumeric(event) }); }} 
                            />
                        </div> */}
                        <div className="column" style={{ "margin": "0px 0px 10px 0px", "padding": "0px 5px 0px 0px" }}>
                            <div>Hours Break</div>
                            <Input fluid disabled={this.props.isBusy} value={this.state.HoursBreak}
                                onChange={(event) => { self.setState({ HoursBreak: handleNumeric(event) }); }}/>
                        </div>

                        {/* ATTACHMENT OVERTIME*/}
                        <div className="column" style={{ "margin": "15px 0px 10px 0px", "padding": "0px 5px 0px 0px" }}>
                            <Input
                                style={{ marginTop: "5px", height: "38px", width: "100%" }}
                                labelPosition="left"
                                type="text"
                                placeholder="File"
                                error={this.state.isError}
                            >
                                <Label style={{ width: "45px", height: "38px" }}>File</Label>
                                <label style={attachLabel} htmlFor="files_$">
                                    <a style={{ fontSize: '12px', padding: '2px'}}>
                                        {this.state.details === "" ? "Choose file" : this.state.details}
                                    </a>
                                </label>
                                <input
                                    id="files_$"
                                    style={{ width: "150px", display: "none" }}
                                    size="small"
                                    name=" "
                                    type="file"
                                    onChange={this.onDocChangedHandler}
                                />
                            </Input>
                            <div style={{fontSize: "11px", textAlign: 'right', margin: "2px" }}>*Max File Size: 3MB</div>
                        </div>
                    </div> 
                    <div className="ui two column grid" style={noMarginNoPadding}>
                        <div className="column" style={{ "margin": "0px 0px 10px 0px", "padding": "0px 5px 0px 0px" }}>
                            <div>Precomputed OT hrs</div>
                            {/* <Header size='small' style={{"margin" : "4px"}}>{this.state.preComputeOT}</Header> */}
                            <Input
                                fluid
                                disabled
                                loading={this.state.IsPrecomputing}
                                value={this.state.preComputeOT}
                                error={this.state.preComputeOT === "00:00" && this.props.showRequiredField}
                            />
                        </div>
                        <div className="column" style={{ "margin": "0px 0px 10px 0px", "padding": "0px 0px 0px 5px" }}>
                            <div>OT Hours</div>
                            <Input
                                fluid
                                disabled={this.props.isBusy}
                                value={this.state.Hours}
                                error={!/^(--:--|([0-1]?[0-9]|2[0-3]):([0-5][0-9]))$/.test(this.state.Hours) && this.props.showRequiredField}
                                onChange={this.handleTimeInput}
                                // error={!checkInt32(this.state.Hours * 60).Result && this.props.showRequiredField}
                                // onChange={(event) => { self.setState({ Hours: handleNumeric(event) }); }}
                                onKeyPress={(e) => { handleNumericOnKeyPress(e) }}
                                onKeyDown={(e) => { handleNumericOnKeyDown(e) }}
                            />
                        </div>
                    </div>

                    <div className="ui one column grid" style={noMarginNoPadding}>
                        <div className="column" style={someMarginNoPadding}>
                            <Form>
                                <div>Reasons</div>
                                <TextArea
                                    fluid
                                    value={this.state.Remarks}
                                    disabled={this.props.isBusy}
                                    style={this.props.showRequiredField && !this.state.Remarks ? semanticErrorStyle : []}
                                    onChange={(event, data) => { self.setState({ Remarks: data.value }); }}>
                                </TextArea>
                            </Form>
                        </div>
                    </div>
                </Modal.Content>
                <Modal.Actions>
                    <Button
                        basic
                        icon='save'
                        content="Submit"
                        loading={this.props.isBusy}
                        disabled={this.props.isBusy ||
                            this.state.preComputeOT === 'computing...'
                            //this.state.IsPrecomputing
                        }
                        onClick={this.props.onSave}>
                    </Button>
                    <Button
                        basic
                        icon='cancel'
                        content="Cancel"
                        disabled={this.props.isBusy}
                        onClick={this.props.onCancel}>
                    </Button>
                </Modal.Actions>
                {
                    this.state.showAskCloseDialog &&
                    <MessageBoxYesNo title="Create New Employee"
                        action="Yes"
                        onClose={() => { this.setState({ showAskCloseDialog: false }) }}
                        onAction={() => { this.props.onCreateEmpClick() }}
                        caption="All unsaved changes will be lost. Do you wish to continue?"
                    />
                }
            </Modal>
        );
    }

}

export default OvertimeForm;
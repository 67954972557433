import React, { Component } from 'react';
import 'semantic-ui-css/semantic.min.css';
import { ajaxPost } from '../../ajax';
import moment from 'moment';
import PDFTemplate from './PDFTemplate';
import PDFMasterlist from './PDFEmployeeMasterlist';
import PDFPositionMovement from './PDFPositionMovement';
import PDFTemplateWithoutDate from './PDFTemplateWithoutDate'
import { ajaxPostDownload } from '../../utils';
import PdfMake from 'pdfmake/build/pdfmake';
import vfsFonts from 'pdfmake/build/vfs_fonts';

async function getStatus(param, onError, onFinally) {

    await ajaxPost({
        data: param,
        url: 'api/BackgroundProcess/GetStatus',
        onSuccess: model => {
            return model;
        },
        onError: onError,
        finally: onFinally
    });
}
class ReportModelBase extends Component {
    state = {
        timer: null,
    }
    constructor(props) {
        super(props);
        this.fromDate = moment().startOf('day');
        this.toDate = moment().startOf('day');
        this.title = "Report";
        this.includeAmount = false;
    }

    search = (searchParameters, onSearchFinished, onError, onFinally) => {
        this.includeAmount = searchParameters.includeAmount
        searchParameters["purpose"] = "";
        var parameter = {
            data: searchParameters,
            url: this.searchUrl,
            onSuccess: onSearchFinished,
            onError: onError,
            finally: onFinally
        };
        ajaxPost(parameter);
    }


    exportToExcelData = (searchParameters, onSearchFinished, onError, onFinally) => {
        searchParameters["purpose"] = "excel";
        var parameter = {
            data: searchParameters,
            url: this.exportToExcelDataUrl,
            onSuccess: onSearchFinished,
            onError: onError,
            finally: onFinally
        };
        ajaxPost(parameter);

    }

    processPdfData = (pdfData) => {
        PDFTemplate(pdfData);
    }
    processSTDPdfData = (pdfData) => {
        const { vfs } = vfsFonts.pdfMake;
        PdfMake.vfs = vfs;

        pdfData.footer = function(currentPage, pageCount) { 
            return {
                text: 'Page ' + currentPage.toString() + ' of ' + pageCount,
                alignment: 'right',
                fontSize: 7,
                margin:[0,0,50,0]
            }
        }

        PdfMake.createPdf(pdfData).open();
    }
    employeeMasterlistPdfData = (pdfData) => {
        PDFMasterlist(pdfData);
    }
    positionMovementPdfData = (pdfData) => {
        PDFPositionMovement(pdfData);
    }
    employeeReportWithoutDate = (pdfData) => {
        PDFTemplateWithoutDate(pdfData);
    }

    masterlistExportToExcel = (searchParameters, onFinished, onError, onFinally) => {
        var parameter = {
            data: searchParameters,
            url: this.exportToExcelUrl,
            onSuccess: onFinished,
            onError: onError,
            finally: onFinally,
            contentType: "application/vnd.ms-excel"
        };
        ajaxPostDownload(
            this.exportToExcelUrl,
            searchParameters,
            (searchParameters.Title === undefined ? searchParameters.ReportTitle : searchParameters.Title) + ".xlsx",
            () => {
                onFinished();
            },
            (error) => {
                onError(error);
            },
            () => {
                onFinally();
            }
        )
    }
    exportToExcel = (searchParameters, onFinished, onError, onFinally) => {
        searchParameters["purpose"] = "excel";
        var parameter = {
            data: searchParameters,
            url: this.exportToExcelUrl,
            onSuccess: onFinished,
            onError: onError,
            finally: onFinally,
            contentType: "application/vnd.ms-excel"
        };
        ajaxPostDownload(
            this.exportToExcelUrl,
            searchParameters,
            (searchParameters.Title === undefined ? searchParameters.ReportTitle : searchParameters.Title) + ".xlsx",
            () => {
                onFinished();
            },
            (error) => {
                onError(error);
            },
            () => {
                onFinally();
            }
        )
    }
    // exportToExcel = (searchParameters,onFinished,onError,onFinally) => {
    //     var parameter = {
    //         data:searchParameters,
    //         url:this.exportToExcelUrl,
    //         onSuccess: onFinished,
    //         onError: onError,
    //         finally: onFinally,
    //         contentType: "application/vnd.ms-excel"
    //     };
    //     var employees = "";
    //     searchParameters.EmployeeIds.forEach(emp => {
    //         employees += emp.toString() + ",";
    //     });
    //     window.location = global.ApiHost +this.exportToExcelUrl + "?" +
    //     "fromDate=" + searchParameters.fromDate + "&" +
    //     "toDate=" + searchParameters.toDate + "&" +
    //     "ReportTitle=" + searchParameters.ReportTitle + "&" +
    //     "strEmployeeIds=" + employees;
    // }
    exportToPdf = (searchParameters, onSearchFinished, onError, onFinally) => {
        searchParameters["purpose"] = "pdf";
        var parameter = {
            data: searchParameters,
            url: this.exportToPdfUrl,
            onSuccess: onSearchFinished,
            onError: onError,
            finally: onFinally
        };
        ajaxPost(parameter);
    }
}
export default ReportModelBase;
import React, { Component } from 'react';
import { Image, Button, Modal, Select, Input, Checkbox } from 'semantic-ui-react';
import 'semantic-ui-css/semantic.min.css';
import TransactionModuleBase from './TransactionModuleBase';
import { ajaxPost } from '../../ajax';
import ViewNotificationDetails from './Commons/ViewNotificationDetails';
import OvertimeForm from './Forms/OvertimeForm';
import { isTaskAvailable, ajaxPostDownload, checkDate, checkTime, checkInt32, momentDateFormat } from '../../utils';
import MessageBoxOkOnly from '../../Commons/MessageBoxOkOnly';
import CustomSelect from '../../Commons/CustomSelect';
import { SelectColumn } from 'react-data-grid';

const ViewID = "Overtime";
const ViewCaption = "Overtime";

const moment = require('moment');
const updateStatus = "api/Overtime/updateStatus";
// const { Editors: { DropDownEditor } } = require('react-data-grid');
const applicationStatuses = [
	'PENDING',
	'APPROVED',
	'CANCELLED',
	'REJECTED'
];
const userRightsKeys = {
	view: "OVERTIME_VIEW",
	create: "OVERTIME_CREATE",
	edit: "OVERTIME_EDIT",
	delete: "OVERTIME_DELETE",
	restore: "OVERTIME_RESTORE",
	approve: "OVERTIME_APPROVE",
	reject: "OVERTIME_REJECT",
	pend: "OVERTIME_PEND",
	cancel: "OVERTIME_CANCEL",
	export: "OVERTIME_EXPORT_TO_EXCEL",
	import: "OVERTIME_IMPORT"
}

var delayTimer = null;
class OvertimeList extends Component {

	constructor(props) {
		super(props);

		const startDate = props.startDate;
		const endDate = props.endDate;		
		this.state = {
			viewType: "hr",
			modalVisible: false,
			exportModalVisible: false,
			exportingToExcel: false,
			employees: [],
			selectedEmployees: [],

			fromDate: moment().format(startDate),
			toDate: moment().format(endDate),
			statuses: ["APPROVED"],
			excelToExportRequestorId: null,
		}
		this.modalRef = React.createRef();
		this.parentRef = React.createRef();
	}

	checkDuplicateApproved = (items) => {
		var conflictingItems = [];
		var isValid = false;
		items.forEach(item => {
			var searchItem = items.find(x => x.Date === item.Date && x._id !== item._id);
			if (searchItem.length > 0) {
				conflictingItems.push(item);
			}
		});
		return {
			isValid: true,

		}
	}

	createUrls = () => {
		return {
			getStatusCounts: "api/Overtime/getStatusCounts",
			search: "api/Overtime/search",
			setDeleted: "api/Overtime/setDeleted",
			saveMultiple: "api/Overtime/saveMultiple",
			save: "api/Overtime/save",
			load: "api/Overtime/load",
			searchEmployees: "api/Overtime/searchEmployees",
			updateStatus: updateStatus,
			exportToExcel: "api/Overtime/exportToExcel",
			downloadTemplate: "api/Overtime/downloadTemplate",
			importFromExcel: "api/Overtime/importFromExcel"
		};
	}

	toRow = (model) => {
		return {
			id: model._id,
			employeeId: model.EmployeeId,
			deleted: model.IsDeleted === undefined ? false : model.IsDeleted,
			employee: model.Employee,
			date: moment(model.Date).format("MM/DD/YYYY"),
			startTime: !model.StartTime ? "-" : moment(model.StartTime).format("hh:mm A"),
			nextDay: model.NextDay ? "Yes" : "No",
			hours: (model.Minutes / 60).toFixed(2),
			hoursBreak: (model.MinutesBreak / 60).toFixed(2),
			status: model.Status,
			remarks: model.Remarks,
			approver: model.Approver,
			approverId: model.ApproverId,
			approvedHours: model.Status === "APPROVED" ? (model.ApprovedMinutes / 60).toFixed(2) : "",
			approverRemarks: model.ApproverRemarks,
			changeStatusDate: !model.ChangeStatusDate ? "" : moment(model.ChangeStatusDate).format("MM/DD/YYYY"),
			requestDate: moment(model.RequestDate).format(momentDateFormat),
			file:model.AttachmentDetail != null ? model.AttachmentDetail.File : "",
			actions:model.AttachmentDetail != null && model.AttachmentDetail.File != "" && model.AttachmentDetail.File != null ?                                               
			<div style={{display:'flex', width:'100%', height:'100%', justifyContent:'center', alignItems:'center' }}><Button 
				style={{fontSize: '0.8rem'}}
				icon="download"
				circular
				onClick={this.downloadDoc.bind(
					this,
					model.AttachmentDetail.UniqueFilename,
					model.AttachmentDetail.File
				  )}
			/> </div>: "",
			AttachmentDetail: model.AttachmentDetail,
			editable: false,
			idle: false,
		};
	};

	downloadDoc(filename, origName) {
        window.location =
            global.ApiHost +
            "api/TransactionsCommon/download?" +
            "fileName=" +
            filename +
            "&" +
            "originalName=" +
            origName;
    }

	toModel = (row) => {
		let minutes = row.hours * 60;
		minutes -= minutes % 1;
		let minutesBreak = row.hoursBreak * 60;
		minutesBreak -= minutesBreak % 1;
		let model = {
			_id: row.id,
			EmployeeId: row.employeeId,
			Date: moment(row.date).toJSON(),
			StartTime: moment(row.date + " " + row.startTime).toJSON(),
			NextDay: row.nextDay === "Yes",
			Minutes: minutes,
			MinutesBreak: minutesBreak,
			File: row.file,
			AttachmentDetail: row.AttachmentDetail,
			Status: row.status,
			Remarks: row.remarks
		};
		return model;
	};

	createColumns = () => {
		let self = this;
		let hasHrAccess = isTaskAvailable("HR_ROLE");
		let columns = [
			SelectColumn,
			{ key: 'date', name: 'Date', width: 90, resizable: true},
			{ key: 'startTime', name: 'Start', width: 90, resizable: true},
			{ key: 'nextDay', name: 'Next Day?', width: 70, resizable: true},
			{ key: 'hours', name: 'OT Hrs.', width: 60, resizable: true},
			{ key: 'hoursBreak', name: 'Break Hrs.', width: 60, resizable: true},
			{ key: 'status', name: 'Status', width: 90, resizable: true},
			{ key: 'remarks', name: 'Reasons', width: 120, resizable: true},
			{ key: 'changeStatusDate', width: 130, name: 'Change Status Date', resizable: true },
			{ key: 'approver', name: 'Approver', width: 170, resizable: true },
			{ key: 'approvedHours', name: 'Approved OT Hrs', width: 100, resizable: true },
			{ key: 'approverRemarks', name: 'Approver Remarks', width: 120, resizable: true },
			{ key: 'requestDate', name: 'Date Filed', width: 90, resizable: true },
			{ key: 'file', name: 'File', width: 100, resizable: true },
			{ key: 'actions', name: 'Actions', width: 90, resizable: true },
		];
		columns.unshift({
			key: 'employee', name: 'Employee', width: 200, resizable: true
		});

		return columns;
	}

	validate = (data) => {
		var errTitle = "", errCaption = "";
		var checkDateParam = checkDate(data.Date);
		var checkInt32MinutesParam = checkInt32(data.Minutes, false)

		if (this.isNullOrEmpty(data.employeeIds)
			|| this.isNullOrEmpty(data.Remarks)
		) {
			errCaption = "Please fill up all required field/s";
			errTitle = "Invalid";
		}
		else if (!checkDateParam.Result) {
			errCaption = checkDateParam.Message;
			errTitle = checkDateParam.Title;
		}
		else if (!checkInt32MinutesParam.Result) {
			errCaption = checkInt32MinutesParam.Message;
			errTitle = checkInt32MinutesParam.Title;
		}
		else if (data.Minutes === 0) {
			errCaption = "OT Hours cannot be '0'";
			errTitle = "Invalid";
		}
		else {
			data.preComputeOT = undefined;
			this.parentRef.current.setState({ showRequiredField: false });
			return true;
		}
		this.parentRef.current.setState({ showRequiredField: true, errTitle, errCaption });
		return false;
	};

	validateFromApi = (rowModels, callback) => {
		var isValidated = false;
		var parameter = {
			data: { items: rowModels },
			url: "api/Overtime/validate",
			onSuccess: function (data, sender) {
				if (data.length > 0) {
					alert("Found (" + data.length + ") invalid filing");
				}
				isValidated = data.length === 0 ? true : false;
			},
			onError: function (error) {
				alert("An error has when validating:\r\n" + JSON.stringify(error));
			},
			finally: function () {
				callback(isValidated, rowModels);
				//return isValidated;
			}
		}
		ajaxPost(parameter);
	};

	showModal = (data, visible) => {
		visible && this.modalRef.current.setContent(data);
		this.setState({ modalVisible: visible });
	}

	exportToExcel = (requestorId) => {
		this.setState({
			excelToExportRequestorId: requestorId,
			exportModalVisible: true
		});
	}

	reqeuestExportToExcel = () => {
		let parameter = {
			employeeIds: this.state.selectedEmployees,
			fromDate: this.state.fromDate,
			toDate: this.state.toDate,
			statuses: this.state.statuses,
			requestorId: this.state.excelToExportRequestorId
		}
		ajaxPostDownload(
			"api/Overtime/exportToExcel",
			parameter,
			"Overtime Requests.xlsx",
			() => {
				this.setState({ exportModalVisible: false });
			},
			(error) => {
				let errorData = JSON.stringify(error);
				alert("An error has occurred." + errorData !== "{}" ? "\r\n" + errorData : "");
			},
			() => {

			}
		)
	}

	onEmployeeSearchChange = (event, data) => {
		var self = this;
		clearTimeout(delayTimer);
		delayTimer = setTimeout(() => {
			var employeeSearchParameter = {
				data: { needle: data.searchQuery === undefined ? "" : data.searchQuery, itemCount: 20 },
				url: "api/Overtime/searchEmployees",
				onSuccess: (data, sender) => {
					var newOptions = data.map((model, index, array) => { return { key: model._id, text: model.LastName + ", " + model.FirstName + " " + model.MiddleName, value: model._id } });
					self.setState({ employees: newOptions });
				},
				onError: (error) => {

				},
				finally: () => { }
			};
			ajaxPost(employeeSearchParameter);
		}, 200);
	};

	onStatusFilterChecked = (event, data) => {
		let statuses = this.state.statuses.slice();
		if (data.checked) {
			if (!statuses.includes(data.taggedstatus)) {
				statuses.push(data.taggedstatus);
			}
		} else {
			let index = this.state.statuses.indexOf(data.taggedstatus);
			if (index > -1) {
				statuses.splice(index, 1);
			}
		}
		this.setState({ statuses: statuses });
	}

	isNullOrEmpty(val) {
		if (val === null || val === undefined || val === "" || val.length === 0) return true;
		else return false;
	}

	render() {
		let self = this;
		return (
			<TransactionModuleBase
				ref={this.parentRef}
				startDate={this.state.fromDate}
				endDate={this.state.toDate}
				module="Overtimes"
				viewID={ViewID}
				title={ViewCaption}
				columns={this.createColumns()}
				toModel={this.toModel}
				toRow={this.toRow}
				urls={this.createUrls()}
				validate={this.validate}
				props={this.props}
				showModal={this.showModal.bind(this)}
				userRightsKeys={userRightsKeys}
				hasExportToExcel={true}
				exportToExcel={this.exportToExcel.bind(this)}
				hasApprovedOTField={true}
			>
					<OvertimeForm
						ref={this.modalRef}
						open={this.state.modalVisible}
						errTitle={self.parentRef.current ? self.parentRef.current.state.errTitle : ""}
						errCaption={self.parentRef.current ? self.parentRef.current.state.errCaption : ""}
						showRequiredField={self.parentRef.current ? self.parentRef.current.state.showRequiredField : ""}
						//getEmployeeData={(input, callback) => { self.parentRef.current.getData(input, callback) }}
						updateEmployeeIds={(Ids) => { self.parentRef.current.updateEmployeeIds(Ids) }}
						isBusy={self.parentRef.current ? self.parentRef.current.state.isSaving : false}
						onCancel={() => { 
							this.setState({ modalVisible: false }, self.parentRef.current.setState({ showRequiredField: false })); 
						}}
						onCreateEmpClick={() => {
							this.setState({ modalVisible: false });
							this.props.createEmpCallback();
						}}
						onSave={() => {
							if (self.modalRef.current.state._id) {
								self.parentRef.current.editFromModal(
									self.modalRef.current.getContent()
								)
							}
							else {
								self.parentRef.current.saveFromModal(
									self.modalRef.current.getContent()
								)
							}
						}}
					>
					</OvertimeForm>
					<Modal open={this.state.exportModalVisible} size="tiny">
						<Modal.Header>
							Export Overtime Requests
					</Modal.Header>
						<Modal.Content>
							<div className="ui one column grid">
								<div className="column">
									<div>Employees</div>
									{
										!this.state._id &&
										<div className="ui one column grid">
											<div className="column">
												<CustomSelect fluid={true} search={true} selection={true} placeholder='Search Employees...' options={this.state.employees}
													onClick={this.onEmployeeSearchChange} onSearchChange={this.onEmployeeSearchChange}
													onChange={
														(event, { value }) => {
															this.setState({ selectedEmployees: value })
														}
													} multiple={true} />
											</div>
										</div>
									}
								</div>
							</div>
							<div className="ui two column grid">
								<div className="column">
									<div>From</div>
									<Input fluid={true} type="date" value={this.state.fromDate} onChange={
										(event, data) => {
											this.setState({ fromDate: data.value });
										}
									} />
								</div>
								<div className="column">
									<div>To</div>
									<Input fluid={true} type="date" value={this.state.toDate} onChange={
										(event, data) => {
											this.setState({ toDate: data.value });
										}
									} />
								</div>
							</div>
							<div className="ui one column grid">
								<div className="column">
									<div>Statuses</div>
									<div className="ui three column grid">
										<div className="column">
											<Checkbox label="Approved" style={{ marginTop: 10 }} taggedstatus="APPROVED" checked={this.state.statuses.includes("APPROVED")} onChange={this.onStatusFilterChecked}></Checkbox>
										</div>
										<div className="column">
											<Checkbox label="Pending" style={{ marginTop: 10 }} taggedstatus="PENDING" checked={this.state.statuses.includes("PENDING")} onChange={this.onStatusFilterChecked}></Checkbox>
										</div>
										<div className="column">
											<Checkbox label="Rejected" style={{ marginTop: 10 }} taggedstatus="REJECTED" checked={this.state.statuses.includes("REJECTED")} onChange={this.onStatusFilterChecked}></Checkbox>
										</div>
									</div>
								</div>
							</div>
						</Modal.Content>
						<Modal.Actions>
							<Button content="Export" disabled={this.state.exportingToExcel} loading={this.state.exportingToExcel} onClick={this.reqeuestExportToExcel.bind(this)}></Button>
							<Button content="Cancel" disabled={this.state.exportingToExcel} onClick={
								() => {
									this.setState({ exportModalVisible: false });
								}
							}></Button>
						</Modal.Actions>
					</Modal>
			</TransactionModuleBase>
		);
	}

}

OvertimeList.getViewID = () => { return ViewID; };
OvertimeList.getCaption = () => { return ViewCaption; };
OvertimeList.getIcon = () => { return (<Image style={{ height: "24px", width: "24px" }} src="/Icons/Overtimes.png" avatar />); };
OvertimeList.getIconSrc = (size) => { return '/Icons/Overtimes.png' }
OvertimeList.getViewAccessKey = function () {
	var count = Object.keys(userRightsKeys).filter(name => isTaskAvailable(userRightsKeys[name]) === true).length;
	return count > 0 ? true : false;
};
OvertimeList.init = function (parent) {
	var handleOvertimeAction = function (status, data, callback) {
		var message = "";
		var title = data.Title;
		if (status === "CLOSE") parent.setState({ external: null });
		else {
			var items = [];
			items.push(data.Data);
			var dataItems = { items: items, status: status, remarks: data.Data.ApproverRemarks };
			dataItems.fromDate = moment(data.Data.Date).toJSON();
			dataItems.toDate = moment(data.Data.Date).toJSON();

			if (status === 'APPROVED') dataItems.approvedOTHours = data.Data.ApprovedHours;
			var parameter = {
				data: dataItems,
				url: updateStatus,
				onError: function (error) {
					alert("Error occured");
				},
				onSuccess: (data, sender) => {
					if (data[data.length - 1].hasOwnProperty("errMessage")) {
						let lastIndex = data.length - 1;
						let titleSingular = title.toLowerCase() === 'official businesses' ? title.substring(0, title.length - 2) : title.substring(0, title.length - 1);
						title = "Invalid";
						message = status === "APPROVED" ? "Already approved " + titleSingular.toLowerCase() + " request has been found." : data[lastIndex]["errMessage"];
					}
					else {
						message = data[0].Message;
					}
					parent.setState({
						external:
							<MessageBoxOkOnly
								title={title}
								caption={message}
								onClick={() => {
									parent.setState({ external: null });
								}}
							/>
					});
				},
				finally: function () {
					callback(message, status);
				}
			};
			ajaxPost(parameter);
		}
	};
	var notificationAction =
		function (parent, action, data, callback) {
			var status = "";
			switch (action.Action) {
				case "Approve": status = "APPROVED"; break;
				case "Reject": status = "REJECTED"; break;
				case "Cancel": status = "CANCELLED"; break;
				default: status = "View";
			}

			//var actionTaken = data["ActionSelection"];
			if (data["ActionTaken"] === "NONE" && data["IsActionable"] === false) {
				data["ActionTaken"] = "READ";
				ajaxPost(
					{
						url: "api/Notification/markAsRead",
						data: data._id,
						onSuccess: data => { },
						finally: () => { }
					}
				)
			}

			ajaxPost({
				url: "api/Notification/loadDataOnly",
				data: data["_id"],
				onSuccess: x => {
					data["Data"] = x;
				},
				finally: () => {
					data["ActionTaken"] = data["ActionTaken"].toString() === "" ? "NONE" : data["ActionTaken"];

					if (action === "View") {
						var viewData = {
							"Date": moment(data.Data["Date"]).format(momentDateFormat),
							"Start Time": moment(data.Data["StartTime"]).format("hh:mm A"),
							"Next Day": data.Data["NextDay"] ? "Yes" : "No",
							"Hours": (data.Data["Minutes"] / 60).toFixed(2),
							"Break Hours": (data.Data["MinutesBreak"] / 60).toFixed(2),
							"Status": data.Data["Status"],
							"Remarks": data.Data["Remarks"],
							"Description": data["Description"],
						};
						if (data.Data["ApprovedMinutes"] && data.Data["Status"] === "APPROVED") {
							viewData["Approved Hours"] = (data.Data["ApprovedMinutes"] / 60).toFixed(2);
						}
						if (data.Data["ApproverRemarks"]) {
							viewData["Approver Remarks"] = data.Data["ApproverRemarks"];
						}
						parent.setState({
							external:
								<ViewNotificationDetails
									Title={data.Title}
									data={data}
									viewData={viewData}
									callback={callback}
									hasApprovedOTField={true}
									onAction={this.handleOvertimeAction.bind(this)}
								/>
						});
					}
					else {
						data.Data["ApproverRemarks"] = "";
						data.Data["ApprovedHours"] = 0;
						this.handleOvertimeAction(status, data, callback);
					}
				}
			});

		}
	parent.FunctionCollection["overtimeNotification"] = notificationAction;
	parent.FunctionCollection["handleOvertimeAction"] = handleOvertimeAction;
}

export default OvertimeList;
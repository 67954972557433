import React, { Component } from 'react';

const styles = {
    menu:
    {
        display: "flex",
        position: "fixed",
        margin: 0,
        height: "100%",
    },
    hands:
    {
        height: 220,
        background: "url('/Images/hands.png') white no-repeat center",
        backgroundSize: "300px 200px",
        width: 320,
        border: "1px solid black",
        marginLeft: "auto",
        marginRight: "auto",
        position: "relative",
    },
    fingerPoints:
    {
        width: 15,
        height: 15,
        border: "1px solid black",
        borderRadius: 10,
        cursor: "pointer",
        position: "absolute",
    }
}

class SelectFinger extends Component {
    constructor(props) {
        super(props);

        if (props.value != null)
            this.state.value = props.value;

        if (props.getRef != null)
            props.getRef(this);
    }

    state =
        {
            module: "Employees",
            toolbar: null,
            drawer: false,
            notifications: [],
            value: 6,
        }

    handleChange = (value, evt) => {
        this.setState({ value: value });
        if (this.props.onChange != null)
            this.props.onChange(value);
    }

    render() {
        const pos = [
            [6, 65],
            [30, 25],
            [45, 5],
            [82, 5],
            [140, 50],
        ]
        var draw = () => {
            var ret = [];
            for (var i = 0; i < 10; i++) {
                var style = {
                }
                if (i < 5) {
                    style.left = pos[i][0];
                    style.top = pos[i][1]
                }
                else {
                    style.right = pos[9 - i][0];
                    style.top = pos[9 - i][1]
                }

                if (i == this.state.value)
                    style.backgroundColor = "yellow";

                ret.push((<div style={{...styles.fingerPoints, ...style}} onClick={this.handleChange.bind(this, i)} />))
            }
            return ret;
        }
        return (
            <div style={styles.hands}>
                {draw()}
            </div>
        )
    }


}

export default SelectFinger;

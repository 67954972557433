import React, { Component } from 'react';
import { Image, Input, Dropdown, Button, Divider, Card, Modal, Loader, Message, Header, Checkbox } from 'semantic-ui-react';
import 'semantic-ui-css/semantic.min.css';
import { ajaxPost } from '../ajax';
import { empty_id } from '../utils';

class SystemSettingsManagement extends Component {

    state = {
        setting: {
            TimeDisplayFormat: "HH:mm",
            DateDisplayFormat: "MM/dd/yyyy",
            MinPasswordLenght: 0,
            MaxLoginTries: 0,
            AutoResetPassword: true,
            MaxAccessNumberLength: 0
        },
        dialog: false,
        showConflict: false,
        saving: false,
        conflict: "",
    }
    componentWillMount = () => {
        this.LoadSettings();
    }
    componentWillUnmount = () => {
        this.props.unmountCallback(this.state, "SystemSettingManagementModule");
    }

    LoadSettings = () => {

        var searchParameter = {
            data: null,
            url: "api/Setting/search",
            onError: (error) => {
                alert("error\r\n" + JSON.stringify(error));
            },
            onSuccess: (data, sender) => {
                var content = data.content;
                content["AutoResetPassword"] = content["AutoResetPassword"].toLowerCase() === "false" ? false : true;
                this.setState({ setting: content });
            },
            finally: () => { }
        };
        ajaxPost(searchParameter);
    }
    Save = (callback, model) => {
        ajaxPost({
            url: 'api/Setting/save',
            data: {
                saveModel: {
                    "TimeDisplayFormat": model.TimeDisplayFormat,
                    "AutoResetPassword": model.AutoResetPassword,
                    "DateDisplayFormat": model.DateDisplayFormat,
                    "MaxAccessNumberLength": model.MaxAccessNumberLength
                }
            },
            onSuccess: data => {
                callback(data);
            },
            onError: function (err) {
                alert(JSON.stringify(err));
            },
            finally: () => { this.setState({ saving: false }); }
        })
    }
    handleSave = (e, data) => {
        this.setState({ saving: true });
        var model = this.state.setting;
        this.Save(data => {
            this.setState({ saving: false });
            this.setState({ showConflict: false });
            if (data.content != null) {
                this.setState({ setting: data.content });
                this.DialogOnSuccess();
            }
            if (data.errors != null && data.errors.length != 0) {
                this.setState({ showConflict: true });
                this.setState({ conflict: data.errors.conflict });
                this.setState({ setting: model });
            }

        }, model);
    }
    DialogOnSuccess = () => {
        this.setState({ dialogTitle: "Save" });
        this.setState({
            dialogContent: (
                <div>
                    <p>Setting has been saved successfully</p>
                </div>
            )
        });
        this.setState({ dialog: true });
    }
    handleComboChange = (name, val) => {
        var current = this.state.setting;
        current[val.name] = val.value;
        this.setState({ setting: current });
    }
    handleChange = name => event => {
        var current = this.state.setting;
        current[name] = event.target.value;
        this.setState({ setting: current });
    };

    handleCheckChange = (e, { checked }) => {
        var current = this.state.setting;
        current.AutoResetPassword = checked;
        this.setState({ setting: current });
    }

    render() {
        const formats = [
            { key: 1, text: 'Hour:Minute', value: "HH:mm" },
            { key: 2, text: 'Hour:Minute AM/PM', value: "hh:mm tt" },
            { key: 3, text: 'Minutes', value: "MINUTES" },
            { key: 4, text: 'Hours', value: "HOURS" }

        ];
        const dateformats = [
            { key: 1, text: 'MM/DD/YYYY', value: "MM/DD/YYYY" },
            { key: 2, text: 'MM/DD/yy', value: "MM/DD/YY" },
            { key: 3, text: 'M/D/YY', value: "M/D/YY" },
            { key: 4, text: 'MM-DD-YYYY', value: "MM-DD-YYYY" }

        ];
        return (<div>
            <div style={{ color: "#606060", marginTop: '1%' }}>
                <h2>Setting Management</h2>
            </div>

            <Divider />
            <Loader active={this.state.saving} ></Loader>
            {this.state.showConflict &&
                <Message negative>
                    <Message.Header>Conflict !</Message.Header>
                    <Message.Content>{this.state.conflict.map(n => {
                        return (<p>{n}</p>)
                    })}</Message.Content>
                </Message>}
            <Card color="olive">
                <Card.Content>
                    <div>
                        <label>Time Display Format</label><br />
                        <Dropdown fluid selection options={formats}
                            value={this.state.setting.TimeDisplayFormat} name="TimeDisplayFormat"
                            onChange={this.handleComboChange.bind(this)}
                        />
                    </div>
                    <div>
                        <label>Date Display Format</label><br />
                        <Dropdown fluid selection options={dateformats}
                            value={this.state.setting.DateDisplayFormat} name="DateDisplayFormat"
                            onChange={this.handleComboChange.bind(this)}
                        />
                    </div>
                    <div>
                        <label>Max AccessNumber Length</label>
                        <Input fluid placeholder='Max AccessNumber Length'
                            value={this.state.setting.MaxAccessNumberLength}
                            onChange={this.handleChange("MaxAccessNumberLength").bind(this)}
                        />
                    </div>
                    {/* <div>
                        <label>Min Password Length</label> <br />
                        <Input fluid placeholder='Min Password Lenght'
                            value={this.state.setting.MinPasswordLenght}
                            onChange={this.handleChange("MinPasswordLenght").bind(this)}
                        />
                    </div> */}
                    {/* <div>
                        <label>Max Login Tries</label> <br />
                        <Input fluid placeholder='Max Login Tries'
                            value={this.state.setting.MaxLoginTries}
                            onChange={this.handleChange("MaxLoginTries").bind(this)}
                        />
                    </div> */}
                    <div style={{ marginTop: 10 }}>
                        <Checkbox checked={this.state.setting.AutoResetPassword} onClick={this.handleCheckChange.bind(this)} label='Allow Auto Reset Password' />
                    </div>
                </Card.Content>
                <Card.Content extra>
                    <Button size="mini" icon="save" content="Save"
                        onClick={this.handleSave.bind()} />
                </Card.Content>
            </Card>
            {/* Modal Form */}
            {<Modal size="mini" open={this.state.dialog} onClose={this.close} >
                <Header content={this.state.dialogTitle} />
                <Modal.Content>
                    {this.state.dialogContent}
                </Modal.Content>
                <Modal.Actions>
                    <Button basic icon="check" content="Yes"
                        onClick={() => { this.setState({ dialog: false }); }} />
                </Modal.Actions>
            </Modal>}
        </div>)
    }

}
SystemSettingsManagement.getViewID = function () { return "SystemSettingManagementModule" };
SystemSettingsManagement.getCaption = function () { return "Setting Management" };
SystemSettingsManagement.getViewAccessKey = () => {
    return global.userId == empty_id;
}

SystemSettingsManagement.getIcon = () => {
    return (<Image style={{ "height": "24px", "width": "24px" }} src='/Icons/Setting_Mngt.png' avatar />);
}

SystemSettingsManagement.getIconSrc = (size) => //size values: small (50x50), medium(150x150), large(300x300)
{
    return "/Icons/Setting_Mngt.png";
}
export default SystemSettingsManagement;
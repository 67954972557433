import React from 'react';
import 'semantic-ui-css/semantic.min.css';
import ReportModelBase from './ReportModelBase';
import { Table } from '../../../node_modules/semantic-ui-react';
// const moment = require('moment');

class OvertimePremiumReportModel extends ReportModelBase {

    constructor(){
        super();
        this.state = {
            isFilterActive: false
        }
        this.searchUrl = "api/Reports/createPremiumSummaryReport";
        this.title = "Overtime Report (Summary)";
        this.exportToExcelDataUrl = "api/Reports/createExportSummaryPremiumReportToExcel";        
        this.exportToExcelUrl = "api/Reports/ExportSummaryPremiumReportToExcel";
        this.exportToPdfUrl = "api/Reports/createExportSummaryPremiumReportToPDF";
    }

    toTableRow = (rowData) => {
        return (
            <Table.Row>
                <Table.Cell>{rowData.EmployeeNo}</Table.Cell>
                <Table.Cell>{rowData.Employee}</Table.Cell>
                <Table.Cell>{rowData.Description}</Table.Cell>
                <Table.Cell>{rowData.OThours}</Table.Cell>
                <Table.Cell>{rowData.OTamount}</Table.Cell>
                <Table.Cell>{rowData.NDhours}</Table.Cell>
                <Table.Cell>{rowData.NDamount}</Table.Cell>
            </Table.Row>
        );
    }

    createColumnHeaders = () => {
        return (
            <Table.Row>
                <Table.HeaderCell>Employee No</Table.HeaderCell>
                <Table.HeaderCell>Employee</Table.HeaderCell>
                <Table.HeaderCell>Description</Table.HeaderCell>
                <Table.HeaderCell>OT Hours</Table.HeaderCell>
                <Table.HeaderCell>OT Amount</Table.HeaderCell>
                <Table.HeaderCell>OT Hours(ND)</Table.HeaderCell>
                <Table.HeaderCell>OT Amount(ND)</Table.HeaderCell>
            </Table.Row>
        );
    }

}

export default OvertimePremiumReportModel;
import React, { Component } from 'react';
import { Modal, Button } from 'semantic-ui-react';
import 'semantic-ui-css/semantic.min.css';

class CutoffNotice extends Component {
    //state = {open:true};
    handleClose = () => {
        this.props.onClose();
    }

    render() {
        return (
            <Modal open size='tiny' onClose={this.handleClose}>
                { this.props.title && <Modal.Header>{this.props.title}</Modal.Header> }
                <Modal.Content>
                    <Modal.Description>
                        {this.props.content.map(x => {return(<p>{x}</p>)})}
                    </Modal.Description>
                </Modal.Content>
                <Modal.Actions>
                    <Button basic icon='check' content='OK' onClick={this.handleClose}></Button>
                </Modal.Actions>
            </Modal>
        )
    }
}

export default CutoffNotice
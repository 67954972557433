import React from 'react';
import 'semantic-ui-css/semantic.min.css';
import ReportModelBase from './../ReportModelBase';
import { Table } from '../../../../node_modules/semantic-ui-react';
const moment = require('moment');

class EmployeeReportModelGroupByGender extends ReportModelBase {

    constructor(props){
        super(props);
        this.searchUrl = "api/Reports/employeeReportGroupByGender";
        this.title = "Employee Report Group by Gender";
        this.exportToExcelUrl = "api/Reports/ExportEmployeeReportGroupByGenderToExcel";
        this.exportToPdfUrl = "api/Reports/ExportEmployeeReportGroupByGenderToPDF";
    }

    toTableRow = (rowData) => {
        return (
            <Table.Row>
                <Table.Cell>{rowData["Employee No"]}</Table.Cell>
                <Table.Cell>{rowData["Employee"]}</Table.Cell>
                <Table.Cell>{rowData["Gender"]}</Table.Cell>
            </Table.Row>
        );
    }

    createColumnHeaders = () => {
        return (
            <Table.Row>
                <Table.HeaderCell>Employee No</Table.HeaderCell>
                <Table.HeaderCell>Employee</Table.HeaderCell>
                <Table.HeaderCell>Gender</Table.HeaderCell>
            </Table.Row>
        );
    }

}
export default EmployeeReportModelGroupByGender;
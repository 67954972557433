import React, { Component } from "react";
import { Modal, Button, List } from "semantic-ui-react";
import "semantic-ui-css/semantic.min.css";

class MessageBoxSaveClose extends Component {
  //state = {open:true};
  handleClose = () => {
    this.props.onClick();
  };

  continueEditing = () => {
    this.props.onClose();
  };

  render() {
    const fields = this.props.reqFields;
    return (
      <Modal open size="tiny" onClose={this.handleClose}>
        {this.props.title && <Modal.Header>{this.props.title}</Modal.Header>}
        <Modal.Content>
          <Modal.Description>
            <div style={{ whiteSpace: "pre-wrap" }}>
              {this.props.caption}
              <div>
                {fields.map((x) => {
                  return (
                    <div>
                      <List bulleted>
                        <List.Item>{x}</List.Item>
                      </List>
                    </div>
                  );
                })}
              </div>
            </div>
          </Modal.Description>
        </Modal.Content>
        <Modal.Actions>
          <Button
            secondary
            icon="check"
            content="Save and Close"
            onClick={this.handleClose}
          ></Button>
          <Button
            primary
            icon="cancel"
            content="Continue Editing"
            onClick={this.continueEditing}
          ></Button>
        </Modal.Actions>
      </Modal>
    );
  }
}

export default MessageBoxSaveClose;

import React, { Component } from 'react';
import Select from 'react-select'
import { Tab, Input, Button,Checkbox, Menu, Image } from 'semantic-ui-react';
import { ajaxPost } from '../../ajax';
import { handleNumericOnKeyPress, handleNumericOnKeyDown } from '../../utils';
import PdfMake from 'pdfmake/build/pdfmake';
import vfsFonts from 'pdfmake/build/vfs_fonts';

const moment = require('moment');

const paymentModeOptions = [
    { label: 'SSS Salary Loan', value: 'SSS Salary Loan' },
    { label: 'SSS Emergency Loan', value: 'SSS Emergency Loan' }
]

export default class SSSML1 extends Component {
    constructor(props) {
        super(props);

        this.state = {
            selectedCompany: null,
            selectedEmployee: null,
            selectedPaymentMode: null,
            month: moment().format('MM'),
            year: moment().format('YYYY'),

            employeeSearch: '',
            isEmployeeLoading: false,
            isCompanyLoading: false,
            isGenerateLoading: false,
            includeInactive: false,

            companyList: [],
            employeeList: [],

            generatedData: {
                TotalAmountCollected: 0
            },

            totalFields: {
                totalAmountRemitted: 0,
            },

            penalty: 0,
            overPayment: 0,
            underPayment: 0
        }

        this.loadCompanies();
    }

    componentWillMount = () => {
        if (this.props.prevState !== null)
            this.state = this.props.prevState;
    }

    componentWillUnmount = () => {
        this.props.unmountCallback(this.state, 'SSSML1');
    }

    computeTotalFields() {
        var { totalFields, generatedData } = this.state;

        var penalty = this.state.penalty === '' ? 0 : parseFloat(this.state.penalty);
        var overPayment = this.state.overPayment === '' ? 0 : parseFloat(this.state.overPayment);
        var underPayment = this.state.underPayment === '' ? 0 : parseFloat(this.state.underPayment);

        totalFields.totalAmountRemitted = parseFloat(generatedData.TotalAmountCollected) + penalty - overPayment + underPayment;

        this.setState({ totalFields });
    }

    onTextChange = (field, e) => {
        this.setState({ [field]: e.target.value }, () => this.computeTotalFields());
    }

    createTableRow(label, value, disable) {
        var curr = this.state[value];
        return (
            <tr style={{ width: '100%' }}>
                <td style={{ width: '100%', minWidth: '250px' }}>
                    {label}
                </td>
                {disable === false && <td style={{ width: '100%', minWidth: '400px' }}>
                    <Input style={{ border: '1px solid gray' }} disabled={disable} fluid
                        onChange={this.onTextChange.bind(this, value)} value={curr}
                        onKeyPress={(e) => handleNumericOnKeyPress(e)}
                        onKeyDown={(e) => handleNumericOnKeyDown(e)}
                    />
                </td>}
                {disable === true && <td style={{ width: '100%', minWidth: '400px' }}>
                    <Input style={{ border: '1px solid gray' }} disabled={disable} fluid value={value} />
                </td>}
            </tr>
        )
    }

    loadCompanies() {
        this.setState({ isCompanyLoading: true })
        ajaxPost({
            url: 'api/group/search',
            data: {
                Search: '',
                startingIndex: 0,
                itemCount: 999,
                Types: ['COMPANY']
            },
            onSuccess: (data) => {
                this.setState({
                    companyList: data.content.map(x => { return ({ label: x.Name, value: x._id }) }),
                    isCompanyLoading: false
                });
            },
            finally: () => { }
        })
    }

    loadEmployees = () => {
        const { selectedCompany } = this.state;
        this.setState({ isEmployeeLoading: true })
        ajaxPost({
            url: 'api/Employee/searchFilteredEmployee',
            data: {
                Search: this.state.employeeSearch,
                // employeeIds: selectedEmployee !== null ? [selectedEmployee.value] : [],
                CompanyIDs: selectedCompany !== null ? [selectedCompany.value] : [],
                includeDeleted: this.state.includeInactive,
                startingIndex: 0,
                itemCount: 30
            },
            onSuccess: (data) => {
                this.setState({ employeeList: data.content.map(x => { return ({ label: x.FullName, value: x._id }) }) });
                this.setState({ isEmployeeLoading: false })
            },
            finally: () => { }
        })
    }

    onSearch = (text, e) => {
        if (e.action === 'input-blur' || e.action === 'menu-close')
            return

        clearTimeout(this.searchTimer);
        this.setState({ employeeSearch: text });
        this.searchTimer = setTimeout(this.loadEmployees, 400, text);
    }
    onChkCheckChange = (e) => {
        this.setState({
            includeInactive: !this.state.includeInactive, 
        } )
    }
    checkStatus(processId, callback) {
        ajaxPost({
            data: { processId: processId },
            url: 'api/BackgroundProcess/GetStatus',
            onSuccess: data => {
                if (data.Status != "PROCESSING") {
                    callback(processId)
                }
                else {
                    this.checkStatus(processId, callback);
                }
            },
            finally: () => { }
        })
    }

    onExportPDFClick = () => {
        if (this.state.selectedCompany === null) {
            alert("Please select an employee");
            return;
        }

        ajaxPost({
            url: 'api/governmentforms/SSSML1ExportToPDF',
            data: {
                companyId: this.state.selectedCompany.value,
                employeeId: this.state.selectedEmployee.value
            },
            onSuccess: (data) => {
                const { vfs } = vfsFonts.pdfMake;

                PdfMake.vfs = vfs;

                var pdfData = {
                    pageSize: 'LETTER',
                    pageOrientation: 'portrait',
                    background: [
                        {
                            image: data.Image,
                            width: 610,
                            height: 792
                        }
                    ],
                    content: [
                        {
                            text: data.SSSEmployer,
                            style: { fontSize: 6 },
                            absolutePosition: { x: 50, y: 205 }
                        },
                        {
                            text: data.SSSEmployee,
                            style: { fontSize: 6 },
                            absolutePosition: { x: 190, y: 205 }
                        },
                        {
                            text: data.CompanyName,
                            style: { fontSize: 8 },
                            absolutePosition: { x: 50, y: 237 }
                        },
                        {
                            text: data.Address,
                            style: { fontSize: 6 },
                            absolutePosition: { x: 50, y: 250 }
                        },
                        {
                            text: this.state.selectedPaymentMode === null ? "" : this.state.selectedPaymentMode.value,
                            style: { fontSize: 8 },
                            absolutePosition: { x: 330, y: 265 }
                        },
                        {
                            text: moment(this.state.year + '-' + this.state.month + '1').format('MMMM') + ' ' + this.state.year,
                            style: { fontSize: 8 },
                            absolutePosition: { x: 460, y: 265 }
                        },
                        {
                            text: this.state.generatedData.TotalAmountCollected,
                            style: { fontSize: 8 },
                            absolutePosition: { x: 457, y: 310 }
                        },
                        {
                            text: this.state.penalty,
                            style: { fontSize: 8 },
                            absolutePosition: { x: 457, y: 332 }
                        },
                        {
                            text: this.state.overPayment,
                            style: { fontSize: 8 },
                            absolutePosition: { x: 457, y: 358 }
                        },
                        {
                            text: this.state.underPayment,
                            style: { fontSize: 8 },
                            absolutePosition: { x: 457, y: 383 }
                        },
                        {
                            text: this.state.totalFields.totalAmountRemitted,
                            style: { fontSize: 8 },
                            absolutePosition: { x: 457, y: 407 }
                        }

                    ]
                }

                PdfMake.createPdf(pdfData).open();
            },
            finally: () => { }
        })

    }

    onGenerateClick = () => {
        this.setState({ isGenerateLoading: true });
        this.generateSSSML1((processId) => {
            ajaxPost({
                url: 'api/governmentforms/GetSSSML1',
                data: processId,
                onSuccess: (data) => {
                    this.setState({ generatedData: data.content, isGenerateLoading: false }, () => this.computeTotalFields());
                },
                finally: () => { }
            })
        })
    }

    generateSSSML1 = (callback) => {
        if (this.state.selectedEmployee === null) {
            alert("No Employee to be Generated");
            return;
        }

        ajaxPost({
            url: 'api/governmentforms/GenerateSSSML1',
            data: {
                employeeId: this.state.selectedEmployee.value,
                year: this.state.year,
                loanType: this.state.selectedPaymentMode.value
            },
            onSuccess: (value) => {
                this.checkStatus(value, callback);
            },
            finally: () => { }
        })
    }

    render() {
        const { generatedData, totalFields } = this.state;

        return (
            <div>
                <h1>SSS ML-1</h1>

                <div style={{ display: 'flex', overflowY: 'auto', height: window.innerHeight - 203 }}>
                    <div style={{ marginRight: '10px', width: 300 }}>
                        <div>
                            <label>Company</label>
                            <Select value={this.state.selectedCompany} isLoading={this.state.isCompanyLoading} options={this.state.companyList} onChange={(e) => this.setState({ selectedCompany: e, selectedEmployee: null }, this.loadEmployees)} />
                        </div>

                        <div style={{ marginTop: 5 }}>
                            <label>Employee</label>
                            <Select value={this.state.selectedEmployee} isLoading={this.state.isEmployeeLoading} options={this.state.employeeList} onInputChange={this.onSearch} onChange={(e) => this.setState({ selectedEmployee: e })} />
                        </div>
                        <div>
                        <Checkbox size='mini' label="Include Resigned" onChange={this.onChkCheckChange.bind(this)} checked={this.state.includeInactive} />
                        </div>

                        <div style={{ marginTop: 5 }}>
                            <label>Payment Mode</label>
                            <Select value={this.state.selectedPaymentMode} options={paymentModeOptions} onChange={(e) => this.setState({ selectedPaymentMode: e })} />
                        </div>

                        <div style={{ marginTop: 5 }}>
                            <label>Month</label>
                            <Input fluid value={this.state.month}
                                onChange={(e) => this.setState({ month: e.target.value })}
                                onKeyPress={(e) => handleNumericOnKeyPress(e)}
                                onKeyDown={(e) => handleNumericOnKeyDown(e)}
                            />
                        </div>

                        <div style={{ marginTop: 5 }}>
                            <label>Year</label>
                            <Input fluid value={this.state.year}
                                onChange={(e) => this.setState({ year: e.target.value })}
                                onKeyPress={(e) => handleNumericOnKeyPress(e)}
                                onKeyDown={(e) => handleNumericOnKeyDown(e)}
                            />
                        </div>

                        <div style={{ display: 'flex', float: 'right', right: 0 }}>
                            <Button style={{ marginTop: 10 }} content='Generate' floated='right' onClick={this.onGenerateClick.bind(this)} />
                            <Button style={{ marginTop: 10 }} content='Export To PDF' floated='right' onClick={this.onExportPDFClick.bind(this)} />
                        </div>
                    </div>
                    <div style={{ width: window.innerWidth - 620 }}>
                        <table style={{ minWidth: 400, width: '100%', paddingRight: '20px', paddingLeft: '20px' }}>
                            {this.createTableRow('Total Amount Collected', generatedData.TotalAmountCollected, true)}
                            {this.createTableRow('Add: Penalty', 'penalty', false)}
                            {this.createTableRow('Less: Over Payment', 'overPayment', false)}
                            {this.createTableRow('Add: Under Payment', 'underPayment', false)}
                            {this.createTableRow('Total Amount Remitted', totalFields.totalAmountRemitted, true)}
                        </table>
                    </div>
                </div>
            </div>
        )
    }
}
import React, { Component } from 'react';
import { Image, Button } from 'semantic-ui-react';
import 'semantic-ui-css/semantic.min.css';
import TransactionModuleBase from './TransactionModuleBase2';
import { ajaxPost, authPost } from '../../ajax';
import ViewNotificationDetails from './Commons/ViewNotificationDetails';
import DTRCorrectionForm from './Forms/DTRCorrectionForm';
import { isTaskAvailable, checkDate, checkTime, momentDateFormat, fnAsyncSearchEmployee } from '../../utils';
import MessageBoxOkOnly from '../../Commons/MessageBoxOkOnly';
import { SelectColumn } from 'react-data-grid';

const ViewID = "DTRCorrection";
const ViewCaption = "DTR Correction";

const moment = require('moment');
const updateStatus = "api/DTRCorrection/updateStatus";

const applicationStatuses = [
	'PENDING',
	'APPROVED',
	'CANCELLED',
	'REJECTED'
];
const logTypes = [
	{ key: "IN", value: "0", text: "IN" },
	{ key: "OUT", value: "1", text: "OUT" },
	{ key: "BREAK OUT", value: "2", text: "BREAK OUT" },
	{ key: "BREAK IN", value: "3", text: "BREAK IN" },
	{ key: "OVERTIME OUT", value: "5", text: "OVERTIME OUT" },
	{ key: "OVERTIME IN", value: "4", text: "OVERTIME IN" }
];
const userRightsKeys = {
	view: "DTR_CORRECTION_VIEW",
	create: "DTR_CORRECTION_CREATE",
	edit: "DTR_CORRECTION_EDIT",
	delete: "DTR_CORRECTION_DELETE",
	restore: "DTR_CORRECTION_RESTORE",
	approve: "DTR_CORRECTION_APPROVE",
	reject: "DTR_CORRECTION_REJECT",
	pend: "DTR_CORRECTION_PEND",
	cancel: "DTR_CORRECTION_CANCEL",
	export: "DTR_CORRECTION_EXPORT_TO_EXCEL",
	import: "DTR_CORRECTION_IMPORT"
}


class DTRCorrectionList extends Component {

	constructor(props) {
		super(props);
		this.state = {
			viewType: "hr",
			modalVisible: false,
			fromDate: moment().format(props.startDate),
			toDate: moment().format(props.endDate),
		}
		this.modalRef = React.createRef();
		this.parentRef = React.createRef();
	}

	createUrls = () => {
		return {
			getStatusCounts: "api/DTRCorrection/getStatusCounts",
			search: "api/DTRCorrection/search",
			setDeleted: "api/DTRCorrection/setDeleted",
			saveMultiple: "/DTRCorrection/saveMultiple",
			load: "api/DTRCorrection/load",
			searchEmployees: "api/DTRCorrection/searchEmployees",
			updateStatus: updateStatus,
			exportToExcel: "api/DTRCorrection/exportToExcel",
			downloadTemplate: "api/DTRCorrection/downloadTemplate",
			importFromExcel: "api/DTRCorrection/importFromExcel"
		};
	}

	toRow = (model) => {
		if( model.hasOwnProperty("id")){
			return {
				id: model.id,
				employeeId: model.employeeId,
				deleted: model.IsDeleted === null ? false : model.IsDeleted,
				employee: model.employee,
				date: moment(model.date).format(momentDateFormat),
				time: moment(model.time, "HH:mm").format("hh:mm A"),
				nextDay: model.nextDay ? "Yes" : "No",
				type: logTypes.filter(x => x.value === model.type)[0].text,
				status: model.status,
				remarks: model.remarks,
				approver: model.approver,
				approverId: model.approverId,
				approverRemarks: !model.approverRemarks ? "" : model.approverRemarks,
				changeStatusDate: !model.changeStatusDate ? "" : moment(model.changeStatusDate).format(momentDateFormat),
				requestDate: moment(model.requestDate).format(momentDateFormat),
				file:model.AttachmentDetail != null ? model.AttachmentDetail.File : "",
				actions:model.AttachmentDetail != null && model.AttachmentDetail.File != "" && model.AttachmentDetail.File != null ?                                               
					<div style={{display:'flex', width:'100%', height:'100%', justifyContent:'center', alignItems:'center' }}>
						<Button style={{fontSize: '0.8rem'}}
							icon="download"
							circular
							onClick={this.downloadDoc.bind(
							this,
							model.AttachmentDetail.UniqueFilename,
							model.AttachmentDetail.File
							)}	
						/> 
					</div>: "",
				AttachmentDetail: model.AttachmentDetail != null && model.AttachmentDetail.File != "" && model.AttachmentDetail.File != null ? model.AttachmentDetail : "",
				editable: false,
				idle: false
			};
		}

		return {
			id: model._id,
			employeeId: model.EmployeeId,
			deleted: model.IsDeleted === null ? false : model.IsDeleted,
			employee: model.Employee,
			date: moment(model.Date).format(momentDateFormat),
			time: moment(model.Time).format("hh:mm A"),
			nextDay: model.NextDay ? "Yes" : "No",
			type: logTypes.filter(x => x.value === model.Type)[0].text,
			status: model.Status,
			remarks: model.Remarks,
			approver: model.Approver,
			approverId: model.ApproverId,
			approverRemarks: model.ApproverRemarks,
			changeStatusDate: !model.ChangeStatusDate ? "" : moment(model.ChangeStatusDate).format(momentDateFormat),
			requestDate: moment(model.RequestDate).format(momentDateFormat),
			file:model.AttachmentDetail != null ? model.AttachmentDetail.File : "",
			actions:model.AttachmentDetail != null && model.AttachmentDetail.File != "" && model.AttachmentDetail.File != null ?                                               
				<div style={{display:'flex', width:'100%', height:'100%', justifyContent:'center', alignItems:'center' }}>
					<Button style={{fontSize: '0.8rem'}}
						icon="download"
						circular
						onClick={this.downloadDoc.bind(
						this,
						model.AttachmentDetail.UniqueFilename,
						model.AttachmentDetail.File
						)}	
					/> 
				</div>: "",
			AttachmentDetail: model.AttachmentDetail,
			editable: false,
			idle: false
		};
	};

	downloadDoc(filename, origName) {
        window.location =
            global.ApiHost +
            "api/TransactionsCommon/download?" +
            "fileName=" +
            filename +
            "&" +
            "originalName=" +
            origName;
    }

	toModel = (row) => {
		return {
			_id: row.hasOwnProperty("_id") ? row._id :  row.id,
			EmployeeId: row.employeeId,
			Date: moment(row.date).toJSON(),
			Time: moment(row.date + " " + row.time).toJSON(),
			NextDay: row.nextDay === "Yes",
			Type: logTypes.filter(x => x.text === row.type)[0].value,
			Status: row.status,
			File: row.file,
			AttachmentDetail: row.AttachmentDetail,
			details: row.AttachmentDetail.File,
			Remarks: row.remarks
		};
	};

	createColumns = () => {
		var self = this;
		let hasHrAccess = isTaskAvailable("HR_ROLE");
		let columns = [
			SelectColumn,
			{ key: 'date', name: 'Date', width: 90, resizable: true},
			{ key: 'time', name: 'Time', width: 90, resizable: true},
			{ key: 'nextDay', name: 'Next Day?', width: 90, resizable: true},
			{ key: 'type', name: 'Type', width: 70, resizable: true},
			{ key: 'status', name: 'Status', width: 90, resizable: true},
			{ key: 'remarks', name: 'Reasons', width: 150, resizable: true},
			{ key: 'changeStatusDate', name: 'Change Status Date', width: 130, resizable: true },
			{ key: 'approver', name: 'Approver', width: 220, resizable: true },
			{ key: 'approverRemarks', name: 'Approver Remarks', width: 150, resizable: true },
			{ key: 'requestDate', name: 'Date Filed', width: 90, resizable: true },
			{ key: 'file', name: 'File', width: 100, resizable: true },
			{ key: 'actions', name: 'Actions', width: 90, resizable: true },
		];
		// if (viewType === "hr") {
		columns.unshift({
			key: 'employee', name: 'Employee', width: 220, resizable: true
		});
		// }
		return columns;
	}

	checkNoDuplicates = async  (data) => {
		var response = await authPost( '/DTRCorrection/CheckDuplicates', data);
		var result = await response.json();

		if( result.length == 0 ){
			return true;
		}

		var employees = await fnAsyncSearchEmployee( 
			"", 
			0,
			2147483647,
			false,
			null,
			result
		);

		var empString = employees.map( x=> `${x.LastName}, ${x.FirstName}`)

		this.parentRef.current.messageBoxOkOnlyOpen(true, "Duplicate Entries", 
			"Cannot proceed because this action will cause duplicate entries for\n" + empString.join('\n'));

		return false;
	}

	validate = async (data) => {
		var errTitle = "", errCaption = "";
		var checkDateParam = checkDate(data.date);
		var checkTimeParam = checkTime(data.time);
		if (this.isNullOrEmpty(data.employeeIds) ||
			this.isNullOrEmpty(data.remarks)
		) {
			errCaption = "Please fill up all required field/s";
			errTitle = "Invalid";
		}

		else if (!checkDateParam.Result) {
			errCaption = checkDateParam.Message;
			errTitle = checkDateParam.Title;
		}
		else if (!checkTimeParam.Result) {
			errCaption = checkTimeParam.Message;
			errTitle = checkTimeParam.Title;
		}
		else {
			this.parentRef.current.setState({ showRequiredField: false });

			return await this.checkNoDuplicates(data);
		}
		this.parentRef.current.setState({ showRequiredField: true, errTitle, errCaption });
		return false;
	};

	showModal = (data, visible) => {
		visible && this.modalRef.current.setContent(data);
		this.setState({ modalVisible: visible });
	}

	isNullOrEmpty(val) {
		if (val === null || val === undefined || val === "" || val.length === 0) return true;
		else return false;
	}

	update = async( content ) => {
		content.excludedId = content.id;
		var isValid = await this.validate(content);

		if( isValid === false){
			return;
		}

		content.employeeId = content.employeeIds[0];
		await authPost( '/DTRCorrection/Update', content);

		this.parentRef.current.updateRow( content );
		this.showModal({}, false);		

	}

	saveMultiple = async (content) => {

		var isValid = await this.validate(content);

		if( isValid === false){
			return;
		}

		var response = await authPost( '/DTRCorrection/CreateMultiple', content);
		var result = await response.json();

		var empIds = result.map( x=> x.employeeId );
		var employees = await fnAsyncSearchEmployee( 
			"", 
			0,
			2147483647,
			false,
			null,
			empIds
		);

		result.forEach( x => {
			var emp = employees.filter( y => y._id == x.employeeId );
			if( emp.length > 0 ){
				x.employee = `${emp[0].LastName}, ${emp[0].FirstName}`;
			}
		})

		this.parentRef.current.addRows( result );
		this.showModal({}, false);		
	}

	onSave = () => {
		let content = this.modalRef.current.getContent();

		if (content.id) {
			this.update(content);
		}
		else {
			this.saveMultiple(content)
		}
	}

	render() {
		let self = this;
		return (
			<TransactionModuleBase
				ref={this.parentRef}
				startDate={this.state.fromDate}
				endDate={this.state.toDate}
				viewID={ViewID}
				title={ViewCaption}
				columns={this.createColumns()}
				toModel={this.toModel}
				toRow={this.toRow}
				urls={this.createUrls()}
				validate={this.validate}
				props={this.props}
				showModal={this.showModal}
				userRightsKeys={userRightsKeys}
				hasExportToExcel={true}
			>
				<DTRCorrectionForm
					ref={this.modalRef}
					open={this.state.modalVisible}
					errTitle={self.parentRef.current ? self.parentRef.current.state.errTitle : ""}
					errCaption={self.parentRef.current ? self.parentRef.current.state.errCaption : ""}
					showRequiredField={self.parentRef.current ? self.parentRef.current.state.showRequiredField : ""}
					updateEmployeeIds={(Ids) => { self.parentRef.current.updateEmployeeIds(Ids) }}
					isBusy={self.parentRef.current ? self.parentRef.current.state.isSaving : false}
					onCancel={() => { 
						this.setState({ modalVisible: false }, self.parentRef.current.setState({ showRequiredField: false })); 
					}}
					onCreateEmpClick={() => {
						this.setState({ modalVisible: false });
						this.props.createEmpCallback();
					}}
					onSave={this.onSave}
				></DTRCorrectionForm>
			</TransactionModuleBase>
		);
	}

}

DTRCorrectionList.getViewID = () => { return ViewID; };
DTRCorrectionList.getCaption = () => { return ViewCaption; };
DTRCorrectionList.getIcon = () => { return (<Image style={{ height: "24px", width: "24px" }} src="/Icons/DTR_Correction.png" avatar />); };
DTRCorrectionList.getIconSrc = (size) => { return '/Icons/DTR_Correction.png' }
DTRCorrectionList.getViewAccessKey = function () {
	var count = Object.keys(userRightsKeys).filter(name => isTaskAvailable(userRightsKeys[name]) === true).length;
	return count > 0 ? true : false;
};
DTRCorrectionList.init = function (parent) {
	var handleDTRCorrectionAction = function (status, data, callback) {
		var message = "";
		var title = data.Title;
		if (status === "CLOSE") 
			parent.setState({ external: null })
		else {
			var items = [];
			items.push(data.Data);
			var dataItems = { items: items, status: status, remarks: data.Data.ApproverRemarks };
			dataItems.fromDate = moment(data.Data.Date).toJSON();
			dataItems.toDate = moment(data.Data.Date).toJSON();
			var parameter = {
				data: dataItems,
				url: updateStatus,
				onError: function (error) {
					alert("Error occured");
				},
				onSuccess: (data, sender) => {
					if (data[data.length - 1].hasOwnProperty("errMessage")) {
						let lastIndex = data.length - 1;
						let titleSingular = title.toLowerCase() === 'official businesses' ? title.substring(0, title.length - 2) : title.substring(0, title.length - 1);
						title = "Invalid";
						message = status === "APPROVED" ? "Already approved " + titleSingular.toLowerCase() + " request has been found." : data[lastIndex]["errMessage"];
					}
					else {
						message = data[0].Message;
					}
					parent.setState({
						external:
							<MessageBoxOkOnly
								title={title}
								caption={message}
								onClick={() => {
									parent.setState({ external: null });
								}}
							/>
					});
				},
				finally: function () {
					callback(message, status);
				}
			};
			ajaxPost(parameter);
		}
	};
	var notificationAction =
		function (parent, action, data, callback) {
			var status = "";
			switch (action.Action) {
				case "Approve": status = "APPROVED"; break;
				case "Reject": status = "REJECTED"; break;
				case "Cancel": status = "CANCELLED"; break;
				default: status = "View"; break;
			}

			//var actionTaken = data["ActionSelection"];
			if (data["ActionTaken"] === "NONE" && data["IsActionable"] === false) {
				data["ActionTaken"] = "READ";
				ajaxPost(
					{
						url: "api/Notification/markAsRead",
						data: data._id,
						onSuccess: data => { },
						finally: () => { }
					}
				)
			}

			ajaxPost({
				url: "api/Notification/loadDataOnly",
				data: data["_id"],
				onSuccess: x => {
					data["Data"] = x;
				},
				finally: () => {
					data["ActionTaken"] = data["ActionTaken"].toString() === "" ? "NONE" : data["ActionTaken"];

					if (status === "View") {
						var viewData = {
							"Date": moment(data.Data["Date"]).format(momentDateFormat),
							"Time": moment(data.Data["Time"],'hh:mm').format("hh:mm A"),
							"Log Type": logTypes.filter(log => log.value === data.Data["Type"] || log.text === data.Data["Type"])[0].text,
							"Next Day": data.Data["NextDay"] ? "Yes" : "No",
							"Status": data.Data["Status"],
							"Remarks": data.Data["Remarks"],
							"Description": data["Description"],
						};
						if (data.Data["ApproverRemarks"]) {
							viewData["Approver Remarks"] = data.Data["ApproverRemarks"];
						}
						parent.setState({ external: <ViewNotificationDetails Title={data.Title} data={data} viewData={viewData} callback={callback} onAction={this.handleDTRCorrectionAction.bind(this)} /> });
					}
					else {
						data.Data["ApproverRemarks"] = "";
						this.handleDTRCorrectionAction(status, data, callback);
					}
				}
			});
		}
	parent.FunctionCollection["DTRCorrectionNotification"] = notificationAction;
	parent.FunctionCollection["handleDTRCorrectionAction"] = handleDTRCorrectionAction;
}

export default DTRCorrectionList;
import React, { Component } from 'react';
import 'semantic-ui-css/semantic.min.css';
import { Icon, Message, Sidebar, SidebarPushable, SidebarPusher, Menu, Accordion, Button, Header, Container, Divider, List, Form, Input } from 'semantic-ui-react';
import { ajaxPost } from '../ajax';
import AnnouncementTrail from './Announcements/AnnouncementTrail';
import AttendanceTrail from './Attendance/AttendanceTrail';
import './Dashboard.css';
import { _dashboardModules } from './ModuleList';
import PendingCountTrail from './Transactions/PendingCountTrail';

var { isTaskAvailable, empState, checkDateRange } = require('../utils');
var moment = require('moment');
var dt = moment().local();

const BlankItem = {
    Title: "",
    Message: "",
    StartDate: dt,
    EndDate: dt,
    RecordDate: dt,
}



const separationTypeList = [
    { text: 'Resignation', value: 'RESIGNATION' },
    { text: 'Termination', value: 'TERMINATION' },
    { text: 'Retirement', value: 'RETIREMENT' }
];


class Dashboard extends Component {

    constructor(props) {
        super(props);

        if (props.announcement == null) {
            this.state.announcement = JSON.parse(JSON.stringify(BlankItem));
        } else {
            this.state.announcement = props.announcement;
        }
    }

    state = {
        incEmpList: [],
        sidebarOpen: false,
        announcementFormOpen: false,
        announcement: null
    }

    styles = {
        sideBar: {
            width: '400px',
            paddingLeft: '2em',
            paddingRight: '2em'
        },
        sideBarHeader: {
            marginTop: '20px',
            paddingTop: '5px'
        },
        empList: {
            width: '350px',
            margin: '20px',
            textAlign: 'left',
            overflowY: 'auto',
            height: '500px'
        },
        notes: {
            margin: '20px'
        },
        backButton: {
            marginRight: '10px'
        },
        formFieldLabel: {
            textAlign: 'left'
        }
    }

    componentDidMount() {
        this.getIncEmpList();
    }
    getIncEmpList = () => {
        ajaxPost({
            url: 'api/Employee/getEmpWithIncompleteData',
            onSuccess: data => {
                this.setState({ incEmpList: data });
            },
            finally: () => { }
        });
    }

    onModuleUnmount = (saveState, viewID) => {
        var tmp = this.state.saveStates;
        tmp[viewID] = saveState;
        this.setState({ tmp: saveState });
    }

    onUpdateEmpClick = (id, noDataFields) => {
        var employee = null;
        ajaxPost(
            {
                url: "api/employee/load",
                data: {
                    employeeID: id
                },
                onSuccess: data => {
                    employee = JSON.parse(JSON.stringify(data));
                    var params = empState;
                    params.selectedEmployee = employee;
                    params.noDataFields = noDataFields;
                    this.setState({ params: params });
                    this.props.OnUpdateEmpClick(params);
                },
                finally: () => { }
            }
        );
    }

    handleChange = name => event => {

        this.setState({ isValid: event.target.value != null });
        var current = this.state.announcement;
        current[name] = event.target.value;
        this.setState({ announcement: current });
    };

    onSave() {
        this.setState({ isErrorTitle: false, isErrorMessage: false });

        if (this.state.announcement.Title === "") {
            this.setState({ Message: "Title is require", isErrorTitle: true })
        }
        if (this.state.announcement.Message === "") {
            this.setState({ Message: "Message is require", isErrorMessage: true })
        }
        if (this.state.announcement.Title === "" || this.state.announcement.Message === "")
            return;

        var res = checkDateRange(moment(this.state.announcement["StartDate"]).format("YYYY-MM-DD"), moment(this.state.announcement["EndDate"]).format("YYYY-MM-DD"));
        if (!res.Result) {
            this.setState({ Message: res.Message.replace(" Previous valid date range is going to restore.", "").replace("Previous valid start date is going to restore.", "").replace("Previous valid end date is going to restore.", ""), isError: !res.Result })
            return;
        }

        this.state.announcement.StartDate = this.state.announcement.StartDate === "" ? "1900-02-01" : this.state.announcement.StartDate;
        this.state.announcement.EndDate = this.state.announcement.EndDate === "" ? "9999-11-01" : this.state.announcement.EndDate;
        this.state.announcement.RecordDate = new Date();
        ajaxPost({
            url: 'api/Announcement/save',
            data: this.state.announcement,
            onSuccess: data => {
                this.setState({ announcementFormOpen: false });
            },
            finally: () => { }
        });
    }

    render() {

        const panels = this.state.incEmpList.map((item) => {
            return (
                {
                    key: item._id,
                    title: item.FullName,
                    content: {
                        content: (
                            <div>
                                <List bulleted animated>
                                    {item.NoDataFields.map(item => {
                                        return (
                                            (<List.Item>
                                                <Header as='h6' color='red' size='tiny' textAlign='left'>{item}</Header>
                                            </List.Item>)
                                        );
                                    })}
                                </List>
                                <br />
                                <Button basic primary size='mini' fluid onClick={this.onUpdateEmpClick.bind(this, item._id, item.NoDataFields)}>Update Employee</Button>
                            </div>
                        )
                    }
                }
            )
        });

        var dic = {};
        dic["Dashboard"] = [];
        _dashboardModules.forEach(i => {
            var add = true;
            if ("getViewAccessKey" in i.Module &&
                i.Module.getViewAccessKey() === false)
                add = false;

            if (add)
                dic["Dashboard"].push(i);
        });
        var createModuleInstance = (itemModule) => {
            if (itemModule == null)
                return null;

            return React.createElement(itemModule,
                {
                    unmountCallback: this.onModuleUnmount,
                    prevState: this.state.saveStates[itemModule.getViewID()],
                    parent: this,
                }, null)
        }

        return (

            <div style={{ height: '100%', OverflowY: 'auto', overflowX:'hidden'}}>
                <div >
                    <Sidebar
                        as={Menu}
                        direction='right'
                        animation='overlay'
                        vertical
                        visible={this.state.sidebarOpen}
                        width='thin'
                        style={this.styles.sideBar}>
                        <div>
                            <Button circular icon='close' floated='left' style={this.styles.backButton} onClick={() => { this.setState({ sidebarOpen: false }) }}></Button>
                            <Header textAlign='left' style={this.styles.sideBarHeader}>
                                <Header.Content>
                                    Reminder
                                </Header.Content>
                            </Header>
                            <p style={this.styles.notes}>Kindly complete the details of the following employee/s. These information are needed to include them in the upcoming payrun.</p>
                            <Accordion styled style={this.styles.empList} panels={panels} defaultActiveIndex={0}></Accordion>
                        </div>
                    </Sidebar>
                    <Sidebar
                        as={Menu}
                        direction='right'
                        animation='overlay'
                        vertical
                        visible={this.state.announcementFormOpen}
                        // width='thin'
                        style={this.styles.sideBar}>
                        <div>
                            <Button circular icon='close' floated='left' style={this.styles.backButton} onClick={() => { this.setState({ announcementFormOpen: false }) }}></Button>
                            <Header textAlign='left' style={this.styles.sideBarHeader}>
                                <Header.Content>
                                    Add Announcement
                                </Header.Content>
                            </Header>
                        </div>
                        <br />
                        <Container>
                            <Form widths='equal'>
                                <Form.Field
                                    id='txtTitle'
                                    control={Input}
                                    label='Title'
                                    value={this.state.announcement["Title"]}
                                    placeholder='Title'
                                    onChange={this.handleChange("Title").bind(this)}
                                    error={this.state.isErrorTitle}
                                >
                                </Form.Field>
                                <Form.Field
                                    id='txtMessage'
                                    control={Input}
                                    label='Message'
                                    value={this.state.announcement["Message"]}
                                    placeholder='Message'
                                    onChange={this.handleChange("Message").bind(this)}
                                    error={this.state.isErrorMessage}
                                >
                                </Form.Field>
                                <Form.Field
                                    id='dtpStartDate'
                                    control={Input}
                                    type='date'
                                    label='From'
                                    placeholder='Start Date'
                                    value={moment(this.state.announcement["StartDate"]).format("YYYY-MM-DD")}
                                    onChange={this.handleChange("StartDate").bind(this)}
                                >
                                </Form.Field>
                                <Form.Field
                                    id='dtpEndDate'
                                    control={Input}
                                    type='date'
                                    label='To'
                                    placeholder='End Date'
                                    value={moment(this.state.announcement["EndDate"]).format("YYYY-MM-DD")}
                                    onChange={this.handleChange("EndDate").bind(this)}
                                >
                                </Form.Field>
                                {this.state.isError && <label style={{ color: 'red' }}>{this.state.Message}</label>}
                                <Button onClick={this.onSave.bind(this)}>Save</Button>
                            </Form>
                        </Container>
                    </Sidebar>
                </div>
                <SidebarPusher>
                    <br />
                    <AnnouncementTrail onModuleSelect={this.props.onModuleSelect} onAddAnnouncement={() => { this.setState({ announcementFormOpen: true }) }} />
                    <br />
                    {
                        this.state.incEmpList.length > 0 && isTaskAvailable("INCOMPLETE_EMPLOYEEDATA_NOTIFCATION") &&
                        <Message negative>
                            <Icon name='warning sign' />
                            There are&nbsp;<b><a href='#' onClick={() => { this.setState({ sidebarOpen: !this.state.sidebarOpen }) }}>{this.state.incEmpList.length} Employees(s)</a></b>&nbsp;with incomplete details needed to
                            include them on upcoming payroll run.
                        </Message>
                    }
                    <br />
                    {
                        isTaskAvailable("TODAY_ATTENDANCE_VIEW") &&
                        <AttendanceTrail />
                    }
                    {
                        isTaskAvailable("TRANSACTION_PENDING_VIEW") &&
                        <PendingCountTrail onModuleSelect={this.props.onModuleSelect} />
                    }
                </SidebarPusher>
            </div>
        )
    }

}

export default Dashboard;
import React, { Component } from 'react';
import 'semantic-ui-css/semantic.min.css';
import Select from 'react-select';
import { Popup, List, Divider, Loader, Menu, Button, Icon, Pagination, Input, Modal, Card, Form } from 'semantic-ui-react';
import MessageBoxYesNo from '../../Commons/MessageBoxYesNo';
import MessageBoxOkOnly from '../../Commons/MessageBoxOkOnly';
import PropTypes from 'prop-types';
import { ajaxPost } from '../../ajax';
import { isTaskAvailable, ajaxPostDownload, checkDateRange, minDate, maxDate, checkDate, empty_id, customStyles, errorStyles, } from '../../utils';
import SelectEmployees from '../../Commons/SelectEmployees';
import _ from 'lodash';
import modStyles from './PayrollTransactionBaseWithDate.module.css'


const fileDialog = require('file-dialog');

const moment = require('moment');
const allEmployeeAccessString = "VIEW_ALL_EMPLOYEES";

const TransactionTypes = [
    { label: "All", value: '' }, 
    { label: "Taxable Income", value: "TAXABLE_INCOME" },
    { label: "Non-Taxable Income", value: "NON_TAXABLE_INCOME" },
    { label: "Taxable Deduction", value: "TAXABLE_DEDUCTION" },
    { label: "Non-Taxable Deduction", value: "NON_TAXABLE_DEDUCTION" },
    { label: "Tax", value: "TAX" },
    { label: "Employer Contribution", value: "EMPLOYER_CONTRIBUTION" },
    { label: "Government Contribution", value: "GOVERNMENT_CONTRIBUTION" }
    
]

class PayrollTransactionBaseWithDate extends Component {
    
    componentWillMount() {

        window.addEventListener('resize', this.handleResize);
        this.handleResize = this.handleResize.bind(this);

        if (this.props.props.prevState !== undefined) {
            this.state = this.props.props.prevState;
        }
        else {
            var monthData = moment().format("YYYY-MM");
            var daysInMonth = moment(monthData, "YYYY-MM").daysInMonth();
            var startOfMonth = moment(monthData + "-01").format("YYYY-MM-DD");
            var endOfMonth = monthData + "-" + daysInMonth;

            this.state = {
                userId: null,
                hasHrAccess: isTaskAvailable(allEmployeeAccessString),

                fromDate: startOfMonth,
                toDate: endOfMonth,
                validFromDate: startOfMonth,
                validToDate: endOfMonth,

                isLoading: false,
                exportTemplateLoading: false,
                importLoading: false,
                isSaving: false,

                activePage: 1,
                totalPages: 1,
                itemsPerPage: 20,
                gridHeight: window.innerHeight - 250,

                cards: [],
                selectedId: "",
                selectedEmployees: [],

                selectedType: [],

                showNewButton: true,
                showDeleteButton: false,
                showRestoreButton: false,
                showEditButton: false,
                showExportButton: false,

                hideSearchToolbar: false,

                showRestoreModal: false,
                showDeleteModal: false,

                filters: {},
                includeDeleted: false,
                filterPrevState: [],

                messageBoxOkOnly: {
                    show: false,
                    title: "",
                    caption: "",
                },
                // isMobileView: this.isMobileView(),
                showRequiredField: false,
                errTitle: "",
                errCaption: "",

                queryString: "",

                //TRANSACTION TYPE - ADJUSTMENT TYPE
                showFiltersModal: false,
                selectedTransactionType: '', 
                isTransactionTypeError: false,
                selectedPayrollAdjustmentType: null,
                isAdjustmentTypeError: false,

                Model: {
                    PayrollAdjustmentTypeID: empty_id,
                    TransactionType: "",            
                },
            };

            this.search();
            this.setState({ selectedPayrollAdjustmentType: null });

        }
    }

    //ADJUSTMENT TYPE
    handlePayrollAdjustmentTypeChange = value => {
        var { Model } = this.state;
        if (value !== undefined){
            Model.PayrollAdjustmentTypeID = value.value;
        }
        else {
            Model.PayrollAdjustmentTypeID = empty_id;
        }

        this.setState({ selectedPayrollAdjustmentType: value, Model : Model, isAdjustmentTypeError: false })
    }

    //TRANSACTION TYPE
    handleSelectChange = (name, errorVariable, selectedOption) => {
        const current = this.state.Model;
        current[name] = selectedOption ? selectedOption.value : ''; 
    
        this.setState({ Model: current });
    
        if (errorVariable !== undefined) {
          this.setState({ [errorVariable]: false });
        }
      }

    loadInitialData(){
        var self = this;
        this.setState({ isLoading: true });
        this.setState({ isAdjustmentTypeError: false, isTransactionTypeError: false, });

            var parameter = {
                queryString: "",
                includeDeleted: false,
                startIndex: 0,
                itemCount: -1
            };
            var searchParameter = {
                data: parameter,
                url: "api/PayrollAdjustmentType/search",
                onError: (error) => {
                },
                onSuccess: (retData, sender) => {
                    if (retData.content.length === 0) {
                        let current = self.state.Model;
                        self.setState({ Model: current });
                        return;
                    }
                    var newOptions = retData.content.map((item) => {
                        return {
                            label: item.Name,
                            key: item._id,
                            text: item.Name,
                            value: item._id,
                        }
                    });
                    newOptions.unshift({
                        label:"All",
                        key: empty_id,
                        text: "All",
                        value: empty_id,
                    })
                    self.setState({ payrollAdjustmentTypeList: newOptions});
                },
                finally: () => {
                    self.setState({ isLoading: false });
                }
            };
            ajaxPost(searchParameter);
    }
    
    isMobileView() {
        var isMobile = window.innerWidth <= 760;
        return isMobile;
    }

    getUserId = () => {
        var parameter = {
            data: {},
            url: "api/TransactionsCommon/getUserId",
            onSuccess: (data, sender) => {
                this.setState({ userId: data });
            },
            onError: (error) => {
                let errorMessage = "An error has occured.";
                if (error) {
                    errorMessage += "\r\n" + JSON.stringify(error);
                }
                this.messageBoxOkOnlyOpen(true, "Error", errorMessage);
            },
            finally: () => {

            }
        }
        ajaxPost(parameter);
    }

    componentDidMount() {
        // this.getUserId();
        this.setState({ selectedPayrollAdjustmentType: { label: "All", key: empty_id, text: "All", value: empty_id } });

        this.handleResize();
    }

    componentWillUnmount() {
        this.props.props.unmountCallback(this.state, this.props.viewID);
        window.removeEventListener('resize', this.handleResize);
    }

    handleResize = () => {
        // this.setState({ isMobileView: this.isMobileView() });
        if (document.getElementById("table") === null) {
            return;
        }
        let headerMargin = 50;
        let footerMargin = 35;
        let gridTop = (document.getElementById("table")).offsetTop;
        var gridHeight = window.innerHeight - (headerMargin + gridTop + footerMargin);
        this.setState({ gridHeight });
    }

    updateButtonsVisibility = (optionalMethod = () => { }) => {

        this.setState({
            // showNewButton: true,
            // showDeleteButton: isTaskAvailable(this.props.userRightsKeys.delete) && this.state.selectedCardIds.length > 0 && deletableItemsCount > 0,
            // showEditButton: isTaskAvailable(this.props.userRightsKeys.edit) && this.state.selectedCardIds.length === 1,
            // hideSearchToolbar: false,
        },
            optionalMethod
        );
    };

        search = () => {
        var fromDate = this.state.fromDate;
        var toDate = this.state.toDate;
        var checkDateParams = checkDateRange(fromDate, toDate)
        if (!checkDateParams.Result) {
            this.messageBoxOkOnlyOpen(true, checkDateParams.Title, checkDateParams.Message);
            fromDate = this.state.validFromDate;
            toDate = this.state.validToDate;
            this.setState({ fromDate, toDate });
            return;
        }
        else {
            this.setState({ validFromDate: fromDate, validToDate: toDate });
        }
        this.setState({ isLoading: true });

        var parameter = {
            // typeID: typeID,
            fromDate: moment(fromDate).toJSON(),
            toDate: moment(toDate).toJSON(),
            employeeIds: this.state.selectedEmployees,
            includeDeleted: this.state.includeDeleted,
            startIndex: (this.state.activePage - 1) * this.state.itemsPerPage,
            itemCount: this.state.itemsPerPage,
            selectedPayrollAdjustmentTypeId: this.state.Model.PayrollAdjustmentTypeID,
            selectedTransactionType: this.state.Model.TransactionType,
        };
        var searchParameter = {
            data: parameter,
            url: this.props.urls.search,
            onError: (error) => {
                var errMessage = "An error has occured";
                if (error) {
                    errMessage += "\r\n" + JSON.stringify(error);
                }
                this.messageBoxOkOnlyOpen(true, "Error", errMessage);
            },
            onSuccess: (data, sender) => {
                var cards = data.content.map((model, index, array) => { return this.props.toCard(model) });
                var totalPages = data.count / this.state.itemsPerPage;
                totalPages -= totalPages % 1;
                if (data.count > totalPages * this.state.itemsPerPage) {
                    totalPages++;
                }
                this.setState(
                    {
                        cards: cards,
                        totalPages: totalPages,
                        selectedIndices: [],
                        selectedCardIds: [],
                    },
                    () => { this.updateButtonsVisibility(); }
                );

            },
            finally: () => {
                this.setState({ isLoading: false });
            }
        };
        ajaxPost(searchParameter);
    }
  
    messageBoxOkOnlyOpen = (isOpen, title = "", caption = "") => {
        var messageBoxOkOnly = {
            show: isOpen,
            title: title,
            caption: caption,
        };
        this.setState({ messageBoxOkOnly });
    }

    btnSearchFilter_click = () => {
        this.setState({ showFiltersModal: false });
        this.setState({ activePage: 1 }, () => { this.search() });
    };

    btnSearch_click = () => {
        this.setState({ activePage: 1 }, () => { this.search() });
    };

    btnFilter_click = () => {
        this.loadInitialData();
        this.setState({ showFiltersModal: true })
    }

    btnAdd_click = () => {
        this.props.showModal(null, true);
    }

    btnEdit_Click = (_id, event, { target }) => {
        this.loadData(_id);
    };

    loadData = (_id) => {
        var requestParameter = {
            data: { "_id": _id },
            url: this.props.urls.load,
            onSuccess: (data, sender) => {
                this.setState({ showRequiredField: false },
                    () => { this.props.showModal(data, true); }
                );
            },
            onError: (error) => {
                this.messageBoxOkOnlyOpen(true, "Error", "Failed to load data.")
            },
            finally: () => { }
        };
        ajaxPost(requestParameter);
    }

    saveFromModal = (data) => {
        let self = this;
        let isValid = false;

        isValid = this.props.validate(data);
        if (!isValid) {
            return;
        }

        this.setState({ isSaving: true });
        let addNew = this.isNullOrEmpty(data._id) || data._id == empty_id;

        var parameter = {
            data: {
                model: JSON.stringify(data)
            },
            url: this.props.urls.save,
            onSuccess: (data, sender) => {
                if (data.hasOwnProperty("errors") && Object.keys(data.errors).length > 0) {
                    isValid = false;
                    this.setState({
                        showRequiredField: true,
                        errTitle: "Conflict !",
                        errCaption: data.errors.map(err => { return (<p>{err}</p>) })
                        // errCaption: Object.keys(data.errors).map(errTitle => {
                        //     return(
                        //         <div>
                        //             {/* {errTitle.toUpperCase()}
                        //             <ul> */}
                        //                 {data.errors[errTitle].map(errMsg => {
                        //                     return(
                        //                         <p>{errMsg}</p>
                        //                     );
                        //                 })}
                        //             {/* </ul> */}
                        //         </div>
                        //     );
                        // })
                    });
                    self.messageBoxOkOnlyOpen(true, "Conflict !", data.errors.map(err => { return (<p>{err}</p>) }));
                    return;
                }
                isValid = true;

                let cards = self.state.cards.slice();
                if (addNew) {
                    data.content.map(model => cards.push(self.props.toCard(model)));
                    // cards.push(self.props.toCard(data.content));
                }
                else {
                    let selectedIndex = -1;
                    let selectedCard = cards.filter(r => r._id === data.content[0]._id)[0];
                    selectedIndex = cards.indexOf(selectedCard);
                    cards[selectedIndex] = self.props.toCard(data.content[0]);
                }

                self.setState({
                    cards: cards,
                    selectedCardIds: [],
                    selectedIndices: [],
                },
                    () => {
                        this.updateButtonsVisibility();
                        self.messageBoxOkOnlyOpen(true, "Success", "Data successfully saved");
                    }
                );
            },
            onError: (error) => {
                isValid = false;
                var errorMessage = "An error has occured";
                if (error) {
                    errorMessage += ":\r\n" + JSON.stringify(error);
                }
                this.setState({ showRequiredField: true, errTitle: "Error", errCaption: errorMessage });
            },
            finally: () => {
                this.setState({ isSaving: false });
                // close modal 
                if (isValid) self.props.showModal({}, false);
            }
        }
        ajaxPost(parameter);
    }

    btnDelete_Click = (card, event) => {
        var requestParameter = {
            data: { "_id": card._id },
            url: this.props.urls.load,
            onSuccess: (data, sender) => {
                this.setState({
                    showDeleteModal: !card.deleted,
                    showRestoreModal: card.deleted,
                    selectedId: card._id
                });
            },
            onError: (error) => {
                this.messageBoxOkOnlyOpen(true, "Error", "Record doesn't exist");
                this.search();
            },
            finally: () => { }
        };
        ajaxPost(requestParameter);
    }

    btnRestore_Click = (event, { item }) => {
    }

    setDeleted = (deleted) => {
        var self = this;
        var parameter = {
            data: {
                "_id": this.state.selectedId,
                "IsDeleted": deleted,
            },
            url: self.props.urls.setDeleted,
            onError: function (error) {
                var errMessage = "An error has occured";
                if (error) {
                    errMessage += "\r\n" + JSON.stringify(error);
                }
                self.setState({ showRestoreModal: false, showDeleteModal: false },
                    () => { self.messageBoxOkOnlyOpen(true, "Error", errMessage); }
                );
            },
            onSuccess: (data, sender) => {
                // if (data.hasOwnProperty("errors") && data.errors.length > 0) {
                //     self.setState({ showRestoreModal: false, showDeleteModal: false },
                //         () => { self.messageBoxOkOnlyOpen(true, "Error", data.errors.map(err => { return (<p>{err}</p>) })); }
                //     );
                // }
                // else if(data.hasOwnProperty("content") && data.content.IsSuccessful) {
                var cards = self.state.cards.slice();
                cards = cards.filter(card => card._id != self.state.selectedId).slice();
                self.setState({
                    cards,
                    selectedId: "",
                    showRestoreModal: false,
                    showDeleteModal: false
                },
                    function () { self.updateButtonsVisibility() }
                );
                // }
            },
            finally: function () { }
        };
        ajaxPost(parameter);
    };

    handlePageChange(event, { activePage }) {
        var self = this;
        if (activePage === this.state.activePage || activePage < 1) return;
        var page = activePage - (activePage % 1);
        if (page < activePage) {
            page++;
        }
        this.setState({ activePage: page }, function () {
            self.search();
        });;
    }

    onExportToExcel = () => {
        this.props.exportToExcel(this.state.userId);
    }
    importToExcel = () => { // originally use for payroll adjustment
        if (this.state.importLoading)
            return;
        fileDialog().then(file => {
            const data = new FormData();
            data.append('file', file[0]);
            data.append('RequestClass', JSON.stringify({
                SessionKey: 'HRIS_SessionId',
                SessionId: global.sessionId
            }
            )
            );
            this.setState({ importLoading: true });
            fetch(global.ApiHost + this.props.urls.importToExcel, {
                method: 'POST',
                credentials: 'include',
                body: data
            }).then((resp) => {
                this.setState({ importLoading: false });
                return resp.json();
            }).then((json) => {
                var data = JSON.parse(json.JsonData);
                var hasErrors = data.hasOwnProperty("errors") && data.errors.length > 0;
                // TODO alert(msg);
                if (data.errors.length > 0) {
                    this.messageBoxOkOnlyOpen(true, "Error", data.errors.map(err => { return (<span>{err}<br /></span>) }));
                }
                else {
                    this.messageBoxOkOnlyOpen(true, "Import " + this.props.title, "Data successfully imported");
                }
                this.setState({ importLoading: false });
            })
        });
    }
    downloadTemplate = () => {
        if (this.state.exportTemplateLoading)
            return;
        this.setState({ exportTemplateLoading: true })
        window.location = global.ApiHost + this.props.urls.downloadTemplate;
        this.setState({ exportTemplateLoading: false })

    }
    exportToExcel = () => {
        if (this.state.cards.length === 0) return;
        this.setState({ isLoading: true });
        let parameter = {
            includeDeleted: this.state.includeDeleted,
            requestorId: this.state.userId,
            fromDate: this.state.fromDate,
            toDate: this.state.toDate,
            EmployeeIds: this.state.selectedEmployees,
            selectedPayrollAdjustmentTypeId: this.state.Model.PayrollAdjustmentTypeID,
            selectedTransactionType: this.state.Model.TransactionType,
        }
        let title = this.props.title;
        let titleSingular = title === 'Official Businesses' ? title.substring(0, title.length - 2) : title.substring(0, title.length - 1);
        ajaxPostDownload(
            this.props.urls.exportToExcel,
            parameter,
            titleSingular + " Requests.xlsx", // title
            () => {
                //this.messageBoxOkOnlyOpen(true, "Success", "Export successful");
            },
            (error) => {
                var errorMessage = "An error has occurred";
                if (error) {
                    let errorData = JSON.stringify(error);
                    errorMessage += errorData !== "{}" ? "\r\n" + errorData : "";
                }
                this.messageBoxOkOnlyOpen(true, "Error", errorMessage);
            },
            () => {
                this.setState({ isLoading: false });
            }
        )
    }


    isNullOrEmpty(val) {
        if (val === null || val === undefined || val === "" || val.length === 0) return true;
        else return false;
    }

    handlePopup(event, { taggedId }) {
        var name = "open" + taggedId;
        this.setState({
            [name]:
                event.type === "mouseenter" && window.innerWidth < 992
        });
    }

    render() {
        let self = this;
        let userRightsKeys = this.props.userRightsKeys;
        let actionableCount =
            Number(isTaskAvailable(userRightsKeys.create)) +
            Number(isTaskAvailable(userRightsKeys.export));
        // let actionableCount = 1;
        let actionableCountRatio = (1 / actionableCount).toFixed(2) + " %";
        let fieldHeader = this.props.fields.find(x => x.valueAs === 'header');
        let hasFieldDescription = this.props.fields.findIndex(x => x.valueAs === 'description') > 0;
        let fieldDescription = hasFieldDescription ? this.props.fields.find(x => x.valueAs === 'description') : {};
        let hasContent = this.props.fields.filter(x => x.valueAs === 'content').length > 0;

        //ADJUSTMENT TYPE
        let model = this.state.Model;

        return <>
            <div className={modStyles.module}>
                <div style={{ color: "#606060", marginTop: '1%' }}>
                    <h2>{this.props.title}</h2>
                </div>
                {/* <Header as='h2'>{this.props.title}</Header> */}
                <Pagination
                    style={{ margin: "0px 0px 10px 0px" }}
                    activePage={this.state.activePage}
                    defaultActivePage={1}
                    pointing
                    secondary
                    //ellipsisItem={{ content: <Icon name='ellipsis horizontal' />, icon: true }}
                    firstItem={{ content: <Icon name='angle double left' />, icon: true }}
                    lastItem={{ content: <Icon name='angle double right' />, icon: true }}
                    prevItem={{ content: <Icon name='angle left' />, icon: true }}
                    nextItem={{ content: <Icon name='angle right' />, icon: true }}
                    size='mini'
                    siblingRange={2}
                    boundaryRange={0}
                    totalPages={this.state.totalPages}
                    onPageChange={this.handlePageChange.bind(this)}
                    floated='right'
                />
                <div>
                    <Menu secondary size='mini' stackable style={{marginBottom:'0.5rem', marginTop:'0.5rem'}}>
                        <div className='w3-col l3' style={{ paddingTop: '10px' }}>
                            <table style={{ width: '100%' }}>
                                <tr>
                                    {isTaskAvailable(userRightsKeys.create) &&
                                        <td style={{ width: actionableCountRatio }}>
                                            <Button fluid
                                                className='w3-hide-small w3-hide-medium'
                                                size="mini"
                                                icon="add"
                                                iconPosition="top"
                                                content="Create New"
                                                disabled={this.state.UserCanAdd}
                                                onClick={this.btnAdd_click}
                                            />
                                            <Button fluid
                                                className='w3-hide-large'
                                                size="mini"
                                                iconPosition="top"
                                                icon="plus"
                                                content='Create New'
                                                onClick={this.btnAdd_click}
                                            />
                                        </td>
                                    }
                                    {isTaskAvailable(userRightsKeys.export) &&
                                        <td style={{ width: actionableCountRatio }}>
                                            <Popup className='w3-hide-small w3-hide-medium' trigger={
                                                <Button fluid
                                                    className='w3-hide-small w3-hide-medium'
                                                    size="mini"
                                                    iconPosition="top"
                                                    icon="file excel outline"
                                                    content='Export'
                                                    onClick={this.exportToExcel}
                                                    disabled={this.state.cards.length === 0}
                                                />
                                            } inverted content='Export to Excel' />
                                            <Button fluid
                                                className='w3-hide-large'
                                                size="mini"
                                                iconPosition="top"
                                                icon="file excel outline"
                                                content='Export'
                                                onClick={this.exportToExcel}
                                                disabled={this.state.cards.length === 0}
                                            />
                                        </td>
                                    }
                                    {isTaskAvailable(userRightsKeys.import) &&
                                        <td style={{ width: actionableCountRatio }}>
                                            {/* <Popup className='w3-hide-small w3-hide-medium' trigger={ */}
                                            <Button fluid
                                                className='w3-hide-small w3-hide-medium'
                                                size="mini"
                                                iconPosition="top"
                                                icon="file excel"
                                                content='Import'
                                                onClick={this.importToExcel}
                                                loading={this.state.importLoading}
                                            />
                                            {/* } inverted content='Import to Excel' /> */}
                                            <Button fluid
                                                className='w3-hide-large'
                                                size="mini"
                                                iconPosition="top"
                                                icon="file excel"
                                                content='Import'
                                                onClick={this.importToExcel}
                                                loading={this.state.importLoading}
                                            />
                                        </td>
                                    }
                                    {isTaskAvailable(userRightsKeys.template) &&
                                        <td style={{ width: actionableCountRatio }}>
                                            {/* <Popup className='w3-hide-small w3-hide-medium' trigger={ */}
                                            <Button fluid
                                                className='w3-hide-small w3-hide-medium'
                                                size="mini"
                                                iconPosition="top"
                                                icon="download"
                                                content='Template'
                                                onClick={this.downloadTemplate}
                                                loading={this.state.exportTemplateLoading}
                                            />
                                            {/* } inverted content='download template' /> */}
                                            <Button fluid
                                                className='w3-hide-large'
                                                size="mini"
                                                iconPosition="top"
                                                icon="download"
                                                content='Template'
                                                onClick={this.downloadTemplate}
                                                loading={this.state.exportTemplateLoading}
                                            />
                                        </td>
                                    }
                                </tr>
                            </table>
                        </div>
                    </Menu>

                    <div className='w3-row'>
                        <div className='w3-col l3' style={{ width: '100%', paddingLeft: '3px', paddingRight: '3px'}}>
                            <div className='w3-col l5' style={{ height:'50px'}}>
                                <div className='w3-col m6 l6' style={{ paddingTop: '10px' }}>
                                    <Input
                                        required
                                        fluid
                                        size="mini"
                                        type='date'
                                        label='Start Date'
                                        min={minDate} max={maxDate}
                                        value={this.state.fromDate}
                                        error={!checkDate(this.state.fromDate).Result}
                                        style={{ marginRight: 3, minWidth: '50px', width: '100%', padding: '0px 5px 0px 0px' }}
                                        onChange={(evt) => this.setState({ fromDate: evt.target.value })}
                                    />
                                </div>
                                <div className='w3-col m6 l6' style={{ paddingTop: '10px' }}>
                                    <Input
                                        required
                                        fluid
                                        size="mini"
                                        type='date'
                                        label='End Date'
                                        min={minDate} max={maxDate}
                                        value={this.state.toDate}
                                        style={{ marginRight: 3, minWidth: '50px', width: '100%', padding: '0px 5px 0px 0px' }}
                                        error={!checkDate(this.state.toDate).Result}
                                        onChange={(evt) => this.setState({ toDate: evt.target.value })}
                                    />
                                </div>
                            </div>

                            <div className='w3-col l4'style={{ height:'50px', float: 'right'}}>
                           { this.props.showModalFilter !== undefined &&
                                <div className='w3-hide-small w3-hide-medium w3-col l1' style={{ paddingTop: '10px' }}>
                                    <Button
                                        style={{ width: '36px', 'max-height': '36px', height: '36px' }}
                                        size="mini"
                                        icon="filter"
                                        onClick={this.btnFilter_click}
                                    />
                                </div>
                            }
                                <div className={'w3-hide-small w3-hide-medium w3-col l9'} style={{ paddingTop: '10px', paddingLeft: '3px', paddingRight: '3px' }} fitted='horizontally'>
                                    <Menu secondary size='mini' style={{ width: '100%' }}>
                                        <div style={{ width: '100%' }}>
                                            <SelectEmployees
                                                Ids={this.state.selectedEmployees}
                                                update={(Ids) => this.setState({ selectedEmployees: Ids !== null ? Ids : [] },
                                                    () => { this.search(); }
                                                )}
                                                style={{ minWidth: '50px', maxWidth: '100%', width: '100%' }}/>
                                        </div>
                                    </Menu>
                                </div>
                                <div className='w3-hide-small w3-hide-medium w3-col l2' style={{ paddingTop: '9px' }} >
                                    <Button floated='left' size='mini' fluid onClick={this.btnSearch_click} style={{height: '36px', paddingLeft: '3px', paddingRight: '3px' }}> Search</Button>
                                </div>
                                <div className='w3-hide-large w3-col l10' style={{ paddingTop: '10px', paddingLeft: '3px', paddingRight: '3px' }} fitted='horizontally'>
                                    <table style={{ width: '100%' }}>
                                        <tr>
                                            <td style={{ width: '90%' }}>
                                                <Menu secondary size='mini' style={{ width: '100%' }}>
                                                    <div style={{ width: '100%' }}>
                                                        <SelectEmployees
                                                            Ids={this.state.selectedEmployees}
                                                            update={(Ids) => this.setState({ selectedEmployees: Ids !== null ? Ids : [] },
                                                                () => { this.search(); }
                                                            )}
                                                            style={{ minWidth: '50px', maxWidth: '100%', width: '100%' }}
                                                        />

                                                    </div>
                                                </Menu>
                                            </td>
                                            <td style={{ width: '10%' }}>
                                                <Button className='w3-hide-medium w3-hide-large' fluid size='mini' icon='search' type='submit' onClick={this.btnSearch_click} />
                                                <Button className='w3-hide-small' fluid size='mini' type='submit' style={{ 'max-height': '31px', marginLeft: '6px' }} onClick={this.btnSearch_click}> Search</Button>
                                            </td>
                                        </tr>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <Divider />
                <Loader active={this.state.isLoading} />
                <div>
                    <Card.Group style={{margin:'0'}}>
                    {
                        this.state.cards != null && this.state.cards.map(card => {
                            return (
                                <Card color='olive'>
                                    <Card.Content>
                                        <Card.Header>{card[fieldHeader.key]}</Card.Header>
                                        {
                                            hasFieldDescription &&
                                            <Card.Meta>{card[fieldDescription.key]}</Card.Meta>
                                        }
                                    </Card.Content>
                                    {
                                        hasContent &&
                                        <Card.Content>
                                            {this.props.fields.filter(field => field.valueAs === 'content').map(field => {
                                                return (
                                                    <span>{field.name}: {card[field.key]}<br /></span>
                                                )
                                            })}
                                        </Card.Content>
                                    }
                                    <Card.Content extra>
                                        <List divided horizontal link>
                                            <List.Item as="a" disabled={!isTaskAvailable(userRightsKeys.edit)} onClick={this.btnEdit_Click.bind(this, card._id)}>[Edit]</List.Item>
                                            <List.Item as="a" disabled={!isTaskAvailable(userRightsKeys.delete)} onClick={this.btnDelete_Click.bind(this, card)}>[Delete]</List.Item>
                                        </List>
                                    </Card.Content>
                                </Card>
                            )
                        })}
                    </Card.Group>
                </div>
                {this.props.children}
            </div>


            {this.state.showDeleteModal &&
                <MessageBoxYesNo
                    title="Delete"
                    caption={"Delete selected item?"}
                    action="Yes"
                    CancelCaption="No"
                    onAction={() => { this.setDeleted(true) }}
                    onClose={() => { this.setState({ showDeleteModal: false }) }} />
            }

            {this.state.showRestoreModal &&
                <MessageBoxYesNo
                    title="Restore"
                    caption={"Restore selected item?"}
                    action="Yes"
                    CancelCaption="No"
                    onAction={() => { this.setDeleted(false) }}
                    onClose={() => { this.setState({ showRestoreModal: false }) }} />
            }
            {this.state.messageBoxOkOnly.show &&
                <MessageBoxOkOnly
                    title={this.state.messageBoxOkOnly.title}
                    caption={this.state.messageBoxOkOnly.caption}
                    onClick={() => { this.messageBoxOkOnlyOpen(false); }}
                />
            }

            {/* TRANSACTION TYPE - ADJUSTMENT TYPE FILTER */}
            <Modal open={this.state.showFiltersModal} size="tiny">
                <Modal.Header>Select Filters</Modal.Header>
                <Modal.Content>
                    <Form>
                        <Form.Field>
                            <label>Payroll Adjustment Type</label>
                            <Select
                                placeholder="Select Payroll Adjustment Type"
                                value={this.state.selectedPayrollAdjustmentType}
                                options={this.state.payrollAdjustmentTypeList}
                                onChange={this.handlePayrollAdjustmentTypeChange}
                                styles={this.state.isAdjustmentTypeError ? errorStyles : customStyles}
                            />
                        </Form.Field>
                        <Form.Field>
                            <label>Transaction Type</label>
                            <Select
                                placeholder="Select Transaction Type"
                                value={TransactionTypes.find(x => x.value === this.state.Model.TransactionType)}
                                options={TransactionTypes}
                                onChange={(selectedOption) => this.handleSelectChange("TransactionType", "isTransactionTypeError", selectedOption)}
                                styles={this.state.isTransactionTypeError ? errorStyles : customStyles}
                            />
                        </Form.Field>
                    </Form>
                </Modal.Content>
                <Modal.Actions>
                    <Button basic content="Generate" onClick={this.btnSearchFilter_click}/>
                    <Button basic icon='cancel' content="Cancel" onClick={() => { this.setState({ showFiltersModal: false }) }} />
                </Modal.Actions>
            </Modal>


        </>
    }

}

PayrollTransactionBaseWithDate.propTypes = {
    viewID: PropTypes.string,
    title: PropTypes.string,
    toCard: PropTypes.func,
    toModel: PropTypes.func,
    fields: PropTypes.func,
    urls: PropTypes.func,
    validate: PropTypes.func,
    props: PropTypes.object,
    showModal: PropTypes.func,
    showModal: PropTypes.func,
    userRightsKeys: PropTypes.func,
    exportToExcel: PropTypes.func
}


export default PayrollTransactionBaseWithDate;


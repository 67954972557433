import React, { Component } from 'react';
import moment from 'moment';
import { Modal, Button, Input, Form, TextArea, Checkbox, Header, Label } from 'semantic-ui-react';
import { ajaxPost } from '../../../ajax';
//import Select from 'react-select';
import SelectEmployees from '../../../Commons/SelectEmployees';
import ErrorMessage from '../../../Commons/ErrorMessage';
import MessageBoxYesNo from '../../../Commons/MessageBoxYesNo';
import { isTaskAvailable, checkDate, checkTime, handleNumeric, minDate, maxDate, checkInt32, handleNumericOnKeyDown, handleNumericOnKeyPress } from '../../../utils';

var delayTimer = null;
const someMarginNoPadding = { "margin": "0px 0px 10px 0px", "padding": "0px" }
const noMarginNoPadding = { "margin": "0px", "padding": "0px" }
const semanticErrorStyle = { 'background-color': '#FFF6F6', 'border-color': '#E0B4B4', 'color': '#9F3A38', '-webkit-box-shadow': 'none', 'box-shadow': 'none' }
const attachLabel = { 'width': '100%', 'display': 'flex', 'justifyContent': 'center', 'alignItems': 'center', 'border': 'solid 1px #e8e8e8', 'borderRadius': '0 4px 4px 0px',
    'wordWrap': 'break-word', 'wordBreak': 'break-all', 'overflow': 'hidden'}

class ChangeScheduleForm extends Component {

    constructor(props) {
        super(props);
        this.state = {
            _id: null,
            Date: moment().format("YYYY-MM-DD"),
            StartTime: moment().format("HH:mm"),
            Hours: 0.00,
            BreakStart: null,
            HoursBreak: 0.00,
            RestDay: false,
            Remarks: "",
            RequesterId: "",
            RequestDate: moment().format("YYYY-MM-DD"),

            employees: [],
            selectedEmployees: [],
            employeeSelectionEnabled: true,
            displayedName: "",
            details: "",
            AttachmentDetail: {
                Source: "",
                File: ""
            }
        }
    }

    getContent = () => {
        return {
            _id: this.state._id,
            Date: moment(this.state.Date).toJSON(),
            StartTime: moment(this.state.StartTime, "HH:mm A").toJSON(),
            Minutes: Math.round(this.state.Hours * 60),
            BreakStart: this.state.BreakStart !== null || this.state.BreakStart !== undefined ? moment(this.state.BreakStart, "HH:mm A").toJSON() : null,
            MinutesBreak: Math.round(this.state.HoursBreak * 60),
            RestDay: this.state.RestDay,
            RequesterId: this.state.RequesterId,
            RequestDate: moment().toJSON(),
            Remarks: this.state.Remarks,
            AttachmentDetail: this.state.AttachmentDetail,
            //employeeIds: this.state.selectedEmployees.map(employee => { return employee.key }),
            employeeIds: this.state.selectedEmployees,
        }
    }

    setContent = (data) => {

        let selectedEmployees = [];
        if (data.EmployeeId) {
            selectedEmployees.push(data.EmployeeId);
        }
        else if (data.userId && !isTaskAvailable("VIEW_ALL_EMPLOYEES")) {
            selectedEmployees.push(data.userId);
        }
        // if(data.userId && !data.hasSubordinates) {
        //     selectedEmployees.push(data.userId);

        this.setState({
            _id: data._id,
            Date: moment(data.Date).format("YYYY-MM-DD"),
            StartTime: moment(data.StartTime).format("HH:mm"),
            Hours: (data.Minutes / 60).toFixed(2),
            BreakStart: data.BreakStart === undefined || data.BreakStart === null ? null :  moment(data.BreakStart).format("HH:mm"),
            HoursBreak: (data.MinutesBreak / 60).toFixed(2),
            RestDay: data.RestDay,
            RequesterId: data.userId,
            Remarks: data.Remarks,
            details: !data._id ? "" : data.AttachmentDetail.File,
            AttachmentDetail: !data._id ? {
                Source: "",
                File: ""
            } : data.AttachmentDetail,
            employees: [],
            selectedEmployees: selectedEmployees,
            employeeSelectionEnabled: !data._id && (isTaskAvailable("VIEW_ALL_EMPLOYEES") || data.hasSubordinates),
            displayedName: !data._id && !data.hasSubordinates ? data.name : data.Employee
        });
    }

    // ATTACHMENT
    onDocChangedHandler = (event) => {
        var img = event.target.files[0];
        if (img === undefined) return;
        if (img.size > 3145728) {
            alert("File too large");
            return;
        } else if (img.size === 0) {
            alert("Image too small. File must not be 0MB");
            return;
        } else {
            this.docReadDataUrl(img, this.docOnload.bind(this));
        }
    };

    docReadDataUrl(img, callback) {
        var reader = new FileReader();
        reader.onload = function () {
            callback(reader.result, img.name);
        };
        reader.onerror = function (error) {
            console.log("Error: ", error);
        };
        reader.readAsDataURL(img);
    }

    docOnload(dataURL, name) {
        var current = { ...this.state.AttachmentDetail };
        current["Source"] = dataURL;
        current["File"] = name;

        this.setState({ AttachmentDetail: current, details: name });
    }
    
    onEmployeeSearchChange = (event, data) => {
        var self = this;
        clearTimeout(delayTimer);
        delayTimer = setTimeout(() => {
            var employeeSearchParameter = {
                data: { needle: data.searchQuery === undefined ? "" : data.searchQuery, itemCount: 20 },
                url: "api/Overtime/searchEmployees",
                onSuccess: (data, sender) => {
                    var newOptions = data.map((model, index, array) => { return { key: model._id, text: model.LastName + ", " + model.FirstName + " " + model.MiddleName, value: model._id } });
                    self.setState({ employees: newOptions });
                },
                onError: (error) => {

                },
                finally: () => { }
            };
            ajaxPost(employeeSearchParameter);
        }, 200);
    };

    onCreateNewItemClick = () => {
        this.setState({ showAskCloseDialog: true });
    }


    render() {
        let self = this;
        return (
            <Modal size='mini' open={this.props.open}>
                <Modal.Header>
                    Change Schedule Application
                </Modal.Header>
                <Modal.Content>
                    {
                        this.props.showRequiredField &&
                        <div className="ui one column grid" style={noMarginNoPadding}>
                            <div className="column" style={someMarginNoPadding}>
                                <ErrorMessage
                                    title={this.props.errTitle}
                                    caption={this.props.errCaption}
                                    open={this.props.showRequiredField}
                                />
                            </div>
                        </div>
                    }
                    {
                        !this.state.employeeSelectionEnabled &&
                        <div className="ui one column grid" style={noMarginNoPadding}>
                            <div className="column" style={someMarginNoPadding}>
                                <div>Employee</div>
                                <Header size='small' style={{ "margin": "0" }}>{this.state.displayedName}</Header>
                                {/* <Input value={this.state.displayedName} disabled={true} /> */}
                            </div>
                        </div>
                    }
                    {
                        this.state.employeeSelectionEnabled &&
                        <div className="ui one column grid" style={noMarginNoPadding}>
                            <div className="column" style={someMarginNoPadding}>
                                <div>Employee</div>
                                <SelectEmployees
                                    Ids={this.state.selectedEmployees}
                                    update={(Ids) => this.setState({ selectedEmployees: Ids })}
                                    width="100%"
                                    disabled={this.props.isBusy}
                                />
                                {
                                    isTaskAvailable("EMPLOYEE_ADD") &&
                                    <a as='a' href='#' onClick={this.onCreateNewItemClick.bind(this)}>Create New</a>
                                }
                            </div>
                        </div>
                    }
                    <div className="ui one column grid" style={noMarginNoPadding}>
                        <div className="column" style={someMarginNoPadding}>
                            <div>Date</div>
                            <Input
                                fluid
                                type="date"
                                min={minDate}
                                max={maxDate}
                                required={true}
                                value={this.state.Date}
                                error={!checkDate(this.state.Date).Result}
                                disabled={this.props.isBusy}
                                onChange={(event, data) => { self.setState({ Date: data.value }); }} />
                        </div>
                    </div>
                    <div className="ui two column grid" style={noMarginNoPadding}>
                        <div className="column" style={{ "margin": "0px 0px 10px 0px", "padding": "0px 5px 0px 0px" }}>
                            <div>Start Time</div>
                            <Input
                                fluid
                                type="time"
                                disabled={this.props.isBusy}
                                value={this.state.StartTime}
                                required={true}
                                error={!checkTime(this.state.StartTime).Result}
                                onChange={(event, data) => { this.setState({ StartTime: data.value }); }}
                            />
                        </div>
                        <div className="column" style={{ "margin": "0px 0px 10px 0px", "padding": "0px 0px 0px 5px" }}>
                            <div>&nbsp;</div>
                            <Checkbox
                                fluid
                                disabled={this.props.isBusy}
                                style={{ marginTop: 10 }}
                                label="Rest Day"
                                checked={this.state.NextDay}
                                onChange={(event, data) => { this.setState({ RestDay: data.checked }); }}
                            />
                        </div>
                    </div>
                    <div className="ui two column grid" style={noMarginNoPadding}>
                        <div className="column" style={{ "margin": "0px 0px 10px 0px", "padding": "0px 5px 0px 0px" }}>
                            <div>Hours</div>
                            <Input
                                fluid
                                value={this.state.Hours}
                                disabled={this.props.isBusy}
                                error={this.props.showRequiredField && !checkInt32(this.state.Hours * 60).Result}
                                //onChange={ (event, data) => { this.setState({ Hours: data.value }); }} 
                                onChange={(event) => { this.setState({ Hours: handleNumeric(event) }); }}
                                onKeyPress={(e) => { handleNumericOnKeyPress(e) }}
                                onKeyDown={(e) => { handleNumericOnKeyDown(e) }}
                            />
                        </div>
                        {/* ATTACHMENT OFFICIAL BUSINESS*/}
                        <div className="column" style={{ "margin": "15px 0px 10px 0px", "padding": "0px 5px 0px 0px" }}>
                            <Input
                                style={{ marginTop: "5px", height: "38px", width: "100%" }}
                                labelPosition="left"
                                type="text"
                                placeholder="File"
                                error={this.state.isError}
                            >
                                <Label style={{ width: "45px", height: "38px" }}>File</Label>
                                <label style={attachLabel} htmlFor="files_$">
                                    <a style={{ fontSize: '12px', padding: '2px'}}>
                                        {this.state.details === "" ? "Choose file" : this.state.details}
                                    </a>
                                </label>
                                <input
                                    id="files_$"
                                    style={{ width: "150px", display: "none" }}
                                    size="small"
                                    name=" "
                                    type="file"
                                    onChange={this.onDocChangedHandler}
                                />
                            </Input>
                            <div style={{fontSize: "11px", textAlign: 'right', margin: "2px" }}>*Max File Size: 3MB</div>
                        </div>
                    </div>
                    <div className="ui two column grid" style={noMarginNoPadding}>
                        <div className="column" style={{ "margin": "0px 0px 10px 0px", "padding": "0px 5px 0px 0px" }}>
                            <div>Break Start</div>
                            <Input
                                fluid
                                type="time"
                                disabled={this.props.isBusy}
                                value={this.state.BreakStart} 
                                onChange={(event, data) => { this.setState({ BreakStart: data.value }); }}
                            />
                        </div>
                        <div className="column" style={{ "margin": "0px 0px 10px 0px", "padding": "0px 0px 0px 5px" }}>
                            <div>Hours Break</div>
                            <Input
                                fluid
                                value={this.state.HoursBreak}
                                disabled={this.props.isBusy}
                                error={this.props.showRequiredField && !checkInt32(this.state.HoursBreak * 60).Result}
                                //onChange={(event, data) => { this.setState({ HoursBreak: data.value }); }} 
                                onChange={(event) => { this.setState({ HoursBreak: handleNumeric(event) }); }}
                                onKeyPress={(e) => { handleNumericOnKeyPress(e) }}
                                onKeyDown={(e) => { handleNumericOnKeyDown(e) }}
                            />
                        </div>
                    </div>
                    <div className="ui one column grid" style={noMarginNoPadding}>
                        <div className="column" style={someMarginNoPadding}>
                            <div>Reasons</div>
                            <Form>
                                <TextArea
                                    fluid
                                    value={this.state.Remarks}
                                    disabled={this.props.isBusy}
                                    style={this.props.showRequiredField && !this.state.Remarks ? semanticErrorStyle : []}
                                    onChange={(event, data) => { this.setState({ Remarks: data.value }); }}
                                />
                            </Form>
                        </div>
                    </div>
                </Modal.Content>
                <Modal.Actions>
                    <Button basic icon='save' content="Submit" disabled={this.props.isBusy} onClick={this.props.onSave}></Button>
                    <Button basic icon='cancel' content="Cancel" disabled={this.props.isBusy} onClick={this.props.onCancel}></Button>
                </Modal.Actions>
                {
                    this.state.showAskCloseDialog &&
                    <MessageBoxYesNo title="Create New Employee"
                        action="Yes"
                        onClose={() => { this.setState({ showAskCloseDialog: false }) }}
                        onAction={() => { this.props.onCreateEmpClick() }}
                        caption="All unsaved changes will be lost. Do you wish to continue?"
                    />
                }
            </Modal>
        );
    }

}

export default ChangeScheduleForm;
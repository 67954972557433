import React, {Component } from 'react';
import {Button,Input,Icon,Header, List,Loader, Menu,Pagination,Card, Divider,Modal} from 'semantic-ui-react';
import 'semantic-ui-css/semantic.min.css';
import {ajaxPost} from '../../ajax';
import { isTaskAvailable } from '../../utils';
import modStyles from './PayrollRateProfileList.module.css'

class PayrollRateProfileList extends Component{
    state = {
        Parameters:{ 
            sessionId:"", 
        },
        search:"",
        initialLoad: true,
        selectedItem:null,
        list:[],
        deleteDialog:false,
        importDialog:false,
        exportDialog:false,
        page:1,
        rowPerPage:10,
        total:0,
        totalPages:1,
        open: false,

        UserAdd:false,
        UserCanEdit:false,
        UserCanDelete:false,
    }
    constructor(props){
        super(props);
        this.state = props.searchState;
    }
    componentWillMount(){
        if(this.state.initialLoad === true){
            this.setState({initialLoad: false});
            const page = this.state.page;
            const startIndex = page -1 ;
            const rowPerPage = this.state.rowPerPage;
            this.setState({loading:true});
            this.loadData(data =>{
                var totalPages = data.count / this.state.rowPerPage;
                totalPages -= totalPages % 1;
                if (data.count > totalPages * this.state.rowPerPage) {
                    totalPages++;
                }
                this.setState({
                    list:data.content,
                    totalPages : totalPages,
                    loading:false
                });
                this.props.onUpdateState(this.state);
            },"",startIndex,rowPerPage);
        }
        var canAdd =!isTaskAvailable("PAYROLL_RATE_PROFILE_CREATE");
        var canEdit = !isTaskAvailable("PAYROLL_RATE_PROFILE_MODIFY");
        var canDelete = !isTaskAvailable("PAYROLL_RATE_PROFILE_DELETE");
        this.setState({
            UserCanAdd:canAdd,
            UserCanEdit:canEdit,
            UserCanDelete:canDelete
        });
    
    }
    loadData =(callback,query,startIndex,itemCount) =>{
    

        ajaxPost({
            url: 'api/PayrollRateProfile/search',
            data: {
            "queryString": query,
            "includeDeleted": false,
            "startIndex": startIndex,
            "itemCount": itemCount
        },
        onSuccess: data => {
            callback(data);
        },
        finally: () => { }
        })
    }
    deleteItem =(id) =>{
    

        ajaxPost({
            url: 'api/PayrollRateProfile/setDeleted',
            data: {
            "_id": id,
            "IsDeleted":true,
        },
        onSuccess: data => {
            this.setState({ list: this.state.list.filter(item => item !== this.state.selectedItem) })
            this.setState({ selectedItem: null });
        },
        finally: () => { }
        })
    }

    onDeleteDialogClose = (IsDeleted) => {
        this.setState({ deleteDialog: false });
        if (IsDeleted) {
            var id = this.state.selectedItem._id;
            this.deleteItem(id);
        }

    }
    handleDelete = (model) =>{
        this.setState({deleteDialog:true});
        this.setState({selectedItem:model});
    }
    handleChange = name => event => {
        this.setState({ search: event.target.value });
    };
    handleChangePage = (event,{activePage}) =>{
        if(isNaN(activePage))
        return;  
        const startIndex = activePage - 1;
        this.setState({page:activePage});
        this.setState({loading:true});  
        var query = this.state.search;
        const rowPerPage =this.state.rowPerPage;
        this.loadData(data =>
            {
                var totalPages = data.count / this.state.rowPerPage;
                totalPages -= totalPages % 1;
                if (data.count > totalPages * this.state.rowPerPage) {
                    totalPages++;
                }
                this.setState({
                    list:data.content,
                    totalPages : totalPages,
                    loading:false
                });
                this.props.onUpdateState(this.state);
               
            },query,startIndex * rowPerPage,rowPerPage);


    }
    handleClick = (event) =>{
        this.setState({loading:true});  
        const startIndex = 0;
        this.setState({page:1});
        var query = this.state.search;
        const rowPerPage = this.state.rowPerPage;
                this.loadData(data =>
                {
                    var totalPages = data.count / this.state.rowPerPage;
                    totalPages -= totalPages % 1;
                    if (data.count > totalPages * this.state.rowPerPage) {
                        totalPages++;
                    }
                    this.setState({
                        list:data.content,
                        totalPages : totalPages,
                        loading:false
                    });
                    this.props.onUpdateState(this.state);

                },query,startIndex,rowPerPage);
                
    }
    handlePress = event => {
        if (event.key === 'Enter')
        {
            this.setState({loading:true});  
            const startIndex = 0;
            this.setState({page:1});
            var search =  event.target.value;
            this.setState({ search: search });
            const rowPerPage =this.state.rowPerPage;
                this.loadData(data =>
                {
                    var totalPages = data.count / this.state.rowPerPage;
                    totalPages -= totalPages % 1;
                    if (data.count > totalPages * this.state.rowPerPage) {
                        totalPages++;
                    }
                    this.setState({
                        list:data.content,
                        totalPages : totalPages,
                        loading:false
                    });
                    this.props.onUpdateState(this.state);
                },search,startIndex,rowPerPage);
        }           
    }
    render()
    {      
        return (
            <div className={modStyles.module}>
                
                <div style={{ color: "#606060", marginTop: '1%' }}>
                    <h2>Payroll Rate Profiles</h2>
                </div>
                <Pagination floated="right" size='mini'
                style={{margin:"0px 0px 10px 0px"}}
                 activePage={this.state.page}
                 totalPages={this.state.totalPages}
                 defaultActivePage={1}
                 pointing
                 secondary
                 siblingRange={2}
                 boundaryRange={0}
                 firstItem={{ content: <Icon name='angle double left' />, icon: true }}
                 lastItem={{ content: <Icon name='angle double right' />, icon: true }}
                 prevItem={{ content: <Icon name='angle left' />, icon: true }}
                 nextItem={{ content: <Icon name='angle right' />, icon: true }}
                onPageChange={this.handleChangePage.bind(this)}
                 />

                <Menu size="mini" secondary style={{ overflow:'hidden',  width: '100%'}} stackable>
                    <Button size="mini" icon="add" content="Create New" disabled={this.state.UserCanAdd}  onClick={this.props.onAdd.bind(this,this.state)}/>            

                <Menu.Menu position="right">
                        <Input style={{width:"300px",margin:"0px 10px 0px 0px"}} placeholder='Search...'
                        value={this.state.search}
                        onChange={this.handleChange("search").bind(this)}
                        onKeyPress={this.handlePress.bind(this)}/>
                        <Button content="search"  size="mini" onClick={this.handleClick.bind(this)}/>                                           
                </Menu.Menu>
                </Menu>
                <Divider/>
                <Loader active={this.state.loading} />
                <div className={modStyles.content}>
                <Card.Group>
                {this.state.list !=null && this.state.list
                .map(n => {
                    return(
                        <Card color='olive'>
                        <Card.Content>
                           <Card.Header>{n.Name}</Card.Header>
                           <Card.Meta>{n.Description}</Card.Meta>
                        </Card.Content>
                        <Card.Content extra>
                                <List divided horizontal link>
                                    <List.Item as="a" disabled={this.state.UserCanEdit} onClick={this.props.onEdit.bind(this,this.state,n)}>[Edit]</List.Item>
                                    <List.Item as="a" disabled={this.state.UserCanDelete} onClick={this.handleDelete.bind(this,n)}>[Delete]</List.Item>
                                </List>
                        </Card.Content>                        
                    </Card>
                    )
                })}                             
                </Card.Group>
                </div>
           
               
                {/* Modal Form */}
                {this.state.deleteDialog && <Modal size="mini" open={true} onClose={this.onDeleteDialogClose.bind(this,false)}>
                    <Header content="Delete Payroll Profile" icon="delete"/>
                    <Modal.Content>
                        <p>Are you sure you want to delete payroll profile?</p>
                    </Modal.Content>
                    <Modal.Actions>
                        <Button basic icon="check" content="Yes"
                        onClick={this.onDeleteDialogClose.bind(this,true)} />
                        <Button basic content="No" icon="cancel"
                        onClick={this.onDeleteDialogClose.bind(this,false)}/>
                    </Modal.Actions>
                </Modal>} 

            </div>
        );
    }

}
export default PayrollRateProfileList;
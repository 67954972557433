import React, { Component } from 'react';
import { Table } from 'semantic-ui-react';
import GovernmentTableBase from './GovernmentTableBase';
/*
const yearlyTaxTable = [
    { additional: 0, percentage: 0 },
    { additional: 0, percentage: 20 },
    { additional: 30000, percentage: 25 },
    { additional: 130000, percentage: 30 },
    { additional: 490000, percentage: 32 },
    { additional: 2410000, percentage: 35 },
]

const monthlyTaxTable = [
    { additional: 0, percentage: 0 },
    { additional: 0, percentage: 20 },
    { additional: 2500, percentage: 25 },
    { additional: 10833.33, percentage: 30 },
    { additional: 40833.33, percentage: 32 },
    { additional: 200833.33, percentage: 35 },
]

const semiMonthlyTaxTable = [
    { additional: 0, percentage: 0 },
    { additional: 0, percentage: 20 },
    { additional: 1250, percentage: 25 },
    { additional: 5416.67, percentage: 30 },
    { additional: 20416.67, percentage: 32 },
    { additional: 100416.67, percentage: 35 },
]

const weeklyTaxTable = [
    { additional: 0, percentage: 0 },
    { additional: 0, percentage: 20 },
    { additional: 576.92, percentage: 25 },
    { additional: 2500, percentage: 30 },
    { additional: 9423.08, percentage: 32 },
    { additional: 46346.15, percentage: 35 },
]

const dailyTaxTable = [
    { additional: 0, percentage: 0 },
    { additional: 0, percentage: 20 },
    { additional: 82.19, percentage: 25 },
    { additional: 356.16, percentage: 30 },
    { additional: 1342.47, percentage: 32 },
    { additional: 6602.74, percentage: 35 },
]
*/
const tableHeaders = ['1', '2', '3', '4', '5', '6'];
const loadTableUrl = 'api/TaxTable/load'
const saveTableUrl = 'api/TaxTable/save'
const resetTableUrl = 'api/TaxTable/reset'
/*
function overridingHeaders(taxType) {
    if(taxType === 'YEARLY') {
        return yearlyTaxTable
    }
    if(taxType === 'MONTHLY') {
        return monthlyTaxTable
    }
    if(taxType === 'SEMIMONTHLY') {
        return semiMonthlyTaxTable
    }
    if(taxType === 'WEEKLY') {
        return weeklyTaxTable
    }
    if(taxType === 'DAILY') {
        return dailyTaxTable
    }
}
*/
export default class YearlyTaxTable extends Component {
    constructor(props) {
        super(props);
    }

    state = {
        overridingHeaders:[]
    }

    loadDataCallback = (data) =>{
        var header = data.Content.map( x=> {
            return {
                additional: x.BaseAmount, 
                percentage: x.BracketExcessPercentage 
            }
        });
        this.setState({overridingHeaders:header});
    }

    render() {
        return (
            <div style={{ minWidth: '500px', width: '100%' }}>
                <div style={{ color: "#606060", marginTop: '1%' }}>
                    <h2>{this.props.title}</h2>
                </div>

                <GovernmentTableBase
                    ref={(x) => this.baseTable = x}
                    headers={tableHeaders}
                    overridingHeaders={this.state.overridingHeaders}
                    loadTableUrl={loadTableUrl}
                    saveTableUrl={saveTableUrl}
                    resetTableUrl={resetTableUrl}
                    year={this.props.year}
                    type={this.props.type}
                    resetButtonEnable={this.props.resetButtonEnable}
                    loadDataCallback={this.loadDataCallback}
                />
            </div>
        )
    }
}
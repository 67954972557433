import React, { Component } from 'react';
import 'semantic-ui-css/semantic.min.css';
import { Modal, Button, Input,Checkbox, Dropdown } from '../../../node_modules/semantic-ui-react';
import SelectEmployees from '../../Commons/SelectEmployees';
import Select from 'react-select';
import { handleNumericOnKeyDown, handleNumericOnKeyPress, fnSearchEmployeeFilter, customStyles, errorStyles } from '../../utils';
import { ajaxPost } from  '../../ajax';
const moment = require('moment');

class OvertimePremiumReportModal extends Component {
    constructor(props) {
        super(props);
        var today = new Date();
        this.state = {
            Year:parseInt(today.getFullYear()),
            Month:today.getMonth() + 1,
            EmployeeIds:[],
            includeEmptyEntries:false,
            includeAmount : true,
            searchQuery:'',
            Employeelist:[],
            selectedEmployees:[],
            isEmployeeLoading: false,
            selectedCompany: null,
            selectedFrequency: 'MONTHLY',
            selectedCompanies:'',
            companyList: [],
            isDisabled: true,
            isCompanyLoading: false
        }
        this.loadCompanies();
        this.loadEmployees();
    }

    loadCompanies() {
        this.setState({ isCompanyLoading: true })
        ajaxPost({
            url: 'api/group/search',
            data: {
                Search: '',
                startingIndex: 0,
                itemCount: 999,
                Types: ['COMPANY']
            },
            onSuccess: (data) => {
                this.setState({
                    companyList: data.content.map(x => { return ({ label: x.Name, value: x._id }) }),
                    isCompanyLoading: false
                });
            },
            finally: () => { }
        })
    }

    loadEmployees = () => {
        const { selectedCompany, includeInactive } = this.state;
        this.setState({ isEmployeeLoading: true })
        ajaxPost({
            url: 'api/Employee/searchFilteredEmployee',
            data: {
                Search: "",
                CompanyIDs: selectedCompany !== null ? [selectedCompany.value] : [],
                includeDeleted: false,
                startingIndex: 0,
                itemCount: 30
            },
            onSuccess: (data) => {
                if (selectedCompany == null){
                    this.setState({ isDisabled: true });
                }else{
                    this.setState({ isDisabled: false});
                    this.setState({ selectedCompanies: selectedCompany.value });
                }
                this.setState({ 
                    Employeelist: data.content.map(x => { return ({ label: x.FullName, value: x._id }) })
                });
                this.setState({ isEmployeeLoading: false});
            },
            finally: () => { }
        })
    }

    getParameters = () => {
        return {
            fromDate:  this.state.Year + '-' + this.state.Month + '-01', 
            toDate: this.state.Year + '-' +  this.state.Month + '-' + new Date(this.state.Year, this.state.Month, 0).getDate(), 
            message: "from overtime premium modal",
            selectedCompany: this.state.selectedCompanies,
            selectedFrequency: this.state.selectedFrequency,
            EmployeeIds: this.state.EmployeeIds == 0 ? this.state.Employeelist.map(x => x.value) : this.state.EmployeeIds,
            includeEmptyEntries: this.state.includeEmptyEntries,
            includeAmount : this.state.includeAmount
        };
    }

    updateEmployeeIds = (ids) =>{
        this.setState({ EmployeeIds: ids});
    }
    
    handleCheckChange = (e) =>{
        e.target.textContent == "Include Amount" ?
        this.setState(prevState => ({ includeAmount: !prevState.includeAmount})) :
        this.setState(prevState => ({ includeEmptyEntries : !prevState.includeEmptyEntries }));
    }

    handleSearchEmployeeChange = (value) => {
        var ids = [];
        var values = [];

        if (value != null && !(value === "" || value.length === 0))
            ids = value.map(x => x.value);
        values = value;
        this.setState({ EmployeeIds: ids, selectedEmployees: values });
    }

    
    handleDropDownChange = name => event => {
        var current = this.state.selectedFrequency;
        if (!(event === undefined || event === null)) {
            current = event.value;
        } else {
            current = ""
        }
        if (current == "MONTHLY" || current == "SEMIMONTHLY" || current == "WEEKLY"){
            this.setState({ selectedFrequency: current });
        }else{
            this.setState({ Month: current });
        }
    }

    render() {

        const months = [
            { label:  'January', value: 1 },
            { label:  'Febuary', value: 2 },
            { label:  'March', value: 3 },
            { label:  'April', value: 4 },
            { label:  'May', value: 5 },
            { label:  'June', value: 6 },
            { label:  'July', value: 7 },
            { label:  'August', value: 8 },
            { label:  'September', value: 9 },
            { label:  'October', value: 10 },
            { label:  'November', value: 11 },
            { label: 'December', value: 12 },
        ];

        const frequencyType = [
            { label: "MONTHLY", value: "MONTHLY" },
            { label: "SEMI-MONTHLY", value: "SEMIMONTHLY" },
            { label: "WEEKLY", value: "WEEKLY"}
        ];

        return (
            <Modal open={this.props.open} size="tiny">
                <Modal.Header>
                    <p>Report Filters</p>
                </Modal.Header>
                <Modal.Content>
                    <div class="ui grid">
                        <div class="left floated left aligned sixteen wide column">
                            <label>Company</label>
                            <Select value={this.state.selectedCompany} isLoading={this.state.isCompanyLoading} options={this.state.companyList}
                                        onChange={(e) => this.setState({ selectedCompany: e, selectedEmployees: null }, this.loadEmployees) } isClearable />
                        </div>
                    </div>
                    <div class="ui grid">
                        <div class="left floated left aligned sixteen wide column">
                            <label>Cut off Frequency</label>
                                <Select value={frequencyType.find(x => x.value == this.state.selectedFrequency)}
                                        options={frequencyType}
                                        onChange={this.handleDropDownChange("frequency").bind(this)}
                                />
                        </div>
                    </div>
                    <div class="ui grid">
                        <div class="left floated left aligned eight wide column">
                            <label>Month</label>
                            <Select label = "Month" value={months.find(x => x.value == this.state.Month)}
                                    options={months}
                                    onChange={this.handleDropDownChange("Months").bind(this)}
                            />
                        </div>
                        <div class="right floated right aligned eight wide column">
                            <label style={{marginRight:'100%'}}>Year</label>
                            <Input fluid value={this.state.Year}
                                onChange={(e) => this.setState({ Year: e.target.value })}
                                onKeyPress={(e) => handleNumericOnKeyPress(e)}
                                onKeyDown={(e) => handleNumericOnKeyDown(e)}
                            /> 
                        </div>
                    </div>
                    <div class="ui grid">
                        <div class="left floated left aligned sixteen wide column">
                            <label>Employee</label>
                            <Select value={this.state.selectedEmployees} isLoading={this.state.isEmployeeLoading} options={this.state.Employeelist} onChange={this.handleSearchEmployeeChange.bind(this)} isMulti= "true" isDisabled={this.state.isDisabled}/>
                        </div>
                    </div>
                </Modal.Content>
                <Modal.Actions>
                    <Button positive content="Generate" onClick={this.props.onSave} disabled={this.state.isDisabled}></Button>
                    <Button negative content="Cancel" onClick={this.props.onCancel}></Button>
                </Modal.Actions>
            </Modal>
        );
    }

}

export default OvertimePremiumReportModal;
import React, { Component } from 'react';
import 'semantic-ui-css/semantic.min.css';
import { Modal, Button, Checkbox, Input,Dropdown, Label } from 'semantic-ui-react';
import SelectEmployees from '../../../Commons/SelectEmployees';
import moment from 'moment';

// function employeeSearchlist(list){
//     var employees = [];
//     if(list !=null){
//        list.map(x =>{
//            return(
//             employees.push({label:x.LastName + ", " + x.FirstName + " " + x.MiddleName,value:x._id})
//            )
//        })
//     }
//     return employees;
// }
class AnnualFilterModal extends Component {

    constructor(props) {
        super(props);
        this.state = {
            year: new Date().getFullYear(),
            EmployeeIds:[],
            includeEmptyEntries:true,
            searchQuery:'',            
            Employeelist:[]
        }
    }

    getParameters = () => {
        return {
            year: this.state.year,
            message: "",
            EmployeeIds:this.state.EmployeeIds,
            includeEmptyEntries: this.state.includeEmptyEntries,
            Search: "",
        };
    }

    updateEmployeeIds = (ids) =>{
        this.setState({ EmployeeIds: ids});
    }
    handleCheckChange = (e) =>{
        var includeEmptyEntries  = this.state.includeEmptyEntries;
        includeEmptyEntries = includeEmptyEntries === false ? true : false;
        this.setState({includeEmptyEntries:includeEmptyEntries});
    }
    render() {
        return (
            <Modal open={this.props.open} size="tiny">
                <Modal.Header>
                    <p>Report Filters</p>
                </Modal.Header>
                <Modal.Content>
                    <div class="ui grid">
                        <div class="left floated left aligned sixteen wide column">
                            <label>Year</label>
                            <Input fluid size="mini" value={this.state.year} onChange={(event, data)=>{this.setState({year:data.value})}} />                            
                        </div>                        
                       
                    </div>
                    <div class="ui grid">
                        <div class="left floated left aligned sixteen wide column">
                        <label>Employee</label>
                            <SelectEmployees Ids={this.state.EmployeeIds} update={this.updateEmployeeIds.bind(this)}/>
                        </div>
                    </div>
                    <div class="ui grid">
                        <div class="left floated left aligned sixteen wide column">
                        <Checkbox toggle fluid label="Include empty entries" 
                                    checked={this.state.includeEmptyEntries}
                                    onClick={this.handleCheckChange.bind(this)}/>
                        </div>
                    </div>
                </Modal.Content>
                <Modal.Actions>
                    <Button basic content="Generate" onClick={this.props.onSave}></Button>
                    <Button basic content="Cancel" onClick={this.props.onCancel}></Button>
                </Modal.Actions>
            </Modal>
        );
    }

}

export default AnnualFilterModal;
import React, { Component } from 'react';
import { Button, Input, Icon, Header, List, Loader, Menu, Pagination, Card, Divider, Modal, Dropdown } from 'semantic-ui-react';
import 'semantic-ui-css/semantic.min.css';
import { ajaxPost } from '../../ajax';
import { isTaskAvailable, ajaxPostDownload } from '../../utils';
import MessageBoxOkOnly from '../../Commons/MessageBoxOkOnly';
import modStyles from './Schedule.module.css'

const fileDialog = require('file-dialog');

const SchedStatus = [
    { text: "All", value: "all" },
    { text: "Active", value: "active" },
    { text: "Inactive", value: "inactive" }
];

class Schedule extends Component {

    state = {
        Parameters: {
            sessionId: "",
        },
        search: "",
        initialLoad: true,
        selectedSchedule: null,
        listSchedule: [],
        selectedFilter: SchedStatus[0].value,
        retrieveDialog: false,
        deleteDialog: false,
        importDialog: false,
        exportDialog: false,
        page: 1,
        rowPerPage: 10,
        total: 0,
        totalPages: 1,
        open: false,

        UserAdd: false,
        UserCanEdit: false,
        UserCanDelete: false,

        isMobileView: false,
        messageBoxOkOnly: {
            show: false,
            title: "",
            caption: "",
        },
    }
    constructor(props) {
        super(props);
        this.state = props.searchState;
    }
    
    // Handle the filter change
    handleFilterChange = (event, { value }) => {
        this.setState({ selectedFilter: value });
    };

    componentWillMount() {
        if (this.state.initialLoad === true) {
            this.setState({ initialLoad: false });
            const page = this.state.page;
            const startIndex = page - 1;
            const rowPerPage = this.state.rowPerPage;
            this.setState({ loading: true });
            this.LoadSchedule(data => {
                var totalPages = data.count / this.state.rowPerPage;
                totalPages -= totalPages % 1;
                if (data.count > totalPages * this.state.rowPerPage) {
                    totalPages++;
                }
                this.setState({
                    listSchedule: data.content,
                    totalPages: totalPages,
                    loading: false
                });
                this.props.onUpdateState(this.state);
            }, "", startIndex, rowPerPage);
        }
        var canAdd = !isTaskAvailable("SCHEDULE_PROFILE_CREATE");
        var canEdit = !isTaskAvailable("SCHEDULE_PROFILE_MODIFY");
        var canDelete = !isTaskAvailable("SCHEDULE_PROFILE_DELETE");
        this.setState({
            UserCanAdd: canAdd,
            UserCanEdit: canEdit,
            UserCanDelete: canDelete
        });
        this.resize();
    }

    resize() {
        this.setState({ isMobileView: window.innerWidth <= 812 });
    }
    messageBoxOkOnlyOpen = (isOpen, title = "", caption = "") => {
        var messageBoxOkOnly = {
            show: isOpen,
            title: title,
            caption: caption,
        };
        this.setState({ messageBoxOkOnly });
    }
    LoadSchedule = (callback, query, startIndex, itemCount) => {


        ajaxPost({
            url: 'api/Schedule/search',
            data: {
                "queryString": query,
                "includeDeleted": false,
                "startIndex": startIndex,
                "itemCount": itemCount
            },
            onSuccess: data => {
                callback(data);
            },
            finally: () => { }

        })
    }

    onDownloadTemplate = (e) => {
        this.setState({ loading: true });
        var apiUrl = 'api/Schedule/downloadTemplate';
        // this.state.Parameters.sessionId = global.sessionId;
        ajaxPostDownload(
            apiUrl,
            {},
            "schedule profile template.xlsx",
            (data, sender) => {
                this.setState({ loading: false });
            },
            (error) => {
                alert("Error:\r\n" + JSON.stringify(error));
            },
            () => {
                // alert("@finally");
                this.setState({ loading: false });
            }

        )

    }
    importFromExcel = () => {
        fileDialog().then(file => {
            const data = new FormData();
            data.append('file', file[0]);
            data.append('RequestClass', JSON.stringify({
                SessionKey: 'HRIS_SessionId',
                SessionId: global.sessionId
            }));
            this.setState({ loading: true });
            fetch(global.ApiHost + "api/Schedule/importFromExcel", {
                method: 'POST',
                credentials: 'include',
                body: data
            }).then((resp) => {
                this.setState({ loading: false });
                return resp.json();
            }).then((json) => {
                var data = JSON.parse(json.JsonData);
                var hasErrors = data.hasOwnProperty("errors") && data.errors.length > 0;
                // TODO alert(msg);
                this.messageBoxOkOnlyOpen(true, "Schedule Profile", !hasErrors ? data.content : <div><p>{data.content}</p>{data.errors.map(err => { return (<span>{err}<br /></span>) })}</div>);
                this.setState({ loading: false });
            })
        });
    }

    handleRetrieve = (model) => {
        this.setState({ retrieveDialog: true });
        this.setState({ selectedSchedule: model });
    }

    onRetrieveDialogClose = (IsDeleted) => {
        this.setState({ retrieveDialog: false });
        if (IsDeleted) {
            var id = this.state.selectedSchedule._id;
            this.RetrieveSchedule(data => {
                if (data.error != undefined) {
                    alert(data.error.conflict);
                } else {
                    const successMessage = "Schedule Profile has been restored successfully.";
                    this.messageBoxOkOnlyOpen(true, "Success", successMessage);    
                    this.setState({ listSchedule: this.state.listSchedule.filter(item => item !== this.state.selectedSchedule) })
                    this.setState({ selectedSchedule: null });
                }
            }, id);
        }
    }

    RetrieveSchedule = (callback, id) => {
        ajaxPost({
            url: 'api/Schedule/setDeleted',
            data: {
                "_id": id,
                "IsDeleted": false,
            },
            onSuccess: data => {
                callback(data);
            },
            finally: () => { }
        });
    }

    handleDelete = (model) => {
        this.setState({ deleteDialog: true });
        this.setState({ selectedSchedule: model });
    }

    onDeleteDialogClose = (IsDeleted) => {
        this.setState({ deleteDialog: false });
        if (IsDeleted) {
            var id = this.state.selectedSchedule._id;
            this.DeleteSchedule(data => {
                if (data.error != undefined) {
                    alert(data.error.conflict);
                } else {
                    const successMessage = "Schedule Profile has been successfully deleted.";
                    this.messageBoxOkOnlyOpen(true, "Delete", successMessage);    
                    this.setState({ listSchedule: this.state.listSchedule.filter(item => item !== this.state.selectedSchedule) })
                    this.setState({ selectedSchedule: null });
                }
            }, id);
        }
    }
    
    DeleteSchedule = (callback, id) => {
        ajaxPost({
            url: 'api/Schedule/setDeleted',
            data: {
                "_id": id,
                "IsDeleted": true,
            },
            onSuccess: data => {
                callback(data);

            },
            finally: () => { }
        })
    }

    handleChange = name => event => {
        this.setState({ search: event.target.value });
    };
    handleChangePage = (event, { activePage }) => {
        if (isNaN(activePage))
            return;
        const startIndex = activePage - 1;
        this.setState({ page: activePage });
        this.setState({ loading: true });
        var query = this.state.search;
        const rowPerPage = this.state.rowPerPage;
        this.LoadSchedule(data => {
            var totalPages = data.count / this.state.rowPerPage;
            totalPages -= totalPages % 1;
            if (data.count > totalPages * this.state.rowPerPage) {
                totalPages++;
            }
            this.setState({
                listSchedule: data.content,
                totalPages: totalPages,
                loading: false
            });
            this.props.onUpdateState(this.state);

        }, query, startIndex * rowPerPage, rowPerPage);


    }
    handleClick = (event) => {
        this.setState({ loading: true });
        const startIndex = 0;
        this.setState({ page: 1 });
        var query = this.state.search;
        const rowPerPage = this.state.rowPerPage;
        this.LoadSchedule(data => {
            var totalPages = data.count / this.state.rowPerPage;
            totalPages -= totalPages % 1;
            if (data.count > totalPages * this.state.rowPerPage) {
                totalPages++;
            }
            this.setState({
                listSchedule: data.content,
                totalPages: totalPages,
                loading: false
            });
            this.props.onUpdateState(this.state);

        }, query, startIndex, rowPerPage);

    }
    handlePress = event => {
        if (event.key === 'Enter') {
            this.setState({ loading: true });
            const startIndex = 0;
            this.setState({ page: 1 });
            var search = event.target.value;
            this.setState({ search: search });
            const rowPerPage = this.state.rowPerPage;
            this.LoadSchedule(data => {
                var totalPages = data.count / this.state.rowPerPage;
                totalPages -= totalPages % 1;
                if (data.count > totalPages * this.state.rowPerPage) {
                    totalPages++;
                }
                this.setState({
                    listSchedule: data.content,
                    totalPages: totalPages,
                    loading: false
                });
                this.props.onUpdateState(this.state);
            }, search, startIndex, rowPerPage);
        }
    }
    render() {
        const { listSchedule, selectedFilter } = this.state;
        // Filter the listSchedule based on the selected filter
        const filteredSchedules = listSchedule.filter(schedule => {
            if (selectedFilter === "all") {
                return true;
            } else if (selectedFilter === "active") {
                return schedule.IsDeleted === false; 
            } else if (selectedFilter === "inactive") {
                return schedule.IsDeleted === true; 
            }
            return true;
        });

        return (
            
            <div className={modStyles.module}>

                <div style={{ color: "#606060", marginTop: '1%' }}>
                    <h2>Schedule Profiles</h2>
                </div>
                {/*
                <Pagination floated="right" size='mini'
                    style={{ margin: "0px 0px 10px 0px", alignSelf:"end" }}
                    activePage={this.state.page}
                    totalPages={this.state.totalPages}
                    defaultActivePage={1}
                    pointing
                    secondary
                    siblingRange={2}
                    boundaryRange={0}
                    firstItem={{ content: <Icon name='angle double left' />, icon: true }}
                    lastItem={{ content: <Icon name='angle double right' />, icon: true }}
                    prevItem={{ content: <Icon name='angle left' />, icon: true }}
                    nextItem={{ content: <Icon name='angle right' />, icon: true }}
                    onPageChange={this.handleChangePage.bind(this)}
                />
                */}

                <Menu size="mini" secondary style={{ marginRight:'0' }} stackable>
                    {!this.state.UserCanAdd && <Button size="mini" icon="add" content="Create New" disabled={this.state.UserCanAdd} onClick={this.props.onAdd.bind(this, this.state)} />
                    }
                    <Button size="mini" icon="upload" disabled={this.state.UserCanAdd} content="import" onClick={this.importFromExcel.bind(this)}
                    />
                    <Button size="mini" icon="download template" content="download template"
                        onClick={this.onDownloadTemplate.bind(this)} />

                    <Dropdown
                        style={{ width: this.state.isMobileView ? '100%' : '150px', margin: '0px 10px 0px 0px' }}
                        fluid selection
                        placeholder="Status"
                        options={SchedStatus}
                        defaultValue={SchedStatus[0].value}
                        value={selectedFilter}
                        onChange={this.handleFilterChange}
                    />

                    <Menu.Menu position="right">
                        <Input style={{ width: this.state.isMobileView ? '100%' : '300px', margin: "0px 10px 0px 0px" }} placeholder='Search Schedule Name...'
                            value={this.state.search}
                            onChange={this.handleChange("search").bind(this)}
                            onKeyPress={this.handlePress.bind(this)} />
                        <Button content="search" size="mini" onClick={this.handleClick.bind(this)} />
                    </Menu.Menu>
                </Menu>
                <Divider />
                <Loader active={this.state.loading} />
                <div className={modStyles.content}>
                    <Card.Group style={{ margin: '0' }}>
                        {filteredSchedules.map((n, index) => {
                            return (
                                <Card color='olive' style={{ minWidth: '330px' }} key={index}>
                                    <Card.Content>
                                        <Card.Header style={{ color: n.IsDeleted ? 'red' : 'inherit' }}>{n.ScheduleName}  ( {n.ScheduleNumber} )</Card.Header>
                                        <Card.Meta style={{ marginTop: '0.5em' }}>Schedule Name</Card.Meta>
                                    </Card.Content>
                                    <Card.Content extra>
                                        <List divided horizontal link>
                                            {n.IsDeleted ? (
                                                            <List.Item as="a" disabled={this.state.UserCanDelete} onClick={() => this.handleRetrieve(n)}>[Retrieve]</List.Item>
                                                ) : (
                                                    <>
                                                        {!this.state.UserCanEdit && (
                                                            <List.Item as="a" disabled={this.state.UserCanEdit} onClick={this.props.onEdit.bind(this, this.state, n)}>[Edit]</List.Item>
                                                        )}
                                                        {!this.state.UserCanDelete && (
                                                            <List.Item as="a" disabled={this.state.UserCanDelete} onClick={this.handleDelete.bind(this, n)}>[Delete]</List.Item>
                                                        )}
                                                    </>
                                                )}
                                        </List>
                                    </Card.Content>
                                </Card>
                            );
                        })}
                    </Card.Group>
                </div>


                {/* Modal Form */}
                {/* Render the retrieve confirmation dialog */}
                {this.state.retrieveDialog && <Modal size="mini" open={true} onClose={this.onRetrieveDialogClose.bind(this, false)}>
                    <Header content="Retrieve Schedule" />
                    <Modal.Content>
                        <p>Are you sure you want to restore schedule profile?</p>
                    </Modal.Content>
                    <Modal.Actions>
                        <Button basic icon="check" content="Yes"
                            onClick={this.onRetrieveDialogClose.bind(this, true)} />
                        <Button basic content="No" icon="cancel"
                            onClick={this.onRetrieveDialogClose.bind(this, false)} />
                    </Modal.Actions>
                </Modal>}

                {/* Render the delete confirmation dialog */}
                {this.state.deleteDialog && <Modal size="mini" open={true} onClose={this.onDeleteDialogClose.bind(this, false)}>
                    <Header content="Delete Schedule" icon="delete" />
                    <Modal.Content>
                        <p>Are you sure you want to delete schedule profile?</p>
                    </Modal.Content>
                    <Modal.Actions>
                        <Button basic icon="check" content="Yes"
                            onClick={this.onDeleteDialogClose.bind(this, true)} />
                        <Button basic content="No" icon="cancel"
                            onClick={this.onDeleteDialogClose.bind(this, false)} />
                    </Modal.Actions>
                </Modal>}
                {
                    (this.state.messageBoxOkOnly != null || this.state.messageBoxOkOnly != undefined) &&
                    this.state.messageBoxOkOnly.show &&
                    <MessageBoxOkOnly
                        title={this.state.messageBoxOkOnly.title}
                        caption={this.state.messageBoxOkOnly.caption}
                        onClick={() => { this.messageBoxOkOnlyOpen(false); }}
                    />
                }

            </div>
        );
    }

}

export default Schedule;
import React from 'react';
import 'semantic-ui-css/semantic.min.css';
import ReportModelBase from '../ReportModelBase';
import { Table } from 'semantic-ui-react';
const moment = require('moment');

class PerfectAttendanceReportModel extends ReportModelBase {

    constructor(props) {
        super(props);
        this.searchUrl = "api/Reports/createPerfectAttendanceReport";
        this.title = "Perfect Attendance Report";
        this.exportToExcelUrl = "api/Reports/exportPerfectAttendanceReportToExcel";
        this.exportToExcelDataUrl = "api/Reports/createPerfectAttendanceReportToExcel";
        this.exportToPdfUrl = "api/Reports/createPerfectAttendanceReportToPDF";
    }

    toTableRow = (rowData) => {
        return (
            <Table.Row>
                <Table.Cell>{rowData["EmployeeNo"]}</Table.Cell>
                <Table.Cell>{rowData["Employee"]}</Table.Cell>
                <Table.Cell>{rowData["Date"] !== "" ? moment(rowData["Date"]).format("MM/DD/YYYY") : ""}</Table.Cell>
                <Table.Cell>{rowData["WorkSchedule"]}</Table.Cell>
                <Table.Cell>{rowData["Remarks"]}</Table.Cell>
                <Table.Cell>{rowData["TimeInOut"]}</Table.Cell>
                <Table.Cell>{rowData["RegHoursWork"]}</Table.Cell>
            </Table.Row>
        );
    }

    createColumnHeaders = () => {
        return (
            <Table.Row>
                <Table.HeaderCell>Employee Number</Table.HeaderCell>
                <Table.HeaderCell>Employee</Table.HeaderCell>
                <Table.HeaderCell>Date</Table.HeaderCell>
                <Table.HeaderCell>Work Schedule</Table.HeaderCell>
                <Table.HeaderCell>Remarks</Table.HeaderCell>
                <Table.HeaderCell>Time In/Out</Table.HeaderCell>
                <Table.HeaderCell>Reg Hrs Work</Table.HeaderCell>
            </Table.Row>
        );
    }

}
export default PerfectAttendanceReportModel;
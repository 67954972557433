import React, { Component } from 'react';
import { Tab, Image, Button, Label, ButtonGroup } from 'semantic-ui-react';
import TaxTable from './TaxTable';
import { isTaskAvailable } from '../../utils';
import SSSTable from './SSSTable';
import PhilhealthTable from './PhilhealthTable';
import PagibigTable from './PagibigTable';
import GSISTable from './GSISTable';

var moment = require('moment');

const buttonStyle = {
    border: '0.5px solid',
    marginTop: '5px',
    marginBottom: '5px'
}
export default class GovernmentTable extends Component {
    constructor() {
        super();

        this.state = {
            selectedYear: moment().format('YYYY'),
            isResetting: false,
            isResetEnabled: false,
            tables: {
                openYearlyTaxTable: true,
                openMonthlyTaxTable: false,
                openSemiMonthlyTaxTable: false,
                openWeeklyTaxTable: false,
                openDailyTaxTable: false,
                openSssTable: false,
                openPhilhealthTable: false,
                openPagibigTable: false,
                openGSISTable: false
            }
        }
    }

    componentDidMount() {
        if (this.props.prevState !== undefined) {
            this.setState({
                tables: this.props.prevState.tables,
                selectedYear: this.props.prevState.selectedYear,
            })
        }
    }

    componentWillUnmount() {
        this.props.unmountCallback(this.state, "GovernmentTable");
    }

    handleTabChange(tabName) {
        var { tables } = this.state;
        Object.keys(tables).forEach(x => {
            if (tabName == x) {
                tables[x] = true;
            }
            else {
                tables[x] = false;
            }
        })
        this.setState({ tables });
    }

    setResetEnabled = (isResetEnabled) => {
        this.setState({ isResetEnabled })
    }

    render() {

        return (
            <div style={{ overflowX: 'auto', width: '100%', height: 'calc(100vh - 80px)' }}>
                <div style={{ display: 'flex', height: '50px' }}>
                    <div style={{ color: "#606060" }}>
                        <h2>Government Tables</h2>
                    </div>
                </div>


                <div style={{ display: 'flex', height: '85px' }}>
                    <div style={{ display: 'flex', verticalAlign: 'center', marginTop: '25px', marginBottom: '25px' }}>
                        <Button icon='left arrow' onClick={(e) => this.setState({ selectedYear: parseInt(this.state.selectedYear) - 1 }, () => this.currTable.baseTable.loadData())} />
                        <Label style={{ backgroundColor: 'transparent' }} size='big'>{this.state.selectedYear}</Label>
                        <Button icon='right arrow' onClick={(e) => this.setState({ selectedYear: parseInt(this.state.selectedYear) + 1 }, () => this.currTable.baseTable.loadData())} />
                    </div>

                    <div style={{ marginLeft: '10px', position: 'relative', width: '200px' }}>
                        {isTaskAvailable('EDIT_GOVTTABLE') && <Button fluid basic icon='add' content='Add New Table in Year' onClick={() => this.currTable.baseTable.createNewTable()} style={{ border: '0.5px solid', margin: 0, position: 'absolute', top: '50%', '-ms-transform': 'translateY(-50%)', transform: 'translateY(-50%)' }} />}
                    </div>

                    <div style={{ marginLeft: '10px', position: 'relative', width: '200px' }}>
                        {isTaskAvailable('EDIT_GOVTTABLE') && <Button fluid basic icon='edit' content='Edit Table' onClick={() => this.currTable.baseTable.editTable()} style={{ border: '0.5px solid', margin: 0, position: 'absolute', top: '50%', '-ms-transform': 'translateY(-50%)', transform: 'translateY(-50%)' }} />}
                    </div>

                    {this.currTable !== undefined &&
                        <div style={{ marginLeft: '10px', position: 'relative', width: '200px' }}>
                            {isTaskAvailable('EDIT_GOVTTABLE') && <Button fluid basic icon='edit'
                                style={{ border: '0.5px solid', margin: 0, position: 'absolute', top: '50%', '-ms-transform': 'translateY(-50%)', transform: 'translateY(-50%)' }}
                                content='Reset Table'
                                loading={this.state.isResetting}
                                disabled={!this.state.isResetEnabled}
                                onClick={this.currTable.baseTable.handleReset}
                            />
                            }
                        </div>}

                </div>

                <div style={{ display: 'flex', width: '100%', height: 'calc(100% - 135px)' }}>
                    <ButtonGroup vertical style={{ width: '230px' }}>
                        <Button style={buttonStyle} fluid basic content='Yearly Tax Table' onClick={() => this.handleTabChange('openYearlyTaxTable')} />
                        <Button style={buttonStyle} fluid basic content='Monthly Tax Table' onClick={() => this.handleTabChange('openMonthlyTaxTable')} />
                        <Button style={buttonStyle} fluid basic content='Semi-Monthly Tax Table' onClick={() => this.handleTabChange('openSemiMonthlyTaxTable')} />
                        <Button style={buttonStyle} fluid basic content='Weekly Tax Table' onClick={() => this.handleTabChange('openWeeklyTaxTable')} />
                        <Button style={buttonStyle} fluid basic content='Daily Tax Table' onClick={() => this.handleTabChange('openDailyTaxTable')} />
                        <Button style={buttonStyle} fluid basic content='SSS Table' onClick={() => this.handleTabChange('openSssTable')} />
                        <Button style={buttonStyle} fluid basic content='Philhealth Table' onClick={() => this.handleTabChange('openPhilhealthTable')} />
                        <Button style={buttonStyle} fluid basic content='Pag-ibig Table' onClick={() => this.handleTabChange('openPagibigTable')} />
                        <Button style={buttonStyle} fluid basic content='GSIS Table' onClick={() => this.handleTabChange('openGSISTable')} />
                    </ButtonGroup>

                    <div style={{ marginLeft: '10px', width: '100%', overflowY: 'auto' }}>
                        {this.state.tables.openYearlyTaxTable &&
                            <TaxTable ref={(x) => this.currTable = x} year={this.state.selectedYear} type='YEARLY' title='Yearly Tax Table'
                                resetButtonEnable={this.setResetEnabled}
                            />
                        }
                        {this.state.tables.openMonthlyTaxTable &&
                            <TaxTable ref={(x) => this.currTable = x} year={this.state.selectedYear} type='MONTHLY' title='Monthly Tax Table'
                                resetButtonEnable={this.setResetEnabled}
                            />
                        }
                        {this.state.tables.openSemiMonthlyTaxTable &&
                            <TaxTable ref={(x) => this.currTable = x} year={this.state.selectedYear} type='SEMIMONTHLY' title='Semi-Monthly Tax Table'
                                resetButtonEnable={this.setResetEnabled}
                            />
                        }
                        {this.state.tables.openWeeklyTaxTable &&
                            <TaxTable ref={(x) => this.currTable = x} year={this.state.selectedYear} type='WEEKLY' title='Weekly Tax Table'
                                resetButtonEnable={this.setResetEnabled}
                            />
                        }
                        {this.state.tables.openDailyTaxTable &&
                            <TaxTable ref={(x) => this.currTable = x} year={this.state.selectedYear} type='DAILY' title='Daily Tax Table'
                                resetButtonEnable={this.setResetEnabled}
                            />
                        }
                        {this.state.tables.openSssTable &&
                            <SSSTable ref={(x) => this.currTable = x} year={this.state.selectedYear}
                                resetButtonEnable={this.setResetEnabled}
                            />
                        }
                        {this.state.tables.openPhilhealthTable &&
                            <PhilhealthTable ref={(x) => this.currTable = x} year={this.state.selectedYear}
                                resetButtonEnable={this.setResetEnabled}
                            />
                        }
                        {this.state.tables.openPagibigTable &&
                            <PagibigTable ref={(x) => this.currTable = x} year={this.state.selectedYear}
                                resetButtonEnable={this.setResetEnabled}
                            />
                        }
                        {this.state.tables.openGSISTable &&
                            <GSISTable ref={(x) => this.currTable = x} year={this.state.selectedYear}
                                resetButtonEnable={this.setResetEnabled}
                            />
                        }
                    </div>
                </div>


            </div>
        )
    }
}


GovernmentTable.getViewID = () => { return "GovernmentTable" };
GovernmentTable.getCaption = () => { return "Government Table" }
GovernmentTable.getViewAccessKey = () => {
    return isTaskAvailable("VIEW_GOVTTABLE");
}
GovernmentTable.getIcon = () => {
    return (<Image style={{ "height": "24px", "width": "24px" }} src='/Icons/Reports.png' avatar />);
}
GovernmentTable.getIconSrc = (size) => {
    return '/Icons/Reports.png';
}
import React, { Component } from 'react';
import { Button, Input, Modal } from 'semantic-ui-react';
import 'semantic-ui-css/semantic.min.css';
import ErrorMessage from '../../../Commons/ErrorMessage';
import {empty_id} from '../../../utils';
const someMarginNoPadding = { "margin": "0px 0px 10px 0px", "padding" : "0px" }
const noMarginNoPadding = { "margin": "0px", "padding" : "0px" }


class PayrollBankForm extends Component{
    constructor(props){
        super(props);
        this.state = {
            Model: this.getDefaultValues(),
        }
    }

    getDefaultValues = () => {
        return {
            _id:empty_id,
			BankName: "",
            BankCode: "",
            BranchCode:"",
            Address: "",
            CompanyAccountNumber: "",
            ContactPerson: "",    
        }
    }

    setContent = (data) => {
        this.setState({
            Model: data === null || data === undefined ? this.getDefaultValues() : data
        });
    }

    getContent = () => {
        return {
            _id: this.state.Model._id,
            BankName: this.state.Model.BankName,
            BankCode: this.state.Model.BankCode,
            BranchCode: this.state.Model.BranchCode,
            Address: this.state.Model.Address,
            CompanyAccountNumber: this.state.Model.CompanyAccountNumber,
            ContactPerson: this.state.Model.ContactPerson,  
        };
    }

    handleChange = name => event => {
        var current = this.state.Model;
        current[name] = event.target.value;
        this.setState({ Model: current });
    };

    handleComboChange = (name, val) => {
        var current = this.state.Model;
        current[val.name] = val.value;
        this.setState({ Model: current });
    }
  
    render(){
        return(
            <Modal size="tiny" open={this.props.open}>
                <Modal.Header>
                    { this.state.Model._id === "" || this.state.Model._id === empty_id ? "Create" : "Edit"} Payroll Bank Detail
                </Modal.Header>
                <Modal.Content>
                    {
                        this.props.showRequiredField &&
                        <div className="ui one column grid" style={noMarginNoPadding}>
                            <div className="column" style={someMarginNoPadding}>
                                <ErrorMessage  
                                    title={this.props.errTitle}
                                    caption={this.props.errCaption}
                                    open={this.props.showRequiredField}
                                />
                            </div>
                        </div>
                    }
                    <div className="ui one column grid" style={noMarginNoPadding}>
                        <div className="column" style={someMarginNoPadding}>
                            <div>Bank Name</div>
                            <Input 
                                fluid 
                                placeholder='Bank Name'
                                value={this.state.Model.BankName} 
                                disabled={this.props.isBusy} 
                                error={this.props.showRequiredField && !this.state.Model.BankName}
                                onChange={this.handleChange("BankName").bind(this)} 
                            />
                        </div>
                    </div>
                    <div className="ui one column grid" style={noMarginNoPadding}>
                        <div className="column" style={someMarginNoPadding}>
                            <div>Bank Code</div>
                            <Input 
                                fluid 
                                value={this.state.Model.BankCode} 
                                disabled={this.props.isBusy} 
                                error={this.props.showRequiredField && !this.state.Model.BankCode} 
                                onChange={this.handleChange("BankCode").bind(this)} 
                            />
                        </div>
                    </div>
                    <div className="ui one column grid" style={noMarginNoPadding}>
                        <div className="column" style={someMarginNoPadding}>
                            <div>Branch Code</div>
                            <Input 
                                fluid 
                                value={this.state.Model.BranchCode} 
                                disabled={this.props.isBusy} 
                                // error={this.props.showRequiredField && !this.state.Model.BranchCode} 
                                onChange={this.handleChange("BranchCode").bind(this)} 
                            />
                        </div>
                    </div>
                    <div className="ui one column grid" style={noMarginNoPadding}>
                        <div className="column" style={someMarginNoPadding}>
                            <div>Address</div>
                            <Input 
                                fluid 
                                value={this.state.Model.Address} 
                                disabled={this.props.isBusy} 
                                onChange={this.handleChange("Address").bind(this)} 
                            />
                        </div>
                    </div>
                    <div className="ui one column grid" style={noMarginNoPadding}>
                        <div className="column" style={someMarginNoPadding}>
                            <div>Account Number</div>
                            <Input 
                                fluid 
                                value={this.state.Model.CompanyAccountNumber} 
                                disabled={this.props.isBusy} 
                                onChange={this.handleChange("CompanyAccountNumber").bind(this)} 
                            />
                        </div>
                    </div>
                    <div className="ui one column grid" style={noMarginNoPadding}>
                        <div className="column" style={someMarginNoPadding}>
                            <div>Contact Person</div>
                            <Input 
                                fluid 
                                value={this.state.Model.ContactPerson} 
                                disabled={this.props.isBusy} 
                                onChange={this.handleChange("ContactPerson").bind(this)} 
                            />
                        </div>
                    </div>
                </Modal.Content>
                <Modal.Actions>
                    <Button basic icon='save' content="Submit" disabled={this.props.isBusy} loading={this.props.isBusy} onClick={this.props.onSave}></Button>
                    <Button basic icon='cancel' content="Cancel" disabled={this.props.isBusy} onClick={this.props.onCancel}></Button>
                </Modal.Actions>
            </Modal>
        );
    }

}
export default PayrollBankForm
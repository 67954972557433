import React, { Component } from 'react';
import { Button, Header, Form, Dropdown, Modal, Menu, Tab, Loader, Icon, Pagination } from 'semantic-ui-react';
import 'semantic-ui-css/semantic.min.css';
import moment from 'moment';
import { ajaxPost } from '../../../ajax';
import { fnSearchEmployeeFilter, isTaskAvailable, ajaxPostDownload } from '../../../utils';
import MessageBoxYesNo from '../../../Commons/MessageBoxYesNo';
import SelectEmployees from '../../../Commons/SelectEmployees';
import PdfMake from 'pdfmake/build/pdfmake';
import vfsFonts from 'pdfmake/build/vfs_fonts';
import LeaveConversionList from './LeaveConversionList';

import modStyles from './LeaveConversionModule.module.css';

class LeaveConversionModule extends Component {
    state = {
        Parameters: {
            CutoffId: "-1",
            needle: "",
            startingIndex: 0,
            itemCount: 10,
            totalPages: 1,
            sessionId: "",
            EmployeeIds: [],
            Type : "",
            advanceFilter: {
                companyIds: [],
                divisionIds: [],
                departmentIds: [],
                locationIds: [],
                positionIds: [],
                includeDeleted: false
            },
        },
        searchState: {
            year: parseInt(moment().format("YYYY")),
            initialLoad: true,
            initialDTRLoad: true,
            loading: false,
            list: [],
            page: 1,
            rowPerPage: 10
        },
        Cutoff: null,
        Employeelist: [],
        EmployeeIds: [],
        searchQuery: '',
        initialDtrSummaryLoad: true,
        initialPayrollLoad: true,
        // DTRDetails: [],
        // DTRSummary: [],
        Payroll: [],
        BtnPostContent: "Post",
        Loading: false,
        page: 1,
        showUnpostMessagebox: false,
        showPostMessagebox: false,
        showEmailNotification: false,
        NotificationDialog: false,
        tabIndex: 0,
        showPayslip: true,
        showPrintPayrollRegister: true,
        UserCanPost: false,
        UserCanUnPost: false,
        UserPostDisabled: false,
        UserCanSendEmail: false,
        isMobileView: false,

    }

    constructor(props) {
        super(props);
        this.state.Cutoff = this.props.Cutoff;
        // this.state.DTRDetails = this.props.DTRDetails;
        // this.state.DTRSummary = this.props.DTRSummary;
        this.state.Parameters.CutoffId = this.state.Cutoff._id;
        this.state.BtnPostContent = this.state.Cutoff.Status !== "PREVIEW" ? "Unpost" : "Post";
        this.ViewControl = React.createRef();
        this.componentRef = React.createRef();
        this.state.searchState = this.props.searchState;
    }

    componentWillMount() {
        fnSearchEmployeeFilter("", data => { this.setState({ Employeelist: data }); }, 0, 20, false);
        var canPost = isTaskAvailable("CUTOFF_POST");
        var canUnPost = isTaskAvailable("CUTOFF_UNPOST");
        // var canSendEmail = isTaskAvailable("CUTOFF_SEND_EMAIL_NOTIFICATION");
        var postDisabled = this.state.Cutoff.Status == "POSTED" ? canUnPost : canPost;
        this.setState({
            UserCanPost: canPost,
            UserCanUnPost: canUnPost,
            UserPostDisabled: postDisabled,
            // UserCanSendEmail: canSendEmail,
        });

        window.addEventListener("resize", this.resize.bind(this));
        this.resize();

    }

    componentWillUnmount() {
        window.removeEventListener("resize", this.resize);
    }

    resize() {
        this.setState({ isMobileView: window.innerWidth <= 812 });
    }

    getUpdate = (param, self, action) => {
        ajaxPost({
            data: param,
            url: 'api/BackgroundProcess/GetStatus',
            onSuccess: data => {
                if (data.Status == "DONE") {
                    if (action == "GENERATE") {
                        this.populateRows(JSON.parse(data.ResultParameters), self);
                    }
                    else if (action == "GENERATE_PDF") {
                        self.state.reportModel.processPdfData(JSON.parse(data.ResultParameters));
                    }
                    // else if (action == "GENERATE_EXCEL") {
                    //     // var result ={ "data": JSON.parse(data.ResultParameters)};
                    //     self.state.reportModel.exportToExcel(JSON.parse(data.ResultParameters),
                    //         data => { },
                    //         (err) => { },
                    //         () => { }
                    //     );
                    // }
                    this.setState({ loading: false });
                    //remove
                    ajaxPost({
                        data: param,
                        url: 'api/BackgroundProcess/Delete',
                        onSuccess: data => { },
                        onError: (err) => { },
                        finally: () => { }
                    });
                } else {
                    this.getUpdate(param, self, action);
                }
            },
            onError: (error) => {
                alert("Error:\r\n" + JSON.stringify(error));
                this.setState({ loading: false });
            },
            finally: () => {
                // alert("@finally");
            }
        });
    }

    processPdfData = (pdfData) => {
        const { vfs } = vfsFonts.pdfMake;
        PdfMake.vfs = vfs;
        PdfMake.createPdf(pdfData).open();
    }

    handleNotification = () => {
        this.setState({ showEmailNotification: true });
    }

    handlePost = (e) => {
        //post
        if (this.state.BtnPostContent === "Post") {
            this.setState({ showPostMessagebox: true });
        }
        else {
            this.setState({ showUnpostMessagebox: true });
        }
    }

    OnNotification = () => {
        this.setState({
            NotificationDialog: true,
            showEmailNotification: false
        });
    }

    OnSendNotification = (sending) => {
        if (sending) {
            var cutoffId = this.state.Cutoff._id;
            var employeeIds = this.state.EmployeeIds;
            this.SendEmailNotification(data => {

            }, cutoffId, employeeIds);

        }
        this.setState({ NotificationDialog: false });
    }

    OnPost = () => {
        this.setState({ showPostMessagebox: false });
        this.setState({ Loading: true });
        var cutoffId = this.state.Cutoff._id;

        this.Post(data => {
            //check progress'
            var result = data;
            if (result === "POST_PENDING") {
                alert("The previous cutoff is not posted yet.  Cannot proceed with Post");
                return;
            }
            else if (result === "POSTING_PENDING") {
                alert("There is a cutoff currently posting.  Cannot proceed with Post");
                return;
            }
            else if (result === "FAILED") {
                alert("Something went wrong.  Cannot proceed with Post");
                return;
            }
            var checkProgress = () => {

                this.GetPostingProgress(data => {
                    if (data.Done) {
                        this.setState({ BtnPostContent: "Unpost" });
                        this.setState({ Loading: false });
                        var cutoff = this.state.Cutoff;
                        cutoff.Status = "POSTED";
                        this.setState({ Cutoff: cutoff });
                        alert("Cutoff has been successfully Posted");
                    }
                    else
                        setTimeout(checkProgress, 1000);

                }, cutoffId);
            }
            checkProgress();
        }, cutoffId);
    }
    onExportPayslipToPdf = () => {
        if (this.state.Loading) {
            alert("Please wait until the process is done.");
            return;
        }
        this.setState({ Loading: true });
        let parameters = this.state.Parameters;
        let api = 'api/Cutoff/exportPayslipToPdf';
        this.exportToPdf(api, parameters,
            (data, sender) => {
                this.processPdfData(data);
                this.setState({ Loading: false });
            },
            (error) => {
                alert("Error:\r\n" + JSON.stringify(error));
                this.setState({ Loading: false });
            },
            () => {
                // alert("@finally");
            })
    };
    onExportToPdf = () => {

        if (this.state.Loading) {
            alert("Please wait until the process is done.");
            return;
        }
        this.setState({ Loading: true });
        let parameters = this.state.Parameters;
        let api = 'api/Cutoff/exportPayrollToPdf';
        this.exportToPdf(api, parameters,
            (data, sender) => {
                this.processPdfData(data);
                this.setState({ Loading: false });
            },
            (error) => {
                alert("Error:\r\n" + JSON.stringify(error));
                this.setState({ Loading: false });
            },
            () => {
                // alert("@finally");
            })

    }
    exportToPdf = (api, searchParameters, onSearchFinished, onError, onFinally) => {
        var parameter = {
            data: searchParameters,
            url: api,
            onSuccess: onSearchFinished,
            onError: onError,
            finally: onFinally
        };
        ajaxPost(parameter);
    }
    OnUnPost = () => {
        this.setState({ showUnpostMessagebox: false });
        this.setState({ Loading: true });
        var cutoffId = this.state.Cutoff._id;

        this.UnPost(data => {
            this.setState({ Loading: false });
            if (data === "OK") {
                alert("Cutoff has been successfully  Unpost");
                this.setState({ BtnPostContent: "Post" });
                var cutoff = this.state.Cutoff;
                cutoff.Status = "PREVIEW";
                this.setState({ Cutoff: cutoff });
            }
            else if (data === "NEXT_POSTED")
                alert("The next cutoff is already posted.  Cannot proceed with UnpostT");
            else
                alert("Something went wrong.");

        }, cutoffId);
    }

    SendEmailNotification = (callback, cutoffId, employeeIds) => {
        ajaxPost({
            url: 'api/OutBox/sendDTREmailNotification',
            data: {
                "CutoffId": cutoffId,
                "EmployeeIds": employeeIds
            },
            onSuccess: data => {
                alert("Send Email Notification Successfully");
            },
            finally: () => { }
        })
    }

    GetPostingProgress = (callback, parameters) => {
        ajaxPost({
            url: 'api/Cutoff/getPostingProgress',
            data: {
                "CutoffId": parameters
            },
            onSuccess: data => {
                callback(data);
            },
            finally: () => { }
        })
    }

    Post = (callback, parameters) => {
        ajaxPost({
            url: 'api/Cutoff/post',
            data: {
                "CutoffId": parameters
            },
            onSuccess: data => {
                callback(data);
            },
            finally: () => { }
        })
    }

    UnPost = (callback, parameters) => {
        ajaxPost({
            url: 'api/Cutoff/unpost',
            data: {
                "CutoffId": parameters
            },
            onSuccess: data => {
                callback(data);
            },
            finally: () => { }
        })
    }

    handleSearchChange = (e, { searchQuery }) =>
        this.setState({ searchQuery: searchQuery }
        );

    handleChangePage = (event, { activePage }) => {
        if (isNaN(activePage))
            return;
        const startIndex = activePage > 0 ? activePage - 1 : 0;
        this.setState({ page: activePage });

        var parameters = this.state.Parameters;
        parameters.startingIndex = startIndex * parameters.itemCount;
        this.setState({ Parameters: parameters });
        this.ViewControl.current.onSearch(parameters);
    }

    updateEmployeeIds = (Ids) => {
        var param = this.state.Parameters;
        param.EmployeeIds = Ids;
        this.setState({ Parameters: param });
        this.ViewControl.current.onSearch(param);
    }

    handleSearchChange = (event) => {
        var str = event.target.value;
        this.setState({ searchQuery: str })
        setTimeout(this.doSearch.bind(this), 200, str, event.target);
    }

    handleSearch = () => {
        var parameters = this.state.Parameters;
        parameters.startingIndex = 0;
        this.setState({
            page: 1,
            Parameters: parameters
        });
        this.ViewControl.current.onSearch(parameters);
    }

    handleComboChange = (name, val) => {
        var current = this.state.Parameters;
        current[val.name] = val.value;
        this.setState({ Parameters: current });
    }

    handleSearchPress = (e) => {
        if (e.key === "Enter") {
            this.handleSearch();
        }
    }

    handleEmployeeIdsChange = (name, { value }) => {
        this.setState({ EmployeeIds: value });
    }

    handleTabChange = (name, { activeIndex }) => {
    }
    updateState = (state, control) => {
        // this.setState({ Parameters: state.Parameters });
        // if (control === "DTRList") {
        //     var searchstate = this.state.searchState;
        //     searchstate.initialDTRLoad = state.initialLoad;
        //     this.setState({ searchState: searchstate });
        //     this.setState({ DTRDetails: state.DTRDetails });
        // }
        // this.props.onUpdateState(this.state.searchState, this.state.DTRDetails, this.state.DTRSummary, this.state.Payroll);
    }
    render() {

        return <>
            <div className={modStyles.container}>
                <div style={{ color: "#606060", marginTop: '1%' }}>
                    <h2>Leave Conversion</h2>
                </div>
                <div>
                    <h5>{moment(this.state.Cutoff.DateStart).format("MMMM DD YYYY") + " - " +
                        moment(this.state.Cutoff.DateEnd).format("MMMM DD YYYY")}{this.state.Cutoff.Status == "POSTED" && " (POSTED)"}</h5>
                </div>
                <div>
                    <Pagination floated="right" size='mini'
                        defaultActivePage={1}
                        style={{ margin: "0px 0px 10px 0px" }}
                        activePage={this.state.page}
                        pointing
                        secondary
                        siblingRange={2}
                        boundaryRange={0}
                        firstItem={{ content: <Icon name='angle double left' />, icon: true }}
                        lastItem={{ content: <Icon name='angle double right' />, icon: true }}
                        prevItem={{ content: <Icon name='angle left' />, icon: true }}
                        nextItem={{ content: <Icon name='angle right' />, icon: true }}
                        totalPages={this.state.Parameters.totalPages}
                        onPageChange={this.handleChangePage.bind(this)}
                    />

                </div>
                <Menu size="mini" secondary style={{ width: '100%', 'mini-height': '50px', paddingTop: '3px' }} stackable>
                    <Button
                        size="mini"
                        icon="arrow alternate circle left"
                        content="Back"
                        onClick={this.props.onCloseForm.bind(this, this.state.Cutoff)}
                    />
                    {
                        this.state.UserPostDisabled &&
                        <Button
                            size="mini"
                            icon="save"
                            content={this.state.BtnPostContent}
                            onClick={this.handlePost.bind(this)}
                        />
                    }
                    {(this.state.showPrintPayrollRegister && this.state.Cutoff.Status === "POSTED") && <Button size='mini' icon='file pdf' content="Payroll Register" onClick={this.onExportToPdf.bind(this)}
                            disabled={this.state.Loading} />}
                    {this.state.showPayslip && <Button size='mini' icon='file pdf' content="Payslip" onClick={this.onExportPayslipToPdf.bind(this)}
                        disabled={this.state.Loading} />}
                    {/* <Button size="mini" icon="file excel online" content="Export to Excel"
                        onClick={this.onExportToExcel.bind(this)} disabled={this.state.Loading} /> */}
                    <Menu.Menu position="right" disabled={this.state.Loading} style={{ width: '100%', maxWidth: '400px' }}>
                        {/* <Button size="mini" icon="btnEmailNotification" content="Send DTR Email Notification"
                        disabled={this.state.UserCanSendEmail}
                    onClick={this.handleNotification.bind(this)} />     */}
                        <div style={{ width: '100%' }}>
                            <SelectEmployees Ids={this.state.Parameters.EmployeeIds}
                                update={this.updateEmployeeIds.bind(this)}
                                style={{ width: this.state.isMobileView ? '100%' : '250px', maxWidth: '300px', minWidth: '50px', marginRight: '3px' }} />
                        </div>

                        <Button content="search" size="mini"
                            onClick={this.handleSearch.bind(this)} />

                    </Menu.Menu>
                </Menu>
                <Loader active={this.state.Loading} />
                <LeaveConversionList
                        ref={this.ViewControl}
                        Payroll={this.state.Payroll}
                        Parameters={this.state.Parameters}
                        initialLoad={this.state.initialDtrSummaryLoad}
                        updateState={this.updateState.bind(this)}
                    />
            </div>
            {
                this.state.showPostMessagebox &&
                // <LeaveConversionForm 
                //     open={this.state.showPostMessagebox}
                //     onPost={this.OnPost.bind(this)}
                //     onClose={() => { this.setState({ showPostMessagebox: false }) }}
                // />
                <MessageBoxYesNo
                    title="Post Cutoff"
                    caption="You are about to Post this CUTOFF.  Do you wish to proceed?"
                    onAction={this.OnPost.bind(this)}
                    onClose={() => { this.setState({ showPostMessagebox: false }) }}
                    action="Post"
                />
            }
            {
                this.state.showUnpostMessagebox &&
                <MessageBoxYesNo
                    title="UnPost Cutoff"
                    caption="You are about to Unpost this CUTOFF.  Do you wish to proceed?"
                    onAction={this.OnUnPost.bind(this)}
                    onClose={() => { this.setState({ showUnpostMessagebox: false }) }}
                    action="Unpost"
                />
            }
        </>
    }
}
export default LeaveConversionModule
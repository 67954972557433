import React, { Component } from 'react';
import { Button, Modal, Checkbox } from 'semantic-ui-react';
import 'semantic-ui-css/semantic.min.css';
import Select from 'react-select';

var { fnSearchEmployeeFilter } = require('../../utils');

function employeeSearchlist(list) {
    var employees = [];
    if (list != null) {
        list.map(x => {
            var fullname = x.LastName + ", " + x.FirstName + " " + x.MiddleName;

            return (
                employees.push({ label: fullname, value: x._id })
            )
        })
    }
    return employees;
}

class SelectEmployeeForDevice extends Component {
    state = {
        busy: false,
        error: "",
        employees: [],
        selectAll: false,
        Employeelist: [],
        EmployeeIds:[],
        searchQuery: '',
    }

    componentWillMount(){
        this.setState({ isLoading: true });
        fnSearchEmployeeFilter("", data => {
            this.setState({ Employeelist: data, isLoading: false });
            if(this.state.loadedIds !== undefined && this.state.loadedIds !== null && this.state.loadedIds.length > 0){
                this.setState({EmployeeIds: employeeSearchlist(data)});
            }
        }, 0, 20, false, null, this.state.loadedIds)

        if(this.state.loadedIds !== undefined && this.state.loadedIds !== null && this.state.loadedIds.length > 0){
            this.doSearch("");
        }
        this.searchTimer = null;
    }

    handleSearchChange = (text) => {
        clearTimeout(this.searchTimer);

        if(this.state.searchQuery !== text){
            this.setState({ searchQuery: text})
            this.searchTimer = setTimeout(this.doSearch, 400, text);
        }
    }
    doSearch = (str) => {
        const { EmployeeIds, Employeelist } = this.state;
        var selectedEmpIds = [];
        if (EmployeeIds !== null && EmployeeIds !== undefined && Employeelist !== undefined)
            selectedEmpIds = Employeelist.filter(x => EmployeeIds.map(x => x.value).includes(x._id));

        this.setState({ searchQuery: str, isLoading: true },
            () => fnSearchEmployeeFilter(str, data => {
                selectedEmpIds.forEach(x => { if (!data.includes(x["_id"])) data.push(x) })
                this.setState({ Employeelist: data, isLoading: false });
            }
                , 0, 20, false, EmployeeIds.map(x => x.value))
        )
    }
    handleSearchEmployeeChange = (value) => {
        var ids = [];
        if (value != null && !(value === "" || value.length === 0))
            ids = value.map(x => x.value);

        this.setState({ EmployeeIds: value, searchQuery: "" }, () => this.doSearch(""));
        //this.props.update(ids);
    }

    handleClose = () => {
        this.props.onClose(false);
    }

    handleAction = () => {
        this.setState({ busy: true });
        if (this.state.selectAll)
            this.props.onAction([]);
        else
            this.props.onAction(this.state.Employeelist.filter(x => this.state.EmployeeIds.map(i=>i.value).includes(x._id)).map(x=>{return x.AccessNumber}));
    }

    handleChange = (value) => {
        this.setState({ employees: value });
    }

    // getData = (input, callback) => {
    //     if(input.includes('\\'))
    //         input = input.split('\\').join("");

    //     fnSearchEmployee(input, data => {
    //         var emps = [];
    //         data.content.map(item => {
    //             emps.push({
    //                 value: item._id,
    //                 label: item.LastName + ', ' + item.FirstName + ' ' + item.MiddleName, accessNumber: item.AccessNumber
    //             });
    //         })
    //         this.setState({empSelectList:emps});
    //         // callback(null, {
    //         //     options: emps
    //         // });
    //     }, 0, 10, false);
    // }

    onCheckboxChange = (event, ctr) => {
        this.setState({ selectAll: ctr.checked });
    }

    render = () => {
        return (
            <Modal open size='tiny'>
                <Modal.Header>{this.props.title}</Modal.Header>
                <Modal.Content>
                    {this.props.allowSelectAll &&
                        <Checkbox toggle
                            onChange={this.onCheckboxChange}
                            checked={this.state.selectAll}
                            label="Select all employees"
                        />
                    }
                    <br />
                    <br />
                    <Modal.Description>{this.props.caption}</Modal.Description>
                    <br />
                    <br />
                    <Select disabled={this.state.started || this.state.selectAll}
                        value={this.state.EmployeeIds}
                        onChange={this.handleSearchEmployeeChange.bind(this)}
                        options={employeeSearchlist(this.state.Employeelist)}
                        onInputChange={this.handleSearchChange.bind(this)}
                        isMulti
                    />
                </Modal.Content>
                <Modal.Actions>
                    <Button basic icon='check' disabled={this.state.busy || (this.state.EmployeeIds.length == 0 && !this.state.selectAll)}
                        content={this.props.action} oading={this.state.busy} onClick={this.handleAction.bind(this)}>
                    </Button>
                    <Button basic icon='cancel' content='Cancel' onClick={this.handleClose} disabled={this.state.busy}></Button>
                </Modal.Actions>
            </Modal>
        )
    }
}

export default SelectEmployeeForDevice
import React, { Component } from 'react';
import { Message } from 'semantic-ui-react';
import 'semantic-ui-css/semantic.min.css';
import PropTypes from 'prop-types';

class ErrorMessage extends Component {
    render() {
        const {
            title,
            caption,
            open,
            style,
            size
          } = this.props;

        return (
            <div>
                <Message negative hidden={!open} style={style} size={size && 'small'}>
                    {title && <Message.Header>{title}</Message.Header>}
                    <p>{caption}</p>
                </Message>
            </div>
        )
    }
}
ErrorMessage.propTypes = {
    visible: PropTypes.bool,
    title: PropTypes.string,
    caption: PropTypes.string,
}
export default ErrorMessage
import React, { Component } from 'react';
import 'semantic-ui-css/semantic.min.css';
import { Modal, Button, Input, Checkbox } from '../../../node_modules/semantic-ui-react';
// import Select from 'react-select';
// import {fnSearchEmployee,fnSearchEmployeeFilter } from '../../utils';
import SelectEmployees from '../../Commons/SelectEmployees';
const moment = require('moment');

class PerfectAttendanceModal extends Component {

    constructor(props) {
        super(props);
        this.state = {
            fromDate: moment().startOf('day').format("YYYY-MM-DD"),
            toDate: moment().startOf('day').format("YYYY-MM-DD"),
            EmployeeIds: null,
            includeEmptyEntries: true,
            considerLeaveAsPresent: true,
            searchQuery: '',
            Employeelist: []
        }
    }

    getParameters = () => {
        return {
            fromDate: this.state.fromDate,
            toDate: this.state.toDate,
            message: "from tardiness modal",
            EmployeeIds: this.state.EmployeeIds,
            includeEmptyEntries: this.state.includeEmptyEntries,
            considerLeaveAsPresent: this.state.considerLeaveAsPresent
        };
    }

    updateEmployeeIds = (ids) => {
        this.setState({ EmployeeIds: ids });
    }
    handleCheckChange = (name, value, e) => {
        this.setState({ [name]: !value });
    }
    render() {
        return (
            <Modal open={this.props.open} size="tiny">
                <Modal.Header>
                    <p>Report Filters</p>
                </Modal.Header>
                <Modal.Content>
                    <div class="ui grid">
                        <div class="left floated left aligned eight wide column">
                            <Input type="date" label="From Date" size="mini" fluid value={this.state.fromDate} onChange={(event, data) => { this.setState({ fromDate: data.value }) }} />
                        </div>
                        <div class="right floated right aligned eight wide column">
                            <Input type="date" label="To Date" size="mini" fluid value={this.state.toDate} onChange={(event, data) => { this.setState({ toDate: data.value }) }} />
                        </div>
                    </div>
                    <div class="ui grid">
                        <div class="left floated left aligned sixteen wide column">
                            <label>Employee</label>
                            <SelectEmployees Ids={this.state.EmployeeIds} update={this.updateEmployeeIds.bind(this)} />
                        </div>
                    </div>
                    <div class="ui grid">
                        <div class="left floated left aligned sixteen wide column">
                            <Checkbox toggle fluid label="Consider Leave as Present"
                                checked={this.state.considerLeaveAsPresent}
                                onClick={this.handleCheckChange.bind(this, "considerLeaveAsPresent", this.state.considerLeaveAsPresent)} />
                        </div>
                    </div>
                    <div class="ui grid">
                        <div class="left floated left aligned sixteen wide column">
                            <Checkbox toggle fluid label="Include empty entries"
                                checked={this.state.includeEmptyEntries}
                                onClick={this.handleCheckChange.bind(this, "includeEmptyEntries", this.state.includeEmptyEntries)} />
                        </div>
                    </div>
                </Modal.Content>
                <Modal.Actions>
                    <Button positive content="Generate" onClick={this.props.onSave}></Button>
                    <Button negative content="Cancel" onClick={this.props.onCancel}></Button>
                </Modal.Actions>
            </Modal>
        );
    }

}

export default PerfectAttendanceModal;
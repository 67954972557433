import React, { Component } from 'react';
import 'semantic-ui-css/semantic.min.css';
import { Modal, Button, Checkbox } from '../../../../node_modules/semantic-ui-react';
import Select from 'react-select';

const moment = require('moment');

function genderList(){
    var gender = [];
    gender.push({label:"All",value:"ALL"});
    gender.push({label:"Unspecified",value:"UNSPECIFIED"});
    gender.push({label:"Male",value:"MALE"});
    gender.push({label:"Female",value:"FEMALE"});
    return gender;
}
class EmployeeReportModalGroupByGender extends Component {

    constructor(props) {
        super(props);
        this.state = {
            includeDeleted:false,
            selectedGender:"",
            includeInactive:false,
        }
    }
    getParameters = () => {
        return {
            message: "from employee report group by gender modal",
            Gender: (this.state.selectedGender === ""? null: this.state.selectedGender.value),
            includeDeleted:this.state.includeInactive,
            startingIndex:0,
            itemCount:20,
            FilterDetails: "Gender: " + (this.state.selectedGender=== null || this.state.selectedGender=== "" ? "ALL": this.state.selectedGender.value)
        };
    }
    handleComboChange = (value) => {
        this.setState({ selectedGender : value})
    }

    onChkCheckChange = (e) => {
        this.setState({ includeInactive: !this.state.includeInactive })
    }
    render() {
        return (
            <Modal open={this.props.open} size="tiny">
                <Modal.Header>
                    <p>Employee Report Group By Gender Filters</p>
                </Modal.Header>
                <Modal.Content>
                    <div class="ui grid">
                        <div class="left floated left aligned sixteen wide column">
                        <label>Gender</label>
                            <Select placeholder='Gender' name="GenderList"
                                onChange={this.handleComboChange.bind(this)}  
                                options={genderList()}                      
                                value={this.state.selectedGender}/>
                            <Checkbox size='mini' 
                                label="Include Resigned" 
                                onChange={this.onChkCheckChange.bind(this)} 
                                checked={this.state.includeInactive} />
                        </div>
                    </div>
                </Modal.Content>
                <Modal.Actions>
                    <Button basic content="Generate" onClick={this.props.onSave}></Button>
                    <Button basic content="Cancel" onClick={this.props.onCancel}></Button>
                </Modal.Actions>
            </Modal>
        );
    }

}

export default EmployeeReportModalGroupByGender;
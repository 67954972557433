import React, { Component } from 'react';
import { Accordion, Button, Checkbox, Input, Dropdown, Grid, Icon, Header, Modal, Loader, Menu, Segment, Message, Table } from 'semantic-ui-react';
import 'semantic-ui-css/semantic.min.css';
import DataGrid from 'react-data-grid';
import Update from 'immutability-helper';
import { ajaxPost } from '../../ajax';
import { handleNumeric, ValidateNumber, empty_id } from '../../utils';
import modStyles from './ScheduleForm.module.css';

import BreakScheduleForm from './BreakScheduleForm';
import RowEditor from './RowEditor';
function validateScheduleRow(params) {
    let conflicts = [];
    if (params.IsRestday !== "YES") {
        conflicts.push("Please Input YES/NO");
    }
    if (params.IsRestday !== "NO") {
        conflicts.push("Please Input YES/NO");
    }

    return conflicts;
}
function detailsFormat(details) {
    var list = [];
    if (details != null) {
        details.map(x => {
            return (
                list.push({
                    Day: x.DayKey,
                    DayCount : x.DayCount,
                    StartTime: x.StartTime === "" ? null : x.StartTime,
                    // EndTime: x.EndTime,
                    HoursWork: x.HoursWork,
                    BreakStart: x.BreakStart === "" ? null : x.BreakStart,
                    MinutesBreak: x.MinutesBreak,
                    IsRestday: x.IsRestday,
                    BreakDetails: x.BreakDetails
                })
            )
        })
    }

    return list;
}
function dayOfWeek(key) {
    return ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"][key];
}
function dayOfWeekKey(day) {
    return ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"].indexOf(day);
}
function DetailsDisplayFormat(details) {
    var list = [];
    var week = ["Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday", "Sunday"];
    //   var dt =  new Date().toLocaleString('en-us', {  weekday: 'long' });
    if (details.length !== 0) {
        //assure databaseFormat
        details.map(x => {
            return (
                list.push({
                    Day: dayOfWeek(x.Day),
                    DayKey: x.Day,
                    DayCount: x.DayCount,
                    StartTime: x.StartTime === null ? "" : x.StartTime,
                    // EndTime: x.EndTime,
                    HoursWork: x.HoursWork,
                    BreakStart: x.BreakStart === null ? "" : x.BreakStart,
                    MinutesBreak: x.MinutesBreak,
                    IsRestday: x.IsRestday,
                    BreakDetails: x.BreakDetails
                })
            )
        })

    }
    else {
        for (let i = 0; i <= 6; i++) {
            var restday = false;
            if (week[i] === "Saturday" || week[i] === "Sunday")
                restday = true
            list.push({
                Day: week[i],
                DayCount: 1.0,
                DayKey: dayOfWeekKey(week[i]),
                StartTime: "08:30",
                // EndTime:"17:30:00",
                HoursWork: 8,
                BreakStart: "12:30",
                MinutesBreak: 60,
                IsRestday: restday,
                BreakDetails: []
            });
        }
    }

    return list;
}
class ScheduleForm extends Component {

    state = {
        activeIndex: 0,
        Schedule: {
            _id: empty_id,
            ScheduleName: '',
            ScheduleNumber: 0,
            ScheduleType: 'STANDARD',
            NonPunching: false,
            Details: [],
            GracePeriod: 0,
            IncludeGraceOnLate: false,
            ComputeOverBreak: false,
            HalfdayHours: 4,
            AbsentHours: 8,
            AllowEarlyInMinutes: 0,
            MinimumOT: 0,
            MaxOT: 24,
            OTRoundDown: 0,
            OTStart: 0,
            OverBreakType: 0,
            MaxAllowedAutoComputeOT: 0,
            MustCompleteRHToCountOT: false,
            AllowComputeHDSDOT: false,
            AllowComputeRD: false,
            ConsiderScheduleOnHoliday: false,
            ComputeEarlyOT: false,
            IsDeleted: false,
            Flexi: 0,
            LateRoundUps: [],
            BreakDetails: [],
            UndertimeRoundUp: 0,
            NDStart: "22:00",
            NDEnd: "06:00",
            MinimumGap: 3,
            MustBePresentBeforeHD: false,
            MustBePresentAfterHD: false,
            HoursPerDay: 8,

        },
        saving: false,
        dialog: false,
        dialogContent: null,
        dialogTitle: '',
        ScheduleDetails: [],
        selectedDay: null,
        BreakDetails: [],
        LateRoundUpIndexes: [],
        LateRoundUps: [],
        conflict: [],
        showConflict: false,
        isMobileView: false,
        modalOpen: false,
    }
    constructor(props, context) {
        super(props, context);
        this.state.Schedule = props.schedule == null ? this.state.Schedule : props.schedule;
        this.state.ScheduleDetails = props.schedule == null ? this.state.ScheduleDetails : props.schedule.Details;
        // Week Schedule
        this._columns = [
            {
                key: 'Day',
                name: 'Day',
                width: 100,
                editable: false
            },
            {
                key: 'DayCount',
                name: 'Day Count',
                width: 30,
                editable: true
            },
            {
                key: 'StartTime',
                name: 'Start Time',
                width: 120
            },
            // {
            //     key: 'EndTime',
            //     name: 'End Time',
            //     editable: true
            // },
            {
                key: 'HoursWork',
                name: 'Hours Work',
                width: 120,
                editable: true
            },
            {
                key: 'BreakStart',
                name: 'Break Start',
                width: 120,
                editable: true
            },
            {
                key: 'MinutesBreak',
                name: 'Minutes Break',
                width: 120,
                editable: true
            },
            {
                key: 'IsRestday',
                name: 'Is Restday?',
                width: 120,
                editable: true
            },
        ];
        this.LateRoundUpColumn = [
            {
                key: 'Late',
                name: 'Late (mins)',
                width: 100,
                editable: true
            },
            {
                key: 'RoundUp',
                name: 'RoundUp (mins)',
                width: 120,
                editable: true
            }];
        this.state.ScheduleDetails = DetailsDisplayFormat(this.state.ScheduleDetails);


    }
    componentWillMount() {
        var scheduleNo = this.state.Schedule.ScheduleNumber;
        var Schedule = this.state.Schedule;
        if (scheduleNo === 0) {
            this.GetScheduleNo(data => {
                Schedule.ScheduleNumber = data;
                this.setState({ Schedule: Schedule });
            });
        }
        window.addEventListener("resize", this.resize.bind(this));
        this.resize();
    }
    resize() {
        this.setState({ isMobileView: window.innerWidth <= 812 });
    }

    handleGridRowsUpdated = ({ fromRow, toRow, updated }) => {
        let ScheduleDetails = this.state.ScheduleDetails.slice();
        var conflicts = [];
        for (let i = fromRow; i <= toRow; i++) {
            let rowToUpdate = ScheduleDetails[i];
            let updatedRow = Update(rowToUpdate, { $merge: ValidateNumber(updated) });
            ScheduleDetails[i] = updatedRow;

            // var conflict = validateScheduleRow(updatedRow);
            // if(conflict.length == 0){           
            //     ScheduleDetails[i] = updatedRow;
            // }
            // else{
            //     conflicts.push(conflict);
            // }
        }
        // if(conflicts.length != 0){

        //     this.setState({conflict:conflict,
        //         showConflict:true
        //     });
        // }
        this.setState({ ScheduleDetails: ScheduleDetails });
    };

    handleGridLateRowsUpdated = ({ fromRow, toRow, updated }) => {
        let LateRoundUps = this.state.Schedule.LateRoundUps.slice();
        var value = updated.Late != undefined ? updated.Late :
            updated.RoundUp != undefined ? updated.RoundUp : 0;
        if (updated.Late != undefined)
            updated.Late = ValidateNumber(value);
        if (updated.RoundUp != undefined)
            updated.RoundUp = ValidateNumber(value);
        for (let i = fromRow; i <= toRow; i++) {
            let rowToUpdate = LateRoundUps[i];
            let updateLateRow = Update(rowToUpdate, { $merge: updated });
            LateRoundUps[i] = updateLateRow;
        }
        let schedule = this.state.Schedule;
        schedule.LateRoundUps = LateRoundUps;
        this.setState({ Schedule: schedule });
    };
    rowGetter = (i) => {
        return this.state.ScheduleDetails[i];
    };
    rowLateRoundUPGetter = (i) => {
        return this.state.Schedule.LateRoundUps[i];
    };
    handleClick = (e, titleProps) => {
        const { index } = titleProps
        const { activeIndex } = this.state
        const newIndex = activeIndex === index ? -1 : index

        this.setState({ activeIndex: newIndex })
    }
    CopyFirstRow = (e) => {

        var details = this.state.ScheduleDetails;
        if (details != null) {
            for (let n = 1; n < details.length; n++) {
                var det = details[0];
                details[n].DayCount = det.DayCount;
                details[n].StartTime = det.StartTime;
                details[n].HoursWork = det.HoursWork;
                details[n].BreakStart = det.BreakStart;
                details[n].MinutesBreak = det.MinutesBreak;
                details[n].IsRestday = det.IsRestday;
                details[n].BreakDetails = det.BreakDetails;
            }
        }
        this.setState({ ScheduleDetails: details });

    }
    handleChange = name => (det, event) => {
        var current = this.state.Schedule;
        let columnNumbers = ["GracePeriod", "HalfdayHours", "AbsentHours", "AllowEarlyInMinutes",
            "MinimumOT", "MaxOT", "OTRoundDown", "OTStart", "MaxAllowedAutoComputeOT",
            "Flexi", "UndertimeRoundUp", "MinimumGap", "HoursPerDay"];
        let isNumeric = columnNumbers.includes(name) ? true : false;
        let value = event.target != undefined ? event.target.value : event.value;
        if (current[name] == undefined) {
            var details = this.state.ScheduleDetails;
            var detail = details.filter(function (x) { return x.DayKey == det.DayKey });
            var index = details.indexOf(detail[0]);
            let columnNumbers = ["MinutesBreak", "HoursWork"];
            let isNumeric = columnNumbers.includes(name) ? true : false;
            detail[0][name] = isNumeric ? handleNumeric(event, false) : value;
            details[index] = detail[0];
            this.setState({ ScheduleDetails: details });
            // current.Details = details;
        } else {
            current[name] = isNumeric ? handleNumeric(event, false) : value;
        }
        this.setState({ Schedule: current });
    };
    handleCheckChange = name => (det, e) => {
        var current = this.state.Schedule;
        if (current[name] == undefined) {
            var details = this.state.ScheduleDetails;
            var detail = details.filter(function (x) { return x.DayKey == det.DayKey });
            var index = details.indexOf(detail[0]);
            detail[0][name] = detail[0][name] === false ? true : false;
            details[index] = detail[0];
            this.setState({ ScheduleDetails: details });
            // current.Details = details;
        } else {
            current[name] = current[name] === false ? true : false;
        }
        this.setState({ Schedule: current });
    }
    handleComboChange = (det, name, val) => {
        var current = this.state.Schedule;
        if (current[val.name] == undefined) {
            var details = current.Details;
            var index = details.indexOf(det);
            var detail = details[index];
            detail[val.name] = val.value;
            details[index] = detail;
            current.Details = details;
        } else {
            current[val.name] = val.value;
        }
        this.setState({ Schedule: current });
    }
    handleAddMoreBreak = name => (det, e) => {
        this.setState({ BreakDetails: det.BreakDetails, selectedDay: det.DayKey, modalOpen: true });
    }
    updateBreakDetails = (breaks) => {
        let list = breaks;
        var dayKey = this.state.selectedDay;
        var details = this.state.ScheduleDetails;
        var detail = details.filter(function (x) { return x.DayKey == dayKey });
        var index = details.indexOf(detail[0]);
        detail[0].BreakDetails = list;
        details[index] = detail[0];
        this.setState({ ScheduleDetails: details });
    }
    onCloseDialog = () => {
        this.setState({ dialog: false });
        this.props.onUpdateComplete(this.state.Schedule);
    }
    btnAddLateRoundUp_Click = (event) => {
        let schedule = this.state.Schedule;
        let lateRoundUps = schedule.LateRoundUps;
        lateRoundUps.push({ Late: 0, RoundUp: 0 });
        this.setState({ Schedule: schedule });
    }
    btnDeleteLateRoundUp_Click = (event) => {
        let rowindexes = this.state.LateRoundUpIndexes;
        let schedule = this.state.Schedule;
        let lateRoundUps = schedule.LateRoundUps;
        rowindexes.map(r => lateRoundUps.splice(r, 1));
        schedule.LateRoundUps = lateRoundUps;
        this.setState({
            Schedule: schedule,
            LateRoundUpIndexes: []
        });

    }
    onRowsSelected = (rows) => {
        this.setState({ LateRoundUpIndexes: this.state.LateRoundUpIndexes.concat(rows.map(r => r.rowIdx)) });
    }
    onRowsDeselected = (rows) => {
        let rowIndexes = rows.map(r => r.rowIdx);
        this.setState({ LateRoundUpIndexes: this.state.LateRoundUpIndexes.filter(i => rowIndexes.indexOf(i) === -1) });
    };

    Save = (callback, model) => {
        ajaxPost({
            url: 'api/Schedule/save',
            data: {
                "model": JSON.stringify(model),
                "includeDeleted": false,
                "startingIndex": 0,
                "itemCount": 1
            },
            onSuccess: data => {
                callback(data);
            },
            onError: function (err) {
                alert(JSON.stringify(err));
            },
            finally: () => { this.setState({ saving: false }); }
        })
    }
    GetScheduleNo = (callback) => {
        ajaxPost({
            url: 'api/Schedule/getScheduleNo',
            data: {
            },
            onSuccess: data => {
                callback(data);
            },
            onError: function (err) {
                alert(JSON.stringify(err));
            },
            finally: () => { this.setState({ saving: false }); }
        })
    }
    handleSave = (e, data) => {
        var model = this.state.Schedule;
        if (model.ScheduleName.trim() === "") {
            this.setState({ showConflict: true });
            this.setState({ conflict: ["Schedule Name must have a value."] });
            return;
        }

        this.setState({ saving: true });

        model.Details = detailsFormat(this.state.ScheduleDetails);
        this.Save(data => {
            this.setState({ saving: false });
            this.setState({ showConflict: false });
            if (data.content != null) {
                this.setState({ Schedule: data.content });
                this.DialogOnSuccess(model._id == empty_id);
            } else if (data.errors != null) {
                if (data.errors.length != 0) {
                    this.setState({ showConflict: true });
                    this.setState({ conflict: data.errors });
                }
            }

        }, model);
    }
    
    DialogOnSuccess = (isNew) => {
        this.setState({ dialogTitle: "Save" });
        this.setState({
            dialogContent: isNew ? (
                <div>
                    <p>Schedule Profile has been added successfully</p>
                </div>
            ) :
                (
                    <div>
                        <p>Schedule Profile has been updated successfully</p>
                    </div>
                )
        });
        this.setState({ dialog: true });
    }

    onRemoveLateRoundup = (index) => {
        var values = this.state.Schedule.LateRoundUps;
        values.splice(index, 1);
        this.setState({ Schedule: {...this.state.Schedule} });
    }

    isNumber = (n) => {
        return !isNaN(parseFloat(n)) && isFinite(n);
    }

    onUpdateLateRoundup = (i, values) => {
        if (this.isNumber(values[0]) === false || this.isNumber(values[1]) === false) {
            return "Values must be positive numbers"
        }
        if (parseInt(values[0]) < 0 || parseInt(values[1]) < 0) {
            return "Values must be positive numbers"
        }
        var roundUps = this.state.Schedule.LateRoundUps;
        var updated = {
            Late: parseInt(values[0]),
            RoundUp: parseInt(values[1])
        }
        roundUps.splice(i, 1, updated);
        this.setState({ Schedule:{...this.state.Schedule} });

        return "";
    }

    render() {
        const { activeIndex } = this.state
        const scheduleTypes = [
            { key: 1, text: 'STANDARD', value: 'STANDARD' },
            { key: 2, text: 'FLEXIBLE', value: 'FLEXIBLE' },
        ]
        const overbreakTypes = [
            { key: 1, text: 'Over Break Before And After Break Schedule', value: 0},
            { key: 2, text: 'Over Break Before Break Schedule', value: 1},
            { key: 3, text: 'Over Break After Break Schedule', value: 2},
        ]
        return <>
            <div className={modStyles.module}>

                <div style={{ overflow: "hidden" }}>
                    <h2 style={{ color: "#606060", marginTop: '1%', float: "left" }}>Schedule Profile Details</h2>
                </div>
                <Menu size="mini" secondary stackable style={{ marginRight: '1em' }}>
                    <Menu.Menu position="right" >
                        <Button.Group>
                            <Button content="Save" size="mini" icon="save"
                                onClick={this.handleSave.bind(this)} style={{ margin: "0px 3px 0px 0px" }} />
                            <Button content="Cancel" size="mini" icon="cancel"
                                onClick={this.props.onCloseForm.bind(this)} />
                        </Button.Group>
                    </Menu.Menu>
                </Menu>
                <Loader active={this.state.saving} />
                {this.state.showConflict &&
                    <Message negative style={{ minHeight: '4.5em' }}>
                        <Message.Header>Conflict !</Message.Header>
                        <Message.Content>{this.state.conflict.map(n => {
                            return (<p>{n}</p>)
                        })}</Message.Content>
                    </Message>}
                <Grid stackable columns={2} style={{ margin: '0' }}>
                    <Grid.Row>
                        <Grid.Column>
                            <Input fluid style={{ width: "300px" }} placeholder='Schedule Name'
                                value={this.state.Schedule.ScheduleName}
                                onChange={this.handleChange("ScheduleName").bind(this)}
                            />
                        </Grid.Column>
                        <Grid.Column>

                            <Dropdown style={{ width: "300px", }} fluid selection options={scheduleTypes} placeholder="Select Schedule Type"
                                value={this.state.Schedule.ScheduleType} name="ScheduleType"
                                onChange={this.handleComboChange.bind(this, "")}
                            />
                        </Grid.Column>
                    </Grid.Row>
                    <Grid.Row >
                        <Grid.Column>
                            <Input disabled style={{ width: "300px", margin: "0 200px 5px 0", }} placeholder='Schedule No'
                                value={this.state.Schedule.ScheduleNumber}
                                onChange={this.handleChange("ScheduleNumber").bind(this)} />
                        </Grid.Column>
                        <Grid.Column>
                            <Checkbox toggle label="Non-punching" checked={this.state.Schedule.NonPunching}
                                onClick={this.handleCheckChange("NonPunching").bind(this)} />
                        </Grid.Column>
                    </Grid.Row>
                </Grid>
                <Grid stackable columns={1} style={{ marginLeft: "0", marginRight: "0" }}>
                    <Grid.Row>
                        <Grid.Column>
                            <Accordion fluid styled>
                                <Accordion.Title active={activeIndex === 0} index={0} onClick={this.handleClick}>
                                    <Icon name='dropdown' />
                                    General
                                </Accordion.Title>
                                <Accordion.Content active={activeIndex === 0}>

                                    <div>
                                        <Button content="Copy First Row" icon="copy"
                                            onClick={this.CopyFirstRow.bind(this)} />
                                    </div>
                                    <Table stackable fluid>
                                        <Table.Header>
                                            <Table.Row>
                                                <Table.HeaderCell width={2}>Day</Table.HeaderCell>
                                                <Table.HeaderCell width={2}>Day Count</Table.HeaderCell>
                                                <Table.HeaderCell width={3}>Start Time</Table.HeaderCell>
                                                <Table.HeaderCell width={2}>Hours Work</Table.HeaderCell>
                                                <Table.HeaderCell width={3}>Break Start</Table.HeaderCell>
                                                <Table.HeaderCell width={2}>Minutes Break</Table.HeaderCell>
                                                <Table.HeaderCell width={2}>Is Restday</Table.HeaderCell>
                                                <Table.HeaderCell width={1}></Table.HeaderCell>

                                            </Table.Row>
                                        </Table.Header>
                                        <Table.Body>
                                            {this.state.ScheduleDetails != null &&
                                                this.state.ScheduleDetails
                                                    .map(n => {
                                                        return (<Table.Row>
                                                            <Table.Cell>{n.Day}</Table.Cell>
                                                            <Table.Cell>
                                                            <Dropdown
                                                                fluid
                                                                selection
                                                                disabled={this.props.isBusy}
                                                                options={[{ key: "Whole Day", value: 1, text: "Whole Day" }, { key: "Half Day", value: 0.5, text: "Half Day" }]}
                                                                value={n.DayCount}
                                                                onChange={(event, data) => {   
                                                                    var details = this.state.ScheduleDetails;
                                                                    var detail = details.filter(function (x) { return x.DayKey === n.DayKey });
                                                                    var index = details.indexOf(detail[0]);
                                                                    detail[0].DayCount = data.value;
                                                                    details[index] = detail[0];
                                                                    this.setState({ ScheduleDetails: details }); 
                                                                }}
                                                                >
                                                            </Dropdown>
                                                                </Table.Cell>
                                                            <Table.Cell>
                                                                <Input fluid type="time" value={n.StartTime}
                                                                    onChange={this.handleChange("StartTime").bind(this, n)} />
                                                            </Table.Cell>
                                                            <Table.Cell>
                                                                <Input fluid type="number" value={n.HoursWork}
                                                                    onChange={this.handleChange("HoursWork").bind(this, n)} /> </Table.Cell>
                                                            <Table.Cell><Input fluid type="time" value={n.BreakStart}
                                                                onChange={this.handleChange("BreakStart").bind(this, n)} /></Table.Cell>
                                                            <Table.Cell><Input fluid value={n.MinutesBreak}
                                                                onChange={this.handleChange("MinutesBreak").bind(this, n)} /></Table.Cell>
                                                            <Table.Cell> <Checkbox toggle checked={n.IsRestday}
                                                                onClick={this.handleCheckChange("IsRestday").bind(this, n)} /></Table.Cell>
                                                            <Table.Cell><Button style={{ "margin": "5px", "padding": "5px" }} onClick={this.handleAddMoreBreak("BreakDetails").bind(this, n)} >
                                                                <Icon name='add' />
                                                                More Breaks</Button>
                                                            </Table.Cell>
                                                        </Table.Row>);
                                                    })
                                            }
                                        </Table.Body>
                                    </Table>
                                </Accordion.Content>
                                <Accordion.Title active={activeIndex === 1} index={1} onClick={this.handleClick}>
                                    <Icon name='dropdown' />
                                    Tardiness Policies
                                </Accordion.Title>
                                <Accordion.Content active={activeIndex === 1}>
                                    <Segment>
                                        <Grid stackable columns={4}>
                                            <Grid.Row>
                                                <Grid.Column>
                                                    <label>Hours Per Day</label>
                                                </Grid.Column>
                                                <Grid.Column>
                                                    <Input fluid placeholder='0'
                                                        value={this.state.Schedule.HoursPerDay}
                                                        onChange={this.handleChange("HoursPerDay").bind(this)} />
                                                </Grid.Column>
                                                <Grid.Column>
                                                    <label>Flexi (mins)</label>
                                                </Grid.Column>
                                                <Grid.Column>
                                                    <Input fluid placeholder='0'
                                                        value={this.state.Schedule.Flexi}
                                                        onChange={this.handleChange("Flexi").bind(this)} />
                                                </Grid.Column>
                                            </Grid.Row>
                                            <Grid.Row>
                                                <Grid.Column>
                                                    <label >Grace Period (mins)</label>
                                                </Grid.Column>
                                                <Grid.Column>
                                                    <Input fluid placeholder='0'
                                                        value={this.state.Schedule.GracePeriod}
                                                        onChange={this.handleChange("GracePeriod").bind(this)} />
                                                </Grid.Column>
                                                <Grid.Column>
                                                    <label >Include Grace Period on Late</label>
                                                </Grid.Column>
                                                <Grid.Column>
                                                    <Checkbox toggle fluid
                                                        checked={this.state.Schedule.IncludeGraceOnLate}
                                                        onClick={this.handleCheckChange("IncludeGraceOnLate").bind(this)} />
                                                </Grid.Column>
                                            </Grid.Row>
                                            <Grid.Row>
                                                <Grid.Column>
                                                    <label>late + undertime consider as halfday (hr/s)</label>
                                                </Grid.Column>
                                                <Grid.Column>
                                                    <Input fluid placeholder='0'
                                                        value={this.state.Schedule.HalfdayHours}
                                                        onChange={this.handleChange("HalfdayHours").bind(this)} />
                                                </Grid.Column>
                                                <Grid.Column>
                                                    <label>late + undertime consider as absent (hr/s)</label>
                                                </Grid.Column>
                                                <Grid.Column>
                                                    <Input fluid placeholder='0'
                                                        value={this.state.Schedule.AbsentHours}
                                                        onChange={this.handleChange("AbsentHours").bind(this)} />
                                                </Grid.Column>
                                            </Grid.Row>
                                            <Grid.Row>
                                                <Grid.Column>
                                                    <label>Undertime RoundUp (mins)</label>
                                                </Grid.Column>
                                                <Grid.Column>
                                                    <Input fluid placeholder='0'
                                                        value={this.state.Schedule.UndertimeRoundUp}
                                                        onChange={this.handleChange("UndertimeRoundUp").bind(this)} />
                                                </Grid.Column>
                                                <Grid.Column> <label >Compute Overbreak</label></Grid.Column>
                                                <Grid.Column>
                                                    <Checkbox toggle fluid
                                                        checked={this.state.Schedule.ComputeOverBreak}
                                                        onClick={this.handleCheckChange("ComputeOverBreak").bind(this)} />
                                                </Grid.Column>
                                            </Grid.Row>

                                            <Grid.Row>
                                                <Grid.Column > <label >Late RoundUp</label></Grid.Column>
                                                <Grid.Column>

                                                    <Button basic icon="add" content="Add" onClick={this.btnAddLateRoundUp_Click.bind(this)} />
                                                    <div className={modStyles.table_roundup}>
                                                        <div className={modStyles.table_roundup_header}>
                                                            <div>Late (mins)</div>
                                                            <div>Roundup (mins)</div>
                                                        </div>
                                                        <div className={modStyles.table_roundup_content}>
                                                            {
                                                                this.state.Schedule.LateRoundUps.map((x, i) => {
                                                                    return <RowEditor
                                                                        values={[
                                                                            x.Late,
                                                                            x.RoundUp
                                                                        ]}
                                                                        widths={['10em', '10em']}
                                                                        type={['TEXT', 'TEXT']}
                                                                        onRemove={this.onRemoveLateRoundup.bind(this, i)}
                                                                        onSave={this.onUpdateLateRoundup.bind(this, i)}
                                                                    />
                                                                })
                                                            }
                                                        </div>
                                                    </div>

                                                    {/*
                                                    <Button basic icon="delete" content="Delete" onClick={this.btnDeleteLateRoundUp_Click.bind(this)} />

                                                    <div className="ui grid" style={{ marginTop: "10px", width: this.state.isMobileView ? '100%' : '310px' }}>
                                                        <DataGrid
                                                            enableCellSelect={true}
                                                            enableRowSelect={true}
                                                            columns={this.LateRoundUpColumn}
                                                            rows={this.state.Schedule.LateRoundUps}
                                                            rowsCount={this.state.Schedule.LateRoundUps.length}
                                                            onGridRowsUpdated={this.handleGridLateRowsUpdated}
                                                            minHeight={180}
                                                            rowSelection={
                                                                {
                                                                    onRowsSelected: this.onRowsSelected,
                                                                    onRowsDeselected: this.onRowsDeselected,
                                                                    selectBy: {
                                                                        indexes: this.state.LateRoundUpIndexes
                                                                    }
                                                                }
                                                            }
                                                        />
                                                    </div>
                                                    */}
                                                </Grid.Column>
                                                <Grid.Column> <label>Overbreak Policies</label></Grid.Column>
                                                <Grid.Column>
                                                <Dropdown style={{ width: "300px", }} fluid selection options={overbreakTypes} placeholder="Select Over Break Type"
                                                    value={this.state.Schedule.OverBreakType} name="OverBreakType"
                                                    onChange={this.handleComboChange.bind(this, "")}
                                                />
                                                </Grid.Column>       
                                            </Grid.Row>
                                        </Grid>

                                    </Segment>
                                </Accordion.Content>

                                <Accordion.Title active={activeIndex === 2} index={2} onClick={this.handleClick}>
                                    <Icon name='dropdown' />
                                    Overtime Policies
                                </Accordion.Title>
                                <Accordion.Content active={activeIndex === 2}>
                                    <Segment>
                                        <Grid stackable columns={4}>
                                            <Grid.Row>
                                                <Grid.Column> <label>ND Start</label></Grid.Column>
                                                <Grid.Column>
                                                    <Input fluid type='time' placeholder='0'
                                                        value={this.state.Schedule.NDStart}
                                                        onChange={this.handleChange("NDStart").bind(this)} />
                                                </Grid.Column>
                                                <Grid.Column><label>ND End</label></Grid.Column>
                                                <Grid.Column>
                                                    <Input fluid type='time' placeholder='0'
                                                        value={this.state.Schedule.NDEnd}
                                                        onChange={this.handleChange("NDEnd").bind(this)} /></Grid.Column>
                                            </Grid.Row>
                                            <Grid.Row>
                                                <Grid.Column><label>Allowed Early In (min/s)</label></Grid.Column>
                                                <Grid.Column>
                                                    <Input fluid placeholder='0'
                                                        value={this.state.Schedule.AllowEarlyInMinutes}
                                                        onChange={this.handleChange("AllowEarlyInMinutes").bind(this)} />
                                                </Grid.Column>
                                                <Grid.Column><label>Must be present on Work Day before holiday for HD pay</label></Grid.Column>
                                                <Grid.Column>
                                                    <Checkbox toggle fluid
                                                        checked={this.state.Schedule.MustBePresentBeforeHD}
                                                        onClick={this.handleCheckChange("MustBePresentBeforeHD").bind(this)} />
                                                </Grid.Column>
                                            </Grid.Row>
                                            <Grid.Row>
                                                <Grid.Column><label>Minimum OT (min/s)</label></Grid.Column>
                                                <Grid.Column>
                                                    <Input fluid placeholder='0'
                                                        value={this.state.Schedule.MinimumOT}
                                                        onChange={this.handleChange("MinimumOT").bind(this)} />
                                                </Grid.Column>
                                                <Grid.Column><label>Must be present on Work Day after holiday for HD pay</label></Grid.Column>
                                                <Grid.Column>
                                                    <Checkbox toggle fluid
                                                        checked={this.state.Schedule.MustBePresentAfterHD}
                                                        onClick={this.handleCheckChange("MustBePresentAfterHD").bind(this)} />
                                                </Grid.Column> 
                                            </Grid.Row>
                                            <Grid.Row>
                                                <Grid.Column><label>OT Round down (min/s)</label></Grid.Column>
                                                <Grid.Column>
                                                    <Input fluid placeholder='0'
                                                        value={this.state.Schedule.OTRoundDown}
                                                        onChange={this.handleChange("OTRoundDown").bind(this)} />
                                                </Grid.Column>
                                                <Grid.Column><label>Must Complete Regular Hours work  to count as OT</label></Grid.Column>
                                                <Grid.Column>
                                                    <Checkbox toggle fluid
                                                        checked={this.state.Schedule.MustCompleteRHToCountOT}
                                                        onClick={this.handleCheckChange("MustCompleteRHToCountOT").bind(this)} />
                                                </Grid.Column> 
                                            </Grid.Row>
                                            <Grid.Row>
                                                <Grid.Column> <label>Minimum gap between shift (hr/s)</label></Grid.Column>
                                                <Grid.Column> <Input fluid placeholder='0'
                                                    value={this.state.Schedule.MinimumGap}
                                                    onChange={this.handleChange("MinimumGap").bind(this)} /></Grid.Column>
                                                    <Grid.Column><label>OT Break after Shift (min/s)</label></Grid.Column>
                                                <Grid.Column><Input fluid placeholder='0'
                                                    value={this.state.Schedule.OTStart}
                                                    onChange={this.handleChange("OTStart").bind(this)} /></Grid.Column>
                                            </Grid.Row>
                                            <Grid.Row>
                                                <Grid.Column>Max Allowed Auto Compute OT (hr/s)</Grid.Column>
                                                <Grid.Column><Input placeholder='0' fluid
                                                    value={this.state.Schedule.MaxAllowedAutoComputeOT}
                                                    onChange={this.handleChange("MaxAllowedAutoComputeOT").bind(this)} />
                                                </Grid.Column>
                                                <Grid.Column><label>Allow to compute HD/SD OT</label></Grid.Column>
                                                <Grid.Column>
                                                    <Checkbox toggle fluid
                                                        checked={this.state.Schedule.AllowComputeHDSDOT}
                                                        onClick={this.handleCheckChange("AllowComputeHDSDOT").bind(this)} />
                                                </Grid.Column>
                                            </Grid.Row>
                                            <Grid.Row>
                                                <Grid.Column>Max OT (hr/s)</Grid.Column>
                                                <Grid.Column><Input placeholder='0' fluid
                                                    value={this.state.Schedule.MaxOT}
                                                    onChange={this.handleChange("MaxOT").bind(this)} />
                                                </Grid.Column>
                                                <Grid.Column><label>Allow to compute RESTDAY</label></Grid.Column>
                                                <Grid.Column>
                                                    <Checkbox toggle fluid
                                                        checked={this.state.Schedule.AllowComputeRD}
                                                        onClick={this.handleCheckChange("AllowComputeRD").bind(this)} />
                                                </Grid.Column>
                                            </Grid.Row>
                                            <Grid.Row>
                                                <Grid.Column></Grid.Column>
                                                <Grid.Column></Grid.Column>
                                                <Grid.Column><label>Consider Schedule on Holiday</label></Grid.Column>
                                                <Grid.Column>
                                                    <Checkbox toggle fluid
                                                        checked={this.state.Schedule.ConsiderScheduleOnHoliday}
                                                        onClick={this.handleCheckChange("ConsiderScheduleOnHoliday").bind(this)} />
                                                </Grid.Column>
                                            </Grid.Row>

                                            <Grid.Row>
                                                <Grid.Column></Grid.Column>
                                                <Grid.Column></Grid.Column>
                                                <Grid.Column><label>Allowed Compute Early OT (hr/s)</label></Grid.Column>
                                                <Grid.Column>
                                                    <Checkbox toggle fluid
                                                        checked={this.state.Schedule.ComputeEarlyOT}
                                                        onClick={this.handleCheckChange("ComputeEarlyOT").bind(this)} />
                                                </Grid.Column>
                                            </Grid.Row>
                                        </Grid>
                                    </Segment>
                                </Accordion.Content>
                            </Accordion>
                        </Grid.Column>
                    </Grid.Row>
                </Grid>
            </div>


            {/* Modal Form */}
            {<Modal size="mini" open={this.state.dialog} onClose={this.close} >
                <Header content={this.state.dialogTitle} />
                <Modal.Content>
                    {this.state.dialogContent}
                </Modal.Content>
                <Modal.Actions>
                    <Button basic icon="check" content="Yes"
                        onClick={this.onCloseDialog.bind(this)} />
                </Modal.Actions>
            </Modal>}
            {this.state.modalOpen &&
                <BreakScheduleForm title="Break Schedule Details Form" modalOpen={true} onClose={() => { this.setState({ modalOpen: false }); }}
                    onCallback={this.updateBreakDetails}
                    breaks={this.state.BreakDetails}
                />
            }


        </>
    }
}
export default ScheduleForm
import React, { Component } from 'react';
import { Image } from 'semantic-ui-react';
import 'semantic-ui-css/semantic.min.css';
import { isTaskAvailable, momentDateFormat } from '../../utils';
import PayrollTransactionBaseWithDate from '../Commons/PayrollTransactionBaseWithDate';
import PayrollAdjustmentForm from './Forms/PayrollAdjustmentForm';
import modStyles from './PayrollAdjustmentList.module.css'

const moment = require('moment');

const ViewID = "PayrollAdjustment";
const ViewCaption = "Payroll Adjustment";

const userRightsKeys = {
    view: "PAYROLL_ADJUSTMENT_VIEW",
    create: "PAYROLL_ADJUSTMENT_CREATE",
    edit: "PAYROLL_ADJUSTMENT_EDIT",
    delete: "PAYROLL_ADJUSTMENT_DELETE",
    import: "PAYROLL_ADJUSTMENT_IMPORT",
    template: "PAYROLL_ADJUSTMENT_TEMPLATE",
    export: "PAYROLL_ADJUSTMENT_TEMPLATE",
}


class PayrollAdjustmentList extends Component {

    constructor(props) {
        super(props);
        this.state = {
            modalVisible: false,
            modalFilterVisible: false,
        }
        this.modalRef = React.createRef();
        this.parentRef = React.createRef();
    }


    createUrls = () => {
        return {
            search: "api/PayrollAdjustment/search",
            setDeleted: "api/PayrollAdjustment/setDeleted",
            save: "api/PayrollAdjustment/save",
            load: "api/PayrollAdjustment/load",
            downloadTemplate: "api/PayrollAdjustment/downloadTemplate",
            importToExcel: "api/PayrollAdjustment/importToExcel",
            exportToExcel: "api/PayrollAdjustment/exportToExcel",
        };
    }

    toCard = (model) => {
        return {
            _id: model._id,
            employee: model.Employee,
            type: model.PayrollAdjustmentType,
            transactionType: model.TransactionType,
            from: moment(model.StartMonth + '/1/' + model.StartYear).format("MMMM YYYY"),
            to: moment(model.EndMonth + '/1/' + model.EndYear).format("MMMM YYYY"),
            frequency: model.Frequency,
            amount: model.Amount,
            deleted: model.IsDeleted === null ? false : model.IsDeleted,
            recordDate: moment(model.RecordDate).format(momentDateFormat),
            editable: true,
            idle: false
        };
    };

    toModel = (card) => {
        return {
            _id: card.id,
            Employee: card.employee,
            PayrollAdjustmentType: card.payrollAdjustmentType,
            TransactionType: card.transactionType,
            Frequency: card.frequency,
            Amount: card.amount,
        };
    };

    createFields = () => {
        // must have only one valueAs: 'header' and 'desciption',
        // 'content' can be none or many
        let fields = [
            { key: 'employee', name: 'Employee', valueAs: 'header' },
            { key: 'type', name: 'Type', valueAs: 'description' },
            { key: 'transactionType', name: 'Transaction Type', valueAs: 'content' },
            { key: 'from', name: 'From', valueAs: 'content' },
            { key: 'to', name: 'To', valueAs: 'content' },
            { key: 'recordDate', name: 'Date Filed', valueAs: 'content', width: 90, resizable: true },
            { key: 'frequency', name: 'Payment Frequency', valueAs: 'content' },
            { key: 'amount', name: 'Amount', valueAs: 'content' },
            // { key: '', name: '', valueAs: 'content' },
        ];
        return fields;
    }

    validate = (data) => {
        var errTitle = "", errCaption = "";
        if (this.isNullOrEmpty(data.employeeIds)
            || this.isNullOrEmpty(data.PayrollAdjustmentTypeID)
            || this.isNullOrEmpty(data.TransactionType)
            || this.isNullOrEmpty(data.StartMonth)
            || this.isNullOrEmpty(data.StartYear)
            || this.isNullOrEmpty(data.EndMonth)
            || this.isNullOrEmpty(data.EndYear)
            || this.isNullOrEmpty(data.Frequency)
            || this.isNullOrEmpty(data.Amount)
        ) {
            errCaption = "Please fill up all field/s";
            errTitle = "Invalid";
        }
        else if (data.CutOffNumber < 1) {
            errCaption = "Invalid CutOff Number";
            errTitle = "Invalid";
        }
        else if (data.Amount < 0) {
            errCaption = "Invalid Amount";
            errTitle = "Invalid";
        }
        else {
            this.parentRef.current.setState({ showRequiredField: false });
            return true;
        }
        this.parentRef.current.setState({ showRequiredField: true, errTitle, errCaption });
        return false;
    };

    showModal = (data, visible) => {
        visible && this.modalRef.current.setContent(data);
        this.setState({ modalVisible: visible });
    }

    showModalFilter = (visible) => {
        this.setState({ modalFilterVisible: visible });
    }

    isNullOrEmpty(val) {
        if (val === null || val === undefined || val === "" || val.length === 0) return true;
        else return false;
    }

    render() {
        let self = this;
        const { open, data } = this.props;

        let model = this.state.Model;

        return (
            <PayrollTransactionBaseWithDate
                ref={this.parentRef}
                viewID={ViewID}
                title={ViewCaption}
                fields={this.createFields()}
                toModel={this.toModel}
                toCard={this.toCard}
                urls={this.createUrls()}
                validate={this.validate}
                props={this.props}
                showModal={this.showModal.bind(this)}
                showModalFilter={this.showModalFilter.bind(this)}
                
                userRightsKeys={userRightsKeys}
            // hasExportToExcel={true}
            >
                <PayrollAdjustmentForm
                    ref={self.modalRef}
                    open={self.state.modalVisible}
                    errTitle={self.parentRef.current ? self.parentRef.current.state.errTitle : ""}
                    errCaption={self.parentRef.current ? self.parentRef.current.state.errCaption : ""}
                    showRequiredField={self.parentRef.current ? self.parentRef.current.state.showRequiredField : ""}
                    isBusy={self.parentRef.current ? self.parentRef.current.state.isSaving : false}
                    onCancel={() => { 
                        self.setState({ modalVisible: false }, self.parentRef.current.setState({ showRequiredField: false })); 
                    }}
                    onCreateEmpClick={() => {
                        this.setState({ modalVisible: false });
                        this.props.createEmpCallback();
                    }}
                    onSave={() => {
                        self.parentRef.current.saveFromModal(
                            self.modalRef.current.getContent()
                        );
                    }}
                />

            </PayrollTransactionBaseWithDate>
        );
    }
}

PayrollAdjustmentList.getViewID = () => { return ViewID };
PayrollAdjustmentList.getCaption = () => { return ViewCaption }
PayrollAdjustmentList.getIcon = () => {
    // return (<Image style={{"height":"24px","width":"24px"}} src='Icons/payrolladjustment_small.png' avatar/>); 
    return (<Image style={{ "height": "24px", "width": "24px" }} src='/Icons/Payroll_Adjustment.png' avatar />);
}
PayrollAdjustmentList.getIconSrc = (size) => {
    // return 'Icons/tr_payrolladjustment_' + size + '.png' 
    return '/Icons/Payroll_Adjustment.png'
}
PayrollAdjustmentList.getViewAccessKey = function () {
    var count = Object.keys(userRightsKeys).filter(name => isTaskAvailable(userRightsKeys[name]) === true).length;
    return count > 0 ? true : false;
};
export default PayrollAdjustmentList
import React, { Component } from 'react';
import { ajaxPost } from '../../ajax.js';
import { Message, Card, Icon, Header, Divider, Button, List, Modal, Label, Loader } from 'semantic-ui-react';

var { isTaskAvailable } = require('../../utils');

class AttendanceTrail extends Component {
    state = {
        dashboardItems: [],
        loading: false
    }

    styles =
        {
            cards:
            {
                margin: '20px'
            }
        }

    componentDidMount() {
        this.onLoadAnalytics();
    }

    onLoadAnalytics = () => {
        this.setState({ loading: true });
        ajaxPost({
            url: 'api/Analytics/LoadCurrentAnalytics',
            data: {
                DisplayCount: 4
            },
            onSuccess: data => {
                var items = this.state.dashboardItems;
                items.push({ Header: "PRESENT", Color: "#bbd87a", Count: data.TotalOnTime, Percentage: data.OnTimePercentage });
                items.push({ Header: "LATE", Color: "#f5A299", Count: data.TotalLate, Percentage: data.LatePercentage });
                items.push({ Header: "ABSENT", Color: "#ece198", Count: data.TotalAbsent, Percentage: data.AbsentPercentage });
                items.push({ Header: "ON-LEAVE", Color: "#97D0DD", Count: data.TotalLeave, Percentage: data.LeavePercentage });
                this.setState({ dashboardItems: items });
                this.setState({ loading: false });
            },
            finally: () => { }
        });
    }

    render() {
        return (
            <div>
                <Header>
                    <Icon name='calendar check outline' disabled />
                    <Header.Content>
                        Today's Attendance
                    </Header.Content>
                </Header>
                <Divider />
                {
                    this.state.loading &&
                    <div>
                        <Loader active inline></Loader>&nbsp;<b>Loading Attendance Data...</b>
                    </div>
                }
                <Card.Group itemsPerRow={8} stackable style={this.styles.cards}>
                    {
                        this.state.dashboardItems.map(item => {
                            return (
                                <Card style={{ borderColor: item.Color, height: '13em' }}
                                key={item.Header}
                                >
                                    <Message attached style={{ backgroundColor: item.Color }}>
                                        <Message.Header>
                                            <p style={{ textAlign: 'center', fontSize: '15px', }}>{item.Header}</p>
                                        </Message.Header>
                                    </Message>
                                    <Card.Content>
                                        <Card.Description>
                                        <Header as='h1' textAlign='center' style={{fontSize: '5em'}}>
                                            {/* <Icon name='users' /> */}
                                            <Header.Content>{item.Count}</Header.Content>
                                        </Header>
                                        </Card.Description>
              
                                        {/* <Card.Description>
                                            {item.Percentage}
                                        </Card.Description> */}
                                    </Card.Content>
                                </Card>
                            )
                        })
                    }
                </Card.Group>
                {/* <Header>
                    <Icon name='calendar check outline' disabled />
                    <Header.Content>
                        Today's Attendance
                    </Header.Content>
                </Header>
                <Divider />
                <Card.Group>
                    <Card color='green'>
                        <Message attached color='green'>
                            <Message.Header>
                                <p style={{ textAlign: 'center', fontSize: '30px' }}>Present</p>
                            </Message.Header>
                        </Message>
                        <Card.Content>
                            <Card.Header>
                                <p style={{ textAlign: 'center', fontSize: '30px' }}>20</p>
                            </Card.Header>
                        </Card.Content>
                    </Card>
                    <Card>
                        <Card.Content>
                            <Card.Header>Absent</Card.Header>
                        </Card.Content>
                    </Card>
                    <Card>
                        <Card.Content>
                            <Card.Header>Late</Card.Header>
                        </Card.Content>
                    </Card>
                    <Card>
                        <Card.Content>
                            <Card.Header>On-Leave</Card.Header>
                        </Card.Content>
                    </Card>
                </Card.Group> */}
            </div>
        )
    }
}
AttendanceTrail.getViewID = () => { return "AttendanceTrailModule" };
AttendanceTrail.getCaption = () => { return "Today's Attendance" };
AttendanceTrail.getViewAccessKey = () => { return isTaskAvailable("TODAY_ATTENDANCE_VIEW") };
export default AttendanceTrail;
import React, { Component } from 'react';
import { Loader, Card, Image } from 'semantic-ui-react';
import 'semantic-ui-css/semantic.min.css';

import { ajaxPost } from '../ajax';

import { _modulesList } from './ModuleList';


class ExternalNotification extends Component {
    constructor(props) {
        super(props);
        this.FunctionCollection = [];

        this.state =
            {
                external: null,
                header: null,
                description: null,
            }
        _modulesList.forEach(grp => {
            grp.Modules.forEach(x => {
                if ("init" in x)
                    x.init(this);
            });
        });


    }

    componentDidMount = () => {
        var _this = this;
        var searchParameter = {
            data: this.props.id,
            url: "api/Notification/load",
            onError: (error) => {
            },
            onSuccess: (data, sender) => {
                if (data === null) {
                    _this.setState({
                        header: "No Data",
                        description: "There is no data to view"
                    })
                } else {
                    if (data.RecipientID === global.userId)
                        _this.FunctionCollection[data.CallbackFunction](_this, "View", data, (result) => {
                            this.setState({ header: "Message", description: result, external: null })

                        })
                    else
                        _this.setState({
                            header: "Access Denied",
                            description: "This email link is not intended for your viewing"
                        })
                }
            },
            finally: () => {
            }
        };
        ajaxPost(searchParameter);

    }

    render = () => {
        var styles =
        {
            segment:
            {
                width: "500px",
                margin: "80px auto auto auto",
                border: "1px solid gray",
                padding: "10px",
            },
            topMenu:
            {
                background: "black",
                height: "50px",
                position: "fixed",
                top: "0px",
                width: "100%",
                padding: "5px"
            },
            logo:
            {
                height: "30px",
                display: "inline-block",
                marginLeft: "10px",
                marginTop: "5px"
            },
        }

        return (
            <div>
                <div style={styles.topMenu}>
                    <a href='/'><Image src={global.light_logo_url} style={styles.logo} /></a>
                </div>
                {this.state.external}
                <Loader active={this.state.external == null && this.state.header == null} />
                {
                    this.state.header != null &&
                    <Card header={this.state.header} description={this.state.description} style={styles.segment} />
                }
            </div>
        )

    }

}

export default ExternalNotification;
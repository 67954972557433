import React, { Component } from 'react';
import { Button, Input, Icon, Header, List, Loader, Menu, Pagination, Table, Card, Divider, Modal, Label, Tab, Grid, TableBody, TableFooter } from 'semantic-ui-react';
import 'semantic-ui-css/semantic.min.css';
import { ajaxPost } from '../ajax';
import { ToRound } from '../utils';
import MessageBoxYesNo from '../Commons/MessageBoxYesNo';
import MessageBoxOkOnly from '../Commons/MessageBoxOkOnly';

class PayrollDetailControl extends Component {
    state = {
        Parameters: {
            CutoffId: "-1",
            needle: "",
            startingIndex: 0,
            itemCount: 20,
            totalPages: 1
        },
        loading: false,
        initialPayrollLoad: true,
        ActivePage: 1,
        PageSize: 100,
        Payroll: [],
        showRemove: false,
        showMessageOnly: false,
        captionMessage: "",
        employeeSelected: "",
        cutoffType: "STANDARD"
    }
    constructor(props) {
        super(props);
        this.state.Parameters = this.props.Parameters;
        this.state.initialPayrollLoad = this.props.initialLoad;
        this.state.Payroll = this.props.Payroll;
        this.state.cutoffType = this.props.CutoffType;
    }
    componentWillMount() {
        if (this.state.initialPayrollLoad === true) {
            var param = this.state.Parameters;
            this.onSearch(param);
        }
    }
    //functon
    onSearch = (parameter) => {
        this.setState({ loading: true }, () => {
            this.loadCutoffPayroll(data => {
                var totalPages = data.Count / this.state.Parameters.itemCount;
                totalPages -= totalPages % 1;
                if (data.Count > totalPages * this.state.Parameters.itemCount) {
                    totalPages++;
                }
                var parameters = this.state.Parameters;
                parameters.totalPages = totalPages;
                this.setState({
                    Payroll: data.Content,
                    loading: false,
                    initialPayrollLoad: false,
                    Parameters: parameters,
                });
                this.props.updateState(this.state, "PayrollList");
            }, parameter);
        });

    }
    //api
    loadCutoffPayroll = (callback, parameters, startIndex, itemCount) => {
        parameters["ViewTab"] = "PAYROLL";
        ajaxPost({
            url: 'api/Cutoff/LoadCutoffPayroll',
            data: {
                "Parameters": JSON.stringify(parameters)
            },
            onSuccess: data => {
                callback(data);
            },
            finally: () => { }
        })
    }
    RemovePayroll = (callback) => {
        var parameter = {
            EmployeeID: this.state.employeeSelected,
            CutoffID: this.state.Parameters.CutoffId
        }
        ajaxPost({
            url: 'api/Cutoff/RemoveEmployeePayroll',
            data: {
                "Parameters": JSON.stringify(parameter)
            },
            onSuccess: data => {
                callback(data);
            },
            finally: () => { }
        })
    }
    //event handler
    handleChangePage = (event, page) => {
        const startIndex = page.activePage - 1;
        this.setState({ Page: startIndex });
        this.setState({ ActivePage: page.ActivePage });
    }
    handleClickRemove = (name, ID) => {
        this.setState({
            captionMessage: "You are about to Remove " + name + " on payroll processing.This action can't be redo,  Do you wish to proceed?",
            showRemove: true, employeeSelected: ID
        });
    }
    OnRemove = () => {
        this.setState({ showRemove: false });
        var payroll = this.state.Payroll;
        this.RemovePayroll(data => {
            payroll = payroll.filter(x => x.EmployeeID != this.state.employeeSelected);
            this.setState({ Payroll: payroll });
            this.setState({ showMessageOnly: true });

        });
    }
    render() {
        return (
            <div>
                <Loader active={this.state.loading} inline='centered' />

                <Card.Group stackable style={{height:'100px'}}>
                    {this.state.Payroll != null && this.state.Payroll.map(i => {
                        return (
                            <Card color='olive' fluid style={{ width: '100%' }}>
                                <Card.Content>
                                    {i.IsZeroNetPay && <Button icon basic color='red' floated='right'
                                        onClick={this.handleClickRemove.bind(this, i.Employee, i.EmployeeID)}>
                                        <Icon name="delete"></Icon> </Button>
                                    }
                                    <Card.Header>{i.Employee}</Card.Header>
                                    <Card.Description>
                                        <Grid columns={3} divided>
                                            <Grid.Row>
                                                <Grid.Column>
                                                    <Table basic='very'>
                                                        <TableBody>
                                                            {i.Incomes.map(entry => {
                                                                return (
                                                                    <Table.Row>
                                                                        <Table.Cell>
                                                                            {entry.Description}
                                                                        </Table.Cell>
                                                                        <Table.Cell>
                                                                            {entry.DisplayQuantity}
                                                                        </Table.Cell>
                                                                        <Table.Cell>
                                                                            <label>{ToRound(entry.Amount)}</label>
                                                                        </Table.Cell>
                                                                    </Table.Row>
                                                                )
                                                            })
                                                            }

                                                        </TableBody>

                                                    </Table>
                                                </Grid.Column>
                                                <Grid.Column>
                                                    <Table basic='very'>
                                                        <TableBody>
                                                            {i.Deductions.map(entry => {
                                                                return (
                                                                    <Table.Row>
                                                                        <Table.Cell>
                                                                            {entry.Description}
                                                                        </Table.Cell>
                                                                        <Table.Cell>
                                                                            {entry.DisplayQuantity}
                                                                        </Table.Cell>
                                                                        <Table.Cell>
                                                                            <label>{ToRound(entry.Amount)}</label>
                                                                        </Table.Cell>
                                                                    </Table.Row>
                                                                )
                                                            })
                                                            }
                                                        </TableBody>

                                                    </Table>
                                                </Grid.Column>
                                                <Grid.Column>
                                                    <Table basic='very'>
                                                        {i.Summaries.map(entry => {
                                                            return (
                                                                <Table.Row>
                                                                    <Table.Cell>
                                                                        {entry.Description}
                                                                    </Table.Cell>
                                                                    <Table.Cell>
                                                                        {entry.DisplayQuantity}
                                                                    </Table.Cell>
                                                                    <Table.Cell>
                                                                        <label style={{ color: i.IsZeroNetPay && entry.TransactionCode == "NETPAY" ? "red" : "black" }}>{ToRound(entry.Amount)}</label>
                                                                    </Table.Cell>
                                                                </Table.Row>
                                                            )
                                                        })
                                                        }
                                                    </Table>
                                                </Grid.Column>
                                            </Grid.Row>
                                            <Grid.Row>
                                                <Grid.Column>
                                                    <Table basic='very'>
                                                        <TableBody>
                                                            <Table.Row>
                                                                <Table.Cell>Total Income </Table.Cell>
                                                                <Table.Cell></Table.Cell>
                                                                <Table.Cell>
                                                                    <label>{ToRound(i.TotalIncome)}</label>
                                                                </Table.Cell>
                                                            </Table.Row>
                                                        </TableBody>
                                                    </Table>
                                                </Grid.Column>
                                                <Grid.Column>
                                                    <Table basic='very'>
                                                        <TableBody>
                                                            <Table.Row>
                                                                <Table.Cell>Total Deduction </Table.Cell>
                                                                <Table.Cell></Table.Cell>
                                                                <Table.Cell> <label>{ToRound(i.TotalDeduction)}</label></Table.Cell>
                                                            </Table.Row>
                                                        </TableBody>
                                                    </Table>
                                                </Grid.Column>
                                                <Grid.Column>
                                                </Grid.Column>
                                            </Grid.Row>
                                        </Grid>
                                    </Card.Description>
                                </Card.Content>
                            </Card>)
                    })}
                </Card.Group>
                {this.state.showRemove && <MessageBoxYesNo title="Remove Payroll"
                    caption={this.state.captionMessage} onAction={this.OnRemove.bind(this)} onClose={() => { this.setState({ showRemove: false }) }} action="Remove" />
                }
                {this.state.showMessageOnly && <MessageBoxOkOnly title="Payroll" caption="Successfully Deleted." onClick={() => this.setState({ showMessageOnly: false })}></MessageBoxOkOnly>}
            </div>
        )
    }
}

export default PayrollDetailControl
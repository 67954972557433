import React, { Component } from 'react';
import { Button, Input, Modal} from 'semantic-ui-react';
import 'semantic-ui-css/semantic.min.css';
import ErrorMessage from '../../../Commons/ErrorMessage';
import { ajaxPost } from '../../../ajax';
import { isTaskAvailable } from '../../../utils';
import SelectEmployees from '../../../Commons/SelectEmployees';
import MessageBoxYesNo from '../../../Commons/MessageBoxYesNo';
import Select from 'react-select';
import { empty_id } from '../../../utils';
import OffCyclePayTypeForm from './OffCyclePayTypeForm';
var { customStyles, checkInt32 } = require('../../../utils');


const someMarginNoPadding = { "margin": "0px 0px 10px 0px", "padding": "0px" }
const noMarginNoPadding = { "margin": "0px", "padding": "0px" }

var delayTimer = null;
const errorStyles = {
    control: (base, state) => ({
        ...base,
        color: "#DF4141",
        borderColor: "#DF4141",
        minHeight: 36,
        maxHeight: 95,
        overflowY: 'auto'
    })
}
const monthList = [
    { label: "January", value: 1 },
    { label: "February", value: 2 },
    { label: "March", value: 3 },
    { label: "April", value: 4 },
    { label: "May", value: 5 },
    { label: "June", value: 6 },
    { label: "July", value: 7 },
    { label: "August", value: 8 },
    { label: "September", value: 9 },
    { label: "October", value: 10 },
    { label: "November", value: 11 },
    { label: "December", value: 12 },
]

const TransactionTypes = [
    { label: "Taxable Income", value: "TAXABLE_INCOME" },
    { label: "Non-Taxable Income", value: "NON_TAXABLE_INCOME" },
    { label: "Taxable Deduction", value: "TAXABLE_DEDUCTION" },
    { label: "Non-Taxable Deduction", value: "NON_TAXABLE_DEDUCTION" },
]

function selectionList(empList, fields, value) {
    var list = [];
    if (empList != null) {
        empList.map(x => {
            return (
                list.push({ label: fields.map(z => { return (x[z] + " ") }), value: x[value] }))
        })
    }
    return list;
}

function populateYear() {
    var startYear = 1990;
    var tempMonthList = [];
    for (var i = startYear; i <= 2999; i++) {
        tempMonthList.push({ label: i, value: i });
    }
    return tempMonthList;
}

class OffCyclePayForm extends Component {

    constructor(props) {
        super(props);
        this.state = {
            Model: this.getDefaultValues(),
            selectedOffCycleType: null,
            lstYearSelection: [],
            showOffCycleTypeForm: false
        }
        this.modalRef = React.createRef();
    }

    getDefaultValues = () => {
        return {
            _id: empty_id,
            selectedEmployees: [],
            OffCyclePayTypeID: "",
            TransactionType: "",
            Year: new Date().getFullYear(),
            Month: new Date().getMonth() + 1,
            CutOffNumber: 1,
            Amount: 0,
            Remarks: "",
            // RecordDate: ""
        }
    }

    setContent = (data) => {
        let onEdit = data !== null && data.EmployeeID.length > 0;
        if (onEdit) {
            data.selectedEmployees = [data.EmployeeID];
        }
        this.setState({
            Model: data === null || data === undefined ? this.getDefaultValues() : data,
            employeeSelectionEnabled: !onEdit,
            displayedName: onEdit ? data.Employee : "",
            lstYearSelection: populateYear()
        }, () => {
            this.loadInitialData(data);
        });
    }

    getContent = () => {
        var model = this.state.Model;
        return {
            _id: model._id,
            employeeIds: model.selectedEmployees,
            OffCyclePayTypeID: model.OffCyclePayTypeID,
            TransactionType: model.TransactionType,
            Year: model.Year,
            Month: model.Month,
            CutOffNumber: model.CutOffNumber,
            Amount: model.Amount,
            Remarks: model.Remarks,
            // RecordDate: model.RecordDate
        }
    }

    handleChange = name => event => {
        var current = this.state.Model;
        current[name] = event.target.value;
        this.setState({ Model: current });
    }

    handleEmpSelectChange = (name, value) => {
        var current = this.state.Model;
        current[name] = value;
        this.setState({ Model: current });
    }

    handleSelectChange = (name, evt) => {
        var current = this.state.Model;
        if (!(evt === undefined || evt === null)) {
            current[name] = evt.value;
        }
        else {
            current[name] = null;
        }
        this.setState({ Model: current });
    }

    handleOffCyclePayChange = value => {
        var { Model } = this.state;
        if (value === null)
            Model.OffCyclePayTypeID = "";
        else
            Model.OffCyclePayTypeID = value.value;
        this.setState({ selectedOffCycleType: value, Model })
    }

    loadInitialData = (data) => {
        var self = this;
        this.setState({ isLoading: true });
        if (data !== null) {
            var parameter = {
                queryString: data.OffCyclePayTypeID,
                includeDeleted: false,
                startIndex: 0,
                itemCount: 15
            };
            var searchParameter = {
                data: parameter,
                url: "api/OffCyclePayType/search",
                onError: (error) => {
                    //self.setState({ leaveTypeData: [] });
                },
                onSuccess: (retData, sender) => {
                    if (retData.content.length === 0) {
                        let current = self.state.Model;
                        current["OffCyclePayTypeID"] = "";
                        self.setState({ Model: current });
                        return;
                    }
                    var newOptions = retData.content.map((item) => {
                        return {
                            label: item.Name,
                            key: item._id,
                            text: item.Name,
                            value: item._id,
                        }
                    });

                    var selectedOffCycleType = newOptions.find(x => x.value === data.OffCyclePayTypeID);
                    self.setState({ offCyclePayTypeList: newOptions, selectedOffCycleType });
                },
                finally: () => {
                    self.setState({ isLoading: false });
                }
            };
            ajaxPost(searchParameter);
        }
    }

    onOffCyclePayTypeKeyPress = (text) => {
        this.setState({ isLoading: true });
        var self = this;
        clearTimeout(delayTimer);

        delayTimer = setTimeout(() => {
            var parameter = {
                queryString: text,
                includeDeleted: false,
                startIndex: 0,
                itemCount: 15
            };
            var searchParameter = {
                data: parameter,
                url: "api/OffCyclePayType/search",
                onError: (error) => {
                    //self.setState({ leaveTypeData: [] });
                },
                onSuccess: (data, sender) => {
                    if (data.content.length === 0) {
                        let current = self.state.Model;
                        current["OffCyclePayTypeID"] = "";
                        self.setState({ Model: current });
                        return;
                    }
                    var newOptions = data.content.map((item) => {
                        return {
                            label: item.Name,
                            key: item._id,
                            text: item.Name,
                            value: item._id,
                        }
                    });

                    self.setState({ offCyclePayTypeList: newOptions });
                },
                finally: () => {
                    self.setState({ isLoading: false });
                }
            };
            ajaxPost(searchParameter);
        }, 200);
    }

    onCreateNewItemClick = () => {
        this.setState({ showAskCloseDialog: true });
    }
    onCreateNewItemTypeClick = () => {
        this.setState({ showOffCycleTypeForm : true });
    }
    isNullOrEmpty(val) {
        if (val === null || val === undefined || val === "" || val.length === 0) return true;
        else return false;
    }
    validate = (data) => {
        var errTitle = "", errCaption = ""; 
        if (this.isNullOrEmpty(data.Name)) {
            errCaption = "'Name' field is required";
            errTitle = "Invalid";
        }
        else {
            this.setState({ showRequiredField: false });
            return true;
        }
        this.setState({ showRequiredField: true, errTitle, errCaption });
        return false;
    };

    saveFromModal = (data) => {
        let self = this;
        let isValid = false;

        isValid = this.validate(data);
        if (!isValid) {
            return;
        }

        this.setState({ isSaving: true }); 

        var parameter = {
            data: {
                model: JSON.stringify(data)
            },
            url: "api/OffCyclePayType/save",
            onSuccess: (data, sender) => {
                if (data.hasOwnProperty("errors") && data.errors.length > 0) {
                    isValid = false;
                    self.setState({
                        showRequiredField: true,
                        errTitle: "Invalid",
                        errCaption: data.errors.map(err => { return (<p>{err}</p>) })
                    }, () => {
                        // self.messageBoxOkOnlyOpen(true, "Failed", "Data not successfully saved");
                    });
                    return;
                }
                isValid = true;


                self.setState({ 
                    showOffCycleTypeForm: false
                },
                    () => { 
                        // self.messageBoxOkOnlyOpen(true, "Success", "Data successfully saved");
                    }
                );
            },
            onError: (error) => {
                isValid = false;
                var errorMessage = "An error has occured";
                if (error) {
                    errorMessage += ":\r\n" + JSON.stringify(error);
                }
                this.setState({ showRequiredField: true, errTitle: "Error", errCaption: errorMessage });
            },
            finally: () => {
                this.setState({ isSaving: false }); 
            }
        }
        ajaxPost(parameter);
    }
    render() {
        let model = this.state.Model;
        return (
            <Modal size="tiny" open={this.props.open}>
                <Modal.Header>
                    {this.state.Model._id === "" || this.state.Model._id === empty_id ? "Create" : "Edit"} OffCycle Pay
                </Modal.Header>
                <Modal.Content>
                    {
                        this.props.showRequiredField &&
                        <div className="ui one column grid" style={noMarginNoPadding}>
                            <div className="column" style={someMarginNoPadding}>
                                <ErrorMessage
                                    title={this.props.errTitle}
                                    caption={this.props.errCaption}
                                    open={this.props.showRequiredField}
                                />
                            </div>
                        </div>
                    }
                    <div className="ui one column grid" style={noMarginNoPadding}>
                        { 
                                <div className="column" style={someMarginNoPadding}>
                                    <div>Employees</div>
                                    <SelectEmployees
                                        errorStyle={this.props.showRequiredField && this.state.Model.selectedEmployees.length == 0}
                                        Ids={model.selectedEmployees}
                                        update={(Ids) => { this.handleEmpSelectChange("selectedEmployees", Ids); }}
                                        width="100%"
                                        disabled={this.props.isBusy || !this.state.employeeSelectionEnabled}
                                    />
                                    {
                                        isTaskAvailable("EMPLOYEE_ADD") &&
                                        <a as='a' href='#' onClick={this.onCreateNewItemClick.bind(this)}>Create New</a>
                                    }
                                </div> 
                        }
                        {/* { !this.state.employeeSelectionEnabled &&
                             <div className="column" style={someMarginNoPadding}>
                             <div>Employee</div>
                             <Header size='small' style={{ "margin": "0" }}>{this.state.displayedName}</Header>
                         </div>
                        } */}
                    </div>
                    <div className="ui one column grid" style={noMarginNoPadding}>
                        <div className="column" style={someMarginNoPadding}>
                            <div>Type</div>
                            <Select
                                styles={this.props.showRequiredField && !this.state.Model.OffCyclePayTypeID ? errorStyles : customStyles}
                                value={this.state.selectedOffCycleType}
                                options={this.state.offCyclePayTypeList}
                                onChange={this.handleOffCyclePayChange.bind(this)}
                                error={this.props.showRequiredField && !this.state.Model.OffCyclePayTypeID}
                                onInputChange={this.onOffCyclePayTypeKeyPress.bind(this)}
                                isClearable
                            />
                             {
                                        isTaskAvailable("OFFCYCLE_PAY_TYPE_CREATE") &&
                                        <a as='a' href='#' onClick={this.onCreateNewItemTypeClick.bind(this)}>Create New</a>
                            }
                        </div>
                    </div>
                    <div className="ui one column grid" style={noMarginNoPadding}>
                        <div className="column" style={someMarginNoPadding}>
                            <div>Transaction Type</div>
                            <Select
                                value={TransactionTypes.find(x => x.value === model.TransactionType)}
                                options={TransactionTypes}
                                styles={this.props.showRequiredField && !this.state.Model.TransactionType ? errorStyles : customStyles}
                                error={this.props.showRequiredField && !this.state.Model.TransactionType}
                                onChange={this.handleSelectChange.bind(this, "TransactionType")}
                            />
                        </div>
                    </div>
                    <div className="ui two column grid" style={noMarginNoPadding}>
                        <div className="column" style={someMarginNoPadding}>
                            <div>Month</div>
                            <Select
                                styles={this.props.showRequiredField && !this.state.Model.Month ? errorStyles : customStyles}
                                placeholder="Month"
                                value={selectionList(monthList, ["label"], "value").find(x => x.value === model.Month)}
                                options={selectionList(monthList, ["label"], "value")}
                                error={this.props.showRequiredField && !this.state.Model.Month}
                                onChange={this.handleSelectChange.bind(this, "Month")}
                            />
                        </div>
                        <div className="column" style={someMarginNoPadding}>
                            <div>Year</div>
                            <Select
                                styles={this.props.showRequiredField && !this.state.Model.Year ? errorStyles : customStyles}
                                placeholder="Year"
                                value={this.state.lstYearSelection.find(x => x.value === model.Year)}
                                options={this.state.lstYearSelection}
                                error={this.props.showRequiredField && !this.state.Model.Year}
                                onChange={this.handleSelectChange.bind(this, "Year")}
                            />
                        </div>
                    </div>
                    <div className="ui one column grid" style={noMarginNoPadding}>
                        <div className="column" style={someMarginNoPadding}>
                            <div>CutOff Number</div>
                            <Input
                                placeholder='CutOff Number'
                                value={model.CutOffNumber}
                                style={{ 'width': '250px' }}
                                error={this.props.showRequiredField && !this.state.Model.CutOffNumber}
                                onChange={this.handleChange("CutOffNumber").bind(this)}
                            // onFocus={() => { this.setState({ isAmountFocus: true }) }} 
                            />
                        </div>
                    </div>
                    <div className="ui one column grid" style={noMarginNoPadding}>
                        <div className="column" style={someMarginNoPadding}>
                            <div>Amount</div>
                            <Input
                                placeholder='Amount'
                                value={model.Amount}
                                style={{ 'width': '250px' }}
                                error={this.props.showRequiredField && !checkInt32(this.state.Model.Amount).Result}
                                onChange={this.handleChange("Amount").bind(this)}
                                onFocus={() => { this.setState({ isAmountFocus: true }) }}
                            />
                        </div>
                    </div>
                    <div className="ui one column grid" style={noMarginNoPadding}>
                        <div className="column" style={someMarginNoPadding}>
                            <div>Remarks</div>
                            <Input
                                placeholder='Remarks'
                                value={model.Remarks}
                                style={{ 'width': '250px' }}
                                error={this.props.showRequiredField && !this.state.Model.Remarks}
                                onChange={this.handleChange("Remarks").bind(this)}
                                maxLength={30} 
                            // onFocus={() => { this.setState({ isAmountFocus: true }) }} 
                            />
                            <label style={{marginLeft:'5px'}}><i>{model.Remarks.length} / 30 Characters</i></label>
                        </div>
                    </div>

                </Modal.Content>
                <Modal.Actions>
                    <Button basic icon='save' content="Submit" disabled={this.props.isBusy} loading={this.props.isBusy} onClick={this.props.onSave}></Button>
                    <Button basic icon='cancel' content="Cancel" disabled={this.props.isBusy} onClick={this.props.onCancel}></Button>
                </Modal.Actions>
                {
                    this.state.showAskCloseDialog &&
                    <MessageBoxYesNo title="Create New Employee"
                        action="Yes"
                        onClose={() => { this.setState({ showAskCloseDialog: false }) }}
                        onAction={() => { this.props.onCreateEmpClick() }}
                        caption="All unsaved changes will be lost. Do you wish to continue?"
                    />
                }
                {
                    this.state.showOffCycleTypeForm &&
                    <OffCyclePayTypeForm open={this.state.showOffCycleTypeForm}
                        ref={this.modalRef}  errTitle={"Error"}
                        errCaption={this.state.errCaption}
                        showRequiredField={this.state.showRequiredField}
                        onCancel={() => { this.setState({ showOffCycleTypeForm: false }) }}
                        onSave={() => {
                            this.saveFromModal(
                                this.modalRef.current.getContent()
                            );
                        }}/>
                }
            </Modal>
        );
    }
}
export default OffCyclePayForm
import React, { Component } from 'react';
import { ajaxPost } from './ajax';
import {Input, Button, Icon, Modal} from 'semantic-ui-react';

const styles = {
    center: {
        paddingTop: 'auto',
        paddingBottom: 'auto',
        marginTop: 'auto',
        marginBotton: 'auto',
    },
}

class ChangePassword extends Component {

    constructor(props) {
        super(props);

        this.state =
            {
                password: "",
                newPassword: "",
                confirmPassword: "",
                errorMessage: "",
                saving: false,
                open: true,
                showCurrentPassword: false,
                showNewPassword: false,
                showConfirmPassword: false,
            }
    }

    handleChange = (id, e, input) => {
        this.setState({ [id]: input.value });
    }

    handleClick = () => {
        var _this2 = this;
        const capsCharacter = "QWERTYUIOPASDFGHJKLZXCVBNM";
        const regCharacter = "qwertyuiopasdfghjklzxcvbnm";
        //const spclCharacter = "!@#$%^&*()_+-={}[]:;\"\'\\<>,./?|`~";
        const numeric = "1234567890";

        this.setState({ errorMessage: "" });
        if (this.state.newPassword !== this.state.confirmPassword) {
            this.setState({ errorMessage: "Entered passwords did not match" });
            return;
        }

        if (this.state.newPassword.length < 8) {
            this.setState({ errorMessage: "Password must contains atleast 8 characters" });
            return;
        }

        var hasCaps = false;
        var hasRegChar = false;
        //var hasSpclChar = false;
        var hasNumeric = false;

        for (let index = 0; index < this.state.newPassword.length; index++) {
            const element = this.state.newPassword.charAt(index);

            if (capsCharacter.search(element) !== -1)
                hasCaps = true;
            if (regCharacter.search(element) !== -1)
                hasRegChar = true;
            // if(spclCharacter.search(element) !== -1)
            //     hasSpclChar = true;
            if (numeric.search(element) !== -1)
                hasNumeric = true;
        }

        if (!hasCaps || !hasNumeric || !hasRegChar) {
            this.setState({ errorMessage: "The password must contains small and capital letter and number" });
            return;
        }

        ajaxPost(
            {
                url: "api/Employee/changePassword",
                data:
                {
                    userId: global.userId,
                    oldPassword: this.state.password,
                    newPassword: this.state.newPassword,
                },
                onSuccess: data => {
                    if (data.result === false) {
                        this.setState({ errorMessage: data.errMessage });
                    }
                    else {
                        window.location = _this2.props.redirect == null ? "/" : _this2.props.redirect;
                    }
                    this.setState({ saving: false });
                },
                finally: () => { }
            }
        )

    }

    render = () => {
        return(
            <div className='w3-content'>
                <div className='w3-row' style={{width:'100%', textAlign:'center', marginTop:'20px'}}>
                    <h1>
                        Change Password
                    </h1>
                </div>

                <div style={{width:'100%', margin:'10px'}}>

                    <div className='w3-row' style={{marginTop:'30px', padding:'auto'}}>
                        <div className='w3-col m4 l4'>
                            <div className="ChangePasswordFormGroupLabel">Current Password</div>
                        </div>
                        <div className='w3-col m8 l8'>
                            <Input type={this.state.showCurrentPassword?'text':'password'} value={this.state.password} style={{ minWidth: '50px', maxWidth: '350px', width: '100%' }}
                                onChange={this.handleChange.bind(this, "password")}
                                icon={<Icon name={'eye'} onMouseUp={()=>{
                                    this.setState({showCurrentPassword: false})
                                }} 
                                onMouseDown={()=>{
                                    this.setState({showCurrentPassword: true})
                                }}
                                link />} />
                        </div>
                    </div>

                    <div className='w3-row' style={{marginTop:'10px', padding:'auto'}}>
                        <div className='w3-col m4 l4'>
                            <div className="ChangePasswordFormGroupLabel" style={styles.center}>New Password</div>
                        </div>
                        <div className='w3-col m8 l8'>
                            <Input type={this.state.showNewPassword?'text':'password'} value={this.state.newPassword} style={{ minWidth: '50px', maxWidth: '350px', width: '100%' }}
                                onChange={this.handleChange.bind(this, "newPassword")}
                                icon={<Icon name={'eye'} onMouseUp={()=>{
                                    this.setState({showNewPassword: false})
                                }} 
                                onMouseDown={()=>{
                                    this.setState({showNewPassword: true})
                                }} 
                                link/>} />
                        </div>
                    </div>
                            
                    <div className='w3-row' style={{marginTop:'10px'}}>
                        <div className='w3-col m4 l4'>
                            <div className="ChangePasswordFormGroupLabel" style={styles.center}>Confirm Password</div>
                        </div>
                        <div className='w3-col m8 l8'>
                            <Input type={this.state.showConfirmPassword?'text':'password'} value={this.state.confirmPassword} style={{ minWidth: '50px', maxWidth: '350px', width: '100%' }}
                                onChange={this.handleChange.bind(this, "confirmPassword")} 
                                icon={<Icon name={'eye'} onMouseUp={()=>{
                                    this.setState({showConfirmPassword: false})
                                }} 
                                onMouseDown={()=>{
                                    this.setState({showConfirmPassword: true})
                                }} 
                                link/>}
                                />
                        </div>
                    </div>
                    
                    <div className='w3-row' style={{marginTop:'10px'}}>
                        <div style={{ color: "red" }}>{this.state.errorMessage}</div>
                    </div>
                    
                    <div className='w3-row' style={{marginTop:'10px'}}>
                        <div style={{ fontWeight: "lighter", fontSize: "12px", verticalAlign: "bottom", padding: "0,0,0,0" }}> Note: </div>
                        <div style={{ fontWeight: "lighter", fontSize: "12px", verticalAlign: "bottom", padding: "0,0,0,0" }}> &nbsp; -Password must contain atleast 1 of the ff.(lowercase, uppercase, number)</div>
                        <div style={{ fontWeight: "lighter", fontSize: "12px", verticalAlign: "bottom", padding: "0,0,0,0" }}> &nbsp; -Password have atleast 8 charactes</div>
                    </div>
                    <div className='w3-row' style={{marginTop:'20px'}}>
                        <Button floated='right' primary loading={this.state.saving} onClick={this.handleClick.bind(this)} >
                            Proceed <Icon name='chevron right' />
                        </Button>
                    </div>
                </div>
            </div>
        )
    }

}

export default ChangePassword;
import React, { Component } from 'react';
import { Modal, Grid, Input, Button } from 'semantic-ui-react';
import ErrorMessage from '../../Commons/ErrorMessage';
import { empty_id } from '../../utils'

const someMarginNoPadding = { "margin": "0px 0px 10px 0px", "padding": "0px" }
const noMarginNoPadding = { "margin": "0px", "padding": "0px" }

class CommonTypeEditForm extends Component {

    constructor(props) {
        super(props);
        this.state = {
            Model: this.getDefaultValues(),
        }
        this.setState({ selectedPayrollAdustmentType: null });
    }

    getDefaultValues = () => {
        return {
            _id: empty_id,
            Name: "",
            Description: "",
        }
    }

    setContent = (data) => {
        this.setState({
            Model: data === null || data === undefined ? this.getDefaultValues() : data
        });
    }

    getContent = () => {
        return {
            _id: this.state.Model._id,
            Name: this.state.Model.Name,
            Description: this.state.Model.Description,
        };
    }

    handleChange = name => event => {
        var current = this.state.Model;
        current[name] = event.target.value;
        this.setState({ Model: current });
    };

    handleComboChange = (name, val) => {
        var current = this.state.Model;
        current[val.name] = val.value;
        this.setState({ Model: current });
    }

    render() {
        return (
            <Modal size="tiny" open={this.props.open}>
                <Modal.Header>
                    {this.props.Title}
                </Modal.Header>
                <Modal.Content>
                    {
                        this.props.showRequiredField &&
                        <div className="ui one column grid" style={noMarginNoPadding}>
                            <div className="column" style={someMarginNoPadding}>
                                <ErrorMessage
                                    title={this.props.errTitle}
                                    caption={this.props.errCaption}
                                    open={this.props.showRequiredField}
                                />
                            </div>
                        </div>
                    }
                    <div className="ui one column grid" style={noMarginNoPadding}>
                        <div className="column" style={someMarginNoPadding}>
                            <div>Name</div>
                            <Input
                                fluid
                                value={this.state.Model.Name}
                                disabled={this.props.isBusy}
                                error={this.props.showRequiredField && !this.state.Model.Name}
                                onChange={this.handleChange("Name").bind(this)}
                            />
                        </div>
                    </div>
                    <div className="ui one column grid" style={noMarginNoPadding}>
                        <div className="column" style={someMarginNoPadding}>
                            <div>Description</div>
                            <Input
                                fluid
                                value={this.state.Model.Description}
                                disabled={this.props.isBusy}
                                // error={this.props.showRequiredField && !this.state.Model.Description} 
                                onChange={this.handleChange("Description").bind(this)}
                            />
                        </div>
                    </div>
                </Modal.Content>
                <Modal.Actions>
                    <Button basic icon='save' content="Submit" disabled={this.props.isBusy} loading={this.props.isBusy} onClick={this.props.onSave}></Button>
                    <Button basic icon='cancel' content="Cancel" disabled={this.props.isBusy} onClick={this.props.onCancel}></Button>
                </Modal.Actions>
            </Modal>
        )
    }
}

export default CommonTypeEditForm;
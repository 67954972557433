import React, { Component } from "react";
import { Table, Input, Button, Label, Message, Image } from "semantic-ui-react";
import { ajaxPost } from "../ajax";
import { empty_id, isTaskAvailable } from "../utils"; 

var moment = require("moment");

class Licensing extends Component {
  state = {
    compCode: "",
    productKey: "",
    registeredEmpCount: 10,
    errorMessage: "",
    isValid: false,
    successActivate: "",
    messageColor: "",
    softwareStatus: "FREE",
    dateAcquired: null,
  };
  getInstallationCode = () => {
    ajaxPost({
      url: "api/License/getCompCode",
      data: {},
      onSuccess: (data) => {
        this.setState({ compCode: data });
      },
      finally: () => {
        //this.doSearch("");
      },
    });
  };

  getProdKeyInfo = () => {
    ajaxPost({
      url: "api/License/getProductKeyInfo",
      data: {},
      onSuccess: (data) => {
        if (data != null) {
          this.setState({ dateAcquired: data["DateAcquired"] });
          this.setState({ registeredEmpCount: data["EmpCount"] });
          this.setState({ productKey: data["ProductKey"] });
        } else {
          this.setState({ registeredEmpCount: 10, productKey: "" }); 
        }
        this.setState({ softwareStatus: data["SoftwareStatus"] });
      },
      finally: () => {},
    });
  };

  register = () => {
    // validate
    if(this.state.productKey === undefined || this.state.productKey === ""){ 
      this.setState({ messageColor: "red",errorMessage: "Invalid ZKTeco Payroll Product Key",successActivate:  "no"  });
      return;
    }

    ajaxPost({
      url: "api/License/registerProductKey",
      data: {
        ProductKey: this.state.productKey,
        PrevEmpCount: this.state.registeredEmpCount,
      },
      onSuccess: (data) => {
        let empCount = data["EmpCount"];
        let dateAcquired = data["DateAcquired"];
        let softwareStatus = data["SoftwareStatus"];
        let isActivated = softwareStatus === "ACTIVATED";

        this.setState({ successActivate: isActivated ? "yes" : "no" });

        if (!isActivated) {
          this.setState({ messageColor: "red" });
          this.setState({ errorMessage: "Invalid ZKTeco Payroll Product Key" });
        } else {
          this.setState({ messageColor: "green" });
          this.setState({
            errorMessage: "Congratulations! Your ZKTeco Payroll has been successfully activated.",
          });
          this.setState({ registeredEmpCount: empCount });
          this.setState({ dateAcquired: dateAcquired });
        }

        this.setState({ softwareStatus: data["SoftwareStatus"] });
      },
      finally: () => {
        //this.doSearch("");
      },
    });
  };

  componentWillUnmount = () => {
    this.props.unmountCallback(this.state, "LicensingModule");
  };

  componentWillMount = () => {
    if (this.props.prevState != null) this.state = this.props.prevState;
    this.getInstallationCode();
    this.getProdKeyInfo();
  };

  render() {
    return (
      <div>
        <div style={{ color: "#606060", marginTop: "1%" }}>
          <h2>Software License</h2>
        </div>
        <br />
        <br />
        <Table celled>
          <Table.Row>
            <Table.Cell>Company Code</Table.Cell>
            <Table.Cell>{this.state.compCode}</Table.Cell>
          </Table.Row>
          <Table.Row>
            <Table.Cell>Status</Table.Cell>
            <Table.Cell>{this.state.softwareStatus}</Table.Cell>
          </Table.Row>
          <Table.Row>
            <Table.Cell>Register Employee Count</Table.Cell>
            <Table.Cell>{this.state.registeredEmpCount}</Table.Cell>
          </Table.Row>
          <Table.Row>
            <Table.Cell>Date Generated</Table.Cell>
            <Table.Cell>
              {this.state.dateAcquired != null &&
                moment(this.state.dateAcquired).format("L")}
            </Table.Cell>
          </Table.Row>
          <Table.Row>
            <Table.Cell>Product Key</Table.Cell>
            <Table.Cell>
              <Input
                fluid
                onChange={(evt) => {
                  this.setState({ productKey: evt.target.value });
                }}
                disabled={!isTaskAvailable("ACTIVATE_LICENSE") && global.userId != empty_id}
                placeholder={this.state.productKey}
              ></Input>
            </Table.Cell>
          </Table.Row>
          <Table.Row>
            <Table.Cell></Table.Cell>
            <Table.Cell>
              <Button primary onClick={this.register.bind(this)} disabled={!isTaskAvailable("ACTIVATE_LICENSE") && global.userId != empty_id}>
               {this.state.productKey != null? "Upgrade" : "Activate"}  
              </Button>
            </Table.Cell>
          </Table.Row>
        </Table>
        {this.state.successActivate !== "" && (
          <Message color={this.state.messageColor}>
            {this.state.errorMessage}
          </Message>
        )}
      </div>
    );
  }
}

Licensing.getViewID = function () {
  return "LicensingModule";
};
Licensing.getCaption = function () {
  return "Software License";
};

Licensing.getIcon = () => {
  return (<Image style={{ "height": "24px", "width": "24px" }} src='Icons/Categories.png' avatar />);
}

Licensing.getIconSrc = (size) => {
  return "Icons/Categories.png";
};

Licensing.getViewAccessKey = () => {
  return isTaskAvailable("VIEW_LICENSE") || global.userId == empty_id;
};

export default Licensing;

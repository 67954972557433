import React, {Component } from 'react';
import 'semantic-ui-css/semantic.min.css';
import {Loader,Button} from 'semantic-ui-react';
// import "react-table/react-table.css";
import {ajaxPost} from '../../ajax';
import DataGrid from 'react-data-grid';
import {TimeDisplayFormat } from '../../utils';
import ApprovalForm from './ApprovalForm';


class ActionDTR extends Component {
    state={
        Parameters:{
            CutoffId:"-1",
            needle:"",
            startingIndex:0,
            itemCount:10,  
            totalPages:1, 
            advanceFilter: {
                companyIds: [],
                divisionIds:[],
                departmentIds: [],
                locationIds: [],
                positionIds: []
            },      
        },
        initialLoad:true,
        Loading:false,
        ActivePage:1,
        PageSize:100,
        ActionableDTRs:[],
        transaction:null,
        modalOpen:false,
        Restrict_Column:false,
        gridHeight: window.innerHeight - 320,
    }
    ActionFormatter = ({value,row}) =>{ 
    
        return(
         <div>
             {value.Action == "APPROVED" &&
             <Button style={{"margin": "5px","padding":"5px"}} onClick={() =>{
               // modal form for approval
               var selected = this.state.ActionableDTRs.filter(x => x.Date == value.Date && x.EmployeeNumber == value.EmployeeNumber);

               this.setState({transaction:value,modalOpen:true});
                }} >{value.Action}</Button>
             }
        </div>)
    };
    updateList = () =>{
        var selected = this.state.transaction; 
        var actionableDTRs =  this.state.ActionableDTRs.filter(x => x.Action.index != selected.index);
        this.setState({ActionableDTRs:actionableDTRs});
    }
    
    constructor(props){
        super(props);
        this.state.Parameters = this.props.Parameters;    
        this.state.initialLoad = this.props.initialLoad;    
        this.state.ActionableDTRs = this.props.ActionableDTRs;  
        this._columns = [
            { key: 'EmployeeNumber', name: 'Employee Number', width: 150,  locked: true, resizable: true },
			{ key: 'FullName', name: 'Employee', width: 200, resizable: true, locked: true, },
			{ key: 'Date', name: 'Date', width: 150, locked: true, resizable: true},
			{ key: 'WorkSchedule', name: 'Work Schedule', width: 180,locked : true, resizable:true},
			{ key: 'TimeInOut', name: 'Time In/Out', width: 180,locked : true, resizable: true},
            { key: 'OTR', name: 'Pre-Compute OT', width: 150, resizable: true},
            { key: 'Remarks', name: 'Remarks', width: 200, resizable: true},
            { key: 'Action', name: '', width: 150,formatter:this.ActionFormatter},           
        ];
    }
    componentWillMount(){
        window.addEventListener('resize', this.handleResize);
        this.handleResize = this.handleResize.bind(this);
        if(this.state.initialLoad===true)
        {
            var param = this.state.Parameters;
            this.onSearch(param);           
        }
    }
    componentWillUnmount(){
        this.props.updateState(this.state,"ActionableDTRs");      
        window.removeEventListener('resize', this.handleResize);  
    }
    componentDidMount(){
        this.handleResize();
      }
      handleResize = () => {
    
        let headerMargin = 271;
        let footerMargin = 35;
        let gridTop = (document.getElementById("table")) == null ? 50 : (document.getElementById("table")).offsetTop ;
        var gridHeight = window.innerHeight - (headerMargin + gridTop + footerMargin);
        this.setState({ 
            gridHeight, 
        });
    }
    rowGetter = (i) => {
		return this.state.ActionableDTRs[i];
    };
    /* method/function */
    onSearch =(parameter) =>{
        this.setState({Loading:true});
        this.LoadData(data =>{
            this.mapData(data.Content);
            var totalPages = data.Count / this.state.Parameters.itemCount;
            totalPages -= totalPages % 1;
            if (data.Count > totalPages * this.state.Parameters.itemCount) {
                totalPages++;
            }
            var parameters = this.state.Parameters;
            parameters.totalPages = totalPages;
            this.setState({
                Loading:false,
                initialLoad:false,
                Parameters :parameters
            });
            var state = this.state;
            this.props.updateState(state,"ActionableDTRs");
        },parameter);
    }
    mapData = (cutoffDetails) =>{
        var ActionableDTRViews = []
        // var rowCount = 0;
        cutoffDetails.forEach(dtr => {
            var actionableDTR = 
            {
                EmployeeNumber: dtr.EmployeeNumber,
                FullName: dtr.LastName + ", " + dtr.FirstName + " " + dtr.MiddleName,
                Date:dtr.FormattedDate,
                WorkSchedule: dtr.Schedule,
                TimeInOut:dtr.TimeEnties,
                OTR:TimeDisplayFormat(dtr.OTR),
                Remarks:dtr.Remarks,
                Action:dtr.ActionContent
            }
            ActionableDTRViews.push(actionableDTR);     
           
        });
        this.setState({ActionableDTRs:ActionableDTRViews});
    }


    // api
    LoadData =(callback,parameters) =>{    

        ajaxPost({
            url: 'api/Cutoff/LoadActionableDTR',
            data: {
            "Parameters": JSON.stringify(parameters),
        },
        onSuccess: data => {
            callback(data);
        },
        onError: data =>{
            this.setState({Loading:false});
        },
        finally: () => {  }
        })
    }
    render(){
            // handle
    
        return(
            <div id='table'> 
                <Loader active={this.state.Loading} />   
                <DataGrid
                    columns={this._columns}
                    rows={this.state.ActionableDTRs}
                    rowsCount={this.state.ActionableDTRs.length}
                    minHeight={this.state.gridHeight}
                />               
            {this.state.modalOpen &&
                <ApprovalForm title= "Approval Form" modalOpen={true} onClose={() =>{this.setState({modalOpen:false});}}
                onCallback={this.updateList}
                transaction={this.state.transaction}
                />
            }
            </div>
        );
    }
}
export default ActionDTR
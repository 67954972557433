import PdfMake from 'pdfmake/build/pdfmake';
import vfsFonts from 'pdfmake/build/vfs_fonts';
import moment from 'moment';

const _formatHeader = (headers) =>{
   var  result = [];
    headers.map(item => {
        result.push({text: item,bold: true,
            fontSize: 10,border: [false, false, false, true]});
    });
    return result;
};
const _formatRow =(data) =>{
    // var withBorder =false;
    var result =[];
    for(let i = 0; i < data.length;i++ ){
        var showBorder = false;
        let values = Object.values(data[i]);
        var nIndex = i+1;
        let nextValues =null;
        if(nIndex !== data.length)
            nextValues = Object.values(data[nIndex]);
        if(nextValues !=null){
            if(nextValues[0]==="" ||nextValues[0] == null){
                showBorder =false;
            }else{
                if(values[0] == null){
                    showBorder =true;
                }else{
                    if(values[0] !== nextValues[0])
                    showBorder = true;
                    else
                    showBorder =false;
                }
            }
        }
        else{
            showBorder =true;
        }
        var items = [];
        if(showBorder){

            values.map(x => {
                items.push({text:x, fontSize: 9,border: [false, false, false, true]});           
            });
        }else{
            values.map(x => {
                items.push({text:x, fontSize: 9,border: [false, false, false, false]});           
            });
        }

        result.push(items);
    }
    return result;
    // return data.map(item =>{
    //     var values = Object.values(item);   
    //     var items =[];
    //     values.map(x =>{
    //         items.push({text:x, fontSize: 9,border: [false, false, false, true]});
    //     });  
    //     return  items;
    // });
};
const _formatWidth =(headers) =>{
    var width = [];
    headers.map(item => {
         if(item === "Employee")
            width.push('auto');
        else
            width.push('*');
    });
    return width;
}
export default (data) =>{
    const {vfs} = vfsFonts.pdfMake;
    PdfMake.vfs = vfs;
    const tableHeaderData = _formatHeader(data.Headers);
    const tableRowData = _formatRow(data.Data);
    const widthData = _formatWidth(data.Headers);
    const pdfDoc = {
        pageSize:'A4',
        pageOrientation:'Potrait',
        content:[
            {text: data.Title,fontSize: 11}, '\n',
            {text:'Start Date : ' + moment(data.DateRangeStart).format("MM/DD/YYYY"),fontSize: 9},'\n',
            {text:'End Date : ' + moment(data.DateRangeEnd).format("MM/DD/YYYY"),fontSize: 9},'\n',
            {table:{
                headerRows: 1,
                widths: widthData,
                body: [
                    tableHeaderData,
                    ...tableRowData,
                ]               
                
            },
            layout: {
                hLineWidth: function(i, node) {
                  return (i === 0 || i === node.table.body.length) ? 0.1 : 0.1;
                },
                vLineWidth: function(i, node) {
                  return (i === 0 || i === node.table.widths.length) ? 0.1 : 0.1;
                }
              }
        },
            
        ],
        styles:{
            tableHeader:{
                bold: true,
                fontSize: 13 ,border: [false, false, false, true]          
            }
        }
    };
    PdfMake.createPdf(pdfDoc).open();
}
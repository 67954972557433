import React, { Component } from 'react';
import { Button, Table, ButtonGroup, Modal, Input, Label, Tab } from 'semantic-ui-react';
import { ajaxPost } from '../../ajax';
import { handleNumericOnKeyDown, handleNumericOnKeyPress, isNullOrEmpty } from '../../utils';
import Select from 'react-select';
import PdfMake from 'pdfmake/build/pdfmake';
import vfsFonts from 'pdfmake/build/vfs_fonts';

const moment = require('moment');

var adjustmentModel = {
    seqNo: 0,
    prevMonth: '',
    datePaid: '',
    bankValidation: '',
    bankCode: '',
    taxPaid: '',
    taxDue: '',
    currYearAdjustment: '',
    yearEndAdjustment: ''
}

export default class Form1601C extends Component {

    constructor() {
        super();

        this.state = {
            isCompanyLoading: false,
            isGenerateLoading: false,
            isExportLoading: false,
            companyList: [],
            selectedCompany: null,
            month: moment().format('MM'),
            year: moment().format('YYYY'),

            generatedData: {
                GrossCompensation: 0.0,
                StatutoryMinimumWage: 0.0,
                OvertimePay: 0.0,
                ThirteenthMonthPay: 0.0,
                DeminimisBenefits: 0.0,
                DeminimisBenefitsT: 0.0,
                ThirteenthMonthPayT: 0.0,
                TaxableCompensation: 0.0,
                GovtContriAndUnionDues: 0.0,
                TaxReqWithheld: 0.0,
            },

            totalField: {
                netTaxableCompensation: 0.0,
                totalNonTaxableCompensation: 0.0,
                totalTaxableCompensation: 0.0,
                totalAdjustmentFromSectionA: 0.0,
                taxReqWithheldRemittance: 0.0,
                totalTaxRemittanceMade: 0.0,
                taxStillDue: 0.0,
                totalPenalties: 0.0,
                totalAmountStillDue: 0.0,
            },

            otherNonTaxCompName: '',
            otherNonTaxCompAmount: 0.0,
            taxRemittedReturnPreviouslyFiled: 0.0,
            otherRemittanceMadeName: '',
            otherRemittanceMadeAmount: 0.0,
            surcharge: 0.0,
            interest: 0.0,
            compromise: 0.0,

            cashBankDrawee: '',
            cashBankNumber: '',
            cashBankDate: '',
            cashBankAmount: 0.0,
            checkDrawee: '',
            checkNumber: '',
            checkDate: '',
            checkAmount: 0.0,
            taxDebitNumber: '',
            taxDebitDate: '',
            taxDebitAmount: 0.0,

            adjustmentOfTaxesList: [],
            sequenceNo: 0,
            selectedAdjustmentModel: null,
            isModalOpen: false
        }

        this.loadCompany('');
    }

    onTextChange = (field, e) => {
        this.setState({ [field]: e.target.value }, () => this.computeTotalFields());
    }

    roundOff(amount) {
        return parseFloat(amount.toFixed(2))
    }

    computeTotalFields() {
        var { totalField, generatedData, adjustmentOfTaxesList, otherNonTaxCompAmount } = this.state;
        var adjustmentTaxes = 0;

        adjustmentOfTaxesList.forEach(x => {
            if (x.currYearAdjustment !== '')
                adjustmentTaxes += parseFloat(x.currYearAdjustment);
        });

        var penaltySurcharge = this.state.surcharge === '' ? 0 : this.state.surcharge;
        var penaltyInterest = this.state.interest === '' ? 0 : this.state.interest;
        var penaltyCompromise = this.state.compromise === '' ? 0 : this.state.compromise;

        var taxRemittedReturnPreviouslyFiled = this.state.taxRemittedReturnPreviouslyFiled === '' ? 0 : this.state.taxRemittedReturnPreviouslyFiled;
        var otherRemittanceMade = this.state.otherRemittanceMadeAmount === '' ? 0 : this.state.otherRemittanceMadeAmount;
        totalField.totalNonTaxableCompensation = generatedData.StatutoryMinimumWage + generatedData.OvertimePay + generatedData.ThirteenthMonthPay + generatedData.DeminimisBenefits + generatedData.GovtContriAndUnionDues + parseFloat(otherNonTaxCompAmount);
        totalField.totalNonTaxableCompensation = this.roundOff(totalField.totalNonTaxableCompensation)
        totalField.totalTaxableCompensation = generatedData.GrossCompensation - totalField.totalNonTaxableCompensation;
        totalField.totalTaxableCompensation = this.roundOff(totalField.totalTaxableCompensation)
        totalField.netTaxableCompensation = totalField.totalTaxableCompensation - generatedData.TaxableCompensation
        totalField.netTaxableCompensation = this.roundOff(totalField.netTaxableCompensation)

        totalField.totalAdjustmentFromSectionA = adjustmentTaxes;
        totalField.totalAdjustmentFromSectionA = this.roundOff(totalField.totalAdjustmentFromSectionA)
        var adjustment = totalField.totalAdjustmentFromSectionA === '' ? 0 : totalField.totalAdjustmentFromSectionA;

        totalField.taxReqWithheldRemittance = parseFloat(adjustment) + parseFloat(generatedData.TaxReqWithheld);
        totalField.taxReqWithheldRemittance = this.roundOff(totalField.taxReqWithheldRemittance)
        totalField.totalTaxRemittanceMade = parseFloat(otherRemittanceMade) + taxRemittedReturnPreviouslyFiled;
        totalField.totalTaxRemittanceMade = this.roundOff(totalField.totalTaxRemittanceMade)
        totalField.taxStillDue = parseFloat(totalField.taxReqWithheldRemittance) - parseFloat(totalField.totalTaxRemittanceMade);
        totalField.taxStillDue = this.roundOff(totalField.taxStillDue)
        totalField.totalPenalties = parseFloat(penaltySurcharge) + parseFloat(penaltyInterest) + parseFloat(penaltyCompromise);
        totalField.totalPenalties = this.roundOff(totalField.totalPenalties)
        totalField.totalAmountStillDue = parseFloat(totalField.taxStillDue) + parseFloat(totalField.totalPenalties);
        totalField.totalAmountStillDue = this.roundOff(totalField.totalAmountStillDue)

        this.setState({ totalField });
    }

    loadCompany() {
        this.setState({ isCompanyLoading: true });
        ajaxPost({
            url: 'api/group/search',
            data: {
                Search: '',
                startingIndex: 0,
                itemCount: 999,
                Types: ['COMPANY']
            },
            onSuccess: (data) => {
                var list = data.content.map(x => { return ({ label: x.Name, value: x._id }) });
                this.setState({ companyList: list });
            },
            finally: () => {
                this.setState({ isCompanyLoading: false });
            }
        })
    }

    createTableRow(label, value, disable) {
        var curr = this.state[value];
        return (
            <tr>
                <td style={{ width: '75%' }}>
                    {label}
                </td>
                {disable === false && <td style={{ width: '25%' }}>
                    <Input style={{ border: '1px solid gray' }} disabled={disable} fluid
                        onChange={this.onTextChange.bind(this, value)} value={curr}
                        onKeyPress={(e) => handleNumericOnKeyPress(e)}
                        onKeyDown={(e) => handleNumericOnKeyDown(e)}
                    />
                </td>}
                {disable === true && <td style={{ width: '25%' }}>
                    <Input style={{ border: '1px solid gray' }} disabled={disable} fluid value={value} />
                </td>}
            </tr>
        )
    }

    createTableInputRow(label, value, isDate) {
        var curr = this.state[value];
        return (
            <tr>
                <td style={{ width: '75%' }}>
                    {label}
                </td>
                {isDate === false && <td style={{ width: '25%' }}>
                    <Input style={{ border: '1px solid gray' }} fluid
                        onChange={this.onTextChange.bind(this, value)} value={curr}
                    />
                </td>}
                {isDate === true && <td style={{ width: '25%' }}>
                    <Input
                        size='small'
                        value={curr}
                        fluid
                        type="date"
                        min="1900-01-01"
                        max="2999-12-31"
                        onChange={this.onTextChange.bind(this, value)}
                    />
                </td>}
            </tr>
        )
    }

    addAdjustment = () => {
        if (this.state.adjustmentOfTaxesList.length > 2) {
            alert('Limit is already reached. Max(3)')
            return;
        }

        var model = JSON.parse(JSON.stringify(adjustmentModel));
        model.seqNo = this.state.sequenceNo + 1;
        this.setState({
            selectedAdjustmentModel: model,
            isModalOpen: true,
            sequenceNo: this.state.sequenceNo + 1
        });
    }

    loadAdjustment = (model) => {
        this.setState({
            selectedAdjustmentModel: JSON.parse(JSON.stringify(model)),
            isModalOpen: true
        });
    }

    deleteAdjustment = (model) => {
        var { adjustmentOfTaxesList } = this.state;
        adjustmentOfTaxesList = adjustmentOfTaxesList.filter(x => x.seqNo !== model.seqNo);
        this.setState({ adjustmentOfTaxesList }, () => this.computeTotalFields());
    }

    handleOnAdd = () => {
        var { adjustmentOfTaxesList, selectedAdjustmentModel } = this.state;

        //validation of prev month
        if (selectedAdjustmentModel.prevMonth.includes('/')) {
            var splittedPrevMonth = selectedAdjustmentModel.prevMonth.split('/')
            if (splittedPrevMonth.length === 2) {
                if (splittedPrevMonth[0] === '' || parseInt(splittedPrevMonth[0]) > 12 || parseInt(splittedPrevMonth[0]) < 1 ||
                    splittedPrevMonth[1] === '' || parseInt(splittedPrevMonth[1]) > 9999 || parseInt(splittedPrevMonth[1]) < 1990) {
                    alert('Previous Month(s) value is not valid');
                    return;
                }
            } else {
                alert('Previous Month(s) value is not valid');
                return;
            }
        } else if (selectedAdjustmentModel.prevMonth !== '') {
            alert('Previous Month(s) value is not valid');
            return;
        }

        var index = adjustmentOfTaxesList.map(x => x.seqNo).indexOf(selectedAdjustmentModel.seqNo);
        if (index === -1) {
            adjustmentOfTaxesList.push(selectedAdjustmentModel);
        } else {
            adjustmentOfTaxesList[index] = selectedAdjustmentModel;
        }

        this.setState({
            adjustmentOfTaxesList,
            selectedAdjustmentModel: null,
            isModalOpen: false
        }, () => { this.computeTotalFields() });
    }

    handleOnCancel = () => {
        this.setState({
            selectedAdjustmentModel: null,
            isModalOpen: false
        })
    }

    handleChanged = (name, event) => {
        var { selectedAdjustmentModel } = this.state;
        selectedAdjustmentModel[name] = event.target.value;
        selectedAdjustmentModel.currYearAdjustment = (selectedAdjustmentModel.taxDue !== ''? selectedAdjustmentModel.taxDue: 0) - (selectedAdjustmentModel.taxPaid !== ''? selectedAdjustmentModel.taxPaid: 0)

        this.setState({ selectedAdjustmentModel });
    }

    prevMonthKeyPressed = (e) => {
        if (
            e.key.length === 1 && e.key !== '/' && isNaN(e.key) && !e.ctrlKey ||
            e.key === '/' && e.target.value.toString().indexOf('/') > -1 ||
            e.key === " "
        ) {
            e.preventDefault();
        }
    }

    onGenerateClick = () => {
        if (this.state.isGenerateLoading)
            return;

        this.setState({ isGenerateLoading: true });
        this.generateForm1601c((processId) =>
            ajaxPost({
                url: 'api/governmentForms/GetForm1601c',
                data: processId,
                onSuccess: (data) => {
                    this.setState({ generatedData: data.content, isGenerateLoading: false }, () => { this.computeTotalFields() });
                },
                finally: () => { }
            })
        )
    }

    generateForm1601c = (callback) => {
        if (this.state.selectedCompany === null) {
            this.setState({ isGenerateLoading: false });
            alert("Please select a company");
            return;
        }

        if (this.state.month === '' || this.state.month > 12 || this.state.month < 1) {
            this.setState({ isGenerateLoading: false });
            alert("Invalid value of month");
            return;
        }

        if (this.state.year === '' || this.state.year > 9999 || this.state.year < 1990) {
            this.setState({ isGenerateLoading: false });
            alert("Invalid value of year");
            return;
        }

        ajaxPost({
            url: 'api/governmentforms/GenerateForm1601c',
            data: {
                companyId: this.state.selectedCompany.value,
                month: this.state.month,
                year: this.state.year
            },
            onSuccess: (value) => {
                this.checkStatus(value, callback);
            },
            finally: () => { }
        })
    }

    checkStatus(processId, callback) {
        ajaxPost({
            data: { processId: processId },
            url: 'api/BackgroundProcess/GetStatus',
            onSuccess: data => {
                if (data.Status != "PROCESSING") {
                    callback(processId)
                }
                else {
                    this.checkStatus(processId, callback);
                }
            },
            finally: () => { }
        })
    }

    onExportPDFClick = () => {
        if (this.state.isExportLoading)
            return;

        var { month, year, totalField, generatedData, adjustmentOfTaxesList } = this.state;
        if (this.state.selectedCompany === null) {
            alert("Please select a company");
            return;
        }

        if (this.state.month === '' || this.state.month > 12 || this.state.month < 1) {
            alert("Invalid value of month");
            return;
        }

        if (this.state.year === '' || this.state.year > 9999 || this.state.year < 1990) {
            alert("Invalid value of year");
            return;
        }

        this.setState({ isExportLoading: true });

        ajaxPost({
            url: 'api/governmentforms/Form1601cExportToPDF',
            data: {
                companyId: this.state.selectedCompany.value,
            },
            onSuccess: (data) => {
                const { vfs } = vfsFonts.pdfMake;
                var adjustmentYcol1 = 129;
                var adjustmentYcol2 = 190;
                var rdoCodeLetterX = 539;
                var companyLetterX = 9;
                var addressLetter1X = 9;
                var addressLetter2X = 9;
                var zipCodeLetterX = 524;
                var contactNoLetterX = 95;
                var emailLetterX = 95;
                var addressLine1 = data.Address.replace(/\s\s+/g, ' ');
                var addressLine2 = ''
                var dataStartX = 538;
                var dataStartY = 253
                var dataGapY = 13.5;

                //detects when the address will break to 2nd line
                if (addressLine1.length > 40) {
                    var indexToSeparate = addressLine1.substring(0, 40).lastIndexOf(' ')
                    addressLine2 = addressLine1.substring(indexToSeparate, addressLine1.length).trim()
                    addressLine1 = addressLine1.substring(0, indexToSeparate).trim()

                    if (addressLine2.length > 31) {
                        var indexToSeparate2 = addressLine2.substring(0, 31).lastIndexOf(' ')
                        addressLine2 = addressLine2.substring(0, indexToSeparate2).trim()
                    }

                }

                PdfMake.vfs = vfs;

                if (data.TIN.length < 13)
                    data.TIN += '0'.repeat(13 - data.TIN.length)

                const tin1 = data.TIN.substring(0, 3).split('').join('      ');
                const tin2 = data.TIN.substring(3, 6).split('').join('      ');
                const tin3 = data.TIN.substring(6, 9).split('').join('      ');
                const tin4 = data.TIN.substring(9).split('').join('      ');

                if (month.length < 2)
                    month += '0'.repeat(2 - month.length)

                month = month.split('').join('      ');
                year = year.split('').join('      ');

                const grossCompWhole = generatedData.GrossCompensation.toFixed(2).split('.')[0]
                const grossCompDecimal = generatedData.GrossCompensation.toFixed(2).split('.')[1]
                const statMinWageWhole = generatedData.StatutoryMinimumWage.toFixed(2).split('.')[0]
                const statMinWageDecimal = generatedData.StatutoryMinimumWage.toFixed(2).split('.')[1]
                const overtimePayWhole = generatedData.OvertimePay.toFixed(2).split('.')[0]
                const overtimePayDecimal = generatedData.OvertimePay.toFixed(2).split('.')[1]
                const thirteenthMonthWhole = generatedData.ThirteenthMonthPay.toFixed(2).split('.')[0]
                const thirteenthMonthDecimal = generatedData.ThirteenthMonthPay.toFixed(2).split('.')[1]
                const deminimisWhole = generatedData.DeminimisBenefits.toFixed(2).split('.')[0]
                const deminimisDecimal = generatedData.DeminimisBenefits.toFixed(2).split('.')[1]
                const govtContriWhole = generatedData.GovtContriAndUnionDues.toFixed(2).split('.')[0]
                const govtContriDecimal = generatedData.GovtContriAndUnionDues.toFixed(2).split('.')[1]
                const otherNonTaxCompWhole = this.state.otherNonTaxCompAmount == ''? '0': parseFloat(this.state.otherNonTaxCompAmount).toFixed(2).split('.')[0]
                const otherNonTaxCompDecimal = this.state.otherNonTaxCompAmount == ''? '00': parseFloat(this.state.otherNonTaxCompAmount).toFixed(2).split('.')[1]
                const totalNonTaxCompWhole = totalField.totalNonTaxableCompensation.toFixed(2).split('.')[0]
                const totalNonTaxCompDecimal = totalField.totalNonTaxableCompensation.toFixed(2).split('.')[1]
                const totalTaxCompWhole = totalField.totalTaxableCompensation.toFixed(2).split('.')[0]
                const totalTaxCompDecimal = totalField.totalTaxableCompensation.toFixed(2).split('.')[1]
                const taxCompWhole = generatedData.TaxableCompensation.toFixed(2).split('.')[0]
                const taxCompDecimal = generatedData.TaxableCompensation.toFixed(2).split('.')[1]
                const netTaxableWhole = totalField.netTaxableCompensation.toFixed(2).split('.')[0]
                const netTaxableDecimal = totalField.netTaxableCompensation.toFixed(2).split('.')[1]
                const totalTaxWithheldWhole = generatedData.TaxReqWithheld.toFixed(2).split('.')[0]
                const totalTaxWithheldDecimal = generatedData.TaxReqWithheld.toFixed(2).split('.')[1]
                const totalAdjustmentWhole = totalField.totalAdjustmentFromSectionA.toFixed(2).split('.')[0]
                const totalAdjustmentDecimal = totalField.totalAdjustmentFromSectionA.toFixed(2).split('.')[1]
                const taxWithheldRemittanceWhole = totalField.taxReqWithheldRemittance.toFixed(2).split('.')[0]
                const taxWithheldRemittanceDecimal = totalField.taxReqWithheldRemittance.toFixed(2).split('.')[1]
                const taxRemittedReturnWhole = this.state.taxRemittedReturnPreviouslyFiled == ''? '0': this.state.taxRemittedReturnPreviouslyFiled.toFixed(2).split('.')[0]
                const taxRemittedReturnDecimal = this.state.taxRemittedReturnPreviouslyFiled == ''? '00': this.state.taxRemittedReturnPreviouslyFiled.toFixed(2).split('.')[1]
                const otherRemittanceWhole = this.state.otherRemittanceMadeAmount == ''? '0': parseFloat(this.state.otherRemittanceMadeAmount).toFixed(2).split('.')[0]
                const otherRemittanceDecimal = this.state.otherRemittanceMadeAmount == ''? '00': parseFloat(this.state.otherRemittanceMadeAmount).toFixed(2).split('.')[1]
                const totalTaxRemitMadeWhole = totalField.totalTaxRemittanceMade.toFixed(2).split('.')[0]
                const totalTaxRemitMadeDecimal = totalField.totalTaxRemittanceMade.toFixed(2).split('.')[1]
                const taxStillDueWhole = totalField.taxStillDue.toFixed(2).split('.')[0]
                const taxStillDueDecimal = totalField.taxStillDue.toFixed(2).split('.')[1]
                const surchargeWhole = this.state.surcharge == ''? '0': parseFloat(this.state.surcharge).toFixed(2).split('.')[0]
                const surchargeDecimal = this.state.surcharge == ''? '00': parseFloat(this.state.surcharge).toFixed(2).split('.')[1]
                const interestWhole = this.state.interest == ''? '0': parseFloat(this.state.interest).toFixed(2).split('.')[0]
                const interestDecimal = this.state.interest == ''? '00': parseFloat(this.state.interest).toFixed(2).split('.')[1]
                const compromiseWhole = this.state.compromise == ''? '0': parseFloat(this.state.compromise).toFixed(2).split('.')[0]
                const compromiseDecimal = this.state.compromise == ''? '00': parseFloat(this.state.compromise).toFixed(2).split('.')[1]
                const totalPenaltiesWhole = totalField.totalPenalties.toFixed(2).split('.')[0]
                const totalPenaltiesDecimal = totalField.totalPenalties.toFixed(2).split('.')[1]
                const totalAmountStillDueWhole = totalField.totalAmountStillDue.toFixed(2).split('.')[0]
                const totalAmountStillDueDecimal = totalField.totalAmountStillDue.toFixed(2).split('.')[1]

                const cashBankAmountWhole = isNullOrEmpty(this.state.cashBankNumber)? '': parseFloat(this.state.cashBankAmount).toFixed(2).split('.')[0]
                const cashBankAmountDecimal = isNullOrEmpty(this.state.cashBankNumber)? '': parseFloat(this.state.cashBankAmount).toFixed(2).split('.')[1]
                const checkAmountWhole = isNullOrEmpty(this.state.checkNumber)? '': parseFloat(this.state.checkAmount).toFixed(2).split('.')[0]
                const checkAmountDecimal = isNullOrEmpty(this.state.checkNumber)? '': parseFloat(this.state.checkAmount).toFixed(2).split('.')[1]
                const taxDebitAmountWhole = isNullOrEmpty(this.state.taxDebitNumber)? '': parseFloat(this.state.taxDebitAmount).toFixed(2).split('.')[0]
                const taxDebitAmountDecimal = isNullOrEmpty(this.state.taxDebitNumber)? '': parseFloat(this.state.taxDebitAmount).toFixed(2).split('.')[1]

                const totalAdjWhole = totalField.totalAdjustmentFromSectionA > 0? totalField.totalAdjustmentFromSectionA.toFixed(2).split('.')[0]: ''
                const totalAdjDecimal = totalField.totalAdjustmentFromSectionA > 0? totalField.totalAdjustmentFromSectionA.toFixed(2).split('.')[1]: ''

                var pdfData = {
                    pageSize: 'LETTER',
                    pageOrientation: 'portrait',
                    content: [
                        {
                            image: data.FirstPageImage,
                            width: 610,
                            height: 792,
                            absolutePosition: { x: 0, y: 0 }
                        },
                        {
                            text: month,
                            style: { fontSize: 7 },
                            absolutePosition: { x: 52, y: 96 }
                        },
                        {
                            text: year,
                            style: { fontSize: 7 },
                            absolutePosition: { x: 80, y: 96 }
                        },
                        {
                            text: tin1,
                            style: { fontSize: 7 },
                            absolutePosition: { x: 238, y: 123 }
                        },
                        {
                            text: tin2,
                            style: { fontSize: 7 },
                            absolutePosition: { x: 295, y: 123 }
                        },
                        {
                            text: tin3,
                            style: { fontSize: 7 },
                            absolutePosition: { x: 352, y: 123 }
                        },
                        {
                            text: tin4,
                            style: { fontSize: 7 },
                            absolutePosition: { x: 410, y: 123 }
                        },
                        data.RDOCode.split('').map(alp => {
                            return ({
                                stack: [{
                                    text: alp,
                                    style: { fontSize: 7 },
                                    absolutePosition: { x: rdoCodeLetterX += 14.5, y: 123 }
                                }]
                            })
                        }),
                        data.CompanyName.split('').map(alp => {
                            return ({
                                stack: [{
                                    text: alp,
                                    style: { fontSize: 7 },
                                    absolutePosition: { x: companyLetterX += 14.3, y: 146 }
                                }]
                            })
                        }),
                        addressLine1.split('').map(alp => {
                            return ({
                                stack: [{
                                    text: alp,
                                    style: { fontSize: 7 },
                                    absolutePosition: { x: addressLetter1X += 14.3, y: 168 }
                                }]
                            })
                        }),
                        addressLine2.split('').map(alp => {
                            return ({
                                stack: [{
                                    text: alp,
                                    style: { fontSize: 7 },
                                    absolutePosition: { x: addressLetter2X += 14.3, y: 181 }
                                }]
                            })
                        }),
                        data.ZipCode.split('').map(alp => {
                            return ({
                                stack: [{
                                    text: alp,
                                    style: { fontSize: 7 },
                                    absolutePosition: { x: zipCodeLetterX += 14.5, y: 181 }
                                }]
                            })
                        }),
                        data.ContactNo.split('').map(alp => {
                            return ({
                                stack: [{
                                    text: alp,
                                    style: { fontSize: 7 },
                                    absolutePosition: { x: contactNoLetterX += 14.3, y: 196 }
                                }]
                            })
                        }),
                        {
                            text: 'X',
                            style: { fontSize: 7 },
                            absolutePosition: { x: 450, y: 196 }
                        },
                        data.Email.split('').map(alp => {
                            return ({
                                stack: [{
                                    text: alp,
                                    style: { fontSize: 7 },
                                    absolutePosition: { x: emailLetterX += 14.3, y: 211 }
                                }]
                            })
                        }),
                        grossCompWhole.split('').reverse().map((num, index) => {
                            return ({
                                stack: [{
                                    text: num,
                                    style: { fontSize: 7 },
                                    absolutePosition: { x: dataStartX - index * 14.3, y: dataStartY }
                                }]
                            })
                        }),
                        grossCompDecimal.split('').map((num, index) => {
                            return ({
                                stack: [{
                                    text: num,
                                    style: { fontSize: 7 },
                                    absolutePosition: { x: dataStartX + (index + 2) * 14.5, y: dataStartY }
                                }]
                            })
                        }),
                        statMinWageWhole.split('').reverse().map((num, index) => {
                            if (index == 0) dataStartY += 20;
                            return ({
                                stack: [{
                                    text: num,
                                    style: { fontSize: 7 },
                                    absolutePosition: { x: dataStartX - index * 14.3, y: dataStartY }
                                }]
                            })
                        }),
                        statMinWageDecimal.split('').map((num, index) => {
                            return ({
                                stack: [{
                                    text: num,
                                    style: { fontSize: 7 },
                                    absolutePosition: { x: dataStartX + (index + 2) * 14.5, y: dataStartY }
                                }]
                            })
                        }),
                        overtimePayWhole.split('').reverse().map((num, index) => {
                            if (index == 0) dataStartY += dataGapY;
                            return ({
                                stack: [{
                                    text: num,
                                    style: { fontSize: 7 },
                                    absolutePosition: { x: dataStartX - index * 14.3, y: dataStartY }
                                }]
                            })
                        }),
                        overtimePayDecimal.split('').map((num, index) => {
                            return ({
                                stack: [{
                                    text: num,
                                    style: { fontSize: 7 },
                                    absolutePosition: { x: dataStartX + (index + 2) * 14.5, y: dataStartY }
                                }]
                            })
                        }),
                        thirteenthMonthWhole.split('').reverse().map((num, index) => {
                            if (index == 0) dataStartY += dataGapY;
                            return ({
                                stack: [{
                                    text: num,
                                    style: { fontSize: 7 },
                                    absolutePosition: { x: dataStartX - index * 14.3, y: dataStartY }
                                }]
                            })
                        }),
                        thirteenthMonthDecimal.split('').map((num, index) => {
                            return ({
                                stack: [{
                                    text: num,
                                    style: { fontSize: 7 },
                                    absolutePosition: { x: dataStartX + (index + 2) * 14.5, y: dataStartY }
                                }]
                            })
                        }),
                        deminimisWhole.split('').reverse().map((num, index) => {
                            if (index == 0) dataStartY += dataGapY;
                            return ({
                                stack: [{
                                    text: num,
                                    style: { fontSize: 7 },
                                    absolutePosition: { x: dataStartX - index * 14.3, y: dataStartY }
                                }]
                            })
                        }),
                        deminimisDecimal.split('').map((num, index) => {
                            return ({
                                stack: [{
                                    text: num,
                                    style: { fontSize: 7 },
                                    absolutePosition: { x: dataStartX + (index + 2) * 14.5, y: dataStartY }
                                }]
                            })
                        }),
                        govtContriWhole.split('').reverse().map((num, index) => {
                            if (index == 0) dataStartY += dataGapY;
                            return ({
                                stack: [{
                                    text: num,
                                    style: { fontSize: 7 },
                                    absolutePosition: { x: dataStartX - index * 14.3, y: dataStartY }
                                }]
                            })
                        }),
                        govtContriDecimal.split('').map((num, index) => {
                            return ({
                                stack: [{
                                    text: num,
                                    style: { fontSize: 7 },
                                    absolutePosition: { x: dataStartX + (index + 2) * 14.5, y: dataStartY }
                                }]
                            })
                        }),
                        otherNonTaxCompWhole.split('').reverse().map((num, index) => {
                            if (index == 0) dataStartY += dataGapY;
                            return ({
                                stack: [{
                                    text: num,
                                    style: { fontSize: 7 },
                                    absolutePosition: { x: dataStartX - index * 14.3, y: dataStartY }
                                }]
                            })
                        }),
                        otherNonTaxCompDecimal.split('').map((num, index) => {
                            return ({
                                stack: [{
                                    text: num,
                                    style: { fontSize: 7 },
                                    absolutePosition: { x: dataStartX + (index + 2) * 14.5, y: dataStartY }
                                }]
                            })
                        }),
                        {
                            text: this.state.otherNonTaxCompName,
                            style: { fontSize: 6 },
                            absolutePosition: { x: 210, y: dataStartY }
                        },
                        totalNonTaxCompWhole.split('').reverse().map((num, index) => {
                            if (index == 0) dataStartY += dataGapY;
                            return ({
                                stack: [{
                                    text: num,
                                    style: { fontSize: 7 },
                                    absolutePosition: { x: dataStartX - index * 14.3, y: dataStartY }
                                }]
                            })
                        }),
                        totalNonTaxCompDecimal.split('').map((num, index) => {
                            return ({
                                stack: [{
                                    text: num,
                                    style: { fontSize: 7 },
                                    absolutePosition: { x: dataStartX + (index + 2) * 14.5, y: dataStartY }
                                }]
                            })
                        }),
                        totalTaxCompWhole.split('').reverse().map((num, index) => {
                            if (index == 0) dataStartY += dataGapY;
                            return ({
                                stack: [{
                                    text: num,
                                    style: { fontSize: 7 },
                                    absolutePosition: { x: dataStartX - index * 14.3, y: dataStartY }
                                }]
                            })
                        }),
                        totalTaxCompDecimal.split('').map((num, index) => {
                            return ({
                                stack: [{
                                    text: num,
                                    style: { fontSize: 7 },
                                    absolutePosition: { x: dataStartX + (index + 2) * 14.5, y: dataStartY }
                                }]
                            })
                        }),
                        taxCompWhole.split('').reverse().map((num, index) => {
                            if (index == 0) dataStartY += dataGapY;
                            return ({
                                stack: [{
                                    text: num,
                                    style: { fontSize: 7 },
                                    absolutePosition: { x: dataStartX - index * 14.3, y: dataStartY }
                                }]
                            })
                        }),
                        taxCompDecimal.split('').map((num, index) => {
                            return ({
                                stack: [{
                                    text: num,
                                    style: { fontSize: 7 },
                                    absolutePosition: { x: dataStartX + (index + 2) * 14.5, y: dataStartY }
                                }]
                            })
                        }),
                        netTaxableWhole.split('').reverse().map((num, index) => {
                            if (index == 0) dataStartY += dataGapY;
                            return ({
                                stack: [{
                                    text: num,
                                    style: { fontSize: 7 },
                                    absolutePosition: { x: dataStartX - index * 14.3, y: dataStartY }
                                }]
                            })
                        }),
                        netTaxableDecimal.split('').map((num, index) => {
                            return ({
                                stack: [{
                                    text: num,
                                    style: { fontSize: 7 },
                                    absolutePosition: { x: dataStartX + (index + 2) * 14.5, y: dataStartY }
                                }]
                            })
                        }),
                        totalTaxWithheldWhole.split('').reverse().map((num, index) => {
                            if (index == 0) dataStartY += dataGapY;
                            return ({
                                stack: [{
                                    text: num,
                                    style: { fontSize: 7 },
                                    absolutePosition: { x: dataStartX - index * 14.3, y: dataStartY }
                                }]
                            })
                        }),
                        totalTaxWithheldDecimal.split('').map((num, index) => {
                            return ({
                                stack: [{
                                    text: num,
                                    style: { fontSize: 7 },
                                    absolutePosition: { x: dataStartX + (index + 2) * 14.5, y: dataStartY }
                                }]
                            })
                        }),
                        totalAdjustmentWhole.split('').reverse().map((num, index) => {
                            if (index == 0) dataStartY += dataGapY;
                            return ({
                                stack: [{
                                    text: num,
                                    style: { fontSize: 7 },
                                    absolutePosition: { x: dataStartX - index * 14.3, y: dataStartY }
                                }]
                            })
                        }),
                        totalAdjustmentDecimal.split('').map((num, index) => {
                            return ({
                                stack: [{
                                    text: num,
                                    style: { fontSize: 7 },
                                    absolutePosition: { x: dataStartX + (index + 2) * 14.5, y: dataStartY }
                                }]
                            })
                        }),
                        taxWithheldRemittanceWhole.split('').reverse().map((num, index) => {
                            if (index == 0) dataStartY += dataGapY;
                            return ({
                                stack: [{
                                    text: num,
                                    style: { fontSize: 7 },
                                    absolutePosition: { x: dataStartX - index * 14.3, y: dataStartY }
                                }]
                            })
                        }),
                        taxWithheldRemittanceDecimal.split('').map((num, index) => {
                            return ({
                                stack: [{
                                    text: num,
                                    style: { fontSize: 7 },
                                    absolutePosition: { x: dataStartX + (index + 2) * 14.5, y: dataStartY }
                                }]
                            })
                        }),
                        taxRemittedReturnWhole.split('').reverse().map((num, index) => {
                            if (index == 0) dataStartY += dataGapY;
                            return ({
                                stack: [{
                                    text: num,
                                    style: { fontSize: 7 },
                                    absolutePosition: { x: dataStartX - index * 14.3, y: dataStartY }
                                }]
                            })
                        }),
                        taxRemittedReturnDecimal.split('').map((num, index) => {
                            return ({
                                stack: [{
                                    text: num,
                                    style: { fontSize: 7 },
                                    absolutePosition: { x: dataStartX + (index + 2) * 14.5, y: dataStartY }
                                }]
                            })
                        }),
                        otherRemittanceWhole.split('').reverse().map((num, index) => {
                            if (index == 0) dataStartY += dataGapY;
                            return ({
                                stack: [{
                                    text: num,
                                    style: { fontSize: 7 },
                                    absolutePosition: { x: dataStartX - index * 14.3, y: dataStartY }
                                }]
                            })
                        }),
                        otherRemittanceDecimal.split('').map((num, index) => {
                            return ({
                                stack: [{
                                    text: num,
                                    style: { fontSize: 7 },
                                    absolutePosition: { x: dataStartX + (index + 2) * 14.5, y: dataStartY }
                                }]
                            })
                        }),
                        {
                            text: this.state.otherRemittanceMadeName,
                            style: { fontSize: 6 },
                            absolutePosition: { x: 200, y: dataStartY }
                        },
                        totalTaxRemitMadeWhole.split('').reverse().map((num, index) => {
                            if (index == 0) dataStartY += dataGapY;
                            return ({
                                stack: [{
                                    text: num,
                                    style: { fontSize: 7 },
                                    absolutePosition: { x: dataStartX - index * 14.3, y: dataStartY }
                                }]
                            })
                        }),
                        totalTaxRemitMadeDecimal.split('').map((num, index) => {
                            return ({
                                stack: [{
                                    text: num,
                                    style: { fontSize: 7 },
                                    absolutePosition: { x: dataStartX + (index + 2) * 14.5, y: dataStartY }
                                }]
                            })
                        }),
                        taxStillDueWhole.split('').reverse().map((num, index) => {
                            if (index == 0) dataStartY += dataGapY;
                            return ({
                                stack: [{
                                    text: num,
                                    style: { fontSize: 7 },
                                    absolutePosition: { x: dataStartX - index * 14.3, y: dataStartY }
                                }]
                            })
                        }),
                        taxStillDueDecimal.split('').map((num, index) => {
                            return ({
                                stack: [{
                                    text: num,
                                    style: { fontSize: 7 },
                                    absolutePosition: { x: dataStartX + (index + 2) * 14.5, y: dataStartY }
                                }]
                            })
                        }),
                        surchargeWhole.split('').reverse().map((num, index) => {
                            if (index == 0) dataStartY += dataGapY;
                            return ({
                                stack: [{
                                    text: num,
                                    style: { fontSize: 7 },
                                    absolutePosition: { x: dataStartX - index * 14.3, y: dataStartY }
                                }]
                            })
                        }),
                        surchargeDecimal.split('').map((num, index) => {
                            return ({
                                stack: [{
                                    text: num,
                                    style: { fontSize: 7 },
                                    absolutePosition: { x: dataStartX + (index + 2) * 14.5, y: dataStartY }
                                }]
                            })
                        }),
                        interestWhole.split('').reverse().map((num, index) => {
                            if (index == 0) dataStartY += dataGapY;
                            return ({
                                stack: [{
                                    text: num,
                                    style: { fontSize: 7 },
                                    absolutePosition: { x: dataStartX - index * 14.3, y: dataStartY }
                                }]
                            })
                        }),
                        interestDecimal.split('').map((num, index) => {
                            return ({
                                stack: [{
                                    text: num,
                                    style: { fontSize: 7 },
                                    absolutePosition: { x: dataStartX + (index + 2) * 14.5, y: dataStartY }
                                }]
                            })
                        }),
                        compromiseWhole.split('').reverse().map((num, index) => {
                            if (index == 0) dataStartY += dataGapY;
                            return ({
                                stack: [{
                                    text: num,
                                    style: { fontSize: 7 },
                                    absolutePosition: { x: dataStartX - index * 14.3, y: dataStartY }
                                }]
                            })
                        }),
                        compromiseDecimal.split('').map((num, index) => {
                            return ({
                                stack: [{
                                    text: num,
                                    style: { fontSize: 7 },
                                    absolutePosition: { x: dataStartX + (index + 2) * 14.5, y: dataStartY }
                                }]
                            })
                        }),
                        totalPenaltiesWhole.split('').reverse().map((num, index) => {
                            if (index == 0) dataStartY += dataGapY;
                            return ({
                                stack: [{
                                    text: num,
                                    style: { fontSize: 7 },
                                    absolutePosition: { x: dataStartX - index * 14.3, y: dataStartY }
                                }]
                            })
                        }),
                        totalPenaltiesDecimal.split('').map((num, index) => {
                            return ({
                                stack: [{
                                    text: num,
                                    style: { fontSize: 7 },
                                    absolutePosition: { x: dataStartX + (index + 2) * 14.5, y: dataStartY }
                                }]
                            })
                        }),
                        totalAmountStillDueWhole.split('').reverse().map((num, index) => {
                            if (index == 0) dataStartY += dataGapY;
                            return ({
                                stack: [{
                                    text: num,
                                    style: { fontSize: 7 },
                                    absolutePosition: { x: dataStartX - index * 14.3, y: dataStartY }
                                }]
                            })
                        }),
                        totalAmountStillDueDecimal.split('').map((num, index) => {
                            return ({
                                stack: [{
                                    text: num,
                                    style: { fontSize: 7 },
                                    absolutePosition: { x: dataStartX + (index + 2) * 14.5, y: dataStartY }
                                }]
                            })
                        }),

                        this.state.cashBankDrawee.split('').map((num, index) => {
                            if (index == 0) dataStartY += dataGapY;
                            return ({
                                stack: [{
                                    text: num,
                                    style: { fontSize: 7 },
                                    absolutePosition: { x: 123 + index * 14.5, y: 668 }
                                }]
                            })
                        }),
                        this.state.cashBankNumber.split('').map((num, index) => {
                            if (index == 0) dataStartY += dataGapY;
                            return ({
                                stack: [{
                                    text: num,
                                    style: { fontSize: 7 },
                                    absolutePosition: { x: 195 + index * 14.5, y: 668 }
                                }]
                            })
                        }),
                        
                        (this.state.cashBankNumber !== '' && this.state.cashBankDate !== '' &&
                            moment(this.state.cashBankDate).format('MMDDYYYY').split('').map((num, index) => {
                                if (index == 0) dataStartY += dataGapY;
                                return ({
                                    stack: [{
                                        text: num,
                                        style: { fontSize: 7 },
                                        absolutePosition: { x: 280 + index * 14.5, y: 668 }
                                    }]
                                })
                            }),
                        cashBankAmountWhole.split('').reverse().map((num, index) => {
                            if (index == 0) dataStartY += dataGapY;
                            return ({
                                stack: [{
                                    text: num,
                                    style: { fontSize: 7 },
                                    absolutePosition: { x: dataStartX - index * 14.3, y: 668 }
                                }]
                            })
                        })),
                        cashBankAmountDecimal.split('').map((num, index) => {
                            return ({
                                stack: [{
                                    text: num,
                                    style: { fontSize: 7 },
                                    absolutePosition: { x: dataStartX + (index + 2) * 14.5, y: 668 }
                                }]
                            })
                        }),

                        this.state.checkDrawee.split('').map((num, index) => {
                            if (index == 0) dataStartY += dataGapY;
                            return ({
                                stack: [{
                                    text: num,
                                    style: { fontSize: 7 },
                                    absolutePosition: { x: 123 + index * 14.5, y: 682.5 }
                                }]
                            })
                        }),
                        this.state.checkNumber.split('').map((num, index) => {
                            if (index == 0) dataStartY += dataGapY;
                            return ({
                                stack: [{
                                    text: num,
                                    style: { fontSize: 7 },
                                    absolutePosition: { x: 195 + index * 14.5, y: 682.5 }
                                }]
                            })
                        }),
                        (this.state.checkNumber !== '' && this.state.checkDate !== '' &&
                        moment(this.state.checkDate).format('MMDDYYYY').split('').map((num, index) => {
                            if (index == 0) dataStartY += dataGapY;
                            return ({
                                stack: [{
                                    text: num,
                                    style: { fontSize: 7 },
                                    absolutePosition: { x: 280 + index * 14.5, y: 682.5 }
                                }]
                            })
                        })),
                        checkAmountWhole.split('').reverse().map((num, index) => {
                            if (index == 0) dataStartY += dataGapY;
                            return ({
                                stack: [{
                                    text: num,
                                    style: { fontSize: 7 },
                                    absolutePosition: { x: dataStartX - index * 14.3, y: 682.5 }
                                }]
                            })
                        }),
                        checkAmountDecimal.split('').map((num, index) => {
                            return ({
                                stack: [{
                                    text: num,
                                    style: { fontSize: 7 },
                                    absolutePosition: { x: dataStartX + (index + 2) * 14.5, y: 682.5 }
                                }]
                            })
                        }),

                        this.state.taxDebitNumber.split('').map((num, index) => {
                            if (index == 0) dataStartY += dataGapY;
                            return ({
                                stack: [{
                                    text: num,
                                    style: { fontSize: 7 },
                                    absolutePosition: { x: 195 + index * 14.5, y: 700 }
                                }]
                            })
                        }),
                        (this.state.taxDebitNumber !== '' && this.state.taxDebitDate !== '' &&
                        moment(this.state.taxDebitDate).format('MMDDYYYY').split('').map((num, index) => {
                            if (index == 0) dataStartY += dataGapY;
                            return ({
                                stack: [{
                                    text: num,
                                    style: { fontSize: 7 },
                                    absolutePosition: { x: 280 + index * 14.5, y: 700 }
                                }]
                            })
                        })),
                        taxDebitAmountWhole.split('').reverse().map((num, index) => {
                            if (index == 0) dataStartY += dataGapY;
                            return ({
                                stack: [{
                                    text: num,
                                    style: { fontSize: 7 },
                                    absolutePosition: { x: dataStartX - index * 14.3, y: 700 }
                                }]
                            })
                        }),
                        taxDebitAmountDecimal.split('').map((num, index) => {
                            return ({
                                stack: [{
                                    text: num,
                                    style: { fontSize: 7 },
                                    absolutePosition: { x: dataStartX + (index + 2) * 14.5, y: 700 }
                                }]
                            })
                        }),
                        {
                            text: totalField.totalAdjustmentFromSectionA,
                            style: { fontSize: 6 },
                            absolutePosition: { x: 380, y: 583 }
                        },
                        {
                            pageBreak: 'before',
                            image: data.SecondPageImage,
                            width: 610,
                            height: 792,
                            absolutePosition: { x: 0, y: 0 }
                        },
                        adjustmentOfTaxesList.map(x => {
                            adjustmentYcol1 += 14.5;
                            adjustmentYcol2 += 14.4;
                            var prevMonth = x.prevMonth !== "" ? x.prevMonth.split('/') : ['', ''];
                            var datePaid = x.datePaid !== "" ? moment(x.datePaid).format('MMDDYYYY') : '';
                            const taxPaidWhole = isNullOrEmpty(x.taxPaid)? '0': parseFloat(x.taxPaid).toFixed(2).split('.')[0]
                            const taxPaidDecimal = isNullOrEmpty(x.taxPaid)? '00': parseFloat(x.taxPaid).toFixed(2).split('.')[1]
                            const taxDueWhole = isNullOrEmpty(x.taxDue)? '0': parseFloat(x.taxDue).toFixed(2).split('.')[0]
                            const taxDueDecimal = isNullOrEmpty(x.taxDue)? '00': parseFloat(x.taxDue).toFixed(2).split('.')[1]
                            const taxAdjustmentWhole = isNullOrEmpty(x.currYearAdjustment)? '0': parseFloat(x.currYearAdjustment).toFixed(2).split('.')[0]
                            const taxAdjustmentDecimal = isNullOrEmpty(x.currYearAdjustment)? '00': parseFloat(x.currYearAdjustment).toFixed(2).split('.')[1]
                            return (
                                {
                                    stack: [
                                        prevMonth[0].split('').map((num, index) => {
                                            return ({
                                                stack: [{
                                                    text: num,
                                                    style: { fontSize: 7 },
                                                    absolutePosition: { x: 36 + index * 14.3, y: adjustmentYcol1 }
                                                }]
                                            })
                                        }),
                                        prevMonth[1].split('').map((num, index) => {
                                            return ({
                                                stack: [{
                                                    text: num,
                                                    style: { fontSize: 7 },
                                                    absolutePosition: { x: 65 + index * 14.3, y: adjustmentYcol1 }
                                                }]
                                            })
                                        }),

                                        datePaid.split('').map((num, index) => {
                                            return ({
                                                stack: [{
                                                    text: num,
                                                    style: { fontSize: 7 },
                                                    absolutePosition: { x: 121 + index * 14.3, y: adjustmentYcol1 }
                                                }]
                                            })
                                        }),

                                        x.bankValidation.split('').map((num, index) => {
                                            return ({
                                                stack: [{
                                                    text: num,
                                                    style: { fontSize: 7 },
                                                    absolutePosition: { x: 235 + index * 14.3, y: adjustmentYcol1 }
                                                }]
                                            })
                                        }),

                                        x.bankCode.split('').map((num, index) => {
                                            return ({
                                                stack: [{
                                                    text: num,
                                                    style: { fontSize: 7 },
                                                    absolutePosition: { x: 306 + index * 14.3, y: adjustmentYcol1 }
                                                }]
                                            })
                                        }),

                                        taxPaidWhole.split('').reverse().map((num, index) => {
                                            return ({
                                                stack: [{
                                                    text: num,
                                                    style: { fontSize: 7 },
                                                    absolutePosition: { x: dataStartX - index * 14.3, y: adjustmentYcol1 }
                                                }]
                                            })
                                        }),
                                        taxPaidDecimal.split('').map((num, index) => {
                                            return ({
                                                stack: [{
                                                    text: num,
                                                    style: { fontSize: 7 },
                                                    absolutePosition: { x: dataStartX + (index + 2) * 14.3, y: adjustmentYcol1 }
                                                }]
                                            })
                                        }),

                                        taxDueWhole.split('').reverse().map((num, index) => {
                                            return ({
                                                stack: [{
                                                    text: num,
                                                    style: { fontSize: 7 },
                                                    absolutePosition: { x: 237 - index * 14.3, y: adjustmentYcol2 }
                                                }]
                                            })
                                        }),
                                        taxDueDecimal.split('').map((num, index) => {
                                            return ({
                                                stack: [{
                                                    text: num,
                                                    style: { fontSize: 7 },
                                                    absolutePosition: { x: 237 + (index + 2) * 14.3, y: adjustmentYcol2 }
                                                }]
                                            })
                                        }),

                                        taxAdjustmentWhole.split('').reverse().map((num, index) => {
                                            return ({
                                                stack: [{
                                                    text: num,
                                                    style: { fontSize: 7 },
                                                    absolutePosition: { x: 480 - index * 14.3, y: adjustmentYcol2 }
                                                }]
                                            })
                                        }),
                                        taxAdjustmentDecimal.split('').map((num, index) => {
                                            return ({
                                                stack: [{
                                                    text: num,
                                                    style: { fontSize: 7 },
                                                    absolutePosition: { x: 480 + (index + 2) * 14.3, y: adjustmentYcol2 }
                                                }]
                                            })
                                        }),
                                        
                                    ]
                                }
                            )
                        }),
                        
                        totalAdjWhole.split('').reverse().map((num, index) => {
                            return ({
                                stack: [{
                                    text: num,
                                    style: { fontSize: 7 },
                                    absolutePosition: { x: 480 - index * 14.3, y: 247.2 }
                                }]
                            })
                        }),
                        totalAdjDecimal.split('').map((num, index) => {
                            return ({
                                stack: [{
                                    text: num,
                                    style: { fontSize: 7 },
                                    absolutePosition: { x: 480 + (index + 2) * 14.3, y: 247.2 }
                                }]
                            })
                        }),
                    ]
                }
                pdfData.content = pdfData.content.filter(x => x !== false);
                PdfMake.createPdf(pdfData).open();
            },
            finally: () => { this.setState({ isExportLoading: false }); }
        })
    }
    render() {

        var { generatedData, totalField, selectedAdjustmentModel } = this.state;

        const tabSelection = [
            {
                menuItem: 'Computation of Tax', pane:
                    <Tab.Pane attached={false} style={{ border: '0px none', width: '100%' }}>
                        <table style={{ minWidth: 675, width: '100%' }}>
                            {this.createTableRow('14 - Total amount of compensation', generatedData.GrossCompensation, true)}
                            <tr>
                                <td colSpan={2}>
                                    Less: Non Taxable Compensation
                                </td>
                            </tr>
                            {this.createTableRow(<div> &nbsp; &nbsp; &nbsp; <label>15 - Statutory Minimum Wage (MWEs)</label></div>, generatedData.StatutoryMinimumWage, true)}
                            {this.createTableRow(<div> &nbsp; &nbsp; &nbsp; <label>16 - Holiday Pay, Overtime Pay, Night Shift Differential Pay, Hazard Pay</label></div>, generatedData.OvertimePay, true)}
                            {this.createTableRow(<div> &nbsp; &nbsp; &nbsp; <label>17 - Thirteenth Month Pay</label></div>, generatedData.ThirteenthMonthPay, true)}
                            {this.createTableRow(<div> &nbsp; &nbsp; &nbsp; <label>18 - Deminimis</label></div>, generatedData.DeminimisBenefits, true)}
                            {this.createTableRow(<div> &nbsp; &nbsp; &nbsp; <label>19 - Government Contribution and Union Dues</label></div>, generatedData.GovtContriAndUnionDues, true)}
                            {this.createTableRow(<div> &nbsp; &nbsp; &nbsp; <label>20 - Other Non-Taxable Compensation (specify)</label> &nbsp;
                                <Input style={{ border: '1px solid gray' }} value={this.state.otherNonTaxCompName} onChange={(evt) => this.setState({ otherNonTaxCompName: evt.target.value })} /></div>,
                                'otherNonTaxCompAmount', false)}
                            {this.createTableRow('21 - Total Non-Taxable Compensation (Sum of items 15 to 20)', totalField.totalNonTaxableCompensation, true)}
                            {this.createTableRow('22 - Total Taxable Compensation (Items 14 less item 21)', totalField.totalTaxableCompensation, true)}
                            {this.createTableRow('23 - Less Taxable compensation not subjected to withholding tax', generatedData.TaxableCompensation, true)}
                            {this.createTableRow('24 - Net Taxable Compensation (Item 22 Less Item 23)', totalField.netTaxableCompensation, true)}
                            {this.createTableRow('25 - Total Taxes Withheld', generatedData.TaxReqWithheld, true)}
                            {this.createTableRow('26 - Add/(Less): Adjustment of Taxes Withheld from Previous Month/s', totalField.totalAdjustmentFromSectionA, true)}
                            {this.createTableRow('27 - Taxes Withheld for Remittance (Sum of items 25 and 26)', totalField.taxReqWithheldRemittance, true)}
                            {this.createTableRow('28 - Less: Tax Remitted in Return Previously Filed, if this is an amended return', 'taxRemittedReturnPreviouslyFiled', false)}
                            {this.createTableRow(<div><label>29 - Other Remittances Made(specify)</label> &nbsp;
                                <Input style={{ border: '1px solid gray' }} value={this.state.otherRemittanceMadeName} onChange={(evt) => this.setState({ otherRemittanceMadeName: evt.target.value })} /></div>,
                                'otherRemittanceMadeAmount', false)}
                            {this.createTableRow(<div><label>30 - Total Tax Remittance Made (Sum of items 28 and 29)</label></div>, totalField.totalTaxRemittanceMade, true)}
                            {this.createTableRow('31 - Tax Still Due/(Overremittance) (items 27 less item 30)', totalField.taxStillDue, true)}
                            <tr>
                                <td colSpan={2}>
                                    Add: Penalties
                                </td>
                            </tr>
                            {this.createTableRow(<div style={{ marginLeft: 40 }}><label>32 - Surcharge</label></div>, 'surcharge', false)}
                            {this.createTableRow(<div style={{ marginLeft: 40 }}><label>33 - Interest</label></div>, 'interest', false)}
                            {this.createTableRow(<div style={{ marginLeft: 40 }}><label>34 - Compromise</label></div>, 'compromise', false)}
                            {this.createTableRow(<div style={{ marginLeft: 40 }}><label>35 - Total Penalties</label></div>, totalField.totalPenalties, true)}
                            {this.createTableRow('36 - Total Amount Still Due/(Overremittance) (Sum of items 31 to 34)', totalField.totalAmountStillDue, true)}
                        </table>
                    </Tab.Pane>

            },
            {
                menuItem: 'Details of Payment', pane:
                    <Tab.Pane attached={false} style={{ border: '0px none', width: '100%' }}>
                        <table style={{ minWidth: 675, width: '100%' }}>
                            <tr>
                                <td colSpan={2}>
                                    <b>CASH/BANK DEBIT MEMO</b>
                                </td>
                            </tr>
                            {this.createTableInputRow(<div style={{marginLeft: 20}}><label>Drawee Bank/Agency</label></div>, 'cashBankDrawee', false)}
                            {this.createTableRow(<div style={{marginLeft: 20}}><label>Number</label></div>, 'cashBankNumber', false)}
                            {this.createTableInputRow(<div style={{marginLeft: 20}}><label>Date</label></div>, 'cashBankDate', true)}
                            {this.createTableRow(<div style={{marginLeft: 20}}><label>Amount</label></div>, 'cashBankAmount', false)}

                            <tr>
                                <td colSpan={2}>
                                    <b>CHECK</b>
                                </td>
                            </tr>
                            {this.createTableInputRow(<div style={{marginLeft: 20}}><label>Drawee Bank/Agency</label></div>, 'checkDrawee', false)}
                            {this.createTableRow(<div style={{marginLeft: 20}}><label>Number</label></div>, 'checkNumber', false)}
                            {this.createTableInputRow(<div style={{marginLeft: 20}}><label>Date</label></div>, 'checkDate', true)}
                            {this.createTableRow(<div style={{marginLeft: 20}}><label>Amount</label></div>, 'checkAmount', false)}

                            <tr>
                                <td colSpan={2}>
                                    <b>TAX DEBIT MEMO</b>
                                </td>
                            </tr>
                            {this.createTableRow(<div style={{marginLeft: 20}}><label>Number</label></div>, 'taxDebitNumber', false)}
                            {this.createTableInputRow(<div style={{marginLeft: 20}}><label>Date</label></div>, 'taxDebitDate', true)}
                            {this.createTableRow(<div style={{marginLeft: 20}}><label>Amount</label></div>, 'taxDebitAmount', false)}
                        </table>
                    </Tab.Pane>

            },
            {
                menuItem: 'Schedule', pane:
                    <Tab.Pane attached={false} style={{ border: '0px none', width: '100%' }}>
                        <div>
                            <p style={{ fontSize: '14', fontFamily: 'Segoe UI', fontWeight: 'bold' }}>Adjustment of Taxes Withheld on Compensation for Previous Months</p>
                        </div>

                        <div style={{ marginTop: 20 }}>
                            <Button size='mini' onClick={this.addAdjustment.bind(this)}>Add</Button>
                            &nbsp; Maximum Row (3)
                        </div>
                        <div>
                            <label style={{ float: 'right' }}> Total: &nbsp; &nbsp; {this.state.totalField.totalAdjustmentFromSectionA}</label>
                        </div>
                        <div style={{ marginTop: 10 }}>
                            <Table fixed striped padded size='small'>
                                <Table.Header>
                                    <Table.Row style={{ 'backgroundColor': 'gainsboro', 'fontSize': '11px', padding: '0px' }}>
                                        <Table.HeaderCell style={{ width: '12.5%' }}>Previous Month(s) (MM/YYYY)</Table.HeaderCell>
                                        <Table.HeaderCell style={{ width: '12.5%' }}>Date Paid (MM/DD/YYYY)</Table.HeaderCell>
                                        <Table.HeaderCell style={{ width: '12.5%' }}>Drawee Bank / Bank Code / Agency</Table.HeaderCell>
                                        <Table.HeaderCell style={{ width: '12.5%' }}>Number</Table.HeaderCell>
                                        <Table.HeaderCell style={{ width: '12.5%' }}>Tax Paid (Excluding Penalties for the Month)</Table.HeaderCell>
                                        <Table.HeaderCell style={{ width: '12.5%' }}>Should Be Tax Due for the Month</Table.HeaderCell>
                                        <Table.HeaderCell style={{ width: '12.5%' }}>Adjustment</Table.HeaderCell>
                                        <Table.HeaderCell style={{ width: '12.5%' }}><span /></Table.HeaderCell>
                                    </Table.Row>
                                </Table.Header>
                                <Table.Body>
                                    {this.state.adjustmentOfTaxesList.map(list => {
                                        return (
                                            <Table.Row>
                                                <Table.Cell verticalAlign='top'>{list.prevMonth}</Table.Cell>
                                                <Table.Cell verticalAlign='top'>{list.datePaid}</Table.Cell>
                                                <Table.Cell verticalAlign='top'>{list.bankValidation}</Table.Cell>
                                                <Table.Cell verticalAlign='top'>{list.bankCode}</Table.Cell>
                                                <Table.Cell verticalAlign='top'>{list.taxPaid}</Table.Cell>
                                                <Table.Cell verticalAlign='top'>{list.taxDue}</Table.Cell>
                                                <Table.Cell verticalAlign='top'>{list.currYearAdjustment}</Table.Cell>
                                                <Table.Cell verticalAlign='top'>
                                                    <ButtonGroup size='mini' floated='right' vertical>
                                                        <Button size='mini' onClick={this.loadAdjustment.bind(this, list)}>Edit</Button>
                                                        <Button size='mini' onClick={this.deleteAdjustment.bind(this, list)}>Delete</Button>
                                                    </ButtonGroup>
                                                </Table.Cell>
                                            </Table.Row>
                                        )
                                    })}
                                </Table.Body>
                            </Table>
                        </div>

                    </Tab.Pane>
            }
        ]

        return (
            <div>
                <h1>Form 1601-C</h1>

                <div style={{ display: 'flex' }}>
                    <div style={{ height: window.innerHeight - 203, marginRight: '10px', width: 300 }}>
                        <div>
                            <label>Company</label>
                            <Select value={this.state.selectedCompany} isLoading={this.state.isCompanyLoading} options={this.state.companyList}
                                onChange={(e) => this.setState({ selectedCompany: e, selectedEmployee: null }, this.loadEmployees)} />
                        </div>

                        <div style={{ marginTop: 5 }}>
                            <label>Month</label>
                            <Input fluid value={this.state.month}
                                onChange={(e) => this.setState({ month: e.target.value })}
                                onKeyPress={(e) => handleNumericOnKeyPress(e)}
                                onKeyDown={(e) => handleNumericOnKeyDown(e)}
                            />
                        </div>

                        <div style={{ marginTop: 5 }}>
                            <label>Year</label>
                            <Input fluid value={this.state.year}
                                onChange={(e) => this.setState({ year: e.target.value })}
                                onKeyPress={(e) => handleNumericOnKeyPress(e)}
                                onKeyDown={(e) => handleNumericOnKeyDown(e)}
                            />
                        </div>

                        <div style={{ display: 'flex', float: 'right', right: 0 }}>
                            <Button style={{ marginTop: 10 }} loading={this.state.isGenerateLoading} content='Generate' floated='right' onClick={this.onGenerateClick.bind(this)} />
                            <Button style={{ marginTop: 10 }} loading={this.state.isExportLoading} content='Export To PDF' floated='right' onClick={this.onExportPDFClick.bind(this)} />
                        </div>

                    </div>

                    <div style={{ width: window.innerWidth - 600, height: window.innerHeight - 203, overflowX: 'auto', overflowY: 'auto' }}>
                        <Tab renderActiveOnly={false} style={{ minWidth: 700 }} menu={{ color: 'green', tabular: false, attached: false }}
                            panes={tabSelection} activeIndex={this.state.activeIndex} onTabChange={this.handleTabChange} />
                    </div>
                </div>

                {this.state.isModalOpen &&
                    <Modal size='small' open={true}>
                        <Modal.Header>
                            Adjustment of Taxes
                        </Modal.Header>
                        <Modal.Content>
                            <Label>Previous Month(s) (MM/YYYY)</Label>
                            <Input fluid value={selectedAdjustmentModel.prevMonth}
                                onChange={this.handleChanged.bind(this, 'prevMonth')}
                                onKeyPress={this.prevMonthKeyPressed.bind(this)}
                                onKeyDown={(e) => handleNumericOnKeyDown(e)}
                            />
                            <Label>Date Paid</Label>
                            <Input
                                size='small'
                                value={selectedAdjustmentModel.datePaid}
                                fluid
                                type="date"
                                min="1900-01-01"
                                max="2999-12-31"
                                onChange={this.handleChanged.bind(this, 'datePaid')}
                            />
                            <Label>Bank Validation / ROR No.</Label>
                            <Input fluid value={selectedAdjustmentModel.bankValidation}
                                onChange={this.handleChanged.bind(this, 'bankValidation')}
                            />
                            <Label>Bank Code</Label>
                            <Input fluid value={selectedAdjustmentModel.bankCode}
                                onChange={this.handleChanged.bind(this, 'bankCode')}
                            />
                            <Label>Tax Paid (Excluding Penalties for the Month)</Label>
                            <Input fluid value={selectedAdjustmentModel.taxPaid}
                                onChange={this.handleChanged.bind(this, 'taxPaid')}
                                onKeyPress={(e) => handleNumericOnKeyPress(e)}
                                onKeyDown={(e) => handleNumericOnKeyDown(e)}
                            />
                            <Label>Should Be Tax Due for the Month</Label>
                            <Input fluid value={selectedAdjustmentModel.taxDue}
                                onChange={this.handleChanged.bind(this, 'taxDue')}
                                onKeyPress={(e) => handleNumericOnKeyPress(e)}
                                onKeyDown={(e) => handleNumericOnKeyDown(e)}
                            />
                            <Label>Adjustment</Label>
                            <Input fluid value={selectedAdjustmentModel.currYearAdjustment} disabled={true} />
                        </Modal.Content>
                        <Modal.Actions>
                            <Button type='submit' onClick={this.handleOnAdd.bind(this)}>Save</Button>
                            <Button onClick={this.handleOnCancel.bind(this)}>Cancel</Button>
                        </Modal.Actions>
                    </Modal>
                }
            </div>
        )
    }

}
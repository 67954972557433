import React from 'react';
import 'semantic-ui-css/semantic.min.css';
import ReportModelBase from '../ReportModelBase';
import { Table } from 'semantic-ui-react';
const moment = require('moment');

class EmploymentHistoryReportModel extends ReportModelBase {

    constructor(props){
        super(props);
        this.searchUrl = "api/Reports/EmploymentHistory";
        this.title = "from Employment History";
        this.exportToExcelUrl = "api/Reports/ExportEmploymentHistoryReportToExcel";
        this.exportToPdfUrl = "api/Reports/ExportEmploymentHistoryReportToPDF";
    }

    toTableRow = (rowData) => {
        return (
            <Table.Row>
                <Table.Cell>{rowData["Employee"]}</Table.Cell>
                <Table.Cell>{rowData["Date Hired"] !== ""? moment(rowData["Date Hired"]).format("MM/DD/YYYY"): ""}</Table.Cell>
                <Table.Cell>{rowData["Regularization Date"] !== ""? moment(rowData["Regularization Date"]).format("MM/DD/YYYY"): ""}</Table.Cell>
                <Table.Cell>{rowData["Resignation Date"] !== ""? moment(rowData["Resignation Date"]).format("MM/DD/YYYY"): ""}</Table.Cell>
                <Table.Cell>{rowData["Tenure"]}</Table.Cell>
                <Table.Cell>{rowData["Reason For Leaving"]}</Table.Cell>
            </Table.Row>
        );
    }

    createColumnHeaders = () => {
        return (
            <Table.Row>
                <Table.HeaderCell>Employee</Table.HeaderCell>
                <Table.HeaderCell>Date Hired</Table.HeaderCell>
                <Table.HeaderCell>Regularization Date</Table.HeaderCell>
                <Table.HeaderCell>Resignation Date</Table.HeaderCell>
                <Table.HeaderCell>Tenure</Table.HeaderCell>
                <Table.HeaderCell>Reason For Leaving</Table.HeaderCell>
            </Table.Row>
        );
    }

}
export default EmploymentHistoryReportModel;
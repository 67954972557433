import React, { Component } from 'react';
import { Tab, Image, Button } from 'semantic-ui-react';
import { isTaskAvailable } from '../utils';
import Alphalist from '../Payroll/GovernmentForms/Alphalist';
import Form2316 from '../Payroll/GovernmentForms/Form2316';
import Form1601C from '../Payroll/GovernmentForms/Form1601C';
import Form1601E from '../Payroll/GovernmentForms/Form1601E';
import Form1603 from '../Payroll/GovernmentForms/Form1603';
import Form1604CF from '../Payroll/GovernmentForms/Form1604CF';
import SSSML1 from '../Payroll/GovernmentForms/SSSML1';
import SSSR3 from '../Payroll/GovernmentForms/SSSR3';
import SSSR5 from '../Payroll/GovernmentForms/SSSR5';
import PagibigMSRF from '../Payroll/GovernmentForms/PagibigMSRF';
import PagibigSTLRF from '../Payroll/GovernmentForms/PagibigSTLRF';

class GovernmentForms extends Component {
    constructor(props) {
        super(props);

        this.state = {
            activeIndexTax: 0,
            activeIndexContribution: 0,
            activeIndexPagibig: 0,
            isTaxShow: true,
            isContributionShow: true,
            isPagibigShow: true,
            formState: {},
        }
    }

    componentWillUnmount = () => {
        this.props.unmountCallback(this.state, "GovernmentForms");
    }

    componentWillMount = () => {
        if (this.props.prevState !== undefined) {
            this.state = this.props.prevState;
        }
    }

    formCallbackState = (state, formName) => {
        var { formState } = this.state;
        formState[formName] = state;
        this.setState({ formState })
    }

    render() {
        var { formState } = this.state;
        const tabSelectionTax = [
            {
                menuItem: 'BIR Form 1601-C', render: () => {
                    return (
                        <Tab.Pane>
                            <Form1601C prevState={formState['Form1601C'] !== undefined ? formState['Form1601C'] : null} unmountCallback={this.formCallbackState} />
                        </Tab.Pane>
                    )
                }
            },
            {
                menuItem: 'BIR Form 1601-E', render: () => {
                    return (
                        <Tab.Pane>
                            <Form1601E prevState={formState['Form1601E'] !== undefined ? formState['Form1601E'] : null} unmountCallback={this.formCallbackState} />
                        </Tab.Pane>
                    )
                }
            },
            {
                menuItem: 'BIR Form 1603', render: () => {
                    return (
                        <Tab.Pane>
                            <Form1603 prevState={formState['Form1603'] !== undefined ? formState['Form1603'] : null} unmountCallback={this.formCallbackState} />
                        </Tab.Pane>
                    )
                }
            },
            {
                menuItem: 'BIR Form 1604CF', render: () => {
                    return (
                        <Tab.Pane>
                            <Form1604CF prevState={formState['Form1604CF'] !== undefined ? formState['Form1604CF'] : null} unmountCallback={this.formCallbackState} />
                        </Tab.Pane>
                    )
                }
            },
            {
                menuItem: 'BIR Form 2316', render: () => {
                    return (
                        <Tab.Pane>
                            <Form2316 prevState={formState['Form2316'] !== undefined ? formState['Form2316'] : null} unmountCallback={this.formCallbackState} />
                        </Tab.Pane>
                    )
                }
            },
            {
                menuItem: 'Alphalist', render: () => {
                    return (
                        <Tab.Pane>
                            <Alphalist prevState={formState['Alphalist'] !== undefined ? formState['Alphalist'] : null} unmountCallback={this.formCallbackState} />
                        </Tab.Pane>
                    )
                }
            },
        ]

        const tabSelectionContribution = [
            {
                menuItem: 'SSS ML1', render: () => {
                    return (
                        <Tab.Pane>
                            <SSSML1 prevState={formState['SSSML1'] !== undefined ? formState['SSSML1'] : null} unmountCallback={this.formCallbackState} />
                        </Tab.Pane>
                    )
                }
            },
            {
                menuItem: 'SSS R3', render: () => {
                    return (
                        <Tab.Pane>
                            <SSSR3 prevState={formState['SSSR3'] !== undefined ? formState['SSSR3'] : null} unmountCallback={this.formCallbackState} />
                        </Tab.Pane>
                    )
                }
            },
            {
                menuItem: 'SSS R5', render: () => {
                    return (
                        <Tab.Pane>
                            <SSSR5 prevState={formState['SSSR5'] !== undefined ? formState['SSSR5'] : null} unmountCallback={this.formCallbackState} />
                        </Tab.Pane>
                    )
                }
            }
        ]

        const tabSelectionPagibig = [
            {
                menuItem: 'Pagibig MSRF', render: () => {
                    return (
                        <Tab.Pane>
                            <PagibigMSRF prevState={formState['MSRF'] !== undefined ? formState['MSRF'] : null} unmountCallback={this.formCallbackState} />
                        </Tab.Pane>
                    )
                }
            },
            {
                menuItem: 'Pagibig STLRF', render: () => {
                    return (
                        <Tab.Pane>
                            <PagibigSTLRF prevState={formState['STLRF'] !== undefined ? formState['STLRF'] : null} unmountCallback={this.formCallbackState} />
                        </Tab.Pane>
                    )
                }
            }
        ]

        return (
            <div>
                <div style={{ display: 'flex' }}>
                    <div >
                        <div style={{ marginBottom: '5px' }}>
                            <Button size='big'
                                //icon='calendar outline'
                                content='BIR FORM'
                                style={{ backgroundColor: 'transparent', border: '1px ridge lightgrey' }}
                                onClick={() => this.setState({ isTaxShow: true, isContributionShow: false, isPagibigShow: false })}
                            />
                        </div>
                        <div style={{ marginBottom: '5px' }}>
                            <Button size='big'
                                //icon='calendar outline'
                                content='SSS FORM'
                                style={{ backgroundColor: 'transparent', border: '1px ridge lightgrey' }}
                                onClick={() => this.setState({ isTaxShow: false, isContributionShow: true })}
                            />
                        </div>
                        <div>
                            <Button size='big'
                                //icon='calendar outline'
                                content='PAGIBIG FORM'
                                style={{ backgroundColor: 'transparent', border: '1px ridge lightgrey' }}
                                onClick={() => this.setState({ isTaxShow: false, isContributionShow: false, isPagibigShow: true })}
                            />
                        </div>
                    </div>
                    {this.state.isTaxShow &&
                        <Tab panes={tabSelectionTax}
                            activeIndex={this.state.activeIndexTax}
                            onTabChange={(e, { activeIndex }) => this.setState({ activeIndexTax: activeIndex })}
                            style={{ width: 'calc(100% - 110px)' }}
                        />
                    }
                    {this.state.isContributionShow &&
                        <Tab panes={tabSelectionContribution}
                            activeIndex={this.state.activeIndexContribution}
                            onTabChange={(e, { activeIndex }) => this.setState({ activeIndexContribution: activeIndex })}
                            style={{ width: 'calc(100% - 110px)' }}
                        />
                    }
                    {this.state.isPagibigShow &&
                        <Tab panes={tabSelectionPagibig}
                            activeIndex={this.state.activeIndexPagibig}
                            onTabChange={(e, { activeIndex }) => this.setState({ activeIndexPagibig: activeIndex })}
                            style={{ width: 'calc(100% - 110px)' }}
                        />
                    }
                </div>

            </div>

        )
    }
}

GovernmentForms.getViewID = () => {
    return "GovernmentForms";
};

GovernmentForms.getCaption = () => {
    return "Government Forms";
};
GovernmentForms.getViewAccessKey = () => {
    return isTaskAvailable("GOVT_FORMS_VIEW") ? true : false
};
GovernmentForms.getIcon = () => {
    return (<Image style={{ height: "24px", width: "24px" }} src="/Icons/Government_Forms.png" avatar />);
};
GovernmentForms.getIconSrc = (size) => //size values: small (50x50), medium(150x150), large(300x300)
{
    return '/Icons/Government_Forms.png'
}

export default GovernmentForms;
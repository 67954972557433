import React, { Component } from 'react';
import { Button, Modal, Table, Checkbox, Form, } from 'semantic-ui-react';
import 'semantic-ui-css/semantic.min.css';
import { ajaxPost } from '../../ajax.js';
import { getDeviceInfoValue } from '../Devices/DeviceTools.js';

var { isTaskAvailable } = require('../../utils');
// var delayTimer = null;

class ScanUnknownDevicesDialog extends Component {
    state = {
        unknownDevices: [],
        infoDialog: false,
        selectedDevice: null,
        allowScanUknownDevices: true,
        allowAddUnknownDevice: true,
        allowDeleteUnknownDevice: true,
        showAll: false,
    }
    componentDidMount() {
        this.setState({ allowAddUnknownDevice: isTaskAvailable("UNKNOWN_DEVICE_CREATE") });
        this.setState({ allowDeleteUnknownDevice: isTaskAvailable("UNKNOWN_DEVICE_DELETE") });
        this.doSearch();
        //setTimeout(this.doSearch, 10000);
        //this.searchUnknownDeviceInterval = setInterval(this.doSearch, 5000, true);
    }
    componentWillMount() {
        //clearInterval(this.searchUnknownDeviceInterval);
    }
    handleClose = () => {
        this.props.onClose();
    }
    onChkCheckChange = (e) => {
        this.setState({ showAll: !this.state.showAll }, function () { this.doSearch() })
    }
    deleteUnknownDevice = (deviceID) => {
        this.setState({ loading: true });

        ajaxPost({
            url: 'api/UnknownDevice/setIgnore',
            data: deviceID,
            startIndex: 0,
            maxData: 100,
            onError: data => {
                var a = data;
            },
            onSuccess: data => {
                var selected = this.state.unknownDevices.filter(item => item.UnknownDeviceId == deviceID)[0];
                if( selected !== undefined ){
                    selected.Ignored = true;
                }
                this.forceUpdate();
                //this.setState({ unknownDevices: this.state.unknownDevices.filter(item => item._id != deviceID) })
            },
            finally: () => {
                this.setState({ loading: false });
            }
        }
        )
    }
    addUnknownDevice = (unknownDevice) => {
        this.setState({ selectedDevice: unknownDevice });
        this.props.onUpdate(unknownDevice);
    }
    //API
    doSearch = () => {
        var exIDs = [];
        this.setState({ loading: true });

        ajaxPost({
            url: 'api/UnknownDevice/listUnknownDevices',
            data: this.state.showAll,
            startIndex: 0,
            maxData: 100,
            onError: data => {
                var a = data;
            },
            onSuccess: data => {
                this.setState({ unknownDevices: data });
                setTimeout( this.doSearch, 10000);
            },
            finally: () => { }
        }
        )
        this.setState({ loading: false });
    }
    render() {
        return (
            <div>
                <Modal open size='large'>
                    <Modal.Header>
                        Unknown Devices
                    </Modal.Header>
                    <Modal.Content>
                        <Checkbox size='mini' label="Show All" onChange={this.onChkCheckChange.bind(this)} checked={this.state.showAll} />
                        <Table stackable>
                            <Table.Header>
                                <Table.Row>
                                    <Table.HeaderCell>IP Address</Table.HeaderCell>
                                    <Table.HeaderCell>Serial Number</Table.HeaderCell>
                                    <Table.HeaderCell>Model</Table.HeaderCell>
                                    <Table.HeaderCell>Firmware Version</Table.HeaderCell>
                                    <Table.HeaderCell>API Version</Table.HeaderCell>
                                    <Table.HeaderCell>&nbsp;</Table.HeaderCell>
                                </Table.Row>
                            </Table.Header>
                            <Table.Body>
                                {
                                    this.state.unknownDevices.map(item => {
                                        return (
                                            <Table.Row>
                                                <Table.Cell>{item.IPAddress}</Table.Cell>
                                                <Table.Cell>{item.SerialNumber + (item.Ignored ? "(Ignored)" : "")}</Table.Cell>
                                                <Table.Cell>{getDeviceInfoValue(item.DeviceInformation,"~DeviceName")}</Table.Cell>
                                                <Table.Cell>{getDeviceInfoValue(item.DeviceInformation,"FWVersion")}</Table.Cell>
                                                <Table.Cell>{item.PushVersion}</Table.Cell>
                                                <Table.Cell>
                                                    <Button disabled={!this.state.allowAddUnknownDevice} primary loading={this.state.loading} onClick={this.addUnknownDevice.bind(this, item)}>Add</Button>
                                                    {!item.Ignored && <Button disabled={!this.state.allowDeleteUnknownDevice} onClick={this.deleteUnknownDevice.bind(this, item.UnknownDeviceId)} >Ignore</Button>}
                                                </Table.Cell>
                                            </Table.Row>
                                        )
                                    })
                                }
                            </Table.Body>
                        </Table>
                    </Modal.Content>
                    <Modal.Actions>
                        <Button basic icon='cancel' content='Close' onClick={this.handleClose.bind(this)}></Button>
                    </Modal.Actions>
                </Modal>
            </div>
        );
    }
}

export default ScanUnknownDevicesDialog
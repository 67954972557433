import React from 'react';
import 'semantic-ui-css/semantic.min.css';
import ReportModelBase from './ReportModelBase';
import { Table } from '../../../node_modules/semantic-ui-react';
// const moment = require('moment');

class TardinessSummaryReportModel extends ReportModelBase {

    constructor(){
        super();
        this.state = {
            isFilterActive: false
        }
        this.searchUrl = "api/Reports/createSummaryReport";
        this.title = "Tardiness Summary Report";
        this.exportToExcelUrl = "api/Reports/ExportSummaryReportToExcel";
        this.exportToExcelDataUrl = "api/Reports/createExportSummaryReportToExcel";
        this.exportToPdfUrl = "api/Reports/createExportSummaryReportToPDF";
    }

    toTableRow = (rowData) => {
        return (
            <Table.Row>
                <Table.Cell>{rowData.EmployeeNo}</Table.Cell>
                <Table.Cell>{rowData.Employee}</Table.Cell>
                <Table.Cell>{rowData.Count}</Table.Cell>
                <Table.Cell>{rowData.Total}</Table.Cell>
                {(
                    this.includeAmount &&
                    <Table.Cell>{rowData.Amount}</Table.Cell>
                )}
            </Table.Row>
        );
    }

    createColumnHeaders = () => {
        return (
            <Table.Row>
                <Table.HeaderCell>Employee No</Table.HeaderCell>
                <Table.HeaderCell>Employee</Table.HeaderCell>
                <Table.HeaderCell>Count</Table.HeaderCell>
                <Table.HeaderCell>Total</Table.HeaderCell>
                {(
                    this.includeAmount &&
                    <Table.HeaderCell>Amount</Table.HeaderCell>
                )}
            </Table.Row>
        );
    }

}

export default TardinessSummaryReportModel;
import React, { Component } from 'react';
import { Pagination, Card, Menu, Select, Button, Icon, Popup, Divider, Checkbox, Image, List, Label, Visibility } from 'semantic-ui-react';
import 'semantic-ui-css/semantic.min.css';
import SelectEmployee from '../Dialogs/SelectEmployeeForDevice';
import SelectEmployeeForDevice from '../Dialogs/SelectEmployeeForDevice';
import MessageBoxYesNo from '../../Commons/MessageBoxYesNo';
import MessageBoxOkOnly from '../../Commons/MessageBoxOkOnly';
import DeviceLoad from './DeviceLoad';
import { ajaxPost, authPost } from '../../ajax.js';
import EnrollDialog from '../Dialogs/EnrollDialog';
import ScanUnknownDevicesDialog from '../Dialogs/ScanUnknownDevicesDialog';
import DateRangePicker from '../Dialogs/DateRangePicker';
import DeviceNameDialog from '../Dialogs/DeviceNameDialog';
import ReactSelect from 'react-select';
import UploadImageForm from './UploadImageForm';
import ViewNotificationDetails from '../../TA/Transactions/Commons/ViewNotificationDetails';
import { getDeviceInfoValue } from './DeviceTools';
import modStyles from './DeviceList.module.css';
var { fnSearchDevice, isTaskAvailable, customStyles, isSpeedface, isNullOrEmpty } = require('../../utils');
var moment = require('moment');
const options = [
    { value: 'ALL', label: 'All Devices' },
    { value: 'OFFLINE', label: 'Offline' },
    { value: 'ONLINE', label: 'Online' }
];

// Updated mapDevices function
function mapDevices(deviceList) {
    var list = [];
    if (deviceList !== undefined) {
        deviceList.forEach(x => {
            const label = `${x.DeviceName} (${x.SerialNumber})`;
            list.push({ 
                label,
                value: x.ZKDeviceId,
             });
        });
    }
    return list;
}

class DeviceList extends Component {

    styles = {
        onlineStatus: {
            color: 'green',
            height:'2em',
        },
        offlineStatus: {
            height:'2em',
            color: 'red'
        },
        extra: {
            backgroundColor: 'gainsboro'
        }
    }
    state = {
        temporaryList: [],
        activePage: 1,
        selectedOption: 'ALL',
        mounted: false,
        list: [],
        search: "",
        totalCount: 0,
        loading: false,
        pageNumber: 0,
        deviceNameDialog: false,
        downloadDialog: false,
        downloadDeviceInfoDialog: false,
        uploadDialog: false,
        uploadComparisonPhotoDialog: false,
        removeDialog: false,
        selectedDevice: null,
        rebootDialog: false,
        deleteDialog: false,
        restoreDialog: false,
        scanDialog: false,
        enrollDialog: false,
        includeInactive: false,
        editForm: false,
        resendDialog: false,
        selected: [],
        selectedAll: false,
        selectedSerialNumbers: [],
        searchDeviceList: [],
        unknownDevice: null,
        allowAdd: true,
        allowEdit: true,
        allowDelete: true,
        allowUploadUsers: true,
        allowDownloadUsers: true,
        allowRemoveUsers: true,
        allowDownloadDeviceInfo: true,
        allowResendTimeEntries: true,
        allowReboot: true,
        allowEnroll: true,
        allowCommands: true,
        selectedDevices: [],
        rowsPerPage: 12,
        messageBoxOkOnly: {
            show: false,
            title: "",
            caption: "",
        },
    }
    componentWillMount = () => {
        if (this.props.prevState != null)
            this.state = this.props.prevState;
        else {
            this.onSearchLoad();
            this.handleClick();
        }

    }

    componentWillUnmount = () => {
        this.props.unmountCallback(this.state, "DeviceManagementModule");
        this.setState({ mounted: false });
    }

    messageBoxOkOnlyOpen = (isOpen, title = "", caption = "") => {
        var messageBoxOkOnly = {
            show: isOpen,
            title: title,
            caption: caption,
        };
        this.setState({ messageBoxOkOnly });
    }

    updateDeviceList = () => {
        const { activePage, rowsPerPage } = this.state;

        this.getList(data => {
            var res = data.count / 12;
            var count = parseInt(res);

            if (res % count > 0)
                count += 1;

            if (count == 0)
                count += 1;

            this.updateDeviceStatus(data, ()=>{
                this.setState({ 
                    pageNumber: count,
                    totalCount: data.count,
                    list: data,
                    selectedAll: false
                })
                    
            });



        }, (activePage - 1) * rowsPerPage, rowsPerPage
        )
    }

    handleFilterChange = (e, { value }) => {

        this.setState({ selectedOption: value }, () => {
            this.updateDeviceList();
        });
    }

    componentDidMount = () => {
        this.setState({ allowAdd: isTaskAvailable("DEVICE_CREATE") });
        this.setState({ allowEdit: isTaskAvailable("DEVICE_MODIFY") });
        this.setState({ allowDelete: isTaskAvailable("DEVICE_INACTIVE_RESTORE") });
        this.setState({ allowUploadUsers: isTaskAvailable("DEVICE_UPLOAD_USER") });
        this.setState({ allowDownloadUsers: isTaskAvailable("DEVICE_DOWNLOAD_USER") });
        this.setState({ allowRemoveUsers: isTaskAvailable("DEVICE_REMOVE_USER") });
        this.setState({ allowReboot: isTaskAvailable("DEVICE_REBOOT") });
        this.setState({ allowEnroll: isTaskAvailable("DEVICE_ENROLL") });
        this.setState({ allowDownloadDeviceInfo: isTaskAvailable("DEVICE_DOWNLOAD_NFORMATION") });
        this.setState({ allowResendTimeEntries: isTaskAvailable("DEVICE_RESEND_TIME_ENTRIES") });
        this.setState({ allowScanUnknownDevices: isTaskAvailable("UNKNOWN_DEVICE_SCAN") });
        this.setState({ mounted: true });
        //this.handleClick()
        setTimeout(this.doAutoStatusUpdate, 1);
    }

    doAutoStatusUpdate = () =>
    {
        this.updateDeviceStatus( this.state.list, () => {
            this.setState({list:this.state.list});

            setTimeout(this.doAutoStatusUpdate, 60* 1000);
        })        
    }

    onCheckboxFilterChange = (event, ctr) => {
        this.setState({ includeInactive: ctr.checked });
    }

    onSearch = (event) => {
        var str = event.target.value;
        this.setState({ search: str })
    }

    getList = (callback, start, max, isSearch, str) => {
        this.setState({ loading: true });

        var {selectedDevices} = this.state;
        var ids = selectedDevices != null ? selectedDevices.map(x => x.value) : null;

        fnSearchDevice(str, data => {
            callback(data);
            this.setState({ loading: false });

        }, start, max, this.state.includeInactive, ids, null, this.state.selectedOption)
    }

    handleClick = () => {
        this.updateDeviceList();

    }

    updateDeviceStatus = (list, callback) => {
        var searhParameter = {
            data: list.map(x => x.SerialNumber),
            url: "api/Device/getDevicesStatus",
            onError: (error) => { },
            onSuccess: (data, sender) => {
                data.map(x => {
                    var item = list.find(y => y.SerialNumber == x.ID);
                    if (item != null) {
                        item.LastUpdate = x.LastUpdate;
                        item.Status = this.parseStatus(item);
                    }
                });

                callback()
/*
                _this.setState({ list: _this.state.list.slice() });

                if (_this.state.mounted && !singleCall)
                    setTimeout(_this.updateDeviceStatus, 10000, _this);
*/
            },
            finally: () => { }
        };
        ajaxPost(searhParameter);
    }

    handlePaginationChange = (e, { activePage }) => {

        if (isNaN(activePage) || activePage === 0) {
            activePage = 1;
        }

        this.setState({ activePage: activePage, loading:true }, () =>{
            this.updateDeviceList();
        });

    }
    toggleSelectAll() {
        let newSelected = {};

        var {selectedOption} = this.state;

        this.state.list.forEach(x => {
            if(!( selectedOption !== "ALL" && x.Status !== selectedOption)){
                newSelected[x.ZKDeviceId] = !this.state.selectedAll;
            }
        });

        this.setState({ selected: newSelected });
        this.setState({ selectedAll: !this.state.selectedAll })
    }
    toggleRow(id) {
        const newSelected = Object.assign({}, this.state.selected);
        newSelected[id] = !this.state.selected[id];
        this.setState({
            selected: newSelected
        });

        var listTrue = [];

        var list = this.getDisplayedDevices();
        list.map(x => {
            if (newSelected[x.ZKDeviceId] === true) {
                listTrue.push(x);
            } 
        });

        this.setState({ selectedAll: listTrue.length === list.length });
    }

    getDisplayedDevices = () => {
        var {selectedOption} = this.state;
        return this.state.list.filter( x => selectedOption == "ALL" || selectedOption == x.Status);        
    }

    getSelectedDevices = (callback, isSync, excludeNoInfo) => {
        var serialNumbers = [];
        const noInfo = [];
        const devices = this.getDisplayedDevices();
     
       devices.filter(x => (excludeNoInfo === true ? isNullOrEmpty(x.DeviceInformation) === false : true )).map(x => {
            if(isNullOrEmpty(x.DeviceInformation))
                noInfo.push(x.SerialNumber);
            if (this.state.selected[x.ZKDeviceId] === true)
                serialNumbers.push(x.SerialNumber);
        });
        if(noInfo.length > 0 && !isSync){
            this.messageBoxOkOnlyOpen(true, "Invalid", "Unable to proceed. Please download Device Information of device(s)");
            return;
        }
        if (serialNumbers.length === 0 && !this.state.selectedAll) {
            this.messageBoxOkOnlyOpen(true, "Invalid", "Unable to proceed. Please select device(s) with device information synced");
            return;
        } 
        callback(serialNumbers);
    }
    //Device functions
    triggerAddNewDevice = (unknown) => {
        this.setState({ unknownDevice: unknown, scanDialog:false, deviceNameDialog: true });
    }

    onScanUnknownDevice = () => {
        this.setState({ scanDialog: true });
    }
    onResend = () => {
        this.getSelectedDevices(sns => {
            this.setState({ selectedSerialNumbers: sns });
            this.setState({ resendDialog: true });
        },false,true);
    }
    onEnroll = (dev) => {
        this.setState({ selectedDevice: dev });
        this.setState({ enrollDialog: true });
    }
    onAdd() {
        this.setState({ selectedDevice: null });
        this.setState({ editForm: true });
    }
    onEdit(dev) {
        this.setState({ selectedDevice: dev });
        this.setState({ editForm: true });
    }
    onDelete = (dev) => {
        this.setState({ selectedDevice: dev });
        if (dev.Deleted) {
            this.setState({ restoreDialog: true });
        } else {
            this.setState({ deleteDialog: true });
        }
    }
    onUpload = () => {
        this.getSelectedDevices(sns => {
            this.setState({ selectedSerialNumbers: sns });
            this.setState({ uploadDialog: true });
        }, false, true);
    }
    onUploadComparisonPhoto = (dev) => {
        this.setState({ selectedDevice: dev, uploadComparisonPhotoDialog: true });
    }
    onDownload = (dev) => {
        this.getSelectedDevices(sns => {
            this.setState({ selectedSerialNumbers: sns });
            this.setState({ downloadDialog: true });
        }, false,true);
    }
    onRemove = (dev) => {
        this.getSelectedDevices(sns => {
            this.setState({ selectedSerialNumbers: sns });
            this.setState({ removeDialog: true });
        },false,true);
    }
    onReboot = (dev) => {
        this.setState({ selectedDevice: dev });
        this.setState({ rebootDialog: true });
    }
    onDownloadInfo = (dev) => {
        this.getSelectedDevices(sns => {
            this.setState({ selectedSerialNumbers: sns });
            this.setState({ downloadDeviceInfoDialog: true });
        },true,false);
    }

    //Closeing Dialogs
    onScanDialogClose = (success) => {
        this.setState({ scanDialog: false });
    }
    onDownloadDialogClose = (success) => {
        this.setState({ downloadDialog: false });
    }
    onUploadDialogClose = (success) => {
        this.setState({ uploadDialog: false });
    }
    onUploadComparisonPhotoDialogClose = (success) => {
        this.setState({ uploadComparisonPhotoDialog: false });
    }
    onRemoveDialogClose = (success) => {
        this.setState({ removeDialog: false });
    }
    onRebootDialogClose = (success) => {
        this.setState({ rebootDialog: false });
    }
    onDeleteDialogClose = (success) => {
        this.setState({ deleteDialog: false });
    }
    onRestoreDialogClose = (success) => {
        this.setState({ restoreDialog: false });
    }
    onEditDialogClose = (success) => {
        this.setState({ editForm: false });
    }
    onEnrollDialogClose = (success) => {
        this.setState({ enrollDialog: false });
    }
    onDownloadDevInfoDialogClose = (success) => {
        this.setState({ downloadDeviceInfoDialog: false });
    }
    onResendDialogClose = (success) => {
        this.setState({ resendDialog: false });
    }
    //DeviceCommand Actions
    onSaveUnknownDevice = (device) => {
        /*
        device.SerialNumber = this.state.unknownDevice.SerialNumber;
        device.IPAddress = this.state.unknownDevice.IPAddress;
        device.PushVersion = this.state.unknownDevice.PushVersion;
        device.Model = this.state.unknownDevice.Model;
        device.FirmwareVersion = this.state.unknownDevice.FirmwareVersion;
        device.Manufacturer = this.state.unknownDevice.Manufacturer;
        device.RegistryCode = "0000000000";
        */
        ajaxPost({
            url: 'api/UnknownDevice/registerUnknownDevice',
            data: {
                UnknownDeviceId: this.state.unknownDevice.UnknownDeviceId,
                Name: device.DeviceName,
                Code: device.DeviceCode,
                Function: device.DeviceFunction,
                TimeZone: "8"
            },
            startIndex: 0,
            maxData: 100,
            onError: data => {
                var a = data;
            },
            onSuccess: data => {
                this.setState({ deviceNameDialog: false });
                if (data.hasOwnProperty("errors") && data.errors.length > 0) {
                    this.messageBoxOkOnlyOpen(true, "Invalid", data.errors[0]);
                    return;
                }
                this.setState({ list: [{...data,LastUpdate:null,Status:''}] });
                if(isNullOrEmpty(data.DeviceInformation)){
                    this.processCommonDeviceCommand([data.SerialNumber], "DOWNLOAD_DEVICEINFO", ()=>{
                        this.setState({ downloadDeviceInfoDialog: false });
                    });
                }
            },
            finally: () => { }
        })
    }
    onRebootAction = async () => {
        var resp = await authPost(
            '/DeviceCommand/Reboot',
            this.state.selectedDevice.SerialNumber
        );
        var result = await resp.text();

        this.setState({ rebootDialog: false });
    }
    onDeleteAction = () => {
        ajaxPost({
            url: 'api/Device/delete',
            data: {
                DeviceID: this.state.selectedDevice.ZKDeviceId,
                
            },
            onSuccess: data => {
                this.setState({ list: this.state.list.filter(item => item != this.state.selectedDevice) })
                this.setState({ selectedDevice: null });
                this.setState({ deleteDialog: false });
                this.setState({ restoreDialog: false });
                
            },
            finally: () => { }
        });
    }

    processCommonDeviceCommand = async (param, cmd, callback) => {
        var resp = await authPost(
            `/DeviceCommand/${cmd.replace("_", "")}`,
            param
        );
        var result = await resp.json();

        var total = (cmd === "DOWNLOAD_DEVICEINFO" || cmd === "RESEND_ATTLOGS") ? 1 : 0;

        var mapped = result.map( item=> {
            var device = this.state.list.find(x=>x.SerialNumber == item.serialNumber)
            return {
                    Type:"DEVICE_COMMAND",
                    Id:item.deviceCommandGroupId,
                    Status:"ACTIVE",
                    Command:cmd,
                    SerialNumber: item.serialNumber,
                    DeviceName: device != undefined ? device.DeviceName : null,
                    Total:total,
                    Progress:0
                }
        });
        global.AddNotificationToPanel( mapped );
        callback();
    }

    onDownloadAction = (accessNumbers) => {
        var param = {
            accessNumbers: accessNumbers,
            serialNumbers: this.state.selectedSerialNumbers
        }
        this.processCommonDeviceCommand( param, "DOWNLOAD_USERS", ()=>{
                this.setState({ downloadDialog: false });
            });
    }

    onUploadAction = (accessNumbers) => {
        var param = {
            accessNumbers: accessNumbers,
            serialNumbers: this.state.selectedSerialNumbers
        }
        this.processCommonDeviceCommand(param, "UPLOAD_USERS", ()=>{
            this.setState({ uploadDialog: false });
        });
    }

    onRemoveAction = (accessNumbers) => {
        var param = {
            accessNumbers: accessNumbers,
            serialNumbers: this.state.selectedSerialNumbers
        }
        this.processCommonDeviceCommand(param, "REMOVE_USERS", ()=>{
            this.setState({ removeDialog: false });
        });
    }

    onDownloadDevInfoAction = () => {
        this.processCommonDeviceCommand(this.state.selectedSerialNumbers, "DOWNLOAD_DEVICEINFO", ()=>{
            this.setState({ downloadDeviceInfoDialog: false });
        });
    }
    onResendLogsAction = (startDate, endDate) => {
        var param = {
            serialNumbers : this.state.selectedSerialNumbers,
            startDate,
            endDate
        }
        this.processCommonDeviceCommand(param, "RESEND_ATTLOGS", ()=>{
            this.setState({ resendDialog: false });
        });
    }
    onEnrollAction = () => {
        this.setState({ enrollDialog: false });
    }
    //extra Functions
    updateComplete = (dev) => {
        this.setState({ editForm: false, list:[{...dev, LastUpdate:null, Status:''}] });
    }

    onDeviceSelected = (value) => {
        this.setState({ selectedDevices: value, search: "" },
            () => { this.handleClick(); this.onSearchLoad() }
        )
    }

    // Updated onDeviceInput function to search for both DeviceName and SerialNumber
    onDeviceInput = (text) => {
    if (this.state.search !== text) {
        clearTimeout(this.searchTimer);
        this.setState({ search: text });
        this.searchTimer = setTimeout(() => this.onSearchLoad(text), 200);
    }}

    onSearchLoad = (str) => {
        /*
        const { activePage, rowsPerPage } = this.state;

        if (isNaN(activePage) || activePage === 0) {
            this.setState({ activePage: 1 });
        }

        this.getList(
            (data) => {
                var selectedDeviceIds = [];
                if (this.state.selectedDevices !== null)
                    selectedDeviceIds = this.state.searchDeviceList.filter(x => this.state.selectedDevices.map(x => x.value).includes(x.ZKDeviceId));

                selectedDeviceIds.forEach(x => { if (!data.map(x => x.ZKDeviceId).includes(x["ZKDeviceId"])) data.push(x) });
                this.setState({ searchDeviceList: data });
            }
            , (activePage - 1) * rowsPerPage, rowsPerPage, true, str
        )
        */
       var {selectedDevice} = this.state;
       var excludedIds = selectedDevice != null ? selectedDevice.map( x=>x.value ) : null;
       var this2 = this;
       fnSearchDevice( 
           str, 
           result => {
                this2.setState( { searchDeviceList : result });
            },
            0,
            50,
            false,
            null,
            excludedIds
       )
    }

    parseStatus(device){
        var dtLimit = moment().subtract(2, 'minutes');
                                
        var latestUpdate = device.LastUpdate != null ? moment.utc(device.LastUpdate).local() : null;
        var status = latestUpdate != null && latestUpdate > dtLimit ? "ONLINE" : "OFFLINE";

        return status;
    }

    render() {
        var styles = this.styles;
        const { selectedOption } = this.state;

        return (
            <div className={modStyles.container}>
                <div style={{ color: "#606060", marginTop: '1%' }}>
                    <h2>Devices</h2>
                </div>
                <br />
                <Menu secondary size='mini' className='w3-hide-small w3-hide-medium'>
                    <Button disabled={!this.state.allowAdd} floated='left' size='mini' onClick={this.onAdd.bind(this)}><Icon name='plus' /> New</Button>
                    <Button size='mini' disabled={!this.state.allowScanUnknownDevices} onClick={this.onScanUnknownDevice.bind(this)}>
                        <Icon name="search" />
                        Scan Network Devices
                    </Button>

                    <div style={{ width: 300, marginRight: '5px', marginLeft: '5px'  }}>
                        <ReactSelect
                            placeholder='Search Device / Serial Number'
                            onChange={this.onDeviceSelected.bind(this)}
                            options={mapDevices(this.state.searchDeviceList)}
                            onInputChange={this.onDeviceInput.bind(this)}
                            styles={customStyles}
                            isMulti
                            isLoading={this.state.loading}
                            value={this.state.selectedDevices}
                        />
                    </div>
                    <Button size='mini' onClick={this.handleClick}>Search</Button>
                    {
                        this.state.totalCount > 0 &&
                        <Pagination size='tiny'
                            floated='right'
                            defaultActivePage={1}
                            pointing
                            secondary
                            firstItem={{ content: <Icon name='angle double left' />, icon: true }}
                            lastItem={{ content: <Icon name='angle double right' />, icon: true }}
                            prevItem={{ content: <Icon name='angle left' />, icon: true }}
                            nextItem={{ content: <Icon name='angle right' />, icon: true }}
                            siblingRange={2}
                            boundaryRange={0}
                            totalPages={this.state.pageNumber}
                            onPageChange={this.handlePaginationChange} />
                    }
                </Menu>
                <div className='w3-hide-large'>
                    <table style={{ width: '100%' }}>
                        <tr>
                            <td style={{ paddingTop: '5px' }}>
                                <Button fluid disabled={!this.state.allowAdd} floated='left' size='mini' onClick={this.onAdd.bind(this)}><Icon name='plus' /> New</Button>
                            </td>
                        </tr>
                        <tr>
                            <td style={{ paddingTop: '5px' }}>
                                <Button fluid size='mini' disabled={!this.state.allowScanUnknownDevices} onClick={this.onScanUnknownDevice.bind(this)}>
                                    <Icon name="search" />
                                    Scan Network Devices
                                </Button>
                            </td>
                        </tr>
                        <tr>
                            <td style={{ paddingTop: '5px'}}>
                            <ReactSelect
                                placeholder='Search Device / Serial Number'
                                onChange={this.onDeviceSelected.bind(this)}
                                options={mapDevices(this.state.searchDeviceList)}
                                onInputChange={this.onDeviceInput.bind(this)}
                                styles={customStyles}
                                isMulti
                                isLoading={this.state.loading}
                                value={this.state.selectedDevices}
                            />
                            </td>
                        </tr>
                        <tr>
                            <td style={{ paddingTop: '5px' }}>
                                <Button fluid size='mini' onClick={this.handleClick}>Search</Button>
                            </td>
                        </tr>

                    </table>
                </div>
                <Divider></Divider>
                <div className=''>
                    <Menu stackable>
                        <Menu.Item disabled={!this.state.allowUploadUsers} size='mini' as={Button} onClick={this.onUpload.bind(this)}>
                            <Icon name='upload' />
                            Upload Users
                        </Menu.Item>
                        <Menu.Item disabled={!this.state.allowDownloadUsers} size='mini' as={Button} onClick={this.onDownload.bind(this)}>
                            <Icon name='download' />
                            Download Users
                        </Menu.Item>
                        <Menu.Item disabled={!this.state.allowRemoveUsers} size='mini' as={Button} onClick={this.onRemove.bind(this)}>
                            <Icon name='remove user' />
                            Remove Users
                        </Menu.Item>
                        <Menu.Item disabled={!this.state.allowDownloadDeviceInfo} size='mini' as={Button} onClick={this.onDownloadInfo.bind(this)}>
                            <Icon name='calculator' />
                            Download Device Information
                        </Menu.Item>
                        <Menu.Item disabled={!this.state.allowResendTimeEntries} size='mini' as={Button} onClick={this.onResend.bind(this)}>
                            <Icon name='send' />
                            Resend Time Entries
                        </Menu.Item>
                        <Menu.Menu position='right'>
                            <Menu.Item>
                                <Select text={selectedOption} options={options} onChange={this.handleFilterChange.bind(this)} />
                            </Menu.Item>
                        </Menu.Menu>
                    </Menu>
                </div>
                <br />
                <div>
                    {
                        this.state.list.length > 0 &&
                        <Checkbox toggle label='Select all' checked={this.state.selectedAll} onChange={() => this.toggleSelectAll()} />
                    }
                    <Divider></Divider>
                    <Card.Group itemsPerRow={3}>
                        {
                            this.getDisplayedDevices().map(item => {
                                var status = item.Status;
                                
                                return (
                                    <Card fluid color={status === "ONLINE" ? 'green' : 'red'}>
                                        <Card.Content>
                                            <Button.Group floated='right'>
                                                <Popup trigger={
                                                    <Button disabled={!this.state.allowEdit || item.Deleted} onClick={this.onEdit.bind(this, item)} icon='edit' circular></Button>}
                                                    content='Edit Device'
                                                    inverted
                                                />
                                                <Popup trigger={
                                                    <Button disabled={!this.state.allowDelete} onClick={this.onDelete.bind(this, item)} icon={item.Deleted ? 'redo' : 'delete'} circular></Button>}
                                                    content={'Delete'}
                                                    inverted
                                                />
                                                <Popup trigger={
                                                    <Button
                                                        disabled={!this.state.allowEnroll || status === 'OFFLINE' || isSpeedface(item.PushVersion)}
                                                        onClick={this.onEnroll.bind(this, item)}
                                                        icon='hand pointer outline'
                                                        circular>
                                                    </Button>
                                                }
                                                    content='Enroll Fingerprint on Device'
                                                    inverted
                                                />
                                                {
                                                    isSpeedface(item.PushVersion) &&
                                                    <Popup trigger={
                                                        <Button
                                                            disabled={!this.state.allowUploadUsers} //  || status === 'OFFLINE' 
                                                            onClick={this.onUploadComparisonPhoto.bind(this, item)}
                                                            icon='image'
                                                            circular>
                                                        </Button>
                                                    }
                                                        content='Upload Comparison Photo'
                                                        inverted
                                                    />
                                                }
                                                <Popup trigger={
                                                    <Button disabled={!this.state.allowReboot || status === 'OFFLINE'} onClick={this.onReboot.bind(this, item)} icon='redo' circular></Button>}
                                                    content='Reboot device'
                                                    inverted
                                                />
                                            </Button.Group>
                                            <Card.Header>
                                                {item.DeviceName}
                                            </Card.Header>
                                            <Card.Meta style={status === 'OFFLINE' ? styles.offlineStatus : styles.onlineStatus}>
                                                {status}
                                            </Card.Meta>
                                            <Card.Description>
                                                <List>
                                                    <List.Item>
                                                        <Label horizontal>Device Code</Label>
                                                        {item.DeviceCode}
                                                    </List.Item>
                                                    <List.Item>
                                                        <Label horizontal>Serial Number</Label>
                                                        {item.SerialNumber}
                                                    </List.Item>
                                                    <List.Item>
                                                        <Label horizontal>IP Address </Label>
                                                        {item.IPAddress}
                                                    </List.Item>
                                                    <List.Item>
                                                        <Label horizontal>Model </Label>
                                                        {getDeviceInfoValue(item.DeviceInformation,"~DeviceName")}
                                                    </List.Item>
                                                    <List.Item>
                                                        <Label horizontal>API Version </Label>
                                                        {item.PushVersion}
                                                    </List.Item>
                                                    <List.Item>
                                                        <Label horizontal>Face Version</Label>
                                                        {getDeviceInfoValue(item.DeviceInformation,"FaceVersion")}
                                                    </List.Item>
                                                    <List.Item>
                                                        <Label horizontal>Fingerprint Version</Label>
                                                        {getDeviceInfoValue(item.DeviceInformation,"FPVersion")}
                                                    </List.Item>
                                                    <List.Item>
                                                        <Label horizontal>User Count</Label>
                                                        {getDeviceInfoValue(item.DeviceInformation,"UserCount")}
                                                    </List.Item>
                                                    <List.Item>
                                                        <Label horizontal>FP Count </Label>
                                                        {getDeviceInfoValue(item.DeviceInformation,"FPCount")}
                                                    </List.Item>
                                                    <List.Item>
                                                        <Label horizontal>Last Online</Label>
                                                        {item.LastUpdate !== null && item.LastUpdate !== undefined ? 
                                                            moment.utc(item.LastUpdate).local().format("YYYY-MM-DD HH:mm") : "N/A"}
                                                    </List.Item>
                                                    {
                                                        isSpeedface(item.PushVersion) &&
                                                        <List.Item >
                                                            <label style={{ color: '#db2828' }}>This device does not support remote enrollment</label>
                                                        </List.Item>
                                                    }
                                                    { 
                                                        isNullOrEmpty(item.DeviceInformation) &&
                                                        <List.Item >
                                                            <label style={{ color: '#db2828' }}>Device Information not yet sync.</label>
                                                        </List.Item> 
                                                    }
                                                </List>
                                            </Card.Description>
                                        </Card.Content>
                                        <Card.Content extra style={this.styles.extra}>
                                            <Checkbox floated='right' toggle checked={this.state.selected[item.ZKDeviceId] === true} onChange={() => this.toggleRow(item.ZKDeviceId)} />
                                        </Card.Content>
                                    </Card>
                                )
                            })
                        }
                    </Card.Group>
                </div>
                {
                    this.state.removeDialog &&
                    <SelectEmployeeForDevice title="Remove Employee Record from Device"
                        action="Remove"
                        onClose={this.onRemoveDialogClose.bind(this)}
                        onAction={this.onRemoveAction.bind(this)}
                        caption="Select the employee(s) you want the fingerprint to be deleted"
                        allowSelectAll={true}
                    />
                }
                {
                    this.state.uploadDialog &&
                    <SelectEmployee title="Upload Employee Record to Device"
                        action="Upload"
                        onClose={this.onUploadDialogClose.bind(this)}
                        onAction={this.onUploadAction.bind(this)}
                        caption="Select the employee(s) you want the fingerprint to be uploaded"
                        allowSelectAll={true}
                    />
                }
                {
                    this.state.uploadComparisonPhotoDialog &&
                    <UploadImageForm
                        onClose={this.onUploadComparisonPhotoDialogClose.bind(this)}
                        device={this.state.selectedDevice}
                        allowSelectAll={true}
                    />
                }
                {
                    this.state.downloadDialog &&
                    <SelectEmployee title="Download Employee Record from Device"
                        action="Download"
                        onClose={this.onDownloadDialogClose.bind(this)}
                        onAction={this.onDownloadAction.bind(this)}
                        caption="Select the employee(s) you want the fingerprint to be downloaded"
                        allowSelectAll={true}
                    />
                }
                {
                    this.state.rebootDialog &&
                    <MessageBoxYesNo title="Reboot Device"
                        action="Reboot"
                        onClose={this.onRebootDialogClose.bind(this)}
                        onAction={this.onRebootAction.bind(this)}
                        caption="Are you sure you want to reboot this device?"
                    />
                }
                {
                    this.state.deleteDialog &&
                    <MessageBoxYesNo title="Delete Device"
                        action="Delete"
                        onClose={this.onDeleteDialogClose.bind(this)}
                        onAction={this.onDeleteAction.bind(this)}
                        caption="Are you sure you want to delete this device?"
                    />
                }
                {
                    this.state.restoreDialog &&
                    <MessageBoxYesNo title="Activate Device"
                        action="Set Active"
                        onClose={this.onRestoreDialogClose.bind(this)}
                        onAction={this.onDeleteAction.bind(this)}
                        caption="Are you sure you want to set this device to ACTIVE?"
                    />
                }
                {
                    this.state.editForm &&
                    <DeviceLoad onClose={this.onEditDialogClose.bind(this)}
                        onUpdateComplete={this.updateComplete.bind(this)}
                        device={JSON.parse(JSON.stringify(this.state.selectedDevice))}
                    />
                }
                {
                    this.state.enrollDialog &&
                    <EnrollDialog
                        device={this.state.selectedDevice}
                        onSuccess={this.onEnrollAction}
                        onClose={this.onEnrollDialogClose.bind(this)}
                        action='Enroll'
                    />
                }
                {
                    this.state.downloadDeviceInfoDialog &&
                    <MessageBoxYesNo title="Download Device Information"
                        action="Download"
                        onClose={this.onDownloadDevInfoDialogClose.bind(this)}
                        onAction={this.onDownloadDevInfoAction.bind(this)}
                        caption="Are you sure you want to download device informations?"
                    />
                }
                {
                    this.state.resendDialog &&
                    <DateRangePicker
                        action="Resend"
                        onClose={this.onResendDialogClose.bind(this)}
                        onAction={this.onResendLogsAction.bind(this)}
                    />
                }
                {
                    this.state.scanDialog &&
                    <ScanUnknownDevicesDialog
                        action="Save"
                        onUpdate={this.triggerAddNewDevice.bind(this)}
                        onClose={this.onScanDialogClose.bind(this)}
                    />
                }
                {
                    this.state.deviceNameDialog &&
                    <DeviceNameDialog
                        action="Save"
                        onClose={() => { this.setState({ deviceNameDialog: false }) }}
                        onAction={this.onSaveUnknownDevice.bind(this)}
                        deviceInfo={this.state.unknownDevice.DeviceInformation}
                    />
                }
                {
                    this.state.messageBoxOkOnly.show &&
                    <MessageBoxOkOnly
                        title={this.state.messageBoxOkOnly.title}
                        caption={this.state.messageBoxOkOnly.caption}
                        onClick={() => { this.messageBoxOkOnlyOpen(false); }}
                    />
                }
            </div>
        )
    }
}

DeviceList.getViewID = () => { return "DeviceManagementModule" };
DeviceList.getCaption = () => { return "Device Management" };
DeviceList.getIconSrc = (size) => {
    return '/Icons/Device_Management.png';
}
DeviceList.getViewAccessKey = () => { return isTaskAvailable("DEVICE_VIEW") };
DeviceList.getIcon = () => {
    return (<Image style={{ "height": "24px", "width": "24px" }} src='/Icons/Device_Management.png' avatar />);
}

DeviceList.init = function (parent) {
    var ViewDeviceCommand = function (status, data, callback) {
        var viewData = {
            "Description": callback.Description,
        };
        parent.setState({ external: null });
        parent.setState({
            external: <ViewNotificationDetails
                Title={callback.Title}
                data={data}
                viewData={viewData}
                callback={callback} />
        });
        ajaxPost(
            {
                url: "api/Notification/markAsRead",
                data: callback.ZKDeviceId,
                onSuccess: data => { },
                finally: () => { }
            }
        )
    }
    parent.FunctionCollection["ViewDeviceCommand"] = ViewDeviceCommand;
}

export default DeviceList
import React from 'react';
import 'semantic-ui-css/semantic.min.css';
import ReportModelBase from './ReportModelBase';
import { Table } from '../../../node_modules/semantic-ui-react';
 const moment = require('moment');
class PayrollAdjustmentReportModel extends ReportModelBase {
    constructor(){
        super();
        this.state = {
            isFilterActive: false
        }
        this.searchUrl = "api/Reports/createPayrollAdjustmentReport";
        this.title = "Payroll Adjustment Report (Summary)";
        this.exportToExcelDataUrl = "api/Reports/createExportPayrollAdjustmentReportToExcel";        
        this.exportToExcelUrl = "api/Reports/ExportPayrollAdjustmentReportToExcel";
        this.exportToPdfUrl = "api/Reports/createPayrollAdjustmentReportToPDF";
    }
    toTableRow = (rowData) => {
        return (
            <Table.Row>
                <Table.Cell>{rowData.EmployeeNo}</Table.Cell>
                <Table.Cell>{rowData.Employee}</Table.Cell>
                <Table.Cell>{rowData.TransactionType}</Table.Cell>
                <Table.Cell>{rowData.Description}</Table.Cell>
                <Table.Cell>{rowData.CutoffDate + ', Cutoff ' + rowData.CutoffNumber}</Table.Cell>
                <Table.Cell>{rowData.Amount}</Table.Cell>
            </Table.Row>
        );
    }
    createColumnHeaders = () => {
        return (
            <Table.Row>
                <Table.HeaderCell>Employee No</Table.HeaderCell>
                <Table.HeaderCell>Employee</Table.HeaderCell>
                <Table.HeaderCell>Transaction Type</Table.HeaderCell>
                <Table.HeaderCell>Description</Table.HeaderCell>
                <Table.HeaderCell>Cut Off</Table.HeaderCell>
                <Table.HeaderCell>Amount</Table.HeaderCell>
            </Table.Row>
        );
    }
}
export default PayrollAdjustmentReportModel;
import React from 'react';
import 'semantic-ui-css/semantic.min.css';
import { Image } from 'semantic-ui-react';
import SimpleTemplate from '../Dashboard/SimpleTemplate';

class Maintenance extends SimpleTemplate {
    constructor(props) {
        super(props);


        this.state.child = (
            <div>
                <Image src='/Images/maintenance_bg.png' style={{height: 'auto', width: '100%'}}></Image>
            </div>
        )
    }

}

export default Maintenance;
import React, { Component } from 'react';
import { Label, Form, Input, Select, Button, Modal, Rail, ButtonGroup, Header, Divider } from 'semantic-ui-react';
import { isTaskAvailable, handleNumeric, empty_id, customStyles } from '../../utils';
import { ajaxPost } from '../../ajax';

class DynamicSideBarForm extends Component {

    constructor() {
        super();
        this.state = {
            selectedGroup: null,
            isSaving: false,
            isNameError: false,
            isCodeError: false,
            show: false
        }
    }

    componentWillMount() {
        this.state.selectedGroup = this.props.selectedGroup;
        this.state.displayGroup = this.props.displayGroup;
        this.state.show = this.props.show;
    }

    handleChange = name => event => {
        if (name === "Name" && event.target.value === "") {
            this.setState({ isNameError: true });
        }
        else if (name === "Name") {
            this.setState({ isNameError: false });
        }

        var selectedGroup = this.state.selectedGroup;
        selectedGroup[name] = event.target.value;
        this.setState({ selectedGroup });
    }

    handleDetailsChange = (name, isNumberOnly) => event => {
        var selectedGroup = this.state.selectedGroup;
        selectedGroup.OtherDetails[name] = isNumberOnly ? handleNumeric(event) : event.target.value;
        this.setState({ selectedGroup });
    }

    handleBankChange = (value) => {
        var selectedGroup = this.state.selectedGroup;
        selectedGroup.OtherDetails["BankID"] = value.value
        this.setState({ selectedGroup });
    }

    transformToTitle(titleField) {
        var abbrvString = "";
        if (titleField.startsWith("TIN", 0) || titleField.startsWith("SSS", 0)) {
            abbrvString = titleField.substring(0, 3) + " ";
            titleField = titleField.substring(3, titleField.length)
        }

        if (titleField.startsWith("HDMF", 0) || titleField.startsWith("HDMF", 0)) {
            abbrvString = titleField.substring(0, 4) + " ";
            titleField = titleField.substring(4, titleField.length)
        }


        titleField = titleField.split("_").join(" ");
        titleField = titleField.replace(/([a-z])([A-Z])/g, '$1 $2');
        titleField = this.toTitleCase(titleField);

        return abbrvString + titleField;
    }
    toTitleCase(str) {
        return str.replace(/\w\S*/g, function (txt) {
            return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
        });
    }
    saveClick = () => {
        if (this.state.isSaving === false) {
            var groupModify = this.state.selectedGroup;
            groupModify.Type = this.state.displayGroup;

            if (groupModify.Name === "") {
                alert("Please fill up required fields.")
                this.setState({ isNameError: true });
                return;
            }

            if (groupModify.OtherDetails.Code === "") {
                alert("Please fill up required fields.")
                this.setState({ isCodeError: true });
                return;
            }

            this.setState({ isSaving: true });
            var isSaveSuccess = true;
            ajaxPost({
                url: 'api/Group/save',
                data: {
                    group: groupModify
                },
                onSuccess: data => {
                    if ("errors" in data && data.errors.length > 0) {
                        alert(data.errors[0])
                        this.setState({ isSaving: false });
                        isSaveSuccess = false;
                        return;
                    }
                    groupModify = data.content;
                    this.props.onClickSave(groupModify);
                },
                finally: () => {
                    if (isSaveSuccess) {
                        this.setState({
                            isSaving: false,
                            show: false
                        })
                    }
                }
            })
        }
    }
    render() {
        return (
            <Modal open={this.state.show} size="tiny">
            <Header content={this.props.sideBarTitle} />
            <Modal.Content>

            
            <div>
                {
                    this.props.displayGroup === 'COMPANY' &&
                    <Form style={{ padding: '20px' }} loading={this.state.isSaving}>
                        <ButtonGroup>
                            <Button color='green' size='tiny' loading={this.state.isSaving} onClick={this.saveClick.bind(this)} >Save</Button>
                            <Button size='tiny' onClick={this.props.onCloseSideBar}>Cancel</Button>
                        </ButtonGroup>
                        <Divider/>
                        <Form.Input fluid style={{ marginTop: '20px' }} labelPosition='left' type='text' placeholder='Name' error={this.state.isNameError}>
                            <Label style={{ width: '120px' }}>Name *</Label>
                            <input style={{ width: '100%' }} value={this.state.selectedGroup.Name} onChange={this.handleChange("Name").bind(this)} />
                        </Form.Input>
                        <Form.Input fluid style={{ marginTop: '5px' }} labelPosition='left' type='text' placeholder='Code'>
                            <Label style={{ width: '120px' }}>Code *</Label>
                            <input style={{ width: '100%' }} value={this.state.selectedGroup.OtherDetails.Code} onChange={this.handleDetailsChange("Code").bind(this)} />
                        </Form.Input>
                        <div style={{ marginTop: '8px' }}>
                            <Form.Input fluid labelPosition='left' type='text' placeholder='Telephone No.'>
                                <Label style={{ width: '120px' }}>Telephone No.</Label>
                                <input style={{ width: '10  0%' }} value={this.state.selectedGroup.OtherDetails.TelephoneNo} onChange={this.handleDetailsChange("TelephoneNo").bind(this)} />
                            </Form.Input>
                            <Form.Input fluid labelPosition='left' type='text' placeholder='Line of Business'>
                                <Label style={{ width: '120px' }}>Line of Business</Label>
                                <input style={{ width: '100%' }} value={this.state.selectedGroup.OtherDetails.LineOfBusiness} onChange={this.handleDetailsChange("LineOfBusiness").bind(this)} />
                            </Form.Input>
                            <Form.Input fluid style={{ marginTop: '5px' }} labelPosition='left' type='text' placeholder='Mobile No.'>
                                <Label style={{ width: '120px' }}>Cellphone No.</Label>
                                <input style={{ width: '100%' }} value={this.state.selectedGroup.OtherDetails.MobileNo} onChange={this.handleDetailsChange("MobileNo").bind(this)} />
                            </Form.Input>
                            <Form.Input fluid style={{ marginTop: '5px' }} labelPosition='left' type='text' placeholder='Email'>
                                <Label style={{ width: '120px' }}>Email</Label>
                                <input style={{ width: '100%' }} value={this.state.selectedGroup.OtherDetails.Email} onChange={this.handleDetailsChange("Email").bind(this)} />
                            </Form.Input>
                            <Form.Input fluid style={{ marginTop: '5px' }} labelPosition='left' type='text' placeholder='Website'>
                                <Label style={{ width: '120px' }}>Website</Label>
                                <input style={{ width: '100%' }} value={this.state.selectedGroup.OtherDetails.Website} onChange={this.handleDetailsChange("Website").bind(this)} />
                            </Form.Input>

                            <div className='w3-row'>
                                <div className='w3-col m6 l6' style={{ marginTop: '5px' }}>
                                    <Form.Input fluid labelPosition='left' type='text' placeholder='SSS'>
                                        <Label style={{ width: '120px' }}>SSS No.</Label>
                                        <input style={{ width: '100%' }} value={this.state.selectedGroup.OtherDetails.SSSNumber} onChange={this.handleDetailsChange("SSSNumber").bind(this)} />
                                    </Form.Input>
                                </div>
                                <div className='w3-col m6 l6' style={{ marginTop: '5px' }}>
                                    <Form.Input fluid labelPosition='left' type='text' placeholder='Branch Code'>
                                        <Label style={{ width: '120px' }}>Branch Code</Label>
                                        <input style={{ width: '100%' }} value={this.state.selectedGroup.OtherDetails.SSSBranchCode} onChange={this.handleDetailsChange("SSSBranchCode").bind(this)} />
                                    </Form.Input>
                                </div>
                            </div>

                            <div className='w3-row'>
                                <div className='w3-col m6 l6' style={{ marginTop: '5px' }}>
                                    <Form.Input fluid labelPosition='left' type='text' placeholder='Philhealth No.'>
                                        <Label style={{ width: '120px' }}>Philhealth No.</Label>
                                        <input style={{ width: '100%' }} value={this.state.selectedGroup.OtherDetails.PhilhealthNumber} onChange={this.handleDetailsChange("PhilhealthNumber").bind(this)} />
                                    </Form.Input>
                                </div>
                                <div className='w3-col m6 l6' style={{ marginTop: '5px' }}>
                                    <Form.Input fluid labelPosition='left' type='text' placeholder='Branch Code'>
                                        <Label style={{ width: '120px' }}>Branch Code</Label>
                                        <input style={{ width: '100%' }} value={this.state.selectedGroup.OtherDetails.PhilhealthBranchCode} onChange={this.handleDetailsChange("PhilhealthBranchCode").bind(this)} />
                                    </Form.Input>
                                </div>
                            </div>

                            <div className='w3-row'>
                                <div className='w3-col m6 l6' style={{ marginTop: '5px' }}>
                                    <Form.Input fluid labelPosition='left' type='text' placeholder='HDMF No.'>
                                        <Label style={{ width: '120px' }}>HDMF No.</Label>
                                        <input style={{ width: '100%' }} value={this.state.selectedGroup.OtherDetails.HDMFNumber} onChange={this.handleDetailsChange("HDMFNumber").bind(this)} />
                                    </Form.Input>
                                </div>
                                <div className='w3-col m6 l6' style={{ marginTop: '5px' }}>
                                    <Form.Input fluid labelPosition='left' type='text' placeholder='Branch Code'>
                                        <Label style={{ width: '120px' }}>Branch Code</Label>
                                        <input style={{ width: '100%' }} value={this.state.selectedGroup.OtherDetails.HDMFBranchCode} onChange={this.handleDetailsChange("HDMFBranchCode").bind(this)} />
                                    </Form.Input>
                                </div>
                            </div>

                            <div className='w3-row'>
                                <div className='w3-col m6 l6' style={{ marginTop: '5px' }}>
                                    <Form.Input fluid labelPosition='left' type='text' placeholder='TIN No.'>
                                        <Label style={{ width: '120px' }}>TIN No.</Label>
                                        <input style={{ width: '100%' }} value={this.state.selectedGroup.OtherDetails.TINNumber} onChange={this.handleDetailsChange("TINNumber").bind(this)} />
                                    </Form.Input>
                                </div>
                                <div className='w3-col m6 l6' style={{ marginTop: '5px' }}>
                                    <Form.Input fluid labelPosition='left' type='text' placeholder='Branch Code'>
                                        <Label style={{ width: '120px' }}>RDO Code</Label>
                                        <input style={{ width: '100%' }} value={this.state.selectedGroup.OtherDetails.RDOCode} onChange={this.handleDetailsChange("RDOCode").bind(this)} />
                                    </Form.Input>
                                </div>
                            </div>

                            <div className='w3-row'>
                                <div className='w3-col m6 l6' style={{ marginTop: '5px' }}>
                                    <Form.Input fluid labelPosition='left' type='text' placeholder='Bank'>
                                        <Label style={{ width: '120px' }}>Bank</Label>
                                        <div style={{ width: '100%' }}>
                                            <Select style={{ width: '100%' }} placeholder='Bank' onChange={this.handleBankChange.bind(this)} />
                                        </div>
                                    </Form.Input>
                                </div>
                                <div className='w3-col m6 l6' style={{ marginTop: '5px' }}>
                                    <Form.Input fluid labelPosition='left' type='text' placeholder='Account No.'>
                                        <Label style={{ width: '120px' }}>Account No.</Label>
                                        <input style={{ width: '100%' }} value={this.state.selectedGroup.OtherDetails.AccountNumber} onChange={this.handleDetailsChange("AccountNumber").bind(this)} />
                                    </Form.Input>
                                </div>
                            </div>
                        </div>

                        <div style={{ marginTop: '8px' }}>
                            <div className='w3-row'>
                                <div className='w3-col m6 l6' style={{ marginTop: '5px' }}>
                                    <Input fluid labelPosition='left' type='text' placeholder='Unit No.'>
                                        <Label style={{ width: '120px' }}>Unit No.</Label>
                                        <input style={{ width: '100%' }} value={this.state.selectedGroup.OtherDetails.UnitNo} onChange={this.handleDetailsChange("UnitNo").bind(this)} />
                                    </Input>
                                </div>
                                <div className='w3-col m6 l6' style={{ marginTop: '5px' }}>
                                    <Input fluid labelPosition='left' type='text' placeholder='Building Name'>
                                        <Label style={{ width: '120px' }}>Building Name</Label>
                                        <input style={{ width: '100%' }} value={this.state.selectedGroup.OtherDetails.BuildingName} onChange={this.handleDetailsChange("BuildingName").bind(this)} />
                                    </Input>
                                </div>
                            </div>

                            <div className='w3-row'>
                                <div className='w3-col m6 l6' style={{ marginTop: '5px' }}>
                                    <Input fluid labelPosition='left' type='text' placeholder='Block No.'>
                                        <Label style={{ width: '120px' }}>House/Lot</Label>
                                        <input style={{ width: '100%' }} value={this.state.selectedGroup.OtherDetails.BlockNo} onChange={this.handleDetailsChange("BlockNo").bind(this)} />
                                    </Input>
                                </div>
                                <div className='w3-col m6 l6' style={{ marginTop: '5px' }}>
                                    <Input fluid labelPosition='left' type='text' placeholder='Street Name'>
                                        <Label style={{ width: '120px' }}>Street Name</Label>
                                        <input style={{ width: '100%' }} value={this.state.selectedGroup.OtherDetails.StreetName} onChange={this.handleDetailsChange("StreetName").bind(this)} />
                                    </Input>
                                </div>
                            </div>

                            <Input fluid labelPosition='left' style={{ marginTop: '5px' }} type='text' placeholder='District'>
                                <Label style={{ width: '150px' }}>Barangay / District</Label>
                                <input style={{ width: '100%' }} value={this.state.selectedGroup.OtherDetails.District} onChange={this.handleDetailsChange("District").bind(this)} />
                            </Input>
                            <Input fluid labelPosition='left' style={{ marginTop: '5px' }} type='text' placeholder='Subdivision'>
                                <Label style={{ width: '150px' }}>Subdivision</Label>
                                <input style={{ width: '100%' }} value={this.state.selectedGroup.OtherDetails.Subdivision} onChange={this.handleDetailsChange("Subdivision").bind(this)} />
                            </Input>
                            <Input fluid labelPosition='left' style={{ marginTop: '5px' }} type='text' placeholder='City'>
                                <Label style={{ width: '150px' }}>City</Label>
                                <input style={{ width: '100%' }} value={this.state.selectedGroup.OtherDetails.City} onChange={this.handleDetailsChange("City").bind(this)} />
                            </Input>
                            <Input fluid labelPosition='left' style={{ marginTop: '5px' }} type='text' placeholder='Province'>
                                <Label style={{ width: '150px' }}>Province</Label>
                                <input style={{ width: '100%' }} value={this.state.selectedGroup.OtherDetails.Province} onChange={this.handleDetailsChange("Province").bind(this)} />
                            </Input>
                            <Input fluid labelPosition='left' style={{ marginTop: '5px' }} type='text' placeholder='Zip Code'>
                                <Label style={{ width: '150px' }}>Zip Code</Label>
                                <input style={{ width: '100%' }} value={this.state.selectedGroup.OtherDetails.ZipCode} onChange={this.handleDetailsChange("ZipCode").bind(this)} />
                            </Input>
                        </div>
                    </Form>
                }
                {
                    this.props.displayGroup !== 'COMPANY' &&
                    <Form style={{ padding: '20px' }}>
                        <Button color='green' size='tiny' loading={this.state.isSaving} onClick={this.saveClick.bind(this)} >Save</Button>
                        <Button size='tiny' onClick={this.props.onCloseSideBar}>Cancel</Button>
                        <Divider/>
                        <Form.Input style={{ marginTop: '5px' }} labelPosition='left' type='text' placeholder='Group Name' error={this.state.isNameError}>
                            <Label style={{ width: '120px' }}>Name *</Label>
                            <input style={{ width: '180px' }} value={this.state.selectedGroup.Name} onChange={this.handleChange("Name").bind(this)} />
                        </Form.Input>
                        {
                            Object.keys(this.state.selectedGroup.OtherDetails).map(det => {
                                return (
                                    <div>
                                        <Form.Input labelPosition='left' placeholder={det} style={{ marginTop: '5px' }} error={det === "Code" && this.state.isCodeError}>
                                            <Label style={{ width: '120px' }}>{this.transformToTitle(det)}{det === "Code" && " *"}</Label>
                                            <input type='text' style={{ width: '180px' }} value={this.state.selectedGroup.OtherDetails[det]} onChange={this.handleDetailsChange(det, det === "Amount" ? true : false).bind(this)} />
                                        </Form.Input>
                                        <br />
                                    </div>
                                )
                            })
                        }
                    </Form>
                }
            </div>
            </Modal.Content>
            </Modal>
        )
    }
}

export default DynamicSideBarForm
import React from "react";
import Select from "react-select";

import {
    Input,
    Tab,
  } from "semantic-ui-react";

  import {
    handleBloodTypeOnKeyPress,
    handleEmailOnKeyPress,
  } from "../../utils";

import { renderHRInfo } from "./EmployeeHRInfo";
  
  var {
    handleNumericOnKeyPress,
    handleNumericOnKeyDown,
    customStyles,
  } = require("../../utils");
  
  
  const CivilStatusType = [
    { label: "Single", value: "SINGLE" },
    { label: "Married", value: "MARRIED" },
    { label: "Separated", value: "SEPARATED" },
    { label: "Widowed", value: "WIDOWED" },
  ];

  const GenderType = [
    { label: "Male", value: "MALE" },
    { label: "Female", value: "FEMALE" },
  ];
  

  
  
export function renderEmployeeDetails(this2)
{
  const {
    _id,
    EmployeeNo,
    LastName,
    FirstName,
    MiddleName,
    Suffix,
    Email,
    UserRoleId,
    LeaveProfileId,
    BirthDate,
    DateHired,
    RegularizationDate,
    ResignationDate,
    Password,
  } = this2.state.selectedEmployee;
  const {
    CivilStatus,
    Height,
    Weight,
    Nationality,
    BloodType,
    Nickname,
    Religion,
    Gender,
    TelephoneNo,
    MobileNo,
  } = this2.state.selectedEmployee;

  
  return (
        <Tab.Pane style={{ border: "none" }}>
          <label
            style={{
              fontWeight: "bold",
              fontSize: "1.3em",
              color: "#606060",
            }}
          >
            Personal Details
          </label>
          {/*<Button onClick={this2.onTestAvatarPath.bind(this)}>Test</Button>*/}
          <hr style={{ color: "#606060", "border-width": "0.8px" }} />
          <p />
          {/* <div className='w3-hide-medium w3-hide-small'> */}
          <div className="w3-row" style={{ width: "100%" }}>
            <div className="w3-col m12 l6">
              <div className="w3-row" style={{ paddingTop: "10px" }}>
                <div
                  className="w3-col s12 m5 l5"
                  style={{ paddingLeft: "10px" }}
                >
                  <label> Last Name *</label>
                </div>
                <div className="w3-col s12 m7 l7">
                  <Input
                    placeholder="Last Name"
                    value={LastName}
                    tabIndex={1}
                    error={this2.state.lastNameIsError}
                    fluid
                    onChange={this2.handleChange.bind(
                      this,
                      "LastName",
                      false,
                      "lastNameIsError"
                    )}
                    ref={(input) => (this2.LastName = input)}
                  />
                </div>
              </div>

              <div className="w3-row" style={{ paddingTop: "10px" }}>
                <div
                  className="w3-col s12 m5 l5"
                  style={{ paddingLeft: "10px" }}
                >
                  <label> First Name *</label>
                </div>
                <div className="w3-col s12 m7 l7">
                  <Input
                    placeholder="First Name"
                    value={FirstName}
                    tabIndex={2}
                    error={this2.state.firstNameIsError}
                    fluid
                    onChange={this2.handleChange.bind(
                      this,
                      "FirstName",
                      false,
                      "firstNameIsError"
                    )}
                    ref={(input) => (this2.FirstName = input)}
                  />
                </div>
              </div>

              <div className="w3-row" style={{ paddingTop: "10px" }}>
                <div
                  className="w3-col s12 m5 l5"
                  style={{ paddingLeft: "10px" }}
                >
                  <label> Middle Name</label>
                </div>
                <div className="w3-col s12 m7 l7">
                  <Input
                    placeholder="Middle Name"
                    value={MiddleName}
                    tabIndex={3}
                    fluid
                    onChange={this2.handleChange.bind(
                      this,
                      "MiddleName",
                      false,
                      ""
                    )}
                  />
                </div>
              </div>

              <div className="w3-row" style={{ paddingTop: "10px" }}>
                <div
                  className="w3-col s12 m5 l5"
                  style={{ paddingLeft: "10px" }}
                >
                  <label> Suffix</label>
                </div>
                <div className="w3-col s12 m7 l7">
                  <Input
                    placeholder="Suffix"
                    value={Suffix}
                    tabIndex={4}
                    fluid
                    onChange={this2.handleChange.bind(
                      this,
                      "Suffix",
                      false,
                      ""
                    )}
                  />
                </div>
              </div>

              <div className="w3-row" style={{ paddingTop: "10px" }}>
                <div
                  className="w3-col s12 m5 l5"
                  style={{ paddingLeft: "10px" }}
                >
                  <label> Nickname</label>
                </div>
                <div className="w3-col s12 m7 l7">
                  <Input
                    placeholder="Nickname"
                    value={Nickname}
                    tabIndex={4}
                    fluid
                    onChange={this2.handleChange.bind(
                      this,
                      "Nickname",
                      false,
                      ""
                    )}
                  />
                </div>
              </div>

              <div className="w3-row" style={{ paddingTop: "10px" }}>
                <div
                  className="w3-col s12 m5 l5"
                  style={{ paddingLeft: "10px" }}
                >
                  <label>Gender</label>
                </div>
                <div className="w3-col s12 m7 l7">
                  <Select
                    isClearable
                    styles={customStyles}
                    //style={{ minWidth: '50px', maxWidth: '200px', width: '100%' }}
                    placeholder="Gender"
                    value={GenderType.find((x) => x.value == Gender)}
                    options={GenderType}
                    onChange={this2.handleDropDownChange("Gender").bind(this)}
                    tabIndex={5}
                  />
                </div>
              </div>

              <div className="w3-row" style={{ paddingTop: "10px" }}>
                <div
                  className="w3-col s12 m5 l5"
                  style={{ paddingLeft: "10px" }}
                >
                  <label>Civil Status</label>
                </div>
                <div className="w3-col s12 m7 l7">
                  <Select
                    isClearable
                    size="mini"
                    styles={customStyles}
                    placeholder="Civil Status"
                    value={CivilStatusType.find(
                      (x) => x.value == CivilStatus
                    )}
                    options={CivilStatusType}
                    onChange={this2.handleDropDownChange("CivilStatus").bind(
                      this
                    )}
                    tabIndex={6}
                  />
                </div>
              </div>

              <div className="w3-row" style={{ paddingTop: "10px" }}>
                <div
                  className="w3-col s12 m5 l5"
                  style={{ paddingLeft: "10px" }}
                >
                  <label> Height (cm) </label>
                </div>
                <div className="w3-col s12 m7 l7">
                  <Input
                    placeholder="Height"
                    value={Height}
                    tabIndex={7}
                    fluid
                    onChange={this2.handleChange.bind(
                      this,
                      "Height",
                      true,
                      ""
                    )}
                    onKeyPress={(e) => {
                      handleNumericOnKeyPress(e);
                    }}
                    onKeyDown={(e) => {
                      handleNumericOnKeyDown(e);
                    }}
                  />
                </div>
              </div>

              <div className="w3-row" style={{ paddingTop: "10px" }}>
                <div
                  className="w3-col s12 m5 l5"
                  style={{ paddingLeft: "10px" }}
                >
                  <label> Weight (kg)</label>
                </div>
                <div className="w3-col s12 m7 l7">
                  <Input
                    placeholder="Weight"
                    value={Weight}
                    tabIndex={8}
                    fluid
                    onChange={this2.handleChange.bind(
                      this,
                      "Weight",
                      true,
                      ""
                    )}
                    onKeyPress={(e) => {
                      handleNumericOnKeyPress(e);
                    }}
                    onKeyDown={(e) => {
                      handleNumericOnKeyDown(e);
                    }}
                  />
                </div>
              </div>
            </div>

            <div className="w3-col m12 l6">
              <div className="w3-row" style={{ paddingTop: "10px" }}>
                <div
                  className="w3-col s12 m5 l5"
                  style={{ paddingLeft: "10px" }}
                >
                  <label> Email</label>
                </div>
                <div className="w3-col s12 m7 l7">
                  <Input
                    fluid
                    placeholder="Email"
                    value={Email}
                    error={this2.state.emailIsError}
                    onChange={this2.handleChange.bind(
                      this,
                      "Email",
                      false,
                      ""
                    )}
                    tabIndex={9}
                    ref={(input) => (this2.Email = input)}
                    onKeyPress={(e) => {
                      handleEmailOnKeyPress(e);
                    }}
                  />
                </div>
              </div>

              <div className="w3-row" style={{ paddingTop: "10px" }}>
                <div
                  className="w3-col s12 m5 l5"
                  style={{ paddingLeft: "10px" }}
                >
                  <label> Telephone No.</label>
                </div>
                <div className="w3-col s12 m7 l7">
                  <Input
                    fluid
                    placeholder="Telephone No."
                    value={TelephoneNo}
                    onChange={this2.handleContactNumberChange.bind(
                      this,
                      "TelephoneNo"
                    )}
                    tabIndex={10}
                    onKeyPress={(e) => {
                      handleNumericOnKeyPress(e);
                    }}
                    onKeyDown={(e) => {
                      handleNumericOnKeyDown(e);
                    }}
                  />
                </div>
              </div>

              <div className="w3-row" style={{ paddingTop: "10px" }}>
                <div
                  className="w3-col s12 m5 l5"
                  style={{ paddingLeft: "10px" }}
                >
                  <label> Mobile No.</label>
                </div>
                <div className="w3-col s12 m7 l7">
                  <Input
                    fluid
                    placeholder="Mobile No."
                    value={MobileNo}
                    onChange={this2.handleContactNumberChange.bind(
                      this,
                      "MobileNo"
                    )}
                    tabIndex={11}
                    onKeyPress={(e) => {
                      handleNumericOnKeyPress(e);
                    }}
                    onKeyDown={(e) => {
                      handleNumericOnKeyDown(e);
                    }}
                  />
                </div>
              </div>

              <div className="w3-row" style={{ paddingTop: "10px" }}>
                <div
                  className="w3-col s12 m5 l5"
                  style={{ paddingLeft: "10px" }}
                >
                  <label> Religion</label>
                </div>
                <div className="w3-col s12 m7 l7">
                  <Input
                    fluid
                    placeholder="Religion"
                    value={Religion}
                    tabIndex={12}
                    onChange={this2.handleChange.bind(
                      this,
                      "Religion",
                      false,
                      ""
                    )}
                  />
                </div>
              </div>

              <div className="w3-row" style={{ paddingTop: "10px" }}>
                <div
                  className="w3-col s12 m5 l5"
                  style={{ paddingLeft: "10px" }}
                >
                  <label> Nationality</label>
                </div>
                <div className="w3-col s12 m7 l7">
                  <Input
                    fluid
                    placeholder="Nationality"
                    value={Nationality}
                    tabIndex={13}
                    onChange={this2.handleChange.bind(
                      this,
                      "Nationality",
                      false,
                      ""
                    )}
                  />
                </div>
              </div>

              <div className="w3-row" style={{ paddingTop: "10px" }}>
                <div
                  className="w3-col s12 m5 l5"
                  style={{ paddingLeft: "10px" }}
                >
                  <label> Blood Type</label>
                </div>
                <div className="w3-col s12 m7 l7">
                  <Input
                    fluid
                    placeholder="Blood Type"
                    value={BloodType}
                    tabIndex={14}
                    onChange={this2.handleChange.bind(
                      this,
                      "BloodType",
                      false,
                      ""
                    )}
                    onKeyPress={(e) => {
                      handleBloodTypeOnKeyPress(e);
                    }}
                    onKeyDown={(e) => {
                      handleNumericOnKeyDown(e);
                    }}
                  />
                </div>
              </div>

              <div className="w3-row" style={{ paddingTop: "10px" }}>
                <div
                  className="w3-col s12 m5 l5"
                  style={{ paddingLeft: "10px" }}
                >
                  <label> Birth Date</label>
                </div>
                <div className="w3-col s12 m7 l7">
                  <Input
                    fluid
                    size="small"
                    value={BirthDate}
                    min="1900-01-01"
                    max="2999-12-31"
                    type="date"
                    onChange={this2.handleChange.bind(
                      this,
                      "BirthDate",
                      false,
                      ""
                    )}
                    tabIndex={15}
                  />
                </div>
              </div>
            </div>
          </div>

          <hr style={{ color: "#606060", "border-width": "0.8px" }} />
          <br />
          <label
            style={{
              fontWeight: "bold",
              fontSize: "1.3em",
              color: "#606060",
            }}
          >
            HR & Account Information
          </label>
          <p/>
          {renderHRInfo(this2)}
        </Tab.Pane>
      )
}
import React from 'react';
import 'semantic-ui-css/semantic.min.css';
import ReportModelBase from '../ReportModelBase';
import { Table } from 'semantic-ui-react';

class TaxAnnualizationReportModel extends ReportModelBase {
    constructor(props) {
        super(props);
        this.searchUrl = "api/Reports/createTaxAnnualizationReport";
        this.title = "Tax Annualization Report";
        this.exportToExcelUrl = "api/Reports/exportTaxAnnualizationReportToExcel";
        this.exportToExcelDataUrl = "api/Reports/createTaxAnnualizationReportToExcel";
        this.exportToPdfUrl = "api/Reports/createTaxAnnualizationReportToPDF";
    }

    toTableRow = (rowData) => {
        return (
            <Table.Row>
                <Table.Cell>{rowData["EmployeeNo"]}</Table.Cell>
                <Table.Cell>{rowData["Employee"]}</Table.Cell>
                <Table.Cell>{rowData["Year"]}</Table.Cell>
                <Table.Cell>{rowData["TaxableIncome"]}</Table.Cell>
                <Table.Cell>{rowData["TaxRange"]}</Table.Cell>
                <Table.Cell>{rowData["AnnualTax"]}</Table.Cell>
                <Table.Cell>{rowData["TotalWTax"]}</Table.Cell>
                <Table.Cell>{rowData["TaxDue"]}</Table.Cell>
                <Table.Cell>{rowData["TaxReturn"]}</Table.Cell>
            </Table.Row>
        );
    }

    createColumnHeaders = () => {
        return (
            <Table.Row>
                <Table.HeaderCell>Employee Number</Table.HeaderCell>
                <Table.HeaderCell>Employee</Table.HeaderCell>
                <Table.HeaderCell>Year</Table.HeaderCell>
                <Table.HeaderCell>Taxable Income</Table.HeaderCell>
                <Table.HeaderCell>Tax Range</Table.HeaderCell>
                <Table.HeaderCell>Annual Tax</Table.HeaderCell>
                <Table.HeaderCell>Withholding Tax</Table.HeaderCell>
                <Table.HeaderCell>Tax Due</Table.HeaderCell>
                <Table.HeaderCell>Tax Return</Table.HeaderCell>
            </Table.Row>
        );
    }
}

export default TaxAnnualizationReportModel;
import React, { Component } from 'react';
import { Message, Card, Icon, Header, Divider, Button, List, Modal } from 'semantic-ui-react';
import 'semantic-ui-css/semantic.min.css';
import { ajaxPost } from '../../ajax.js';
import AnnouncementList from '../Announcements/AnnouncementList';
import { isTaskAvailable } from '../../utils.js';
var moment = require('moment');

class AnnouncementTrail extends Component {
    state = {
        list: [],
        selectedAnnouncement: null,
        viewMoreDialog: false
    }
    styles = {
        cards: {
            background: 'olive',
            color: 'white'
        }
    }

    componentDidMount() {
        setTimeout(this.getList, 1000);
    }

    getList = () => {
        ajaxPost({
            url: 'api/Announcement/listDisplay',
            data: {
                DisplayCount: 4
            },
            onSuccess: data => {
                this.setState({ list: data });
                setTimeout(this.getList, 60000);
            },
            finally: () => { }
        });
    }

    handleViewMore = (selected) => {
        this.setState({ selectedAnnouncement: selected });
        this.setState({ viewMoreDialog: true });
    }

    handleCloseModal = () => {
        this.setState({ viewMoreDialog: false });
    }

    render() {
        return (
            <div>
                <Header>
                    <Icon name='announcement' disabled />
                    <Header.Content>
                        Announcements
                    </Header.Content>
                </Header>
                <Divider />
                {
                    isTaskAvailable("ANNOUNCEMENT_CREATE") &&
                    <Button basic color='olive' size='small' type='submit' style={{ maxHeight: '31px' }} onClick={this.props.onAddAnnouncement}><b>+ Create New</b></Button>
                }
                <br />
                {
                    this.state.list.length === 0 &&
                    <Message color='olive'>
                        <Message.Header>
                            No announcements ...
                        </Message.Header>
                    </Message>
                }
                <Card.Group itemsPerRow={4} stackable style={{ paddingTop: 10 }}>
                    {
                        this.state.list.map(item => {
                            var trimmed = item.Message.substring(0, 50);
                            if (trimmed.length == 50) {
                                trimmed = trimmed + " ...";
                            }

                            return (
                                <Card color='olive' style={{marginLeft: '0.8em', marginRight: '0.8em'}}>
                                    <Card.Content>
                                        <Card.Header>{item.Title}</Card.Header>
                                        <Card.Meta>{moment(item.RecordDate).fromNow()}</Card.Meta>
                                        <Card.Description>
                                            {trimmed} &nbsp;
                                            {
                                                trimmed.length >= 50 &&
                                                <a onClick={this.handleViewMore.bind(this, item)}>View more</a>
                                            }
                                        </Card.Description>
                                    </Card.Content>
                                </Card>
                            )
                        })
                    }
                </Card.Group>
                {
                    this.state.list.length > 0 &&
                    <div>
                        <Divider />
                        <Button color='olive' size='mini' onClick={this.props.onModuleSelect.bind(this, AnnouncementList)}>View All</Button>
                    </div>
                }
                {
                    this.state.selectedAnnouncement !== null &&
                    <Modal open={this.state.viewMoreDialog}>
                        <Modal.Header>
                            {this.state.selectedAnnouncement.Title}
                        </Modal.Header>
                        <Modal.Content>
                            <Modal.Description>
                                <i>{moment(this.state.selectedAnnouncement.RecordDate).fromNow()}</i>
                                <br />
                                <br />
                                {this.state.selectedAnnouncement.Message} &nbsp;
                            </Modal.Description>
                        </Modal.Content>
                        <Modal.Actions>
                            <Button onClick={this.handleCloseModal.bind(this)}>Close</Button>
                        </Modal.Actions>
                    </Modal>
                }
            </div >
        )
    }
}

export default AnnouncementTrail;
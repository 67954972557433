import React, { Component } from 'react';
import {
    Modal,
    Button
} from "../../node_modules/semantic-ui-react";
import { ajaxPost } from "../ajax";

var info = require('./../buildInfo.json');

class WhatsNewDialog extends Component {
    handleClose = () => {
        this.props.onClick();
    };

    componentDidMount() {
        ajaxPost({
            url: "api/Setting/getWhatsNewUpdate",
            data: {
                Version: info.version
            },
            onSuccess: (data) => {
                if (data != null) {
                    const { updates } = this.state;

                    data.Updates.map(x => {
                        updates.push(x);
                    });

                    this.setState({ version: data.Version, updates });
                }
            },
            finally: () => { },
        });
    }

    state = {
        version: "",
        updates: []
    }

    render() {

        return (
            <Modal open size='small'>
                <Modal.Header>What's New?</Modal.Header>
                <Modal.Content>
                    <Modal.Description>
                        <h4>{this.state.version}</h4>
                        {
                            this.state.updates.map(update => {
                                return (
                                    <p>
                                        {
                                            update.IsBold &&
                                            <b>
                                                {update.Description}
                                            </b>
                                        }
                                        {
                                            !update.IsBold &&
                                            update.Description
                                        }
                                    </p>
                                )
                            })
                        }
                    </Modal.Description>
                </Modal.Content>
                <Modal.Actions>
                    <Button basic content="OK" onClick={this.handleClose}></Button>
                </Modal.Actions>
            </Modal>
        )
    }
}
export default WhatsNewDialog;
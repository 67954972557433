import React, { Component } from 'react';
import Select from 'react-select'
import { Tab, Input, Button, Table, Checkbox } from 'semantic-ui-react';
import { ajaxPost } from '../../ajax';
import { handleNumericOnKeyPress, handleNumericOnKeyDown, numToWord } from '../../utils';
import PdfMake from 'pdfmake/build/pdfmake';
import vfsFonts from 'pdfmake/build/vfs_fonts';
import MessageBoxOkOnly from '../../Commons/MessageBoxOkOnly';
const fileDialog = require('file-dialog');

const moment = require('moment');

function mapToViewModel(model, scheduleRow) {
    Object.keys(model).forEach(element => {
        var viewKey = element[0].toLowerCase() + element.slice(1, element.length);
        if (element === 'RemittanceDate' || element === 'DateOfInsuance' || element === 'DateOfExpiry' || element === 'RefundDate') {
            scheduleRow[viewKey] = model[element] === null ? '' : moment(model[element]).format('YYYY-MM-DD')
        }
        else {
            scheduleRow[viewKey] = model[element] === null? '': model[element];
        }
    });

    return scheduleRow;
}

function numberFormat(x) {
    return parseFloat(x).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

const defaultSchedule1 = [
    { remittanceDate: '', bankName: '', taxesWithheld: '', adjustment: '', penalties: '', totalAmount: '', month: 'JAN' },
    { remittanceDate: '', bankName: '', taxesWithheld: '', adjustment: '', penalties: '', totalAmount: '', month: 'FEB' },
    { remittanceDate: '', bankName: '', taxesWithheld: '', adjustment: '', penalties: '', totalAmount: '', month: 'MAR' },
    { remittanceDate: '', bankName: '', taxesWithheld: '', adjustment: '', penalties: '', totalAmount: '', month: 'APR' },
    { remittanceDate: '', bankName: '', taxesWithheld: '', adjustment: '', penalties: '', totalAmount: '', month: 'MAY' },
    { remittanceDate: '', bankName: '', taxesWithheld: '', adjustment: '', penalties: '', totalAmount: '', month: 'JUN' },
    { remittanceDate: '', bankName: '', taxesWithheld: '', adjustment: '', penalties: '', totalAmount: '', month: 'JUL' },
    { remittanceDate: '', bankName: '', taxesWithheld: '', adjustment: '', penalties: '', totalAmount: '', month: 'AUG' },
    { remittanceDate: '', bankName: '', taxesWithheld: '', adjustment: '', penalties: '', totalAmount: '', month: 'SEP' },
    { remittanceDate: '', bankName: '', taxesWithheld: '', adjustment: '', penalties: '', totalAmount: '', month: 'OCT' },
    { remittanceDate: '', bankName: '', taxesWithheld: '', adjustment: '', penalties: '', totalAmount: '', month: 'NOV' },
    { remittanceDate: '', bankName: '', taxesWithheld: '', adjustment: '', penalties: '', totalAmount: '', month: 'DEC' },
]

const defaultSchedule2and3 = [
    { remittanceDate: '', bankName: '', taxesWithheld: '', penalties: '', totalAmount: '', month: 'JAN' },
    { remittanceDate: '', bankName: '', taxesWithheld: '', penalties: '', totalAmount: '', month: 'FEB' },
    { remittanceDate: '', bankName: '', taxesWithheld: '', penalties: '', totalAmount: '', month: 'MAR' },
    { remittanceDate: '', bankName: '', taxesWithheld: '', penalties: '', totalAmount: '', month: 'APR' },
    { remittanceDate: '', bankName: '', taxesWithheld: '', penalties: '', totalAmount: '', month: 'MAY' },
    { remittanceDate: '', bankName: '', taxesWithheld: '', penalties: '', totalAmount: '', month: 'JUN' },
    { remittanceDate: '', bankName: '', taxesWithheld: '', penalties: '', totalAmount: '', month: 'JUL' },
    { remittanceDate: '', bankName: '', taxesWithheld: '', penalties: '', totalAmount: '', month: 'AUG' },
    { remittanceDate: '', bankName: '', taxesWithheld: '', penalties: '', totalAmount: '', month: 'SEP' },
    { remittanceDate: '', bankName: '', taxesWithheld: '', penalties: '', totalAmount: '', month: 'OCT' },
    { remittanceDate: '', bankName: '', taxesWithheld: '', penalties: '', totalAmount: '', month: 'NOV' },
    { remittanceDate: '', bankName: '', taxesWithheld: '', penalties: '', totalAmount: '', month: 'DEC' },
]

const defaultSchedule4 = [
    { remittanceDate: '', bankName: '', taxesWithheld: '', penalties: '', totalAmount: '', month: '1ST QTR' },
    { remittanceDate: '', bankName: '', taxesWithheld: '', penalties: '', totalAmount: '', month: '2ND QTR' },
    { remittanceDate: '', bankName: '', taxesWithheld: '', penalties: '', totalAmount: '', month: '3RD QTR' },
    { remittanceDate: '', bankName: '', taxesWithheld: '', penalties: '', totalAmount: '', month: '4TH QTR' },
]

export default class Form1604CF extends Component {
    constructor(props) {
        super(props);

        this.state = {
            selectedCompany: null,
            year: moment().format('YYYY'),

            isCompanyLoading: false,
            isGenerateLoading: false,
            isExportLoading: false,
            isDataLoading: false,
            isSaving: false,

            companyList: [],
            employeeList: [],

            generatedData: {
                EmployerTin: '',
                RdoCode: '',
                CompanyName: '',
                Address: '',
                TelephoneNo: '',
                ZipCode: '',
                ContributionList: []
            },

            generalInfo: {
                amendedReturn: false, sheetAttachedCount: '', isReleaseRefund: false, refundDate: '', totalAmountOverremittance: '', monthCreditingOverremittance: '',
                accreditedTaxAgent: '', titleAccreditedTaxAgent: '', tinAccreditedTaxAgent: '', taxAgentAccountNumber: '', dateOfInsuance: '', dateOfExpiry: '',
                treasurer: '', titleTreasurer: '', tinTreasurer: ''
            },
            totalSchedule1: { taxesWithheld: '', adjustment: '', penalties: '', totalAmount: '', month: 'TOTAL' },
            totalSchedule2: { taxesWithheld: '', penalties: '', totalAmount: '', month: 'TOTAL' },
            totalSchedule3: { taxesWithheld: '', penalties: '', totalAmount: '', month: 'TOTAL' },
            totalSchedule4: { taxesWithheld: '', penalties: '', totalAmount: '', month: 'TOTAL' },

            schedule1: JSON.parse(JSON.stringify(defaultSchedule1)),
            schedule2: JSON.parse(JSON.stringify(defaultSchedule2and3)),
            schedule3: JSON.parse(JSON.stringify(defaultSchedule2and3)),
            schedule4: JSON.parse(JSON.stringify(defaultSchedule4))
        }

        this.loadCompanies();
    }

    mapToState(data) {
        var { schedule1, schedule2, schedule3, schedule4, generalInfo } = this.state;

        var generalInfo = mapToViewModel(data.GeneralInfo, generalInfo);

        data.Schedule1.forEach(data => {
            var currRow = schedule1.find(x => x.month == data.Month);
            currRow = mapToViewModel(data, currRow)
        });

        data.Schedule2.forEach(data => {
            var currRow = schedule2.find(x => x.month == data.Month);
            currRow = mapToViewModel(data, currRow)
        });

        data.Schedule3.forEach(data => {
            var currRow = schedule3.find(x => x.month == data.Month);
            currRow = mapToViewModel(data, currRow)
        });

        data.Schedule4.forEach(data => {
            var currRow = schedule4.find(x => x.month == data.Month);
            currRow = mapToViewModel(data, currRow)
        });

        this.setState({ schedule1, schedule2, schedule3, schedule4, generalInfo },
            () => {
                this.computeTotalSchedule1();
                this.computeTotalSchedule2();
                this.computeTotalSchedule3();
                this.computeTotalSchedule4();
            })
    }

    componentWillMount = () => {
        if (this.props.prevState !== null)
            this.state = this.props.prevState;
    }

    componentWillUnmount = () => {
        this.props.unmountCallback(this.state, 'FORM1604CF');
    }

    loadCompanies() {
        this.setState({ isCompanyLoading: true })
        ajaxPost({
            url: 'api/group/search',
            data: {
                Search: '',
                startingIndex: 0,
                itemCount: 999,
                Types: ['COMPANY']
            },
            onSuccess: (data) => {
                this.setState({
                    companyList: data.content.map(x => { return ({ label: x.Name, value: x._id }) }),
                    isCompanyLoading: false
                });
            },
            finally: () => { }
        })
    }

    saveData = () => {
        if (this.state.isSaving)
            return;

        const { selectedCompany, year } = this.state;
        if (selectedCompany === null) {
            alert("Please select a company");
            return;
        }

        if (isNaN(year) || year >= 9999 || year < 1980) {
            alert("Invalid year value");
            return;
        }

        this.state.schedule1.forEach(x => {
            if (x.taxesWithheld === '') x.taxesWithheld = null;
            if (x.adjustment === '') x.adjustment = null;
            if (x.penalties === '') x.penalties = null;
        });
        this.state.schedule2.forEach(x => {
            if (x.taxesWithheld === '') x.taxesWithheld = null;
            if (x.penalties === '') x.penalties = null;
        })
        this.state.schedule3.forEach(x => {
            if (x.taxesWithheld === '') x.taxesWithheld = null;
            if (x.penalties === '') x.penalties = null;
        })
        this.state.schedule4.forEach(x => {
            if (x.taxesWithheld === '') x.taxesWithheld = null;
            if (x.penalties === '') x.penalties = null;
        })

        this.setState({ isSaving: true })
        ajaxPost({
            url: 'api/GovernmentForms/SaveForm1604cf',
            data: {
                CompanyId: selectedCompany.value,
                Year: year,
                GeneralInfo: this.state.generalInfo,
                Schedule1: this.state.schedule1,
                Schedule2: this.state.schedule2,
                Schedule3: this.state.schedule3,
                Schedule4: this.state.schedule4
            },
            onSuccess: (data) => {
                this.mapToState(data);

                alert("Successfully Save.")
            },
            finally: () => { this.setState({ isSaving: false }) }
        })
    }

    loadData = () => {
        if (this.state.isDataLoading)
            return;

        const { selectedCompany, year } = this.state;
        if (selectedCompany === null) {
            alert("Please select a company");
            return;
        }

        if (isNaN(year) || year >= 9999 || year < 1980) {
            alert("Invalid year value");
            return;
        }

        this.setState({ isDataLoading: true })
        ajaxPost({
            url: 'api/GovernmentForms/LoadForm1604cf',
            data: {
                companyId: selectedCompany.value,
                year: year,
            },
            onSuccess: (data) => {
                if (data === null)
                    alert("No saved data found.")
                else
                    this.mapToState(data);
            },
            finally: () => { this.setState({ isDataLoading: false }) }
        })
    }

    onTemplateClick = () => {
        if (this.state.exportTemplateLoading)
            return;
        this.setState({ exportTemplateLoading: true })
        window.location = global.ApiHost + "api/GovernmentForms/Form1604cfTemplate"
        this.setState({ exportTemplateLoading: false })
    }

    onImportClick = () => {
        if (this.state.importLoading)
            return;

        fileDialog().then(file => {
            const data = new FormData();
            data.append('file', file[0]);
            data.append('RequestClass', JSON.stringify({
                SessionKey: 'HRIS_SessionId',
                SessionId: global.sessionId
            }
            )
            );
            this.setState({ importLoading: true });
            fetch(global.ApiHost + 'api/GovernmentForms/importForm1604cf', {
                method: 'POST',
                credentials: 'include',
                body: data
            }).then((resp) => {
                this.setState({ importLoading: false });
                return resp.json();
            }).then((json) => {
                var data = JSON.parse(json.JsonData);
                var result = "Successfully Imported";
                if (data.length > 0)
                    result = "Import was interrupted";

                this.setState({
                    openPopUp: true,
                    popUpTitle: "Import Form1604cf",
                    popUpContent:
                        <div>
                            <p>{result}</p>
                            {data.map(err => { return (<span>{err}<br /></span>) })}
                        </div>
                    // (!hasErrors ? data.content : data.errors.map(err => { return(<span>{err}<br /></span>) })),
                })

                this.setState({ importLoading: false });
            })
        });
    }

    onExportPDFClick = () => {
        if (this.state.isExportLoading)
            return;

        if (this.state.selectedCompany === null) {
            alert("Please select a company");
            return;
        }

        if (this.state.selectedPayor === null) {
            alert("Please select a payor");
            return;
        }

        this.setState({ isExportLoading: true });

        ajaxPost({
            url: 'api/governmentforms/Form1604cfExportToPDF',
            data: {
                companyId: this.state.selectedCompany.value
            },
            onSuccess: (data) => {
                const { vfs } = vfsFonts.pdfMake;
                const { generalInfo } = this.state;
                PdfMake.vfs = vfs;

                if (data.TIN.length < 13)
                    data.TIN += '0'.repeat(13 - data.TIN.length)

                var pdfData = {
                    pageSize: { width: 612, height: 828},
                    pageOrientation: 'portrait',
                    content: [
                        {
                            image: data.Image,
                            width: 594,
                            height: 792,
                            absolutePosition: { x: 5, y: 10 }
                        },
                        this.state.year.toString().split('').map((x, index) => {
                            return {
                                text: x,
                                style: { fontSize: 7 },
                                absolutePosition: { x: 133 + index * 11.5, y: 101 }
                            }
                        }),
                        {
                            text: "X",
                            style: { fontSize: 14 },
                            absolutePosition: { x: (generalInfo.amendedReturn? 280: 318), y: 97 }
                        },
                        generalInfo.sheetAttachedCount.toString().padStart(2, '0').split('').map((x, index) => {
                            return {
                                text: x,
                                style: { fontSize: 7 },
                                absolutePosition: { x: 515 + index * 14, y: 101 }
                            }
                        }),
                        data.TIN.toString().split('').splice(0, 3).map((x, index) => {
                            return {
                                text: x,
                                style: { fontSize: 7 },
                                absolutePosition: { x: 67 + index * 11.5, y: 127 }
                            }
                        }),
                        data.TIN.toString().split('').splice(3, 3).map((x, index) => {
                            return {
                                text: x,
                                style: { fontSize: 7 },
                                absolutePosition: { x: 113 + index * 11.5, y: 127 }
                            }
                        }),
                        data.TIN.toString().split('').splice(6, 3).map((x, index) => {
                            return {
                                text: x,
                                style: { fontSize: 7 },
                                absolutePosition: { x: 159 + index * 11.5, y: 127 }
                            }
                        }),
                        data.TIN.toString().split('').splice(9, 4).map((x, index) => {
                            return {
                                text: x,
                                style: { fontSize: 7 },
                                absolutePosition: { x: 205 + index * 11.5, y: 127 }
                            }
                        }),
                        data.RDOCode.toString().split('').map((x, index) => {
                            return {
                                text: x,
                                style: { fontSize: 7 },
                                absolutePosition: { x: 308.5 + index * 11.5, y: 127 }
                            }
                        }),
                        {
                            text: data.LineOfBusiness,
                            style: { fontSize: 7 },
                            absolutePosition: { x: 438.5, y: 127 }
                        },
                        {
                            text: data.CompanyName,
                            style: { fontSize: 6 },
                            absolutePosition: { x: 67, y: 151 }
                        },
                        data.TelephoneNo.toString().split('').map((x, index) => {
                            return {
                                text: x,
                                style: { fontSize: 7 },
                                absolutePosition: { x: 490 + index * 11.2, y: 149 }
                            }
                        }),
                        {
                            text: data.Address,
                            style: { fontSize: 6 },
                            absolutePosition: { x: 67, y: 173 }
                        },
                        data.ZipCode.toString().split('').map((x, index) => {
                            return {
                                text: x,
                                style: { fontSize: 7 },
                                absolutePosition: { x: 512.4 + index * 13.8, y: 171 }
                            }
                        }),
                        {
                            text: 'x',
                            style: { fontSize: 6 },
                            absolutePosition: { x: (generalInfo.isReleaseRefund? 244: 283), y: 191 }
                        },
                        (generalInfo.isReleaseRefund? moment(generalInfo.refundDate).format("MMDDYYYY"): "").split('').map((x, index) => {
                            return {
                                text: x,
                                style: { fontSize: 6 },
                                absolutePosition: { x: 480 + index * 11, y: 188 }
                            }
                        }),
                        {
                            text: generalInfo.totalAmountOverremittance === ""? "": generalInfo.totalAmountOverremittance.toFixed(2),
                            style: { fontSize: 6 },
                            absolutePosition: { x: 185, y: 205 }
                        },
                        {
                            stack: (generalInfo.totalAmountOverremittance === '' || parseInt(generalInfo.totalAmountOverremittance) <= 0 ? []: generalInfo.monthCreditingOverremittance.toString().padStart(2, '0').split('')).map((x, index) => {
                                return {
                                    text: x,
                                    style: { fontSize: 6 },
                                    absolutePosition: { x: 375 + index * 7, y: 205 }
                                }
                            })
                        },
                        {
                            text: 'x',
                            style: { fontSize: 6 },
                            absolutePosition: { x: 427, y: 207 }
                        },
                        this.state.schedule1.map((x, index) => {
                            return {
                                stack: [
                                    { text: x.remittanceDate === ''? '': moment(x.remittanceDate).format('MM/DD/YYYY'), style: { fontSize: 6 }, absolutePosition: { x: 80, y: 249.4 + index * 8 } },
                                    { text: x.bankName, style: { fontSize: 6 }, absolutePosition: { x: 135, y: 249.5 + index * 8 } },
                                    { text: x.taxesWithheld === '' || x.taxesWithheld === null ? '' : numberFormat(x.taxesWithheld), style: { fontSize: 6 }, absolutePosition: { x: 220, y: 249.5 + index * 8 } },
                                    { text: x.adjustment === '' || x.adjustment === null ? '' : numberFormat(x.adjustment), style: { fontSize: 6 }, absolutePosition: { x: 310, y: 249.5 + index * 8 } },
                                    { text: x.penalties === '' || x.penalties === null ? '' : numberFormat(x.penalties), style: { fontSize: 6 }, absolutePosition: { x: 400, y: 249.5 + index * 8 } },
                                    { text: x.totalAmount === '' || x.totalAmount === null ? '' : numberFormat(x.totalAmount), style: { fontSize: 6 }, absolutePosition: { x: 490, y: 249.5 + index * 8 } },
                                ]
                            }
                        }),
                        { text: this.state.totalSchedule1.taxesWithheld === '' ? '' : numberFormat(this.state.totalSchedule1.taxesWithheld), style: { fontSize: 6 }, absolutePosition: { x: 220, y: 345.5 } },
                        { text: this.state.totalSchedule1.adjustment === '' ? '' : numberFormat(this.state.totalSchedule1.adjustment), style: { fontSize: 6 }, absolutePosition: { x: 310, y: 345.5 } },
                        { text: this.state.totalSchedule1.penalties === '' ? '' : numberFormat(this.state.totalSchedule1.penalties), style: { fontSize: 6 }, absolutePosition: { x: 400, y: 345.5 } },
                        { text: this.state.totalSchedule1.totalAmount === '' ? '' : numberFormat(this.state.totalSchedule1.totalAmount), style: { fontSize: 6 }, absolutePosition: { x: 490, y: 345.5 } },

                        this.state.schedule2.map((x, index) => {
                            return {
                                stack: [
                                    { text: x.remittanceDate === ''? '': moment(x.remittanceDate).format('MM/DD/YYYY'), style: { fontSize: 6 }, absolutePosition: { x: 80, y: 377.4 + index * 8 } },
                                    { text: x.bankName, style: { fontSize: 6 }, absolutePosition: { x: 135, y: 377.5 + index * 8 } },
                                    { text: x.taxesWithheld === '' || x.taxesWithheld === null ? '' : numberFormat(x.taxesWithheld), style: { fontSize: 6 }, absolutePosition: { x: 240, y: 377.4 + index * 8 } },
                                    { text: x.penalties === '' || x.penalties === null ? '' : numberFormat(x.penalties), style: { fontSize: 6 }, absolutePosition: { x: 365, y: 377.4 + index * 8 } },
                                    { text: x.totalAmount === '' || x.totalAmount === null ? '' : numberFormat(x.totalAmount), style: { fontSize: 6 }, absolutePosition: { x: 460, y: 377.4 + index * 8 } },
                                ]
                            }
                        }),
                        { text: this.state.totalSchedule2.taxesWithheld === '' ? '' : numberFormat(this.state.totalSchedule2.taxesWithheld), style: { fontSize: 6 }, absolutePosition: { x: 240, y: 473.4 } },
                        { text: this.state.totalSchedule2.penalties === '' ? '' : numberFormat(this.state.totalSchedule2.penalties), style: { fontSize: 6 }, absolutePosition: { x: 365, y: 473.4 } },
                        { text: this.state.totalSchedule2.totalAmount === '' ? '' : numberFormat(this.state.totalSchedule2.totalAmount), style: { fontSize: 6 }, absolutePosition: { x: 460, y: 473.4 } },

                        this.state.schedule3.map((x, index) => {
                            return {
                                stack: [
                                    { text: x.remittanceDate === ''? '': moment(x.remittanceDate).format('MM/DD/YYYY'), style: { fontSize: 6 }, absolutePosition: { x: 80, y: 505.4 + index * 8 } },
                                    { text: x.bankName, style: { fontSize: 6 }, absolutePosition: { x: 135, y: 505.5 + index * 8 } },
                                    { text: x.taxesWithheld === '' || x.taxesWithheld === null ? '' : numberFormat(x.taxesWithheld), style: { fontSize: 6 }, absolutePosition: { x: 240, y: 505.4 + index * 8 } },
                                    { text: x.penalties === '' || x.penalties === null ? '' : numberFormat(x.penalties), style: { fontSize: 6 }, absolutePosition: { x: 365, y: 505.4 + index * 8 } },
                                    { text: x.totalAmount === '' || x.totalAmount === null ? '' : numberFormat(x.totalAmount), style: { fontSize: 6 }, absolutePosition: { x: 460, y: 505.4 + index * 8 } },
                                ]
                            }
                        }),
                        { text: this.state.totalSchedule3.taxesWithheld === '' ? '' : numberFormat(this.state.totalSchedule3.taxesWithheld), style: { fontSize: 6 }, absolutePosition: { x: 240, y: 601.4 } },
                        { text: this.state.totalSchedule3.penalties === '' ? '' : numberFormat(this.state.totalSchedule3.penalties), style: { fontSize: 6 }, absolutePosition: { x: 365, y: 601.5 } },
                        { text: this.state.totalSchedule3.totalAmount === '' ? '' : numberFormat(this.state.totalSchedule3.totalAmount), style: { fontSize: 6 }, absolutePosition: { x: 460, y: 601.5 } },

                        this.state.schedule4.map((x, index) => {
                            return {
                                stack: [
                                    { text: x.remittanceDate === ''? '': moment(x.remittanceDate).format('MM/DD/YYYY'), style: { fontSize: 6 }, absolutePosition: { x: 80, y: 633.4 + index * 8 } },
                                    { text: x.bankName, style: { fontSize: 6 }, absolutePosition: { x: 135, y: 633.5 + index * 8 } },
                                    { text: x.taxesWithheld === '' || x.taxesWithheld === null ? '' : numberFormat(x.taxesWithheld), style: { fontSize: 6 }, absolutePosition: { x: 240, y: 633.4 + index * 8 } },
                                    { text: x.penalties === '' || x.penalties === null ? '' : numberFormat(x.penalties), style: { fontSize: 6 }, absolutePosition: { x: 365, y: 633.4 + index * 8 } },
                                    { text: x.totalAmount === '' || x.totalAmount === null ? '' : numberFormat(x.totalAmount), style: { fontSize: 6 }, absolutePosition: { x: 460, y: 633.4 + index * 8 } },
                                ]
                            }
                        }),
                        { text: this.state.totalSchedule4.taxesWithheld === '' ? '' : numberFormat(this.state.totalSchedule4.taxesWithheld), style: { fontSize: 6 }, absolutePosition: { x: 240, y: 665.4 } },
                        { text: this.state.totalSchedule4.penalties === '' ? '' : numberFormat(this.state.totalSchedule4.penalties), style: { fontSize: 6 }, absolutePosition: { x: 365, y: 665.4 } },
                        { text: this.state.totalSchedule4.totalAmount === '' ? '' : numberFormat(this.state.totalSchedule4.totalAmount), style: { fontSize: 6 }, absolutePosition: { x: 460, y: 665.4 } },
                        {
                            absolutePosition: { x: 1, y: 696 },
                            alignment: 'center',
                            columns: [
                                {
                                    text: '',
                                    width: 55
                                },
                                {
                                    width: 215,
                                    text: generalInfo.accreditedTaxAgent,
                                    style: { fontSize: 6 },
                                    
                                },
                                {
                                    width: 170,
                                    text: generalInfo.treasurer,
                                    style: { fontSize: 6 },
                                    
                                },
                                {
                                    width: '*',
                                    text: ''
                                }
                            ]
                        },
                        {
                            absolutePosition: { x: 1, y: 729 },
                            alignment: 'center',
                            columns: [
                                {
                                    text: '',
                                    width: 45
                                },
                                {
                                    width: 120,
                                    text: generalInfo.titleAccreditedTaxAgent,
                                    style: { fontSize: 6 },
                                    
                                },
                                {
                                    width: 122,
                                    text: generalInfo.tinAccreditedTaxAgent,
                                    style: { fontSize: 6 },
                                    
                                },
                                {
                                    width: 140,
                                    text: generalInfo.titleTreasurer,
                                    style: { fontSize: 6 },
                                    
                                },
                            ]
                        },
                        {
                            absolutePosition: { x: 1, y: 744 },
                            alignment: 'center',
                            columns: [
                                {
                                    text: '',
                                    width: 45
                                },
                                {
                                    width: 120,
                                    text: generalInfo.taxAgentAccountNumber,
                                    style: { fontSize: 6 },
                                    
                                },
                                {
                                    width: 70,
                                    text: (generalInfo.dateOfInsuance == ''? '': moment(generalInfo.dateOfInsuance).format('MM/DD/YYYY')),
                                    style: { fontSize: 6 },
                                    
                                },
                                {
                                    width: 50,
                                    text: (generalInfo.dateOfExpiry == ''? '': moment(generalInfo.dateOfExpiry).format('MM/DD/YYYY')),
                                    style: { fontSize: 6 },
                                    
                                },
                                {
                                    width: 140,
                                    text: generalInfo.tinTreasurer,
                                    style: { fontSize: 6 },
                                    
                                },
                            ]
                        },
                        
                    ]
                }

                PdfMake.createPdf(pdfData).open();
            },
            finally: () => {
                this.setState({ isExportLoading: false });
            }
        });
    }

    onGenerateClick = () => {
        if (this.state.isGenerateLoading)
            return;

        this.setState({
            isGenerateLoading: true,
            schedule1: JSON.parse(JSON.stringify(defaultSchedule1)),
            schedule2: JSON.parse(JSON.stringify(defaultSchedule2and3)),
            schedule3: JSON.parse(JSON.stringify(defaultSchedule2and3)),
            schedule4: JSON.parse(JSON.stringify(defaultSchedule4)),
            totalSchedule1: { taxesWithheld: '', adjustment: '', penalties: '', totalAmount: '', month: 'TOTAL' },
            totalSchedule2: { taxesWithheld: '', penalties: '', totalAmount: '', month: 'TOTAL' },
            totalSchedule3: { taxesWithheld: '', penalties: '', totalAmount: '', month: 'TOTAL' },
            totalSchedule4: { taxesWithheld: '', penalties: '', totalAmount: '', month: 'TOTAL' }
        });

        this.generateForm1604cf((processId) =>
            ajaxPost({
                url: 'api/governmentForms/GetForm1604cf',
                data: processId,
                onSuccess: (data) => {
                    var { schedule1 } = this.state;
                    schedule1.forEach((x, index) => {
                        x.taxesWithheld = data.content[index + 1].toFixed(2);
                    });
                    this.setState({ schedule1, isGenerateLoading: false }, this.computeTotalSchedule1);
                },
                finally: () => { }
            })
        )
    }

    generateForm1604cf = (callback) => {
        if (this.state.selectedCompany === null) {
            this.setState({ isGenerateLoading: false });
            alert("Please select a company");
            return;
        }

        if (this.state.year === '' || this.state.year > 9999 || this.state.year < 1990) {
            this.setState({ isGenerateLoading: false });
            alert("Invalid value of year");
            return;
        }

        ajaxPost({
            url: 'api/governmentforms/GenerateForm1604cf',
            data: {
                companyId: this.state.selectedCompany.value,
                year: this.state.year
            },
            onSuccess: (value) => {
                this.checkStatus(value, callback);
            },
            finally: () => { }
        })
    }

    checkStatus(processId, callback) {
        ajaxPost({
            data: { processId: processId },
            url: 'api/BackgroundProcess/GetStatus',
            onSuccess: data => {
                if (data.Status != "PROCESSING") {
                    callback(processId)
                }
                else {
                    this.checkStatus(processId, callback);
                }
            },
            finally: () => { }
        })
    }

    onTextChange = (field, e) => {
        this.setState({ [field]: e.target.value });
    }

    onInfoChange = (field, e) => {
        var { generatedData } = this.state;
        generatedData[field] = e.target.value;

        this.setState({ generatedData });
    }

    createTextField(label, field, isNumericOnly) {
        var { generatedData } = this.state;
        return (
            <div className="w3-col s12 m5 l5" style={{ paddingTop: '10px' }}>
                <div className="w3-col s12 m5 l5" style={{ paddingLeft: '10px' }}>
                    <label> {label} </label>
                </div>
                {isNumericOnly === false && <div className="w3-col s12 m7 l7">
                    <Input style={{ border: '1px solid gray' }} fluid
                        value={generatedData[field]} onChange={this.onInfoChange.bind(this, field)} />
                </div>}
                {isNumericOnly === true && <div className="w3-col s12 m7 l7">
                    <Input style={{ border: '1px solid gray' }} fluid
                        value={generatedData[field]} onChange={this.onInfoChange.bind(this, field)}
                        onKeyPress={(e) => handleNumericOnKeyPress(e)}
                        onKeyDown={(e) => handleNumericOnKeyDown(e)}
                    />
                </div>}
            </div>
        )
    }

    onChangeSchedule1 = (model, field, e) => {
        var { schedule1 } = this.state;
        model[field] = e.target.value;

        var currIndex = schedule1.findIndex(x => x.month === model.month)
        schedule1[currIndex] = model;
        this.setState({ schedule1 }, this.computeTotalSchedule1);
    }

    onChangeSchedule2 = (model, field, e) => {
        var { schedule2 } = this.state;
        model[field] = e.target.value;

        var currIndex = schedule2.findIndex(x => x.month === model.month)
        schedule2[currIndex] = model;
        this.setState({ schedule2 }, this.computeTotalSchedule2);
    }
    onChangeSchedule3 = (model, field, e) => {
        var { schedule3 } = this.state;
        model[field] = e.target.value;

        var currIndex = schedule3.findIndex(x => x.month === model.month)
        schedule3[currIndex] = model;
        this.setState({ schedule3 }, this.computeTotalSchedule3);
    }
    onChangeSchedule4 = (model, field, e) => {
        var { schedule4 } = this.state;
        model[field] = e.target.value;

        var currIndex = schedule4.findIndex(x => x.month === model.month)
        schedule4[currIndex] = model;
        this.setState({ schedule4 }, this.computeTotalSchedule4);
    }

    computeTotalSchedule1 = () => {
        var { totalSchedule1, schedule1 } = this.state;
        totalSchedule1.totalAmount = 0
        totalSchedule1.taxesWithheld = 0;
        totalSchedule1.adjustment = 0;
        totalSchedule1.penalties = 0;

        var isTaxesWithheldEmpty = true;
        var isAdjustmentEmpty = true;
        var isPenaltiesEmpty = true;
        schedule1.forEach(x => {
            x.totalAmount = 0;
            if (!(isNaN(x.taxesWithheld) || x.taxesWithheld === '' || x.taxesWithheld === null)) {
                totalSchedule1.taxesWithheld += parseFloat(x.taxesWithheld)
                x.totalAmount += parseFloat(x.taxesWithheld);
            }
            if (!(isNaN(x.adjustment) || x.adjustment === '' || x.adjustment === null)) {
                totalSchedule1.adjustment += parseFloat(x.adjustment)
                x.totalAmount += parseFloat(x.adjustment);
            }
            if (!(isNaN(x.penalties) || x.penalties === '' || x.penalties === null)) {
                totalSchedule1.penalties += parseFloat(x.penalties)
                x.totalAmount += parseFloat(x.penalties);
            }

            if (!(x.taxesWithheld === '' || x.taxesWithheld === null)) {
                isTaxesWithheldEmpty = false;
            }
            if (!(x.adjustment === '' || x.adjustment === null)) {
                isAdjustmentEmpty = false;
            }

            if (!(x.penalties === '' || x.penalties === null)) {
                isPenaltiesEmpty = false;
            }

            if ((x.taxesWithheld === '' || x.taxesWithheld === null) && (x.adjustment === '' || x.adjustment === null) && (x.penalties === '' || x.penalties === null))
                x.totalAmount = '';

            if (x.totalAmount !== '')
                totalSchedule1.totalAmount += parseFloat(x.totalAmount);
        });

        if (isTaxesWithheldEmpty)
            totalSchedule1.taxesWithheld = '';
        if (isAdjustmentEmpty)
            totalSchedule1.adjustment = '';
        if (isPenaltiesEmpty)
            totalSchedule1.penalties = '';

        if (isTaxesWithheldEmpty && isAdjustmentEmpty && isPenaltiesEmpty)
            totalSchedule1.totalAmount = '';

        this.setState({ schedule1, totalSchedule1 })
    }

    computeTotalSchedule2 = () => {
        var { totalSchedule2, schedule2 } = this.state;
        totalSchedule2.totalAmount = 0
        totalSchedule2.taxesWithheld = 0;
        totalSchedule2.penalties = 0;

        var isTaxesWithheldEmpty = true;
        var isPenaltiesEmpty = true;
        schedule2.forEach(x => {
            x.totalAmount = 0;
            if (!(isNaN(x.taxesWithheld) || x.taxesWithheld === '' || x.taxesWithheld === null)) {
                totalSchedule2.taxesWithheld += parseFloat(x.taxesWithheld)
                x.totalAmount += x.taxesWithheld;
            }
            if (!(isNaN(x.penalties) || x.penalties === '' || x.penalties === null)) {
                totalSchedule2.penalties += parseFloat(x.penalties)
                x.totalAmount += x.penalties;
            }

            if (!(x.taxesWithheld === '' || x.taxesWithheld === null)) {
                isTaxesWithheldEmpty = false;
            }

            if (!(x.penalties === '' || x.penalties === null)) {
                isPenaltiesEmpty = false;
            }

            if ((x.taxesWithheld === '' || x.taxesWithheld === null) && (x.penalties === '' || x.penalties === null))
                x.totalAmount = '';

            if (x.totalAmount !== '')
                totalSchedule2.totalAmount += x.totalAmount;
        });

        if (isTaxesWithheldEmpty)
            totalSchedule2.taxesWithheld = '';
        if (isPenaltiesEmpty)
            totalSchedule2.penalties = '';

        if (isTaxesWithheldEmpty && isPenaltiesEmpty)
            totalSchedule2.totalAmount = '';

        this.setState({ schedule2, totalSchedule2 })
    }

    computeTotalSchedule3 = () => {
        var { totalSchedule3, schedule3 } = this.state;
        totalSchedule3.totalAmount = 0
        totalSchedule3.taxesWithheld = 0;
        totalSchedule3.penalties = 0;

        var isTaxesWithheldEmpty = true;
        var isPenaltiesEmpty = true;
        schedule3.forEach(x => {
            x.totalAmount = 0;
            if (!(isNaN(x.taxesWithheld) || x.taxesWithheld === '' || x.taxesWithheld === null)) {
                totalSchedule3.taxesWithheld += parseFloat(x.taxesWithheld)
                x.totalAmount += x.taxesWithheld;
            }
            if (!(isNaN(x.penalties) || x.penalties === '' || x.penalties === null)) {
                totalSchedule3.penalties += parseFloat(x.penalties)
                x.totalAmount += x.penalties;
            }

            if (!(x.taxesWithheld === '' || x.taxesWithheld === null)) {
                isTaxesWithheldEmpty = false;
            }

            if (!(x.penalties === '' || x.penalties === null)) {
                isPenaltiesEmpty = false;
            }

            if ((x.taxesWithheld === '' || x.taxesWithheld === null) && (x.penalties === '' || x.penalties === null))
                x.totalAmount = '';

            if (x.totalAmount !== '')
                totalSchedule3.totalAmount += x.totalAmount;
        });

        if (isTaxesWithheldEmpty)
            totalSchedule3.taxesWithheld = '';
        if (isPenaltiesEmpty)
            totalSchedule3.penalties = '';

        if (isTaxesWithheldEmpty && isPenaltiesEmpty)
            totalSchedule3.totalAmount = '';

        this.setState({ schedule3, totalSchedule3 })
    }

    computeTotalSchedule4 = () => {
        var { totalSchedule4, schedule4 } = this.state;
        totalSchedule4.totalAmount = 0
        totalSchedule4.taxesWithheld = 0;
        totalSchedule4.penalties = 0;

        var isTaxesWithheldEmpty = true;
        var isPenaltiesEmpty = true;
        schedule4.forEach(x => {
            x.totalAmount = 0;
            if (!(isNaN(x.taxesWithheld) || x.taxesWithheld === '' || x.taxesWithheld === null)) {
                totalSchedule4.taxesWithheld += parseFloat(x.taxesWithheld)
                x.totalAmount += x.taxesWithheld;
            }
            if (!(isNaN(x.penalties) || x.penalties === '' || x.penalties === null)) {
                totalSchedule4.penalties += parseFloat(x.penalties)
                x.totalAmount += x.penalties;
            }

            if (!(x.taxesWithheld === '' || x.taxesWithheld === null)) {
                isTaxesWithheldEmpty = false;
            }

            if (!(x.penalties === '' || x.penalties === null)) {
                isPenaltiesEmpty = false;
            }

            if ((x.taxesWithheld === '' || x.taxesWithheld === null) && (x.penalties === '' || x.penalties === null))
                x.totalAmount = '';

            if (x.totalAmount !== '')
                totalSchedule4.totalAmount += x.totalAmount;
        });

        if (isTaxesWithheldEmpty)
            totalSchedule4.taxesWithheld = '';
        if (isPenaltiesEmpty)
            totalSchedule4.penalties = '';

        if (isTaxesWithheldEmpty && isPenaltiesEmpty)
            totalSchedule4.totalAmount = '';

        this.setState({ schedule4, totalSchedule4 })
    }

    onInfoCheckboxChange = (field, e) => {
        var { generalInfo } = this.state;
        generalInfo[field] = !generalInfo[field];
        this.setState({ generalInfo })
    }

    onInfoTextChange = (field, e) => {
        var { generalInfo } = this.state;
        generalInfo[field] = e.target.value;
        this.setState({ generalInfo })
    }

    createInput(property, list, isNumberOnly, onChange) {
        if(isNumberOnly) {
            return <Input fluid value={list[property]} 
                onChange={onChange.bind(this, list, property)}
                onKeyPress={(e) => handleNumericOnKeyPress(e)}
                onKeyDown={(e) => handleNumericOnKeyDown(e)}
            />
        }
        return <Input fluid value={list[property]} 
            onChange={this.onChangeSchedule1.bind(this, list, property)}
        />
    }

    render() {
        const tabSelection = [
            {
                menuItem: 'Information', pane:
                    <Tab.Pane attached={false} style={{ border: '0px none', width: '100%' }}>
                        <div style={{ border: '0.5px solid', borderStyle: 'dashed', width: '100%' }}>
                            <div style={{ marginLeft: '10px', marginTop: '10px' }}>
                                <label>Amended Return</label>
                                <Checkbox style={{ marginLeft: '5px' }} checked={this.state.generalInfo.amendedReturn} onChange={this.onInfoCheckboxChange.bind(this, 'amendedReturn')} />
                            </div>
                            <div style={{ marginLeft: '10px', marginTop: '10px' }}>
                                <label>No. of Sheets Attached</label>
                                <Input style={{ marginLeft: '5px' }} value={this.state.generalInfo.sheetAttachedCount} onChange={this.onInfoTextChange.bind(this, 'sheetAttachedCount')}
                                    onKeyPress={(e) => handleNumericOnKeyPress(e)}
                                    onKeyDown={(e) => handleNumericOnKeyDown(e)}
                                />
                            </div>
                            <div style={{ marginLeft: '10px', marginTop: '10px' }}>
                                <div>
                                    <label>In case of overwithholding/overremittance after the year end adjustment on compensation, have you released the refund/s to your employee/s?</label>
                                    <Checkbox style={{ marginLeft: '5px' }} checked={this.state.generalInfo.isReleaseRefund} onChange={this.onInfoCheckboxChange.bind(this, 'isReleaseRefund')} />
                                </div>
                                <div>
                                    <label>If yes, specify date of refund</label>
                                    <Input style={{ marginLeft: '5px' }} type='date' value={this.state.generalInfo.refundDate} disabled={!this.state.generalInfo.isReleaseRefund} onChange={this.onInfoTextChange.bind(this, 'refundDate')} />
                                </div>

                            </div>

                            <div style={{ marginLeft: '10px', marginTop: '10px' }}>
                                <div>
                                    <label>Total Amount of Overremittance on Tax Withheld under Compensations</label>
                                    <Input style={{ marginLeft: '5px' }} value={this.state.generalInfo.totalAmountOverremittance} onChange={this.onInfoTextChange.bind(this, 'totalAmountOverremittance')}
                                        onKeyPress={(e) => handleNumericOnKeyPress(e)}
                                        onKeyDown={(e) => handleNumericOnKeyDown(e)}
                                    />
                                </div>
                                <div>
                                    <label>Month of First Crediting of Overremittance</label>
                                    <Input style={{ marginLeft: '5px' }} value={this.state.generalInfo.monthCreditingOverremittance} onChange={this.onInfoTextChange.bind(this, 'monthCreditingOverremittance')}
                                        onKeyPress={(e) => handleNumericOnKeyPress(e)}
                                        onKeyDown={(e) => handleNumericOnKeyDown(e)}
                                    />
                                </div>
                            </div>
                        </div>

                        <div style={{ border: '0.5px solid', borderStyle: 'dashed', width: '100%', marginTop: '30px' }}>
                            <div style={{ marginLeft: '10px', marginTop: '10px' }}>
                                <label>President / Vice President / Principal Officer / Accredited Tax Agent / Authorized Representative / Tax Payer</label>
                                <div style={{ maxWidth: '400px', minWidth: '200px', width: '100%' }}>
                                    <Input fluid value={this.state.generalInfo.accreditedTaxAgent} onChange={this.onInfoTextChange.bind(this, 'accreditedTaxAgent')} />
                                </div>

                            </div>

                            <div style={{ marginLeft: '10px', marginTop: '10px' }}>
                                <label style={{ width: '180px', display: 'inline-block' }}>Title / Position of Signatory</label>
                                <Input style={{ marginLeft: '5px' }} value={this.state.generalInfo.titleAccreditedTaxAgent} onChange={this.onInfoTextChange.bind(this, 'titleAccreditedTaxAgent')} />
                            </div>

                            <div style={{ marginLeft: '10px', marginTop: '10px' }}>
                                <label style={{ width: '180px', display: 'inline-block' }}>TIN of Signatory</label>
                                <Input style={{ marginLeft: '5px' }} value={this.state.generalInfo.tinAccreditedTaxAgent} onChange={this.onInfoTextChange.bind(this, 'tinAccreditedTaxAgent')} />
                            </div>

                            <div style={{ marginLeft: '10px', marginTop: '10px' }}>
                                <label style={{ width: '180px' }}>Tax Agent Acc. No. / Atty's Roll No. (if applicable)</label>
                                <Input style={{ marginLeft: '5px' }} value={this.state.generalInfo.taxAgentAccountNumber} onChange={this.onInfoTextChange.bind(this, 'taxAgentAccountNumber')} />
                            </div>

                            <div style={{ marginLeft: '10px', marginTop: '10px' }}>
                                <label style={{ width: '180px', display: 'inline-block' }}>Date of Insuance</label>
                                <Input style={{ marginLeft: '5px' }} type='date' value={this.state.generalInfo.dateOfInsuance} onChange={this.onInfoTextChange.bind(this, 'dateOfInsuance')} />
                            </div>

                            <div style={{ marginLeft: '10px', marginTop: '10px' }}>
                                <label style={{ width: '180px', display: 'inline-block' }}> Date of Expiry</label>
                                <Input style={{ marginLeft: '5px' }} type='date' value={this.state.generalInfo.dateOfExpiry} onChange={this.onInfoTextChange.bind(this, 'dateOfExpiry')} />
                            </div>
                        </div>

                        <div style={{ border: '0.5px solid', borderStyle: 'dashed', width: '100%', marginTop: '30px' }}>
                            <div style={{ marginLeft: '10px', marginTop: '10px' }}>
                                <label>Treasurer / Assistant Treasurer</label>
                                <div style={{ maxWidth: '400px', minWidth: '200px', width: '100%' }}>
                                    <Input fluid value={this.state.generalInfo.treasurer} onChange={this.onInfoTextChange.bind(this, 'treasurer')} />
                                </div>
                            </div>

                            <div style={{ marginLeft: '10px', marginTop: '10px' }}>
                                <label style={{ width: '180px', display: 'inline-block' }}>Title / Position of Signatory</label>
                                <Input style={{ marginLeft: '5px' }} value={this.state.generalInfo.titleTreasurer} onChange={this.onInfoTextChange.bind(this, 'titleTreasurer')} />
                            </div>

                            <div style={{ marginLeft: '10px', marginTop: '10px' }}>
                                <label style={{ width: '180px', display: 'inline-block' }}>TIN of Signatory</label>
                                <Input style={{ marginLeft: '5px' }} value={this.state.generalInfo.tinTreasurer} onChange={this.onInfoTextChange.bind(this, 'tinTreasurer')} />
                            </div>
                        </div>

                    </Tab.Pane>
            },
            {
                menuItem: 'Schedule 1', pane:
                    <Tab.Pane attached={false} style={{ border: '0px none', width: '100%' }}>
                        <Table fixed striped padded size='small'>
                            <Table.Header>
                                <Table.Row style={{ 'backgroundColor': 'gainsboro', 'fontSize': '11px', padding: '0px' }}>
                                    <Table.HeaderCell ></Table.HeaderCell>
                                    <Table.HeaderCell >Date Remittance</Table.HeaderCell>
                                    <Table.HeaderCell >Bank Name</Table.HeaderCell>
                                    <Table.HeaderCell >Taxes Withheld</Table.HeaderCell>
                                    <Table.HeaderCell >Adjustment</Table.HeaderCell>
                                    <Table.HeaderCell >Penalties</Table.HeaderCell>
                                    <Table.HeaderCell >Total</Table.HeaderCell>
                                </Table.Row>
                            </Table.Header>
                            <Table.Body>
                                {this.state.schedule1.map(list => {
                                    return (
                                        <Table.Row>
                                            <Table.Cell verticalAlign='top'>{list.month}</Table.Cell>
                                            <Table.Cell verticalAlign='top'><Input fluid type='date' value={list.remittanceDate} onChange={this.onChangeSchedule1.bind(this, list, 'remittanceDate', false)} /></Table.Cell>
                                            <Table.Cell verticalAlign='top'>{this.createInput('bankName', list, false, this.onChangeSchedule1)}</Table.Cell>
                                            <Table.Cell verticalAlign='top'>{this.createInput('taxesWithheld', list, true, this.onChangeSchedule1)}</Table.Cell>
                                            <Table.Cell verticalAlign='top'>{this.createInput('adjustment', list, true, this.onChangeSchedule1)}</Table.Cell>
                                            <Table.Cell verticalAlign='top'>{this.createInput('penalties', list, true, this.onChangeSchedule1)}</Table.Cell>
                                            <Table.Cell verticalAlign='top'>{list.totalAmount}</Table.Cell>
                                        </Table.Row>
                                    )
                                })}
                                <Table.Row>
                                    <Table.Cell verticalAlign='top'>Total</Table.Cell>
                                    <Table.Cell verticalAlign='top'> <span /> </Table.Cell>
                                    <Table.Cell verticalAlign='top'> <span /> </Table.Cell>
                                    <Table.Cell verticalAlign='top'>{this.state.totalSchedule1.taxesWithheld}</Table.Cell>
                                    <Table.Cell verticalAlign='top'>{this.state.totalSchedule1.adjustment}</Table.Cell>
                                    <Table.Cell verticalAlign='top'>{this.state.totalSchedule1.penalties}</Table.Cell>
                                    <Table.Cell verticalAlign='top'>{this.state.totalSchedule1.totalAmount}</Table.Cell>
                                </Table.Row>
                            </Table.Body>
                        </Table>
                    </Tab.Pane>
            },
            {
                menuItem: 'Schedule 2', pane:
                    <Tab.Pane attached={false} style={{ border: '0px none', width: '100%' }}>
                        <Table fixed striped padded size='small'>
                            <Table.Header>
                                <Table.Row style={{ 'backgroundColor': 'gainsboro', 'fontSize': '11px', padding: '0px' }}>
                                    <Table.HeaderCell ></Table.HeaderCell>
                                    <Table.HeaderCell >Date Remittance</Table.HeaderCell>
                                    <Table.HeaderCell >Bank Name</Table.HeaderCell>
                                    <Table.HeaderCell >Taxes Withheld</Table.HeaderCell>
                                    <Table.HeaderCell >Penalties</Table.HeaderCell>
                                    <Table.HeaderCell >Total</Table.HeaderCell>
                                </Table.Row>
                            </Table.Header>
                            <Table.Body>
                                {this.state.schedule2.map(list => {
                                    return (
                                        <Table.Row>
                                            <Table.Cell verticalAlign='top'>{list.month}</Table.Cell>
                                            <Table.Cell verticalAlign='top'><Input fluid type='date' value={list.remittanceDate} onChange={this.onChangeSchedule2.bind(this, list, 'remittanceDate', false)} /></Table.Cell>
                                            <Table.Cell verticalAlign='top'>{this.createInput('bankName', list, false, this.onChangeSchedule2)}</Table.Cell>
                                            <Table.Cell verticalAlign='top'>{this.createInput('taxesWithheld', list, true, this.onChangeSchedule2)}</Table.Cell>
                                            <Table.Cell verticalAlign='top'>{this.createInput('penalties', list, true, this.onChangeSchedule2)}</Table.Cell>
                                            <Table.Cell verticalAlign='top'>{list.totalAmount}</Table.Cell>
                                        </Table.Row>
                                    )
                                })}
                                <Table.Row>
                                    <Table.Cell verticalAlign='top'>Total</Table.Cell>
                                    <Table.Cell verticalAlign='top'> <span /> </Table.Cell>
                                    <Table.Cell verticalAlign='top'> <span /> </Table.Cell>
                                    <Table.Cell verticalAlign='top'>{this.state.totalSchedule2.taxesWithheld}</Table.Cell>
                                    <Table.Cell verticalAlign='top'>{this.state.totalSchedule2.penalties}</Table.Cell>
                                    <Table.Cell verticalAlign='top'>{this.state.totalSchedule2.totalAmount}</Table.Cell>
                                </Table.Row>
                            </Table.Body>
                        </Table>
                    </Tab.Pane>
            },
            {
                menuItem: 'Schedule 3', pane:
                    <Tab.Pane attached={false} style={{ border: '0px none', width: '100%' }}>
                        <Table fixed striped padded size='small'>
                            <Table.Header>
                                <Table.Row style={{ 'backgroundColor': 'gainsboro', 'fontSize': '11px', padding: '0px' }}>
                                    <Table.HeaderCell ></Table.HeaderCell>
                                    <Table.HeaderCell >Date Remittance</Table.HeaderCell>
                                    <Table.HeaderCell >Bank Name</Table.HeaderCell>
                                    <Table.HeaderCell >Taxes Withheld</Table.HeaderCell>
                                    <Table.HeaderCell >Penalties</Table.HeaderCell>
                                    <Table.HeaderCell >Total</Table.HeaderCell>
                                </Table.Row>
                            </Table.Header>
                            <Table.Body>
                                {this.state.schedule3.map(list => {
                                    return (
                                        <Table.Row>
                                            <Table.Cell verticalAlign='top'>{list.month}</Table.Cell>
                                            <Table.Cell verticalAlign='top'><Input fluid type='date' value={list.remittanceDate} onChange={this.onChangeSchedule3.bind(this, list, 'remittanceDate', false)} /></Table.Cell>
                                            <Table.Cell verticalAlign='top'>{this.createInput('bankName', list, false, this.onChangeSchedule3)}</Table.Cell>
                                            <Table.Cell verticalAlign='top'>{this.createInput('taxesWithheld', list, true, this.onChangeSchedule3)}</Table.Cell>
                                            <Table.Cell verticalAlign='top'>{this.createInput('penalties', list, true, this.onChangeSchedule3)}</Table.Cell>
                                            <Table.Cell verticalAlign='top'>{list.totalAmount}</Table.Cell>
                                        </Table.Row>
                                    )
                                })}
                                <Table.Row>
                                    <Table.Cell verticalAlign='top'>Total</Table.Cell>
                                    <Table.Cell verticalAlign='top'> <span /> </Table.Cell>
                                    <Table.Cell verticalAlign='top'> <span /> </Table.Cell>
                                    <Table.Cell verticalAlign='top'>{this.state.totalSchedule3.taxesWithheld}</Table.Cell>
                                    <Table.Cell verticalAlign='top'>{this.state.totalSchedule3.penalties}</Table.Cell>
                                    <Table.Cell verticalAlign='top'>{this.state.totalSchedule3.totalAmount}</Table.Cell>
                                </Table.Row>
                            </Table.Body>
                        </Table>
                    </Tab.Pane>
            },
            {
                menuItem: 'Schedule 4', pane:
                    <Tab.Pane attached={false} style={{ border: '0px none', width: '100%' }}>
                        <Table fixed striped padded size='small'>
                            <Table.Header>
                                <Table.Row style={{ 'backgroundColor': 'gainsboro', 'fontSize': '11px', padding: '0px' }}>
                                    <Table.HeaderCell ></Table.HeaderCell>
                                    <Table.HeaderCell >Date Remittance</Table.HeaderCell>
                                    <Table.HeaderCell >Bank Name</Table.HeaderCell>
                                    <Table.HeaderCell >Taxes Withheld</Table.HeaderCell>
                                    <Table.HeaderCell >Penalties</Table.HeaderCell>
                                    <Table.HeaderCell >Total</Table.HeaderCell>
                                </Table.Row>
                            </Table.Header>
                            <Table.Body>
                                {this.state.schedule4.map(list => {
                                    return (
                                        <Table.Row>
                                            <Table.Cell verticalAlign='top'>{list.month}</Table.Cell>
                                            <Table.Cell verticalAlign='top'><Input fluid type='date' value={list.remittanceDate} onChange={this.onChangeSchedule4.bind(this, list, 'remittanceDate', false)} /></Table.Cell>
                                            <Table.Cell verticalAlign='top'>{this.createInput('bankName', list, false, this.onChangeSchedule4)}</Table.Cell>
                                            <Table.Cell verticalAlign='top'>{this.createInput('taxesWithheld', list, true, this.onChangeSchedule4)}</Table.Cell>
                                            <Table.Cell verticalAlign='top'>{this.createInput('penalties', list, true, this.onChangeSchedule4)}</Table.Cell>
                                            <Table.Cell verticalAlign='top'>{list.totalAmount}</Table.Cell>
                                        </Table.Row>
                                    )
                                })}
                                <Table.Row>
                                    <Table.Cell verticalAlign='top'>Total</Table.Cell>
                                    <Table.Cell verticalAlign='top'> <span /> </Table.Cell>
                                    <Table.Cell verticalAlign='top'> <span /> </Table.Cell>
                                    <Table.Cell verticalAlign='top'>{this.state.totalSchedule4.taxesWithheld}</Table.Cell>
                                    <Table.Cell verticalAlign='top'>{this.state.totalSchedule4.penalties}</Table.Cell>
                                    <Table.Cell verticalAlign='top'>{this.state.totalSchedule4.totalAmount}</Table.Cell>
                                </Table.Row>
                            </Table.Body>
                        </Table>
                    </Tab.Pane>
            },
        ]

        return (
            <div>
                <h1>Form 1604 CF</h1>

                <div style={{ display: 'flex', overflowY: 'auto', height: window.innerHeight - 203 }}>
                    <div style={{ marginRight: '10px', width: 300 }}>
                        <div>
                            <label>Company</label>
                            <Select value={this.state.selectedCompany} isLoading={this.state.isCompanyLoading} options={this.state.companyList} onChange={(e) => this.setState({ selectedCompany: e })} />
                        </div>

                        <div style={{ marginTop: 5 }}>
                            <label>Year</label>
                            <Input fluid value={this.state.year}
                                onChange={(e) => this.setState({ year: e.target.value })}
                                onKeyPress={(e) => handleNumericOnKeyPress(e)}
                                onKeyDown={(e) => handleNumericOnKeyDown(e)}
                            />
                        </div>

                        <div style={{ display: 'flex' }}>
                            <div style={{ width: '50%', paddingRight: '5px' }}>
                                <Button style={{ marginTop: 10 }} content='Generate' floated='right' loading={this.state.isGenerateLoading} fluid onClick={this.onGenerateClick.bind(this)} />
                            </div>
                            <div style={{ width: '50%', paddingLeft: '5px' }}>
                                <Button style={{ marginTop: 10 }} content='Export To PDF' floated='right' loading={this.state.isExportLoading} fluid onClick={this.onExportPDFClick.bind(this)} />
                            </div>
                        </div>
                        <div style={{ display: 'flex' }}>
                            <div style={{ width: '50%', paddingRight: '5px' }}>
                                <Button style={{ marginTop: 10 }} content='Save' floated='right' loading={this.state.isSaving} fluid onClick={this.saveData.bind(this)} />
                            </div>
                            <div style={{ width: '50%', paddingLeft: '5px' }}>
                                <Button style={{ marginTop: 10 }} content='Load' floated='right' loading={this.state.isDataLoading} fluid onClick={this.loadData.bind(this)} />
                            </div>
                        </div>

                        <div style={{ display: 'flex' }}>
                            <div style={{ width: '50%', paddingRight: '5px' }}>
                                <Button style={{ marginTop: 10 }} content='Template' floated='right' loading={this.state.exportTemplateLoading} fluid onClick={this.onTemplateClick.bind(this)} />
                            </div>
                            <div style={{ width: '50%', paddingLeft: '5px' }}>
                                <Button style={{ marginTop: 10 }} content='Import' floated='right' loading={this.state.importLoading} fluid onClick={this.onImportClick.bind(this)} />
                            </div>
                        </div>
                    </div>

                    <div style={{ width: window.innerWidth - 600, height: window.innerHeight - 203, overflowX: 'auto', overflowY: 'auto' }}>
                        <Tab renderActiveOnly={false} style={{ minWidth: 700 }} menu={{ color: 'green', tabular: false, attached: false }}
                            panes={tabSelection} activeIndex={this.state.activeIndex} onTabChange={this.handleTabChange} />
                    </div>

                    {this.state.openPopUp && <MessageBoxOkOnly title={this.state.popUpTitle} caption={this.state.popUpContent} onClick={() => this.setState({ openPopUp: false })} />}
                </div>
            </div>
        )
    }
}
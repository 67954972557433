import React from 'react';
import 'semantic-ui-css/semantic.min.css';
import ReportModelBase from '../ReportModelBase';
import { Table } from 'semantic-ui-react';
const moment = require('moment');

class LoanSummaryReport extends ReportModelBase {

    constructor(props){
        super(props);
        this.searchUrl = "api/Reports/createLoanSummaryReport";
        this.title = "Loan Summary Report";
        this.exportToExcelUrl = "api/Reports/ExportLoanSummaryReportToExcel";
        this.exportToExcelDataUrl = "api/Reports/createExportLoanSummaryReportToExcel";
        this.exportToPdfUrl = "api/Reports/createLoanSummaryReportToPDF";
    }

    toTableRow = (rowData) => {
        return (
            <Table.Row>
                <Table.Cell>{rowData.EmployeeNo}</Table.Cell>
                <Table.Cell>{rowData.Employee}</Table.Cell>
                <Table.Cell>{rowData.LoanType}</Table.Cell>
                <Table.Cell>{moment(rowData.StartDate).format("YYYY MMMM") + ", Cutoff " + moment(rowData.StartDate).format("D")}</Table.Cell>
                <Table.Cell>{moment(rowData.EndDate).format("YYYY MMMM") + ", Cutoff " + moment(rowData.EndDate).format("D")}</Table.Cell>
                <Table.Cell>{rowData.PrincipalAmount}</Table.Cell>
                <Table.Cell>{rowData.PayableAmount}</Table.Cell>
                <Table.Cell>{rowData.Amortization}</Table.Cell>
                <Table.Cell>{rowData.Remarks}</Table.Cell>
            </Table.Row>
        );
    }

    createColumnHeaders = () => {
        return (
            <Table.Row>
                <Table.HeaderCell>No.</Table.HeaderCell>
                <Table.HeaderCell>Name</Table.HeaderCell>
                <Table.HeaderCell>Loan Type</Table.HeaderCell>
                <Table.HeaderCell>Start Date</Table.HeaderCell>
                <Table.HeaderCell>Aprox. End Date</Table.HeaderCell>
                <Table.HeaderCell>Principal Amount</Table.HeaderCell>
                <Table.HeaderCell>Payable Amount</Table.HeaderCell>
                <Table.HeaderCell>Amortization</Table.HeaderCell>
                <Table.HeaderCell>Remarks</Table.HeaderCell>
            </Table.Row>
        );
    }

}

export default LoanSummaryReport;
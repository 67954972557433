import React, {useEffect, useState} from 'react';
import { render } from 'react-dom';
import { Image, Icon,Modal,Button } from 'semantic-ui-react';
import { ajaxGet } from '../ajax'; 
const crypto = require("crypto");


class EmployeeImage extends React.Component 
{
    constructor(props) {
        super(props);
        this.state = {
            src: "data:,",
            isView: false,
            hover: false
        };
    }

    guidToBytes(guid){
        var bytes = [];
        guid.split('-').map((number, index) => {
            var bytesInChar = index < 3 ? number.match(/.{1,2}/g).reverse() :  number.match(/.{1,2}/g);
            bytesInChar.map((byte) => { bytes.push(parseInt(byte, 16));})
        });
        return bytes;
    }

    componentDidUpdate(prevProps, prevState){
        if( prevProps !== null && this.props.runtime !== prevProps.runtime && this.props.runtime !== undefined ){
            this.setState({src:this.props.runtime})
        }
    }

    loadImage( runtime, fileName, empId )
    {
     const folderName = this.props.isPhoto ? "photos" : "avatars";
        if( runtime !== null && runtime !== undefined && runtime !== '' )
        {
            this.setState({src:this.state.runtime});
        }
        else if( fileName === 'default.jpg'){
            this.setState({src:"/Images/default.png" });
        }
        else if( fileName.lastIndexOf(".bin") !== -1)
        { 
            var url = global.StorageAccountUrl + "/uploads/" + folderName + "/" + fileName;
            window.fetch( url )
                .then( resp => {
                    if( !resp.ok ){
                        this.setState({src:"/Images/default.png" });
                    }
                    else{
                        return resp.arrayBuffer();
                    }
                })
                .then( buffer => {
                    const data = new Uint8Array(buffer);
                    const iv = data.slice(0, 16);
                    const cipher = new Buffer(data.slice(16), 'hex');
    
                    const hash = crypto.createHash('sha256');
                    const binKey = this.guidToBytes(empId);
                    const key = hash.update( binKey ).digest('hex');
    
                    const bufferKey = new Buffer(key, 'hex');
    
                    const decrypter = crypto.createDecipheriv("aes-256-cbc", bufferKey, iv);
                    let plain =  decrypter.update(cipher);
                    plain = Buffer.concat([plain, decrypter.final()]);
    
                    const base64 = plain.toString('base64');
                    this.setState({src:'data:image/jpeg;base64,' + base64});
                    
                }).catch( reason => {
                    this.setState({src:"/Images/default.png" });
                });
                
        }
        else{
            if( fileName != '')
            {
                var url = global.StorageAccountUrl + "/uploads/" + folderName + "/" + fileName;
                this.setState({src:url});
            }
            else
            {
                this.setState({src:"/Images/default.png" });
            }
        }

    }
    
    componentDidMount() 
    {
        if( this.props.fetchFileName === true )
        {
            var url = `${global.ApiHost}api/Employee/avatarImage?empId=${this.props.empId}`;
            window.fetch(url,
                {
                    credentials: 'include',//params.credentials != null ? 'include' : 'same-origin',
                    headers: {
                        'Accept': 'application/json, text/plain, */*',
                    },
                    method: 'get',
                })
                .then((response) => {
                    if (!response.ok) {
                        return "default.jpg";
                    }
                    return response.text()
                }).then((fileName) => {
                    this.loadImage(this.props.runtime, fileName, this.props.empId)
                })
                .catch(()=>{
                    this.loadImage(null, "default.jpg", null);
                });
        }
        else
        {
            this.loadImage( this.props.runtime, this.props.fileName, this.props.empId );
        }
    }
    clearLogo(){
        this.setState({ src:'/Images/default.png' },() =>{
            if(this.props.onDelete !== undefined){
                this.props.onDelete();
            }
        });
    }
    render()
    {
        return <div style={{position: 'relative'}}> 
                <Image onClick={() => { if(this.props.isPhoto !== undefined) { this.setState({ isView: true }) } }} size='small' src={this.state.src}
                    style={{ ...this.props.style }} circular floated={this.props.floated} /> 
                { this.state.isView &&
                            <Modal open size='tiny' onClose={() =>{ this.setState({ isView: false }) }}
                             > 
                             <Modal.Header>Profile Photo</Modal.Header>
                            <Modal.Content>
                                <div style={{ display: 'flex', flexDirection:'column', alignItems:'center'}}>
                                    <Image size='medium' onClick={this.props.onClick}  src={this.state.src} 
                                        onMouseOver ={() => 
                                            this.setState({hover: true})}
                                        onMouseOut={(_ => this.setState({hover: false}))}
                                        style={{ cursor: "pointer", filter: `${this.state.hover ? 'blur(1px)' : ''}` }} /> 
                                        <p style={{ visibility: `${this.state.hover ? 'visible' : 'hidden'}`, color: 'gray'}}>
                                             Note: Click the Photo to Add/Replace 
                                        </p>
                                </div>
                            </Modal.Content> 
                            <Modal.Actions>
                                { this.state.src !== "/Images/default.png" &&
                                    <Button color='red' onClick={this.clearLogo.bind(this)}>
                                    <Icon name='trash' /> Remove
                                    </Button>
                                }
                                <Button  onClick={() =>{ this.setState({ isView: false }) }}>
                                <Icon name='close' /> Close
                                </Button>
                            </Modal.Actions>
                        </Modal> 
                }
        </div> 
    }
    
}

export default EmployeeImage;
import React, {useEffect, useState} from "react";
import{ Modal, Button, Radio, Input, Icon } from "semantic-ui-react";
import "semantic-ui-css/semantic.min.css";
import styles from './SentryIntegration.module.css';
import { authGet, authPost } from "../../ajax";
import MessageBoxOkOnly from "../../Commons/MessageBoxOkOnly";

const keyModel = {
    Token : "",
    SecretKey : ""
}

const msgBoxModel = {
    Title : "",
    Message : ""
}
const SentryIntegration = (props) =>{
    const [key, setKey] = useState(keyModel);
    const [enabled, setEnabled] = useState(false);
    const [listToken, setListToken]= useState([]);
    const [isMsgOk, setIsMsgOk] = useState(false);
    const [msgBox, setMsgBox] = useState(msgBoxModel);

    const handleClose = () =>{
        props.onClick();
    }

    useEffect(()=>{
        setListToken([]);
        setListToken((prevState) => [
            ...prevState,
            {Key : "Token", Value : key.Token},
            {Key : "SecretKey", Value : key.SecretKey}
        ]);
    },[key])

    const copyToClipBoard = (text) => {
        navigator.clipboard
            .writeText(text)
            .then(() => {
                alert("Successfully Copied !");
            })
            .catch(() => {
                alert("Something went wrong.");
            });
    }

    //API Request
    useEffect( async ()=>{
        const response = await authGet('/setting/GetAPIToken');
        var result = await response.json();
        if(result.length > 0){
            key.SecretKey = result.find(x => x.key =="SecretKey").value;
            key.Token = result.find(x => x.key == "Token").value;
            setEnabled(true);
        }
    },[]);

    const getToken = async () => {
        const response = await authGet('/user/GenerateToken');
        var result = await response.text();
        setKey(prevState => ({
            ...prevState,
            Token : result
        }));
    }

    const getSecretkey = async () =>{
        const response = await authGet('/user/GenerateSecretKey');
        var result = await response.text();
        setKey(prevState => ({
            ...prevState,
            SecretKey : result
        }));
    }

    const onSave = async () =>{
        const response = await authPost('/setting/SaveAPIToken', listToken);
        if(response.status == 201){
            msgBox.Title = "Sentry Integration";
            msgBox.Message = "Successfully Saved !";
            setIsMsgOk(true);
        }
    }

    //UI Effects
    const onChangeRadio = async (e, {checked}) =>{
        setEnabled(checked);
        if(!checked){
            setKey({...keyModel});
            await authPost('/setting/DeleteAPIToken');
        }
        else{
            getToken();
            getSecretkey();
        }
    }

    return(
        <div>
            <Modal size="small" open onClose={handleClose}>
                <Modal.Content>
                    <div className={styles.mainWrapper}>
                        <div className={styles.header}>
                            <div className={styles.headerLeft}>
                                <img src="https://staticzktecoph.blob.core.windows.net/static/Images/sentry-logo.svg" />
                            </div>
                            <div className={styles.headerRight}>
                                <Radio slider label="Enable" checked={enabled} onChange={onChangeRadio}></Radio>
                            </div>
                        </div>
                        <div className={styles.body}>
                            <span className={styles.bodyTitle}>Sentry Integration</span>
                            <span className={styles.bodyDescription}>Integrate your ZKTeco Payroll with our Sentry and unlock the full potential of your ZKTeco Payroll
                            with detailed and accurate device logs that seamlessly integrated with Sentry 24/7 . </span>
                            <div className={styles.bodyControls}>
                                <div className={styles.inputContainer}>
                                    <span>Secret Key</span>
                                    <div className={styles.secretKeyContainer}>
                                        <Input style={{ width: '75%' }} value ={key.SecretKey} 
                                        action={{
                                            color: 'green',
                                            labelPosition: 'right',
                                            icon: 'copy',
                                            content: 'Copy',
                                            onClick : (e) => copyToClipBoard(key.SecretKey),
                                            disabled : enabled ?  false : true
                                            }} disabled={enabled ? false : true}></Input>
                                        <Button style={{marginLeft : '0.5em'}} color="blue" onClick={getSecretkey} disabled={enabled ? false : true}>
                                            <Icon name='configure' /> Generate
                                        </Button>
                                    </div>
                                </div>
                                <div className={styles.inputContainer}>
                                    <span>Client ID</span>
                                    <div className={styles.inputToken}>
                                        <Input style={{ width: '98%' }} value={key.Token} 
                                        action={{
                                            color: 'green',
                                            labelPosition: 'right',
                                            icon: 'copy',
                                            content: 'Copy',
                                            onClick : (e) => copyToClipBoard(key.Token),
                                            disabled : enabled ?  false : true
                                        }} disabled={enabled ? false : true}></Input>
                                    </div>
                                </div>
                            </div> 
                            {/* <div className={styles.bodyFooter}>
                                <span>Generated Date : May 25, 2023</span>
                            </div> */}
                        </div>
                    </div>
                </Modal.Content>
                <Modal.Actions>
                    <Button color="blue" onClick={onSave} disabled={enabled ? false : true}>Save</Button>
                    <Button onClick={handleClose}>Close</Button>
                </Modal.Actions>
            </Modal>

            { isMsgOk &&
                    <MessageBoxOkOnly title={msgBox.Title} action="Yes"
                        caption={msgBox.Message}
                        onClick={()=> setIsMsgOk(false)}
                    />
            }
        </div>
    )
}

export default SentryIntegration;
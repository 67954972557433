import React, { Component } from 'react';
import { Header, Input, Button, Modal, Container, Form } from 'semantic-ui-react';
import 'semantic-ui-css/semantic.min.css';
import { checkDateRange, errorStyles } from '../../utils'

var moment = require('moment');

var dt = moment().local();

const BlankItem = {
    Title: "",
    Message: "",
    StartDate: dt,
    EndDate: dt,
    RecordDate: dt,
}

class AnnouncementForm extends Component {
    state = {
        announcement: null,
        saving: false,
        isValid: false,
        isError: false,
        isErrorTitle: false,
        isErrorMessage: false,

    }

    constructor(props) {
        super(props);

        if (props.announcement == null) {
            this.state.announcement = JSON.parse(JSON.stringify(BlankItem));
        } else {
            this.state.announcement = props.announcement;
        }
    }

    handleChange = name => event => {

        this.setState({ isValid: event.target.value != null });
        var current = this.state.announcement;
        current[name] = event.target.value;
        this.setState({ announcement: current });
    };

    onCancel() {
        this.props.onCancel();
    }

    onSave() {
        this.setState({ isErrorTitle: false, isErrorMessage: false });

        if (this.state.announcement.Title === "") {
            this.setState({ Message: "Title is require", isErrorTitle: true })
        }
        if (this.state.announcement.Message === "") {
            this.setState({ Message: "Message is require", isErrorMessage: true })
        }
        if (this.state.announcement.Title === "" || this.state.announcement.Message === "")
            return;

        var res = checkDateRange(moment(this.state.announcement["StartDate"]).format("YYYY-MM-DD"), moment(this.state.announcement["EndDate"]).format("YYYY-MM-DD"));
        if (!res.Result) {
            this.setState({ Message: res.Message.replace(" Previous valid date range is going to restore.", "").replace("Previous valid start date is going to restore.", "").replace("Previous valid end date is going to restore.", ""), isError: !res.Result })
            return;
        }

        this.props.onSave(this.state.announcement);
    }

    render() {
        return (
            <Modal open size='tiny'>
                <Modal.Header>
                    <Header>Announcement</Header>
                </Modal.Header>
                <Modal.Content>
                    <Container>
                        <Form widths='equal'>
                            <Form.Field
                                id='txtTitle'
                                control={Input}
                                label='Title'
                                value={this.state.announcement["Title"]}
                                placeholder='Title'
                                onChange={this.handleChange("Title").bind(this)}
                                error={this.state.isErrorTitle}
                            >
                            </Form.Field>
                            <Form.Field
                                id='txtMessage'
                                control={Input}
                                label='Message'
                                value={this.state.announcement["Message"]}
                                onChange={this.handleChange("Message").bind(this)}
                                error={this.state.isErrorMessage}
                            >
                            </Form.Field>
                            <Form.Field
                                id='dtpStartDate'
                                control={Input}
                                type='date'
                                label='From'
                                placeholder='Start Date'
                                value={moment(this.state.announcement["StartDate"]).format("YYYY-MM-DD")}
                                onChange={this.handleChange("StartDate").bind(this)}
                            >
                            </Form.Field>
                            <Form.Field
                                id='dtpEndDate'
                                control={Input}
                                type='date'
                                label='To'
                                placeholder='End Date'
                                value={moment(this.state.announcement["EndDate"]).format("YYYY-MM-DD")}
                                onChange={this.handleChange("EndDate").bind(this)}
                            >
                            </Form.Field>
                            {this.state.isError && <label style={{ color: 'red' }}>{this.state.Message}</label>}
                        </Form>
                    </Container>
                </Modal.Content>
                <Modal.Actions>
                    <Button disabled={!this.state.isValid} basic icon='save' onClick={this.onSave.bind(this)} content='Save' />
                    <Button basic icon='cancel' onClick={this.onCancel.bind(this)} content='Cancel' />
                </Modal.Actions>
            </Modal>
        )
    }
}

export default AnnouncementForm
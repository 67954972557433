import React, { Component } from 'react';
import Select from 'react-select'
import { Tab, Input, Button, Table } from 'semantic-ui-react';
import { ajaxPost } from '../../ajax';
import { handleNumericOnKeyPress, handleNumericOnKeyDown } from '../../utils';
import PdfMake from 'pdfmake/build/pdfmake';
import vfsFonts from 'pdfmake/build/vfs_fonts';

const moment = require('moment');

export default class PagibigSTLRF extends Component {
    constructor(props) {
        super(props);

        this.state = {
            selectedCompany: null,
            month: moment().format('MM'),
            year: moment().format('YYYY'),

            isCompanyLoading: false,
            isGenerateLoading: false,
            isExportLoading: false,

            companyList: [],

            generatedData: [],

            totalFields: {
                pageTotalSummary: [],
                grandTotal: 0
            },
        }

        this.loadCompanies();
    }

    componentWillMount = () => {
        if (this.props.prevState !== null)
            this.state = this.props.prevState;
    }

    componentWillUnmount = () => {
        this.props.unmountCallback(this.state, 'STLRF');
    }

    computeTotalFields() {
        var { totalFields, generatedData } = this.state;
        var totalPages = Math.ceil(generatedData.length / 30)
        var total = 0;
        var totalPerPage = [];
        for (let index = 0; index < totalPages; index++) {
            var currTotal = 0;
            generatedData.slice(index * 30, index * 30 + 30).forEach(x => {
                currTotal += x.Amount;
            });

            total += currTotal;

            totalPerPage.push(currTotal);
        }

        totalFields.pageTotalSummary = totalPerPage;
        totalFields.grandTotal = total;

        this.setState({ totalFields });
    }

    loadCompanies() {
        this.setState({ isCompanyLoading: true })
        ajaxPost({
            url: 'api/group/search',
            data: {
                Search: '',
                startingIndex: 0,
                itemCount: 999,
                Types: ['COMPANY']
            },
            onSuccess: (data) => {
                this.setState({
                    companyList: data.content.map(x => { return ({ label: x.Name, value: x._id }) }),
                    isCompanyLoading: false
                });
            },
            finally: () => { }
        })
    }

    checkStatus(processId, callback) {
        ajaxPost({
            data: { processId: processId },
            url: 'api/BackgroundProcess/GetStatus',
            onSuccess: data => {
                if (data.Status != "PROCESSING") {
                    callback(processId)
                }
                else {
                    this.checkStatus(processId, callback);
                }
            },
            finally: () => { }
        })
    }

    orderList = (a, b) => {
        if (a.LastName > b.LastName)
            return 1;
        if (a.LastName < b.LastName)
            return -1;

        if (a.FirstName > b.FirstName)
            return 1;
        if (a.FirstName < b.FirstName)
            return -1;

        if (a.MiddleName > b.MiddleName)
            return 1;
        if (a.MiddleName < b.MiddleName)
            return -1;

        return 0;
    }

    onExportPDFClick = () => {
        if (this.state.selectedCompany === null) {
            alert("Please select a company");
            return;
        }

        var year = this.state.year.split('').join('       ');
        const { generatedData, totalFields } = this.state;

        ajaxPost({
            url: 'api/governmentforms/PagibigSTLRFExportToPDF',
            data: {
                companyId: this.state.selectedCompany.value,
            },
            onSuccess: (data) => {
                const { vfs } = vfsFonts.pdfMake;
                PdfMake.vfs = vfs;

                var stackIndex = [];

                for (let index = 0; index < Math.ceil(generatedData.length / 30); index++) {
                    stackIndex.push(index);
                }

                var pdfData = {
                    pageSize: 'LETTER',
                    pageOrientation: 'portrait',
                    content: stackIndex.map((index, currIndex) => {
                        return ([
                            {
                                image: data.Image,
                                width: 570,
                                height: 720,
                                absolutePosition: { x: 21, y: 2 }
                            },
                            {
                                text: data.EmployerNo.toUpperCase(),
                                style: { fontSize: 8 },
                                absolutePosition: { x: 415, y: 90 }
                            },
                            {
                                absolutePosition: { x: 60, y: 160 },
                                layout: 'noBorders',
                                table: {
                                    widths: [75, 75, 155, 75, 100],
                                    body: [
                                        [
                                            {
                                                text: data.UnitNo.toUpperCase(),
                                                style: { fontSize: 6 },
                                                alignment: 'center'
                                            },
                                            {
                                                text: data.BuildingName.toUpperCase(),
                                                style: { fontSize: 6 },
                                                alignment: 'center'
                                            },
                                            {
                                                text: data.BlockNo.toUpperCase(),
                                                style: { fontSize: 6 },
                                                alignment: 'center'
                                            },
                                            {
                                                text: data.StreetName.toUpperCase(),
                                                style: { fontSize: 6 },
                                                alignment: 'center'
                                            },
                                            {
                                                text: moment(this.state.year + '-' + this.state.month + '-1').format('MMMM YYYY').toUpperCase(),
                                                style: { fontSize: 6 },
                                                alignment: 'left'
                                            },
                                        ]
                                    ]
                                }
                            },
                            {
                                absolutePosition: { x: 60, y: 180 },
                                layout: 'noBorders',
                                table: {
                                    widths: [45, 30, 65, 150, 85, 100],
                                    body: [
                                        [
                                            {
                                                text: data.Subdivision.toUpperCase(),
                                                style: { fontSize: 6 },
                                                alignment: 'center'
                                            },
                                            {
                                                text: '',
                                                style: { fontSize: 6 },
                                                alignment: 'center'
                                            },
                                            {
                                                text: data.City.toUpperCase(),
                                                style: { fontSize: 6 },
                                                alignment: 'center'
                                            },
                                            {
                                                text: '',
                                                style: { fontSize: 6 },
                                                alignment: 'center'
                                            },
                                            {
                                                text: data.ZipCode.toUpperCase(),
                                                style: { fontSize: 6 },
                                                alignment: 'center'
                                            },
                                            {
                                                text: data.TelephoneNo.toUpperCase(),
                                                style: { fontSize: 6 },
                                                alignment: 'left'
                                            },
                                        ]
                                    ]
                                }
                            },
                            {
                                absolutePosition: { x: 63, y: 223 },
                                layout: 'noBorders',
                                table: {
                                    widths: [47, 60, 48, 49, 49, 49, 45, 47],
                                    heights: 6.9,
                                    body: generatedData.slice(index * 30, index * 30 + 30).map(x => {
                                        return (
                                            [
                                                {
                                                    text: x.PagibigNo,
                                                    style: { fontSize: 4 },
                                                },
                                                {
                                                    text: '',
                                                    style: { fontSize: 4 },
                                                },
                                                {
                                                    text: x.LastName.toUpperCase(),
                                                    style: { fontSize: 4 },
                                                },
                                                {
                                                    text: x.FirstName.toUpperCase(),
                                                    style: { fontSize: 4 },
                                                },
                                                {
                                                    text: x.Suffix.toUpperCase(),
                                                    style: { fontSize: 4 },
                                                },
                                                {
                                                    text: x.MiddleName.toUpperCase(),
                                                    style: { fontSize: 4 },
                                                },
                                                {
                                                    text: x.LoanType.replace('Pagibig', '').replace('Loan', '').trim().toUpperCase(),
                                                    style: { fontSize: 4 },
                                                    alignment: 'left'
                                                },
                                                {
                                                    text: x.Amount,
                                                    style: { fontSize: 4 },
                                                    alignment: 'left'
                                                },
                                            ]
                                        )
                                    })
                                }
                            },
                            {
                                absolutePosition: { x: 472, y: 551 },
                                layout: 'noBorders',
                                table: {
                                    widths: [200],
                                    heights: 8.1,
                                    body: [
                                        [
                                            {
                                                text: totalFields.pageTotalSummary[index],
                                                style: { fontSize: 4 },
                                            },
                                        ]
                                    ]
                                }
                            },

                            {
                                absolutePosition: { x: 472, y: 563 },
                                layout: 'noBorders',
                                pageBreak: currIndex === stackIndex.length - 1 ? '' : 'after',
                                table: {
                                    widths: [200],
                                    heights: 8.1,
                                    body: [
                                        [
                                            {
                                                text: currIndex === stackIndex.length - 1 ? totalFields.grandTotal : '',
                                                style: { fontSize: 4 },
                                            },
                                        ]
                                    ]
                                }
                            }
                        ])
                    })
                }

                PdfMake.createPdf(pdfData).open();
            },
            finally: () => { }
        })

    }

    onGenerateClick = () => {
        this.setState({ isGenerateLoading: true });
        this.generatePagibigSTLRF((processId) => {
            ajaxPost({
                url: 'api/governmentforms/GetPagibigSTLRF',
                data: processId,
                onSuccess: (data) => {
                    this.setState({ generatedData: data.content.sort(this.orderList), isGenerateLoading: false }, () => this.computeTotalFields());
                },
                finally: () => { }
            })
        })
    }

    generatePagibigSTLRF = (callback) => {
        if (this.state.selectedCompany === null) {
            this.setState({ isGenerateLoading: false });
            alert("No company to be generated");
            return;
        }

        if (this.state.month === '' || this.state.month > 12 || this.state.month < 1) {
            this.setState({ isGenerateLoading: false });
            alert("Invalid value of month");
            return;
        }

        if (this.state.year === '' || this.state.year > 9999 || this.state.year < 1990) {
            this.setState({ isGenerateLoading: false });
            alert("Invalid value of year");
            return;
        }

        ajaxPost({
            url: 'api/governmentforms/GeneratePagibigSTLRF',
            data: {
                companyId: this.state.selectedCompany.value,
                year: this.state.year,
                month: this.state.month
            },
            onSuccess: (value) => {
                this.checkStatus(value, callback);
            },
            finally: () => { }
        })
    }

    render() {
        const { generatedData } = this.state;

        return (
            <div>
                <h1>Pagibig STLRF</h1>

                <div style={{ display: 'flex', overflowY: 'auto', height: window.innerHeight - 203 }}>
                    <div style={{ marginRight: '10px', width: 300 }}>
                        <div>
                            <label>Company</label>
                            <Select value={this.state.selectedCompany} isLoading={this.state.isCompanyLoading} options={this.state.companyList} onChange={(e) => this.setState({ selectedCompany: e })} />
                        </div>

                        <div style={{ marginTop: 5 }}>
                            <label>Month</label>
                            <Input fluid value={this.state.month}
                                onChange={(e) => this.setState({ month: e.target.value })}
                                onKeyPress={(e) => handleNumericOnKeyPress(e)}
                                onKeyDown={(e) => handleNumericOnKeyDown(e)}
                            />
                        </div>

                        <div style={{ marginTop: 5 }}>
                            <label>Year</label>
                            <Input fluid value={this.state.year}
                                onChange={(e) => this.setState({ year: e.target.value })}
                                onKeyPress={(e) => handleNumericOnKeyPress(e)}
                                onKeyDown={(e) => handleNumericOnKeyDown(e)}
                            />
                        </div>

                        <Button style={{ marginTop: 10 }} content='Export To PDF' floated='right' loading={this.state.isExportLoading} onClick={this.onExportPDFClick.bind(this)} />
                        <Button style={{ marginTop: 10 }} content='Generate' floated='right' loading={this.state.isGenerateLoading} onClick={this.onGenerateClick.bind(this)} />
                    </div>

                    <div style={{ width: window.innerWidth - 600, height: window.innerHeight - 203, overflowX: 'auto', overflowY: 'auto' }}>
                        <Table fixed striped padded size='small' style={{ minWidth: '1000px' }}>
                            <Table.Header>
                                <Table.Row style={{ 'backgroundColor': 'gainsboro', 'fontSize': '11px', padding: '0px' }}>
                                    <Table.HeaderCell style={{ width: '20%' }}>HDMF No</Table.HeaderCell>
                                    <Table.HeaderCell style={{ width: '20%' }}>Last Name</Table.HeaderCell>
                                    <Table.HeaderCell style={{ width: '20%' }}>First Name</Table.HeaderCell>
                                    <Table.HeaderCell style={{ width: '20%' }}>Middle Name</Table.HeaderCell>
                                    <Table.HeaderCell style={{ width: '20%' }}>Loan Type</Table.HeaderCell>
                                    <Table.HeaderCell style={{ width: '20%' }}>Amount</Table.HeaderCell>
                                </Table.Row>
                            </Table.Header>
                            <Table.Body>
                                {generatedData.map(list => {
                                    return (
                                        <Table.Row>
                                            <Table.Cell verticalAlign='top'>{list.PagibigNo}</Table.Cell>
                                            <Table.Cell verticalAlign='top'>{list.LastName}</Table.Cell>
                                            <Table.Cell verticalAlign='top'>{list.FirstName}</Table.Cell>
                                            <Table.Cell verticalAlign='top'>{list.MiddleName}</Table.Cell>
                                            <Table.Cell verticalAlign='top'>{list.LoanType}</Table.Cell>
                                            <Table.Cell verticalAlign='top'>{list.Amount}</Table.Cell>
                                        </Table.Row>
                                    )
                                })}
                            </Table.Body>
                        </Table>
                    </div>
                </div>
            </div>
        )
    }
}

const BlankDevice = {
    DeviceCode: "",
    DeviceName: "",
    SerialNumber: "",
    DeviceInformation: {},
    IPAddress: "",
    LastUpdate: null,
    Deleted: false,
    FaceSupported: false,
    DeviceFunction: "Default"
}

var  GetBlankDevice = () =>
{
    return JSON.parse(JSON.stringify(BlankDevice));
}

module.exports=GetBlankDevice;
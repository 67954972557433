import React, { Component } from 'react';
import { Button } from 'semantic-ui-react';
//import 'semantic-ui-css/semantic.min.css';
import { ajaxPost } from '../ajax';
import {NotificationPopupControl} from './MasterPageControls'

class NotificationPreview extends Component {
    state =
        {
            notifications: []
        }

    componentWillMount = () => this.updateNotificationList();

    updateNotificationList = () => {
        var searchParameter = {
            data: {
                StartIndex: 0,
                MaxData: 5,
            },
            url: "api/Notification/list",
            onError: (error) => { },
            onSuccess: (data, sender) => {
                var list = data.map((x) => {
                    return {
                        data: x,
                        enabled: true,
                    };
                });
                this.setState({ notifications: list });
            },
            finally: () => { }
        };
        ajaxPost(searchParameter);
    };

    handleMarkReadAll = () => {
        ajaxPost({
            url: "api/notification/markAsReadAll",
            data: "",
            onSuccess: (data) => { },
            finally: () => { },
        });
    };

    render() {
        return (
            <div>
                <Button
                    style={{
                        border: "none",
                        backgroundColor: "transparent",
                    }}
                    size="mini"
                    floated="right"
                    icon="envelope open outline"
                    content="Mark all as read"
                    onClick={this.handleMarkReadAll}
                />
                <br /> <br />
                <NotificationPopupControl
                    notifications={this.state.notifications}
                    notificationItemClick={this.props.notificationItemClick}
                    parent={this.props.parent}
                />
                <div
                    onClick={this.props.handleViewAllNotif}
                    style={{ padding: "10px", cursor: "pointer"}}
                >
                    View All Notifications
                </div>
            </div>
        )
    }
}

export default NotificationPreview;
import { useState } from 'react'
import { Checkbox, Icon } from 'semantic-ui-react'
import modStyles from './RowEditor.module.css'

export default function RowEditor(props) {
    const [editMode, setEditMode] = useState(false);
    const [values, setValues] = useState(props.values);
    const [error, setError] = useState("");

    const onEdit = () => {
        setEditMode(true);
    }

    const onCancel = () => {
        setValues(props.values);
        setEditMode(false);
        setError("");
    }

    const onChange = (i, e) => {
        values.splice(i, 1, e.target?.value);
        setValues([...values])
    }

    const onCheckChange = (i) => {
        var checked = values[i];
        checked = checked === 'YES' ? 'NO' : 'YES'
        values.splice(i, 1, checked);
        setValues([...values])
    }


    const onSave = () => {
        var result = props.onSave(values);
        if( result === ""){
            setEditMode(false);
        }
        setError( result );
    }

    const getComponent = (value, i) => {
        if (editMode === false) {
            if (props.type[i] == 'CHECKBOX') {
                return <Icon name={value === 'YES' || value === true ? "check square outline" : "square outline"} />
            }
            return value;
        }

        switch (props.type[i]) {
            case "TEXT":
                return <input type="text" value={value} style={{ width: '100%' }} onChange={onChange.bind(this, i)} />
            case "TIME":
                return <input type="time" value={value} style={{ width: '100%' }} onChange={onChange.bind(this, i)} />
            case "CHECKBOX":
                return <Checkbox checked={value === true || value === 'YES'} onChange={onCheckChange.bind(this, i)} />
        }
    }

    return <div className={modStyles.row}>
        {
            values.map((x, i) => {
                return <div style={{ minWidth: props.widths[i] }}>{getComponent(x, i)}</div>
            })
        }
        <div className={modStyles.buttons}>
            {
                editMode === false ?
                <>
                    <div onClick={onEdit}>
                        <Icon link name="edit" size="big" />
                    </div>
                    <div onClick={props.onRemove}>
                        <Icon link name="cancel" size="big" />
                    </div>
                </>
                : <>
                    <div onClick={onSave}>
                        <Icon link name="check" size="big" />
                    </div>
                    <div onClick={onCancel}>
                        <Icon link name="ban" size="big" />
                    </div>
                </>
            }
            {
                error !== "" && 
                <div>{error}</div>
            }
        </div>
    </div>
}
import PdfMake from 'pdfmake/build/pdfmake';
import vfsFonts from 'pdfmake/build/vfs_fonts';
import moment from 'moment';

export default (data) => {
    const { vfs } = vfsFonts.pdfMake;
    PdfMake.vfs = vfs;
    
    var logs = [];
    data.content.forEach(e => {
        var log = [];
        log.push(e.EmployeeNo);
        log.push(e.Employee);
        log.push(moment(e.RecordDate).format("YYYY-MM-DD"));
        log.push(moment(e.RecordTime).format("hh:mm A"));
        log.push(e.DisplayLogType);
        logs.push(log);
    });

    const pdfDoc = {
        pageSize: 'letter',
        pageOrientation: 'portrait',
        content: [
            {
                text: [
                    { text: "Employee Devicelogs" },
                    '\n',
                    { text: "Start Date: " + data.StartDate },
                    '\n',
                    { text: "End Date: " + data.EndDate },
                    '\n','\n',
                ]
            },
            {
                width: "*",
                layout: 'noBorders',
                table: {
                    headerRows: 1,
                    widths: [100, 200, 70, 70, 70],
                    body: [
                        [
                            {
                                text: "Employee No",
                                bold: true,
                            },
                            {
                                text: "Employee",
                                bold: true,
                            },
                            {
                                text: "Date",
                                bold: true,
                            },
                            {
                                text: "Time",
                                bold: true,
                            },
                            {
                                text: "Type",
                                bold: true,
                            }
                        ],
                    ]
                }
            },
            //line break
            {
                layout: 'headerLineOnly',
                table: {
                    headerRows: 1,
                    widths: ["*"],
                    body: [
                        [''],
                        ['']
                    ]
                },
            },
            {
                width: "*",
                layout: 'noBorders',
                table: {
                    headerRows: 1,
                    widths: [100, 200, 70, 70, 70],
                    body:logs,
                }
            },
        ]

    };
    PdfMake.createPdf(pdfDoc).open();
}
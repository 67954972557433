import React, { Component } from 'react';
import { Button, Table, ButtonGroup, Modal, Input, Label, Tab, Checkbox } from 'semantic-ui-react';
import { ajaxPost } from '../../ajax';
import { handleNumericOnKeyDown, handleNumericOnKeyPress } from '../../utils';
import Select from 'react-select';
import PdfMake from 'pdfmake/build/pdfmake';
import vfsFonts from 'pdfmake/build/vfs_fonts';

const moment = require('moment');

export default class Form1601E extends Component {

    constructor() {
        super();

        this.state = {
            isCompanyLoading: false,
            isGenerateLoading: false,
            isExportLoading: false,
            companyList: [],
            selectedCompany: null,
            month: moment().format('MM'),
            year: moment().format('YYYY'),

            generatedData: [],

            totalField: {
                totalTaxReqWithheldAndRemitted: 0.0,
                totalTaxCreditOrPayments: 0.0,
                taxStillDue: 0.0,
                totalPenalties: 0.0,
                totalAmountStillDue: 0.0,
            },

            taxRemittedReturnPreviouslyFiled: 0.0,
            advancePaymentMade: 0.0,
            surcharge: 0.0,
            interest: 0.0,
            compromise: 0.0,

            isAmendedReturn: false,
            isAnyTaxWithheld: false,
            isUnderSpecialLaw: false,
            specifySpecialLaw: '',

            isModalOpen: false
        }

        this.loadCompany('');
    }

    componentWillMount = () => {
        if(this.props.prevState !== null)
            this.state = this.props.prevState;
    }

    componentWillUnmount = () => {
        this.props.unmountCallback(this.state, 'Form1601E');
    }

    onTextChange = (field, e) => {
        this.setState({ [field]: e.target.value }, () => this.computeTotalFields());
    }

    computeTotalFields() {
        var { totalField, generatedData } = this.state;
        var totalTaxReqWithheld = 0.0;

        generatedData.forEach(x => {
            totalTaxReqWithheld += x.TaxRequireWithheld
        });

        var penaltySurcharge = this.state.surcharge === ''? 0: this.state.surcharge;
        var penaltyInterest = this.state.interest === ''? 0: this.state.interest;
        var penaltyCompromise = this.state.compromise === ''? 0: this.state.compromise;

        var taxRemittedReturnPreviouslyFiled = this.state.taxRemittedReturnPreviouslyFiled === ''? 0: this.state.taxRemittedReturnPreviouslyFiled;
        var advancePaymentMade = this.state.advancePaymentMade === ''? 0: this.state.advancePaymentMade;

        totalField.totalTaxReqWithheldAndRemitted = parseFloat(totalTaxReqWithheld);
        totalField.totalTaxCreditOrPayments = parseFloat(taxRemittedReturnPreviouslyFiled) + parseFloat(advancePaymentMade);
        totalField.taxStillDue = parseFloat(totalField.totalTaxReqWithheldAndRemitted) - parseFloat(totalField.totalTaxCreditOrPayments);
        totalField.totalPenalties = parseFloat(penaltySurcharge) + parseFloat(penaltyInterest) + parseFloat(penaltyCompromise);
        totalField.totalAmountStillDue = parseFloat(totalField.taxStillDue) + parseFloat(totalField.totalPenalties);

        this.setState({ totalField });
    }

    loadCompany() {
        this.setState({ isCompanyLoading: true });
        ajaxPost({
            url: 'api/group/search',
            data: {
                Search: '',
                startingIndex: 0,
                itemCount: 999,
                Types: ['COMPANY']
            },
            onSuccess: (data) => {
                var list = data.content.map(x => { return ({ label: x.Name, value: x._id }) });
                this.setState({ companyList: list });
            },
            finally: () => {
                this.setState({ isCompanyLoading: false });
            }
        })
    }

    createTableRow(label, value, disable) {
        var curr = this.state[value];
        return (
            <tr>
                <td style={{ width: '75%' }}>
                    {label}
                </td>
                {disable === false && <td style={{ width: '25%' }}>
                    <Input style={{ border: '1px solid gray' }} disabled={disable} fluid
                        onChange={this.onTextChange.bind(this, value)} value={curr}
                        onKeyPress={(e) => handleNumericOnKeyPress(e)}
                        onKeyDown={(e) => handleNumericOnKeyDown(e)}
                    />
                </td>}
                {disable === true && <td style={{ width: '25%' }}>
                    <Input style={{ border: '1px solid gray' }} disabled={disable} fluid value={value}/>
                </td>}
            </tr>
        )
    }

    onGenerateClick = () => {
        if(this.state.isGenerateLoading)
            return;

        this.setState({isGenerateLoading: true});
        this.generateForm1601e( (processId) =>
            ajaxPost({
                url: 'api/governmentForms/GetForm1601e',
                data: processId,
                onSuccess: (data) => {
                    this.setState({generatedData: data.content, isGenerateLoading: false}, () => {this.computeTotalFields()});
                },
                finally: () => {}
            })
        )
    }

    generateForm1601e = (callback) => {
        if (this.state.selectedCompany === null) {
            this.setState({isGenerateLoading: false});
            alert("Please select a company");
            return;
        }

        if(this.state.month === '' || this.state.month > 12 || this.state.month < 1) {
            this.setState({isGenerateLoading: false});
            alert("Invalid value of month");
            return;
        }

        if(this.state.year === '' || this.state.year > 9999 || this.state.year < 1990) {
            this.setState({isGenerateLoading: false});
            alert("Invalid value of year");
            return;
        }

        ajaxPost({
            url: 'api/governmentforms/GenerateForm1601e',
            data: {
                companyId: this.state.selectedCompany.value,
                month: this.state.month,
                year: this.state.year
            },
            onSuccess: (value) => {
                this.checkStatus(value, callback);
            },
            finally: () => { }
        })
    }

    checkStatus(processId, callback) {
        ajaxPost({
            data: { processId: processId },
            url: 'api/BackgroundProcess/GetStatus',
            onSuccess: data => {
                if (data.Status != "PROCESSING") {
                    callback(processId)
                }
                else {
                    this.checkStatus(processId, callback);
                }
            },
            finally: () => { }
        })
    }

    onExportPDFClick = () => {
        if(this.state.isExportLoading)
            return;

        var {month, year, totalField, generatedData} = this.state;
        if(this.state.selectedCompany === null){
            alert("Please select a company");
            return;
        }

        if(this.state.month === '' || this.state.month > 12 || this.state.month < 1) {
            alert("Invalid value of month");
            return;
        }

        if(this.state.year === '' || this.state.year > 9999 || this.state.year < 1990) {
            alert("Invalid value of year");
            return;
        }

        this.setState({isExportLoading: true})

        ajaxPost({
            url: 'api/governmentforms/Form1601eExportToPDF',
            data: {
                companyId: this.state.selectedCompany.value
            },
            onSuccess: (data) => {
                const { vfs } = vfsFonts.pdfMake;
                var computationOfTaxY = 201;
                PdfMake.vfs = vfs;

                if(data.TIN.length < 13)
                    data.TIN += '0'.repeat(13 - data.TIN.length)

                const tin1 = data.TIN.substring(0,3).split('').join('      ');
                const tin2 = data.TIN.substring(3,6).split('').join('       ');
                const tin3 = data.TIN.substring(6,9).split('').join('       ');
                const tin4 = data.TIN.substring(9).split('').join('       ');

                if(month.length < 2)
                    month = '0'.repeat(2 - month.length) + month

                month = month.split('').join('        ');
                year = year.split('').join('        ');

                var pdfData = {
                    pageSize: 'LETTER',
                    pageOrientation: 'portrait',
                    content: [
                        {
                            image: data.Image,
                            width: 610,
                            height: 792,
                            absolutePosition: { x: 0, y: 0 }
                        },
                        {
                            text: 'X',
                            style: {fontSize: 6},
                            absolutePosition: { x: this.state.isAmendedReturn? 254: 295, y: 87 }
                        },
                        {
                            text: 'X',
                            style: {fontSize: 6},
                            absolutePosition: { x: this.state.isAnyTaxWithheld? 483: 540, y: 87 }
                        },
                        {
                            text: 'X',
                            style: {fontSize: 6},
                            absolutePosition: { x: this.state.isUnderSpecialLaw? 198: 244, y: 173 }
                        },
                        {
                            text: this.state.isUnderSpecialLaw? this.state.specifySpecialLaw: '',
                            style: {fontSize: 6},
                            absolutePosition: { x: 380, y: 171 }
                        },
                        {
                            text: month,
                            style: {fontSize: 6},
                            absolutePosition: { x: 116, y: 83 }
                        },
                        {
                            text: year,
                            style: {fontSize: 6},
                            absolutePosition: { x: 149, y: 83 }
                        },
                        {
                            text: tin1,
                            style: {fontSize: 6},
                            absolutePosition: { x: 48, y: 112 }
                        },
                        {
                            text: tin2,
                            style: {fontSize: 6},
                            absolutePosition: { x: 101, y: 112 }
                        },
                        {
                            text: tin3,
                            style: {fontSize: 6},
                            absolutePosition: { x: 157, y: 112 }
                        },
                        {
                            text: tin4,
                            style: {fontSize: 6},
                            absolutePosition: { x: 213, y: 112 }
                        },
                        {
                            text: data.CompanyName,
                            style: {fontSize: 6},
                            absolutePosition: { x: 48, y: 134 }
                        },
                        {
                            text: data.Address,
                            style: {fontSize: 6},
                            absolutePosition: { x: 48, y: 153 }
                        },
                        {
                            text: totalField.totalTaxReqWithheldAndRemitted,
                            style: {fontSize: 6},
                            absolutePosition: { x: 470, y: 455 }
                        },
                        {
                            text: parseFloat(this.state.taxRemittedReturnPreviouslyFiled).toFixed(2),
                            style: {fontSize: 6},
                            absolutePosition: { x: 470, y: 474 }
                        },
                        {
                            text: parseFloat(this.state.advancePaymentMade).toFixed(2),
                            style: {fontSize: 6},
                            absolutePosition: { x: 470, y: 485 }
                        },
                        {
                            text: totalField.totalTaxCreditOrPayments.toFixed(2),
                            style: {fontSize: 6},
                            absolutePosition: { x: 470, y: 496 }
                        },
                        {
                            text: totalField.taxStillDue.toFixed(2),
                            style: {fontSize: 6},
                            absolutePosition: { x: 470, y: 507 }
                        },

                        {
                            text: parseFloat(this.state.surcharge).toFixed(2),
                            style: {fontSize: 6},
                            absolutePosition: { x: 95, y: 529 }
                        },
                        {
                            text: parseFloat(this.state.interest).toFixed(2),
                            style: {fontSize: 6},
                            absolutePosition: { x: 210, y: 529 }
                        },
                        {
                            text: parseFloat(this.state.compromise).toFixed(2),
                            style: {fontSize: 6},
                            absolutePosition: { x: 345, y: 529 }
                        },

                        {
                            text: totalField.totalPenalties.toFixed(2),
                            style: {fontSize: 6},
                            absolutePosition: { x: 470, y: 530 }
                        },
                        {
                            text: totalField.totalAmountStillDue.toFixed(2),
                            style: {fontSize: 6},
                            absolutePosition: { x: 470, y: 541 }
                        },
                        generatedData.map(x=> {
                            computationOfTaxY += 10;
                            return (
                                { stack: [
                                    {text: x.NatureOfIncome, style: {fontSize: 6}, absolutePosition: { x: 25, y: computationOfTaxY}},
                                    {text: x.TaxCode, style: {fontSize: 6}, absolutePosition: { x: 283, y: computationOfTaxY}},
                                    {text: x.TaxBase, style: {fontSize: 6}, absolutePosition: { x: 327, y: computationOfTaxY}},
                                    {text: x.TaxRate + '%', style: {fontSize: 6}, absolutePosition: { x: 437, y: computationOfTaxY}},
                                    {text: x.TaxRequireWithheld, style: {fontSize: 6}, absolutePosition: { x: 470, y: computationOfTaxY}},
                                ]
                                }
                            )
                        })
                    ]
                }

                PdfMake.createPdf(pdfData).open();
            },
            finally: () => {this.setState({isExportLoading: false})}
        })
    }

    render() {

        var { generatedData, totalField, selectedAdjustmentModel } = this.state;

        const tabSelection = [
            {
                menuItem: 'Computation of Tax', pane:
                    <Tab.Pane attached={false} style={{ border: '0px none', width: '100%' }}>
                        <div style={{ marginTop: 10 }}>
                            <Table fixed striped padded size='small'>
                                <Table.Header>
                                    <Table.Row style={{ 'backgroundColor': 'gainsboro', 'fontSize': '11px', padding: '0px' }}>
                                        <Table.HeaderCell style={{ width: '50%' }}>Nature of Payment</Table.HeaderCell>
                                        <Table.HeaderCell style={{ width: '10%' }}>ATC</Table.HeaderCell>
                                        <Table.HeaderCell style={{ width: '10%' }}>Tax Base</Table.HeaderCell>
                                        <Table.HeaderCell style={{ width: '10%' }}>Tax Rate</Table.HeaderCell>
                                        <Table.HeaderCell style={{ width: '20%' }}>Tax Requried to be Withheld</Table.HeaderCell>
                                    </Table.Row>
                                </Table.Header>
                                <Table.Body>
                                    {generatedData.map(list => {
                                        return (
                                            <Table.Row>
                                                <Table.Cell verticalAlign='top'>{list.NatureOfIncome}</Table.Cell>
                                                <Table.Cell verticalAlign='top'>{list.TaxCode}</Table.Cell>
                                                <Table.Cell verticalAlign='top'>{list.TaxBase}</Table.Cell>
                                                <Table.Cell verticalAlign='top'>{list.TaxRate + '%'}</Table.Cell>
                                                <Table.Cell verticalAlign='top'>{list.TaxReqWithheld}</Table.Cell>
                                            </Table.Row>
                                        )
                                    })}
                                </Table.Body>
                            </Table>
                        </div>

                    </Tab.Pane>
            },
            {
                menuItem: 'Adjustments', pane:
                    <Tab.Pane attached={false} style={{ border: '0px none', width: '100%' }}>
                        <table style={{ minWidth: 675, width: '100%' }}>
                            {this.createTableRow('14 - Total Tax Required to be Withheld and Remitted', totalField.totalTaxReqWithheldAndRemitted.toFixed(2), true)}
                            <tr>
                                <td colSpan={2}>
                                    15 - Less: Tax Credits and Payments
                                </td>
                            </tr>
                            {this.createTableRow(<div> &nbsp; &nbsp; &nbsp; <label>15A - Tax Remitted in Return Previously Filed, if this is an Amended Return</label></div>, 'taxRemittedReturnPreviouslyFiled', false)}
                            {this.createTableRow(<div> &nbsp; &nbsp; &nbsp; <label>15B - Advance Payment Made</label></div>, 'advancePaymentMade', false)}
                            {this.createTableRow(<div> &nbsp; &nbsp; &nbsp; <label>15C - Total Tax Credits / Payments (sum of 15A and 15B)</label></div>, totalField.totalTaxCreditOrPayments.toFixed(2), true)}
                            {this.createTableRow('16 - Tax Still Due (Overremittance)', totalField.taxStillDue.toFixed(2), true)}
                            <tr>
                                <td colSpan={2}>
                                    24 - Add: Penalties
                                </td>
                            </tr>
                            {this.createTableRow(<div> &nbsp; &nbsp; &nbsp; <label>24A - Surcharge</label></div>, 'surcharge', false)}
                            {this.createTableRow(<div> &nbsp; &nbsp; &nbsp; <label>24B - Interest</label></div>, 'interest', false)}
                            {this.createTableRow(<div> &nbsp; &nbsp; &nbsp; <label>24C - Compromise</label></div>, 'compromise', false)}
                            {this.createTableRow('24D - Total Penalties', totalField.totalPenalties.toFixed(2), true)}
                            {this.createTableRow('25 - Total Amount Still Due (Overremittance)', totalField.totalAmountStillDue.toFixed(2), true)}
                        </table>
                    </Tab.Pane>

            },
            {
                menuItem: 'Tax Information', pane:
                    <Tab.Pane attached={false} style={{ border: '0px none', width: '100%' }}>
                        <table style={{ minWidth: 675, width: '100%' }}>
                            <tr>
                                <td>
                                    <Checkbox checked={this.state.isAmendedReturn} label='Amended Return?'
                                        onChange={(e, {checked}) => {this.setState({isAmendedReturn: checked})}}/>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <Checkbox checked={this.state.isAnyTaxWithheld} label='Any Tax Withheld?'
                                        onChange={(e, {checked}) => {this.setState({isAnyTaxWithheld: checked})}}/>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <Checkbox checked={this.state.isUnderSpecialLaw} label='Are the payees availing tax relief under Special Law or International Tax Treaty?'
                                        onChange={(e, {checked}) => {this.setState({isUnderSpecialLaw: checked})}}/>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <div>
                                        <label>If Yes, Specify</label>
                                    </div>
                                    <div>
                                        <Input style={{width: 300}} value={this.state.specifySpecialLaw} onChange={(e) => this.setState({specifySpecialLaw: e.target.value})}/>
                                    </div>
                                </td>
                            </tr>
                        </table>
                    </Tab.Pane>
            }
        ]

        return (
            <div>
                <h1>Form 1601-E</h1>

                <div style={{ display: 'flex' }}>
                    <div style={{ height: window.innerHeight - 203, marginRight: '10px', width: 300 }}>
                        <div>
                            <label>Company</label>
                            <Select value={this.state.selectedCompany} isLoading={this.state.isCompanyLoading} options={this.state.companyList}
                                onChange={(e) => this.setState({ selectedCompany: e, selectedEmployee: null }, this.loadEmployees)} />
                        </div>

                        <div style={{ marginTop: 5 }}>
                            <label>Month</label>
                            <Input fluid value={this.state.month}
                                onChange={(e) => this.setState({ month: e.target.value })}
                                onKeyPress={(e) => handleNumericOnKeyPress(e)}
                                onKeyDown={(e) => handleNumericOnKeyDown(e)}
                            />
                        </div>

                        <div style={{ marginTop: 5 }}>
                            <label>Year</label>
                            <Input fluid value={this.state.year}
                                onChange={(e) => this.setState({ year: e.target.value })}
                                onKeyPress={(e) => handleNumericOnKeyPress(e)}
                                onKeyDown={(e) => handleNumericOnKeyDown(e)}
                            />
                        </div>

                        <div style={{ display: 'flex', float: 'right', right: 0 }}>
                            <Button style={{ marginTop: 10 }} loading={this.state.isGenerateLoading} content='Generate' floated='right' onClick={this.onGenerateClick.bind(this)} />
                            <Button style={{ marginTop: 10 }} loading={this.state.isExportLoading} content='Export To PDF' floated='right' onClick={this.onExportPDFClick.bind(this)} />
                        </div>

                    </div>

                    <div style={{ width: window.innerWidth - 600, height: window.innerHeight - 203, overflowX: 'auto', overflowY: 'auto' }}>
                        <Tab renderActiveOnly={false} style={{ minWidth: 700 }} menu={{ color: 'green', tabular: false, attached: false }}
                            panes={tabSelection} activeIndex={this.state.activeIndex} onTabChange={this.handleTabChange} />
                    </div>
                </div>

                {this.state.isModalOpen &&
                    <Modal size='small' open={true}>
                        <Modal.Header>
                            Adjustment of Taxes
                        </Modal.Header>
                        <Modal.Content>
                            <Label>Previous Month(s) (MM/YYYY)</Label>
                            <Input fluid value={selectedAdjustmentModel.prevMonth}
                                onChange={this.handleChanged.bind(this, 'prevMonth')}
                                onKeyPress={this.prevMonthKeyPressed.bind(this)}
                                onKeyDown={(e) => handleNumericOnKeyDown(e)}
                            />
                            <Label>Date Paid</Label>
                            <Input
                                size='small'
                                value={selectedAdjustmentModel.datePaid}
                                fluid
                                type="date"
                                min="1900-01-01"
                                max="2999-12-31"
                                onChange={this.handleChanged.bind(this, 'datePaid')}
                            />
                            <Label>Bank Validation / ROR No.</Label>
                            <Input fluid value={selectedAdjustmentModel.bankValidation}
                                onChange={this.handleChanged.bind(this, 'bankValidation')}
                            />
                            <Label>Bank Code</Label>
                            <Input fluid value={selectedAdjustmentModel.bankCode}
                                onChange={this.handleChanged.bind(this, 'bankCode')}
                            />
                            <Label>Tax Paid (Excluding Penalties for the Month)</Label>
                            <Input fluid value={selectedAdjustmentModel.taxPaid}
                                onChange={this.handleChanged.bind(this, 'taxPaid')}
                                onKeyPress={(e) => handleNumericOnKeyPress(e)}
                                onKeyDown={(e) => handleNumericOnKeyDown(e)}
                            />
                            <Label>Should Be Tax Due for the Month</Label>
                            <Input fluid value={selectedAdjustmentModel.taxDue}
                                onChange={this.handleChanged.bind(this, 'taxDue')}
                                onKeyPress={(e) => handleNumericOnKeyPress(e)}
                                onKeyDown={(e) => handleNumericOnKeyDown(e)}
                            />
                            <Label>Adjustment From Current Year</Label>
                            <Input fluid value={selectedAdjustmentModel.currYearAdjustment}
                                onChange={this.handleChanged.bind(this, 'currYearAdjustment')}
                                onKeyPress={(e) => handleNumericOnKeyPress(e)}
                                onKeyDown={(e) => handleNumericOnKeyDown(e)}
                            />
                            <Label>From Year-End Adjustment of the Immediate Preceeding Year</Label>
                            <Input fluid value={selectedAdjustmentModel.yearEndAdjustment}
                                onChange={this.handleChanged.bind(this, 'yearEndAdjustment')}
                                onKeyPress={(e) => handleNumericOnKeyPress(e)}
                                onKeyDown={(e) => handleNumericOnKeyDown(e)}
                            />
                        </Modal.Content>
                        <Modal.Actions>
                            <Button type='submit' onClick={this.handleOnAdd.bind(this)}>Save</Button>
                            <Button onClick={this.handleOnCancel.bind(this)}>Cancel</Button>
                        </Modal.Actions>
                    </Modal>
                }
            </div>
        )
    }

}
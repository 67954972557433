import React, { Component } from 'react';
// import { Checkbox, Radio, Modal, Menu } from 'semantic-ui-react';
import 'semantic-ui-css/semantic.min.css';
import Select from 'react-select';
import { fnSearchEmployeeFilter, customStyles, errorStyles } from '../utils';
import { TableCell } from 'semantic-ui-react';
//import CustomSelect from './CustomSelect';

function employeeSearchlist(list) {
    var employees = [];
    if (list != null) {
        list.map(x => {
            var fullname = x.LastName + ", " + x.FirstName + " " + x.MiddleName;

            return (
                employees.push({ label: fullname, value: x._id })
            )
        })
    }
    return employees;
}

class SelectEmployees extends Component {
    state = {
        Employeelist: [],
        EmployeeIds: [],
        searchQuery: '',
        errorStyle: false,
        isMulti:true,
    }
    constructor(props) {
        super(props);
        this.state = {
            loadedIds: this.props.Ids,
            EmployeeIds: [],
            isLoading: false,
            includeDeleted: this.props.includeDeleted !== undefined ? this.props.includeDeleted : false,
            errorStyle: this.props.errorStyle !== undefined ? this.props.errorStyle : false,
            isMulti: this.props.isMulti !== undefined ? this.props.isMulti :true,
            disableIncludedEmployees: false,
        }
    }
    componentWillMount() {
        this.setState({ isLoading: true });
        fnSearchEmployeeFilter("", data => {
            this.setState({ Employeelist: data, isLoading: false });
            if(this.state.loadedIds !== undefined && this.state.loadedIds !== null && this.state.loadedIds.length > 0){
                this.setState({EmployeeIds: employeeSearchlist(data)});
            }
        }, 0, 1000, this.state.includeDeleted, null, this.state.loadedIds)

        if(this.state.loadedIds !== undefined && this.state.loadedIds !== null && this.state.loadedIds.length > 0){
            this.doSearch("");
        }
        this.searchTimer = null;
    }
    handleSearchChange = (text) => {
        clearTimeout(this.searchTimer);

        if(this.state.searchQuery !== text){
            this.setState({ searchQuery: text, includeDeleted: this.props.includeDeleted !== undefined ? this.props.includeDeleted : false })
            this.searchTimer = setTimeout(this.doSearch, 400, text);
        }
    }
    updateEmployeeOption = (str, disableIncludedEmployees = false) => {
        this.setState({ disableIncludedEmployees}, () => {
            this.doSearch(str);
        });
    }

    doSearch = (str) => {
        const { EmployeeIds, Employeelist } = this.state;
        var selectedEmpIds = [];
        if (EmployeeIds !== null && EmployeeIds !== undefined && Employeelist !== undefined)
            selectedEmpIds = Employeelist.filter(x => EmployeeIds.map(x => x.value).includes(x._id));

        // this.state.disableIncludedEmployees will override includedEmpIds. Variable "includedEmpIds" 
        // came from a prop which will need to be rendered first, in times that "doSearch()" or 
        // "updateEmployeeOption()" has been trigered using react Ref, state will be read first before props,
        // so unupdated rendering will cause the previous props to be read
        
        const includedEmpIds = 
            this.state.disableIncludedEmployees ||
            this.props.includedEmpIds === undefined || 
            this.props.includedEmpIds === null ? null 
                : this.props.includedEmpIds;

        this.setState({ searchQuery: str, isLoading: true, disableIncludedEmployees: false },
            () => fnSearchEmployeeFilter(str, data => {
                selectedEmpIds.forEach(x => { if (!data.includes(x["_id"])) data.push(x) })
                this.setState({ Employeelist: data, isLoading: false });
            }
                , 0, 1000, this.state.includeDeleted, EmployeeIds.map(x => x.value), includedEmpIds)
        )
    }
    handleSearchEmployeeChange = (value) => {
        var ids = [];
        var values = [];
        if(this.state.isMulti){
            if (value != null && !(value === "" || value.length === 0))
                ids = value.map(x => x.value);
            values = value;
        }else{
            ids.push(value.value);
            values.push(value);
        }

        this.setState({ EmployeeIds: values, searchQuery: "" }, () => this.doSearch(""));
        this.props.update(ids);
    }

    render() {
        // var newStyle = this.props.style;
        // if(newStyle === undefined)
        //     newStyle = {};

        return (
            <div style={{width: this.props.width, marginLeft: '5px'}}>
                <Select placeholder='Employees'
                    onChange={this.handleSearchEmployeeChange.bind(this)}
                    options={employeeSearchlist(this.state.Employeelist)}
                    onInputChange={this.handleSearchChange.bind(this)}
                    styles={this.props.errorStyle ? errorStyles : customStyles}
                    value={this.state.isMulti ? this.state.EmployeeIds : this.state.EmployeeIds[0]}
                    isMulti={this.state.isMulti}
                    isDisabled={this.props.disabled === undefined ? false : this.props.disabled}
                    isLoading={this.state.isLoading}
                    searchQuery={this.props.searchQuery}
                />
            </div>
            
        )
    }

}
SelectEmployees.defaultProps = {
    EmployeeIds: [],
    errorStyle: false
}
export default SelectEmployees
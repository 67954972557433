import React, {Component} from "react";
import
{
    Modal,
    Button,
    Image,
    Message
}
from "../../node_modules/semantic-ui-react";
import "../RemoteClockDialog.css";
import "semantic-ui-css/semantic.min.css";
import { render } from "react-dom";

const RemoteClockDialog = (props) =>{
    const handleClose = () =>{
        props.onClick();
    }
    return(
        <Modal size="large" open onClose={handleClose}>
                <Modal.Content>
                    <div className="main-wrapper">
                       <div className="left-wrapper">
                        <label className="header-label">Download Remote Clock</label>
                        <span>A Mobile Application that gives you the Peace of Mind in Clocking IN and OUT, 
                                even outside the company premises. Especially Made for ZKTeco Payroll.</span>
                        <div className="link-wrapper">
                            <div className="qr-container">
                                <label className="subtitle">Scan QR : </label>
                                <img src={"https://staticzktecoph.blob.core.windows.net/static/Images/RemoteClock%20Assets/RemoteClockQRCode.png"} />
                            </div>
                            <div className="link-container">
                                <label className="subtitle">Download via : </label>
                                <div>
                                    <img className="google-play" src={"https://staticzktecoph.blob.core.windows.net/static/Images/RemoteClock%20Assets/googleplay.svg"} 
                                         onClick={()=> {window.open("https://play.google.com/store/apps/details?id=com.zkteco.remoteclock&hl=en")}} />
                                    <img className="app-store" src={"https://staticzktecoph.blob.core.windows.net/static/Images/RemoteClock%20Assets/appstore.svg"} 
                                         onClick={()=> {window.open("https://apps.apple.com/ph/app/remote-clock/id1574125822")}} />
                                </div>
                            </div>
                        </div>
                       </div>
                       <div className="right-wrapper">
                            <img className="rc-background" src={"https://staticzktecoph.blob.core.windows.net/static/Images/RemoteClock%20Assets/rc_background.svg"} />
                       </div>
                    </div>
                </Modal.Content>
                <Modal.Actions>
                    <Button onClick={handleClose}>Close</Button>
                </Modal.Actions>
            </Modal>
    )
}



export default RemoteClockDialog;
import React, { Component } from 'react';
import { Image, Button } from 'semantic-ui-react';
import 'semantic-ui-css/semantic.min.css';
import TransactionModuleBase from './TransactionModuleBase';
import { ajaxPost } from '../../ajax';
import ViewNotificationDetails from './Commons/ViewNotificationDetails';
import ChangeOfRestdayForm from './Forms/ChangeOfRestdayForm';
import { isTaskAvailable, checkDate, checkTime, checkInt32, momentDateFormat } from '../../utils';
import MessageBoxOkOnly from '../../Commons/MessageBoxOkOnly';
import { SelectColumn } from 'react-data-grid';

var ViewID = "ChangeOfRestday";
var ViewCaption = "Change of Restday";

const moment = require('moment');
const updateStatus = "api/ChangeRestDay/updateStatus";
const userRightsKeys = {
	view: "CHANGE_OF_RESTDAY_VIEW",
	create: "CHANGE_OF_RESTDAY_CREATE",
	edit: "CHANGE_OF_RESTDAY_EDIT",
	delete: "CHANGE_OF_RESTDAY_DELETE",
	restore: "CHANGE_OF_RESTDAY_RESTORE",
	approve: "CHANGE_OF_RESTDAY_APPROVE",
	reject: "CHANGE_OF_RESTDAY_REJECT",
	pend: "CHANGE_OF_RESTDAY_PEND",
	cancel: "CHANGE_OF_RESTDAY_CANCEL",
	export: "CHANGE_OF_RESTDAY_EXPORT_TO_EXCEL",
	import: "CHANGE_OF_RESTDAY_IMPORT"
};

class ChangeOfRestdayList extends Component {

	constructor(props) {
		super(props);
		this.state = {
			modalVisible: false,
			fromDate: moment().format(props.startDate),
			toDate: moment().format(props.endDate),
		}
		this.modalRef = React.createRef();
		this.parentRef = React.createRef();
	}

	getDay(day) {
		return ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"][day];
	}

	createUrls = () => {
		return {
			getStatusCounts: "api/ChangeRestDay/getStatusCounts",
			search: "api/ChangeRestDay/search",
			setDeleted: "api/ChangeRestDay/setDeleted",
			saveMultiple: "api/ChangeRestDay/saveMultiple",
			save: "api/ChangeRestDay/save",
			load: "api/ChangeRestDay/load",
			searchEmployees: "api/ChangeRestDay/searchEmployees",
			updateStatus: updateStatus,
			exportToExcel: "api/ChangeRestDay/exportToExcel",
			downloadTemplate: "api/ChangeRestDay/downloadTemplate",
			importFromExcel: "api/ChangeRestDay/importFromExcel"
		};
	}

	toRow = (model) => {
		return {
			id: model._id,
			employeeId: model.EmployeeId,
			deleted: this.isNullOrEmpty(model.IsDeleted) ? false : model.IsDeleted,
			employee: model.Employee,
			month: moment(model.WeekStartDate).format("MMMM YYYY"),
			week: moment(model.WeekStartDate).format("MMM DD") + " - " + moment(model.WeekStartDate).add(6, 'days').format("MMM DD"),
			weekStartDate: moment(model.Date).format(momentDateFormat),
			fromRestDay: this.getDay(model.FromRestDay),
			toRestDay: this.getDay(model.ToRestDay),
			isPermanent: model.IsPermanent ? "YES" : "NO",
			status: model.Status,
			remarks: model.Remarks,
			approver: model.Approver,
			approverId: model.ApproverId,
			approverRemarks: model.ApproverRemarks,
			changeStatusDate: !model.ChangeStatusDate ? "" : moment(model.ChangeStatusDate).format(momentDateFormat),
			requestDate: moment(model.RequestDate).format(momentDateFormat),
			file:model.AttachmentDetail != null ? model.AttachmentDetail.File : "",
			actions:model.AttachmentDetail != null && model.AttachmentDetail.File != "" && model.AttachmentDetail.File != null ?                                               
			<div style={{display:'flex', width:'100%', height:'100%', justifyContent:'center', alignItems:'center' }}><Button 
				style={{fontSize: '0.8rem'}}
				icon="download"
				circular
				onClick={this.downloadDoc.bind(
					this,
					model.AttachmentDetail.UniqueFilename,
					model.AttachmentDetail.File
				  )}
			/> </div>: "",
			AttachmentDetail: model.AttachmentDetail,
			editable: false,
			idle: false
		};
	};

	downloadDoc(filename, origName) {
        window.location =
            global.ApiHost +
            "api/TransactionsCommon/download?" +
            "fileName=" +
            filename +
            "&" +
            "originalName=" +
            origName;
    }

	toModel = (row) => {
		return {
			_id: row.id,
			EmployeeId: row.employeeId,
			WeekStartDate: moment(row.weekStartDate).toJSON(),
			FromRestDay: row.fromRestDay,
			ToRestDay: row.toRestDay,
			IsPermanent: row.isPermanent === "YES" ? true : false,
			Status: row.status,
			File: row.file,
			AttachmentDetail: row.AttachmentDetail,
			Remarks: row.remarks
		};
	};

	createColumns = () => {
		var self = this;
		let hasHrAccess = isTaskAvailable("HR_ROLE");
		let columns = [
			SelectColumn,
			{ key: 'month', name: 'Month', width: 100, resizable: true},
			{ key: 'week', name: 'Week', width: 100, resizable: true},
			{ key: 'fromRestDay', name: 'From Restday', width: 100},
			{ key: 'toRestDay', name: 'To Restday', width: 100, resizable: true},
			{ key: 'status', name: 'Status', width: 90, resizable: true},
			{ key: 'remarks', name: 'Reasons', width: 150, resizable: true},
			{ key: 'changeStatusDate', name: 'Change Status Date', width: 130, resizable: true },
			{ key: 'approver', name: 'Approver', width: 220, resizable: true },
			{ key: 'approverRemarks', name: 'Approver Remarks', width: 150, resizable: true },
			{ key: 'requestDate', name: 'Date Filed', width: 90, resizable: true },
			{ key: 'file', name: 'File', width: 100, resizable: true },
			{ key: 'actions', name: 'Actions', width: 90, resizable: true },
		];
		// if(this.state.viewType == "hr"){
		columns.unshift({
			key: 'employee', name: 'Employee', width: 220, resizable: true
		});
		// }

		return columns;
	}

	validate = (data) => {
		var errTitle = "", errCaption = "";
		var checkDateParam = checkDate(data.WeekStartDate);
		if (this.isNullOrEmpty(data.employeeIds) ||
			this.isNullOrEmpty(data.Remarks)
			// this.isNullOrEmpty(data.FromRestDay) || this.isNullOrEmpty(data.ToRestDay) 
		) {
			errCaption = "Please fill up all required field/s";
			errTitle = "Invalid";
		}
		// if (this.isNullOrEmpty(data.employeeIds)) {
		//     errTitle="Invalid"
		//     errCaption="Select an employee first."
		// }
		else if (this.isNullOrEmpty(data.FromRestDay) || this.isNullOrEmpty(data.ToRestDay)) {
			errCaption = "No schedule found on selected employee";
			errTitle = "Invalid";
		}
		// else if(this.isNullOrEmpty(data.Remarks)) {
		// 	errCaption = "Fill up all required fields";
		// 	errTitle = "Invalid";
		// }
		else if (!checkDateParam.Result) {
			errCaption = checkDateParam.Message;
			errTitle = checkDateParam.Title;
		}
		else {
			this.parentRef.current.setState({ showRequiredField: false });
			return true;
		}
		this.parentRef.current.setState({ showRequiredField: true, errTitle, errCaption });
		return false;
	};

	showModal = (data, visible) => {
		visible && this.modalRef.current.setContent(data);
		this.setState({ modalVisible: visible });
	}

	isNullOrEmpty(val) {
		if (val === null || val === undefined || val === "" || val.length === 0) return true;
		else return false;
	}

	render() {
		let self = this;
		return (
			<TransactionModuleBase
				ref={this.parentRef}
				startDate={this.state.fromDate}
				endDate={this.state.toDate}
				module="Change of Restdays"
				viewID={ViewID}
				title={ViewCaption}
				columns={this.createColumns()}
				toModel={this.toModel}
				toRow={this.toRow}
				urls={this.createUrls()}
				validate={this.validate}
				props={this.props}
				showModal={this.showModal.bind(this)}
				userRightsKeys={userRightsKeys}
				hasExportToExcel={true}
			>
					<ChangeOfRestdayForm
						ref={this.modalRef}
						open={this.state.modalVisible}
						errTitle={self.parentRef.current ? self.parentRef.current.state.errTitle : ""}
						errCaption={self.parentRef.current ? self.parentRef.current.state.errCaption : ""}
						showRequiredField={self.parentRef.current ? self.parentRef.current.state.showRequiredField : ""}
						updateEmployeeIds={(Ids) => { self.parentRef.current.updateEmployeeIds(Ids) }}
						isBusy={self.parentRef.current ? self.parentRef.current.state.isSaving : false}
						onCancel={() => { 
							this.setState({ modalVisible: false }, self.parentRef.current.setState({ showRequiredField: false })); 
						}}
						onCreateEmpClick={() => {
							this.setState({ modalVisible: false });
							this.props.createEmpCallback();
						}}
						onSave={() => {
							if (self.modalRef.current.state._id) {
								self.parentRef.current.editFromModal(
									self.modalRef.current.getContent()
								)
							}
							else {
								self.parentRef.current.saveFromModal(
									self.modalRef.current.getContent()
								);
							}
						}}
					>
					</ChangeOfRestdayForm>
			</TransactionModuleBase>
		);
	}

}

ChangeOfRestdayList.getViewID = () => { return ViewID };
ChangeOfRestdayList.getCaption = () => { return ViewCaption }
ChangeOfRestdayList.getIcon = () => { return (<Image style={{ "height": "24px", "width": "24px" }} src='/Icons/Change_Restday.png' avatar />); }
ChangeOfRestdayList.getIconSrc = (size) => { return '/Icons/Change_Restday.png' }
ChangeOfRestdayList.getViewAccessKey = function () {
	var count = Object.keys(userRightsKeys).filter(name => isTaskAvailable(userRightsKeys[name]) === true).length;
	return count > 0 ? true : false;
};
ChangeOfRestdayList.init = function (parent) {
	var handleChangeRestdayAction = function (status, data, callback) {
		var message = "";
		var title = data.Title;
		if (status === "CLOSE") 
			parent.setState({ external: null })
		else {
			var items = [];
			items.push(data.Data);
			var dataItems = { items: items, status: status, remarks: data.Data.ApproverRemarks };
			dataItems.fromDate = moment(data.Data.WeekStartDate).toJSON();
			dataItems.toDate = moment(data.Data.WeekStartDate).toJSON();
			var parameter = {
				data: dataItems,
				url: updateStatus,
				onError: function (error) {
					alert("Error occured");
				},
				onSuccess: (data, sender) => {
					if (data[data.length - 1].hasOwnProperty("errMessage")) {
						let lastIndex = data.length - 1;
						let titleSingular = title.toLowerCase() === 'official businesses' ? title.substring(0, title.length - 2) : title.substring(0, title.length - 1);
						title = "Invalid";
						message = status === "APPROVED" ? "Already approved " + titleSingular.toLowerCase() + " request has been found." : data[lastIndex]["errMessage"];
					}
					else {
						message = data[0].Message;
					}
					parent.setState({
						external:
							<MessageBoxOkOnly
								title={title}
								caption={message}
								onClick={() => {
									parent.setState({ external: null });
								}}
							/>
					});
				},
				finally: function () {
					callback(message, status);
				}
			};
			ajaxPost(parameter);
		}
	};
	var notificationAction =
		function (parent, action, data, callback) {
			var status = "";
			switch (action.Action) {
				case "Approve": status = "APPROVED"; break;
				case "Reject": status = "REJECTED"; break;
				case "Cancel": status = "CANCELLED"; break;
				default: status = "View"; break;
			}

			//var actionTaken = data["ActionSelection"];
			if (data["ActionTaken"] === "NONE" && data["IsActionable"] === false) {
				data["ActionTaken"] = "READ";
				ajaxPost(
					{
						url: "api/Notification/markAsRead",
						data: data._id,
						onSuccess: data => { },
						finally: () => { }
					}
				)
			}

			ajaxPost({
				url: "api/Notification/loadDataOnly",
				data: data["_id"],
				onSuccess: x => {
					data["Data"] = x;
				},
				finally: () => {
					data["ActionTaken"] = data["ActionTaken"].toString() === "" ? "NONE" : data["ActionTaken"];
					if (status === "View") {
						var viewData = {
							"Month": moment(data.Data["WeekStartDate"]).format("MMMM YYYY"),
							"Week": moment(data.Data["WeekStartDate"]).format("MMM DD") + " - " + moment(data.Data["WeekStartDate"]).add(6, 'days').format("MMM DD"),
							"From Restday": ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"][data.Data["FromRestDay"]],
							"To Restday": ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"][data.Data["ToRestDay"]],
							// "Is Permanent?" : data.Data["IsPermanent"] ? "Yes" : "No",
							"Status": data.Data["Status"],
							"Remarks": data.Data["Remarks"],
							"Description": data["Description"],
						};
						if (data.Data["ApproverRemarks"]) {
							viewData["Approver Remarks"] = data.Data["ApproverRemarks"];
						}
						parent.setState({ external: <ViewNotificationDetails Title={data.Title} data={data} viewData={viewData} callback={callback} onAction={this.handleChangeRestdayAction.bind(this)} /> });
					}
					else {
						data.Data["ApproverRemarks"] = "";
						this.handleChangeRestdayAction(status, data, callback);
					}
				}
			});
		}
	parent.FunctionCollection["changeOfRestdayNotification"] = notificationAction;
	parent.FunctionCollection["handleChangeRestdayAction"] = handleChangeRestdayAction;
}

export default ChangeOfRestdayList;
import React, { Component } from 'react';
import 'semantic-ui-css/semantic.min.css';
import { Popup, Dropdown, Button, Menu, Image, Header, Pagination, Icon, Modal, Checkbox, Grid, Loader, Card, Input, Segment, Search } from 'semantic-ui-react';
import MessageBoxYesNo from '../../Commons/MessageBoxYesNo';
import MessageBoxOkOnly from '../../Commons/MessageBoxOkOnly';
import { ajaxPost } from '../../ajax';
import { isTaskAvailable, customStyles } from '../../utils';
import LeaveTypeForm from './LeaveTypeForm';
import Select from 'react-select';
import DataGrid, {SelectColumn} from 'react-data-grid';

import modStyles from './LeaveTypeList.module.css';

import _ from 'lodash';


const ViewID = "LeaveTypeModule";
const ViewCaption = "Leave Type Management";
const moment = require('moment');
const allEmployeeAccessString = "VIEW_ALL_EMPLOYEES";
var delayTimer = null;

const userRightsKeys = {
    view: "LEAVE_TYPE_VIEW",
    create: "LEAVE_TYPE_CREATE",
    edit: "LEAVE_TYPE_EDIT",
    delete: "LEAVE_TYPE_DELETE",
    restore: "LEAVE_TYPE_RESTORE",
    export: "LEAVE_TYPE_EXPORT_TO_EXCEL"
}

const urls = {
    search: "api/LeaveType/search",
    setDeleted: "api/LeaveType/setDeleted",
    saveMultiple: "api/LeaveType/saveMultiple",
    save: "api/LeaveType/save",
    load: "api/LeaveType/load",
    exportToExcel: "api/LeaveType/exportToExcel",
}

class LeaveTypeModule extends Component {

    constructor(props) {
        super(props);
        this._columns = [
            SelectColumn,
            { key: 'code', name: 'Leave Code', width: 120, resizable: true },
            { key: 'name', name: 'Name', width: 200, resizable: true },
            { key: 'description', name: 'Description', width: 400, resizable: true },
            { key: 'useCalendarDays', name: 'Use Calendar Days?', resizable: true },
        ];
        this.modalRef = React.createRef();
    }

    componentWillMount() {
        window.addEventListener('resize', this.handleResize);
        this.handleResize = this.handleResize.bind(this);

        if (this.props.prevState !== undefined) {
            this.state = this.props.prevState;
        }
        else {
            this.state = {
                leaveTypeData: [],
                //selectedLeaveTypeId: "",
                selectedLeaveTypeIds: [],
                selectedLeaveType: [],

                searchQuery: "",
                searchResults: [],
                searchLoading: false,
                rows: [],
                selectedRowIds: new Set(),
                userId: null,
                hasHrAccess: isTaskAvailable(allEmployeeAccessString),
                isLoading: true,
                isSaving: false,
                isBusy: false,
                activePage: 1,
                totalPages: 0,
                itemsPerPage: 20,
                includeDeleted: false,
                isBusy: false,

                showNewButton: true,
                showDeleteButton: false,
                showEditButton: false,
                showImportButton: false,
                showExportButton: false,

                hideSearchToolbar: false,
                mode: "DEFAULT",

                showRestoreModal: false,
                showDeleteModal: false,
                showCancelModal: false,
                showFiltersModal: false,

                messageBoxOkOnly: {
                    show: false,
                    title: "",
                    caption: "",
                },
                isMobileView: this.isMobileView(),
                showRequiredField: false,
                errTitle: "",
                errCaption: "",

            };

            this.onLeaveTypeKeyPress("");
            this.search();
        }
    }

    isMobileView() {
        var isMobile = window.innerWidth <= 760;
        return isMobile;
    }

    messageBoxOkOnlyOpen = (isOpen, title = "", caption = "") => {
        var messageBoxOkOnly = {
            show: isOpen,
            title: title,
            caption: caption,
        };
        this.setState({ messageBoxOkOnly });
    }

    componentDidMount() {
        this.getUserId();
        this.handleResize();
    }

    componentWillUnmount = () => {
        this.props.unmountCallback(this.state, ViewID);
        window.removeEventListener('resize', this.handleResize);
    }

    onLeaveTypeChange = (value) => {
        //var ids = value === "" ? "" : value.split(',');     
        //this.setState({ EmployeeIds: ids, searchQuery: ""}, () => this.doSearch(""));
        //let searchQuery = this.state.leaveTypeData.find(item => item.value === value).text;
        this.setState({
            searchQuery: value.map(x => x.value),
            selectedLeaveTypeIds: value.map(x => x.value),
            selectedLeaveType: value
        },
            () => { this.search(); }
        );
    }

    onLeaveTypeKeyPress = (value) => {
        this.setState({ isBusy: true });
        var self = this;
        //var value = event.target.value 
        clearTimeout(delayTimer);
        delayTimer = setTimeout(() => {
            var parameter = {
                search: value,
                includeDeleted: false,
                startIndex: 0,
                itemCount: 15
            };
            var searchParameter = {
                data: parameter,
                url: "api/LeaveType/search",
                onError: (error) => {
                    //self.setState({ leaveTypeData: [] });
                },
                onSuccess: (data, sender) => {
                    var x = data;
                    var newOptions = data.content.map((item) => {
                        return {
                            //: item._id, 
                            label: item.Name + " (" + item.Code + ")",
                            value: item._id,
                            text: item.Name,
                        }
                    });

                    var previousSelectedOptions = [];
                    var previousSelectedIds = this.state.searchQuery;
                    if (previousSelectedIds.length > 0) {
                        previousSelectedIds.forEach(id => {
                            let hasPreviousData = self.state.leaveTypeData.filter(leave => leave.value === id).length > 0;
                            if (hasPreviousData) {
                                let index = self.state.leaveTypeData.indexOf(self.state.leaveTypeData.filter(leave => leave.value === id)[0]);
                                if (index !== -1) previousSelectedOptions.push(self.state.leaveTypeData[index]);

                            }
                        });
                    }

                    previousSelectedOptions.forEach(opt => {
                        let index = newOptions.indexOf(opt);
                        if (index === -1) newOptions.push(opt);
                    });

                    self.setState({ leaveTypeData: newOptions });
                },
                finally: () => {
                    self.setState({ isBusy: false });
                }
            };
            ajaxPost(searchParameter);
        }, 200);
    };

    search = () => {
        var parameter = {
            search: this.state.searchQuery,
            includeDeleted: this.state.includeDeleted,
            startIndex: (this.state.activePage - 1) * this.state.itemsPerPage,
            itemCount: this.state.itemsPerPage
        };
        var searchParameter = {
            data: parameter,
            url: urls.search,
            onError: (error) => {
                var errMessage = "An error has occured";
                if (error) {
                    errMessage += "\r\n" + JSON.stringify(error);
                }
                this.messageBoxOkOnlyOpen(true, "Error", errMessage);
            },
            onSuccess: (data, sender) => {
                var rows = data.content.map(model => { return this.toRow(model) });
                var totalPages = data.count / this.state.itemsPerPage;
                totalPages -= totalPages % 1;
                if (data.count > totalPages * this.state.itemsPerPage) {
                    totalPages++;
                }
                if (rows.length > 0) {
                    rows.sort(
                        (a, b) => {
                            var x = a.code.toLowerCase();
                            var y = b.code.toLowerCase();
                            if (x < y) { return -1; }
                            if (x > y) { return 1; }
                            return 0;
                        }
                    );
                }

                this.setState(
                    {
                        rows: rows,
                        totalPages: totalPages,
                        selectedIndices: [],
                        selectedRowIds: new Set(),
                        mode: "DEFAULT"
                    },
                    () => { this.updateButtonsVisibility(); }
                );
            },
            finally: () => {
                this.setState({ isLoading: false });
            }
        };
        ajaxPost(searchParameter);
    }

    handleResize = () => {
        //
        this.setState({ isMobileView: this.isMobileView() });
        if (document.getElementById("table") === null) {
            return;
        }
        let headerMargin = 50;
        let footerMargin = 35;
        let gridTop = (document.getElementById("table")) == null ? 50 : (document.getElementById("table")).offsetTop;
        var gridHeight = window.innerHeight - (headerMargin + gridTop + footerMargin);
        this.setState({ gridHeight });
    }

    updateButtonsVisibility = () => {
        var hasHrAccess = this.state.hasHrAccess;
        //let userId = this.state.userId;
        var mode = this.state.mode;
        if (mode === "DEFAULT") {
            let deletableItemsCount = 0;
            let selectedRows = [];
            if (this.state.selectedRowIds.size > 0) {
                this.state.selectedRowIds.forEach((rowId) => {
                    selectedRows.push(this.state.rows.filter(row => row.id === rowId)[0]);
                });
                deletableItemsCount = selectedRows.filter(function (item) { return !item.deleted && item.editable }).length;
            }

            this.setState({
                showNewButton: true,
                showDeleteButton: this.state.selectedRowIds.size > 0 && deletableItemsCount > 0,
                showEditButton: isTaskAvailable(userRightsKeys.edit) && this.state.selectedRowIds.size === 1,
                //showExportButton: false,
                hideSearchToolbar: false,
            });
        }
    };

    getUserId = () => {
        var parameter = {
            data: {},
            url: "api/TransactionsCommon/getUserId",
            onSuccess: (data, sender) => {
                this.setState({ userId: data });
            },
            onError: (error) => {
                var errMessage = "An error has occured";
                if (error) {
                    errMessage += "\r\n" + JSON.stringify(error);
                }
                this.messageBoxOkOnlyOpen(true, "Error", errMessage);
            },
            finally: () => {

            }
        }
        ajaxPost(parameter);
    }
/*
    onCheckBoxHeader_Checked = (event, data) => {
        var checked = data.checked;
        var selectedRowIds = [];
        if (checked) {
            this.state.rows.forEach(row => {
                let rowId = row.id;
                selectedRowIds = selectedRowIds.concat(rowId);
            });
        }
        this.setState({ selectedRowIds: selectedRowIds },
            () => { this.updateButtonsVisibility() }
        );
    }

    onCheckBoxSelectAll_Checked = (event, data) => {
        this.state.rows
            .filter((rowd, index) => index === 0 || rowd.employee !== this.state.rows[index - 1].employee)
            .forEach(rowEmp => {
                document.getElementById(rowEmp.employeeId).check = data.checked;
            });
    }

    onCheckBoxHeaderEmp_Checked = (event, data) => {
        var checked = data.checked;
        var employeeId = data.taggedId;
        var selectedRowIds = this.state.selectedRowIds;
        this.state.rows.filter(row => row.employeeId === employeeId).forEach(row => {
            let rowId = row.id;
            if (checked) {
                if (selectedRowIds.indexOf(rowId) === -1) selectedRowIds = selectedRowIds.concat(rowId);
            }
            else {
                selectedRowIds = selectedRowIds.filter(e => e !== rowId);
            }
        });
        this.setState({ selectedRowIds: selectedRowIds },
            () => { this.updateButtonsVisibility() }
        );
    }

    onCheckBox_Checked = (event, data) => {
        var checked = data.checked;
        var rowId = data.taggedId;
        var selectedRowIds = this.state.selectedRowIds;
        if (checked && (rowId !== null || rowId !== undefined)) {
            selectedRowIds = selectedRowIds.concat(rowId);
        }
        else {
            //     let index = selectedRowIds.indexOf(rowId); 
            //     if (index !== -1) selectedRowIds = selectedRowIds.splice(index ,1);
            selectedRowIds = selectedRowIds.filter(e => e !== rowId);
        }
        this.setState({ selectedRowIds: selectedRowIds },
            () => { this.updateButtonsVisibility() }
        );
    }
*/
    btnSearch_click = (event, { content }) => {
        this.setState({ activePage: 1 }, () => { this.search() });
    };

    btnAdd_click = (event) => {
        var self = this;
        var requestParameter = {
            data: { "_id": "" },
            url: urls.load,
            onSuccess: (data, sender) => {
                self.setState({ showRequiredField: false },
                    () => { self.showModal(data, true); }
                );
            },
            onError: (error) => {
                var errMessage = "Failed loading new record.";
                this.messageBoxOkOnlyOpen(true, "Error", errMessage);
            },
            finally: () => { }
        };
        ajaxPost(requestParameter);
    }

    showModal = (data, visible) => {
        visible && this.modalRef.current.setContent(data);
        this.setState({ modalVisible: visible });
    }

    editFromModal = (model) => {
        let isValid = false;
        isValid = this.validate(model);
        if (!isValid) {
            return;
        }

        var parameter = {
            data: { model: JSON.stringify(model) },
            url: urls.save,
            onSuccess: (data, sender) => {
                if (data.hasOwnProperty("errors") && data.errors.length > 0) {
                    isValid = false;
                    this.setState({
                        showRequiredField: true,
                        errTitle: "Invalid",
                        errCaption: data.errors.map(err => { return (<p>{err}</p>) })
                    });
                    return;
                }
                let tableRow = this.toRow(data.content);
                let rows = this.state.rows.slice();
                let targetIndex = -1;
                rows.forEach((item, index) => {
                    if (item.id === tableRow.id) {
                        targetIndex = index;
                    }
                })
                rows[targetIndex] = tableRow;
                this.setState({ rows: rows },
                    () => { this.messageBoxOkOnlyOpen(true, "Success", "Leave type successfully updated"); }
                );
            },
            onError: (error) => {
                var errMessage = "An error has occured";
                if (error) {
                    errMessage += "\r\n" + JSON.stringify(error);
                }
                this.messageBoxOkOnlyOpen(true, "Error", errMessage);
            },
            finally: () => {
                // close modal 
                if (isValid) this.showModal({}, false);
            }
        }
        ajaxPost(parameter);
    }

    saveFromModal = (model) => {
        let self = this;
        let isValid = false;
        isValid = this.validate(model);
        if (!isValid) {
            return;
        }

        var parameter = {
            data: { model: JSON.stringify(model) },
            url: urls.save,
            onSuccess: (data, sender) => {
                if (data.hasOwnProperty("errors") && data.errors.length > 0) {
                    isValid = false;
                    this.setState({
                        showRequiredField: true,
                        errTitle: "Invalid",
                        errCaption: data.errors.map(err => { return (<p>{err}</p>) })
                    });
                    return;
                }
                isValid = true;
                let rows = self.state.rows.slice();
                rows.unshift(self.toRow(data.content));
                self.setState({
                    rows: rows,
                    selectedRowIds: new Set(),
                    selectedIndices: [],
                },
                    () => { self.messageBoxOkOnlyOpen(true, "Success", "New leave type added") }
                );
            },
            onError: (error) => {
                isValid = false;
                var errMessage = "An error has occured";
                if (error) {
                    errMessage += "\r\n" + JSON.stringify(error);
                }
                this.messageBoxOkOnlyOpen(true, "Error", errMessage);
            },
            finally: () => {
                // close modal 
                if (isValid) self.showModal({}, false);
            }
        }
        ajaxPost(parameter);
    }

    btnEdit_Click = (row, event, { target }) => {
        var rowId = row.id;
        //var hasHrAccess = this.state.hasHrAccess;
        var requestParameter = {
            data: { "_id": rowId },
            url: urls.load,
            onSuccess: (data, sender) => {
                if (!data.Editable) {
                    this.messageBoxOkOnlyOpen(true, "Unable to edit", "This record has been disabled for editing.");
                    this.search();
                    return;
                }
                else if (data.Deleted === true) {
                    this.messageBoxOkOnlyOpen(true, "Unable to edit", "This record no longer exists or has been deleted.");
                    this.search();
                    return;
                }
                this.showModal(data, true);
            },
            onError: (error) => {
                this.messageBoxOkOnlyOpen(true, "Error", "Failed loading record.");
            },
            finally: () => { }
        };
        ajaxPost(requestParameter);
    };

    btnDelete_Click = (row, event) => {
        //let hasHrAccess = isTaskAvailable("VIEW_ALL_EMPLOYEES");
        let rowId = row.id;
        var requestParameter = {
            data: { "_id": rowId },
            url: urls.load,
            onSuccess: (data, sender) => {
                this.setState({
                    showDeleteModal: !row.Deleted,
                    showRestoreModal: row.Deleted,
                    selectedRow: row
                });
            },
            onError: (error) => {
                var errMessage = "Failed deleting record.";
                if (error) {
                    errMessage += "\r\n" + JSON.stringify(error);
                }
                this.messageBoxOkOnlyOpen(true, "Error", errMessage);
            },
            finally: () => { }
        };
        ajaxPost(requestParameter);
    }

    setDeleted = (deleted) => {
        var self = this;
        let ids = [];
        let selectedRows = [];
        let deletableItems = [];
        let hasHrAccess = this.state.hasHrAccess;
        //let userId = this.state.userId;

        this.state.selectedRowIds.forEach((rowId) => {
            selectedRows.push(this.state.rows.filter(row => row.id === rowId)[0]);
        });

        deletableItems = selectedRows.filter(function (item) { return !item.deleted && item.editable });

        deletableItems.forEach(item => {
            ids.push(item.id);
        });

        //ids = this.state.selectedRowIds;
        var parameter = {
            data: {
                ids: ids,
                deleted: deleted
            },
            url: urls.setDeleted,
            onError: function (error) {
                var errMessage = "An error has occured";
                if (error) {
                    errMessage += "\r\n" + JSON.stringify(error);
                }
                self.setState({ showRestoreModal: false, showDeleteModal: false },
                    () => { self.messageBoxOkOnlyOpen(true, "Error", errMessage); }
                );
            },
            onSuccess: (data, sender) => {
                if (data.hasOwnProperty("errors") && data.errors.length > 0) {
                    self.setState({ showRestoreModal: false, showDeleteModal: false },
                        () => { self.messageBoxOkOnlyOpen(true, "Error", data.errors.map(err => { return (<p>{err}</p>) })); }
                    );
                }
                else if (data.hasOwnProperty("content") && data.content.IsSuccessful) {
                    var rows = self.state.rows.slice();
                    deletableItems.forEach(item => {
                        var rowIndex = rows.findIndex((r => r.id === item.id));
                        rows[rowIndex].deleted = deleted;
                        rows = rows.filter(row => row.id != item.id).slice();
                    });
                    self.setState({
                        rows: rows,
                        selectedRow: {},
                        selectedRowIds: new Set(),
                        selectedIndices: [],
                        showRestoreModal: false,
                        showDeleteModal: false
                    },
                        function () { self.updateButtonsVisibility() }
                    );
                }
            },
            finally: function () { }
        };
        ajaxPost(parameter);
    };

    validate = (data) => {
        var errTitle = "", errCaption = "";
        if (this.isNullOrEmpty(data.Code)
            || this.isNullOrEmpty(data.Name)
            // || this.isNullOrEmpty(data.Description)  // requested to remove description as required, Bug 8935: Leave Type Required Fields
        ) {
            errTitle = "Invalid"
            errCaption = "Fill up all required fields."
        }
        else {
            this.setState({ showRequiredField: false });
            return true;
        }
        this.setState({ showRequiredField: true, errTitle, errCaption });
        return false;
    };

    handlePageChange(event, { activePage }) {
        var self = this;
        if (self.state.mode !== "DEFAULT") {
            return
        }
        var page = activePage - (activePage % 1);
        if (page < activePage) {
            page++;
        }
        this.setState({ activePage: page }, function () {
            this.search();
        });;
    }

    isNullOrEmpty(val) {
        if (val === null || val === undefined || val === "" || val.length === 0) return true;
        else return false;
    }


    onRowsSelected = (rows) => {
        this.setState({ selectedRowIds: rows },
            () => { this.updateButtonsVisibility(); }
        );
    };

    onRowsDeselected = (rows) => {
        let rowIndexes = rows.map(r => r.rowIdx);
        let selectedIndices = this.state.selectedIndices.filter(i => rowIndexes.indexOf(i) === -1);
        let selectedRowIds = this.state.selectedRowIds.filter(id => rows.map(r => r.row.id).indexOf(id) === -1);
        this.setState(
            {
                selectedIndices, selectedRowIds
            },
            function () {
                this.updateButtonsVisibility();
            }
        );
    };

    toRow = (model) => {
        return {
            id: model._id,
            code: model.Code,
            name: model.Name,
            description: model.Description,
            useCalendarDays: model.UseCalendarDays ? "Yes" : "No",
            deleted: model.Deleted === undefined ? false : model.Deleted,
            editable: model.Editable,
            idle: false,
        };
    };

    toModel = (row) => {
        let model = {
            _id: row.id,
            Code: row.code,
            Name: row.name,
            Description: row.description,
            UseCalendarDays: row.useCalendarDays === "Yes",
        };
        return model;
    };

    rowKeyGetter(row){
        return row.id;
    }

    render() {

        let self = this;
        let userId = this.state.userId;
        let hasHrAccess = this.state.hasHrAccess;
        //let userRightsKeys = this.props.userRightsKeys; 
        let actionableCount =
            Number(isTaskAvailable(userRightsKeys.create)) +
            Number(isTaskAvailable(userRightsKeys.edit) && window.innerWidth >= 992) +
            //Number(isTaskAvailable(userRightsKeys.edit)) +
            //Number(isTaskAvailable(userRightsKeys.export)) +
            Number(isTaskAvailable(userRightsKeys.delete));
        let actionableCountRatio = (1 / actionableCount).toFixed(2) + " %";

        var arrSelected = Array.from(this.state.selectedRowIds);

        //let selectedRow = this.state.selectedRowIds.size > 0 ? this.state.rows.find( this.state.selectedRowIds[0]) : undefined;

        return <>
            <div className={modStyles.container}>
                <Header as='h2'>{ViewCaption}</Header>
                <div style={{ marginTop: '20px' }}>
                    <div className='w3-row'>
                        <div className={'w3-hide-small w3-col l12'}>
                            <Pagination
                                activePage={this.state.activePage}
                                defaultActivePage={1}
                                pointing
                                secondary
                                //ellipsisItem={{ content: <Icon name='ellipsis horizontal' />, icon: true }}
                                firstItem={{ content: <Icon name='angle double left' />, icon: true }}
                                lastItem={{ content: <Icon name='angle double right' />, icon: true }}
                                prevItem={{ content: <Icon name='angle left' />, icon: true }}
                                nextItem={{ content: <Icon name='angle right' />, icon: true }}
                                size='mini'
                                siblingRange={2}
                                boundaryRange={0}
                                totalPages={this.state.totalPages}
                                onPageChange={this.handlePageChange.bind(this)}
                                floated='right'
                            />
                        </div>
                        <div className={'w3-hide-medium w3-hide-large w3-col l12'}>
                            <Pagination
                                activePage={this.state.activePage}
                                defaultActivePage={1}
                                pointing
                                secondary
                                //ellipsisItem={{ content: <Icon name='ellipsis horizontal' />, icon: true }}
                                firstItem={{ content: <Icon name='angle double left' />, icon: true }}
                                lastItem={{ content: <Icon name='angle double right' />, icon: true }}
                                prevItem={{ content: <Icon name='angle left' />, icon: true }}
                                nextItem={{ content: <Icon name='angle right' />, icon: true }}
                                size='mini'
                                siblingRange={1}
                                boundaryRange={0}
                                totalPages={this.state.totalPages}
                                onPageChange={this.handlePageChange.bind(this)}
                                floated='right'
                            />
                        </div>
                    </div>
                </div>

                <div>
                    <div className='w3-row' style={{ width: '100%', minHeight: '50px', marginTop: '10px' }} fitted='horizontally'>
                        <div className='w3-col l3'>
                            <table style={{ width: '100%', marginTop: '10px' }}>
                                <tr>
                                    {isTaskAvailable(userRightsKeys.create) &&
                                        <td style={{ width: actionableCountRatio }}>
                                            <Popup trigger={
                                                <Button
                                                    className='w3-hide-small w3-hide-medium'
                                                    fluid
                                                    size="mini"
                                                    iconPosition="top"
                                                    icon='plus'
                                                    onClick={this.btnAdd_click}
                                                >
                                                </Button>
                                            } inverted content='Create New' />
                                            <Button
                                                className='w3-hide-large'
                                                size="mini"
                                                iconPosition="top"
                                                icon="plus"
                                                content='Create New'
                                                fluid
                                                onClick={this.btnAdd_click}
                                            />
                                        </td>
                                    }
                                    {isTaskAvailable(userRightsKeys.edit) &&
                                        <td style={{ width: actionableCountRatio }}>
                                            <Popup trigger={
                                                <Button
                                                    className='w3-hide-small w3-hide-medium'
                                                    fluid
                                                    size="mini"
                                                    iconPosition="top"
                                                    icon="edit"
                                                    onClick={this.btnEdit_Click.bind(this, this.state.rows.find(row => row.id == arrSelected[0]))}
                                                    disabled={!this.state.showEditButton}
                                                />
                                            } inverted content='Edit' />
                                        </td>
                                    }
                                    {isTaskAvailable(userRightsKeys.delete) &&
                                        <td style={{ width: actionableCountRatio }}>
                                            <Popup className='w3-hide-small w3-hide-medium' trigger={
                                                <Button
                                                    className='w3-hide-small w3-hide-medium'
                                                    fluid
                                                    size="mini"
                                                    iconPosition="top"
                                                    icon="delete"
                                                    onClick={() => { this.setState({ showDeleteModal: true }); }}
                                                    disabled={!this.state.showDeleteButton}
                                                />
                                            } inverted content='Delete' />
                                            <Button
                                                className='w3-hide-large'
                                                fluid
                                                size="mini"
                                                iconPosition="top"
                                                icon="delete"
                                                content='Delete'
                                                onClick={() => { this.setState({ showDeleteModal: true }); }}
                                                disabled={!this.state.showDeleteButton}
                                            />
                                        </td>
                                    }
                                </tr>
                            </table>
                        </div>
                        <div className='w3-col l9'>
                            <div className='w3-hide-small w3-hide-medium w3-col l10' style={{ paddingTop: '10px', paddingLeft: '3px', paddingRight: '3px' }} fitted='horizontally'>
                                <Menu secondary size='mini' floated='right'>
                                    <div style={{ width: '100%' }}>
                                        <div style={{ width: 300 }}>
                                            <Menu secondary size='mini' style={{ width: '100%' }}>
                                                <div style={{ width: '100%' }}>
                                                    <Select
                                                        //placeholder='Leave Type'
                                                        onChange={this.onLeaveTypeChange.bind(this)}
                                                        options={this.state.leaveTypeData}
                                                        onInputChange={this.onLeaveTypeKeyPress}
                                                        styles={customStyles}
                                                        isMulti
                                                        isLoading={this.state.isBusy}
                                                        searchQuery={this.state.searchQuery}
                                                        //value={this.state.selectedLeaveTypeId}
                                                        value={this.state.selectedLeaveType}
                                                    />
                                                </div>
                                            </Menu>
                                        </div>
                                    </div>
                                </Menu>
                            </div>
                            <div className='w3-hide-small w3-hide-medium w3-col l2' style={{ paddingTop: '10px' }} >
                                <Button id='ButtonSearch' floated='left' size='mini' fluid onClick={this.btnSearch_click} style={{ paddingLeft: '3px', paddingRight: '3px' }}> Search</Button>
                            </div>
                            <div className='w3-hide-large w3-col l10' style={{ paddingTop: '10px', paddingLeft: '3px', paddingRight: '3px' }} fitted='horizontally'>
                                <table style={{ width: '100%' }}>
                                    <tr>
                                        <td style={{ width: '90%' }}>
                                            <Menu secondary size='mini' style={{ width: '100%' }}>
                                                <div style={{ width: '100%' }}>
                                                    <Select
                                                        //placeholder='Leave Type'
                                                        onChange={this.onLeaveTypeChange.bind(this)}
                                                        options={this.state.leaveTypeData}
                                                        onInputChange={this.onLeaveTypeKeyPress}
                                                        styles={customStyles}
                                                        isMulti
                                                        isLoading={this.state.isBusy}
                                                        searchQuery={this.state.searchQuery}
                                                        //value={this.state.selectedLeaveTypeId}
                                                        value={this.state.selectedLeaveType}
                                                    />
                                                </div>
                                            </Menu>
                                        </td>
                                        <td style={{ width: '10%' }}>
                                            <Button className='w3-hide-medium w3-hide-large' fluid size='mini' icon='search' type='submit' onClick={this.btnSearch_click} />
                                            <Button className='w3-hide-small' fluid size='mini' type='submit' style={{ 'max-height': '31px', marginLeft: '6px' }} onClick={this.btnSearch_click}> Search</Button>
                                        </td>
                                    </tr>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>

                <Loader size="big" active={this.state.isLoading}></Loader>

                <DataGrid
                    ref={node => this.grid = node}
                    enableCellSelect={true}
                    columns={this._columns}
                    rows={this.state.rows}
                    rowsCount={this.state.rows.length}
                    minHeight={this.state.gridHeight}
                    selectedRows={this.state.selectedRowIds}
                    rowKeyGetter = {this.rowKeyGetter}
                    onSelectedRowsChange={this.onRowsSelected}
                    
                    
                />

                {this.state.showDeleteModal &&
                    <MessageBoxYesNo
                        title="Confirm Delete"
                        caption={"Delete selected leave type" + (this.state.selectedRowIds.size > 1 ? "s" : "") + "?"}
                        action="Delete"
                        onAction={() => { this.setDeleted(true) }}
                        onClose={() => { this.setState({ showDeleteModal: false }) }} />
                }

            </div>
            {this.state.messageBoxOkOnly.show &&
                    <MessageBoxOkOnly
                        title={this.state.messageBoxOkOnly.title}
                        caption={this.state.messageBoxOkOnly.caption}
                        onClick={() => { this.messageBoxOkOnlyOpen(false); }}
                    />
                }

                <LeaveTypeForm
                    ref={this.modalRef}
                    open={this.state.modalVisible}
                    errTitle={this.state.errTitle}
                    errCaption={this.state.errCaption}
                    showRequiredField={this.state.showRequiredField}
                    onCancel={() => { self.setState({ modalVisible: false, showRequiredField: false }); }}
                    onSave={() => {
                        if (self.modalRef.current.state._id) {
                            self.editFromModal(
                                self.modalRef.current.getContent()
                            );
                        }
                        else {
                            self.saveFromModal(
                                self.modalRef.current.getContent()
                            );
                        }
                    }}
                >
                </LeaveTypeForm>
        </>
    }
}
LeaveTypeModule.getViewID = () => { return ViewID; };
LeaveTypeModule.getCaption = () => { return ViewCaption; };
LeaveTypeModule.getIcon = () => { return (<Image style={{ "height": "24px", "width": "24px" }} src='/Icons/Leave_Type_Management.png' avatar />); }
LeaveTypeModule.getIconSrc = (size) => { return '/Icons/Leave_Type_Management.png' }
LeaveTypeModule.getViewAccessKey = function () {
    var count = Object.keys(userRightsKeys).filter(name => isTaskAvailable(userRightsKeys[name]) === true).length;
    return count > 0 ? true : false;
};


export default LeaveTypeModule;
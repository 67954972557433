import React, { Component } from 'react';
import { ajaxPost, fetchGet, fetchPost } from './ajax';
import { Input, Button } from 'semantic-ui-react';
import MessageBoxOkOnly from './Commons/MessageBoxOkOnly'
import isMailFine from 'is-mail-fine'
import moment from 'moment';

import styles from './forgotPassword.module.css';

var passwordValidator = require('password-validator');

class ForgotPassword extends Component {

    constructor(props) {
        super(props);

        this.state =
        {
            email: "",
            code: "",
            password: "",
            confirmPassword: "",
            message: "",
            popupOpen: false,
            busy: false,
            isRedirectToLogin: false,
            id: "",
            action: "email",
            invalidCode: false,
            passwordError: "",
        }
    }

    handleChange = (name, e, input) => {
        this.setState({ [name]: input.value });
    }

    emailEntered = async (e) => {
        if (!isMailFine(this.state.email)) {
            this.setState({ message: "Please enter a valid email address", popupOpen: true });
            return;
        }


        this.setState({ busy: true });
        let redirLogin = false;
        var resp = await fetchGet(`/Employee/forgotPassword?email=${this.state.email}`);

        if (resp.ok) {
            const id = await resp.text();
            this.setState({
                id,
                action: "code",
                busy: false
            });
        }
        else if (resp.status == 404) {
            this.setState({ message: "The provided email address doesn't exist in the records", popupOpen: true, busy: false });
        }
        else {
            var next = await resp.json();
            var dt = moment.utc(next);

            this.setState({
                message: `We detected too many forgot password requests on this email.  For your protection, we have disabled further requests until ${dt.local().toString()}`,
                popupOpen: true,
                isRedirectToLogin: true
            });

        }

    }

    codeEntered = async (e) => {
        this.setState({
            busy: true,
            invalidCode: false
        });

        var resp = await fetchGet(`/Employee/ValidateOtp?id=${this.state.id}&code=${this.state.code}`);

        if (resp.ok) {
            this.setState({
                action: "password",
                busy: false
            });
        }
        else if (resp.status == 404) {
            this.setState({
                message: "This change password request has expired.",
                popupOpen: true,
                isRedirectToLogin: true
            });
        }
        else {
            var error = await resp.text();

            if (error === "INVALID_CODE") {
                this.setState({
                    invalidCode: true,
                    busy: false
                })
            }
            else {
                this.setState({
                    message: "You have entered an invalid code too many times",
                    popupOpen: true,
                    isRedirectToLogin: true
                });

            }

        }
    }

    passwordEntered = async () => {
        var schema = new passwordValidator();

        schema
            .is().min(8)
            .is().max(50)
            .has().uppercase()
            .has().lowercase()
            .has().digits()
            .has().not().spaces();

        var error = schema.validate(this.state.password, {details:true});
        if( error.length > 0 ){
            this.setState({passwordError: error[0].message});
            return;
        }

        if( this.state.password !== this.state.confirmPassword ){
            this.setState({passwordError: "Passwords entered did not match"});
            return;
        }

        this.setState({
            busy: true,
            invalidCode: false
        });

        var resp = await fetchPost('/Employee/ChangePasswordFromOtp',
        {
            id:this.state.id,
            code:this.state.code,
            password:this.state.password
        });

        var message = "";

        if( resp.ok )
        {
            message = "Password has been updated successfully";
        }
        else
        {
            message = "Failed to change the password";
        }

        this.setState({
            message,
            popupOpen: true,
            isRedirectToLogin: true
        });


    }


    onCloseModal = () => {
        this.setState({ popupOpen: false })

        if (this.state.isRedirectToLogin)
            window.location = "/login";
    }

    onFormKeyDown = (e) => {
        if( e.keyCode == 13 && e.target != null && e.target.localName == 'input'){
            setTimeout( ()=>{
                switch( this.state.action){
                    case "email":
                        this.emailEntered();
                        break;
                    case "code":
                        this.codeEntered();
                        break;
                    case "password":
                        this.passwordEntered();
                        break;
                }

            },100);
        }
    }

    render = () => {
        return <>
            <div className={styles.main} onKeyDown={this.onFormKeyDown} >
                <h1>
                    Forgot Password
                </h1>

                {this.state.action === "email" &&
                    <div className={styles.one_line}>
                        <div>Email Address</div>
                        <Input type='text' value={this.state.email}     
                            onChange={this.handleChange.bind(this, "email")} autoFocus />
                        <Button  primary loading={this.state.busy} onClick={this.emailEntered.bind(this)} > Send </Button>
                    </div>
                }
                {this.state.action === "code" &&
                    <div >
                        <div className={styles.one_line}>
                            <div>One Time Code</div>
                            <Input type='text' value={this.state.code} autoFocus
                                onChange={this.handleChange.bind(this, "code")} />
                            <Button primary loading={this.state.busy} onClick={this.codeEntered} > Continue </Button>
                        </div>
                        {this.state.invalidCode &&
                            <div className={styles.error}>
                                You have entered an invalid code
                            </div>
                        }
                    </div>
                }
                {this.state.action === "password" &&
                    <div class={styles.password} >
                        <div class={styles.one_line}>
                            <div>New Password</div>
                            <Input type='password' value={this.state.password} fluid autoFocus
                                onChange={this.handleChange.bind(this, "password")} />
                        </div>
                        <div class={styles.one_line}>
                            <div>Reenter Password</div>
                            <Input type='password' value={this.state.confirmPassword} fluid
                                onChange={this.handleChange.bind(this, "confirmPassword")} />
                        </div>
                        <div className={styles.error}>{this.state.passwordError}</div>
                        <Button primary loading={this.state.busy} onClick={this.passwordEntered} >Update</Button>
                    </div>
                }
            </div>
            {this.state.popupOpen && <MessageBoxOkOnly
                title={"Forgot Password"}
                caption={this.state.message}
                onClick={this.onCloseModal.bind(this)}
            />}
        </>
    }

}

export default ForgotPassword;
import React, { Component } from 'react';
import 'semantic-ui-css/semantic.min.css';
import { Modal, Button, Checkbox, Input } from '../../node_modules/semantic-ui-react';
import Select from 'react-select';
import { ajaxPost } from '../ajax';
import { customStyles } from '../utils';

const moment = require('moment');

function employeeSearchlist(list) {
    var employees = [];
    if (list != null) {
        list.map(x => {
            return (
                employees.push({ label: x.LastName + ", " + x.FirstName + " " + x.MiddleName, value: x._id })
            )
        })
    }
    return employees;
}

function getFirstDayOfTheMonth(date) {
    var date = new Date(date);
    return new Date(date.getFullYear(), date.getMonth(), 1).toLocaleDateString();
}

function getLastDayOfTheMonth(date) {
    var date = new Date(date);
    return new Date(date.getFullYear(), date.getMonth() + 1, 0).toLocaleDateString();
}

function positionSearchlist(list) {
    var positions = [];
    if (list != null) {
        list.map(x => {
            return (
                positions.push({ label: x.Name, value: x._id })
            )
        })
    }
    return positions;
}

function groupMap(groupList, groupType, ids) {
    if (groupType == "CITY")
        ids = ids != null ? ids.map(x => x.CityId) : null;
    if (groupType == "DEPARTMENT")
        ids = ids != null ? ids.map(x => x.DepartmentID) : null;
    if (groupType == "DIVISION")
        ids = ids != null ? ids.map(x => x.DivisionID) : null;
    if (groupType == "POSITION")
        ids = ids != null ? ids.map(x => x.PositionID) : null;
    if (groupType == "COST_CENTER")
        ids = ids != null ? ids.map(x => x.CostCenterID) : null; // Cost Center
    if(groupType === "COMPANY")
        ids = ids != null ? ids.map(x => x.CompanyID) :null;
    var list = [];
    if (groupList != null) {
        if (groupType !== "SALARY_GRADE") {
            groupList.map(x => {
                return (
                    (x.Type === groupType) ?
                        ((ids != null ? ids.includes(x._id) : true) &&
                            list.push({ label: x.Name == "" ? "Name Not Set" : x.Name, value: x._id })) : [])
            })
        } else {
            groupList.map(x => {
                return (
                    (x.Type === groupType) ?
                        list.push({ label: x.Name + " (" + x.OtherDetails.Amount + ")", value: x._id }) : [])
            })
        }

    }
    return list;
}

function onlyUnique(value, index, self) {
    return self.indexOf(value) === index;
}

class EmployeeFilterModal extends Component {
    dt = moment().format('YYYY-MM-DD');

    constructor(props) {
        super(props);
        this.state = {
            employeelist: [],
            selectedEmployees: [],
            action: props.action == undefined ? "Generate" : props.action,
            hideFilter: props.hideFilter == undefined ? [] : props.hideFilter,
            showFilter: props.showFilter == undefined ? [] : props.showFilter,
            searcEmployeehQuery: '',

            groupList: [],

            companies:[],

            positionList: [],

            locationList: [],

            costcenterList: [], // Cost Center

            employeeIds: props.advFilter !== null ? props.advFilter.employeeIds != null ? props.advFilter.employeeIds : [] : [],

            CompanyIds: props.advFilter !== null ? props.advFilter.companyIds != null ? props.advFilter.companyIds : [] : [],
            searcCompanyQuery: '',

            DivisionIds: props.advFilter !== null ?props.advFilter.divisionIds != null ? props.advFilter.divisionIds : [] : [],
            searcDivisionQuery: '',

            DepartmentIds: props.advFilter !== null ? props.advFilter.departmentIds != null ? props.advFilter.departmentIds : [] : [],
            searcDepartmentQuery: '',

            PositionIds: props.advFilter !== null ? props.advFilter.positionIds != null ? props.advFilter.positionIds : [] : [],
            searchPositionQuery: '',

            LocationIds: props.advFilter !== null ? props.advFilter.locationIds != null ? props.advFilter.locationIds : [] : [],
            searcLocationsQuery: '',

            // Cost Center
            CostCenterIds: props.advFilter !== null ? props.advFilter.costcenterIds != null ? props.advFilter.costcenterIds : [] : [],
            searchCostCenterQuery: '',

            fromDate: moment(getFirstDayOfTheMonth(this.dt)).format('YYYY-MM-DD'),
            toDate: moment(getLastDayOfTheMonth(this.dt)).format('YYYY-MM-DD'),
            includeDeletedEmployee: props.advFilter != null ? props.advFilter.includeDeletedEmployee !== undefined ? props.advFilter.includeDeletedEmployee : false : false,
            inactiveOnly: props.advFilter != null ? props.advFilter.inactiveOnly !== undefined ? props.advFilter.inactiveOnly : false : false,
            onGenerateCallback: props.advFilter !== null ? props.onGenerateCallback : null,
            removeStatusFilter: props.removeStatusFilter == undefined? false: props.removeStatusFilter,
        }

        this.loadEmployees([], props.advFilter.employeeIds);
    }

    onGenerate = () => {
        var ids = this.state.selectedEmployees;
        // if(ids.length == 0)
        //     ids.push(empty_id);

        if ((this.state.CompanyIds === undefined || this.state.CompanyIds == null) && (this.state.DepartmentIds === undefined || this.state.DepartmentIds == null) && (this.state.LocationIds === undefined || this.state.LocationIds == null) 
            && (this.state.PositionIds === undefined || this.state.PositionIds == null) && (this.state.CostCenterIds === undefined || this.state.CostCenterIds == null) && (this.state.employeeIds === undefined || this.state.employeeIds == null))
            ids = [];
        // else
        //     ids = this.state.employeeIds != null && this.state.employeeIds !== "" ? this.state.employeeIds.map(x => x.value) : [];

        var retValueDictionary = {
            employeeIds: ids,
            companyIds: this.state.CompanyIds,
            divisionIds: this.state.DivisionIds,
            departmentIds: this.state.DepartmentIds,
            locationIds: this.state.LocationIds,
            positionIds: this.state.PositionIds,
            costcenterIds: this.state.CostCenterIds, // Cost Center
            includeDeletedEmployee: this.state.includeDeletedEmployee,
            inactiveOnly: this.state.inactiveOnly
        };

        this.props.callback(retValueDictionary, this.state.includeDeletedEmployee,this.state.inactiveOnly, this.state.onGenerateCallback, this.state.fromDate, this.state.toDate)
     }

    loadGroups = () => {
        this.setState({ isDepartmentLoading: true });
        ajaxPost({
            url: "api/Group/search",
            data: {
                "Search": "",
                "includeDeleted": this.state.includeDeletedEmployee,
                "startingIndex": 0,
                "itemCount": 1000
            },
            onSuccess: data => {
                this.setState({ groupList: data.content, isDepartmentLoading: false });
            },
            finally: () => { }
        })
    }
 
    onEmployeeChange = (selected) => {
        this.setState({selectedEmployees: selected})
        this.loadEmployees(selected.map(x => x.value));
    }

    loadEmployees = (excludedEmployeeIds, ids) => {
        this.setState({ isEmployeeLoading: true});
        ajaxPost({
            url: "api/Employee/searchFilteredEmployee",
            data: {
                "Search": "",
                "includeDeleted": this.state.includeDeletedEmployee,
                "startingIndex": 0,
                "itemCount": 10,
                "employeeIds": ids,
                "excludedEmployeeIds": excludedEmployeeIds,
                "CompanyIDs": this.state.CompanyIds,
                "DivisionIDs": this.state.DivisionIds,
                "DepartmentIDs": this.state.DepartmentIds,
                "PositionIDs": this.state.PositionIds,
                "CityIDs": this.state.LocationIds,
                "CostCenterIDs": this.state.CostCenterIds, // Cost Center
                "inactiveOnly": this.state.inactiveOnly,
            },
            onSuccess: data => {
                if(ids !== undefined && ids.length > 0) {
                    this.setState({
                        selectedEmployees: employeeSearchlist(data.content)
                    }, this.loadEmployees)
                }
                else {
                    this.setState({ employeelist: data.content, isEmployeeLoading: false });
                }
                
            },
            finally: () => { }
        })
    } 
    onSearch = (text, evt) => {
        if(evt.action === "input-blur"|| evt.action === "menu-close")
            return;

        clearTimeout(this.employeeSearchTimer);
        this.setState({ search: text });
        this.employeeSearchTimer = setTimeout(this.loadSearchEmployee, 400, text);
    }

    loadSearchEmployee = (str) => {
        var { selectedEmployees } = this.state;
        var ids = (selectedEmployees === "" || selectedEmployees === undefined || selectedEmployees.length == 0) ? [] : selectedEmployees.map(x => x.value);

        this.setState({ isEmployeeLoading: true });
        ajaxPost({
            url: "api/Employee/searchFilteredEmployee",
            data: {
                "Search": str,
                "includeDeleted": this.state.includeDeletedEmployee,
                "startingIndex": 0,
                "itemCount": 10,
                "excludedemployeeIds": ids,
                "CompanyIDs": this.state.CompanyIds,
                "DivisionIDs": this.state.DivisionIds,
                "DepartmentIDs": this.state.DepartmentIds,
                "PositionIDs": this.state.PositionIds,
                "CityIDs": this.state.LocationIds,
                "CostCenterIDs": this.state.CostCenterIds, // Cost Center
                "inactiveOnly": this.state.inactiveOnly,
            },
            onSuccess: data => {
                this.setState({ employeelist: data.content, isEmployeeLoading: false });
            },
            finally: () => { }
        })
    }

    componentWillMount() {
        this.loadGroups(); 
        this.loadEmployees();
    }

    handleCompanyChange = (value) => {
        var ids = [];
        if (value != null && !(value === "" || value.length === 0))
            ids = value.map(x => x.value);

        this.setState({ CompanyIds: ids, searcCompanyQuery: '', DepartmentIds: [], selectedEmployees: [] },() =>{ this.loadEmployees() } );
    }
    handleDivisionChange = (value) => {
        var ids = [];
        if (value != null && !(value === "" || value.length === 0))
            ids = value.map(x => x.value);

        this.setState({ DivisionIds: ids, searcDivisionQuery: '', selectedEmployees: [] },() =>{ this.loadEmployees() } ) ;
    }

    handleDepartmentChange = (value) => {
        var ids = [];
        if (value != null && !(value === "" || value.length === 0))
            ids = value.map(x => x.value);

        this.setState({ DepartmentIds: ids, searcDepartmentQuery: '', selectedEmployees: [] },() =>{ this.loadEmployees() } );
    }
    handleLocationChange = (value) => {
        var ids = [];
        if (value != null && !(value === "" || value.length === 0))
            ids = value.map(x => x.value);

        this.setState({ LocationIds: ids, searcLocationQuery: '', selectedEmployees: [] }, () => { this.loadEmployees() });
    }
    handlePositionChange = (value) => {
        var ids = [];
        if (value != null && !(value === "" || value.length === 0))
            ids = value.map(x => x.value);

        this.setState({ PositionIds: ids, searchPositionQuery: '', selectedEmployees: [] },() =>{ this.loadEmployees() } );
    }
    handleCostCenterChange = (value) => {
        var ids = [];
        if (value != null && !(value === "" || value.length === 0))
            ids = value.map(x => x.value);

        this.setState({ CostCenterIds: ids, searchCostCenterQuery: '', selectedEmployees: [] },() =>{ this.loadEmployees() } );
    }

    onChkCheckChange = (chk,e) => {
        if (chk == "STATUS")
            this.setState({ includeDeletedEmployee: !this.state.includeDeletedEmployee }, function () { this.loadEmployees() })
        if (chk == "INACTIVE_ONLY")
            this.setState({ inactiveOnly: !this.state.inactiveOnly }, function () { this.loadEmployees() })

    }

    canGenerate() {
        return (!this.state.isEmployeeLoading && !this.state.isDepartmentLoading && !this.state.isPositionLoading && !this.state.isLocationLoading && !this.state.isCostCenterLoading)
    }

    handleStartDate = (e) => {
        this.setState({ fromDate: e.target.value })
    }
    handleEndDate = (e) => {
        this.setState({ toDate: e.target.value })
    }

    render() {
        return (
            <Modal open={this.props.open} size="tiny">
                <Modal.Header>
                    <p>Employee Filter</p>
                </Modal.Header>
                <Modal.Content>
                    <div>
                        {!this.state.hideFilter.includes("COMPANY") &&
                            <div>
                                <label>Company</label>
                                <Select placeholder='Company'
                                    onChange={this.handleCompanyChange.bind(this)}
                                    options={groupMap(this.state.groupList, "COMPANY", null)}
                                    styles={customStyles}
                                    isMulti
                                    isLoading={this.state.isCompanyLoading}
                                    value={groupMap(this.state.groupList, "COMPANY", null).filter(x => this.state.CompanyIds.includes(x.value))} />
                            </div>}
                        <div>
                            <label>Division</label>
                            <Select placeholder='Division'
                                onChange={this.handleDivisionChange.bind(this)}
                                options={groupMap(this.state.groupList, "DIVISION", null)}
                                styles={customStyles}
                                isMulti
                                isLoading={this.state.isDivisionLoading}
                                value={groupMap(this.state.groupList, "DIVISION", null).filter(x => this.state.DivisionIds.includes(x.value))} />
                        </div>
                        <div>
                            <label>Department</label>
                            <Select placeholder='Departments'
                                onChange={this.handleDepartmentChange.bind(this)}
                                options={groupMap(this.state.groupList, "DEPARTMENT", null)}
                                styles={customStyles}
                                isMulti
                                isLoading={this.state.isDepartmentLoading}
                                value={groupMap(this.state.groupList, "DEPARTMENT", null).filter(x => this.state.DepartmentIds.includes(x.value))} />
                        </div>
                        <div>
                            <label>Position</label>
                            <Select placeholder='Positions'
                                onChange={this.handlePositionChange.bind(this)} 
                                options={groupMap(this.state.groupList, "POSITION",null)}
                                styles={customStyles}
                                isMulti
                                isLoading={this.state.isPositionLoading}
                                backspaceRemoves={true}
                                value={groupMap(this.state.groupList, "POSITION", null).filter(x => this.state.PositionIds.includes(x.value))} /> 
                        </div>
                        <div>
                            <label>Location</label>
                            <Select placeholder='Locations'
                                onChange={this.handleLocationChange.bind(this)}
                                options={groupMap(this.state.groupList, "CITY", null)}
                                styles={customStyles}
                                isMulti
                                isLoading={this.state.isLocationLoading}
                                backspaceRemoves={true}
                                value={groupMap(this.state.groupList, "CITY", null).filter(x => this.state.LocationIds.includes(x.value))} />
                        </div>
                        {!this.state.hideFilter.includes("COST_CENTER") &&
                        <div>
                            <label>Cost Center</label>
                            <Select placeholder='Cost Centers'
                                onChange={this.handleCostCenterChange.bind(this)}
                                options={groupMap(this.state.groupList, "COST_CENTER", null)}
                                styles={customStyles}
                                isMulti
                                isLoading={this.state.isCostCenterLoading}
                                backspaceRemoves={true}
                                value={groupMap(this.state.groupList, "COST_CENTER", null).filter(x => this.state.CostCenterIds.includes(x.value))} />
                        </div>
                        }
                        {!this.state.hideFilter.includes("EMPLOYEE") &&
                        <div>
                            <label>Employees</label>
                            <Select placeholder='Employees'
                                //onChange={this.handleSearchEmployeeChange.bind(this)}
                                options={employeeSearchlist(this.state.employeelist)}
                                onChange={this.onEmployeeChange.bind(this)}
                                onInputChange={this.onSearch}  
                                styles={customStyles}
                                isMulti
                                isLoading={this.state.isEmployeeLoading}
                                searchQuery={this.props.searchQuery}
                                value={this.state.selectedEmployees} />
                            <label style={{ fontSize: '13px', fontStyle: 'italic', color: '#2D2D2D' }}>**Note: Availability of employees in the selection may vary base on the selected values of every filter above.</label>
                        </div>}
                        {this.props.showDateRange === undefined ? false : this.props.showDateRange &&
                            <div class="ui grid">
                                <div class="left floated left aligned eight wide column">
                                    <Input type="date" label="From Date" size="mini" fluid value={this.state.fromDate} onChange={this.handleStartDate.bind(this)} />
                                </div>
                                <div class="right floated right aligned eight wide column">
                                    <Input type="date" label="To Date" size="mini" fluid value={this.state.toDate} onChange={this.handleEndDate.bind(this)} />
                                </div>
                            </div>
                        }
                        <br />
                        {!this.state.hideFilter.includes("STATUS") && !this.state.inactiveOnly && !this.state.removeStatusFilter &&
                            <div>
                                <Checkbox size='mini' label="Include Resigned" onChange={this.onChkCheckChange.bind(this, "STATUS")} checked={this.state.includeDeletedEmployee} />
                            </div>}
                        {this.state.showFilter.includes("INACTIVE_ONLY") && !this.state.removeStatusFilter &&
                            <div>
                                <Checkbox size='mini' label="Inactive Only" onChange={this.onChkCheckChange.bind(this, "INACTIVE_ONLY")} checked={this.state.inactiveOnly} />
                            </div>}
                    </div>
                </Modal.Content>
                <Modal.Actions>
                    <Button basic disabled={!this.canGenerate()} content={this.state.action} onClick={this.onGenerate.bind(this)}></Button>
                    <Button basic content="Cancel" onClick={this.props.onCancel}></Button>
                </Modal.Actions>
            </Modal>
        );
    }

}

export default EmployeeFilterModal;
import React, { Component } from 'react';
import 'semantic-ui-css/semantic.min.css';
import { Checkbox, Button, Table, Modal, Label, TextArea, Menu, Input, Image, Icon, Pagination, Radio, ButtonGroup } from 'semantic-ui-react';
import { ajaxPost } from '../../ajax';
import { isTaskAvailable, fnSearchEmployeeFilter, customStyles } from '../../utils';
import ErrorMesssage from '../../Commons/ErrorMessage';
import MesssageBoxOkOnly from '../../Commons/MessageBoxOkOnly';
import Select from 'react-select';

import modStyles from './ClockAssignmentsList.module.css'

function devList(devList) {
    var list = [];
    if (devList != null) {
        devList.map(x => {
            return (
                list.push({ label: x.DeviceName, value: x.SerialNumber }))
        })
    }
    return list;
}

function empList(empList) {
    var list = [];
    if (empList != null) {
        empList.map(x => {
            return (
                list.push({ label: x.LastName + ', ' + x.FirstName + ' ' + (x.MiddleName === "" ? "" : x.MiddleName[0]) + "(" + x.EmployeeNo + ")", value: x._id }))
        })
    }
    return list;
}

class ClockAssignmentList extends Component {

    state = {
        clockAssignList: [],
        isFirstLoad: true,
        clockAssignId: "",
        name: "",
        description: "",
        selectedClockAssign: [],
        isSaving: false,
        isAddDeviceOpen: false,
        isDeleteOpen: false,
        saveModalOpen: false,
        saveModalCaption: "",
        actionType: "",
        search: "",
        clockAssignCount: 0,
        includeDeleted: false,
        errorDetails: {
            Title: "",
            Caption: "",
            Open: false,
            Size: 'mini'
        },

        employees: [],
        selectedEmployee: "",
        isEmployeeLoading: false,

        deviceList: [],
        isDeviceLoading: false,
        selectedDevices: "",

        selectedDevice: "",
    }

    componentWillUnmount = () => {
        this.props.unmountCallback(this.state, "ClockAssignModule");
    }

    componentWillMount = () => {
        if (this.props.prevState != null)
            this.state = this.props.prevState;
        this.getInitialData();
    }

    getInitialData() {
        this.loadEmployee();
        this.loadDevice();
    }

    mapDeviceName(serial) {
        var dev = this.state.deviceList.filter(x => x.SerialNumber === serial)[0];
        if (dev === undefined)
            return "";
        return dev.DeviceName;
    }

    saveClick = () => {
        this.setState({ isLoading: true })
        const clockAssignCount = this.state.clockAssignList.length;
        ajaxPost({
            url: "api/ClockAssignment/save",
            data: {
                EmployeeID: this.state.selectedEmployee.value,
                Devices: this.state.clockAssignList,
            },
            onSuccess: data => {
                if (data === true) {
                    var caption = "Clock Assignment successfully saved."
                    this.setState({ saveModalOpen: true, saveModalCaption: caption, isLoading: false, clockAssignCount });
                }
            },
            finally: () => { }
        })
    }

    addDeviceClick = () => {
        if (this.state.selectedDevices == null || this.state.selectedDevices == "") {
            var err = {
                Title: "Required Field",
                Caption: "Name must not be empty.",
                Open: true,
                Size: "mini"
            }

            this.setState({ errorDetails: err })
            return;
        }

        var clockAssignments = this.state.clockAssignList;
        this.state.selectedDevices.map(x => {
            var devClass = {
                DeviceName: x.label,
                SerialNumber: x.value,
                AccessType: "NONE"
            }
            var found = clockAssignments.some(function (cAss) {
                return cAss.SerialNumber === devClass.SerialNumber;
            });
            if (!found & devClass.SerialNumber !== undefined)
                clockAssignments.push(devClass);
        });

        this.setState({ clockAssignList: clockAssignments, isAddDeviceOpen: false, clockAssignCount: this.state.clockAssignCount + 1 });

    }

    onModifyClockAssignClick = (cAss) => {
        this.setState({
            clockAssignId: cAss._id,
            name: cAss.Name,
            description: cAss.Description,
            isCreateNewOpen: true,
            actionType: "EDIT"
        })
    }

    handlePaginationChange = (e, { activePage }) => {
        if (activePage == 0)
            return;
        ajaxPost({
            url: "api/ClockAssignment/search",
            data: {
                "Search": "",
                "includeDeleted": false,
                "startingIndex": (activePage - 1) * 10,
                "itemCount": 10
            },
            onSuccess: data => {
                this.setState({ clockAssignList: data.content, isFirstLoad: false });
            },
            finally: () => { }
        })
    }

    deleteClick = (dev) => {
        var items = this.state.clockAssignList;
        var sDevices = this.state.selectedDevices;
        var index = items.indexOf(dev);
        if (index > -1) {
            items.splice(index, 1);
            sDevices = sDevices.filter(x => x != dev.SerialNumber);
        }

        this.setState({ isDeleteOpen: false, selectedDevices: sDevices });
    }

    loadEmployee = () => {
        fnSearchEmployeeFilter("", data => {
            this.setState({ employees: data, isLoading: false });
        }, 0, 20, false)
    }

    onEmpSearch = text => {
        clearTimeout(this.empSearchTimer);

        this.setState({ empSearch: text });
        this.empSearchTimer = setTimeout(this.doEmpSearch, 400, text);
    }

    doEmpSearch = (str) => {
        const { employees } = this.state;
        this.setState({ loading: true });

        var selected = this.state.selectedEmployee.value;
        var ids = (selected === "" || selected === undefined) ? null : selected.toString().split(',');

        var selectedEmpIds = [];
        if (ids != null && employees != undefined)
            selectedEmpIds = employees.filter(x => ids.includes(x._id));

        fnSearchEmployeeFilter(str, data => {
            selectedEmpIds.forEach(x => { if (!data.includes(x["_id"])) data.push(x) })
            this.setState({ employees: data, isLoading: false });
        }, 0, 20, false, ids)
    }

    onSearch = (event) => {
        var str = event.target.value;
        this.setState({ search: str })
        setTimeout(this.doSearch, 200, str, event.target);

    }

    onClickSearch = (event) => {
        if (this.state.selectedEmployee.value === "" || this.state.selectedEmployee === "") {
            return;
        }
        this.doSearch(this.state.search);
    }

    doSearch = (str, source) => {
        this.setState({ loading: true });
        ajaxPost({
            url: "api/ClockAssignment/search",
            data: {
                "search": str,
                "employeeId": this.state.selectedEmployee.value,
                "startingIndex": 0,
                "itemCount": 1000
            },
            onSuccess: data => {
                var devices = [];
                data.content.map(x => {
                    devices.push({ label: this.mapDeviceName(x.SerialNumber), text: x.SerialNumber });
                })

                this.setState({
                    clockAssignList: data.content,
                    clockAssignCount: data.content.length,
                    selectedDevices: data.content !== null ? devices : []
                });
            },
            finally: () => { }
        })

    }

    loadDevice = () => {
        this.setState({ isDeviceLoading: true })
        ajaxPost({
            url: "api/Device/search",
            data: {
                "Search": "",
                "IncludeDeleted": false,
                "StartIndex": 0,
                "MaxData": 1000
            },
            onSuccess: data => {
                this.setState({ deviceList: data, isDeviceLoading: false });
            },
            finally: () => { }
        })
    }

    showDelete = (dev) => () => this.setState({ selectedDevice: dev, isDeleteOpen: true })
    showRestore = (selectedClockAssign) => () => this.setState({ selectedClockAssign, isRestoreOpen: true })
    show = (size) => () => this.setState({
        size,
        isAddDeviceOpen: true,
        clockAssignId: "",
        name: "",
        description: "",
        actionType: "ADD"
    }, () => this.loadDevice())
    close = () => {
        this.state.errorDetails.Open = false;
        this.setState({ isAddDeviceOpen: false, isDeleteOpen: false, isRestoreOpen: false, errorDetails: this.state.errorDetails })
    }
    onChkCheckChange = (e) => {
        this.setState({ includeDeleted: !this.state.includeDeleted }, function () { this.doSearch(this.state.search) })
    }

    onSelectChange = (name, value) => {
        this.state.errorDetails.Open = false;
        var devs = value == null ? "" : value;
        if (name === "Employee") {
            this.setState({ ["selected" + name]: devs, errorDetails: this.state.errorDetails, clockAssignList: [] }, () => this.onClickSearch())
        } else {
            this.setState({ ["selected" + name]: devs, errorDetails: this.state.errorDetails })
        }
    }


    chkAccessTypeChange = (dev, value, e) => {
        var newData = this.state.clockAssignList;
        newData.find(x => x.SerialNumber === dev.SerialNumber).AccessType = value;
        this.setState({ clockAssignList: newData })
    }

    render() {
        const { activePage } = this.state
        return (
            <div className={modStyles.module}>
                <div style={{ color: "#606060", marginTop: '1%' }}>
                    <h2>Clock Assignments</h2>
                </div>
                {this.state.clockAssignCount > 0 &&
                    <div>
                        <Pagination
                            floated='right'
                            activePage={activePage}
                            defaultActivePage={1}
                            firstItem={{ content: <Icon name='angle double left' />, icon: true }}
                            lastItem={{ content: <Icon name='angle double right' />, icon: true }}
                            prevItem={{ content: <Icon name='angle left' />, icon: true }}
                            nextItem={{ content: <Icon name='angle right' />, icon: true }}
                            pointing
                            secondary
                            size='mini'
                            siblingRange={2}
                            boundaryRange={0}
                            totalPages={Math.ceil(this.state.clockAssignCount / 10)}
                            onPageChange={this.handlePaginationChange}
                        />
                    </div>
                }
                <br />
                <br />
                <div>
                    <Menu secondary size='mini'>
                        <Button floated='left' size='mini' style={{ 'max-height': '31px' }} onClick={this.show('mini')} disabled={(this.state.selectedEmployee === "" || this.state.selectedEmployee === undefined)}> <Icon name='plus' />Add Device</Button>
                        &nbsp;
                        <Button
                            disabled={
                                this.state.selectedDevices === "" ||
                                this.state.selectedEmployee === undefined ||
                                this.state.selectedEmployee === "" ||
                                this.state.clockAssignCount === 0
                            }
                            icon='save'
                            size='mini'
                            style={{ 'max-height': '31px' }}
                            content='Save'
                            onClick={this.saveClick.bind(this)}
                        />
                        {this.state.isLoading && <Button floated='left' style={{ border: 'none', 'background-color': 'transparent', cursor: 'arrow', 'max-height': '31px' }}
                            size='tiny' loading></Button>}
                        <Menu.Menu position='right'>
                            <div style={{ width: 300 }}>
                                <Select
                                    options={empList(this.state.employees)}
                                    value={this.state.selectedEmployee}
                                    onChange={this.onSelectChange.bind(this, "Employee")}
                                    onInputChange={this.onEmpSearch}
                                    loading={this.state.isLoading} />
                            </div>
                            &nbsp;
                            &nbsp;
                            <Button size='mini' type='submit' style={{ 'max-height': '31px' }} onClick={this.onClickSearch.bind(this)}>Search</Button>
                        </Menu.Menu>
                    </Menu>
                </div>
                &nbsp;
                <div className={modStyles.content}>
                    <Table selectable striped size='small'>
                        <Table.Header>
                            <Table.Row style={{ 'backgroundColor': 'gainsboro', 'fontSize': '11px', padding: '0px' }}>
                                <Table.HeaderCell>Device</Table.HeaderCell>
                                <Table.HeaderCell>Access Types</Table.HeaderCell>
                                <Table.HeaderCell>Action</Table.HeaderCell>
                            </Table.Row>
                        </Table.Header>
                        <Table.Body>
                            {this.state.clockAssignList !== undefined && this.state.clockAssignList.map(list => {
                                return (
                                    <Table.Row selected>
                                        <Table.Cell>{list === undefined ? "" : list.DeviceName === undefined ? this.mapDeviceName(list.SerialNumber) : list.DeviceName}</Table.Cell>
                                        <Table.Cell>
                                            <Radio
                                                label='User'
                                                name={list.SerialNumber}
                                                value="USER"
                                                checked={list.AccessType === "USER"}
                                                onChange={this.chkAccessTypeChange.bind(this, list, "USER")}
                                            /> &nbsp;&nbsp;&nbsp;
                                            <Radio
                                                label='Admin'
                                                name={list.SerialNumber}
                                                checked={list.AccessType === "ADMIN"}
                                                onChange={this.chkAccessTypeChange.bind(this, list, "ADMIN")}
                                            />&nbsp;&nbsp;&nbsp;
                                            <Radio
                                                label='Enroller'
                                                name={list.SerialNumber}
                                                value="ENROLLER"
                                                checked={list.AccessType === "ENROLLER"}
                                                onChange={this.chkAccessTypeChange.bind(this, list, "ENROLLER")}
                                            />&nbsp;&nbsp;&nbsp;
                                            <Radio
                                                label='None'
                                                name={list.SerialNumber}
                                                value="NONE"
                                                checked={list.AccessType === "NONE"}
                                                onChange={this.chkAccessTypeChange.bind(this, list, "NONE")}
                                            />&nbsp;&nbsp;&nbsp;
                                        </Table.Cell>
                                        <Table.Cell>
                                            <ButtonGroup>
                                                <Button size='mini' style={{ margin: '2px', 'font-size': '10px', height: '25px' }} onClick={this.showDelete(list)} content='Delete' />
                                            </ButtonGroup>
                                        </Table.Cell>
                                    </Table.Row>
                                )
                            })}
                        </Table.Body>

                        {
                            this.state.isAddDeviceOpen &&
                            <Modal fluid size='small' open={true} onClose={this.close.bind(this)} closeOnEscape={true} closeOnDimmerClick={false}>
                                <Modal.Header>Select Device</Modal.Header>
                                <Modal.Content>
                                    <ErrorMesssage
                                        title={this.state.errorDetails.Title}
                                        caption={this.state.errorDetails.Caption}
                                        open={this.state.errorDetails.Open}
                                        size={this.state.errorDetails.Size}
                                    />
                                    {this.state.errorDetails.Open && <br />}
                                    <Select styles={customStyles}
                                        options={devList(this.state.deviceList)}
                                        value={this.state.selectedDevices}
                                        onChange={this.onSelectChange.bind(this, "Devices")}
                                        isloading={this.state.isDeviceLoadingLoading}
                                        isMulti />
                                </Modal.Content>
                                <Modal.Actions>
                                    <Button basic icon='add' content='Add' onClick={this.addDeviceClick.bind(this)} loading={this.state.isSaving} />
                                    <Button basic icon='cancel' content='Cancel' onClick={this.close.bind(this)} />
                                </Modal.Actions>
                            </Modal>
                        }
                        {
                            this.state.isDeleteOpen &&
                            <Modal size='mini' open={true} onClose={this.close.bind(this)} closeOnDimmerClick={false}>
                                <Modal.Header>Remove Device</Modal.Header>
                                <Modal.Content>
                                    <p>Are you sure you want to delete '{this.state.selectedDevice.DeviceName}'? </p>
                                </Modal.Content>
                                <Modal.Actions>
                                    <Button basic icon='check' onClick={this.deleteClick.bind(this, this.state.selectedDevice)} content='Yes' />
                                    <Button basic icon='cancel' onClick={this.close.bind(this)} content='No' />
                                </Modal.Actions>
                            </Modal>
                        }

                        {this.state.saveModalOpen &&
                            <MesssageBoxOkOnly
                                title='Clock Assignments'
                                caption={this.state.saveModalCaption}
                                onClick={() => this.setState({ saveModalOpen: false })}
                            />
                        }


                        {/* <Modal size='mini' open={this.state.isRestoreOpen} onClose={this.close}>
                            <Modal.Header>Restore Clock Assignment</Modal.Header>
                            <Modal.Content>
                                <p>Are you sure you want to restore '{this.state.selectedClockAssign.Name}'? </p>
                            </Modal.Content>
                            <Modal.Actions>
                                <Button basic icon='check' onClick={this.restoreClick.bind(this, this.state.selectedClockAssign)} content='Yes' />
                                <Button basic icon='cancel' onClick={this.close} content='No' />
                            </Modal.Actions>
                        </Modal> */}
                    </Table>
                </div>
            </div>
        )
    }
}

ClockAssignmentList.getViewID = () => { return "ClockAssignModule" };
ClockAssignmentList.getCaption = () => { return "Clock Assignment" }
ClockAssignmentList.getIcon = () => { return (<Image style={{ "height": "24px", "width": "24px" }} src='/Icons/Clock_Assignment.png' avatar />); }
ClockAssignmentList.getIconSrc = (size) => { return '/Icons/Clock_Assignment.png' }
ClockAssignmentList.getViewAccessKey = () => {
    return isTaskAvailable("CLOCKASSIGNMENTS_MANAGEMENT");
}

export default ClockAssignmentList;
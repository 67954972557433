import React from "react";
import Select from "react-select";
import styled from 'styled-components';

const CustomSelect = styled(Select)`
.Select-control{
    background-color: #fff;
    border-radius: 3px;
    border: 1px solid #c9cacc;
    color: #333;
    cursor: default;
    display: table;
    border-spacing: 0;
    border-collapse: separate;
    height: 32px;
    outline: none;
    overflow: hidden;
    position: relative;
    width: 100%;
    table-layout: fixed;
    }
    .Select-input > input {
    width: 100%;
    background: none transparent;
    border: 0 none;
    box-shadow: none;
    cursor: default;
    display: inline-block;
    font-family: inherit;
    font-size: inherit;
    margin: 0;
    outline: none;
    line-height: 17px;
    /* For IE 8 compatibility /
    padding: 7px 0;
    / For IE 8 compatibility */
    -webkit-appearance: none;
    max-width:100%;
    }
    .Select-input {
    height: 30px;
    padding-left: 10px;
    padding-right: 10px;
    vertical-align: middle;
    max-width:100%;
    }
    .Select-multi-value-wrapper {
    max-width: 100%;
    display: block;
    }
`
export default (props) => <CustomSelect {...props} />
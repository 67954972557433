import React from 'react';
import 'semantic-ui-css/semantic.min.css';
import ReportModelBase from '../ReportModelBase';
import { Table } from 'semantic-ui-react';
const moment = require('moment');

class EmployeeReportModelGroupByLocation extends ReportModelBase {

    constructor(props){
        super(props);
        this.searchUrl = "api/Reports/employeeReportGroupByLocation";
        this.title = "Employee Report Group by Location";
        this.exportToExcelUrl = "api/Reports/ExportEmployeeReportGroupByLocationToExcel";
        this.exportToPdfUrl = "api/Reports/ExportEmployeeReportGroupByLocationToPDF";
    }

    toTableRow = (rowData) => {
        return (
            <Table.Row>
                <Table.Cell>{rowData["Employee No"]}</Table.Cell>
                <Table.Cell>{rowData["Employee"]}</Table.Cell>
                <Table.Cell>{rowData["Location"]}</Table.Cell>
            </Table.Row>
        );
    }

    createColumnHeaders = () => {
        return (
            <Table.Row>
                <Table.HeaderCell>Employee No</Table.HeaderCell>
                <Table.HeaderCell>Employee</Table.HeaderCell>
                <Table.HeaderCell>Location</Table.HeaderCell>
            </Table.Row>
        );
    }

}
export default EmployeeReportModelGroupByLocation;
import React, { Component } from 'react';
import { Modal, Button, Table,Input, Form,TextArea,Message } from 'semantic-ui-react';
import 'semantic-ui-css/semantic.min.css';
import {ajaxPost} from '../../ajax';
import {checkInt32, } from '../../utils';
import moment from 'moment';

class ApprovalForm extends Component {
    state = {
        transaction:null,
        ApprovedHours: 0,
        Remarks: "",
        modalOpen:true,
        errorApproved:false,
        errorRemark:false,
        showConflict:false,
        conflict:[]
    }
    constructor(props){
        super(props);
        this.state.transaction = this.props.transaction;
        this.state.modalOpen = this.props.modalOpen;
        this.state.ApprovedHours =this.props.transaction == undefined ? 0 : (this.props.transaction.ApprovedMinutes /60).toFixed(2);
    }

    handleClose = () => {
        this.props.onClose();
    }
    handleApproved = () =>{
        this.setState({conflict:[],showConflict:false});
        if(this.state.ApprovedHours == ""){
            var conflict = this.state.conflict;
            conflict.push("Approved Hours is required");
            this.setState({errorApproved:true,showConflict:true,conflict:conflict});
            return;
        }
        else if(!checkInt32(this.state.ApprovedHours * 60).Result)
        {
            var conflict = this.state.conflict;
            conflict.push("invalid Approved Hours");
            this.setState({errorApproved:true,showConflict:true,conflict:conflict});
            return;
        }
        else if(this.state.ApprovedHours == 0){
            var conflict = this.state.conflict;
            conflict.push("Approved Hours cannot be '0'");
            this.setState({errorApproved:true,showConflict:true,conflict:conflict});
            return;
        }
        if(this.state.Remarks ==""){
            var conflict = this.state.conflict;
            conflict.push("Remarks is required");
            this.setState({errorRemark:true,showConflict:true,conflict:conflict});
            
            return;
        }      

        //set data
        var transaction = this.state.transaction;
        transaction.ApprovedMinutes = Math.round(this.state.ApprovedHours * 60);
        transaction.ApproverRemarks = this.state.Remarks;
        transaction.ApproverId = global.userId;
            // due to checking of duplicate assume that the data is utc
        transaction.Date = moment(transaction.Date).utc();
        transaction.StartTime = moment(transaction.StartTime).utc();
        transaction.ChangeStatusDate = moment(transaction.ChangeStatusDate).utc();
        transaction.RequestDate = moment(transaction.RequestDate).utc();

        
        //saving

        this.save(data =>{
            if (data[data.length - 1].hasOwnProperty("errMessage")) {
                let lastIndex = data.length - 1;
                var conflict = this.state.conflict;
                conflict.push(data[lastIndex]["errMessage"]);
                this.setState({showConflict:true,conflict:conflict });
                return;
            }
            alert("Successfully Submitted");
             //closed
            this.handleClose();
            this.props.onCallback();
        },transaction);
       
    }
    save =(callback,trans) =>{    
        let items = [];
        items.push(trans);
        ajaxPost({
            url: 'api/Overtime/saveMultiple',
            data: {
            "items": items,
        },
        onSuccess: data => {
            callback(data);
        },
        finally: () => { }
        })
    }

    render() {
        return (
            <Modal open={this.props.modalOpen} size='tiny' onClose={this.handleClose}>
                { this.props.title && <Modal.Header>{this.props.title}</Modal.Header> }
                <Modal.Content>
                    <Modal.Description>
                        <div>
                        {this.state.showConflict &&
                            <Message negative>
                                <Message.Header>Conflict !</Message.Header>
                                <Message.Content>{this.state.conflict.map(n => {
                                    return (<p>{n}</p>)
                                })}</Message.Content>
                            </Message>}
                        </div>
                        <Table>
                            <Table.Row>
                                <Table.Cell>Approve Hours </Table.Cell>
                                <Table.Cell>
                                    <Input value={this.state.ApprovedHours}
                                    onChange={(event,data) => {this.setState({ApprovedHours: data.value})}}
                                    error={!checkInt32(this.state.ApprovedHours * 60).Result && this.state.errorApproved} 
                                     ></Input>
                                </Table.Cell>
                            </Table.Row>
                            <Table.Row>
                            <Table.Cell>Remarks</Table.Cell>
                                <Table.Cell>
                                    <Form>                                       
                                        <TextArea 
                                            fluid 
                                            value={this.state.Remarks}
                                            onChange={(event, data) => {this.setState({ Remarks: data.value });}}
                                            error={this.state.errorRemark}
                                            >
                                        </TextArea>
                                    </Form>
                                </Table.Cell>

                            </Table.Row>
                        </Table>
                        
                        
                    </Modal.Description>
                </Modal.Content>
                <Modal.Actions>
                    <Button basic icon='check' content='APPROVED' onClick={this.handleApproved.bind(this)}></Button>
                    <Button basic icon='cancel' content='Cancel' onClick={this.handleClose.bind(this)}></Button>
                </Modal.Actions>
            </Modal>
        )
    }
}

export default ApprovalForm
import React, { Component, lazy } from 'react';
import { Modal, Button, Input, Dropdown, TextArea, Checkbox, Form, Header, Label } from 'semantic-ui-react';
import moment from 'moment';
import { ajaxPost } from '../../../ajax';
//import Select from 'react-select';
import SelectEmployees from '../../../Commons/SelectEmployees';
import ErrorMessage from '../../../Commons/ErrorMessage';
import MessageBoxYesNo from '../../../Commons/MessageBoxYesNo';
import LeaveTypeForm from '../../Leaves/LeaveTypeForm';
import { isTaskAvailable, checkDateRangeNoRestore, minDate, maxDate, checkYear, handleNumeric, handleNumericOnKeyDown, handleNumericOnKeyPress } from '../../../utils';

var delayTimer = null;
const someMarginNoPadding = { "margin": "0px 0px 10px 0px", "padding": "0px" }
const noMarginNoPadding = { "margin": "0px", "padding": "0px" }
const semanticErrorStyle = { 'background-color': '#FFF6F6', 'border-color': '#E0B4B4', 'color': '#9F3A38', '-webkit-box-shadow': 'none', 'box-shadow': 'none' }
const attachLabel = { 'width': '100%', 'display': 'flex', 'justifyContent': 'center', 'alignItems': 'center', 'border': 'solid 1px #e8e8e8', 'borderRadius': '0 4px 4px 0px',
    'wordWrap': 'break-word', 'wordBreak': 'break-all', 'overflow': 'hidden'}
    
class LeaveForm extends Component {

    constructor(props) {
        super(props);
        this.state = {
            _id: null,
            LeaveTypeId: "",
            StartDate: moment().format("YYYY-MM-DD"),
            EndDate: moment().format("YYYY-MM-DD"),
            LeaveTypeCode: "",
            ApplicationType: 1,
            Count: "Whole Day",
            Hours: 0,
            WithPay: true,
            YearDeducted: Number(moment().format("YYYY")),
            Remarks: "",
            RequesterId: "",
            RequestDate: moment().format("YYYY-MM-DD"),

            employees: [],
            selectedEmployees: [],
            employeeSelectionEnabled: true,
            displayedName: "",
            leaveTypeData: [],
            details: "",
            AttachmentDetail: {
                Source: "",
                File: ""
            },
            isLoading: false,
            showHourField: false,
            showLeaveTypeForm: false
        }
        this.modalRef = React.createRef();
    }

    isNullOrEmpty(val) {
        if (val === null || val === undefined || val === "" || val.length === 0) return true;
        else return false;
    }

    validate = (data) => {
        var errTitle = "", errCaption = "";
        if (this.isNullOrEmpty(data.Code)
            || this.isNullOrEmpty(data.Name)
            // || this.isNullOrEmpty(data.Description)  // requested to remove description as required, Bug 8935: Leave Type Required Fields
        ) {
            errTitle = "Invalid"
            errCaption = "Fill up all required fields."
        }
        else {
            this.setState({ showRequiredField: false });
            return true;
        }
        this.setState({ showRequiredField: true, errTitle, errCaption });
        return false;
    };

    saveFromModal = (model) => {
        let self = this;
        let isValid = false;
        isValid = this.validate(model);
        if (!isValid) {
            return;
        }

        var parameter = {
            data: { model: JSON.stringify(model) },
            url: "api/LeaveType/save",
            onSuccess: (data, sender) => {
                if (data.hasOwnProperty("errors") && data.errors.length > 0) {
                    isValid = false;
                    this.setState({
                        showRequiredField: true,
                        errTitle: "Invalid",
                        errCaption: data.errors.map(err => { return (<p>{err}</p>) })
                    });
                    return;
                }
                if (isValid) {
                    this.setState({ showLeaveTypeForm: false });
                    this.setState({ LeaveTypeId: data.content["_id"] });
                    this.onLeaveTypeKeyPress(null);
                }

                // isValid = true;
                // let rows = self.state.rows.slice();
                // rows.unshift(self.toRow(data.content));
                // self.setState({
                //     rows: rows,
                //     selectedRowIds: [],
                //     selectedIndices: [],
                // },
                //     () => { self.messageBoxOkOnlyOpen(true, "Success", "New leave type added") }
                // );
            },
            onError: (error) => {
                isValid = false;
                var errMessage = "An error has occured";
                if (error) {
                    errMessage += "\r\n" + JSON.stringify(error);
                }
                this.messageBoxOkOnlyOpen(true, "Error", errMessage);
            },
            finally: () => {
                // close modal 
            }
        }
        ajaxPost(parameter);
    }

    setContent = (data) => {
        if (data.LeaveTypeId) {
            this.onLeaveTypeKeyPress(this, { searchQuery: data.LeaveTypeCode });
        }

        let selectedEmployees = [];
        if (data.EmployeeId) {
            selectedEmployees.push(data.EmployeeId);
        }
        else if (data.userId && !isTaskAvailable("VIEW_ALL_EMPLOYEES")) {
            selectedEmployees.push(data.userId);
        }
        // if(data.userId && !data.hasSubordinates) {
        //     selectedEmployees.push(data.userId);
        let count = data.Count === 0.25 ? "Quarter" : data.Count === 0.5 ? "Half Day" : data.Count === 1 ? "Whole Day" : "Hourly";
        this.setState({
            _id: data._id,
            LeaveTypeId: data.LeaveTypeId,
            StartDate: moment(data.StartDate).format("YYYY-MM-DD"),
            EndDate: moment(data.EndDate).format("YYYY-MM-DD"),
            LeaveTypeCode: data.LeaveTypeCode,
            ApplicationType: data.ApplicationType,
            Count: count,
            Hours: count === "Hourly" ? data.Count * 8 : 0,
            WithPay: data.WithPay,
            YearDeducted: data.YearDeducted,
            Remarks: data.Remarks,
            RequesterId: data.userId,
            details: !data._id ? "" : data.AttachmentDetail.File,
            AttachmentDetail: !data._id ? {
                Source: "",
                File: ""
            } : data.AttachmentDetail,
            employees: [],
            selectedEmployees: selectedEmployees,
            employeeSelectionEnabled: !data._id && (isTaskAvailable("VIEW_ALL_EMPLOYEES") || data.hasSubordinates),
            // displayedName: !data._id && !data.hasSubordinates ? data.name : data.Employee,
            displayedName: !data._id ? data.name : data.Employee,
            showHourField: count === "Hourly"
        });
    }

    getContent = () => {
        return {
            _id: this.state._id,
            LeaveTypeId: this.state.LeaveTypeId,
            StartDate: moment(this.state.StartDate).toJSON(),
            EndDate: moment(this.state.EndDate).toJSON(),
            LeaveTypeCode: this.state.LeaveTypeCode,
            ApplicationType: this.state.ApplicationType,
            Count: this.state.Count === "Quarter" ? 0.25 : this.state.Count === "Half Day" ? 0.5 : this.state.Count === "Hourly" ? this.state.Hours / 8 : 1,
            WithPay: this.state.WithPay,
            YearDeducted: this.state.YearDeducted,
            RequesterId: this.state.RequesterId,
            RequestDate: moment().toJSON(),
            Remarks: this.state.Remarks,
            AttachmentDetail: this.state.AttachmentDetail,
            //employeeIds: this.state.selectedEmployees.map(employee => { return employee.key }),
            employeeIds: this.state.selectedEmployees,
        }
    }

    // ATTACHMENT
    onDocChangedHandler = (event) => {
        var img = event.target.files[0];
        if (img === undefined) return;
        if (img.size > 3145728) {
            alert("File too large");
            return;
        } else if (img.size === 0) {
            alert("Image too small. File must not be 0MB");
            return;
        } else {
            this.docReadDataUrl(img, this.docOnload.bind(this));
        }
    };

    docReadDataUrl(img, callback) {
        var reader = new FileReader();
        reader.onload = function () {
            callback(reader.result, img.name);
        };
        reader.onerror = function (error) {
            console.log("Error: ", error);
        };
        reader.readAsDataURL(img);
    }

    docOnload(dataURL, name) {
        var current = { ...this.state.AttachmentDetail };
        current["Source"] = dataURL;
        current["File"] = name;

        this.setState({ AttachmentDetail: current, details: name });
    }

    onEmployeeSearchChange = (event, data) => {
        var self = this;
        clearTimeout(delayTimer);
        delayTimer = setTimeout(() => {
            var employeeSearchParameter = {
                data: { needle: data.searchQuery === undefined ? "" : data.searchQuery, itemCount: 20 },
                url: "api/Overtime/searchEmployees",
                onSuccess: (data, sender) => {
                    if (data.content === null || data.content === undefined) return;
                    var newOptions = data.map((model, index, array) => { return { key: model._id, text: model.LastName + ", " + model.FirstName + " " + model.MiddleName, value: model._id } });
                    self.setState({ employees: newOptions });
                },
                onError: (error) => {

                },
                finally: () => { }
            };
            ajaxPost(employeeSearchParameter);
        }, 200);
    };

    onLeaveTypeChange = (event, data) => {
        this.setState({
            LeaveTypeId: data.value,
            LeaveTypeCode: this.state.leaveTypeData.find(item => item.value === data.value).code
        });
    }

    onLeaveTypeKeyPress = (event, data) => {
        this.setState({ isLoading: true });
        var self = this;
        clearTimeout(delayTimer);
        delayTimer = setTimeout(() => {
            self.loadLeaveTypes(data);
        }, 200);
    };

    loadLeaveTypes = (data) => {
        var self = this;

        var parameter = {
            search: data !== undefined && data !== null ? data.searchQuery : "",
            includeDeleted: false,
            startIndex: 0,
            itemCount: 15
        };
        var searchParameter = {
            data: parameter,
            url: "api/LeaveType/search",
            onError: (error) => {
                //self.setState({ leaveTypeData: [] });
            },
            onSuccess: (data, sender) => {
                if (data.content.length === 0) {
                    self.setState({ LeaveTypeId: "" });
                    return;
                }
                var newOptions = data.content.map((item) => {
                    return {
                        key: item._id,
                        text: item.Name + " (" + item.Code + ")",
                        value: item._id,
                        code: item.Code,
                    }
                });
                self.setState({ leaveTypeData: newOptions });
            },
            finally: () => {
                self.setState({ isLoading: false });
            }

        }
        ajaxPost(searchParameter);
    }

    onCreateNewItemClick = () => {
        this.setState({ showAskCloseDialog: true });
    }

    onCreateTypeClick = () => {
        this.setState({ showLeaveTypeForm: !this.state.showLeaveTypeForm, errCaption: "", errTitle: "", showRequiredField: false })
    }

    render() {
        const self = this;
        return (
            <Modal size="tiny" open={this.props.open}>
                <Modal.Header>
                    Leave Application
                </Modal.Header>
                <Modal.Content>
                    {
                        this.props.showRequiredField &&
                        <div className="ui one column grid" style={noMarginNoPadding}>
                            <div className="column" style={someMarginNoPadding}>
                                <ErrorMessage
                                    title={this.props.errTitle}
                                    caption={this.props.errCaption}
                                    open={this.props.showRequiredField}
                                />
                            </div>
                        </div>
                    }
                    {
                        !this.state.employeeSelectionEnabled &&
                        <div className="ui one column grid" style={noMarginNoPadding}>
                            <div className="column" style={someMarginNoPadding}>
                                <div>Employee</div>
                                <Header size='small' style={{ "margin": "0" }}>{this.state.displayedName}</Header>
                                {/* <Input value={this.state.displayedName} disabled={true} /> */}
                            </div>
                        </div>
                    }
                    {
                        this.state.employeeSelectionEnabled &&
                        <div className="ui one column grid" style={noMarginNoPadding}>
                            <div className="column" style={someMarginNoPadding}>
                                <div>Employee</div>
                                <SelectEmployees
                                    Ids={this.state.selectedEmployees}
                                    update={(Ids) => this.setState({ selectedEmployees: Ids })}
                                    width="100%"
                                    disabled={this.props.isBusy}
                                />
                                {
                                    isTaskAvailable("EMPLOYEE_ADD") &&
                                    <a as='a' href='#' onClick={this.onCreateNewItemClick.bind(this)}>Create New</a>
                                }
                            </div>
                        </div>
                    }
                    <div className="ui two column grid" style={noMarginNoPadding}>
                        <div className="column" style={{ "margin": "0px 0px 10px 0px", "padding": "0px 5px 0px 0px" }}>
                            <div>Start Date</div>
                            <Input
                                fluid
                                type="date"
                                min={minDate}
                                max={maxDate}
                                disabled={this.props.isBusy}
                                required={true}
                                error={!checkDateRangeNoRestore(this.state.StartDate, this.state.EndDate).Result}
                                value={this.state.StartDate} onChange={(event, data) => { this.setState({ StartDate: data.value }); }} />
                        </div>
                        <div className="column" style={{ "margin": "0px 0px 10px 0px", "padding": "0px 0px 0px 5px" }}>
                            <div>End Date</div>
                            <Input
                                fluid
                                type="date"
                                min={minDate}
                                max={maxDate}
                                disabled={this.props.isBusy}
                                required={true}
                                error={!checkDateRangeNoRestore(this.state.StartDate, this.state.EndDate).Result}
                                value={this.state.EndDate} onChange={(event, data) => { this.setState({ EndDate: data.value }); }} />
                        </div>
                    </div>
                    <div className="ui two column grid" style={noMarginNoPadding}>
                        <div className="column" style={someMarginNoPadding}>
                            <div>Leave Type</div>
                            <Dropdown
                                fluid
                                search
                                selection
                                disabled={this.props.isBusy || this.state.showLeaveTypeForm}
                                options={this.state.leaveTypeData}
                                value={this.state.LeaveTypeId}
                                error={!this.state.LeaveTypeId && this.props.showRequiredField}
                                loading={this.state.isLoading}
                                onClick={this.onLeaveTypeKeyPress.bind()}
                                onSearchChange={this.onLeaveTypeKeyPress.bind()}
                                onChange={this.onLeaveTypeChange.bind()}>
                            </Dropdown>
                            {
                                !this.state.showLeaveTypeForm && isTaskAvailable("LEAVE_TYPE_CREATE") &&
                                <a as='a' href='#' onClick={this.onCreateTypeClick.bind(this)}>Create New</a>
                            }
                            {
                                <LeaveTypeForm
                                    ref={this.modalRef}
                                    open={this.state.showLeaveTypeForm}
                                    errTitle={"Error"}
                                    errCaption={this.state.errCaption}
                                    showRequiredField={this.state.showRequiredField}
                                    onCancel={() => { this.setState({ showLeaveTypeForm: false }) }}
                                    onSave={() => {
                                        self.saveFromModal(
                                            self.modalRef.current.getContent()
                                        );
                                    }}
                                >
                                </LeaveTypeForm>
                            }
                        </div>
                        {/* ATTACHMENT OFFICIAL BUSINESS*/}
                        <div className="column" style={{ "margin": "15px 0px 10px 0px", "padding": "0px 5px 0px 0px" }}>
                            <Input
                                style={{ marginTop: "5px", height: "38px", width: "100%" }}
                                labelPosition="left"
                                type="text"
                                placeholder="File"
                                error={this.state.isError}
                            >
                                <Label style={{ width: "45px", height: "38px" }}>File</Label>
                                <label style={attachLabel} htmlFor="files_$">
                                    <a style={{ fontSize: '12px', padding: '2px'}}>
                                        {this.state.details === "" ? "Choose file" : this.state.details}
                                    </a>
                                </label>
                                <input
                                    id="files_$"
                                    style={{ width: "150px", display: "none" }}
                                    size="small"
                                    name=" "
                                    type="file"
                                    onChange={this.onDocChangedHandler}
                                />
                            </Input>
                            <div style={{fontSize: "11px", textAlign: 'right', margin: "2px" }}>*Max File Size: 3MB</div>
                        </div>
                    </div>
                    {/* <div className="ui one column grid" style={noMarginNoPadding}>
                        <div className="column" style={someMarginNoPadding}>
                            <div>Application</div>
                            <Dropdown 
                                fluid 
                                selection 
                                disabled={this.props.isBusy} 
                                options={this.props.applicationTypeOptions} 
                                value={this.state.ApplicationType} 
                                error={!this.state.ApplicationType && this.props.showRequiredField}                                
                                onChange={ (event, data) => { this.setState({ ApplicationType: data.value }); }}>
                            </Dropdown>
                        </div>
                    </div> */}
                    <div className="ui three column grid" style={noMarginNoPadding}>
                        <div className="column" style={{ "margin": "0px 0px 10px 0px", "padding": "0px 5px 0px 0px" }}>
                            {/* <div>With Pay</div> */}
                            <div>&nbsp;</div>
                            <Checkbox
                                fluid
                                disabled={this.props.isBusy}
                                style={{ marginTop: 10 }}
                                label="With Pay"
                                checked={this.state.WithPay}
                                onChange={(event, data) => { this.setState({ WithPay: data.checked }); }}
                            />
                        </div>
                        <div className="column" style={{ "margin": "0px 0px 10px 0px", "padding": "0px 5px 0px 5px" }}>
                            <div>Count</div>
                            <Dropdown
                                fluid
                                selection
                                disabled={this.props.isBusy}
                                options={[{ key: "Whole Day", value: "Whole Day", text: "Whole Day" }, { key: "Half Day", value: "Half Day", text: "Half Day" }, { key: "Quarter", value: "Quarter", text: "Quarter" }]}
                                /* // hourly is optional
                                 options={[{key: "Whole Day", value: "Whole Day", text: "Whole Day"}, {key: "Half Day", value: "Half Day", text: "Half Day"}, {key: "Quarter", value: "Quarter", text: "Quarter"},{key:"Hourly",value:"Hourly",text:"Hourly"}]} */
                                value={this.state.Count}
                                onChange={(event, data) => { this.setState({ Count: data.value, showHourField: data.value === "Hourly" }); }}>
                            </Dropdown>
                        </div>
                        <div className="column" style={{ "margin": "0px 0px 10px 0px", "padding": "0px 0px 0px 5px" }}>
                            <div>Year Deduction</div>
                            {/* <Input fluid type='number' value={this.state.YearDeducted} onChange={ (evt) => {this.setState({ YearDeducted: evt.target.value }); }} /> */}
                            <Input
                                fluid
                                disabled={this.props.isBusy}
                                value={this.state.YearDeducted}
                                error={!checkYear(this.state.YearDeducted).Result}
                                onChange={(event) => { this.setState({ YearDeducted: handleNumeric(event, true) }); }}
                                onKeyPress={(e) => { handleNumericOnKeyPress(e) }}
                                onKeyDown={(e) => { handleNumericOnKeyDown(e) }}
                            />
                        </div>
                    </div>
                    <div className="ui one column grid" style={noMarginNoPadding}>
                        {this.state.showHourField &&
                            <div className="column" style={{ "margin": "0px 0px 10px 0px", padding: "0px", width: "50%" }}>
                                <div>Hours</div>
                                <Input fluid disabled={this.props.isBusy} value={this.state.Hours}
                                    onChange={(event) => { this.setState({ Hours: handleNumeric(event) }); }}
                                />
                            </div>
                        }
                    </div>
                    <div className="ui one column grid" style={noMarginNoPadding}>
                        <div className="column" style={someMarginNoPadding}>
                            <div>Reasons</div>
                            <Form>
                                <TextArea
                                    fluid
                                    value={this.state.Remarks}
                                    disabled={this.props.isBusy}
                                    style={this.props.showRequiredField && !this.state.Remarks ? semanticErrorStyle : []}
                                    onChange={(event, data) => { this.setState({ Remarks: data.value }); }}
                                />
                            </Form>
                        </div>
                    </div>
                </Modal.Content>
                <Modal.Actions>
                    <Button basic icon='save' content="Submit" disabled={this.props.isBusy} loading={this.props.isBusy} onClick={this.props.onSave} ></Button>
                    <Button basic icon='cancel' content="Cancel" disabled={this.props.isBusy} onClick={this.props.onCancel}></Button>
                </Modal.Actions>
                {
                    this.state.showAskCloseDialog &&
                    <MessageBoxYesNo title="Create New Employee"
                        action="Yes"
                        onClose={() => { this.setState({ showAskCloseDialog: false }) }}
                        onAction={() => { this.props.onCreateEmpClick() }}
                        caption="All unsaved changes will be lost. Do you wish to continue?"
                    />
                }
            </Modal>
        );
    }

}

export default LeaveForm;
import { authGet, fetchGet } from '../ajax';

const signalR = require('@microsoft/signalr');

export async function StartSignalR(){
      var resp = await authGet("/Notification/SignalRToken");
      var token = await resp.text();
    
      var options = {
        accessTokenFactory:()=>token
      };
      var connection = new signalR.HubConnectionBuilder()
      .withUrl(global.SignalRUrl, options)
      .build();
      
      try{
        await connection.start();
        global.SignalRConnection = connection;

        var sessionId = localStorage.getItem("SessionId");

        connection.on(`userId:${sessionId}`, (data) => {
            if( global.ProcessNotification != undefined){
                global.ProcessNotification(data);
            }
          });
      }
      catch{

      }
      
      
}
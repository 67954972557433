import React from 'react';
import 'semantic-ui-css/semantic.min.css';
import ReportModelBase from './ReportModelBase';
import { Table} from '../../../node_modules/semantic-ui-react';
// const moment = require('moment');

class AttendanceAnalyticReportModel extends ReportModelBase {

    constructor(props){
        super(props);
        this.searchUrl = "api/Reports/createAttendanceAnalyticReport";
        this.title = "Attendance Analytics Report";
        this.exportToExcelUrl = "api/Reports/ExportAttendanceAnalyticReportToExcel";
        this.exportToExcelDataUrl = "api/Reports/createExportAttendanceAnalyticReportToExcel";
        this.exportToPdfUrl = "api/Reports/createExportAttendanceAnalyticReportToPDF";
    }

    toTableRow = (rowData) => {
        return (
            <Table.Row>
                <Table.Cell>{rowData.Company}</Table.Cell>
                <Table.Cell>{rowData.Position}</Table.Cell>
                <Table.Cell>{rowData.EmployeeNo}</Table.Cell>
                <Table.Cell>{rowData.EmployeeName}</Table.Cell>
                <Table.Cell>{rowData.Date}</Table.Cell>
                <Table.Cell>{rowData.FirstTimeIn}</Table.Cell>
                <Table.Cell>{rowData.LastTimeOut}</Table.Cell>
                <Table.Cell>{rowData.Status}</Table.Cell>
            </Table.Row>
        );
    }

    createColumnHeaders = () => {
        return (
            <Table.Row>
                <Table.HeaderCell>Company</Table.HeaderCell>
                <Table.HeaderCell>Position</Table.HeaderCell>
                <Table.HeaderCell>Employee No</Table.HeaderCell>
                <Table.HeaderCell>Employee Name</Table.HeaderCell>
                <Table.HeaderCell>Date</Table.HeaderCell>
                <Table.HeaderCell>Fist Time In</Table.HeaderCell>
                <Table.HeaderCell>Last Time Out</Table.HeaderCell>
                <Table.HeaderCell>Status</Table.HeaderCell>                
            </Table.Row>
        );
    }

}

export default AttendanceAnalyticReportModel;
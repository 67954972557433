import React, { Component } from 'react';
import Select from 'react-select'; 
import { Button, Input, Dropdown, Header, Modal, Loader, Message, Table } from 'semantic-ui-react';
import 'semantic-ui-css/semantic.min.css';
import { ajaxPost } from '../../ajax';
import moment from 'moment';
import { dropdownMapping, empty_id, checkDate, checkDateRangeNoRestore } from '../../utils'; 
import EmployeeFilterModal from '../../Commons/EmployeeFilterModal';
import { customStyles } from '../../utils'; 


class CutoffPayrollForm extends Component {
    state = {
        Cutoff: {
            _id: empty_id,
            CompanyId: empty_id,
            Company: "",
            CutoffFrequency: "",
            Type: "STANDARD",
            Year: 2018,
            Month: 1,
            CutoffNumber: 1,
            DateStart: moment(new Date()).format("YYYY-MM-DD"),
            DateEnd: moment(new Date()).format("YYYY-MM-DD"),
            CutoffGroupCode: "DEFAULT",
            Status: "PREVIEW",
            CutoffOptions: {
                EmployeeIds: [],
                DepartmentIds: [],
                LocationIds: [],
                PositionIds: [],
                DivisionIds: [],
                CostCenterIds: [],
            }
        },
        cutoffNumbers: [],
        cutoffTypes: [],
        Companies: [],
        showCompany: false,
        showConflict: false,
        showError: false,
        conflict: [],
        error: [],
        saving: false,
        dialog: false,
        inactiveOnly: false,
        isCompanyLoading: false,
        showSelectEmployeeDialog: false,
    }

    mapAdvanceFilter() {
        const { Cutoff } = this.state;
        return {
            companyIds: [Cutoff.CompanyId],
            employeeIds: Cutoff.CutoffOptions.EmployeeIds,
            departmentIds: Cutoff.CutoffOptions.DepartmentIds,
            locationIds: Cutoff.CutoffOptions.LocationIds,
            positionIds: Cutoff.CutoffOptions.PositionIds,
            divisionIds: Cutoff.CutoffOptions.DivisionIds,
            costcenterIds: Cutoff.CutoffOptions.CostCenterIds,
            inactiveOnly: this.state.inactiveOnly
        }
    }

    constructor(props) {
        super(props);
        this.loadCompanies(data => {
            if (data.length === 1) {
                const cutoff = this.state.Cutoff; 
                cutoff.CompanyId = data[0].id;
                cutoff.Company = data[0].Name;
                this.setState({ Cutoff : cutoff});
                // this.state.Cutoff.advanceFilter.companyIds.push(data[0].id);
                this.setState({ showCompany: false });
            } else {
                this.setState({ showCompany: true });
            }
            this.setState({ Companies: dropdownMapping(data) });
        }, "COMPANY");
        var today = new Date();
        this.state.Cutoff.Year = parseInt(today.getFullYear());
        this.state.Cutoff.Month = today.getMonth() + 1;
        this.state.Cutoff = this.props.Cutoff == null ? this.state.Cutoff : this.props.Cutoff;
        this.state.Cutoff.DateStart = this.state.Cutoff.DateStart != null ? moment(this.state.Cutoff.DateStart).format("YYYY-MM-DD") : this.state.Cutoff.DateStart;
        this.state.Cutoff.DateEnd = this.state.Cutoff.DateEnd != null ? moment(this.state.Cutoff.DateEnd).format("YYYY-MM-DD") : this.state.Cutoff.DateEnd;
        this.loadCutoffTypes(data => {
            var list = [];
            data.forEach(element => {
                list.push({ text: element, value: element });
            });
            this.setState({ cutoffTypes: list });
        });
    }
    loadCutoffTypes = (callback) => {
        ajaxPost({
            url: 'api/Cutoff/getCutoffTypes',
            onSuccess: data => {
                callback(data);
            },
            finally: () => { }
        })
    }
    loadCompanies = (callback, parameters) => {

        ajaxPost({
            url: 'api/Group/getGroupsByType',
            data: {
                "Type": parameters
            },
            onSuccess: data => {
                callback(data);
            },
            finally: () => { }
        })
    }
    handleChange = name => event => {
        var current = this.state.Cutoff;
        current[name] = event.target.value;
        this.setState({ Cutoff: current });
    };


    // COMPANY
    getCompanyOptions = () => {
        const { Companies } = this.state;

        const options = Companies.map(company => ({
            label: company.text, 
            value: company.value, 
        }));

        return options;
    }

    handleCompanyChange = (selectedOption) => {
        const selectedCompanyId = selectedOption ? selectedOption.value : null;

        var current = { ...this.state.Cutoff };
        current.CompanyId = selectedCompanyId;  
        this.setState({ Cutoff: current });
    }

    handleComboChange = (name, val) => {
        var current = this.state.Cutoff;
        current[val.name] = val.value;
        // if (val.name === 'CompanyId') {
        //     current["Company"] = name.target.innerText;
        //     current.advanceFilter["companyIds"] = [];
        //     current.advanceFilter["companyIds"].push(val.value);
        // }
        if (val.name === 'Month') {
            this.updateDateRange(current, val.value, null);
        }
        else if (val.name === 'CutoffFrequency') {
            this.updateCutoffNumber(val.value);
        }
        else if (val.name === "Type") {
            if (val.value === "QUIT CLAIM") {
                this.setState({ inactiveOnly: true });
            }
            else {
                this.setState({ inactiveOnly: false });
            }
        }
        this.setState({ Cutoff: current });
    }
    updateDateRange = (cutoff, month, year) => {
        var sYear = new Date(cutoff["DateStart"]).getFullYear();
        sYear = year == null ? sYear : year;
        var sMonth = new Date(cutoff["DateStart"]).getMonth() + 1;
        sMonth = moment == null ? sMonth : month;
        var sDay = new Date(cutoff["DateStart"]).getDate();
        var strDateStart = (sYear + "-" + sMonth + "-" + sDay).toString();
        cutoff["DateStart"] = moment(strDateStart).format("YYYY-MM-DD");

        var eYear = new Date(cutoff["DateEnd"]).getFullYear();
        eYear = year == null ? eYear : year;
        var eMonth = new Date(cutoff["DateEnd"]).getMonth() + 1;
        eMonth = moment == null ? eMonth : month;
        var eDay = new Date(cutoff["DateEnd"]).getDate();
        var strDateEnd = (eYear + "-" + eMonth + "-" + eDay).toString();
        cutoff["DateEnd"] = moment(strDateEnd).format("YYYY-MM-DD");

    }
    updateCutoffNumber = (frequency) => {
        let cutoffNumbers = this.state.cutoffNumbers;
        if (frequency === 'MONTHLY') {
            
            cutoffNumbers = [{ key: 1, text: '1st', value: 1 }];
        }
        else if (frequency === 'SEMIMONTHLY') {
            cutoffNumbers = [{ key: 1, text: '1st', value: 1 }, { key: 2, text: '2nd', value: 2 },];
        } else {
            cutoffNumbers = [
                { key: 1, text: '1st', value: 1 }, { key: 2, text: '2nd', value: 2 },
                { key: 3, text: '3rd', value: 3 }, { key: 4, text: '4th', value: 4 }, { key: 5, text: '5th', value: 5 },
            ];
        }
        this.setState({ cutoffNumbers: cutoffNumbers });

    }
    handleSave = (e, data) => {
        var model = this.state.Cutoff;

        if (model.CompanyId === empty_id) {
            this.setState({ showError: true, error: ["No Company Selected"] });
            return;
        }

        if (model.CutoffFrequency === '') {
            this.setState({ showError: true, error: ["No Frequency Selected"] });
            return;
        }

        if (!checkDate(model.DateStart).Result ||
            !checkDate(model.DateEnd).Result
        ) {
            this.setState({ showConflict: true, conflict: ["Incorrect Date Range"] });
            return;
        }
        if(!checkDateRangeNoRestore(model.DateStart, model.DateEnd).Result){
            this.setState({ showConflict: true, conflict: ["Incorrect Date Range"] });
            return;
        }
            this.setState({ saving: true });
            this.Save(data => {
                this.setState({ saving: false });
                this.setState({ showConflict: false });
                if (data.content != null) {
                    this.setState({ Cutoff: data.content });
                    this.DialogOnSuccess();
                }
                if (data.errors.length > 0 && data.errors != null) {
                    this.setState({ showConflict: true });
                    this.setState({ conflict: data.errors });
                    this.setState({ Cutoff: model });
                }

            }, model);
        
    }
    onSelectEmployees = () => {
        this.setState({ showSelectEmployeeDialog: true });
    }
    handleSelectEmployees = () => {
        this.setState({ showSelectEmployeeDialog: false });
    }
    handleCancelSelectEmployees = () => {
        this.setState({ showSelectEmployeeDialog: false });
    }
    onViewFilterClick(isOpen) {
        this.setState({ showSelectEmployeeDialog: isOpen });
    }
    advanceFilterCallBack = (advanceFilter, includeDeleted) => {
        // var hasAdvFilter = true;
        // if ((advanceFilter.companyIds === undefined || advanceFilter.companyIds === null || advanceFilter.companyIds.length === 0) &&
        //     (advanceFilter.departmentIds === undefined || advanceFilter.departmentIds === null || advanceFilter.departmentIds.length === 0) &&
        //     (advanceFilter.locationIds === undefined || advanceFilter.locationIds === null || advanceFilter.locationIds.length === 0) &&
        //     (advanceFilter.positionIds === undefined || advanceFilter.positionIds === null || advanceFilter.positionIds.length === 0) &&
        //     (advanceFilter.divisionIds === undefined || advanceFilter.divisionIds === null || advanceFilter.divisionIds.length === 0))
        //     hasAdvFilter = false
        let ids = advanceFilter.employeeIds != null && advanceFilter.employeeIds !== "" ? advanceFilter.employeeIds.map(x => x.value) : [];
        advanceFilter.employeeIds = ids;
        var cutoff = this.state.Cutoff;
        cutoff.CutoffOptions = {
            DepartmentIds: advanceFilter.departmentIds,
            LocationIds: advanceFilter.locationIds,
            PositionIds: advanceFilter.positionIds,
            DivisionIds: advanceFilter.divisionIds,
            CostCenterIds: advanceFilter.costcenterIds,
            EmployeeIds: advanceFilter.employeeIds
        }
        this.setState({
            Cutoff: cutoff,
            showSelectEmployeeDialog: false,
        });
        // get employeeIDs
    }
    // getValidatedEmployees = (callback,model) =>{
    //     this.setState({saving:true});
    //     ajaxPost({
    //         url:'api/Cutoff/getValidatedEmployees',
    //         data: {
    //             "Parameters": JSON.stringify(model),
    //         },
    //         onSuccess: data =>{
    //             callback(data);
    //         },
    //         onError: function(err){
    //             alert(JSON.stringify(err));
    //         },
    //         finally: () => { this.setState({ saving: false }); }
    //     })
    // }

    Save = (callback, model) => {
        // model.Year = parseInt(model.Year);
        // model.DateStart =moment(model.DateStart);
        // model.DateEnd =moment(model.DateEnd);
        ajaxPost({
            url: 'api/Cutoff/save',
            data: {
                "model": JSON.stringify(model),
            },
            onSuccess: data => {
                callback(data);
            },
            onError: function (err) {
                alert(JSON.stringify(err));
            },
            finally: () => { this.setState({ saving: false }); }
        })
    }
    DialogOnSuccess = () => {
        this.setState({ dialogTitle: "Save" });
        this.setState({
            dialogContent: (
                <div>
                    <p>Cutoff has been created successfully</p>
                </div>
            )
        });
        this.setState({ dialog: true });
    }
    onCloseDialog = () => {
        this.setState({ dialog: false });
        this.props.onUpdateComplete(this.state.Cutoff);
    }

    render() {
        const { isCompanyLoading, Cutoff } = this.state;

        const months = [
            { key: 1, text: 'January', value: 1 },
            { key: 2, text: 'Febuary', value: 2 },
            { key: 3, text: 'March', value: 3 },
            { key: 4, text: 'April', value: 4 },
            { key: 5, text: 'May', value: 5 },
            { key: 6, text: 'June', value: 6 },
            { key: 7, text: 'July', value: 7 },
            { key: 8, text: 'August', value: 8 },
            { key: 9, text: 'September', value: 9 },
            { key: 10, text: 'October', value: 10 },
            { key: 11, text: 'November', value: 11 },
            { key: 12, text: 'December', value: 12 },
        ];
        const cutoffNumbers = this.state.cutoffNumbers;
        const CutoffFrequency = [
            { key: 1, text: 'MONTHLY', value: 'MONTHLY' },
            { key: 2, text: 'SEMI-MONTHLY', value: 'SEMIMONTHLY' },
            { key: 3, text: 'WEEKLY', value: 'WEEKLY' },
        ];
        const Companies = this.state.Companies;
        return (
            <Modal size='small' open>
                <Modal.Header>
                    <div style={{ overflow: "hidden" }}>
                        <Header size='medium'>New Cutoff</Header>
                    </div>
                </Modal.Header>
                <Modal.Content>
                    <div>
                        <Loader active={this.state.saving} />

                        {/* <Menu size="mini" secondary>
                        <Menu.Menu position="right" >
                        <Button content="Save" size="mini" icon="save" color="green"
                            onClick={this.handleSave.bind(this)}/>
                        <Button content="Cancel" size="mini" icon="cancel" color="grey"
                            onClick={this.props.onCloseForm.bind(this)}/>
                        </Menu.Menu>
                    </Menu> */}
                        {
                            this.state.showConflict &&
                            <Message negative>
                                <Message.Header>Conflict !</Message.Header>
                                <Message.Content>{this.state.conflict.map(n => {
                                    return (<p>{n}</p>)
                                })}</Message.Content>
                            </Message>
                        }
                        {
                            this.state.showError &&
                            <Message negative>
                                <Message.Content>{this.state.error.map(n => {
                                    return (<p>{n}</p>)
                                })}</Message.Content>
                            </Message>
                        }
                        <div>
                            <Table>
                                {this.state.showCompany && 
                                <Table.Row >
                                    <Table.Cell colSpan={2}>
                                        Company
                                    </Table.Cell>
                                    <Table.Cell colSpan={2}>
                                        <div style={{width: '250px'}}> 
                                            <Select
                                                placeholder='Company'
                                                onChange={this.handleCompanyChange}
                                                options={this.getCompanyOptions()}
                                                styles={customStyles}
                                                isLoading={isCompanyLoading}
                                                value={this.getCompanyOptions().find(option => option.value === Cutoff.CompanyId)}
                                                name="CompanyId"
                                                isSearchable 
                                            />
                                        </div>
                                    </Table.Cell>
                                </Table.Row>}
                                    {/* <Table.Cell colSpan={2}>
                                        <Dropdown style={{ width: "250px" }} selection options={Companies} 
                                            value={this.state.Cutoff.CompanyId} name="CompanyId"
                                            onChange={this.handleComboChange.bind(this)} />
                                    </Table.Cell> */}
                                <Table.Row>
                                    <Table.Cell colSpan={2}>
                                        Type
                                    </Table.Cell>
                                    <Table.Cell colSpan={2}>
                                        <Dropdown style={{ width: "250px" }} selection options={this.state.cutoffTypes} placeholder="Cutoff Type"
                                            value={this.state.Cutoff.Type} name="Type"
                                            onChange={this.handleComboChange.bind(this)} />
                                    </Table.Cell>
                                </Table.Row>
                                <Table.Row>
                                    <Table.Cell colSpan={2}>
                                        Frequency
                                    </Table.Cell>
                                    <Table.Cell colSpan={2}>
                                        <Dropdown style={{ width: "250px" }} selection options={CutoffFrequency} placeholder="Frequency"
                                            value={this.state.Cutoff.CutoffFrequency} name="CutoffFrequency"
                                            onChange={this.handleComboChange.bind(this)} />
                                    </Table.Cell>
                                </Table.Row>
                                <Table.Row>
                                    <Table.Cell>
                                        Month
                                    </Table.Cell>
                                    <Table.Cell>
                                        <Dropdown selection options={months} placeholder="Month"
                                            value={this.state.Cutoff.Month} name="Month"
                                            onChange={this.handleComboChange.bind(this)} />
                                    </Table.Cell>
                                    <Table.Cell>
                                        Year
                                    </Table.Cell>
                                    <Table.Cell>
                                        <Input fluid placeholder='Year'
                                            value={this.state.Cutoff.Year}
                                            onChange={this.handleChange("Year").bind(this)} />
                                    </Table.Cell>
                                </Table.Row>
                                <Table.Row>
                                    <Table.Cell colSpan={2}>
                                        Start Date
                                    </Table.Cell>
                                    <Table.Cell colSpan={2}>
                                        <Input fluid type="date" placeholder='Date Start'
                                            value={this.state.Cutoff.DateStart}
                                            error={!checkDateRangeNoRestore(this.state.Cutoff.DateStart, this.state.Cutoff.DateEnd).Result}
                                            onChange={this.handleChange("DateStart").bind(this)} />
                                    </Table.Cell>
                                </Table.Row>
                                <Table.Row>
                                    <Table.Cell colSpan={2}>
                                        End Date
                                    </Table.Cell>
                                    <Table.Cell colSpan={2}>
                                        <Input fluid type="date" placeholder='Date End'
                                            value={this.state.Cutoff.DateEnd}
                                            error={!checkDateRangeNoRestore(this.state.Cutoff.DateStart, this.state.Cutoff.DateEnd).Result}
                                            onChange={this.handleChange("DateEnd").bind(this)} />
                                    </Table.Cell>
                                </Table.Row>
                                <Table.Row>
                                    <Table.Cell colSpan={2}>
                                        Cutoff Number
                                    </Table.Cell>
                                    <Table.Cell colSpan={2}>
                                        <Dropdown style={{ width: "250px" }} selection options={cutoffNumbers} placeholder="Cutoff Number"
                                            value={this.state.Cutoff.CutoffNumber} name="CutoffNumber"
                                            onChange={this.handleComboChange.bind(this)} />
                                    </Table.Cell>
                                </Table.Row>
                                <Table.Row>
                                    <Table.Cell colSpan={2}>Employees</Table.Cell>
                                    <Table.Cell colSpan={2}>
                                        <Button basic icon="check" disabled={!this.state.Cutoff.CutoffFrequency || !this.state.Cutoff.Type} content="Select" onClick={this.onSelectEmployees.bind(this)} />
                                    </Table.Cell>
                                </Table.Row>
                            </Table>
                        </div>
                        {/* Modal Form */}
                        {<Modal size="mini" open={this.state.dialog} onClose={this.close} >
                            <Header content={this.state.dialogTitle} />
                            <Modal.Content>
                                {this.state.dialogContent}
                            </Modal.Content>
                            <Modal.Actions>
                                <Button basic icon="check" content="Yes"
                                    onClick={this.onCloseDialog.bind(this)} />
                            </Modal.Actions>
                        </Modal>}
                        {this.state.showSelectEmployeeDialog &&
                            <EmployeeFilterModal
                                advFilter={this.mapAdvanceFilter()}
                                action="Select"
                                open={this.state.showSelectEmployeeDialog}
                                onCancel={this.onViewFilterClick.bind(this, false)}
                                callback={this.advanceFilterCallBack.bind(this)}
                                hideFilter={["STATUS", "COMPANY"]}
                            />
                        }
                        {/* <SelectEmployeesForDTRDialog title="Select Employees"
                        action="Select"
                        onClose={this.handleCancelSelectEmployees.bind(this)}
                        onAction={this.handleSelectEmployees.bind(this)}
                        caption="Select the employee(s) you want to include on cutoff"
                        allowSelectAll={true}
                    /> */}
                    </div>
                </Modal.Content>
                <Modal.Actions>
                    <Button basic content="Save" size="mini" icon="save"
                        onClick={this.handleSave.bind(this)} loading={this.state.saving} />
                    <Button basic content="Cancel" size="mini" icon="cancel"
                        onClick={this.props.onCloseForm.bind(this)} disabled={this.state.saving} />
                </Modal.Actions>
            </Modal>
        );
    }
}
export default CutoffPayrollForm
import React from 'react';
import 'semantic-ui-css/semantic.min.css';
import ReportModelBase from './ReportModelBase';
import { Table, Modal, Button } from '../../../node_modules/semantic-ui-react';
const moment = require('moment');

function compareValues(key, order='asc') {
  return function(a, b) {
    if(!a.hasOwnProperty(key) || !b.hasOwnProperty(key)) {
      // property doesn't exist on either object
        return 0; 
    }

    const varA = (typeof a[key] === 'string') ? 
      a[key].toUpperCase() : a[key];
    const varB = (typeof b[key] === 'string') ? 
      b[key].toUpperCase() : b[key];

    let comparison = 0;
    if (varA > varB) {
      comparison = 1;
    } else if (varA < varB) {
      comparison = -1;
    }
    return (
      (order == 'desc') ? (comparison * -1) : comparison
    );
  };
}

class MasterListReportModel extends ReportModelBase {

    constructor(props){
        super(props);
        this.searchUrl = "api/Reports/employeeMasterlist";
        this.title = "Employee Masterlist";
        this.exportToExcelUrl = "api/Reports/ExportEmployeeMasterlistToExcel";
        this.exportToPdfUrl = "api/Reports/ExportEmployeeMasterlistToPDF";
    }

    toTableRow = (rowData) => {
        return (
            <Table.Row>
                <Table.Cell>{rowData["Employee No"]}</Table.Cell>
                <Table.Cell>{rowData["Employee"]}</Table.Cell>
                <Table.Cell>{rowData["Date Hired"]}</Table.Cell>
                <Table.Cell>{rowData["Birth Date"]}</Table.Cell>
                <Table.Cell>{rowData["Email"]}</Table.Cell>
                <Table.Cell>{rowData["Company"]}</Table.Cell>
                <Table.Cell>{rowData["Department"]}</Table.Cell>
                <Table.Cell>{rowData["Position"]}</Table.Cell>
            </Table.Row>
        );
    }

    

    createColumnHeaders = () => {
        return (
            <Table.Row>
                <Table.HeaderCell>Employee No</Table.HeaderCell>
                <Table.HeaderCell>Employee</Table.HeaderCell>
                <Table.HeaderCell>Date Hired</Table.HeaderCell>
                <Table.HeaderCell>Birthdate</Table.HeaderCell>
                <Table.HeaderCell>Email</Table.HeaderCell>
                <Table.HeaderCell>Company</Table.HeaderCell>
                <Table.HeaderCell>Department</Table.HeaderCell>
                <Table.HeaderCell>Position</Table.HeaderCell>
            </Table.Row>
        );
    }

}

export default MasterListReportModel;
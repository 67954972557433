import React from 'react';
import 'semantic-ui-css/semantic.min.css';
import ReportModelBase from '../ReportModelBase';
import { Table, List } from 'semantic-ui-react';
const moment = require('moment');

class EmployeeReportModelGroupByPosition extends ReportModelBase {

    constructor(props){
        super(props);
        this.searchUrl = "api/Reports/EmployeeSubordinatesReport";
        this.title = "Employee Subordinate Report";
        this.exportToExcelUrl = "api/Reports/ExportEmployeeSubordinatesReportToExcel";
        this.exportToPdfUrl = "api/Reports/ExportEmployeeSubordinatesReportToPDF";
    }

    toTableRow = (rowData) => {
        return (
            <Table.Row>
                <Table.Cell>{rowData["Employee No"]}</Table.Cell>
                <Table.Cell>{rowData["Employee"]}</Table.Cell>
                <Table.Cell>{rowData["Total"]}</Table.Cell>
                <Table.Cell>{rowData["Subordinates"].split("• ").map(sub => {
                     return (
                        <List>
                            <List.Item>{sub==="" ? "" : "• " + sub}</List.Item>
                        </List>)
                        })}
                </Table.Cell>
            </Table.Row>
        );
    }

    createColumnHeaders = () => {
        return (
            <Table.Row>
                <Table.HeaderCell>Employee No</Table.HeaderCell>
                <Table.HeaderCell>Employee</Table.HeaderCell>
                <Table.HeaderCell>Total</Table.HeaderCell>
                <Table.HeaderCell>Subordinates</Table.HeaderCell>
            </Table.Row>
        );
    }

}
export default EmployeeReportModelGroupByPosition;
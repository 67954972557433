import React, { Component } from 'react';
import { Input, Button, Checkbox, Modal, Menu, Visibility, Loader, Accordion, Icon } from 'semantic-ui-react';
import { ajaxPost } from '../ajax';

import styles from './EmployeeSelection.module.css';
export default class EmployeeSelection extends Component {
    constructor(props) {
        super(props);

        this.state = {
            width: props.width,
            height: props.height,
            tempFilterOptions: {
                selectedCompanies: [],
                selectedDepartments: [],
                selectedPositions: [],
                selectedLocations: []
            },
            filterOptions: {
                selectedCompanies: [],
                selectedCostCenter: [],
                selectedDepartments: [],
                selectedPositions: [],
                selectedLocations: [],
            },
            includeInactive: false,
            isSelectAll: false,
            searchText: '',
            latestSearchText: '',
            employeeList: [],
            companyList: [],
            costcenterList: [],
            departmentList: [],
            positionList: [],
            locationList: [],

            employeeCount: 0,
            selectedEmployeeIds: [],
            selectedCount: 0,
            startingIndex: 0,

            isCompanyFilterOpen: false,
            isCostCenterFilterOpen: false,
            isDepartmentFilterOpen: false,
            isPositionFilterOpen: false,
            isLocationFilterOpen: false,

            isEmployeeLoading: false,
            isMaximumScrollReach: false,
            isAdvanceFilterOpen: false,
        }

        this.loadEmployees();
        this.loadGroups(); 
    }

    componentDidUpdate = () => {
        if (this.state.isEmployeeLoading === false && this.state.isMaximumScrollReach === true) {
            this.setState({startingIndex: this.state.startingIndex + 1, isEmployeeLoading: true}, this.loadEmployees);
        }
    }

    loadEmployees() {
        var {employeeList, isSelectAll, selectedEmployeeIds, isMaximumScrollReach, searchText} = this.state;
        this.setState({isEmployeeLoading: true, isMaximumScrollReach: false});
        ajaxPost({
            url: "api/Employee/searchEmployeeSelection",
            data: {
                "Search": searchText,
                "includeDeleted": this.state.includeInactive,
                "startingIndex": isMaximumScrollReach? 0: this.state.startingIndex * 30,
                "itemCount": 30,
                "excludedEmployeeIds": isMaximumScrollReach? this.state.employeeList.map(x=> {return x._id}): this.state.selectedEmployeeIds,
                "CompanyIDs": this.state.filterOptions.selectedCompanies,
                "DepartmentIDs": this.state.filterOptions.selectedDepartments,
                "PositionIDs": this.state.filterOptions.selectedPositions,
                "CostCenterIDs": this.state.filterOptions.selectedCostCenter,
                "CityIDs": this.state.filterOptions.selectedLocations,
                "inactiveOnly": false,
            },
            onSuccess: data => {
                // data.content = data.content.filter(x=> !selectedEmployeeIds.includes(x._id));
                data.content = data.content.filter(x=> !employeeList.map(x=> {return x._id}).includes(x._id))
                
                if(data.count !== 0 && data.count !== this.state.employeeCount)
                    isSelectAll = false;

                if(isSelectAll)
                    data.content.map(x=> selectedEmployeeIds.push(x._id))

                this.setState({ employeeList: employeeList.concat(data.content),
                    selectedEmployeeIds,
                    employeeCount: data.count,
                    isEmployeeLoading: false,
                    isMaximumScrollReach: false,
                    isSelectAll,
                    latestSearchText: searchText
                });
            },
            finally: () => { }
        })
    }

    loadGroups() {
        ajaxPost({
            url: 'api/group/search',
            data: {
                Search: '',
                startingIndex: 0,
                itemCount: 9999,
                Types: ['COMPANY', 'DEPARTMENT', 'CITY','POSITION','COST_CENTER']
            },
            onSuccess: (data) => {
                this.setState({
                    companyList: data.content.filter(x=> x.Type === 'COMPANY'),
                    departmentList: data.content.filter(x=> x.Type === 'DEPARTMENT'),
                    locationList: data.content.filter(x=> x.Type === 'CITY'),
                    positionList:data.content.filter(x => x.Type = 'POSITION'),
                    costcenterList:data.content.filter(x => x.Type = 'COST_CENTER')
                });
            },
            finally: () => {}
        })
    }
 
    onEmployeeCheckedChanged = (id, e, { checked }) => {
        var {isSelectAll, selectedEmployeeIds, selectedCount, employeeCount, startingIndex, latestSearchText } = this.state;
        if (checked)
            selectedEmployeeIds.push(id);
        else
            selectedEmployeeIds = selectedEmployeeIds.filter(x => x !== id);

        selectedCount = selectedEmployeeIds.length;

        if(employeeCount === selectedCount)
            isSelectAll = true;
        else if(employeeCount !== selectedCount)
            isSelectAll = false;
            
        this.props.callback(this.state.filterOptions, selectedEmployeeIds, isSelectAll && latestSearchText === "")
        this.setState({ selectedEmployeeIds, selectedCount, isSelectAll, startingIndex });
    }

    onSelectAllCheckedChanged = (e, {checked}) => {
        var {isSelectAll, selectedEmployeeIds, selectedCount, employeeCount, employeeList, latestSearchText} = this.state;

        isSelectAll = checked;
        if(!checked){
            selectedCount = 0;
            selectedEmployeeIds = [];
        }
        else {
            selectedCount = employeeCount;
            selectedEmployeeIds = employeeList.map(x=> {return x._id});
        }
        
        this.props.callback(this.state.filterOptions, selectedEmployeeIds, isSelectAll && latestSearchText === "")
        this.setState({ isSelectAll, selectedEmployeeIds, selectedCount })
    }

    onSearchClick = () => {
        var {employeeList, selectedEmployeeIds} = this.state;
        employeeList = employeeList.filter(x=> selectedEmployeeIds.includes(x._id));

        this.setState({startingIndex: 0, isMaximumScrollReach: false, employeeList}, this.loadEmployees);
    }

    onBottomVisible = () => {
        if (this.state.employeeList.length < this.state.employeeCount && this.state.isEmployeeLoading === false)
            this.setState({ isMaximumScrollReach: true })
    }

    onAdvanceFilterClick = () => {
        this.setState({isAdvanceFilterOpen: true})
    }

    onFilterCheckedChanged = (id, filterName, e, {checked}) => {
        var list = this.state.tempFilterOptions;
        if(checked)
            list[filterName].push(id);
        else
            list[filterName] = list[filterName].filter(x=> x !== id)

        this.setState({tempFilterOptions: list})
    }
    onChkCheckChange = (e) => {
        this.setState({
            includeInactive: !this.state.includeInactive 
        } )
    }
    handleContextRef = (contextRef) => this.setState({ contextRef })

    render() {
        return (
            <Menu vertical style={{ width: this.state.width, height: this.state.height }}>
                <div style={{padding: 5}}>
                    <div style={{ display: 'flex', width: '100%', paddingTop: 5 }}>
                        <Input style={{ width: '100%' }} value={this.state.searchText} onChange={(e) => this.setState({ searchText: e.target.value })} />
                        <Button icon='search' onClick={this.onSearchClick.bind(this)}/>
                        <Button float='right' icon='cog' onClick={this.onAdvanceFilterClick.bind(this)}/>
                    </div>
                    <div style={{ display: 'flex', width: '100%', paddingTop: 5 }}>
                        <label>Total: {this.state.employeeCount}</label> &nbsp; &nbsp;
                        <label>Selected: {this.state.selectedCount}</label> &nbsp; &nbsp;
                        <Checkbox float='right' label='Select All' style={{ right: 0 }} checked={this.state.isSelectAll} onChange={this.onSelectAllCheckedChanged.bind(this)} />
                    </div> 
                    <div ref={this.handleContextRef} style={{height: window.innerHeight - 300, overflowY: 'auto'}}>
                    {this.state.contextRef &&
                        <Visibility context={this.state.contextRef} offset={[10, 10]} once={false}
                            onBottomVisible={this.onBottomVisible.bind(this)}
                            // onTopVisible={() => { if (this.state.startingIndex !== 0 && this.state.employeeList.length > 120) this.setState({ isMinimumScrollReach: true }) }}
                        >
                            {this.state.employeeList.map((x,index) => {
                                return (<div key={"e"+index} style={{paddingTop: 5}} className={x.deleted ? styles.red_color : styles.black_color}>
                                    <Checkbox  checked={this.state.isSelectAll || this.state.selectedEmployeeIds.includes(x._id)} label={x.EmployeeName} onChange={this.onEmployeeCheckedChanged.bind(this, x._id)} />
                                </div>
                            )})}
                        </Visibility>
                    }
                    {this.state.isLoading && this.state.isMaximumScrollReach && <Loader active inline='centered' />}
                    </div>
                </div>
                {this.state.isAdvanceFilterOpen && <Modal open={true}>
                    <Modal.Header>
                        Advance Filter
                    </Modal.Header>
                    <Modal.Description>
                        <Accordion as={Menu} vertical fluid>
                            <Menu.Item>
                                <Accordion.Title active={this.state.isCompanyFilterOpen} onClick={()=> {this.setState({isCompanyFilterOpen: !this.state.isCompanyFilterOpen})}}>
                                    <Icon name={this.state.isCompanyFilterOpen?'angle down':'angle right'}/> COMPANY
                                </Accordion.Title>
                                <Accordion.Content active={this.state.isCompanyFilterOpen} content=
                                    {this.state.companyList.map((x) => {
                                        return (<div style={{paddingTop: 5 }}>
                                            <Checkbox checked={this.state.tempFilterOptions.selectedCompanies.includes(x._id)} label={x.Name} onClick={this.onFilterCheckedChanged.bind(this, x._id, 'selectedCompanies')}/>
                                        </div>
                                    )})}
                                />
                            </Menu.Item>

                            <Menu.Item>
                                <Accordion.Title active={this.state.isDepartmentFilterOpen} onClick={()=> {this.setState({isDepartmentFilterOpen: !this.state.isDepartmentFilterOpen})}}>
                                    <Icon name={this.state.isDepartmentFilterOpen?'angle down':'angle right'}/> DEPARTMENT
                                </Accordion.Title>
                                <Accordion.Content active={this.state.isDepartmentFilterOpen} content=
                                    {this.state.departmentList.map((x) => {
                                        return (<div style={{paddingTop: 5 }}>
                                            <Checkbox checked={this.state.tempFilterOptions.selectedDepartments.includes(x._id)} label={x.Name} onClick={this.onFilterCheckedChanged.bind(this, x._id, 'selectedDepartments')}/>
                                        </div>
                                    )})}
                                />
                            </Menu.Item>

                            <Menu.Item>
                                <Accordion.Title active={this.state.isPositionFilterOpen} onClick={()=> {this.setState({isPositionFilterOpen: !this.state.isPositionFilterOpen})}}>
                                    <Icon name={this.state.isPositionFilterOpen?'angle down':'angle right'}/> POSITION
                                </Accordion.Title>
                                <Accordion.Content active={this.state.isPositionFilterOpen} content=
                                    {this.state.positionList.map((x) => {
                                        return (<div style={{paddingTop: 5 }}>
                                            <Checkbox checked={this.state.tempFilterOptions.selectedPositions.includes(x._id)} label={x.Name} onClick={this.onFilterCheckedChanged.bind(this, x._id, 'selectedPositions')}/>
                                        </div>
                                    )})}
                                />
                            </Menu.Item>

                            <Menu.Item>
                                <Accordion.Title active={this.state.isLocationFilterOpen} onClick={()=> {this.setState({isLocationFilterOpen: !this.state.isLocationFilterOpen})}}>
                                    <Icon name={this.state.isLocationFilterOpen?'angle down':'angle right'}/> LOCATION
                                </Accordion.Title>
                                <Accordion.Content active={this.state.isLocationFilterOpen} content=
                                    {<div>
                                        {this.state.locationList.map((x) => {
                                            return (<div  style={{paddingTop: 5 }} >
                                                <Checkbox  checked={this.state.tempFilterOptions.selectedLocations.includes(x._id)} label={x.Name} onClick={this.onFilterCheckedChanged.bind(this, x._id, 'selectedLocations')}/>
                                        </div>)})}
                                    </div>}
                                />
                            </Menu.Item>
                            <Menu.Item>
                            <div>
                                <Checkbox size='mini' label="Include Resigned" onChange={this.onChkCheckChange.bind(this)} checked={this.state.includeInactive} />
                            </div>
                            </Menu.Item>
                        </Accordion>
                    </Modal.Description>
                    <Modal.Actions>
                        <Button content='Done' onClick={() =>
                            this.setState({
                                filterOptions: JSON.parse(JSON.stringify(this.state.tempFilterOptions)),
                                isAdvanceFilterOpen: false,
                                employeeList: [],
                                selectedEmployeeIds: [],
                                selectedCount: 0,
                                startingIndex: 0
                            }, () => this.loadEmployees())
                        }/>
                        <Button content='Cancel' onClick={() =>
                            this.setState({
                                isAdvanceFilterOpen: false,
                                tempFilterOptions: JSON.parse(JSON.stringify(this.state.filterOptions))
                            })
                        }/>
                    </Modal.Actions>
                </Modal>}
            </Menu>
        )
    }
}
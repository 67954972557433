import React, { Component } from 'react';
import { Tab, Table, Form, Label, Icon, Button, Input, Image, Checkbox, Popup, Card, Modal, Pagination } from 'semantic-ui-react';
import { ajaxPost } from '../../ajax';
import Select from 'react-select';
import { fnLoadZKUserInfoByEmployeeId, fnGetEmployeeImagePath, isTaskAvailable, handleBloodTypeOnKeyPress, empty_id, customStyles, handleNumericOnKeyDown, handleNumericOnKeyPress } from '../../utils';
import MessageBoxOkOnly from '../../Commons/MessageBoxOkOnly';
import MessageBoxYesNo from '../../Commons/MessageBoxYesNo';
import '../../w3.css';
import EmployeeImage from '../../Commons/EmployeeImage';
var moment = require('moment');

const errorStyles = {
    control: (base, state) => ({
        ...base,
        color: "#DF4141",
        backgroundColor: "#FFF6F5",
        borderColor: "#ffb3b3",
        minHeight: 36,
        maxHeight: 95,
        overflowY: 'auto'
    })
}

function replaceAt(str, index, replacement) {
    var endChar = ''
    if (str.length - 1 !== index) {
        endChar = str.slice(index + 1, str.length);
    }
    return str.slice(0, index) + replacement + endChar;
}

function setInitialValue(type) {
    Object.keys(type).forEach(x => type[x] = type[x].initialValue)

    return type;
}

const DeviceUserType = [
    { label: "User", value: 0 },
    { label: "Enroller", value: 4 },
    { label: "Administrator", value: 14 },
]

const GenderType = [
    { label: "Male", value: "MALE" },
    { label: "Female", value: "FEMALE" }
]

const CivilStatusType = [
    { label: "Single", value: "SINGLE" },
    { label: "Married", value: "MARRIED" },
    { label: "Widowed", value: "WIDOWED" }
]

function shortDateFormat(BsonDateTime) {
    if (BsonDateTime === "")
        return "";
    var myDate = new Date(BsonDateTime);
    var month = myDate.getMonth() + 1;
    var day = myDate.getDate();
    var year = myDate.getFullYear();
    return month + "/" + day + "/" + year;
}

function eligibilityValueAnalyzer(field, collection) {
    if ("From_Date" in collection && !(collection["From_Date"] === "1/1/1900" || collection["From_Date"] === "") && field === "To_Date" && (collection["To_Date"] === "1/1/1900" || collection["To_Date"] === ""))
        return "UP TO PRESENT";
    else if (field === "From_Date" || field === "To_Date") {
        if (collection[field] === "" || collection["From_Date"] === "1/1/1900")
            return "";
        else
            return moment(collection[field]).format("MMMM YYYY")
    }
    else if (field.includes("Date")) {
        if (shortDateFormat(collection[field]) === "1/1/1900")
            return "";
        else
            return shortDateFormat(collection[field])
    }
    else if (field.includes("Sponsored")) {
        if (collection[field])
            return "YES"
        else
            return "NO"
    }
    else
        return collection[field]
}

const NewFamilyBackground = {
    Relation: { initialValue: "", isRequired: true },
    Name: { initialValue: "", isRequired: true },
    Birth_Date: { initialValue: "", isRequired: false },
    Contact_Number: { initialValue: "", isRequired: false },
    Employer: { initialValue: "", isRequired: false },
}

const NewAddress = {
    Type: { initialValue: "", isRequired: true },
    'Unit_#': { initialValue: "", isRequired: true },
    Building: { initialValue: "", isRequired: false },
    Street: { initialValue: "", isRequired: true },
    City: { initialValue: "", isRequired: true },
    District: { initialValue: "", isRequired: false },
    Province: { initialValue: "", isRequired: false },
    Zip_Code: { initialValue: "", isRequired: false },
    Country: { initialValue: "", isRequired: false }
}

const NewIdentification = {
    Type: { initialValue: "", isRequired: true },
    ID_Number: { initialValue: "", isRequired: true },
    Place_Issued: { initialValue: "", isRequired: false },
    Date_Issued: { initialValue: "", isRequired: false },
    Expiration_Date: { initialValue: "", isRequired: false }
}

const NewEducationalBackground = {
    Type: { initialValue: "", isRequired: true },
    School: { initialValue: "", isRequired: true },
    Location: { initialValue: "", isRequired: false },
    Course: { initialValue: "", isRequired: true },
    Honors: { initialValue: "", isRequired: false },
    From_Date: { initialValue: "", isRequired: false },
    To_Date: { initialValue: "", isRequired: false }
}

const NewTrainingBackground = {
    Training_Name: { initialValue: "", isRequired: true },
    Provider: { initialValue: "", isRequired: false },
    Venue: { initialValue: "", isRequired: false },
    Instructor: { initialValue: "", isRequired: false },
    Training_Fee: { initialValue: "", isRequired: false },
    Date: { initialValue: "", isRequired: false },
    Remarks: { initialValue: "", isRequired: false },
    Sponsored: { initialValue: false, isRequired: false }
}

const NewOtherInfo = {
    Type: { initialValue: "", isRequired: true },
    Description: { initialValue: "", isRequired: true }
}

const NewWorkExperience = {
    Company: { initialValue: "", isRequired: true },
    Position: { initialValue: "", isRequired: true },
    Reason_For_Leaving: { initialValue: "", isRequired: false },
    Nature_Of_Work: { initialValue: "", isRequired: false },
    Salary: { initialValue: "", isRequired: false },
    From_Date: { initialValue: "", isRequired: false },
    To_Date: { initialValue: "", isRequired: false }
}

const NewMedicalRecord = {
    Date: { initialValue: moment().format('YYYY-MM-DD'), isRequired: true },
    Medication: { initialValue: "", isRequired: true },
    Dose_Given: { initialValue: "", isRequired: true },
    Is_Frequenty: { initialValue: "", isRequired: true },
    Remarks: { initialValue: "", isRequired: false }
}

const NewDocument = {
    Type: { initialValue: "", isRequired: true },
    Reference_Date: { initialValue: "", isRequired: false },
    Remarks: { initialValue: "", isRequired: false },
    File: { initialValue: "", isRequired: true },
    Date_Created: { initialValue: moment().format('YYYY-MM-DD'), isRequired: false },
    Source: { initialValue: "", isRequired: false },
}

const NewPersonalReference = {
    Name: { initialValue: "", isRequired: true },
    Company: { initialValue: "", isRequired: true },
    Address: { initialValue: "", isRequired: true },
    Position: { initialValue: "", isRequired: true },
    Contact_Number: { initialValue: "", isRequired: true },
    Email: { initialValue: "", isRequired: false }
}

const NewCertificate = {
    Name: { initialValue: "", isRequired: true },
    Exam_Date: { initialValue: "", isRequired: true },
    Rating: { initialValue: "", isRequired: false },
    ExamPlace: { initialValue: "", isRequired: false }
}

const BlankZKUserInfo = {
    EmployeeId: "",
    AccessNumber: "",
    Password: "",
    RFID: "",
    MIFAREID: "", 
    Privilege: 0,
    FPCount: 0,
    FaceCount: 0,
    PalmCount: 0,
    Name: ""
}
class EmployeeUserView extends Component {
    dt = moment().format('YYYY-MM-DD');
    state = {
        action: "View",
        fullName: "",
        selectedEligibility: null,
        eligibilityIndex: -1,
        eligibilityDisplay: "",
        eligibilityOptions: [],
        userRoleList: [],

        employeeNoIsError: false,
        lastNameIsError: false,
        firstNameIsError: false,
        accessNumberIsError: false,

        isImageHover: false,
        modalOpen: false,

        isFirstLoad: false,
        eligibilityList: [],
        eligibilityDeleteList: [],
        isZKUserInfoSave: false,
        isEligibilitiesSave: false,
        isEligibilitiesRemove: false,

        prevEmployee: {},
        prevEligibilityList: [],
        prevZKUserInfo: {},

        userChanges: [],
        hasChanges: false,
        prevChangeCount: 0,
        showDeleteModal: false,

        focusField: "",
        activeIndex: 0,
    }

    focusRequiredField(fieldName) {
        this[fieldName].focus();
    }

    constructor(props) {
        super(props);
        var elgbtyType = {
            IDENTIFICATION: window.innerWidth > 768 ? "Identifications" : "ID's",
            ADDRESS: "Address",
            FAMILY_BACKGROUND: window.innerWidth > 768 ? "Family Background" : "Fam. Backg.",
            EDUCATIONAL_BACKGROUND: window.innerWidth > 768 ? "Educational Background" : "Educ. Backg.",
            WORK_EXPERIENCE: window.innerWidth > 768 ? "Work Experience" : "Work Exp.",
            // MEDICAL_RECORD: window.innerWidth > 768 ? "Medical Record" : "Med. Rec.",
            DOCUMENTS: window.innerWidth > 768 ? "Documents" : "Docs",
            TRAINING: "Training",
            OTHER_INFO: "Other Info",
            CHARACTER_REFERENCE: window.innerWidth > 768 ? "Character Reference" : "Char. Ref.",
        };

        Object.keys(elgbtyType).map(type => {
            return this.state["activePage_" + type] = 1;
        })

        this.state.selectedEmployee = {};
        this.state.prevEmployee = {};
        this.state.ZKUserInfo = JSON.parse(JSON.stringify(BlankZKUserInfo));
        this.state.prevZKUserInfo = JSON.parse(JSON.stringify(BlankZKUserInfo));

        this.state.eligibilityType = elgbtyType;
    }

    loadDetails = () => {
        var employee = null;
        var newEmployee = null;
        var zkEmpInfo = null;
        var fullName = "";
        var paramEmployee = {
            url: "api/employee/load",
            data: {
                employeeID: ""
            },
            onSuccess: data => {
                employee = JSON.parse(JSON.stringify(data));
                newEmployee = JSON.parse(JSON.stringify(data));
                newEmployee.Password = "";

                fullName = data.LastName + ", " +
                    data.FirstName + " " +
                    data.MiddleName;
            },
            finally: () => {
                fnLoadZKUserInfoByEmployeeId(employee._id, zkInfo => {
                    zkEmpInfo = zkInfo;
                    this.setState({ZKUserInfo:zkEmpInfo});
                    ajaxPost({
                        url: "api/Eligibility/search",
                        data: {
                            Search: '',
                            employeeId: employee._id,
                            startingIndex: 0,
                            itemCount: 1000,
                            includeDeleted: false
                        },
                        onSuccess: data => {
                            this.setState({
                                selectedEmployee: newEmployee,
                                prevEmployee: employee,
                                eligibilityList: JSON.parse(JSON.stringify(data.content)),
                                prevEligibilityList: JSON.parse(JSON.stringify(data.content)),
                                ZKUserInfo: JSON.parse(JSON.stringify(zkEmpInfo)),
                                prevZKUserInfo: JSON.parse(JSON.stringify(zkEmpInfo)),
                                fullName: fullName,
                            });
                        },
                        finally: () => { }
                    })
                })

             
            }
        }

        ajaxPost(paramEmployee)
    }

    componentWillMount = () => {
        // if (this.props.prevState !== undefined && this.props.prevState !== null)
        //     this.state = this.props.prevState;

        if (this.props.prevState === undefined || this.props.prevState === null)
            this.loadUserRoleList();

        this.loadDetails();
    }

    componentWillUnmount = () => {
        this.props.unmountCallback(this.state, "EmployeeUserView");
    }

    componentDidUpdate = () => {

        var changes = this.checkChanges();
        if (this.state.prevChangeCount !== changes.length) {
            this.setState({
                userChanges: changes,
                hasChanges: changes.length > 0,
                prevChangeCount: changes.length
            });
        }

        if (!this.state.showPopUp && !this.state.isSaving && this.state.focusField !== "") {
            if (this.state.focusField === "AccessNumber" && this.state.activeIndex !== 1) {
                this.setState({ activeIndex: 1 });
            }
            else if (this.state.focusField === "AccessNumber") {
                this.focusRequiredField(this.state.focusField);
                this.setState({ focusField: "" });
            }
            else {
                if (this.state.activeIndex !== 0) {
                    this.setState({ activeIndex: 0 })
                }
                else {
                    this.focusRequiredField(this.state.focusField);
                    this.setState({ focusField: "" });
                }
            }

        }
    }

    componentDidMount = () => {
        this.setState({ showPopUp: false });
    }

    loadUserRoleList = () => {
        this.setState({
            userRoleList: [],
            isUserRoleLoading: true,
        })
        ajaxPost({
            url: 'api/User/ListMinimal',
            data: {},
            onSuccess: data => {
                this.setState({ userRoleList: data });
            },
            finally: () => {
                this.setState({ isUserRoleLoading: false });
            }
        })
    }

    handleChange = (name, isNumericOnly, errorState, event) => {
        var numberEntry = '1234567890.';
        var newValue = event.target.value;

        if (isNumericOnly && event.target.value != null && !numberEntry.includes((newValue[newValue.length - 1]))) {
            newValue = newValue.slice(0, newValue.length - 1);
        }
        else if (isNumericOnly && event.target.value != null && (newValue.split(".").length - 1) > 1) {
            newValue = newValue.slice(0, newValue.length - 1);
        }

        if (isNumericOnly && newValue.startsWith("0"))
            newValue = newValue.slice(1, newValue.length)

        if (isNumericOnly && newValue === "")
            newValue = 0;

        var current = this.state.selectedEmployee;
        current[name] = newValue;
        this.setState({ selectedEmployee: current });

        if (errorState !== "" && newValue === "")
            this.setState({ [errorState]: true });
        else if (errorState !== "")
            this.setState({ [errorState]: false });
    }

    handleContactNumberChange = (name, event) => {
        var numberEntry = '+1234567890; ';
        var newValue = event.target.value;

        if (event.target.value != null && !numberEntry.includes((newValue[newValue.length - 1]))) {
            newValue = newValue.slice(0, newValue.length - 1);
        }
        else if (event.target.value != null && (newValue.split("+").length - 1) > 1) {
            newValue = newValue.slice(0, newValue.length - 1);
        }

        var current = this.state.selectedEmployee;
        current[name] = newValue;
        this.setState({ selectedEmployee: current });
    }

    handleDropDownChange = name => event => {
        var current = this.state.selectedEmployee;
        if (!(event === undefined || event === null)) {

            current[name] = event.value;
        } else {
            current[name] = ""
        }

        this.setState({ selectedEmployee: current });
    }

    handleDropDownUserInfoChange = name => event => {
        var current = this.state.ZKUserInfo;
        if (!(event === undefined || event === null)) {
            current[name] = event.value;
        } else {
            current[name] = ""
        }

        this.setState({ ZKUserInfo: current });
    }

    handleZKUserChange = name => event => {
        var current = this.state.ZKUserInfo;
        current[name] = event.target.value;
        this.setState({ ZKUserInfo: current });
    }

    onAccessNumberChange = name => event => {
        const allowedChar = "1234567890";
        var input = event.target.value;

        for (let index = 0; index < input.length; index++) {
            if (allowedChar.includes(input.charAt(index)) === false)
                input = replaceAt(input, index, '');
        }

        var current = this.state.ZKUserInfo;
        current[name] = input
        this.setState({ ZKUserInfo: current });

        if (event.target.value === undefined || event.target.value === '') {
            this.setState({ accessNumberIsError: true })
        }
        else {
            this.setState({ accessNumberIsError: false })
        }

        var currEmployee = this.state.selectedEmployee;
        currEmployee[name] = input
        this.setState({ selectedEmployee: currEmployee })
    }

    onEligibilityDetailChange = name => event => {
        var current = this.state.selectedEligibility;
        if (event == null) {
            current.Details[name] = "";
        } else {
            current.Details[name] = event.target.value;
        }

        this.setState({ selectedEligibility: current });
    }

    onDocChangedHandler = (event) => {
        var img = event.target.files[0];
        if (img === undefined)
            return;
        if (img.size > 3145728) {
            alert("File too large");
        }  else if(img.size === 0){
            alert("Image too small. File must be not 0MB");
            return;      
        }
        else
            this.docReadDataUrl(img, this.docOnload.bind(this));
    }

    docReadDataUrl(img, callback) {
        var reader = new FileReader();
        reader.onload = function () {
            var dataURL = reader.result;
            callback(dataURL, img.name);
        }
        reader.onerror = function (error) {
            console.log('Error: ', error);
        };
        reader.readAsDataURL(img);
    }

    docOnload(result, name) {
        var current = this.state.selectedEligibility;
        if (result === undefined) {
            current.Details["Source"] = "";
            current.Details["File"] = "";
        } else {
            current.Details["Source"] = result;
            current.Details["File"] = name;
        }

        this.setState({ selectedEligibility: current });
    }

    downloadDoc(filename, origName) {
        window.location = global.ApiHost + "api/Eligibility/download?" +
            "fileName=" + filename + "&" +
            "originalName=" + origName
    }

    onDropDownChange = name => event => {
        var current = this.state.selectedEligibility;
        if (event === undefined) {
            current.Details[name] = "";
        } else {
            current.Details[name] = event.value;
        }

        this.setState({ selectedEligibility: current });
    }

    eligibilityAdd = () => {
        if (this.state.eligibilityIndex === -1) {
            var newList = this.state.eligibilityList;
            newList.push(this.state.selectedEligibility);
            this.setState({ eligibilityList: newList });
        }
        this.setState({ modalOpen: false });
    }

    saveClick = () => {
        this.setState({ showPopUp: false }, () => {
            var newEmpList = this.state.employeeList;
            var hasMissingReqField = false;
            var content = "";
            var focusField = "";
            if (this.state.selectedEmployee.EmployeeNo === "") {
                this.setState({ "employeeNoIsError": true });
                content += "Employee Number"
                focusField = "EmployeeNo";
                hasMissingReqField = true;
            }

            if (this.state.selectedEmployee.AccessNumber === "" && isTaskAvailable("EMPLOYEE_DEVICE_EDIT")) {
                this.setState({ "accessNumberIsError": true });
                if (content !== "")
                    content += " , "

                if (focusField === "")
                    focusField = "AccessNumber";

                content += "Access Number"
                hasMissingReqField = true;
            }

            if (this.state.selectedEmployee.LastName === "") {
                this.setState({ "lastNameIsError": true });
                if (content !== "")
                    content += " , "

                if (focusField === "")
                    focusField = "LastName";
                content += "Last Name"
                hasMissingReqField = true;
            }

            if (this.state.selectedEmployee.FirstName === "") {
                this.setState({ "firstNameIsError": true });
                if (content !== "")
                    content += " , "

                if (focusField === "")
                    focusField = "FirstName";
                content += "First Name"
                hasMissingReqField = true;
            }

            if (hasMissingReqField) {
                this.setState({ focusField });
                this.setState({ showPopUp: true, popUpTitle: "Invalid", popUpContent: "Please fill up required fields (" + content + ")" })
                return;
            }
          

            this.setState({ isSaving: true }, () => {
                
                    this.saveAsAdmin(newEmpList);
                 
            });


        });
    }
  
    saveAsAdmin() {
        var employee = this.state.selectedEmployee;
        var zkUserInfo = this.state.ZKUserInfo;
        var focusField = "";
        delete employee["FullName"];

        var eligibilities = this.state.eligibilityList;
        var prevEligibilities = this.state.prevEligibilityList;
        var eligibilityToSave = [];
        eligibilities.forEach(currEligibility => {
            if (prevEligibilities.find(prevEligibility => prevEligibility._id === currEligibility._id) === null ||
                JSON.stringify(prevEligibilities.find(prevEligibility => prevEligibility._id === currEligibility._id)) !== JSON.stringify(currEligibility))
                eligibilityToSave.push(currEligibility);
        })

        var deletedIDs = this.state.eligibilityDeleteList.map(x => x._id);
        if (deletedIDs.length === 0) {
            deletedIDs = null;
        }

        ajaxPost({
            url: "api/Employee/save",
            data: {
                "employee": employee,
                "zkUserInfo": zkUserInfo,
                "eligibilities": eligibilities,
                "eligibilityDelete": deletedIDs,
                "positionAssignments": [],
                "positionAssignmentDelete": [],
                "server": global.ApiHost,
            },
            onSuccess: data => {
                employee = data.content;
            },
            finally: () => {
                var isError = false;
                let errMessage = "";

                if ("errEmpNo" in employee) {
                    this.setState({ employeeNoIsError: true });
                    errMessage += "Duplicate Employee Number"
                    isError = true;

                    focusField = "EmployeeNo";
                }
                if ("errAccessNo" in employee) {
                    this.setState({ accessNumberIsError: true });
                    if (errMessage !== "")
                        errMessage += " and ";
                    errMessage += "Duplicate Access Number";
                    isError = true;

                    focusField = "AccessNumber";
                }

                if (isError) {
                    this.setState({ focusField });
                    this.setState({ showPopUp: true, popUpTitle: "Invalid", popUpContent: errMessage });
                    return;
                }

                this.setState({ showPopUp: true, popUpTitle: "Employee", popUpContent: "Successfully Save" })
            }
        })
    }

    checkChanges() {
        var dictChanges = [];
        this.stateComparer(this.state.prevEmployee, this.state.selectedEmployee, dictChanges, "Employees", this.state.prevEmployee._id);
        this.stateComparer(this.state.prevZKUserInfo, this.state.ZKUserInfo, dictChanges, "ZKUserInfos", this.state.prevEmployee._id);
        this.stateComparer(this.state.prevEligibilityList, this.state.eligibilityList, dictChanges, "Eligibilities", this.state.prevEmployee._id);
        this.state.eligibilityDeleteList.forEach(item => {
            dictChanges.push({
                "TableName": "Eligibilities", "UpdateId": item["_id"], "EmployeeId": this.state.prevEmployee._id,
                "OldValue": item, "NewValue": null, "Action": "DELETE", "FieldName": item["Type"]
            })
        })
        dictChanges.forEach(item => {
            item["_id"] = empty_id;
            item["Status"] = "PENDING";
            item["RequestedBy"] = this.state.loginUser;
            //item["RequestedDate"] = moment().format('YYYY-MM-DD hh:mm:ss A');
            item["ApprovedBy"] = empty_id;
            item["ApprovedDate"] = null;
            item["Remarks"] = "";
            item["ApproverRemarks"] = "";
            item["Deleted"] = false;
            item["UpdateId"] = item["UpdateId"] === undefined || item["UpdateId"] === null ? empty_id : item["UpdateId"];
        });

        return dictChanges;
    }

    stateComparer(prevState, newState, changes, module, employeeId) {
        if (Array.isArray(newState) && newState.length > 0 && module != "ZKUserInfos") {
            newState.forEach(row => {
                var existence = prevState.find(z => z._id == row._id);
                if (existence === undefined) {
                    delete row["Employee"];
                    changes.push({
                        "TableName": module, "UpdateId": null, "EmployeeId": employeeId,
                        "OldValue": null, "NewValue": row, "Action": "NEW", "FieldName": row.Type
                    })
                } else if (existence !== row) {
                    var eligibilityChanges = [];
                    this.stateComparer(existence, row, eligibilityChanges, module, employeeId)
                    if (eligibilityChanges.filter((e) => e.FieldName !== "Employee").length > 0) {
                        delete existence["Employee"];
                        delete row["Employee"];
                        changes.push({
                            "TableName": module, "UpdateId": row._id, "EmployeeId": employeeId,
                            "OldValue": existence, "NewValue": row, "Action": "UPDATE", "FieldName": row.Type
                        })
                    }
                }
            });
        } else if (!(newState === undefined || newState === null)) {
            Object.keys(newState).forEach(item => {
                if (item === "Password" && module == "Employees") {
                    return;
                }
                else if (item === "FPCount" || item === "OtherDetails" || item === "Name") {
                    return;
                }
                else if (item === "Details") {
                    this.stateComparer(prevState[item], newState[item], changes, module, employeeId)
                }
                else if (item !== "file") {
                    if (Array.isArray(newState[item])) {
                        this.stateComparer(prevState[item], newState[item], changes, module);
                    }
                    else if (prevState[item] !== newState[item]) {
                        changes.push({
                            "TableName": module, "UpdateId": newState._id, "EmployeeId": employeeId,
                            "OldValue": prevState[item], "NewValue": newState[item], "Action": "UPDATE", "FieldName": item
                        })
                    }
                }
            });
        }
    }

    // stateComparer(prevState, newState, changes, module, employeeId){  
    //     if(Array.isArray(newState) && newState.length > 0){
    //         newState.forEach(row => {
    //             var existence = prevState.find(z=> z._id == row._id);
    //             if(existence == null){
    //                 changes.push({"TableName": module, "UpdateId": null, "EmployeeId": employeeId,
    //                 "OldValue": null, "NewValue": row, "Action": "NEW", "FieldName": row.Type})
    //             }else if(existence != row){
    //                 var eligibilityChanges = [];
    //                 this.stateComparer(existence, row, eligibilityChanges, module,employeeId)
    //                 if(eligibilityChanges.length > 0){
    //                     changes.push({"TableName": module, "UpdateId": row._id, "EmployeeId": employeeId,
    //                     "OldValue": existence, "NewValue": row, "Action": "UPDATE", "FieldName": row.Type})
    //                 }
    //             }
    //         });
    //     }else{
    //         Object.keys(newState).forEach(item => {
    //             if(item == "Password" && newState[item] != "" && module == "Employees"){
    //                 changes.push({"TableName": module, "UpdateId": newState._id, "EmployeeId": employeeId,
    //                     "OldValue": prevState[item], "NewValue": newState[item], "Action": "UPDATE", "FieldName": item})
    //             }
    //             else if (newState[item] == "" && module == "Employees"){
    //                 return;
    //             }
    //             else if(item == "Details"){
    //                 this.stateComparer(prevState[item], newState[item], changes, module,employeeId)
    //             }
    //             else if(item != "file"){
    //                 if(Array.isArray(newState[item])){
    //                     this.stateComparer(prevState[item], newState[item], changes, module);
    //                 }
    //                 else if(prevState[item] != newState[item]){
    //                     changes.push({"TableName": module, "UpdateId": newState._id, "EmployeeId": employeeId,
    //                     "OldValue": prevState[item], "NewValue": newState[item], "Action": "UPDATE", "FieldName": item})
    //                 }
    //             }
    //         });
    //     }
    // }

    onMouseEnter() {
        this.setState({ isImageHover: true });
    }

    onMouseLeave() {
        this.setState({ isImageHover: false });
    }

    fileChangedHandler = (event) => {
        var img = event.target.files[0];
        if (img === undefined)
            return;

        if (img.size > 1572864) {
            alert("Image too large")
            return;
        }
        if(img.size === 0){
            alert("Image too small. File must be not 0MB");
            return;
        }
        this.readDataUrl(img, this.onload.bind(this));
    }

    readDataUrl(img, callback) {
        var reader = new FileReader();
        reader.onload = function () {
            var dataURL = reader.result;
            callback(dataURL);
        }
        reader.onerror = function (error) {
            console.log('Error: ', error);
        };
        reader.readAsDataURL(img);
    }

    onload(result) {
        var current = this.state.selectedEmployee;
        current["file"] = result;
        current["Image"] = result;
        this.setState({ selectedEmployee: current });
    }
        onclearAvatar( ) {
        var current = this.state.selectedEmployee;
        current["file"] = undefined;
        current["Image"] = "default.jpg";
        this.setState({ selectedEmployee: current });
      }
    uploadHandler = () => {
        document.getElementById("myFile").click()
    }

    onCreateNewClick = (type) => {
        const BlankEligibilities = {
            _id: empty_id, Type: "", Details: []
        }

        var blankEligibility = BlankEligibilities;
        blankEligibility.Type = type;

        this.populateDropDown(type, blankEligibility);

        this.setState({ selectedEligibility: blankEligibility });
        this.setState({ eligibilityIndex: -1 })
        this.setState({ modalOpen: true });
    }

    onEditClick = (eligibility, index) => {
        var blankEligibilities = {
            Type: "", Details: []
        }
        this.populateDropDown(eligibility.Type, blankEligibilities);

        this.setState({ eligibilityDisplay: eligibility.Type });
        this.setState({ selectedEligibility: eligibility });
        this.setState({ eligibilityIndex: index });
        this.setState({ modalOpen: true });
    }

    onDeleteClick = (eligibility, index) => {
        var newList = this.state.eligibilityList;
        newList.splice(index, 1);
        var deleteList = this.state.eligibilityDeleteList;
        delete eligibility.Employee;

        if ("_id" in eligibility) {
            deleteList.push(eligibility);
        }

        this.setState({ eligibilityList: newList });
        this.setState({ eligibilityDeleteList: deleteList });
        this.setState({ showDeleteModal: false });
    }

    onHandleCheckChange = name => event => {
        var current = this.state.selectedEligibility;
        current.Details[name] = !this.state.selectedEligibility.Details[name];
        this.setState({ selectedEligibility: current });
    }

    populateDropDown(type, blankEligibility) {
        switch (type) {
            case "IDENTIFICATION":
                blankEligibility.Details = setInitialValue(JSON.parse(JSON.stringify(NewIdentification)));
                this.setState({
                    eligibilityOptions:
                        [{ value: "SSS_ID", label: "SSS ID" },
                        { value: "PHILHEALTH_ID", label: "Philhealth ID" },
                        { value: "PAGIBIG_ID", label: "Pagibig ID" },
                        { value: "TIN_ID", label: "TIN ID" },
                        { value: "GSIS_ID", label: "GSIS ID" },
                        { value: "PASSPORT_ID", label: "Passport" },
                        { value: "DRIVERS_LICENSE_ID", label: "Drivers License" }],
                });
                break;
            case "ADDRESS":
                blankEligibility.Details = setInitialValue(JSON.parse(JSON.stringify(NewAddress)));
                this.setState({
                    eligibilityOptions:
                        [{ label: "Current Address", value: "CURRENT_ADDRESS" },
                        { label: "Relocated Address", value: "RELOCATED_ADDRESS" },
                        { label: "Permanent Address", value: "PERMANENT_ADDRESS" },
                        // { label: "Mailing Address", value: "MAILING_ADDRESS" },
                        // { label: "Temporary Address", value: "TEMPORARY_ADDRESS" },
                        { label: "Birthplace", value: "BIRTH_PLACE" },],
                });
                break;
            case "FAMILY_BACKGROUND":
                blankEligibility.Details = setInitialValue(JSON.parse(JSON.stringify(NewFamilyBackground)));
                this.setState({
                    eligibilityOptions:
                        [{ label: "Spouse", value: "SPOUSE" },
                        { label: "Child", value: "CHILD" },
                        { label: "Mother", value: "MOTHER" },
                        { label: "Father", value: "FATHER" },
                        { label: "Sibling", value: "SIBLING" },
                        { label: "EmergencyContact", value: "EMERGENCY_CONTACT" },
                        { label: "FatherInLaw", value: "FATHER_IN_LAW" },
                        { label: "MotherInLaw", value: "MOTHER_IN_LAW" }],
                });
                break;
            case "EDUCATIONAL_BACKGROUND":
                blankEligibility.Details = setInitialValue(JSON.parse(JSON.stringify(NewEducationalBackground)));
                this.setState({
                    eligibilityOptions:
                        [{ label: "Primary", value: "PRIMARY" },
                        { label: "Secondary", value: "SECONDARY" },
                        { label: "Tertiary", value: "TERTIARY" },
                        { label: "Master", value: "MASTER" },
                        { label: "Doctorate", value: "DOCTORATE" },
                        { label: "Vocational", value: "VOACTIONAL" }],
                })
                break;
            case "OTHER_INFO":
                blankEligibility.Details = setInitialValue(JSON.parse(JSON.stringify(NewOtherInfo)));
                this.setState({
                    eligibilityOptions:
                        [{ label: "Skills", value: "SKILLS" },
                        { label: "Hobby", value: "HOBBY" },
                        { label: "Non-Academic Distinction", value: "NON_ACADEMIC_DISTINCTION" },
                        { label: "Organization", value: "ORGANIZATION" }
                        ],
                })
                break;
            case "WORK_EXPERIENCE":
                blankEligibility.Details = setInitialValue(JSON.parse(JSON.stringify(NewWorkExperience)));
                this.setState({ eligibilityOptions: [] })
                break;
            case "MEDICAL_RECORD":
                blankEligibility.Details = setInitialValue(JSON.parse(JSON.stringify(NewMedicalRecord)));
                this.setState({ eligibilityOptions: [] })
                break;
            case "TRAINING":
                blankEligibility.Details = setInitialValue(JSON.parse(JSON.stringify(NewTrainingBackground)));
                this.setState({ eligibilityOptions: [] })
                break;
            case "CHARACTER_REFERENCE":
                blankEligibility.Details = setInitialValue(JSON.parse(JSON.stringify(NewPersonalReference)));
                this.setState({ eligibilityOptions: [] })
                break;
            case "DOCUMENTS":
                blankEligibility.Details = setInitialValue(JSON.parse(JSON.stringify(NewDocument)));
                this.setState({
                    eligibilityOptions: [
                        { value: "CERTIFICATES", label: "Certificates" },
                        { value: "FORMS", label: "Forms" },
                        { value: "IDENTIFICATION_CARDS", label: "Identification Cards" },
                        { value: "CONFIDENTIALITY_AGREEMENT", label: "Confidentiality Agreements" },
                        { value: "FILES", label: "Files" },
                        { value: "NOTES", label: "Notes" },
                        { value: "GOVERNMENT_FILES", label: "Government Files" },
                        { value: "REPORTS", label: "Reports" },
                        { value: "MEMOS", label: "Memos" },
                        { value: "INCIDENT", label: "Incident" },
                        { value: "OTHERS", label: "Others" }
                    ],
                    eligibilityUsed: JSON.parse(JSON.stringify(NewDocument))
                })
                break;
            case "CERTIFICATES":
                blankEligibility.Details = setInitialValue(JSON.parse(JSON.stringify(NewCertificate)));
                this.setState({ eligibilityOptions: [] })
                break;
        }
    }

    convertToFieldName(text) {
        return text.replace(/_/g, ' '); // replace all underline with a space [ ] 
    }
/*
    imagePath() {
        if (this.state.selectedEmployee.file !== undefined) {
            return this.state.selectedEmployee.file;
        } else {
            return global.ApiHost + "api/Employee/avatarImageByFilename?filename=" + this.state.selectedEmployee.Image;
        }
    }
*/
    onTestAvatarPath() {
        var path = "";
        fnGetEmployeeImagePath(this.state.selectedEmployee._id,
            data => {
                path = data;
                alert(path);
            })
    }

    getOptions(index, key) {
        var itemIndex = 2;
        const arrOptions = {}
        if (isTaskAvailable("EMPLOYEE_IDENTIFICATION_EDIT")) {
            arrOptions[itemIndex] = { "eligibility": JSON.parse(JSON.stringify(NewIdentification)) }
            itemIndex++;
        }
        if (isTaskAvailable("EMPLOYEE_ADDRESS_EDIT")) {
            arrOptions[itemIndex] = { "eligibility": JSON.parse(JSON.stringify(NewAddress)) }
            itemIndex++;
        }
        if (isTaskAvailable("EMPLOYEE_FAMILY_BACKGROUND_EDIT")) {
            arrOptions[itemIndex] = { "eligibility": JSON.parse(JSON.stringify(NewFamilyBackground)) }
            itemIndex++;
        }
        if (isTaskAvailable("EMPLOYEE_EDUCATIONAL_BACKGROUND_EDIT")) {
            arrOptions[itemIndex] = { "eligibility": JSON.parse(JSON.stringify(NewEducationalBackground)) }
            itemIndex++;
        }
        if (isTaskAvailable("EMPLOYEE_WORK_EXPERIENCE_EDIT")) {
            arrOptions[itemIndex] = { "eligibility": JSON.parse(JSON.stringify(NewWorkExperience)) }
            itemIndex++;
        }
        if (isTaskAvailable("EMPLOYEE_DOCUMENTS_EDIT")) {
            arrOptions[itemIndex] = { "eligibility": JSON.parse(JSON.stringify(NewDocument)) }
            itemIndex++;
        }
        if (isTaskAvailable("EMPLOYEE_TRAINING_EDIT")) {
            arrOptions[itemIndex] = { "eligibility": JSON.parse(JSON.stringify(NewTrainingBackground)) }
            itemIndex++;
        }
        if (isTaskAvailable("EMPLOYEE_OTHER_INFO_EDIT")) {
            arrOptions[itemIndex] = { "eligibility": JSON.parse(JSON.stringify(NewOtherInfo)) }
            itemIndex++;
        }
        if (isTaskAvailable("EMPLOYEE_CHARACTER_REFERENCE_EDIT")) {
            arrOptions[itemIndex] = { "eligibility": JSON.parse(JSON.stringify(NewPersonalReference)) }
            itemIndex++;
        }
        const options = arrOptions[index]
        if (options === undefined && key === "options")
            return [];

        if (options === undefined && key === "eligibility")
            return [];

        return options[key];
    }

    handleTabChange = (e, { activeIndex }) => this.setState({ activeIndex, eligibilityUsed: this.getOptions(activeIndex, "eligibility") })

    render() {
        if( Object.entries(this.state.selectedEmployee).length === 0){
            return <></>;
        }
        var zkInfo = this.state.ZKUserInfo
        const { LastName, FirstName, MiddleName, Suffix, AccessNumber, Email, BirthDate } = this.state.selectedEmployee;
        const { CivilStatus, Height, Weight, Nationality, BloodType, Nickname, Religion, Gender, TelephoneNo, MobileNo } = this.state.selectedEmployee;
        const { eligibilityUsed } = this.state;
        var RFID, Privilege, FPCount, FaceCount,PalmCount , Password;
        if (zkInfo !== null && zkInfo !== undefined) {
            RFID = zkInfo.RFID;
            Privilege = zkInfo.Privilege;
            FPCount = zkInfo.FPCount;
            FaceCount = zkInfo.FaceCount;
            PalmCount = zkInfo.PalmCount;
            Password = zkInfo.Password;
        } else {
            zkInfo = {};
        }

        const { eligibilityType, eligibilityList, selectedEligibility, eligibilityOptions } = this.state;

        var panel = [];
        Object.keys(eligibilityType).map(elgbtyType => {
            return (isTaskAvailable("EMPLOYEE_" + elgbtyType + "_EDIT") && panel.push({
                menuItem: eligibilityType[elgbtyType], render: () => <Tab.Pane style={{ border: 'none' }}>
                    <div style={{ width: '100%' }}>
                        <label style={{ fontWeight: 'bold', fontSize: '1.3em', color: "#606060" }}>{eligibilityType[elgbtyType]}</label>
                        <hr style={{ color: "#606060", 'border-width': '0.8px' }} />
                        <div style={{ display: 'inline' }}>
                            <Button floated='left' size='mini' onClick={this.onCreateNewClick.bind(this, elgbtyType)}
                                style={{ 'max-height': '31px', 'background-color': 'transparent' }}> <Icon name='plus' />Add</Button>
                            <Pagination
                                floated='right'
                                defaultActivePage={1}
                                activePage={this.state["activePage_" + elgbtyType]}
                                pointing
                                secondary
                                firstItem={{ content: <Icon name='angle double left' />, icon: true }}
                                lastItem={{ content: <Icon name='angle double right' />, icon: true }}
                                prevItem={{ content: <Icon name='angle left' />, icon: true }}
                                nextItem={{ content: <Icon name='angle right' />, icon: true }}
                                size='mini'
                                siblingRange={2}
                                boundaryRange={0}
                                totalPages={Math.ceil(eligibilityList.filter((e) => e.Type === elgbtyType).length / 10)}
                                onPageChange={(e, { activePage }) => this.setState({ ["activePage_" + elgbtyType]: activePage })}
                            />
                        </div>
                        <br />
                        <br />
                        <Form.Group className='w3-hide-medium w3-hide-small'>
                            <Table stackable selectable striped size='small'>
                                <Table.Header>
                                    <Table.Row style={{ 'backgroundColor': 'gainsboro', 'fontSize': '11px', padding: '0px' }}>
                                        {eligibilityList != null && eligibilityList.length > 0 && eligibilityList.find((e) => e.Type === elgbtyType) != null &&
                                            Object.keys(eligibilityList.find((e) => e.Type === elgbtyType).Details).filter(x => x != "Source" && x != "UniqueFilename").map(det => {
                                                return (
                                                    <Table.HeaderCell>
                                                        {det}
                                                    </Table.HeaderCell>
                                                )
                                            })}
                                        <Table.HeaderCell>Actions</Table.HeaderCell>
                                    </Table.Row>
                                </Table.Header>
                                <Table.Body>
                                    {eligibilityList != null && eligibilityList.length > 0 && eligibilityList.find((e) => e.Type === elgbtyType) != null &&
                                        eligibilityList.filter((e) => e.Type === elgbtyType).slice((this.state["activePage_" + elgbtyType] * 10) - 10, (this.state["activePage_" + elgbtyType] * 10) - 1)
                                            .map(det => {
                                                return (
                                                    <Table.Row>
                                                        {Object.keys(det.Details).filter(x => x != "Source" && x != "UniqueFilename").map(otherDet => {
                                                            return (
                                                                <Table.Cell>
                                                                    {eligibilityValueAnalyzer(otherDet, det.Details)}
                                                                </Table.Cell>
                                                            )
                                                        })}
                                                        <Table.Cell>
                                                            <div>
                                                                <Popup trigger={
                                                                    <Button onClick={this.onEditClick.bind(this, det, eligibilityList.indexOf(det))}
                                                                        icon='edit' circular></Button>}
                                                                    content='Edit'
                                                                    inverted
                                                                />
                                                                <Popup trigger={
                                                                    <Button
                                                                        // onClick={this.onDeleteClick.bind(this, det, eligibilityList.indexOf(det))}
                                                                        onClick={() => this.setState({ selectedEligibility: det, eligibilityIndex: eligibilityList.indexOf(det), showDeleteModal: true })}
                                                                        icon='delete' circular></Button>}
                                                                    content='Delete'
                                                                    inverted
                                                                />
                                                                {det.Type === "DOCUMENTS" && <Popup trigger={
                                                                    <Button onClick={this.downloadDoc.bind(this, det.Details.UniqueFilename, det.Details.File)}
                                                                        icon='download' circular></Button>}
                                                                    content='Download'
                                                                    inverted
                                                                />}
                                                            </div>
                                                        </Table.Cell>
                                                    </Table.Row>
                                                )
                                            })}
                                </Table.Body>
                            </Table>
                        </Form.Group>
                        <div className='w3-hide-large' style={{ width: '100%' }}>
                            {eligibilityList != null && eligibilityList.length > 0 && eligibilityList.find((e) => e.Type === elgbtyType) != null &&
                                eligibilityList.filter((e) => e.Type === elgbtyType).slice((this.state["activePage_" + elgbtyType] * 10) - 10, (this.state["activePage_" + elgbtyType] * 10) - 1)
                                    .map(det => {
                                        return (
                                            <Card fluid>
                                                <Card.Content>
                                                    <Card.Description>
                                                        <div>
                                                            {Object.keys(det.Details).filter(x => x != "Source" && x != "UniqueFilename")
                                                                .map(otherDet => {
                                                                    return (
                                                                        <div>
                                                                            <b>{otherDet}</b>: <span />
                                                                            {eligibilityValueAnalyzer(otherDet, det.Details)}
                                                                        </div>
                                                                    )
                                                                })}

                                                            <div className='w3-center' style={{ content: 'inline' }}>
                                                                <Button onClick={this.onEditClick.bind(this, det, eligibilityList.indexOf(det))}
                                                                    icon='edit'
                                                                    content='Edit'
                                                                    basic>
                                                                </Button>
                                                                <Button
                                                                    // onClick={this.onDeleteClick.bind(this, det, eligibilityList.indexOf(det))}
                                                                    onClick={() => this.setState({ selectedEligibility: det, eligibilityIndex: eligibilityList.indexOf(det), showDeleteModal: true })}
                                                                    icon='delete'
                                                                    content='Delete'
                                                                    basic
                                                                />
                                                                {det.Type === "DOCUMENTS" &&
                                                                    <Button onClick={this.downloadDoc.bind(this, det.Details.UniqueFilename, det.Details.File)}
                                                                        icon='download'
                                                                        content='Download'
                                                                        basic
                                                                    />}
                                                            </div>
                                                        </div>
                                                    </Card.Description>
                                                </Card.Content>
                                            </Card>
                                        )
                                    })
                            }
                        </div>
                    </div>
                </Tab.Pane>
            }))
        })

        var panes = [
            {
                menuItem: 'Details', render: () => <Tab.Pane style={{ border: 'none' }}>
                    <label style={{ fontWeight: 'bold', fontSize: '1.3em', color: "#606060" }}>Personal Details</label>{/*<Button onClick={this.onTestAvatarPath.bind(this)}>Test</Button>*/}
                    <hr style={{ color: "#606060", 'border-width': '0.8px' }} />
                    <p />
                    <div className="w3-row" style={{ width: '100%' }}>
                        <div className="w3-col m12 l6">
                            <div className="w3-row" style={{ paddingTop: '10px' }}>
                                <div className="w3-col s12 m5 l5" style={{ paddingLeft: '10px' }}>
                                    <label> Last Name *</label>
                                </div>
                                <div className="w3-col s12 m7 l7">
                                    <Input placeholder='Last Name' value={LastName} tabIndex={1} error={this.state.lastNameIsError}
                                        fluid onChange={this.handleChange.bind(this, "LastName", false, "lastNameIsError")} ref={(input) => this.LastName = input} />
                                </div>
                            </div>

                            <div className="w3-row" style={{ paddingTop: '10px' }}>
                                <div className="w3-col s12 m5 l5" style={{ paddingLeft: '10px' }}>
                                    <label> First Name *</label>
                                </div>
                                <div className="w3-col s12 m7 l7">
                                    <Input placeholder='First Name' value={FirstName} tabIndex={2} error={this.state.firstNameIsError}
                                        fluid onChange={this.handleChange.bind(this, "FirstName", false, "firstNameIsError")} ref={(input) => this.FirstName = input} />
                                </div>
                            </div>

                            <div className="w3-row" style={{ paddingTop: '10px' }}>
                                <div className="w3-col s12 m5 l5" style={{ paddingLeft: '10px' }}>
                                    <label> Middle Name</label>
                                </div>
                                <div className="w3-col s12 m7 l7">
                                    <Input placeholder='Middle Name' value={MiddleName} tabIndex={3}
                                        fluid onChange={this.handleChange.bind(this, "MiddleName", false, "")} />
                                </div>
                            </div>

                            <div className="w3-row" style={{ paddingTop: '10px' }}>
                                <div className="w3-col s12 m5 l5" style={{ paddingLeft: '10px' }}>
                                    <label> Suffix</label>
                                </div>
                                <div className="w3-col s12 m7 l7">
                                    <Input placeholder='Suffix' value={Suffix} tabIndex={4}
                                        fluid onChange={this.handleChange.bind(this, "Suffix", false, "")} />
                                </div>
                            </div>

                            <div className="w3-row" style={{ paddingTop: '10px' }}>
                                <div className="w3-col s12 m5 l5" style={{ paddingLeft: '10px' }}>
                                    <label> Nickname</label>
                                </div>
                                <div className="w3-col s12 m7 l7">
                                    <Input placeholder='Nickname' value={Nickname} tabIndex={4}
                                        fluid onChange={this.handleChange.bind(this, "Nickname", false, "")} />
                                </div>
                            </div>

                            <div className="w3-row" style={{ paddingTop: '10px' }}>
                                <div className="w3-col s12 m5 l5" style={{ paddingLeft: '10px' }}>
                                    <label>Gender</label>
                                </div>
                                <div className="w3-col s12 m7 l7">
                                    <Select
                                        styles={customStyles}
                                        //style={{ minWidth: '50px', maxWidth: '200px', width: '100%' }}
                                        placeholder="Gender"
                                        value={GenderType.find(x => x.value == Gender)}
                                        options={GenderType}
                                        onChange={this.handleDropDownChange("Gender").bind(this)}
                                        tabIndex={5}
                                    />
                                </div>
                            </div>

                            <div className="w3-row" style={{ paddingTop: '10px' }}>
                                <div className="w3-col s12 m5 l5" style={{ paddingLeft: '10px' }}>
                                    <label>Civil Status</label>
                                </div>
                                <div className="w3-col s12 m7 l7">
                                    <Select size='mini'
                                        styles={customStyles}
                                        placeholder="Civil Status"
                                        value={CivilStatusType.find(x => x.value == CivilStatus)}
                                        options={CivilStatusType}
                                        onChange={this.handleDropDownChange("CivilStatus").bind(this)}
                                        tabIndex={6}
                                    />
                                </div>
                            </div>

                            <div className="w3-row" style={{ paddingTop: '10px' }}>
                                <div className="w3-col s12 m5 l5" style={{ paddingLeft: '10px' }}>
                                    <label> Height (cm) </label>
                                </div>
                                <div className="w3-col s12 m7 l7">
                                    <Input placeholder='Height' value={Height} tabIndex={7} fluid
                                        onChange={this.handleChange.bind(this, "Height", true, "")}
                                        onKeyPress={(e) => { handleNumericOnKeyPress(e) }}
                                        onKeyDown={(e) => { handleNumericOnKeyDown(e) }}
                                    />
                                </div>
                            </div>

                            <div className="w3-row" style={{ paddingTop: '10px' }}>
                                <div className="w3-col s12 m5 l5" style={{ paddingLeft: '10px' }}>
                                    <label> Weight (kg)</label>
                                </div>
                                <div className="w3-col s12 m7 l7">
                                    <Input placeholder='Weight' value={Weight} tabIndex={8} fluid
                                        onChange={this.handleChange.bind(this, "Weight", true, "")}
                                        onKeyPress={(e) => { handleNumericOnKeyPress(e) }}
                                        onKeyDown={(e) => { handleNumericOnKeyDown(e) }}
                                    />
                                </div>
                            </div>
                        </div>

                        <div className="w3-col m12 l6">
                            <div className="w3-row" style={{ paddingTop: '10px' }}>
                                <div className="w3-col s12 m5 l5" style={{ paddingLeft: '10px' }}>
                                    <label> Email</label>
                                </div>
                                <div className="w3-col s12 m7 l7">
                                    <Input fluid placeholder='Email' value={Email} error={this.state.emailIsError}
                                        onChange={this.handleChange.bind(this, "Email", false, "emailIsError")} tabIndex={9} ref={(input) => this.Email = input} />
                                </div>
                            </div>

                            <div className="w3-row" style={{ paddingTop: '10px' }}>
                                <div className="w3-col s12 m5 l5" style={{ paddingLeft: '10px' }}>
                                    <label> Telephone No.</label>
                                </div>
                                <div className="w3-col s12 m7 l7">
                                    <Input fluid placeholder='Telephone No.' value={TelephoneNo}
                                        onChange={this.handleContactNumberChange.bind(this, "TelephoneNo")} tabIndex={10}
                                        onKeyPress={(e) => { handleNumericOnKeyPress(e) }}
                                        onKeyDown={(e) => { handleNumericOnKeyDown(e) }}
                                    />
                                </div>
                            </div>

                            <div className="w3-row" style={{ paddingTop: '10px' }}>
                                <div className="w3-col s12 m5 l5" style={{ paddingLeft: '10px' }}>
                                    <label> Mobile No.</label>
                                </div>
                                <div className="w3-col s12 m7 l7">
                                    <Input fluid placeholder='Mobile No.' value={MobileNo}
                                        onChange={this.handleContactNumberChange.bind(this, "MobileNo")} tabIndex={11}
                                        onKeyPress={(e) => { handleNumericOnKeyPress(e) }}
                                        onKeyDown={(e) => { handleNumericOnKeyDown(e) }}
                                    />
                                </div>
                            </div>

                            <div className="w3-row" style={{ paddingTop: '10px' }}>
                                <div className="w3-col s12 m5 l5" style={{ paddingLeft: '10px' }}>
                                    <label> Religion</label>
                                </div>
                                <div className="w3-col s12 m7 l7">
                                    <Input fluid placeholder='Religion' value={Religion} tabIndex={12}
                                        onChange={this.handleChange.bind(this, "Religion", false, "")} />
                                </div>
                            </div>

                            <div className="w3-row" style={{ paddingTop: '10px' }}>
                                <div className="w3-col s12 m5 l5" style={{ paddingLeft: '10px' }}>
                                    <label> Nationality</label>
                                </div>
                                <div className="w3-col s12 m7 l7">
                                    <Input fluid placeholder='Nationality' value={Nationality} tabIndex={13}
                                        onChange={this.handleChange.bind(this, "Nationality", false, "")} />
                                </div>
                            </div>

                            <div className="w3-row" style={{ paddingTop: '10px' }}>
                                <div className="w3-col s12 m5 l5" style={{ paddingLeft: '10px' }}>
                                    <label> Blood Type</label>
                                </div>
                                <div className="w3-col s12 m7 l7">
                                    <Input fluid placeholder='Blood Type' value={BloodType} tabIndex={14}
                                        onChange={this.handleChange.bind(this, "BloodType", false, "")}
                                        onKeyPress={(e) => { handleBloodTypeOnKeyPress(e) }}
                                        onKeyDown={(e) => { handleNumericOnKeyDown(e) }}
                                    />
                                </div>
                            </div>

                            <div className="w3-row" style={{ paddingTop: '10px' }}>
                                <div className="w3-col s12 m5 l5" style={{ paddingLeft: '10px' }}>
                                    <label> Birth Date</label>
                                </div>
                                <div className="w3-col s12 m7 l7">
                                    <Input
                                        fluid
                                        size='small'
                                        value={BirthDate}
                                        min="1900-01-01"
                                        max="2999-12-31"
                                        type="date"
                                        onChange={this.handleChange.bind(this, "BirthDate", false, "")}
                                        tabIndex={15}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>

                </Tab.Pane>
            },
            isTaskAvailable("EMPLOYEE_DEVICE_EDIT") && {
                menuItem: 'Device Info', render: () => <Tab.Pane style={{ border: 'none' }}>
                    <label style={{ fontWeight: 'bold', fontSize: '1.3em', color: "#606060" }}>Device Information</label>
                    <hr style={{ color: "#606060", 'border-width': '0.8px' }} />
                    <div className="w3-row" style={{ width: '100%' }}>
                        <div className="w3-col m12 l6">
                            <div className="w3-row" style={{ paddingTop: '10px' }}>
                                <div className="w3-col s12 m5 l5" style={{ paddingLeft: '10px' }}>
                                    <label> Access Number *</label>
                                </div>
                                <div className="w3-col s12 m7 l7">
                                    <Input placeholder='Access Number' fluid value={AccessNumber} error={this.state.accessNumberIsError}
                                        onChange={this.onAccessNumberChange("AccessNumber").bind(this)} ref={(input) => this.AccessNumber = input}
                                        onKeyPress={(e) => { handleNumericOnKeyPress(e) }}
                                        onKeyDown={(e) => { handleNumericOnKeyDown(e) }}
                                    />
                                </div>
                            </div>

                            <div className="w3-row" style={{ paddingTop: '10px' }}>
                                <div className="w3-col s12 m5 l5" style={{ paddingLeft: '10px' }}>
                                    <label> Pin </label>
                                </div>
                                <div className="w3-col s12 m7 l7">
                                    <Input icon={<Icon name='eye'
                                        onMouseUp={() => {
                                            this.setState({ ShowZkPassword: false })
                                        }}
                                        onMouseDown={() => {
                                            this.setState({ ShowZkPassword: true })
                                        }}
                                        link
                                    />}
                                        placeholder='Pin' type={this.state.ShowZkPassword ? "text" : "password"} fluid
                                        value={Password} onChange={this.handleZKUserChange("Password").bind(this)}
                                        onKeyPress={(e) => { handleNumericOnKeyPress(e) }}
                                        onKeyDown={(e) => { handleNumericOnKeyDown(e) }}
                                    />
                                </div>
                            </div>

                            <div className="w3-row" style={{ paddingTop: '10px' }}>
                                <div className="w3-col s12 m5 l5" style={{ paddingLeft: '10px' }}>
                                    <label> Card Number </label>
                                </div>
                                <div className="w3-col s12 m7 l7">
                                    <Input placeholder='Card Number' fluid
                                        value={RFID === "-1" ? "" : RFID} onChange={this.handleZKUserChange("RFID").bind(this)} />
                                </div>
                            </div>

                            <div className="w3-row" style={{ paddingTop: '10px' }}>
                                <div className="w3-col s12 m5 l5" style={{ paddingLeft: '10px' }}>
                                    <label> User Type </label>
                                </div>
                                <div className="w3-col s12 m7 l7">
                                    <Select
                                        styles={customStyles}
                                        placeholder="User Type"
                                        value={DeviceUserType.find(x => x.value == Privilege)}
                                        options={DeviceUserType}
                                        onChange={this.handleDropDownUserInfoChange("Privilege").bind(this)}
                                    />
                                </div>
                            </div>

                            <div className="w3-row" style={{ paddingTop: '10px' }}>
                                <div className="w3-col s12 m5 l5" style={{ paddingLeft: '10px' }}>
                                    <label> Finger User Count </label>
                                </div>
                                <div className="w3-col s12 m7 l7">
                                    <label> {FPCount} </label>
                                </div>
                            </div>

                            <div className="w3-row" style={{ paddingTop: '10px' }}>
                                <div className="w3-col s12 m5 l5" style={{ paddingLeft: '10px' }}>
                                    <label> Face User Count </label>
                                </div>
                                <div className="w3-col s12 m7 l7">
                                    <label> {FaceCount} </label>
                                </div>
                            </div>
                            <div className="w3-row" style={{ paddingTop: '10px' }}>
                                <div className="w3-col s12 m5 l5" style={{ paddingLeft: '10px' }}>
                                    <label> Palm User Count </label>
                                </div>
                                <div className="w3-col s12 m7 l7">
                                    <label> {PalmCount} </label>
                                </div>
                            </div>
                        </div>
                    </div>
                </Tab.Pane>
            }
        ]

        return (
            <div>
                <div style={{ color: "#606060", marginTop: '1%' }}>
                    <div style={{ width: '100%', display: 'inline-block' }}>
                        <div style={{ verticalAlign: 'middle', height: '75px', margin: '15px 0px', padding: 'auto', float: 'right' }}>
                            <Button size='mini' onClick={this.saveClick.bind(this)} loading={this.state.isSaving} disabled={!this.state.hasChanges}><Icon name='save'></Icon>Save</Button>
                            {/* <Button size='mini' onClick={this.seeAvatarImage.bind(this)}><Icon name='cancel' />Image</Button> */}
                        </div>
                        <div>
                            {/*
                            <input type="file" id='myFile' onChange={this.fileChangedHandler} style={{ display: 'none' }} />
                            {this.state.isImageHover === false &&
                                <Image src={this.imagePath()} size='small' style={{ width: '75px', height: '75px' }}
                                    onMouseEnter={this.onMouseEnter.bind(this)} onMouseOut={this.onMouseLeave.bind(this)} avatar />
                            }
                            {this.state.isImageHover &&
                                <Image src='/Images/addImage.png' size='small' style={{ width: '75px', height: '75px', cursor: 'pointer' }}
                                    onMouseEnter={this.onMouseEnter.bind(this)} onMouseOut={this.onMouseLeave.bind(this)} onClick={this.uploadHandler} avatar />
                            }
                            */}
                            <input
                                type="file"
                                id="myFile"
                                onChange={this.fileChangedHandler}
                                style={{ display: "none" }}
                            />
                            <div style={{float: "left"}}>
                                <EmployeeImage
                                    empId={this.state.selectedEmployee._id}
                                    fileName={this.state.selectedEmployee.Image}
                                    runtime={this.state.selectedEmployee.file}
                                    style={{ width: "75px", height: "75px", cursor: "pointer" }}
                                    onClick={this.uploadHandler}
                                    onMouseEnter={this.onMouseEnter.bind(this)}
                                    onMouseOut={this.onMouseLeave.bind(this)}
                                    onDelete={this.onclearAvatar.bind(this)}
                                    avatar
                                />

                                {window.innerWidth > 768 && <span style={{ fontSize: '1.6em', fontWeight: ' bold' }}>
                                    {this.state.selectedEmployee._id === undefined ? "New Employee" : this.state.selectedEmployee.LastName + ", " + this.state.selectedEmployee.FirstName + " " + this.state.selectedEmployee.MiddleName + " (" + this.state.selectedEmployee.EmployeeNo + ")"}
                                </span>}
                            </div>
                        </div>
                    </div>
                </div>
                <div style={{ marginTop: '10px', 'width': '100%' }}>
                    <Tab panes={panes.concat(panel)} menu={{ fluid: true, vertical: true, tabular: true }} activeIndex={this.state.activeIndex} onTabChange={this.handleTabChange} />
                </div>
                <Modal size='mini' open={this.state.modalOpen} onClose={this.close} style={{ width: '340px' }}>
                    <Modal.Header>{selectedEligibility != null && selectedEligibility.Type.replace('_', ' ')}</Modal.Header>
                    <Modal.Content>
                        {selectedEligibility != null && this.state.modalOpen &&
                            Object.keys(selectedEligibility.Details).map(det => {
                                return (
                                    <div>
                                        {det.includes("Sponsored") &&
                                            <Checkbox style={{ marginTop: '5px' }} checked={this.state.selectedEligibility.Details[det]} onChange={this.onHandleCheckChange(det).bind(this)} label='Sponsored' toggle />
                                        }
                                        {det.includes("File") && !det.includes("UniqueFilename") &&
                                            <Input style={{ marginTop: '5px' }} labelPosition='left' type='text' placeholder={this.convertToFieldName(det)} error={eligibilityUsed[det] !== undefined && eligibilityUsed[det].isRequired && this.state["isError" + selectedEligibility.Type + det]}>
                                                <Label style={{ width: '130px' }}>{this.convertToFieldName(det)} {this.state.eligibilityUsed[det].isRequired && "*"}</Label>
                                                <label style={{ padding: '15px', 'word-wrap': 'break-word', 'word-break': 'break-all', overflow: 'hidden' }} for='files'><a>{selectedEligibility.Details[det] === "" ? "Choose file" : selectedEligibility.Details[det]}</a></label>
                                                <input id='files' style={{ width: '170px', display: 'none' }} size='small' name={selectedEligibility.Details[det]}
                                                    type="file" onChange={this.onDocChangedHandler.bind(this)} />
                                            </Input>
                                        }
                                        {det.includes("Date") &&
                                            <Input style={{ marginTop: '5px', display: det.includes("Date_Created") && "none" }} labelPosition='left' type='text' placeholder={this.convertToFieldName(det)} error={eligibilityUsed[det] !== undefined && eligibilityUsed[det].isRequired && this.state["isError" + selectedEligibility.Type + det]}>
                                                <Label style={{ width: '130px' }}>{this.convertToFieldName(det)} {this.state.eligibilityUsed[det].isRequired && "*"}</Label>
                                                <input style={{ width: '170px' }} size='small' value={selectedEligibility.Details[det]}
                                                    type="date" min="1900-01-01" max="2999-12-31" onChange={this.onEligibilityDetailChange(det).bind(this)} />
                                            </Input>
                                        }
                                        {(det.includes("Type") || det.includes("Relation")) &&
                                            <Input style={{ marginTop: '5px', display: 'flex' }} labelPosition='left' type='text' placeholder={this.convertToFieldName(det)}>
                                                <Label style={{ width: '130px' }}>{this.convertToFieldName(det)} {this.state.eligibilityUsed[det].isRequired && "*"}</Label>
                                                <div style={{ width: '170px' }}>
                                                    <Select styles={eligibilityUsed[det] !== undefined && eligibilityUsed[det].isRequired && this.state["isError" + selectedEligibility.Type + det] ? errorStyles : customStyles} placeholder='select type' value={eligibilityOptions.find(x => x.value == selectedEligibility.Details[det])}
                                                        options={eligibilityOptions} onChange={this.onDropDownChange(det).bind(this)} />
                                                </div>
                                            </Input>
                                        }
                                        {!(det.includes("Type") || det.includes("Date") || det.includes("File") || det.includes("Sponsored") || det.includes("Relation")) &&
                                            <Input style={{ marginTop: '5px', display: det.includes("Source") && "none" }} labelPosition='left' type='text' placeholder={this.convertToFieldName(det)} error={eligibilityUsed[det] !== undefined && eligibilityUsed[det].isRequired && this.state["isError" + selectedEligibility.Type + det]}>
                                                <Label style={{ width: '130px' }}>{this.convertToFieldName(det)} {this.state.eligibilityUsed[det].isRequired && "*"}</Label>
                                                <input style={{ width: '170px' }} size='small' value={selectedEligibility.Details[det]}
                                                    onChange={this.onEligibilityDetailChange(det).bind(this)} />
                                            </Input>
                                        }
                                    </div>
                                )
                            })

                        }
                    </Modal.Content>
                    <Modal.Actions>
                        <Button onClick={this.eligibilityAdd.bind(this)} icon='save' content='Save' />
                        <Button onClick={() => this.setState({ modalOpen: false })} icon='cancel' content='Cancel' />
                    </Modal.Actions>
                </Modal>
                {this.state.showPopUp &&
                    <MessageBoxOkOnly title={this.state.popUpTitle} caption={this.state.popUpContent} onClick={() => this.setState({ showPopUp: false, isSaving: false })} />
                }
                {this.state.showDeleteModal &&
                    <MessageBoxYesNo title="Delete"
                        caption="Are you sure you want to delete this item?"
                        action="Yes"
                        onAction={this.onDeleteClick.bind(this, this.state.selectedEligibility, this.state.eligibilityIndex)}
                        onClose={() => this.setState({ showDeleteModal: false })}
                    />
                }
            </div>
        )
    }
}

EmployeeUserView.getViewID = () => { return "EmployeeUserView" };
EmployeeUserView.getCaption = () => { return "Employees" }
EmployeeUserView.getViewAccessKey = () => {
    return isTaskAvailable("EMPLOYEE_USERVIEW") && !(isTaskAvailable("EMPLOYEE_VIEW") || isTaskAvailable("EMPLOYEE_ADD"))
}
EmployeeUserView.getIcon = () => {
    return (<Image style={{ "height": "24px", "width": "24px" }} src='Icons/employees.png' avatar />);
}
EmployeeUserView.getIconSrc = (size) => {
    return 'Icons/employees.png';
}

export default EmployeeUserView;
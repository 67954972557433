import React from 'react';
import 'semantic-ui-css/semantic.min.css';
import ReportModelBase from './ReportModelBase';
import { Table } from '../../../node_modules/semantic-ui-react';
// const moment = require('moment');

class AbsenceSummaryReportModel extends ReportModelBase {

    constructor(){
        super();
        this.state = {
            isFilterActive: false
        }
        this.searchUrl = "api/Reports/createSummaryReport";
        this.title = "Absences Summary Report";
        this.exportToExcelDataUrl = "api/Reports/createExportSummaryReportToExcel";        
        this.exportToExcelUrl = "api/Reports/ExportSummaryReportToExcel";
        this.exportToPdfUrl = "api/Reports/createExportSummaryReportToPDF";
    }

    toTableRow = (rowData) => {
        return (
            <Table.Row>
                <Table.Cell>{rowData.EmployeeNo}</Table.Cell>
                <Table.Cell>{rowData.Employee}</Table.Cell>
                <Table.Cell>{rowData.Absences}</Table.Cell>
                <Table.Cell>{rowData.Leaves}</Table.Cell>
                <Table.Cell>{rowData.WholeDayLeaves}</Table.Cell>

            </Table.Row>
        );
    }

    createColumnHeaders = () => {
        return (
            <Table.Row>
                <Table.HeaderCell>Employee No</Table.HeaderCell>
                <Table.HeaderCell>Employee</Table.HeaderCell>
                <Table.HeaderCell>Absences</Table.HeaderCell>
                <Table.HeaderCell>Leaves</Table.HeaderCell>
                <Table.HeaderCell>Whole Day Leaves</Table.HeaderCell>
            </Table.Row>
        );
    }
}

export default AbsenceSummaryReportModel;
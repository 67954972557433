import React from 'react';
import ReactDOM from 'react-dom';
import MasterPage3 from './Dashboard/MasterPage3';
import Login from "./login";
import ChangePassword from "./changePassword";
import ExternalNotification from './Dashboard/ExternalNotification'
import ForgotPassword from './forgotPassword';
import Maintenance from './Commons/Maintenance';
import ErrorPage from './Dashboard/ErrorPage';
import { Loader } from 'semantic-ui-react';

import { ajaxGet } from './ajax';

const url = require('url');
const querystring = require('querystring');

function Logout() {
    ajaxGet(
        {
            url: 'api/user/logout?sessionKey=HRIS_SessionId',
            credentials: 'include',
            data: "",
            onError: () => { },
            onSuccess: data => { },
            finally: () => { window.location = "Login" },
        });
}


export default function Navigate() 
{
    const uri = url.parse(window.location.href);
    const pathName = uri.pathname.toLowerCase();
    const qs = querystring.parse(uri.query);
        
    switch (pathName) {
        case '/maintenance':
            ReactDOM.render(<Maintenance />, document.getElementById('root'));
            break;
        case '/logout':
            Logout();
            break;
        case '/login':
            ReactDOM.render(<Login />, document.getElementById('root'));
            break;
        case '/forgotpassword':
            ReactDOM.render(<ForgotPassword />, document.getElementById('root'));
            break;
        case '/changepassword':
            ajaxGet(
                {
                    url: 'api/User/checkSession?sessionKey=HRIS_SessionId',
                    credentials: 'include',
                    data: "",
                    onError: () => { },
                    onSuccess: data => {
                        if (data != null) {
                            if (data.Expired) {
                                window.location = "Login";
                            }
                            else {
                                global.sessionId = data._id;
                                global.userRights = data.UserRights;
                                global.userId = data.UserId;

                                switch (pathName) {
                                    case "/changepassword":
                                        ReactDOM.render(<ChangePassword redirect={qs.redirect} />, document.getElementById('root'));
                                        break;
                                    case "/":
                                        ReactDOM.render(<MasterPage3 />, document.getElementById('root'));
                                        break;
                                    default:
                                        break;
                                }

                            }
                        }
                        else {
                            if (pathName === "/")
                                ReactDOM.render(<Login />, document.getElementById('root'));
                            else if (pathName === "/changepassword")
                                ReactDOM.render(<Login redirect={"changepassword"} />, document.getElementById('root'));
                        }
                    },
                    finally: () => { }
                }
            )
            ReactDOM.render(<Loader active />, document.getElementById('root'));
            break;
        case "/":
        case '/notification':
            ajaxGet(
                {
                    url: 'api/User/checkSession?sessionKey=HRIS_SessionId',
                    credentials: 'include',
                    data: "",
                    onError: () => { },
                    onSuccess: data => {
                        if (data != null) {
                            if (data.Expired)
                                window.location = "Login";
                            else if (data.IsReset)
                                window.location = "changepassword";
                            else {
                                global.sessionId = data._id;
                                global.userRights = data.UserRights;
                                global.userId = data.UserId;

                                switch (pathName) {
                                    case "/notification":
                                        ReactDOM.render(<ExternalNotification id={qs.id} />, document.getElementById('root'));
                                        break;
                                    case "/":
                                        ReactDOM.render(<MasterPage3 />, document.getElementById('root'));
                                        break;
                                    default:
                                        break;
                                }

                            }
                        }
                        else {
                            if (pathName === "/")
                                ReactDOM.render(<Login />, document.getElementById('root'));
                            else if (pathName === "/notification")
                                ReactDOM.render(<Login redirect={"notification" + uri.search} />, document.getElementById('root'));
                        }
                    },
                    finally: () => { }
                }
            )
            ReactDOM.render(<Loader active />, document.getElementById('root'));
            break;
        default:
            ReactDOM.render(<ErrorPage id="invalid_path" />, document.getElementById('root'));
    }


}
import React, { Component } from 'react';
import { Button, Table, Modal, Icon, Menu, Pagination, Popup, Input, Image } from 'semantic-ui-react';
import Select from 'react-select';
import { ajaxPost } from '../../ajax';
import { handleNumericOnKeyPress, handleNumericOnKeyDown } from '../../utils';
import SelectEmployees from '../../Commons/SelectEmployees';
import MessageBoxYesNo from '../../Commons/MessageBoxYesNo';

import modStyles from './FringeBenefit.module.css'

var moment = require('moment');
var { fnSearchEmployeeFilter, isTaskAvailable, empty_id } = require('../../utils');

const blankFringeBenfit = {
    _id: empty_id,
    EmployeeId: empty_id,
    Amount: '',
    StartMonth: moment().format('M'),
    StartYear: moment().format('YYYY'),
    EndMonth: moment().format('M'),
    EndYear: moment().format('YYYY'),
    Frequency: -1,
    Remarks: ''
}

const frequencyOptions = [
    { label: 'Every Cutoff', value: -1 },
    { label: 'First Cutoff / Week', value: 1 },
    { label: 'Last Cutoff / Week', value: 0 },
    { label: 'Second Cutoff', value: 2 },
    { label: 'Third Cutoff', value: 3 },
    { label: 'Fourth Cutoff', value: 4 }
]

export default class FringeBenefit extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isSaving: false,
            isLoading: false,
            isFormModalOpen: false,
            isMessageModalOpen: false,
            messageModalTitle: '',
            messageModalContent: '',

            isConfirmationModalOpen: false,

            selectedFringeBenefit: null,
            employeeOptions: [],
            monthOptions: [],
            yearOptions: [],

            selectedEmployee: null,
            amount: '',
            startMonth: '',
            startYear: '',
            endMonth: '',
            endYear: '',
            deductionFrequency: null,
            remarks: '',

            filterStartDate: '',
            filterEndDate: '',
            filterSelectedEmployees: [],

            fringeBenefitList: [],
            fringeBenefitCount: 0,
            activePage: 1,
            fringeBenefitIndex: -1
        }
    }

    componentWillMount() {
        this.doSearch();
    }

    mapFrequency(value) {
        return frequencyOptions.find(x => x.value === value).label;
    }

    loadMonthOptions() {
        var monthOption = [];
        for (let index = 1; index <= 12; index++) {
            monthOption.push({ label: moment('2000-' + index + '-1').format('MMMM'), value: index });
        }

        return monthOption;
    }

    loadYearOptions() {
        var yearOption = [];
        const currentYear = parseInt(moment().format('YYYY'));
        for (let index = currentYear - 30; index <= currentYear + 50; index++)
            yearOption.push({ label: index, value: index })

        return yearOption;
    }

    doSearch = () => {
        if (this.state.isLoading)
            return;

        this.setState({ isLoading: true });

        ajaxPost({
            url: 'api/FringeBenefit/searchWithPagination',
            data: {
                search: '',
                startingIndex: (this.state.activePage - 1) * 10,
                itemCount: 10,
                employeeIds: this.state.filterSelectedEmployees
            },
            onSuccess: (data) => {
                this.setState({
                    fringeBenefitCount: data.count,
                    fringeBenefitList: data.content
                })
            },
            finally: () => { this.setState({ isLoading: false }) }
        })
    }

    onAddNewFringeBenefit = () => {
        this.setState({
            selectedEmployee: null,
            selectedFringeBenefit: JSON.parse(JSON.stringify(blankFringeBenfit)),
            isFormModalOpen: true,
            amount: '',
            remarks: '',
            fringeBenefitIndex: -1,
        });

        this.loadInitialData(blankFringeBenfit);
    }

    onEditFringeBenefit = (fringeBenefit, index, e) => {
        this.setState({
            selectedFringeBenefit: JSON.parse(JSON.stringify(fringeBenefit)),
            selectedEmployee: [fringeBenefit.EmployeeId],
            amount: fringeBenefit.Amount,
            remarks: fringeBenefit.Remarks,
            isFormModalOpen: true,
            fringeBenefitIndex: index
        }, () => this.loadInitialData(fringeBenefit));


    }

    onDeleteFringeBenefit = (fringeBenefit, e) => {
        this.setState({
            selectedFringeBenefit: fringeBenefit,
            isConfirmationModalOpen: true
        });
    }

    deleteFringeBenefit(id) {
        ajaxPost({
            url: 'api/fringebenefit/delete',
            data: id,
            onSuccess: (data) => {
                if (data === true) {
                    this.setState({
                        fringeBenefitList: this.state.fringeBenefitList.filter(x => x._id !== id),
                        messageModalTitle: 'Fringe Benefit',
                        messageModalContent: 'Successfully Deleted.',
                        isMessageModalOpen: true,
                        selectedFringeBenefit: null,
                        isConfirmationModalOpen: false
                    })
                }
            },
            finally: () => { }
        });
    }

    loadInitialData(fringeBenefit) {
        const monthOption = this.loadMonthOptions();
        const yearOption = this.loadYearOptions();
        this.setState({
            startMonth: monthOption.find(x => x.value === parseInt(fringeBenefit.StartMonth)),
            startYear: yearOption.find(x => x.value === parseInt(fringeBenefit.StartYear)),
            endMonth: monthOption.find(x => x.value === parseInt(fringeBenefit.EndMonth)),
            endYear: yearOption.find(x => x.value === parseInt(fringeBenefit.EndYear)),
            deductionFrequency: frequencyOptions.find(x => x.value === fringeBenefit.Frequency),
            monthOptions: monthOption,
            yearOptions: yearOption
        })
    }

    onSave = () => {
        if (this.state.isSaving)
            return;

        if (this.state.selectedEmployee == null ||
            this.state.selectedEmployee[0] == undefined) {
            this.setState({
                isMessageModalOpen: true,
                messageModalContent: 'Please select an employee.',
                messageModalTitle: 'Fringe Benefit'
            })
            return;
        }
        if (!(parseInt(this.state.amount) > 0)) {
            this.setState({
                isMessageModalOpen: true,
                messageModalContent: 'Please input correct amount.',
                messageModalTitle: 'Fringe Benefit'
            })
            return;
        }

        if (this.state.startMonth.value == undefined || this.state.endMonth.value == undefined) {
            this.setState({
                isMessageModalOpen: true,
                messageModalContent: 'Invalid: Please select valid start/end month',
                messageModalTitle: 'Fringe Benefit'
            })
            return;
        }

        if (this.state.startYear.value == undefined || this.state.endYear.value == undefined) {
            this.setState({
                isMessageModalOpen: true,
                messageModalContent: 'Invalid: Please select valid start/end year',
                messageModalTitle: 'Fringe Benefit'
            })
            return;
        }

        if (parseInt(this.state.startYear.value.toString() + this.state.startMonth.value.toString().padStart(2, '0')) > parseInt(this.state.endYear.value.toString() + this.state.endMonth.value.toString().padStart(2, '0'))) {
            this.setState({
                isMessageModalOpen: true,
                messageModalContent: 'Invalid: End date must be greater than start date.',
                messageModalTitle: 'Fringe Benefit'
            })
            return;
        }

        this.setState({ isSaving: true });

        ajaxPost({
            url: 'api/FringeBenefit/save',
            data: {
                _id: this.state.selectedFringeBenefit._id,
                EmployeeId: this.state.selectedEmployee[0],
                Amount: this.state.amount,
                StartMonth: this.state.startMonth.value,
                StartYear: this.state.startYear.value,
                EndMonth: this.state.endMonth.value,
                EndYear: this.state.endYear.value,
                Frequency: this.state.deductionFrequency.value,
                Remarks: this.state.remarks,
            },
            onSuccess: (data) => {
                var selectedEmp = this.state.selectedEmployee[0];
                var { fringeBenefitList, fringeBenefitIndex } = this.state;
                var hasErrors = data.hasOwnProperty("errors") && data.errors.length > 0;

                if (hasErrors == false) {
                    if (fringeBenefitIndex === -1)
                        fringeBenefitList.push(data.content);
                    else
                        fringeBenefitList[fringeBenefitIndex] = data.content;

                    this.setState({
                        fringeBenefitList,
                        isMessageModalOpen: true,
                        messageModalContent: "Successfully Save.",
                        messageModalTitle: 'Fringe Benefit'
                    })
                } else {
                    this.setState({
                        fringeBenefitList,
                        isMessageModalOpen: true,
                        messageModalContent: <div> <p>{data.content}</p> {data.errors.map(err => { return (<span>{err}<br /></span>) })} </div>,
                        messageModalTitle: 'Fringe Benefit'
                    })
                }

            },
            finally: () => {
                this.setState({
                    isSaving: false,
                    isFormModalOpen: false,
                })
            }
        })
    }

    onCreateNewItemClick = () => {
        this.setState({ showAskCloseDialog: true });
    }

    render() {
        return (
            <div className={modStyles.module}>
                <div style={{ color: "#606060", marginTop: '1%' }}>
                    <h2>Fringe Benefit</h2>
                </div>
                <div className='w3-row' style={{ paddingTop: '8px' }}>
                    <div className='w3-col l4 m4 s12'>
                        <div style={{ display: 'flex', width: '100%' }}>
                            <Button floated='left' size='mini' style={{ 'max-height': '31px' }}
                                fluid={window.innerWidth < 768}
                                onClick={this.onAddNewFringeBenefit.bind(this)} disabled={!(isTaskAvailable("FRINGE_BENEFIT_ADD") || this.state.isSuperAdmin)}>
                                <Icon name='plus' />New
                            </Button>
                            <div className='w3-hide-small'>
                                <Button floated='left' style={{ border: 'none', 'background-color': 'transparent', cursor: 'arrow', 'max-height': '31px' }}
                                    size='tiny' loading={this.state.isLoading} fluid={window.innerWidth < 768}>
                                </Button>
                            </div>
                        </div>
                    </div>
                    <div className='w3-col l8 m8 s12'>
                        <Menu secondary size='mini' style={{ width: '100%' }}>
                            <div style={window.innerWidth > 500 ? { display: 'flex', justifyContent: 'flex-end', width: '100%' } : { width: '100%' }}>
                                <SelectEmployees Ids={this.state.filterSelectedEmployees} includeDeleted={false} update={(ids) => this.setState({ filterSelectedEmployees: ids }, this.doSearch.bind(this))} width={window.innerWidth < 500 ? '100%' : '300px'} />
                                <Button size='mini' fluid={window.innerWidth < 768} type='submit' onClick={() => this.setState({ activePage: 1 }, this.doSearch)} style={window.innerWidth >= 768 ? { 'max-height': '30px', marginLeft: '10px' } : { 'max-height': '30px' }} content='Search' />
                            </div>
                        </Menu>
                    </div>
                </div>
                <hr style={{ color: "#606060", 'border-width': '0.8px' }} />
                <Pagination
                    style={{alignSelf:"end"}}
                    defaultActivePage={1}
                    pointing
                    secondary
                    firstItem={{ content: <Icon name='angle double left' />, icon: true }}
                    lastItem={{ content: <Icon name='angle double right' />, icon: true }}
                    prevItem={{ content: <Icon name='angle left' />, icon: true }}
                    nextItem={{ content: <Icon name='angle right' />, icon: true }}
                    size='mini'
                    siblingRange={2}
                    boundaryRange={0}
                    totalPages={Math.ceil(this.state.fringeBenefitCount / 10)}
                    activePage={this.state.activePage}
                    onPageChange={(e, { activePage }) => this.setState({ activePage }, () => { this.doSearch(); })}
                />
                <div className={modStyles.content}>
                    <Table stackable selectable striped size='small'>
                        <Table.Header>
                            <Table.Row style={{ 'backgroundColor': 'gainsboro', 'fontSize': '11px', padding: '0px' }}>
                                <Table.HeaderCell> Employee </Table.HeaderCell>
                                <Table.HeaderCell> Amount </Table.HeaderCell>
                                <Table.HeaderCell> Start Period </Table.HeaderCell>
                                <Table.HeaderCell> End Period </Table.HeaderCell>
                                <Table.HeaderCell> Frequency </Table.HeaderCell>
                                <Table.HeaderCell> Remarks </Table.HeaderCell>
                                <Table.HeaderCell> Actions </Table.HeaderCell>
                            </Table.Row>
                        </Table.Header>
                        <Table.Body>
                            {this.state.fringeBenefitList
                                .map((det, index) => {
                                    return (
                                        <Table.Row>
                                            <Table.Cell> {det.Employee} </Table.Cell>
                                            <Table.Cell> {det.Amount} </Table.Cell>
                                            <Table.Cell> {moment(det.StartYear + '-' + det.StartMonth + '-1').format('MMMM YYYY')} </Table.Cell>
                                            <Table.Cell> {moment(det.EndYear + '-' + det.EndMonth + '-1').format('MMMM YYYY')} </Table.Cell>
                                            <Table.Cell> {this.mapFrequency(det.Frequency)} </Table.Cell>
                                            <Table.Cell> {det.Remarks} </Table.Cell>
                                            <Table.Cell>
                                                <div>
                                                    <Popup trigger={
                                                        <Button
                                                            onClick={this.onEditFringeBenefit.bind(this, det, index)}
                                                            icon='edit' circular disabled={!(isTaskAvailable("FRINGE_BENEFIT_EDIT") || this.state.isSuperAdmin)}></Button>}
                                                        content='Edit'
                                                        inverted
                                                    />
                                                    <Popup trigger={
                                                        <Button
                                                            onClick={this.onDeleteFringeBenefit.bind(this, det)}
                                                            icon='delete' circular disabled={!(isTaskAvailable("FRINGE_BENEFIT_DELETE") || this.state.isSuperAdmin)}></Button>}
                                                        content='Delete'
                                                        inverted
                                                    />
                                                </div>
                                            </Table.Cell>
                                        </Table.Row>
                                    )
                                })}
                        </Table.Body>
                    </Table>
                </div>
                {
                    this.state.showAskCloseDialog &&
                    <MessageBoxYesNo title="Create New Employee"
                        action="Yes"
                        onClose={() => { this.setState({ showAskCloseDialog: false }) }}
                        onAction={() => {
                            this.setState({ modalVisible: false });
                            this.props.createEmpCallback();
                        }}
                        caption="All unsaved changes will be lost. Do you wish to continue?"
                    />
                }
                {
                    this.state.isFormModalOpen &&
                    <Modal open={true} size='mini'>
                        <Modal.Header>Fringe Benefit</Modal.Header>
                        <Modal.Content>
                            <table style={{ width: '100%' }}>
                                <tr>
                                    <td>
                                        <label>Employee</label>
                                    </td>
                                    <td>
                                        <SelectEmployees 
                                            disabled={this.state.fringeBenefitIndex !== -1}
                                            isMulti={false}
                                            includeDeleted={false}
                                            Ids={this.state.selectedEmployee}
                                            update={(ids) => this.setState({ selectedEmployee: ids }, this.doSearch.bind(this))}
                                            width={'100%'}
                                        />
                                        {
                                            isTaskAvailable("EMPLOYEE_ADD") &&
                                            <a as='a' href='#' onClick={this.onCreateNewItemClick.bind(this)}>Create New</a>
                                        }
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <label>Amount</label>
                                    </td>
                                    <td>
                                        <Input style={{ width: '100%' }} value={this.state.amount}
                                            content={this.state.amount}
                                            onKeyPress={(e) => { handleNumericOnKeyPress(e) }}
                                            onKeyDown={(e) => { handleNumericOnKeyDown(e) }}
                                            onChange={(e) => this.setState({ amount: e.target.value })}
                                        />
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <label>Start Month</label>
                                    </td>
                                    <td>
                                        <Select value={this.state.startMonth} options={this.state.monthOptions} onChange={(value) => this.setState({ startMonth: value })}></Select>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <label>Start Year</label>
                                    </td>
                                    <td>
                                        <Select value={this.state.startYear} options={this.state.yearOptions} onChange={(value) => this.setState({ startYear: value })}></Select>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <label>End Month</label>
                                    </td>
                                    <td>
                                        <Select value={this.state.endMonth} options={this.state.monthOptions} onChange={(value) => this.setState({ endMonth: value })}></Select>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <label>End Year</label>
                                    </td>
                                    <td>
                                        <Select value={this.state.endYear} options={this.state.yearOptions} onChange={(value) => this.setState({ endYear: value })}></Select>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <label>Frequency</label>
                                    </td>
                                    <td>
                                        <Select value={this.state.deductionFrequency} options={frequencyOptions} onChange={(value) => this.setState({ deductionFrequency: value })}></Select>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <label>Remarks</label>
                                    </td>
                                    <td>
                                        <Input style={{ width: '100%' }} value={this.state.remarks} content={this.state.remarks} onChange={(e) => this.setState({ remarks: e.target.value })} maxLength={50}/>
                                    </td>
                                </tr>
                                <tr>
                                    <td></td>
                                    <td><label><i>{this.state.remarks.length} / 50 Characters</i></label></td>
                                </tr>
                            </table>
                        </Modal.Content>
                        <Modal.Actions>
                            <Button onClick={this.onSave.bind(this)} icon='save' content='Save' disabled={this.state.isSaving} loading={this.state.isSaving} />
                            <Button onClick={() => this.setState({ isFormModalOpen: false })} disabled={this.state.isSaving} icon='cancel' content='Cancel' />
                        </Modal.Actions>
                    </Modal>
                }
                {
                    this.state.isMessageModalOpen &&
                    <Modal size='mini' open={true}>
                        <Modal.Header>{this.state.messageModalTitle}</Modal.Header>
                        <Modal.Content>{this.state.messageModalContent}</Modal.Content>
                        <Modal.Actions>
                            <Button onClick={() => this.setState({ isMessageModalOpen: false })} content='OK' />
                        </Modal.Actions>
                    </Modal>
                }
                {
                    this.state.isConfirmationModalOpen &&
                    <Modal open={true}>
                        <Modal.Header>Fringe Benefit</Modal.Header>
                        <Modal.Content>Are you sure you want to delete this item?</Modal.Content>
                        <Modal.Actions>
                            <Button onClick={() => this.deleteFringeBenefit(this.state.selectedFringeBenefit._id)} icon='check' content='Yes' disabled={this.state.isSaving} loading={this.state.isSaving} />
                            <Button onClick={() => this.setState({ isConfirmationModalOpen: false })} disabled={this.state.isSaving} icon='cancel' content='No' />
                        </Modal.Actions>
                    </Modal>
                }
            </div>)
    }
}

FringeBenefit.getViewID = () => { return "FringeBenefit" };
FringeBenefit.getCaption = () => { return "Fringe Benefit" };
FringeBenefit.getIcon = () => {
    return (<Image style={{ "height": "24px", "width": "24px" }} src='/Icons/Fringe_Benefits.png' avatar />);
}
FringeBenefit.getIconSrc = (size) => {
    return '/Icons/Fringe_Benefits.png';
}
FringeBenefit.getViewAccessKey = () => {
    return isTaskAvailable("FRINGE_BENEFIT_VIEW");
};
import React, { Component } from 'react';
import { Button, Input, Modal, Checkbox, Dropdown } from 'semantic-ui-react';
import 'semantic-ui-css/semantic.min.css';
import ErrorMessage from '../../../Commons/ErrorMessage';
import { ajaxPost } from '../../../ajax';
import { handleNumeric, checkInt32, isNullOrEmpty, handleNumericOnKeyDown, handleNumericOnKeyPress } from '../../../utils';
import _ from 'lodash';

import modStyles from './LeaveProfileDetailsForm.module.css'
import RowEditor from  './RowEditor';


const someMarginNoPadding = { "margin": "0px 0px 10px 0px", "padding": "0px" };
const noMarginNoPadding = { "margin": "0px", "padding": "0px" };

var delayTimer = null;

class LeaveProfileDetailsForm extends Component {

    constructor(props) {
        super(props);

        let Model = props.data === null || props.data === undefined ? this.getDefaultValues() : Object.assign({}, props.data);
        let AccrualDetails = props.data === null || props.data === undefined ? [{ Year: 0, Grants: 0, Convertible: 0, CarryOver: 0, Taxable: 0 }] :
            Model.AccrualDetailString.split(',').map(
                item => {
                    let values = item.split(':');
                    let Convertible = values[2] ? values[2] : 0;
                    let CarryOver = values[3] ? values[3] : 0;
                    let Taxable = values[4] ? values[4] : 0;
                    return { Year: values[0], Grants: values[1], Convertible, CarryOver, Taxable }
                }
            );

        // codes below is to fix problem regarding above 'split' function which always
        // return 1 object that's why 'AccrualDetails' also has at least one data result (but undefined value)  
        AccrualDetails[0].Year = AccrualDetails[0].Year ? AccrualDetails[0].Year : 0;
        AccrualDetails[0].Grants = AccrualDetails[0].Grants ? AccrualDetails[0].Grants : 0;
        AccrualDetails[0].Convertible = AccrualDetails[0].Convertible ? AccrualDetails[0].Convertible : 0;
        AccrualDetails[0].CarryOver = AccrualDetails[0].CarryOver ? AccrualDetails[0].CarryOver : 0;
        AccrualDetails[0].Taxable = AccrualDetails[0].Taxable ? AccrualDetails[0].Taxable : 0;

        this.state = {
            Model,
            AccrualDetails,
            errTitle: "",
            errCaption: "",
            showRequiredField: false,
            showAccruedYearForm: Model.Accrued
        }

        this.columns = [
            { key: 'Year', name: 'Year'},
            { key: 'Grants', name: 'Grants' },
            { key: 'Convertible', name: 'Convertible' },
            { key: 'CarryOver', name: 'CarryOver' },
            { key: 'Taxable', name: 'Non-Taxable' },
            { key: 'Action', name: 'Action' }
        ];

        this.accruedYearFormRef = React.createRef();
    }

    onDelete = (index) => {
        let AccrualDetails = this.state.AccrualDetails;
        AccrualDetails.splice(index, 1);
        // AccrualDetails.splice(year - 1, 1);
        // AccrualDetails.forEach((item, index) => {
        //     item.Year = index + 1;
        // })        
        this.setState({ AccrualDetails });
    }

    onAdd = () => {
        let AccrualDetails = this.state.AccrualDetails;
        // AccrualDetails.push({ Year: AccrualDetails.length + 1, Grants: 0 });
        AccrualDetails.push({ Year: 0, Grants: 0, Convertible: 0, CarryOver: 0, Taxable: 0 });
        this.setState({ AccrualDetails });
    }
    isNumber = (n) => {
        return !isNaN(parseFloat(n)) && isFinite(n);
      }    
    onUpdateRow = (i,values) => {
        let result = "";
        values.forEach((v) => {
            if( this.isNumber(v) === false){
                result = "Invalid value"
            }
            if( parseInt(v) < 0 ){
                result = "Value must be non-negative"
            } 
        })
        if(result.length > 0){
            return result;
        }
        
       
        var accrualDetails = [...this.state.AccrualDetails];
        var updated = { Year: values[0], Grants: values[1], Convertible: values[2], CarryOver: values[3], Taxable: values[4] }
         
        accrualDetails.splice( i, 1, updated);
        this.setState({AccrualDetails: accrualDetails});

        return "";
    }

    toRow = (model, index) => {
        return {
            Year: model.Year,
            Grants: model.Grants,
            Convertible: model.Convertible,
            CarryOver: model.CarryOver,
            Taxable: model.Taxable,
            Action:
                <div>
                    <Button icon='add' size='mini' basic onClick={this.onAdd.bind(this)} />
                    <Button icon='minus' size='mini' basic onClick={this.onDelete.bind(this, index)} disabled={this.state.AccrualDetails.length <= 1} />
                </div>
        }
    }

    toModel = (row) => {
        return {
            Year: row.Year,
            Grants: row.Grants,
            Convertible: row.Convertible,
            CarryOver: row.CarryOver,
            Taxable: row.Taxable
        }
    }

    componentDidMount() {
        if (this.state.Model.LeaveTypeId) {
            this.onLeaveTypeKeyPress(this, { searchQuery: this.state.Model.LeaveType });
        }
    }

    getDefaultValues = () => {
        return {
            LeaveType: "",
            LeaveTypeId: "",
            AccrualDetailString: "",
            Accrued: false,
            Grants: 0,
            CarryOver: 0,
            Convertible: 0,
            Taxable: 0,
            ValidPriorFilingDays: 0,
            ValidLateFilingDays: 0
        }
    }

    setContent = (data) => {
        let Model = data === null || data === undefined ? this.getDefaultValues() : Object.assign({}, data);
        let AccrualDetails = Model.AccrualDetailString.split(',').map(
            item => {
                let values = item.split(':');
                let Convertible = values[2] ? values[2] : 0;
                let CarryOver = values[3] ? values[3] : 0;
                let Taxable = values[4] ? values[4] : 0;
                return { Year: values[0], Grants: values[1], Convertible, CarryOver, Taxable }
            }
        );

        this.setState({
            Model,
            AccrualDetails
        });
    }

    getContent = () => {
        let Model = this.state.Model;
        Model.AccrualDetailString = this.state.AccrualDetails.map(x =>
            (x.Year ? x.Year : 0) + ':' +
            (x.Grants ? x.Grants : 0) + ':' +
            (x.Convertible ? x.Convertible : 0) + ':' +
            (x.CarryOver ? x.CarryOver : 0) + ':' +
            (x.Taxable ? x.Taxable : 0)
        ).join(',');
        // Model.AccrualDetailString = this.state.AccrualDetails.length > 1 ? this.state.AccrualDetails.map(x => x.Year + ':' + x.Grants).join(',') : '';
        return Model;
    }

    handleChange = name => event => {
        var current = this.state.Model;
        current[name] = event.target.value;
        this.setState({ Model: current });
    };

    handleComboChange = (name, val) => {
        var current = this.state.Model;
        current[val.name] = val.value;
        this.setState({ Model: current });
    }

    handleSelectChange = (name, evt) => {
        var current = this.state.Model;
        if (!(evt === undefined || evt === null)) {
            current[name] = evt.value;
        }
        else {
            current[name] = null;
        }
        this.setState({ Model: current });
    }

    onLeaveTypeChange = (event, data) => {
        let Model = this.state.Model;
        Model.LeaveTypeId = data.value;
        Model.LeaveType = this.state.leaveTypeData.find(item => item.value === data.value).code;
        this.setState({ Model });
    }

    onLeaveTypeKeyPress = (event, data) => {
        this.setState({ isLoading: true });
        var self = this;
        clearTimeout(delayTimer);
        delayTimer = setTimeout(() => {
            var parameter = {
                search: data.searchQuery,
                includeDeleted: false,
                startIndex: 0,
                itemCount: 15
            };
            var searchParameter = {
                data: parameter,
                url: "api/LeaveType/search",
                onError: (error) => {
                    //self.setState({ leaveTypeData: [] });
                },
                onSuccess: (data, sender) => {
                    if (data.content.length === 0) {
                        self.setState({ LeaveTypeId: "" });
                        return;
                    }
                    var newOptions = data.content.filter(x =>
                        !this.props.existingLeaveIds.some(_id => _id === x._id) || x._id === this.state.Model.LeaveTypeId
                    ).map((item) => {
                        return {
                            key: item._id,
                            text: item.Name + " (" + item.Code + ")",
                            value: item._id,
                            code: item.Code,
                        }
                    });

                    self.setState({ leaveTypeData: newOptions });
                },
                finally: () => {
                    self.setState({ isLoading: false });
                }
            };
            ajaxPost(searchParameter);
        }, 200);
    };

    rowGetter = (i) => {
        return _.clone(this.toRow(this.state.AccrualDetails[i], i));
    };

    handleGridRowsUpdated = ({ action, cellKey, fromRow, toRow, updated }) => {
        this.setState(state => {
            const AccrualDetails = state.AccrualDetails.slice();
            for (let i = fromRow; i <= toRow; i++) {
                let updatedObj = Object.assign({}, AccrualDetails[i], updated);// { Year: AccrualDetails[i][cellKey], Grants: updated.value}
                AccrualDetails[i] = { ...updatedObj, ...updated };
            }
            return { AccrualDetails };
        });
    }

    render() {
        let model = this.state.Model;
        // if (this.state.showAccruedYearForm) {
        //     return  (
        //         <AccruedYearForm
        //             onOpen={this.state.showAccruedYearForm}
        //             onClose={() => this.setState({  
        //                 showAccruedYearForm: false
        //             })}
        //         />
        //     )
        // }
        // else
        if (true) {
            return (
                <Modal size="tiny" open={this.props.open}>
                    <Modal.Header>
                        Leave Profile Details
                    </Modal.Header>
                    <Modal.Content>
                        {
                            this.state.showRequiredField &&
                            <div className="ui one column grid">
                                <div className="column">
                                    <ErrorMessage
                                        title={this.state.errTitle}
                                        caption={this.state.errCaption}
                                        open={this.state.showRequiredField}
                                    />
                                </div>
                            </div>
                        }
                        <div className="ui one column grid">
                            <div className="column">
                                <div>Leave Type</div>
                                <Dropdown
                                    fluid
                                    search
                                    selection
                                    //disabled={this.props.isBusy} 
                                    options={this.state.leaveTypeData}
                                    value={model.LeaveTypeId}
                                    error={!model.LeaveTypeId && this.state.showRequiredField}
                                    loading={this.state.isLoading}
                                    onClick={this.onLeaveTypeKeyPress}
                                    onSearchChange={this.onLeaveTypeKeyPress}
                                    onChange={this.onLeaveTypeChange}>
                                </Dropdown>
                            </div>
                        </div>
                        <div className="ui two column grid">
                            <div className="column">
                                <div>Valid Days Prior Filing</div>
                                <Input
                                    fluid
                                    value={model.ValidPriorFilingDays}
                                    //disabled={this.props.isBusy} 
                                    // onChange={(event) => { self.setState({ Hours: handleNumeric(event) }); }} 
                                    error={this.state.showRequiredField && (isNullOrEmpty(model.ValidPriorFilingDays) || !checkInt32(model.ValidPriorFilingDays))}
                                    onChange={this.handleChange("ValidPriorFilingDays").bind(this)}
                                    onKeyDown={(e) => handleNumericOnKeyDown(e)}
                                    onKeyPress={(e) => handleNumericOnKeyPress(e)}
                                />
                            </div>
                            <div className="column">
                                <div>Valid Days Late Filing</div>
                                <Input
                                    fluid
                                    value={model.ValidLateFilingDays}
                                    //disabled={this.props.isBusy} 
                                    // onChange={(event) => { self.setState({ Hours: handleNumeric(event) }); }} 
                                    error={this.state.showRequiredField && (isNullOrEmpty(model.ValidLateFilingDays) || !checkInt32(model.ValidLateFilingDays))}
                                    onChange={this.handleChange("ValidLateFilingDays").bind(this)}
                                    onKeyDown={(e) => handleNumericOnKeyDown(e)}
                                    onKeyPress={(e) => handleNumericOnKeyPress(e)}
                                />
                            </div>
                        </div>
                        <div className="ui two column grid" style={noMarginNoPadding}>
                            <div className="column" style={someMarginNoPadding}>
                                <div>&nbsp;</div>
                                <Checkbox
                                    label='Accrued'
                                    checked={model.Accrued}
                                    onClick={(event, data) => {
                                        var current = this.state.Model;
                                        current["Accrued"] = data.checked;
                                        this.setState({ Model: current, showAccruedYearForm: data.checked },
                                            // () => {
                                            //     if (data.checked && this.state.LeaveTypeId && this.state.AccrualDetails.length === 0) {
                                            //         this.setState({

                                            //         })
                                            //     }
                                            // }    
                                        );
                                    }}
                                />
                                {/* <AccruedYearForm
                                    ref={this.accruedYearFormRef} 
                                    onOpen={this.state.showAccruedYearForm}
                                    onClose={() => this.setState({  
                                        showAccruedYearForm: false
                                    })}
                                    trigger={
                                        <Checkbox
                                            label='Accrued' 
                                            checked={model.Accrued}
                                            onClick={(event, data) => {
                                                var current = this.state.Model;
                                                current["Accrued"] = data.checked;
                                                this.setState({ Model: current, showAccruedYearForm: data.checked },
                                                    () => {
                                                        this.accruedYearFormRef.current.setContent();
                                                    }    
                                                );                            
                                            }}
                                      0  />
                                    }
                                /> */}
                            </div>
                        </div>
                        {
                            this.state.showAccruedYearForm &&
                            <div>
                                <div className={modStyles.table}>
                                <div className={modStyles.table_header}>
                                    {this.columns.map((col,index) => {
                                        return(   <div>{col.name}</div>)
                                    })}
                                </div>
                                <div className={modStyles.table_content}>
                                    {
                                        this.state.AccrualDetails.map((x ,i) => {
                                          return <RowEditor 
                                            values= {[
                                                x.Year,
                                                x.Grants,
                                                x.Convertible,
                                                x.CarryOver,
                                                x.Taxable
                                            ]}
                                            widths={['4em', '4em', '4em','4em','4em']}
                                            type={['TEXT','TEXT','TEXT','TEXT','TEXT']}
                                            onAdd={this.onAdd.bind(this,i)}
                                            onRemove={this.onDelete.bind(this,i)}
                                            onSave={this.onUpdateRow.bind(this,i)}
                                            key={`accruedkey${i}`}
                                          />  
                                        }) 

                                        // this.state.breaks.map( (x,i)=> {
                                        //     return <RowEditor 
                                        //     values={[ 
                                        //         x.MinutesBreak.toString(),
                                        //         x.Paid
                                        //     ]}
                                        //     widths={['8em', '8em', '8em']}
                                        //     type={['TIME','TEXT','CHECKBOX']}
                                        //     onRemove={this.onRemove.bind(this,i)}
                                        //     onSave={this.onUpdateRow.bind(this,i)}
                                        //     key={`AdditionalBreak${i}`}
                                        //     />
                                        // })
                                    }
                                </div>
                            </div>  
                                {/* <DataGrid
                                    ref={node => this.grid = node}
                                    minHeight={35 * 4}
                                    onGridRowsUpdated={this.handleGridRowsUpdated}
                                    enableCellSelect={true}
                                    columns={this.columns}
                                    rows={this.toRow(this.state.AccrualDetails)}
                                    rowsCount={this.state.AccrualDetails.length}
                                /> */}
                            </div>
                        }
                        {!this.state.showAccruedYearForm &&
                            <div className="ui two column grid">
                                <div className="column">
                                    <div>Grants</div>
                                    <Input
                                        fluid
                                        value={model.Grants}
                                        //disabled={this.props.isBusy} 
                                        // onChange={(event) => { self.setState({ Hours: handleNumeric(event) }); }} 
                                        error={this.state.showRequiredField && (isNullOrEmpty(model.Grants) || !checkInt32(model.Grants))}
                                        onChange={this.handleChange("Grants").bind(this)}
                                        onKeyDown={(e) => handleNumericOnKeyDown(e)}
                                        onKeyPress={(e) => handleNumericOnKeyPress(e)}
                                    />
                                </div>
                                <div className="column">
                                    <div>Convertible</div>
                                    <Input
                                        fluid
                                        value={model.Convertible}
                                        //disabled={this.props.isBusy} 
                                        error={this.state.showRequiredField && (isNullOrEmpty(model.Convertible) || !checkInt32(model.Convertible))}
                                        onChange={this.handleChange("Convertible").bind(this)}
                                        onKeyDown={(e) => handleNumericOnKeyDown(e)}
                                        onKeyPress={(e) => handleNumericOnKeyPress(e)}
                                    />
                                </div>
                            </div>
                        }
                        {!this.state.showAccruedYearForm &&
                            <div className="ui two column grid">
                                <div className="column">
                                    <div>Carry Over</div>
                                    <Input
                                        fluid
                                        value={model.CarryOver}
                                        //disabled={this.props.isBusy} 
                                        error={this.props.showRequiredField && (isNullOrEmpty(model.CarryOver) || !checkInt32(model.CarryOver))}
                                        onChange={this.handleChange("CarryOver").bind(this)}
                                        onKeyDown={(e) => handleNumericOnKeyDown(e)}
                                        onKeyPress={(e) => handleNumericOnKeyPress(e)}
                                    />
                                </div>
                                <div className="column">
                                    <div>Non Taxable</div>
                                    <Input
                                        fluid
                                        value={model.Taxable}
                                        //disabled={this.props.isBusy} 
                                        error={this.props.showRequiredField && (isNullOrEmpty(model.Taxable) || !checkInt32(model.Taxable))}
                                        onChange={this.handleChange("Taxable").bind(this)}
                                        onKeyDown={(e) => handleNumericOnKeyDown(e)}
                                        onKeyPress={(e) => handleNumericOnKeyPress(e)}
                                    />
                                </div>
                            </div>
                        }

                    </Modal.Content>
                    <Modal.Actions>
                        <Button basic icon='save' disabled={!this.state.Model.LeaveTypeId || !this.state.leaveTypeData} content="Submit" onClick={this.props.onSubmit}></Button>
                        <Button basic icon='cancel' content="Cancel" onClick={this.props.onCancel}></Button>
                    </Modal.Actions>
                </Modal>
            );
        }
    }
}

export default LeaveProfileDetailsForm
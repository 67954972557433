import React, { Component } from 'react';
import 'semantic-ui-css/semantic.min.css';
import { Modal, Button, Checkbox, Input,Dropdown, Label } from 'semantic-ui-react';
import SelectEmployees from '../../../Commons/SelectEmployees';
import Select from 'react-select';
import moment from 'moment';
import { ajaxPost } from  '../../../ajax';
// function employeeSearchlist(list){
//     var employees = [];
//     if(list !=null){
//        list.map(x =>{
//            return(
//             employees.push({label:x.LastName + ", " + x.FirstName + " " + x.MiddleName,value:x._id})
//            )
//        })
//     }
//     return employees;
// }
function dropdownMapping(list) {
    var profiles = [];
    if (list != null) {
        profiles.push({label:"ALL",value:""});
        list.map(x => {
            return (
                profiles.push({ label: x.ProfileName, value: x.id })
            )
        })
    }
    return profiles;
}
class LoanPaymentFilterModal extends Component {

    constructor(props) {
        super(props);
        this.state = {
            Filter:{
                Year:moment().startOf('day').format("YYYY"),
                Month:parseInt(moment().startOf('day').format("M")),
                loanType:"",
                EmployeeIds:[],
                includeEmptyEntries:true,
            },
            
            searchQuery:'',
            Employeelist:[],
            LoanTypes:[],
            title:props.title
        }
        this.loadLoanTypes(data => {
            this.setState({ LoanTypes: data });
        })
    }   

    getParameters = () => {
        return {
            Year: this.state.Filter.Year,
            Month: this.state.Filter.Month,         
            message: "",
            loanType:this.state.Filter.loanType,
            EmployeeIds:this.state.Filter.EmployeeIds,
            includeEmptyEntries: this.state.Filter.includeEmptyEntries,
        };
    }

    loadLoanTypes = (callback) => {
        ajaxPost({
            url: 'api/PayrollLoanType/LoanTypeNames',
            data: {
                "Name": 1,
                "_id": 1,
            },
            onSuccess: data => {
                callback(data);
            },
            finally: () => { }
        })
    }

    updateEmployeeIds = (ids) =>{
        const {Filter} = this.state;
        Filter.EmployeeIds = ids;
        this.setState({ Filter });
    }
    handleCheckChange = (e) =>{
        var filter  = this.state.Filter;
        filter.includeEmptyEntries = filter.includeEmptyEntries === false ? true : false;
        this.setState({Filter:filter});
    }
    handleComboChange = name => (value) => {
        var current = this.state.Filter;
        if(value != null){
            current[name] = value.value;
            this.setState({ Filter : current});
        }
    }
    handleChange = name => event => {
        var current = this.state.Filter;
        current[name] = event.target.value;
        this.setState({ Filter: current });
    };
    render() {
        const months = [
            { label: 'January', value: 1 },
            {  label: 'Febuary', value: 2 },
            {  label: 'March', value: 3 },
            {  label: 'April', value: 4 },
            {  label: 'May', value: 5 },
            {  label: 'June', value: 6 },
            {  label: 'July', value: 7 },
            {  label: 'August', value: 8 },
            {  label: 'September', value: 9 },
            {  label: 'October', value: 10 },
            {  label: 'November', value: 11 },
            {  label: 'December', value: 12 },
        ];
        return (
            <Modal open={this.props.open} size="tiny">
                <Modal.Header>
                    <p>{this.props.title} Filters</p>
                </Modal.Header>
                <Modal.Content>
                    <div class="ui grid">
                        <div class="left floated left aligned eight wide column">
                            <Select options={months} placeholder="Month" value={months.find(x => x.value === this.state.Filter.Month)} name="Month" onChange={this.handleComboChange("Month").bind(this)} />
                        </div>
                        <div class="right floated right aligned eight wide column">
                            <Input fluid placeholder='Year' value={this.state.Filter.Year} onChange={this.handleChange("Year").bind(this)} />
                        </div>
                    </div>
                    
                        <div class="ui grid">
                            <div class="left floated left aligned sixteen wide column">
                                <Select placeholder='Loan Type' name="loanType"
                                    onChange={this.handleComboChange("loanType").bind(this)}  
                                    options={dropdownMapping(this.state.LoanTypes)}    
                                    value={dropdownMapping(this.state.loanTypes).find(x=> x.value === this.state.Filter.loanType)}
                                    />
                            </div>
                        </div>
                    <div class="ui grid">
                        <div class="left floated left aligned sixteen wide column">
                        <label>Employee</label>
                            <SelectEmployees Ids={this.state.Filter.EmployeeIds} update={this.updateEmployeeIds.bind(this)}/>
                        </div>
                    </div>
                </Modal.Content>
                <Modal.Actions>
                    <Button basic content="Generate" onClick={this.props.onSave}></Button>
                    <Button basic content="Cancel" onClick={this.props.onCancel}></Button>
                </Modal.Actions>
            </Modal>
        );
    }

}

export default LoanPaymentFilterModal;
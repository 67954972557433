import React, { Component } from 'react';
import 'semantic-ui-css/semantic.min.css';
import { Modal, Button, Checkbox } from '../../../../node_modules/semantic-ui-react';
import Select from 'react-select';
import CustomSelect from '../../../Commons/CustomSelect';
const moment = require('moment');

function monthList(){
    var BirthMonth = [];
    BirthMonth.push({label:"All",value:"ALL"});
    BirthMonth.push({label:"Unspecified",value:"UNSPECIFIED"});
    BirthMonth.push({label:"January",value:"1"});
    BirthMonth.push({label:"February",value:"2"});
    BirthMonth.push({label:"March",value:"3"});
    BirthMonth.push({label:"April",value:"4"});
    BirthMonth.push({label:"May",value:"5"});
    BirthMonth.push({label:"June",value:"6"});
    BirthMonth.push({label:"July",value:"7"});
    BirthMonth.push({label:"August",value:"8"});
    BirthMonth.push({label:"September",value:"9"});
    BirthMonth.push({label:"October",value:"10"});
    BirthMonth.push({label:"November",value:"11"});
    BirthMonth.push({label:"December",value:"12"});
    return BirthMonth;
}
class EmployeeReportModalGroupByBirthMonth extends Component {

    constructor(props) {
        super(props);
        this.state = {
            includeDeleted:false,
            selectedBirthMonth:"",
            includeInactive:false,
        }
    }
    getParameters = () => {
        const monthNames = ["January", "February", "March", "April", "May", "June",
            "July", "August", "September", "October", "November", "December", "All Months","Unspecified"
        ];
        return {
            message: "from employee report group by birth month modal",
            BirthMonth:this.state.selectedBirthMonth.value,
            includeDeleted:this.state.includeInactive,
            startingIndex:0,
            itemCount:20,
            FilterDetails: "Birth Month: " + (this.state.selectedBirthMonth === null || this.state.selectedBirthMonth.value === "ALL" || this.state.selectedBirthMonth.value === "" ?monthNames[12]:this.state.selectedBirthMonth.value==="UNSPECIFIED"?monthNames[13]:this.state.selectedBirthMonth.value===undefined?"": monthNames[this.state.selectedBirthMonth.value-1])
        };
    }

    handleComboChange = (value) => {
        this.setState({ selectedBirthMonth : value})
    }

    onChkCheckChange = (e) => {
        this.setState({ includeInactive: !this.state.includeInactive })
    }
    render() {
        return (
            <Modal open={this.props.open} size="tiny">
                <Modal.Header>
                    <p>Employee Report Group By Birth Month Filters</p>
                </Modal.Header>
                <Modal.Content>
                    <div class="ui grid">
                        <div class="left floated left aligned sixteen wide column">
                        <label>Birth Month</label>
                            <CustomSelect fluid placeholder='Birth Month' size="mini" name="BirthMonthList"
                                onChange={this.handleComboChange.bind(this)}  
                                options={monthList()}
                                simpleValue                        
                                value={this.state.selectedBirthMonth}/>
                            <Checkbox size='mini' 
                                label="Include Resigned" 
                                onChange={this.onChkCheckChange.bind(this)} 
                                checked={this.state.includeInactive} />
                        </div>
                    </div>
                </Modal.Content>
                <Modal.Actions>
                    <Button basic content="Generate" onClick={this.props.onSave}></Button>
                    <Button basic content="Cancel" onClick={this.props.onCancel}></Button>
                </Modal.Actions>
            </Modal>
        );
    }

}

export default EmployeeReportModalGroupByBirthMonth;
import React, { Component } from 'react';
import { Button,Checkbox, Header, Form, Dropdown, Modal, Menu, Tab, Loader, Icon, Pagination, Container, Segment } from 'semantic-ui-react';
import 'semantic-ui-css/semantic.min.css';
import moment from 'moment';
import DTRList from '../Cutoff/DTRList';
import DTRSummary from '../Cutoff/DTRSummaryList';
import DTRAdjustment from '../Cutoff/DTRAdjustmentList';
import PayrollList from '../../Payroll/PayrollDetailControl';
import PayrollRegister from '../../Payroll/PayrollDetailControl';
import { loadGroups } from '../../utils';
// import Select from 'react-select';
import { ajaxPost } from '../../ajax';
import { fnSearchEmployeeFilter, isTaskAvailable, ajaxPostDownload, empty_id } from '../../utils';
import MessageBoxYesNo from '../../Commons/MessageBoxYesNo';
import GroupFilterModal from '../../Commons/EmployeeFilterModal';
import SelectEmployees from '../../Commons/SelectEmployees';
import ReactToPrint from "react-to-print";
import PdfMake from 'pdfmake/build/pdfmake';
import vfsFonts from 'pdfmake/build/vfs_fonts';
import BankForm from './BankForm';
import CutoffNotice from './CutoffNotice';
import ActionDTR from './ActionableDTR';
import CutoffEmployeeSelection from '../../Commons/CutoffEmployeeSelection';
import MessageBoxOkOnly from '../../Commons/MessageBoxOkOnly';

import modStyles from './CutoffDetail.module.css';

function employeeSearchlist(list) {
    var employees = [];
    if (list != null) {
        list.map(x => {
            return (
                employees.push({ text: x.LastName + ", " + x.FirstName + " " + x.MiddleName, value: x._id })
            )
        })
    }
    return employees;
}
class CutoffDetail extends Component {
    state = {
        Parameters: {
            CutoffId: "-1",
            needle: "",
            startingIndex: 0,
            itemCount: 10,
            totalPages: 1,
            sessionId: "",
            EmployeeIds: [],
            Type : "",
            advanceFilter: {
                companyIds: [],
                divisionIds: [],
                departmentIds: [],
                locationIds: [],
                positionIds: [],
                costcenterIds:[], // CostCenter
                includeDeleted: false
            },
        },
        searchState: {
            year: parseInt(moment().format("YYYY")),
            initialLoad: true,
            initialDTRLoad: true,
            initialActionableDTR: true,
            initialDtrSummaryLoad: true,
            initialDTRAdjustmentLoad: true,
            loading: false,
            list: [],
            page: 1,
            rowPerPage: 10,
            tabIndex: 0,
        },
        panes: [],
        Cutoff: null,
        CutoffCompanyId: [],
        Employeelist: [],
        EmployeeIds: [],
        searchQuery: '',
        selectedAll: true,
        initialDtrSummaryLoad: true,
        initialPayrollLoad: true,
        DTRDetails: [],
        DTRSummary: [],
        DTRAdjustment: [],
        Payroll: [],
        ActionableDTRs: [],
        BtnPostContent: "Post",
        Loading: false,
        page: 1,
        showDTRAdjustment: false,
        showActionableDTR: false,
        showUnpostMessagebox: false,
        showPostMessagebox: false,
        showRePostMessagebox: false,
        showEmailNotification: false,
        showEpayslip: false,
        NotificationDialog: false,
        showNotice: false,
        noticeContent: [],
        CompanyName:"",
        isFilterActive: false,

        UserCanPost: false,
        UserCanUnPost: false,
        UserPostDisabled: false,
        UserCanSendEmail: false,
        isMobileView: false,
        isPrintPayrollRegister: false,
        isPrintPayslip: false,
        showPrintPayrollRegister: false,
        showDTRExportExcel: false,
        showADP: false,
        showPayslip: false,
        showRemittance: false,
        showBankSummary: false,
        showBankSummaryPrompt: false,
        showPrintPayrollRegisterPreview: false,
        modalBankVisible: false,
        BankAction: "BANK_REMITTANCE",
        showRequiredField: false,
        errTitle: "",
        errCaption: "",
        isBackClicked: false,
        bgColor: "",
        
    }

    constructor(props) {
        super(props);
        this.state.Cutoff = this.props.Cutoff;
        this.state.CutoffCompanyId = [];
        this.state.CutoffCompanyId.push(this.props.Cutoff.CompanyId);
        this.state.DTRDetails = this.props.DTRDetails;
        this.state.DTRSummary = this.props.DTRSummary;
        this.state.DTRAdjustment = this.props.DTRAdjustment;
        this.state.Parameters.CutoffId = this.state.Cutoff._id;
        this.state.Parameters.EmployeeIds = this.props.initialEmployeeIds;
        this.state.Parameters.Type = this.state.Cutoff.Type;
        this.state.BtnPostContent = this.state.Cutoff.Status !== "PREVIEW" ? "Unpost" : "Post";
        this.ViewControl = React.createRef();
        this.componentRef = React.createRef();
        this.state.searchState = this.props.searchState;
        this.modalBankRef = React.createRef();

    }
    componentWillMount() {
        fnSearchEmployeeFilter("", data => { this.setState({ Employeelist: data }); }, 0, 20, false)
        var canPost = isTaskAvailable("CUTOFF_POST");
        var canUnPost = isTaskAvailable("CUTOFF_UNPOST");
        var canSendEmail = isTaskAvailable("CUTOFF_SEND_EMAIL_NOTIFICATION");
        var showActionableDTR = isTaskAvailable("CUTOFF_DTRACTIONABLES_TAB");
        var postDisabled = this.state.Cutoff.Status === "POSTED" ? canUnPost : canPost;
        var standardCutoff = this.state.Cutoff.Type === "STANDARD" ? true : false;
        loadGroups('',['COMPANY'],(compList)=>{
            var selected = compList.content.filter(x => x._id === this.state.CutoffCompanyId[0]);
            this.state.CompanyName = selected.map(x=>x.Name);
        });
        this.setState({
            UserCanPost: canPost,
            UserCanUnPost: canUnPost,
            UserPostDisabled: postDisabled,
            UserCanSendEmail: canSendEmail,
            showADP: standardCutoff,
            showDTRExportExcel: standardCutoff,
            showActionableDTR
        });
        this.getNotice(data => {
            let notice = [];
            if (data.Errors.length > 0) {
                notice = data.Errors;
                this.setState({ showNotice: true, noticeContent: notice });
            }
        }, this.state.Parameters);
        this.updateToolBar(this.state.searchState.tabIndex);
        window.addEventListener("resize", this.resize.bind(this));
        this.resize();

        var payrollOnly = this.state.Cutoff.Type != null &&
            (this.state.Cutoff.Type !== "STANDARD" && this.state.Cutoff.Type !== "QUIT CLAIM");

        var panes = [];

        if (!payrollOnly) {
            panes.push({
                menuItem: 'DTR SUMMARY', render: () =>
                    <Tab.Pane attached={false}>
                        <DTRSummary ref={this.ViewControl} DTRSummary={this.state.DTRSummary}
                            Parameters={this.state.Parameters}
                            initialLoad={this.state.initialDtrSummaryLoad}
                            updateState={this.updateState.bind(this)} />
                    </Tab.Pane>
            });
        }
        if (!payrollOnly) {
            panes.push({
                menuItem: 'DTR Details', render: () => <Tab.Pane attached={false} >
                    <DTRList ref={this.ViewControl} DTRDetails={this.state.DTRDetails}
                        Parameters={this.state.Parameters}
                        initialLoad={this.state.searchState.initialDTRLoad}
                        updateState={this.updateState.bind(this)}
                    /></Tab.Pane>
            });
            {/*
            if (showActionableDTR) {
                panes.push({
                    menuItem: 'Actionable DTR', render: () => <Tab.Pane attached={false}>
                        <ActionDTR ref={this.ViewControl} ActionableDTRs={this.state.ActionableDTRs}
                            Parameters={this.state.Parameters}
                            initialLoad={this.state.searchState.initialActionableDTR}
                            updateState={this.updateState.bind(this)}
                        ></ActionDTR>
                    </Tab.Pane>
                });
            }
            */}

            if (this.state.showDTRAdjustment) {

                panes.push({
                    menuItem: 'DTR Adjustment', render: () => <Tab.Pane attached={false} >
                        <DTRAdjustment ref={this.ViewControl} DTRAdjustment={this.state.DTRAdjustment}
                            Parameters={this.state.Parameters}
                            initialLoad={this.state.searchState.initialDTRAdjustmentLoad}
                            updateState={this.updateState.bind(this)}
                        ></DTRAdjustment>
                    </Tab.Pane>
                });
            }
        }
        if (!isTaskAvailable("CUTOFF_DTR_RESTRICT_COLUMN")) {
            panes.push({
                menuItem: 'PAYROLL', render: () => <Tab.Pane attached={false} className={modStyles.payroll}>
                    <PayrollList ref={this.ViewControl} Payroll={this.state.Payroll}
                        CutoffType={this.state.Cutoff.Type}
                        Parameters={this.state.Parameters}
                        initialLoad={this.state.initialPayrollLoad}
                        updateState={this.updateState.bind(this)}
                    />
                </Tab.Pane>
            })
        }

        if(this.state.Cutoff.Status === "PREVIEW")
        this.state.bgColor = "rgb(252, 50, 50)";
        else
        this.state.bgColor = "rgb(0, 181, 173)";

        this.setState({ panes: panes });
    }

    componentWillUnmount = () => {
        if (!this.state.isBackClicked)
            this.props.unmountCallback(this.state.Parameters.EmployeeIds);
    }

    resize() {
        this.setState({ isMobileView: window.innerWidth <= 812 });
    }

    onGeneratePayrollRegister = (e) => {
        this.setState({ showPrintPayrollRegisterPreview: true });
    }
    exportToPdf = (api, searchParameters, onSearchFinished, onError, onFinally) => {
        var parameter = {
            data: searchParameters,
            url: api,
            onSuccess: onSearchFinished,
            onError: onError,
            finally: onFinally
        };
        ajaxPost(parameter);
    }
    onExportToExcel = (e) => {
        var indexedTab = ['DTR_SUMMARY', 'DTR_DETAILS', 'DTR_ADJUSTMENT', 'PAYROLL'];
        if (!this.state.showActionableDTR) {
            indexedTab = indexedTab.filter(x => x !== 'DTR_ACTIONABLES');
        }
        if (!this.state.showDTRAdjustment) {
            indexedTab = indexedTab.filter(x => x !== 'DTR_ADJUSTMENT');
        }

        this.setState({ Loading: true });
        var apiUrl = 'api/Cutoff/exportDTRSummaryToExcel';
        var fileName = "";
        if (indexedTab[this.state.searchState.tabIndex] === 'DTR_SUMMARY') {
            apiUrl = 'api/Cutoff/exportDTRSummaryToExcel';
            fileName = "DTR Summary Report.xlsx";
        }
        else if (indexedTab[this.state.searchState.tabIndex] === 'DTR_DETAILS') {
            apiUrl = 'api/Cutoff/exportDTRToExcel';
            fileName = "DTR Detailed Report.xlsx";
        }
        else if (indexedTab[this.state.searchState.tabIndex] === 'PAYROLL') {
            apiUrl = 'api/Cutoff/exportPayrollToExcel';
            fileName = "Payroll Register.xlsx";
        }

        // var needle = this.state.Parameters.needle !=null ? this.state.Parameters.needle : "";
        // var request = global.ApiHost + apiUrl + "?" +
        // "cutoffId=" + this.state.Cutoff._id + "&" +
        // "needle=" + needle + "&" +
        // "sessionId=" + global.sessionId

        // window.location = request;
        this.state.Parameters.sessionId = global.sessionId;
        ajaxPostDownload(
            apiUrl,
            this.state.Parameters,
            fileName,
            (data, sender) => {
                this.setState({ Loading: false });
            },
            (error) => {
                alert("Error:\r\n" + JSON.stringify(error));
            },
            () => {
                // alert("@finally");
                this.setState({ Loading: false });
            }

        )

    }
    /*
    onExportADPToExcel = (e) => {
        this.setState({ Loading: true });
        var apiUrl = 'api/Cutoff/exportDTRSummaryADPToExcel';
        var fileName = "ADP Report.xls";
        this.state.Parameters.sessionId = global.sessionId;
        ajaxPostDownload(
            apiUrl,
            this.state.Parameters,
            fileName,
            (data, sender) => {
                this.setState({ Loading: false });
            },
            (error) => {
                alert("Error:\r\n" + JSON.stringify(error));
            },
            () => {
                // alert("@finally");
                this.setState({ Loading: false });
            }
        )
    }
    */
    getUpdate = (param, self, action) => {
        ajaxPost({
            data: param,
            url: 'api/BackgroundProcess/GetStatus',
            onSuccess: data => {
                if (data.Status === "DONE") {
                    if (action === "GENERATE") {
                        this.populateRows(JSON.parse(data.ResultParameters), self);
                    }
                    else if (action === "GENERATE_PDF") {
                        self.state.reportModel.processPdfData(JSON.parse(data.ResultParameters));
                    }
                    // else if (action == "GENERATE_EXCEL") {
                    //     // var result ={ "data": JSON.parse(data.ResultParameters)};
                    //     self.state.reportModel.exportToExcel(JSON.parse(data.ResultParameters),
                    //         data => { },
                    //         (err) => { },
                    //         () => { }
                    //     );
                    // }
                    this.setState({ loading: false });
                    //remove
                    ajaxPost({
                        data: param,
                        url: 'api/BackgroundProcess/Delete',
                        onSuccess: data => { },
                        onError: (err) => { },
                        finally: () => { }
                    });
                } else {
                    this.getUpdate(param, self, action);
                }
            },
            onError: (error) => {
                alert("Error:\r\n" + JSON.stringify(error));
                this.setState({ loading: false });
            },
            finally: () => {
                // alert("@finally");
            }
        });
    }
    processPdfData = (pdfData) => {
        const { vfs } = vfsFonts.pdfMake;
        PdfMake.vfs = vfs;
        PdfMake.createPdf(pdfData).open();
    }
    onExportBankRemittanceToText = () => {
        if (this.state.Loading) {
            alert("Please wait until the process is done");
            return;
        }

        this.setState({
            BankAction: "BANK_REMITTANCE",
            errCaption: "", errTitle: "",
        }, () => {
            this.setState({ modalBankVisible: true });
        });

    }
    onExportBankSummaryToPdf = () => {
        if (this.state.Loading) {
            alert("Please wait until the process is done");
            return;
        }

        this.setState({ showBankSummaryPrompt: true });
    }
    onExportBankSummaryToExcel = () => {
        if (this.state.Loading) {
            alert("Please wait until the process is done");
            return;
        }

        this.setState({
            BankAction: "BANK_SUMMARY_EXCEL",
            errCaption: "", errTitle: "",
        }, () => {
            this.setState({ modalBankVisible: true });
        });
    }
    onExportPayslipToPdf = () => {
        if (this.state.Loading) {
            alert("Please wait until the process is done.");
            return;
        }
        this.setState({ Loading: true });
        let parameters = this.state.Parameters;
        let api = 'api/Cutoff/exportPayslipToPdf';
        this.exportToPdf(api, parameters,
            (data, sender) => {
                this.processPdfData(data);
                this.setState({ Loading: false });
            },
            (error) => {
                alert("Error:\r\n" + JSON.stringify(error));
                this.setState({ Loading: false });
            },
            () => {
                // alert("@finally");
            })
    };
    onExportToPdf = () => {
        if (this.state.Loading) {
            alert("Please wait until the process is done.");
            return;
        }
        this.setState({ Loading: true });
        let parameters = this.state.Parameters;
        let api ='api/Cutoff/exportPayrollToPdf';
        this.exportToPdf(api, parameters,
            (data, sender) => {
                this.processPdfData(data);
                this.setState({ Loading: false });
            },
            (error) => {
                alert("Error:\r\n" + JSON.stringify(error));
                this.setState({ Loading: false });
            },
            () => {
                // alert("@finally");
            })

    }
    handleNotification = () => {
        this.setState({ showEmailNotification: true });
    }
    handleEPayslip = () => {
        this.setState({ showEpayslip: true });
    }
    handlePost = (e) => {
        //post
        if (this.state.BtnPostContent === "Post") {
            // if(this.state.UserCanPost){
            //     alert("you have no rights to access Posting Cutoff.");
            //     return;
            // }
            this.setState({ showPostMessagebox: true });
        }
        else {
            // if(this.state.UserCanUnPost){
            //     alert("you have no rights to access Un-Post Cutoff.");
            //     return;
            // }
            this.setState({ showUnpostMessagebox: true });
        }
    }

    onViewFilterClick(isOpen) {
        this.setState({ isFilterActive: isOpen });
    }
    advanceFilterCallBack = (advanceFilter, includeDeleted) => {
        let hasAdvFilter = true;
        if ((advanceFilter.companyIds === undefined || advanceFilter.companyIds === null || advanceFilter.companyIds.length === 0) &&
            (advanceFilter.divisionIds === undefined || advanceFilter.divisionIds === null || advanceFilter.divisionIds.length === 0) &&
            (advanceFilter.departmentIds === undefined || advanceFilter.departmentIds === null || advanceFilter.departmentIds.length === 0) &&
            (advanceFilter.locationIds === undefined || advanceFilter.locationIds === null || advanceFilter.locationIds.length === 0) &&
            (advanceFilter.costcenterIds === undefined || advanceFilter.costcenterIds === null || advanceFilter.costcenterIds.length === 0) &&
            (advanceFilter.positionIds === undefined || advanceFilter.positionIds === null || advanceFilter.positionIds.length === 0)){
                hasAdvFilter = false
            } 


        var parameters = this.state.Parameters;
        parameters.advanceFilter = advanceFilter;
        parameters.advanceFilter.includeDeleted = advanceFilter.includeDeletedEmployee;
        parameters.startingIndex = 0;

        this.setState({
            page: 1,
            Parameters: parameters,
            isFilterActive: false,
        });
        this.ViewControl.current.onSearch(parameters);
        this.state.employeeSelectionRefresh("");

    }
    OnNotification = () => {
        this.setState({
            NotificationDialog: true,
            showEpayslip: false
        });

    }
    OnSendNotification = (sending) => {
        this.setState({ NotificationDialog: false });
        if (sending) {
            var cutoffId = this.state.Cutoff._id;
            var employeeIds = this.state.EmployeeIds;
            this.SendEmailNotification(data => {

            }, cutoffId, employeeIds);

        }
       
    }
    OnSendPayslip = (sending) => {
       
        if (sending) {
            if(this.state.EmployeeIds.length === 0 && !this.state.selectedAll){
                alert("Please select employees");
                return;
            }
            var cutoffId = this.state.Cutoff._id;
            var employeeIds = this.state.EmployeeIds;
            this.SendPayslipNofication( cutoffId, employeeIds, () =>{
                this.setState({ NotificationDialog: false });
            });

        }
        this.setState({ NotificationDialog: false });
    }
    OnPost = (repost) => {
        this.setState({ showPostMessagebox: false });
        this.setState({ Loading: true });
        var cutoffId = this.state.Cutoff._id;

        this.Post(data => {
            //check progress'
            var result = data;
            if (result === "POST_PENDING") {
                alert("The previous cutoff is not posted yet.  Cannot proceed with Post");
                return;
            }
            if (result === "POSTING_DONE") {
                alert("The cutoff is posted.  Cannot proceed with Post");
                this.setState({ Loading: false });
                return;
            }
            else if (result === "POSTING_PENDING") {
                this.setState({ showRePostMessagebox: true });
                return;
            }
            else if (result === "FAILED") {
                alert("Something went wrong.  Cannot proceed with Post");
                return;
            }
            var checkProgress = () => {

                this.GetPostingProgress(data => {
                    if (data.Done) {
                        this.setState({ BtnPostContent: "Unpost" });
                        this.setState({ Loading: false });
                        var cutoff = this.state.Cutoff;
                        cutoff.Status = "POSTED";
                        this.setState({ Cutoff: cutoff });
                        alert("Cutoff has been successfully Posted");
                    }
                    else
                        setTimeout(checkProgress, 1000);

                }, cutoffId);
            }
            checkProgress();
        }, cutoffId, repost);
    }
    OnUnPost = () => {
        this.setState({ showUnpostMessagebox: false });
        this.setState({ Loading: true });
        var cutoffId = this.state.Cutoff._id;

        this.UnPost(data => {
            this.setState({ Loading: false });
            if (data === "OK") {
                alert("Cutoff has been successfully  Unpost");
                this.setState({ BtnPostContent: "Post" });
                var cutoff = this.state.Cutoff;
                cutoff.Status = "PREVIEW";
                this.setState({ Cutoff: cutoff });
            }
            else if (data === "NEXT_POSTED")
                alert("The next cutoff is already posted.  Cannot proceed with Unpost");
            else
                alert("Something went wrong.");

        }, cutoffId);
    }
    SendEmailNotification = (callback, cutoffId, employeeIds) => {
        ajaxPost({
            url: 'api/OutBox/sendDTREmailNotification',
            data: {
                "CutoffId": cutoffId,
                "EmployeeIds": employeeIds
            },
            onSuccess: data => {
                alert("Send Email Notification Successfully");
            },
            finally: () => { }
        })
    }
    SendPayslipNofication = (cutoffId, employeeIds, callback) => {
        ajaxPost({
            url: 'api/OutBox/sendPaySlipEmailNotification',
            data: {
                "CutoffId": cutoffId,
                "EmployeeIds": employeeIds
            },
            onSuccess: data => {
                alert("Send Email  Successfully");
                callback();
            },
            finally: () => { }
        })
    }
    GetPostingProgress = (callback, parameters) => {

        ajaxPost({
            url: 'api/Cutoff/getPostingProgress',
            data: {
                "CutoffId": parameters
            },
            onSuccess: data => {
                callback(data);
            },
            finally: () => { }
        })
    }
    Post = (callback, cutoffId, repost) => {

        ajaxPost({
            url: 'api/Cutoff/post',
            data: {
                "CutoffId": cutoffId,
                "Repost": repost
            },
            onSuccess: data => {
                callback(data);
            },
            finally: () => { }
        })
    }
    UnPost = (callback, parameters) => {

        ajaxPost({
            url: 'api/Cutoff/unpost',
            data: {
                "CutoffId": parameters
            },
            onSuccess: data => {
                callback(data);
            },
            finally: () => { }
        })
    }
    getNotice = (callback, parameters) => {

        ajaxPost({
            url: 'api/Cutoff/cutoffNotice',
            data: {
                "Parameters": JSON.stringify(parameters)
            },
            onSuccess: data => {
                callback(data);
            },
            finally: () => { }
        })
    }
    exportBankSummaryToPdf = (param) => {
        this.setState({ Loading: true });
        let parameters = param;
        let api = 'api/Cutoff/exportBankSummaryToPdf';
        this.exportToPdf(api, parameters,
            (data, sender) => {
                this.processPdfData(data);
                this.setState({ Loading: false });
            },
            (error) => {
                alert("Error:\r\n" + JSON.stringify(error));
                this.setState({ Loading: false });
            },
            () => {
                // alert("@finally");
            })
    }
    exportBankRemittanceToText = (param) => {
        this.setState({ Loading: true });
        let parameters = param;
        let api = 'api/Cutoff/exportBankRemittanceToText';

        ajaxPostDownload(api, parameters, "Remittance.txt",
            (data, sender) => {
                //
                this.setState({ Loading: false });
            },
            (error) => {
                alert("Error:\r\n" + JSON.stringify(error));
                this.setState({ Loading: false });
            },
            () => {}
            )
    }
    exportBankSummaryExcel = (param) => {
        this.setState({ Loading: true });
        let parameters = param;
        let api = 'api/Cutoff/exportBankSummaryToExcel';

        ajaxPostDownload(api, parameters, "banksummary.xlsx",
            (data, sender) => {
                //
                this.setState({ Loading: false });
            },
            (error) => {
                alert("Error:\r\n" + JSON.stringify(error));
                this.setState({ Loading: false });
            },
            () => {}
            )
    }
    handleBankSave = () => {
        var content = this.modalBankRef.current.getContent();
        var valid = this.validate(content);
        if (valid === false)
            return;
        if (this.state.BankAction === "BANK_SUMMARY") {

            this.exportBankSummaryToPdf(
                content
            );
        } else if (this.state.BankAction === "BANK_REMITTANCE") {
            this.exportBankRemittanceToText(
                content
            );
        } else if( this.state.BankAction === "BANK_SUMMARY_EXCEL"){
            this.exportBankSummaryExcel(content);
        }
        this.setState({ modalBankVisible: false });

    }

    handleSearchChange = (e, { searchQuery }) =>
        this.setState({ searchQuery: searchQuery }
        );
    handleChangePage = (event, { activePage }) => {
        if (isNaN(activePage))
            return;
        const startIndex = activePage > 0 ? activePage - 1 : 0;
        this.setState({ page: activePage });

        var parameters = this.state.Parameters;
        parameters.startingIndex = startIndex * parameters.itemCount;
        this.setState({ Parameters: parameters });
        this.ViewControl.current.onSearch(parameters);

    }
    updateEmployeeIds = (Ids) => {
        var param = this.state.Parameters;
        param.EmployeeIds = Ids;
        this.setState({ Parameters: param });
        this.ViewControl.current.onSearch(param);
    }

    handleSearchChange = (event) => {
        var str = event.target.value;
        this.setState({ searchQuery: str })
        setTimeout(this.doSearch.bind(this), 200, str, event.target);
    }
    handleSearch = () => {
        var parameters = this.state.Parameters;
        parameters.startingIndex = 0;
        this.setState({
            page: 1,
            Parameters: parameters
        });
        this.ViewControl.current.onSearch(parameters);
    }
    handleComboChange = (name, val) => {
        var current = this.state.Parameters;
        current[val.name] = val.value;
        this.setState({ Parameters: current });
    }
    handleSearchPress = (e) => {
        if (e.key === "Enter") {
            this.handleSearch();
        }
    } 
    handleEmployeeIdsChange = (ids) => { 
        this.setState({ EmployeeIds: ids });
    }
    handleCheckChange = (e) => {
        this.setState({  selectedAll: !this.state.selectedAll,EmployeeIds:[] });
    }
    handleTabChange = (name, { activeIndex }) => {
        this.updateToolBar(activeIndex);
    }
    updateToolBar = (activeIndex) => {
        var indexedTab = ['DTR_SUMMARY', 'DTR_DETAILS', 'DTR_ADJUSTMENT', 'PAYROLL'];
        if (!this.state.showDTRAdjustment) {
            indexedTab = indexedTab.filter(x => x !== 'DTR_ADJUSTMENT');
        }

        if (this.state.Cutoff.Type === "13TH MONTH PAY" || this.state.Cutoff.Type === "OFFCYCLE"
             //|| this.state.Cutoff.Type === "QUIT CLAIM"
        ) {
            this.setState({
                showPayslip: true,
                showPrintPayrollRegister: true,
            });
            activeIndex = 0;
        } else {
            if (indexedTab[activeIndex] === 'PAYROLL') //payroll
            {
                this.setState({
                    showPrintPayrollRegister: true,
                    showPayslip: true,
                    showBankSummary: true,
                    showRemittance: true,
                    showADP: false,
                    showDTRExportExcel: true,
                });
            }
            else if (indexedTab[activeIndex] === 'DTR_SUMMARY') {
                if (this.state.Cutoff.Type === "STANDARD") {
                    this.setState({
                        showADP: true,
                        showDTRExportExcel: true,
                        showPrintPayrollRegister: false,
                        showPayslip: false,
                        showBankSummary: false,
                        showRemittance: false,
                    });
                }
            }
            else if (indexedTab[activeIndex] === 'DTR_DETAILS') {
                if (this.state.Cutoff.Type === "STANDARD") {
                    this.setState({
                        showADP: false,
                        showDTRExportExcel: true,
                        showPrintPayrollRegister: false,
                        showPayslip: false,
                        showBankSummary: false,
                        showRemittance: false,
                    });
                }
            }
            else
                this.setState({
                    showPrintPayrollRegister: false,
                    showPayslip: false,
                    showBankSummary: false,
                    showRemittance: false,
                    showADP: false,
                    showDTRExportExcel: false
                });
        }
        var searchState = this.state.searchState;
        searchState.tabIndex = activeIndex;
        this.setState({ searchState: searchState });
    }
    updateState = (state, control) => {
        this.setState({ Parameters: state.Parameters });
        if (control === "DTRList") {
            var searchstate = this.state.searchState;
            searchstate.initialDTRLoad = state.initialLoad;
            this.setState({ searchState: searchstate });
            this.setState({ DTRDetails: state.DTRDetails });
        }
        if (control === "DTRSummaryList") {
            this.setState({ initialDtrSummaryLoad: state.initialLoad });
            this.setState({ DTRSummary: state.DTRSummary });
        }
        if (control === "DTRAdjustmentList") {
            this.setState({ initialDtrAdjustmentLoad: state.initialLoad });
            this.setState({ DTRAdjustment: state.DTRAdjustment });
        }
        if (control === "PayrollList") {
            this.setState({
                initialPayrollLoad: state.initialPayrollLoad,
                Payroll: state.Payroll
            });
        }

        this.props.onUpdateState(this.state.searchState, this.state.DTRDetails, this.state.DTRSummary, this.state.DTRAdjustment, this.state.Payroll);
    }
    validate = (data) => {
        var errTitle = "", errCaption = "";
        if (this.isNullOrEmpty(data.BankId) || data.BankId === empty_id
            || (data.EffectivityDate !== undefined && this.isNullOrEmpty(data.EffectivityDate))
            || (data.BankLetterDate !== undefined && this.isNullOrEmpty(data.BankLetterDate))
        ) {
            errCaption = "Please fill up all required field/s";
            errTitle = "Invalid";
        }
        else {
            this.setState({ showRequiredField: false });
            return true;
        }
        this.setState({ showRequiredField: true, errTitle, errCaption });
        return false;
    };
    isNullOrEmpty(val) {
        if (val === null || val === undefined || val === "" || val.length === 0) return true;
        else return false;
    }

    onCloseCutoffDetails = () => {
        this.setState({ isBackClicked: true }, this.props.onCloseForm.bind(this, this.state.Cutoff))
    }

    render() {
        return <>
            <div className={modStyles.container}>
                {/* <div style={{ color: "#606060", marginTop: '1%' }}> */}
                <div className={modStyles.payHeader}>
                    <span>Cutoff Detail</span>
                    <label style={{ backgroundColor : this.state.bgColor }}>{this.state.Cutoff.Status}</label>
                </div>
                <div className={modStyles.companyHeader}>
                    <label>({this.state.CompanyName})</label>
                </div>
                <div>
                    <h5>{moment(this.state.Cutoff.DateStart).format("MMMM DD YYYY") + " - " +
                        moment(this.state.Cutoff.DateEnd).format("MMMM DD YYYY")}
                        {/* {this.state.Cutoff.Status == "POSTED" && " (POSTED)"} */}
                        </h5>
                </div>
                <div>
                    <Pagination floated="right" size='mini'
                        defaultActivePage={1}
                        style={{ margin: "0px 0px 10px 0px" }}
                        activePage={this.state.page}
                        pointing
                        secondary
                        siblingRange={2}
                        boundaryRange={0}
                        firstItem={{ content: <Icon name='angle double left' />, icon: true }}
                        lastItem={{ content: <Icon name='angle double right' />, icon: true }}
                        prevItem={{ content: <Icon name='angle left' />, icon: true }}
                        nextItem={{ content: <Icon name='angle right' />, icon: true }}
                        totalPages={this.state.Parameters.totalPages}
                        onPageChange={this.handleChangePage.bind(this)}
                    />

                </div>
                <div className='w3-row' style={{ width: '100%', minHeight: '50px', marginTop: '10px' }} fitted='horizontally'>
                    <div className='w3-col l8' style={{ paddingTop: '10px' }}>
                        <Button size="mini" icon="arrow alternate circle left" content="Back"
                            onClick={this.onCloseCutoffDetails.bind(this)} />
                        {this.state.UserPostDisabled && <Button size="mini" icon="save" content={this.state.BtnPostContent}
                            disabled={this.state.Loading} 
                            onClick={this.handlePost.bind(this)} />
                        }
                        {this.state.showDTRExportExcel && <Button size="mini" icon="file excel online" content="Export to Excel"
                            onClick={this.onExportToExcel.bind(this)} disabled={this.state.Loading} />}
                        {(this.state.showPrintPayrollRegister && this.state.Cutoff.Status === "POSTED")  && <Button size='mini' icon='file pdf' content="Payroll Register" onClick={this.onExportToPdf.bind(this)}
                            disabled={this.state.Loading} />}
                        {this.state.showPayslip && <Button size='mini' icon='file pdf' content="Payslip" onClick={this.onExportPayslipToPdf.bind(this)}
                            disabled={this.state.Loading} />}
                        {(this.state.showPayslip && this.state.Cutoff.Status === "POSTED") && <Button size='mini' icon='file pdf' content="E-Payslip" onClick={this.handleEPayslip.bind(this)}
                            disabled={this.state.Loading} />}
                        {this.state.showRemittance &&
                            <Button size='mini' icon='file text' content="Remittance" onClick={this.onExportBankRemittanceToText.bind(this)}
                                disabled={this.state.Loading} />}
                        {this.state.showBankSummary &&
                            <Button size='mini' icon='file pdf' content="Pdf Bank Summary" onClick={this.onExportBankSummaryToPdf.bind(this)}
                                disabled={this.state.Loading} />}
                        {this.state.showBankSummary &&
                            <Button size='mini' icon='file excel' content="Excel Bank Summary" onClick={this.onExportBankSummaryToExcel.bind(this)}
                                disabled={this.state.Loading} />}
                    </div>
                    <div className='w3-col l4' fitted='horizontally'>
                        <div className='w3-col l1' style={{ paddingTop: '10px', paddingRight: '10px' }}>
                            <Button style={{ width: '100%', 'max-height': '36px', height: '36px' }} size="mini" icon="filter"
                                onClick={this.onViewFilterClick.bind(this, true)}
                            />
                        </div>
                        <div className='w3-col l9' style={{ paddingTop: '10px' }}>
                            <div style={{ width: '100%', marginRight: 3, height: 3 }}>
                                <CutoffEmployeeSelection
                                    update={this.updateEmployeeIds.bind(this)}
                                    companyId={this.state.Cutoff.CompanyId}
                                    cutoffId={this.state.Cutoff._id}
                                    type={this.state.Cutoff.Type}
                                    status={this.state.Cutoff.Status}
                                    departmentIds={this.state.Parameters.advanceFilter.departmentIds}
                                    locationIds={this.state.Parameters.advanceFilter.locationIds}
                                    positionIds={this.state.Parameters.advanceFilter.positionIds}
                                    costcenterIds={this.state.Parameters.advanceFilter.costcenterIds}
                                    refreshSelection={(refreshFunction) => this.setState({ employeeSelectionRefresh: refreshFunction })}
                                    style={{ width: this.state.isMobileView ? '100%' : '250px', maxWidth: '300px', minWidth: '50px', marginRight: '3px' }} />
                            </div>
                        </div>
                        <div className='w3-col l2' style={{ paddingTop: '10px' }}>
                            <Button fluid content="search" size="mini" style={{ height: 36 }}
                                onClick={this.handleSearch.bind(this)} />
                        </div>
                    </div>
                </div>
                {
                    this.state.showBankSummaryPrompt &&
                    <MessageBoxOkOnly title="Bank Summary"
                        action="OK"
                        onClick={() => {
                            this.setState({
                                showBankSummaryPrompt: false,
                                BankAction: "BANK_SUMMARY",
                                errCaption: "", errTitle: ""
                            },
                                () => {
                                    this.setState({ modalBankVisible: true });
                                });
                        }}
                        caption={"Export Bank Summary is available for the following banks:\n* Bank of the Philippine Island\n* Metropolitan Bank and Trust Company"}
                    />
                }

                <Loader active={this.state.Loading} inline='centered' />
                <Tab className={modStyles.tabContainer} menu={{ secondary: true, pointing: true }} panes={this.state.panes} activeIndex={this.state.searchState.tabIndex} onTabChange={this.handleTabChange.bind(this)} />
            </div>
            {this.state.showUnpostMessagebox && <MessageBoxYesNo title="UnPost Cutoff"
                caption="You are about to Unpost this CUTOFF.  Do you wish to proceed?" onAction={this.OnUnPost.bind(this)} onClose={() => { this.setState({ showUnpostMessagebox: false }) }} action="Unpost" />
            }
            {this.state.showPostMessagebox && <MessageBoxYesNo title="Post Cutoff"
                caption="You are about to Post this CUTOFF.  Do you wish to proceed?" onAction={this.OnPost.bind(this, false)} onClose={() => { this.setState({ showPostMessagebox: false }) }} action="Post" />
            }
            {this.state.showRePostMessagebox && <MessageBoxYesNo title="Post Cutoff"
                caption="There is a cutoff currently posting.  Do you wish to proceed?" onAction={this.OnPost.bind(this, true)} onClose={() => { this.setState({ showPostMessagebox: false }) }} action="Post" />
            } 
            {this.state.showEpayslip && <MessageBoxYesNo title="Send Email Paylip"
                caption="Your are about to Send Payslip Email. Please ensure that the Email of the Employee is correct or not empty. Do you wish to proceed?"
                 onAction={this.OnNotification.bind(this)} 
                 onClose={() => this.setState({ showEpayslip: false })} action="Continue" />
            }
            {this.state.modalBankVisible &&
                <BankForm
                    ref={this.modalBankRef}
                    open={this.state.modalBankVisible}
                    errTitle={this.state.errTitle}
                    errCaption={this.state.errCaption}
                    showRequiredField={this.state.showRequiredField}
                    CutoffId={this.state.Parameters.CutoffId}
                    action={this.state.BankAction}
                    isBusy={this.state.isSaving}
                    onCancel={() => { this.setState({ modalBankVisible: false }) }}
                    onSave={this.handleBankSave.bind(this)}
                />
            }
            <GroupFilterModal
                advFilter={this.state.Parameters.advanceFilter}
                hideFilter={["COMPANY", "EMPLOYEE"]}
                open={this.state.isFilterActive}
                removeStatusFilter={true}
                onCancel={this.onViewFilterClick.bind(this, false)}
                callback={this.advanceFilterCallBack.bind(this)}
            />
            {this.state.NotificationDialog &&
            <Modal open={this.state.NotificationDialog} size="tiny">
                <Header content="Send Email Notification" />
                <Modal.Content>
                    <Form>
                        <Form.Field>
                            <label>Employee</label>
                            <CutoffEmployeeSelection
                                    update={this.handleEmployeeIdsChange.bind(this)}
                                    companyId={this.state.Cutoff.CompanyId}
                                    cutoffId={this.state.Cutoff._id}
                                    type={this.state.Cutoff.Type}
                                    status={this.state.Cutoff.Status} 
                                    refreshSelection={(refreshFunction) => this.setState({ employeeSelectionRefresh: refreshFunction })}
                                    style={{ width: this.state.isMobileView ? '100%' : '250px', maxWidth: '300px', minWidth: '50px', marginRight: '3px' }} 
                                    disabled={this.state.selectedAll}

                             /> 
                             <Checkbox size='mini' label="Select All" onChange={this.handleCheckChange.bind(this)} checked={this.state.selectedAll} />
                        </Form.Field>

                    </Form>
                </Modal.Content>
                <Modal.Actions>
                    <Button basic icon="send" content="Send"
                        onClick={this.OnSendPayslip.bind(this, true)}
                    />
                    <Button basic icon="cancel" content="Cancel"
                        onClick={this.OnSendPayslip.bind(this, false)}
                    />
                </Modal.Actions>
            </Modal>
            }
            {this.state.showNotice &&
                <CutoffNotice title="Cutoff Notice"
                    onClose={() => this.setState({ showNotice: false })}
                    content={this.state.noticeContent} />}


        </>
    }
}
export default CutoffDetail
import PdfMake from 'pdfmake/build/pdfmake';
import vfsFonts from 'pdfmake/build/vfs_fonts';
import moment from 'moment';

export default (data) => {
    const { vfs } = vfsFonts.pdfMake;
    PdfMake.vfs = vfs;

    var logs = [];

    data.forEach(e => {
        var log = [];
        log.push(e.EmployeeNo);
        log.push(e.EmployeeName);
        log.push(moment(e.Date).format("YYYY-MM-DD"));
        log.push(e.FirstIn);
        log.push(e.LastOut);
        logs.push(log);
    });
    const pdfDoc = {
        pageSize: 'letter',
        pageOrientation: 'portrait',
        content: [
            {
                text: [
                    { text: "Daily Time Record" }, '\n', '\n',
                ]
            },
            {
                width: "*",
                layout: 'noBorders',
                table: {
                    headerRows: 1,
                    widths: [100, 200, 70, 70, 70],
                    body: [
                        [
                            {
                                text: "Employee No",
                                bold: true,
                            },
                            {
                                text: "Employee",
                                bold: true,
                            },
                            {
                                text: "Date",
                                bold: true,
                            },
                            {
                                text: "First In",
                                bold: true,
                            },
                            {
                                text: "Last Out",
                                bold: true,
                            }
                        ],
                    ]
                }
            },
            // line break
            {
                layout: 'headerLineOnly',
                table: {
                    headerRows: 1,
                    widths: ["*"],
                    body: [
                        [''],
                        [''],
                        [''],
                        [''],
                    ]
                },
            },
            {
                width: "*",
                layout: 'noBorders',
                table: {
                    widths: [100, 200, 70, 70, 70],
                    body: logs,
                }
            },
        ]

    };
    PdfMake.createPdf(pdfDoc).open();
}
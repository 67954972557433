import React, { Component } from 'react';
import 'semantic-ui-css/semantic.min.css';
import { Popup, List, Divider, Segment, Loader, Header, Menu, Button, Icon, Pagination, Input, Modal, Checkbox, Dropdown, Card, Grid, GridColumn } from 'semantic-ui-react';
import MessageBoxYesNo from '../../Commons/MessageBoxYesNo';
import MessageBoxOkOnly from '../../Commons/MessageBoxOkOnly';
import PropTypes from 'prop-types';
import { ajaxPost } from '../../ajax';
import { fnSearchEmployee, isTaskAvailable, ajaxPostDownload, checkDateRange, minDate, maxDate, checkDate, empty_id } from '../../utils';
import SelectEmployees from '../../Commons/SelectEmployees';
import _ from 'lodash';
import { isNullOrEmpty } from '../../utils';
import modStyles from './PayrollTransactionBase.module.css';

const fileDialog = require('file-dialog');

// import update from 'immutability-helper';
// import CardRenderer from '../../TA/Transactions/Commons/CardRenderer';

const moment = require('moment');
const allEmployeeAccessString = "VIEW_ALL_EMPLOYEES";
var delayTimer = null;

class PayrollTransactionBase extends Component {

    // constructor(props) {
    //     super(props);

    //     if (this.props.props.prevState !== undefined) {
    //         this.setState(
    //             this.props.props.prevState
    //         );
    //     }
    // }

    componentWillMount() {

        window.addEventListener('resize', this.handleResize);
        this.handleResize = this.handleResize.bind(this);

        if (this.props.props.prevState !== undefined) {
            this.state = this.props.props.prevState;
        }
        else {
            this.state = {
                userId: null,
                hasHrAccess: isTaskAvailable(allEmployeeAccessString),

                isLoading: false,
                isSaving: false,

                activePage: 1,
                totalPages: 0,
                itemsPerPage: 20,
                gridHeight: window.innerHeight - 250,

                cards: [],
                selectedId: "",

                showNewButton: true,
                showDeleteButton: false,
                showRestoreButton: false,
                showEditButton: false,
                showExportButton: false,

                hideSearchToolbar: false,

                showRestoreModal: false,
                showDeleteModal: false,

                filters: {},
                showFiltersModal: false,
                includeDeleted: false,
                filterPrevState: [],

                messageBoxOkOnly: {
                    show: false,
                    title: "",
                    caption: "",
                },
                // isMobileView: this.isMobileView(),
                showRequiredField: false,
                errTitle: "",
                errCaption: "",

                queryString: "",
            };

            this.search();
        }

    }

    isMobileView() {
        var isMobile = window.innerWidth <= 760;
        return isMobile;
    }

    downloadTemplate = () => {
        if (this.state.exportTemplateLoading)
            return;
        this.setState({ exportTemplateLoading: true })
        window.location = global.ApiHost + this.props.urls.downloadTemplate;
        this.setState({ exportTemplateLoading: false })

    }

    importFromExcel = () => { // originally use for payroll adjustment
        if (this.state.importLoading)
            return;

        fileDialog().then(file => {
            const data = new FormData();
            data.append('file', file[0]);
            data.append('RequestClass', JSON.stringify({
                SessionKey: 'HRIS_SessionId',
                SessionId: global.sessionId
            }
            )
            );
            this.setState({ importLoading: true });
            fetch(global.ApiHost + this.props.urls.importFromExcel, {
                method: 'POST',
                credentials: 'include',
                body: data
            }).then((resp) => {
                this.setState({ importLoading: false });
                return resp.json();
            }).then((json) => {
                var data = JSON.parse(json.JsonData);
                var hasErrors = data.hasOwnProperty("errors") && data.errors.length > 0;
                // TODO alert(msg);
                if (data.errors.length > 0) {
                    this.messageBoxOkOnlyOpen(true, "Error", data.errors.map(err => { return (<span>{err}<br /></span>) }));
                }
                else {
                    this.messageBoxOkOnlyOpen(true, "Import " + this.props.title, "Data successfully imported");
                }
                this.setState({ importLoading: false });
            })
        });
    }

    getUserId = () => {
        var parameter = {
            data: {},
            url: "api/TransactionsCommon/getUserId",
            onSuccess: (data, sender) => {
                this.setState({ userId: data });
            },
            onError: (error) => {
                let errorMessage = "An error has occured.";
                if (error) {
                    errorMessage += "\r\n" + JSON.stringify(error);
                }
                this.messageBoxOkOnlyOpen(true, "Error", errorMessage);
            },
            finally: () => {

            }
        }
        ajaxPost(parameter);
    }

    componentDidMount() {
        // this.getUserId();
        this.handleResize();
    }

    componentWillUnmount() {
        this.props.props.unmountCallback(this.state, this.props.viewID);
        window.removeEventListener('resize', this.handleResize);
    }

    handleResize = () => {
        // this.setState({ isMobileView: this.isMobileView() });
        if (document.getElementById("table") === null) {
            return;
        }
        let headerMargin = 50;
        let footerMargin = 35;
        let gridTop = (document.getElementById("table")).offsetTop;
        var gridHeight = window.innerHeight - (headerMargin + gridTop + footerMargin);
        this.setState({ gridHeight });
    }

    updateButtonsVisibility = (optionalMethod = () => { }) => {

        this.setState({
            // showNewButton: true,
            // showDeleteButton: isTaskAvailable(this.props.userRightsKeys.delete) && this.state.selectedCardIds.length > 0 && deletableItemsCount > 0,
            // showEditButton: isTaskAvailable(this.props.userRightsKeys.edit) && this.state.selectedCardIds.length === 1,
            // hideSearchToolbar: false,
        },
            optionalMethod
        );
    };

    search = () => {
        this.setState({ isLoading: true });
        // let ids = this.state.selectedIds.slice();
        var parameter = {
            // ids: ids,
            queryString: this.state.queryString,
            includeDeleted: this.state.includeDeleted,
            startIndex: (this.state.activePage - 1) * this.state.itemsPerPage,
            itemCount: this.state.itemsPerPage
        };
        var searchParameter = {
            data: parameter,
            url: this.props.urls.search,
            onError: (error) => {
                var errMessage = "An error has occured";
                if (error) {
                    errMessage += "\r\n" + JSON.stringify(error);
                }
                this.messageBoxOkOnlyOpen(true, "Error", errMessage);
            },
            onSuccess: (data, sender) => {
                var cards = data.content.map((model, index, array) => { return this.props.toCard(model) });
                var totalPages = data.count / this.state.itemsPerPage;
                totalPages -= totalPages % 1;
                if (data.count > totalPages * this.state.itemsPerPage) {
                    totalPages++;
                }
                this.setState(
                    {
                        cards: cards,
                        totalPages: totalPages,
                        selectedIndices: [],
                        selectedCardIds: [],
                    },
                    () => { this.updateButtonsVisibility(); }
                );
            },
            finally: () => {
                this.setState({ isLoading: false });
            }
        };
        ajaxPost(searchParameter);
    }

    messageBoxOkOnlyOpen = (isOpen, title = "", caption = "") => {
        var messageBoxOkOnly = {
            show: isOpen,
            title: title,
            caption: caption,
        };
        this.setState({ messageBoxOkOnly });
    }

    btnSearch_click = () => {
        this.setState({ activePage: 1 }, () => { this.search() });
    };

    btnAdd_click = () => {
        // this.loadData("");
        this.props.showModal(null, true);
    }

    btnEdit_Click = (_id, event, { target }) => {
        this.loadData(_id);
    };

    loadData = (_id) => {
        var requestParameter = {
            data: { "_id": _id },
            url: this.props.urls.load,
            onSuccess: (data, sender) => {
                this.setState({ showRequiredField: false },
                    () => { this.props.showModal(data, true); }
                );
            },
            onError: (error) => {
                this.messageBoxOkOnlyOpen(true, "Error", "Failed to load data.")
            },
            finally: () => { }
        };
        ajaxPost(requestParameter);
    }

    saveFromModal = (data) => {
        let self = this;
        let isValid = false;

        isValid = this.props.validate(data);
        if (!isValid) {
            return;
        }

        this.setState({ isSaving: true });
        let addNew = isNullOrEmpty(data._id) || data._id == empty_id;

        var parameter = {
            data: {
                model: JSON.stringify(data)
            },
            url: this.props.urls.save,
            onSuccess: (data, sender) => {
                if (data.hasOwnProperty("errors") && data.errors.length > 0) {
                    isValid = false;
                    self.setState({
                        showRequiredField: true,
                        errTitle: "Invalid",
                        errCaption: data.errors.map(err => { return (<p>{err}</p>) })
                    }, () => {
                        self.messageBoxOkOnlyOpen(true, "Failed", "Data not successfully saved");
                    });
                    return;
                }
                isValid = true;

                let cards = self.state.cards.slice();
                if (addNew) {
                    cards.push(self.props.toCard(data.content));
                }
                else {
                    let selectedIndex = -1;
                    let selectedCard = cards.filter(r => r._id === data.content._id)[0];
                    selectedIndex = cards.indexOf(selectedCard);
                    cards[selectedIndex] = self.props.toCard(data.content);
                }

                self.setState({
                    cards: cards,
                    selectedCardIds: [],
                    selectedIndices: [],
                },
                    () => {
                        this.updateButtonsVisibility();
                        self.messageBoxOkOnlyOpen(true, "Success", "Data successfully saved");
                    }
                );
            },
            onError: (error) => {
                isValid = false;
                var errorMessage = "An error has occured";
                if (error) {
                    errorMessage += ":\r\n" + JSON.stringify(error);
                }
                this.setState({ showRequiredField: true, errTitle: "Error", errCaption: errorMessage });
            },
            finally: () => {
                this.setState({ isSaving: false });
                // close modal 
                if (isValid) self.props.showModal({}, false);
            }
        }
        ajaxPost(parameter);
    }

    btnDelete_Click = (card, event) => {
        var requestParameter = {
            data: { "_id": card._id },
            url: this.props.urls.load,
            onSuccess: (data, sender) => {
                this.setState({
                    showDeleteModal: !card.deleted,
                    showRestoreModal: card.deleted,
                    selectedId: card._id
                });
            },
            onError: (error) => {
                this.messageBoxOkOnlyOpen(true, "Error", "Record doesn't exist");
                this.search();
            },
            finally: () => { }
        };
        ajaxPost(requestParameter);
    }

    btnRestore_Click = (event, { item }) => {
    }

    setDeleted = (deleted) => {
        var self = this;
        var parameter = {
            data: {
                "_id": this.state.selectedId,
                "IsDeleted": deleted,
            },
            url: self.props.urls.setDeleted,
            onError: function (error) {
                var errMessage = "An error has occured";
                if (error) {
                    errMessage += "\r\n" + JSON.stringify(error);
                }
                self.setState({ showRestoreModal: false, showDeleteModal: false },
                    () => { self.messageBoxOkOnlyOpen(true, "Error", errMessage); }
                );
            },
            onSuccess: (data, sender) => {
                // if (data.hasOwnProperty("errors") && data.errors.length > 0) {
                //     self.setState({ showRestoreModal: false, showDeleteModal: false },
                //         () => { self.messageBoxOkOnlyOpen(true, "Error", data.errors.map(err => { return (<p>{err}</p>) })); }
                //     );
                // }
                // else if(data.hasOwnProperty("content") && data.content.IsSuccessful) {
                var cards = self.state.cards.slice();
                cards = cards.filter(card => card._id != self.state.selectedId).slice();
                self.setState({
                    cards,
                    selectedId: "",
                    showRestoreModal: false,
                    showDeleteModal: false
                },
                    function () { self.updateButtonsVisibility() }
                );
                // }
            },
            finally: function () { }
        };
        ajaxPost(parameter);
    };

    handlePageChange(event, { activePage }) {
        var self = this;
        if (activePage === this.state.activePage || activePage < 1) return;
        var page = activePage - (activePage % 1);
        if (page < activePage) {
            page++;
        }
        this.setState({ activePage: page }, function () {
            self.search();
        });;
    }

    onExportToExcel = () => {
        this.props.exportToExcel(this.state.userId);
    }

    exportToExcel = () => {
        if (this.state.cards.length === 0) return;
        this.setState({ isLoading: true });
        let parameter = {
            includeDeleted: this.state.includeDeleted,
            requestorId: this.state.userId,
        }
        let title = this.props.title;
        let titleSingular = title === 'Official Businesses' ? title.substring(0, title.length - 2) : title.substring(0, title.length - 1);
        ajaxPostDownload(
            this.props.urls.exportToExcel,
            parameter,
            titleSingular + " Requests.xlsx", // title
            () => {
                //this.messageBoxOkOnlyOpen(true, "Success", "Export successful");
            },
            (error) => {
                var errorMessage = "An error has occurred";
                if (error) {
                    let errorData = JSON.stringify(error);
                    errorMessage += errorData !== "{}" ? "\r\n" + errorData : "";
                }
                this.messageBoxOkOnlyOpen(true, "Error", errorMessage);
            },
            () => {
                this.setState({ isLoading: false });
            }
        )
    }

    handlePopup(event, { taggedId }) {
        var name = "open" + taggedId;
        // w3-hide-small	Hide content on small screens (less than 601px)
        // w3-hide-medium	Hide content on medium screens
        // w3-hide-large	Hide content on large screens (larger than 992px)
        this.setState({
            [name]:
                event.type === "mouseenter" && window.innerWidth < 992
        });
    }

    render() {
        let self = this;
        let userRightsKeys = this.props.userRightsKeys;
        let actionableCount =
            Number(isTaskAvailable(userRightsKeys.create)) +
            Number(isTaskAvailable(userRightsKeys.export)) +
            Number(isTaskAvailable(userRightsKeys.import)) +
            Number(isTaskAvailable(userRightsKeys.downloadTemplate));
        // let actionableCount = 1;
        let actionableCountRatio = (1 / actionableCount).toFixed(2) + " %";
        let fieldHeader = this.props.fields.find(x => x.valueAs === 'header');
        let hasFieldDescription = this.props.fields.findIndex(x => x.valueAs === 'description') > 0;
        let fieldDescription = hasFieldDescription ? this.props.fields.find(x => x.valueAs === 'description') : {};
        let hasContent = this.props.fields.filter(x => x.valueAs === 'content').length > 0;

        return <>
            <div className={modStyles.module}>
                <div style={{ color: "#606060", marginTop: '1%' }}>
                    <h2>{this.props.title}</h2>
                </div>
                {/* <Header as='h2'>{this.props.title}</Header> */}
                <Pagination
                    style={{ margin: "0px 0px 10px 0px" }}
                    activePage={this.state.activePage}
                    defaultActivePage={1}
                    pointing
                    secondary
                    //ellipsisItem={{ content: <Icon name='ellipsis horizontal' />, icon: true }}
                    firstItem={{ content: <Icon name='angle double left' />, icon: true }}
                    lastItem={{ content: <Icon name='angle double right' />, icon: true }}
                    prevItem={{ content: <Icon name='angle left' />, icon: true }}
                    nextItem={{ content: <Icon name='angle right' />, icon: true }}
                    size='mini'
                    siblingRange={2}
                    boundaryRange={0}
                    totalPages={this.state.totalPages}
                    onPageChange={this.handlePageChange.bind(this)}
                    floated='right'
                />

                <div>
                    <div className='w3-row' style={{ width: '100%' }} fitted='horizontally'>
                        <div className='w3-col l3'>
                            <table style={{ width: (actionableCount * actionableCountRatio) }}>
                                <tr >
                                    {isTaskAvailable(userRightsKeys.create) &&
                                        <td style={{ width: actionableCountRatio }}>
                                            <Button
                                                className='w3-hide-small w3-hide-medium'
                                                size="mini"
                                                icon="add"
                                                iconPosition="top"
                                                content="Create New"
                                                disabled={this.state.UserCanAdd}
                                                onClick={this.btnAdd_click}
                                            />
                                            <Button
                                                className='w3-hide-large'
                                                size="mini"
                                                iconPosition="top"
                                                icon="plus"
                                                content='Create New'
                                                fluid
                                                onClick={this.btnAdd_click}
                                            />
                                        </td>
                                    }


                                    {isTaskAvailable(userRightsKeys.export) &&
                                        <td style={{ width: actionableCountRatio }}>
                                            <Popup className='w3-hide-small w3-hide-medium' trigger={
                                                <Button
                                                    className='w3-hide-small w3-hide-medium'
                                                    fluid
                                                    size="mini"
                                                    iconPosition="top"
                                                    icon="file excel outline"
                                                    onClick={this.exportToExcel}
                                                    disabled={this.state.cards.length === 0}
                                                />
                                            } inverted content='Export to Excel' />
                                            <Button
                                                className='w3-hide-large'
                                                fluid
                                                size="mini"
                                                iconPosition="top"
                                                icon="file excel outline"
                                                content='Export to Excel'
                                                onClick={this.exportToExcel}
                                                disabled={this.state.cards.length === 0}
                                            />
                                        </td>
                                    }

                                    {isTaskAvailable(userRightsKeys.import) &&
                                        <td style={{ width: actionableCountRatio }}>
                                            <Button
                                                className='w3-hide-small w3-hide-medium'
                                                size="mini"
                                                icon="file excel outline"
                                                iconPosition="top"
                                                content="Import"
                                                loading={this.state.importLoading}
                                                disabled={this.state.UserCanAdd}
                                                onClick={this.importFromExcel}
                                            />
                                            <Button
                                                className='w3-hide-large'
                                                size="mini"
                                                iconPosition="top"
                                                icon="file excel outline"
                                                content='Import'
                                                loading={this.state.importLoading}
                                                fluid
                                                onClick={this.importFromExcel}
                                            />
                                        </td>
                                    }
                                    {isTaskAvailable(userRightsKeys.downloadTemplate) &&
                                        <td style={{ width: actionableCountRatio }}>
                                            <Button
                                                className='w3-hide-small w3-hide-medium'
                                                size="mini"
                                                icon="file excel outline"
                                                iconPosition="top"
                                                content="Download"
                                                disabled={this.state.UserCanAdd}
                                                onClick={this.downloadTemplate}
                                            />
                                            <Button
                                                className='w3-hide-large'
                                                size="mini"
                                                iconPosition="top"
                                                icon="file excel outline"
                                                content='Download'
                                                fluid
                                                onClick={this.downloadTemplate}
                                            />
                                        </td>
                                    }
                                </tr>
                            </table>
                        </div>

                        <div className='w3-hide-small w3-hide-medium w3-col l5' fitted='horizontally'>
                            &nbsp;
                        </div>
                        <div className='w3-col l4'>
                            {/* 
                                // TODO, add filtration here
                                {this.state.filters} 
                            */}
                            <div className={'w3-hide-small w3-hide-medium w3-col l10'} style={{ paddingTop: '10px', paddingLeft: '3px', paddingRight: '3px' }} fitted='horizontally'>
                                <Menu secondary size='mini' style={{ width: '100%' }}>
                                    <div style={{ width: '100%' }}>
                                        <Input
                                            value={this.state.queryString}
                                            onChange={(event, data) => { this.setState({ queryString: data.value }) }}
                                            style={{ minWidth: '50px', maxWidth: '100%', width: '100%' }}
                                        />
                                    </div>
                                </Menu>
                            </div>
                            <div className='w3-hide-small w3-hide-medium w3-col l2' style={{ paddingTop: '10px' }} >
                                <Button floated='left' size='mini' fluid onClick={this.btnSearch_click} style={{ paddingLeft: '3px', paddingRight: '3px' }}> Search</Button>
                            </div>
                            <div className='w3-hide-large w3-col l10' style={{ paddingTop: '10px', paddingLeft: '3px', paddingRight: '3px' }} fitted='horizontally'>
                                <table style={{ width: '100%' }}>
                                    <tr>
                                        <td style={{ width: '90%' }}>
                                            <Menu secondary size='mini' style={{ width: '100%' }}>
                                                <div style={{ width: '100%' }}>
                                                    <Input
                                                        value={this.state.queryString}
                                                        onChange={(event, data) => { this.setState({ queryString: data.value }) }}
                                                        style={{ minWidth: '50px', maxWidth: '100%', width: '100%' }}
                                                    />
                                                </div>
                                            </Menu>
                                        </td>
                                        <td style={{ width: '10%' }}>
                                            <Button className='w3-hide-medium w3-hide-large' fluid size='mini' icon='search' type='submit' onClick={this.btnSearch_click} />
                                            <Button className='w3-hide-small' fluid size='mini' type='submit' style={{ 'max-height': '31px', marginLeft: '6px' }} onClick={this.btnSearch_click}> Search</Button>
                                        </td>
                                    </tr>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
                <Divider />
                <Loader active={this.state.isLoading} />
                <div>
                    <Card.Group>
                        {
                            this.state.cards != null && this.state.cards.map(card => {
                                return (
                                    <Card color='olive'>
                                        <Card.Content>
                                            <Card.Header>{card[fieldHeader.key]}</Card.Header>
                                            {
                                                hasFieldDescription &&
                                                <Card.Meta>{card[fieldDescription.key]}</Card.Meta>
                                            }
                                        </Card.Content>
                                        {
                                            hasContent &&
                                            <Card.Content>
                                                {this.props.fields.filter(field => field.valueAs === 'content').map(field => {
                                                    return (
                                                        <span>
                                                            {field.name}                {/* Name/Caption */}
                                                            {field.name ? ": " : ""}    {/* Semi Colon and space is optional */}
                                                            {card[field.key]}           {/* Content */}
                                                            <br />
                                                        </span>
                                                    )
                                                })}
                                            </Card.Content>
                                        }
                                        <Card.Content extra>
                                            <List divided horizontal link>
                                                <List.Item as="a" disabled={!card.editable || !isTaskAvailable(userRightsKeys.edit)} onClick={this.btnEdit_Click.bind(this, card._id)}>[Edit]</List.Item>
                                                <List.Item as="a" disabled={!card.editable || !isTaskAvailable(userRightsKeys.delete)} onClick={this.btnDelete_Click.bind(this, card)}>[Delete]</List.Item>
                                            </List>
                                        </Card.Content>
                                    </Card>
                                )
                            })}
                    </Card.Group>
                </div>
                {this.props.children}
            </div>
            <Modal open={this.state.showFiltersModal} size="mini">
                <Modal.Header>
                    Search Filters
                </Modal.Header>
                <Modal.Content>
                    <div>
                        {/* { isTaskAvailable(this.props.userRightsKeys.delete) &&
                            <div class="ui grid">
                                <div class="sixteen wide column">
                                    <Checkbox checked={this.state.includeDeleted} tag="includeDeleted" onChange={() => { this.setState({ includeDeleted: !this.state.includeDeleted }); }} label='Show deleted records' />
                                </div>
                            </div>
                            } */}
                        <div class="ui grid">
                            <div class="sixteen wide column">
                                <p>Filter Here</p>
                            </div>
                            <div class="left floated eight wide column">
                                <Checkbox onChange={() => { }} label='Filter 1' />
                            </div>
                            <div class="left floated eight wide column">
                                <Checkbox onChange={() => { }} label='Filter 2' />
                            </div>
                        </div>
                    </div>
                </Modal.Content>
                <Modal.Actions>
                    <Button basic icon='save' content="Save" onClick={() => { this.setState({ showFiltersModal: false }, this.search()) }}></Button>
                    <Button basic icon='cancel' content="Cancel" onClick={this.cancelFilter} />
                </Modal.Actions>
            </Modal>

            {this.state.showDeleteModal &&
                <MessageBoxYesNo
                    title="Delete"
                    caption={"Delete selected item?"}
                    action="Yes"
                    CancelCaption="No"
                    onAction={() => { this.setDeleted(true) }}
                    onClose={() => { this.setState({ showDeleteModal: false }) }} />
            }

            {this.state.showRestoreModal &&
                <MessageBoxYesNo
                    title="Restore"
                    caption={"Restore selected item?"}
                    action="Yes"
                    CancelCaption="No"
                    onAction={() => { this.setDeleted(false) }}
                    onClose={() => { this.setState({ showRestoreModal: false }) }} />
            }
            {this.state.messageBoxOkOnly.show &&
                <MessageBoxOkOnly
                    title={this.state.messageBoxOkOnly.title}
                    caption={this.state.messageBoxOkOnly.caption}
                    onClick={() => { this.messageBoxOkOnlyOpen(false); }}
                />
            }


        </>
    }

}

PayrollTransactionBase.propTypes = {
    viewID: PropTypes.string,
    title: PropTypes.string,
    toCard: PropTypes.func,
    toModel: PropTypes.func,
    fields: PropTypes.func,
    urls: PropTypes.func,
    validate: PropTypes.func,
    props: PropTypes.object,
    showModal: PropTypes.func,
    userRightsKeys: PropTypes.func,
    exportToExcel: PropTypes.func
}

export default PayrollTransactionBase;
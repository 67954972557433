import React, {useEffect, useState} from 'react';
import { render } from 'react-dom';
import { Image, Icon } from 'semantic-ui-react';
import { ajaxGet } from '../ajax';
import { relative } from 'path';

const crypto = require("crypto");


class GroupImage extends React.Component 
{
    constructor(props) {
        super(props);
        this.state = {
            src: "data:,",
            hasDeleted: false
        };
    }

    guidToBytes(guid){
        var bytes = [];
        guid.split('-').map((number, index) => {
            var bytesInChar = index < 3 ? number.match(/.{1,2}/g).reverse() :  number.match(/.{1,2}/g);
            bytesInChar.map((byte) => { bytes.push(parseInt(byte, 16));})
        });
        return bytes;
    }

    componentDidUpdate(prevProps, prevState){
        if( prevProps !== null && this.props.runtime !== prevProps.runtime && this.props.runtime !== undefined ){
            this.setState({src:this.props.runtime})
        }
    }

    loadImage( runtime, fileName, id )
    {
        if(fileName === undefined){
            fileName = 'logo.jpg';
        }
        if( runtime !== null && runtime !== undefined && runtime !== '' )
        {
            this.setState({src:this.state.runtime});
        }
        else if( fileName === 'logo.jpg'){
            this.setState({src:"/Images/logo.png" });
        }
        else if( fileName.lastIndexOf(".bin") !== -1)
        {
            var url = global.StorageAccountUrl + "/uploads/avatars/" + fileName;
            window.fetch( url )
                .then( resp => {
                    if( !resp.ok ){
                        this.setState({src:"/Images/logo.png" });
                    }
                    else{
                        return resp.arrayBuffer();
                    }
                })
                .then( buffer => {
                    const data = new Uint8Array(buffer);
                    const iv = data.slice(0, 16);
                    const cipher = new Buffer(data.slice(16), 'hex');
    
                    const hash = crypto.createHash('sha256');
                    const binKey = this.guidToBytes(id);
                    const key = hash.update( binKey ).digest('hex');
    
                    const bufferKey = new Buffer(key, 'hex');
    
                    const decrypter = crypto.createDecipheriv("aes-256-cbc", bufferKey, iv);
                    let plain =  decrypter.update(cipher);
                    plain = Buffer.concat([plain, decrypter.final()]);
    
                    const base64 = plain.toString('base64');
                    this.setState({src:'data:image/jpeg;base64,' + base64});
                    this.setState({ hasDeleted: true});
                }).catch( reason => {
                    this.setState({src:"/Images/logo.png" });
                });
                
        }
        else{
            if( fileName != '')
            {
                var url = global.StorageAccountUrl + "/uploads/avatars/" + fileName;
                this.setState({src:url});
            }
            else
            {
                this.setState({src:"/Images/logo.png" });
            }
            this.setState({ hasDeleted: false});
        }

    }
    
    componentDidMount() 
    {
        if( this.props.fetchFileName === true )
        {
            var url = `${global.ApiHost}api/Group/avatarImage?groupId=${this.props.id}`;
            window.fetch(url,
                {
                    credentials: 'include',//params.credentials != null ? 'include' : 'same-origin',
                    headers: {
                        'Accept': 'application/json, text/plain, */*',
                    },
                    method: 'get',
                })
                .then((response) => {
                    if (!response.ok) {
                        return "logo.jpg";
                    }
                    return response.text()
                }).then((fileName) => {
                    this.loadImage(this.props.runtime, fileName, this.props.id)
                })
                .catch(()=>{
                    this.loadImage(null, "logo.jpg", null);
                });
        }
        else
        {
            this.loadImage( this.props.runtime, this.props.fileName, this.props.id );
        }
    }
    clearLogo(){
        this.setState({ src:'/Images/logo.png' },() =>{
            if(this.props.onDelete !== undefined){
                this.props.onDelete();
            }
        });
       
    }
    render()
    {
        return <div style={{position: 'relative'}}> 
                <Image onClick={this.props.onClick} size='small' src={this.state.src}
                    style={{ ...this.props.style }} circular floated={this.props.floated} />
                { this.state.src !== "/Images/logo.png" &&
                    <Icon name="remove circle" style={{position: 'absolute', top: 0, left: 0, cursor: 'pointer'}} size='large'
                    onClick={this.clearLogo.bind(this)} /> 
                }
        </div>
    }
    
}

export default GroupImage;
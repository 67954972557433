import React, { Component } from "react";
import {Menu, List, Header, Input, Button, Checkbox, Image, Modal, Card, Icon, CardContent, Label,} from "semantic-ui-react";
import "semantic-ui-css/semantic.min.css";
import { ajaxPost } from "../ajax";
import MessageBoxYesNo from "./MessageBoxYesNo";
import { empty_id } from "../utils";
import modStyles from './UserRightsManagement.module.css'

const { isTaskAvailable } = require("../utils");

class UserRightsManagement extends Component {
  state = {
    searchKey: "",
    saving: false,
    errorMessage: "",
    txtUserRole: "",
    definitions: [],
    userRoles: [],
    mode: "VIEW",
    selected: null,
    displayContent: [],
    origDisplayContent: [],
    deleting: false,
    openDlg: false,
    dlgContents: null,
  };

  loadDefs = (chain) => {
    var searchParameter = {
      data: null,
      url: "api/User/getUserRightsDefintions",
      onError: (error) => {
        alert("error\r\n" + JSON.stringify(error));
      },
      onSuccess: (data, sender) => {
        this.setState({ definitions: data });
        chain();
      },
      finally: () => { },
    };
    ajaxPost(searchParameter);
  };

  loadRoles = () => {
    var searchParameter = {
      data: null,
      url: "api/User/list",
      onError: (error) => {
        alert("error\r\n" + JSON.stringify(error));
      },
      onSuccess: (data, sender) => {
        this.setState({ userRoles: data });
      },
      finally: () => { },
    };
    ajaxPost(searchParameter);
  };

  componentWillMount = () => {
    this.loadDefs(() => {
      this.loadRoles();
    });
  };

  handleAdd = () => {
    var newRole = {
      _id: empty_id,
      UserRole: "",
      UserRights: [],
    };
    var content = this.prepareContent("ADD", newRole);
    this.setState({
      selected: newRole,
      mode: "ADD",
      displayContent: content,
      origDisplayContent: content,
      txtUserRole: "",
    });
  };

  handleEdit = () => {
    var content = this.prepareContent("EDIT", this.state.selected);
    this.setState({
      mode: "EDIT",
      displayContent: content,
      origDisplayContent: content,
      txtUserRole: this.state.selected.UserRole,
    });
  };

  handleDelete = () => {
    this.setState({ deleting: true }, () => {
      ajaxPost({
        url: "api/User/getAssignedEmployees",
        data: this.state.selected._id,
        onSuccess: (data) => {
          if (data.length > 0)
            this.setState({
              openDlg: true,
              dlgContents: [
                <Modal.Header>UserRole Used</Modal.Header>,
                <Modal.Content>
                  <p>
                    This user role cannot be deleted because its used by these
                    employees
                  </p>
                  <List
                    items={data.map((x) => {
                      return (
                        x.LastName + ", " + x.FirstName + " " + x.MiddleName
                      );
                    })}
                  />
                </Modal.Content>,
              ],
              showDeleteModal: false,
            });
          else {
            ajaxPost({
              url: "api/User/delete",
              data: this.state.selected._id,
              onSuccess: (data) => {
                this.setState({
                  userRoles: this.state.userRoles.filter(
                    (x) => x !== this.state.selected
                  ),
                  selected: null,
                });
              },
              finally: () => {
                this.setState({ showDeleteModal: false, displayContent: [] });
              },
            });
          }
        },
        finally: () => {
          this.setState({ deleting: false });
        },
      });
    });
  };

  handleUserRole = (role) => {
    var list = this.prepareContent("VIEW", role);
    this.setState({
      selected: role,
      displayContent: list,
      origDisplayContent: list,
    });
  };

  handleCancel = () => {
    var selected = this.state.selected;
    if (this.state.mode === "ADD") selected = null;

    var content = this.prepareContent("VIEW", selected);
    this.setState({
      mode: "VIEW",
      selected: selected,
      displayContent: content,
      origDisplayContent: content,
    });
  };

  handleSearch = () => {
    // const { origDisplayContent } = this.state;
    const origList = this.state.displayContent;
    const search = this.state.searchKey.toLocaleLowerCase();

    var selected = this.state.selected;
    var content = this.state.displayContent;

    content.forEach(x => {
      x.Expanded = true;
      x.UserRights.forEach(y => y.Hidden = !(y.Description.toLowerCase().includes(search) || search === ""));
    })

    this.setState({ displayContent: content });

  };

  handleSave = () => {
    var _this = this;
    var { state } = this;

    if (this.state.txtUserRole.trim() === "") {
      this.setState({
        errorMessage: "Please enter user role definition",
      });
      return;
    }

    var obj = Object.assign({}, this.state.selected); //clone object
    obj.UserRole = this.state.txtUserRole;
    obj.UserRights = this.state.displayContent
      .reduce((a, b) => {
        return a.concat(b.UserRights);
      }, [])
      .filter((x) => {
        return x.Checked;
      })
      .map((x) => {
        return {
          Key: x.Key,
          Value: true,
        };
      });

    const origCheckedRights = this.state.origDisplayContent
      .reduce((a, b) => {
        return a.concat(b.UserRights);
      }, [])
      .filter((x) => {
        return x.Checked;
      })
      .map((x) => {
        return {
          Key: x.Key,
          Value: true,
        };
      });

    origCheckedRights.map((x) => {
      var matched = obj.UserRights.find((y) => y.Key === x.Key);
      if (x.Value === true && matched == null) {
        obj.UserRights.push(x);
      }
    });

    var parameter = {
      data: obj,
      url: "api/User/save",
      onError: (error) => {
        alert("error\r\n" + JSON.stringify(error));
      },
      onSuccess: (data, sender) => {
        if (data.Result === "DUPLICATE") {
          this.setState({
            errorMessage: "This user role definition already exists",
            saving: false,
          });
        } else {
          if (this.state.mode === "ADD") {
            state.userRoles.push(data.content);
          } else {
            var index = state.userRoles.findIndex((x) => {
              return x === state.selected;
            });
            state.userRoles[index] = data.content;
          }

          var list = _this.prepareContent("VIEW", data.content);
          _this.setState({
            displayContent: list,
            selected: data.Content,
            mode: "VIEW",
            saving: false,
            errorMessage: "",
            userRoles: this.state.userRoles.slice(0), //clone array
          });
        }
      },
      finally: () => { },
    };

    this.setState({ saving: true });
    ajaxPost(parameter);
  };

  handleInputChange = (e) => {
    this.setState({ txtUserRole: e.target.value });
  };

  handleSearchChange = (e) => {
    this.setState({ searchKey: e.target.value });
  };

  handleCheckbox = (obj, e, cb) => {
    obj.Checked = cb.checked;
  };

  onExpandClick = (obj) => {
    obj.Expanded = !obj.Expanded;
  };

  onGroupCheck = (obj, e, cb) => {
    obj.UserRights.forEach((x) => {
      x.Checked = cb.checked;
    });
  };

  onCheckAll = (e, cb) => {
    this.state.displayContent
      .reduce((a, b) => {
        return a.concat(b.UserRights);
      }, [])
      .forEach((x) => {
        x.Checked = cb.checked;
      });
  };
  /*
    styles = {
      listItem: {
        marginLeft: "2em",
      },
      left: {
        display: "table-cell",
        width: "200px",
        paddingRight: "10px",
        borderRight: "1px gray solid",
      },
      content: {
        display: "table-cell",
        paddingLeft: "10px",
      },
      contentInner: {
        height: "calc(100vh - 105px)",
        overflowY: "scroll",
        width: "calc(97vw - 510px)",
      },
      contentInnerEdit: {
        height: "calc(100vh - 165px)",
        overflowY: "scroll",
        width: "calc(96vw - 510px)",
        padding: "5px",
      },
      menuButton: {
        display: "inline-block",
      },
      inline: {
        display: "inline-block",
      },
      group: {
        paddingBottom: "20px",
      },
    };
  */
  prepareContent = (mode, role) => {
    if (role == null) return [];

    var list = this.state.definitions.map((x) => {
      return {
        GroupName: x.GroupName,
        Expanded: true,
        UserRights: x.UserRights.map((y) => {
          return {
            Key: y.Key,
            Description: y.Description,
            Checked: false,
            Hidden: false
          };
        }),
      };
    });

    var flat = list.reduce((a, b) => {
      return a.concat(b.UserRights);
    }, []);

    var merged = flat.filter((x) =>
      role.UserRights.map((y) => {
        return y.Key;
      }).includes(x.Key)
    );
    merged.forEach((x) => {
      x.Checked = true;
    });

    if (mode === "VIEW") {
      list.forEach((x) => {
        x.UserRights = x.UserRights.filter((y) => {
          return y.Checked;
        });
      });
      list = list.filter((x) => {
        return x.UserRights.length > 0;
      });
    }

    return list;
  };

  render() {
    const listPositions = [
      { start: 0, end: 19 },
      { start: 20, end: 35 },
    ];

    const visibleContent = this.state.displayContent
      .filter(x => x.UserRights
        .filter(y => y.Hidden === false).length > 0);

    return <>
      <div className={modStyles.module}>
        <Header>User Rights Management</Header>
        <div className={modStyles.main}>
          <div>
            <div>
              <Button
                size="tiny"
                content="New"
                icon="add"
                className={modStyles.menuButton}
                onClick={this.handleAdd.bind(this)}
                disabled={this.state.mode !== "VIEW"}
              />
              <Button
                size="tiny"
                content="Edit"
                icon="edit"
                className={modStyles.menuButton}
                onClick={this.handleEdit.bind(this)}
                disabled={
                  this.state.mode !== "VIEW" || this.state.selected == null
                }
              />
              <Button
                size="tiny"
                content="Delete"
                icon="delete"
                className={modStyles.menuButton}
                onClick={() => this.setState({ showDeleteModal: true })}
                disabled={
                  this.state.mode !== "VIEW" ||
                  this.state.selected == null ||
                  this.state.selected._id == '97b3bbba-8ec3-4474-af69-6d3c7b973546' ||
                  this.state.deleting
                }
                loading={this.state.deleting}
              />
            </div>
            {this.state.userRoles.length > 0 ? (
              <Menu vertical borderless>
                {this.state.userRoles.map((x) => (
                  <Menu.Item
                    as="a"
                    // name={x.name}
                    content={x.UserRole}
                    onClick={this.handleUserRole.bind(this, x)}
                    active={this.state.selected === x}
                    disabled={this.state.mode !== "VIEW" || this.state.deleting}
                  />
                ))}
              </Menu>
            ) : (
              <p style={{ padding: "10px" }}>No user roles defined</p>
            )}
          </div>
          <div className={modStyles.content} >
            {this.state.mode !== "VIEW" && (
              <div style={{ marginBottom: '1em' }}>
                <Input
                  size="mini"
                  style={{ display: "inline-block", marginRight: "1em" }}
                  placeholder="Enter definition"
                  value={this.state.txtUserRole}
                  onChange={this.handleInputChange.bind(this)}
                />
                <Button
                  disabled={this.state.saving}
                  loading={this.state.saving}
                  size="tiny"
                  icon="save"
                  content="Save"
                  className={modStyles.menuButton}
                  onClick={this.handleSave.bind(this)}
                />
                <Button
                  size="tiny"
                  icon="cancel"
                  content="Cancel"
                  className={modStyles.menuButton}
                  onClick={this.handleCancel.bind(this)}
                />
                {this.state.errorMessage != "" && (
                  <div style={{ height: "20px" }}>
                    {this.state.errorMessage}
                  </div>
                )}
              </div>
            )}
            <div style={{ marginBottom: '1em' }}>
              <Input
                size="mini"
                style={{
                  display: "inline-block",
                  marginRight: "1em",
                }}
                value={this.state.searchKey}
                onChange={this.handleSearchChange.bind(this)}
              />
              <Button
                disabled={this.state.saving}
                loading={this.state.saving}
                size="tiny"
                icon="search"
                content="Search"
                className={modStyles.menuButton}
                onClick={this.handleSearch.bind(this)}
              />
            </div>
            {this.state.displayContent.length === 0 ? (
              <p> There are no entries to display </p>
            ) : (
              <div>
                {this.state.mode !== "VIEW" && (
                  <div style={{ marginBottom: '1em' }}>
                    <b>
                      <Checkbox
                        checked={
                          this.state.displayContent
                            .reduce((a, b) => {
                              return a.concat(b.UserRights);
                            }, [])
                            .some((x) => {
                              return x.Checked === false;
                            }) === false
                        }
                        label="All Items"
                        onChange={this.onCheckAll.bind(this)}
                      />
                    </b>
                  </div>
                )}
                {/* <Card.Group centered stackable itemsPerRow={this.state.displayContent.length > 10 ? 2 : 1}> */}
                <div style={{ display: "flex", flexDirection: "row" }}>
                  {listPositions.map((pos, index) => {
                    return (
                      <div
                        style={
                          ({
                            display: "flex",
                            flexDirection: "column",
                          },
                            index > 0 ? { paddingLeft: "2em" } : {})
                        }
                      >
                        {visibleContent
                          .slice(pos.start, pos.end + 1)
                          .map((x) => {
                            return (
                              <Card
                                style={
                                  this.state.mode !== "VIEW"
                                    ? { blockSize: "fit-content" }
                                    : {}
                                }
                              >
                                <Card.Content>
                                  <Card.Header>
                                    <div
                                      style={{
                                        display: "flex",
                                        flexDirection: "row",
                                      }}
                                    >
                                      {this.state.mode !== "VIEW" ? (
                                        <Checkbox
                                          label={x.GroupName}
                                          checked={
                                            x.UserRights.some((x) => {
                                              return x.Checked === false;
                                            }) === false
                                          }
                                          onChange={this.onGroupCheck.bind(
                                            this,
                                            x
                                          )}
                                        />
                                      ) : (
                                        <Header as="h5">{x.GroupName}</Header>
                                      )}
                                      {
                                        <div style={{ marginLeft: "auto" }}>
                                          <Icon
                                            name={
                                              !x.Expanded
                                                ? "angle down"
                                                : "angle up"
                                            }
                                            link
                                            onClick={this.onExpandClick.bind(
                                              this,
                                              x
                                            )}
                                          />
                                        </div>
                                      }
                                    </div>
                                  </Card.Header>
                                  <Card.Description>
                                    <List>
                                      {x.Expanded &&
                                        x.UserRights.filter(ur => ur.Hidden === false).map((y) => {
                                          return (
                                            <List.Item>
                                              {this.state.mode === "VIEW" ? (
                                                <div>
                                                  {y.Description}
                                                </div>
                                              ) : (
                                                <div>
                                                  <Checkbox
                                                    checked={y.Checked}
                                                    label={y.Description}
                                                    onChange={this.handleCheckbox.bind(
                                                      this,
                                                      y
                                                    )}
                                                  />
                                                </div>
                                              )}
                                            </List.Item>
                                          );
                                        })}
                                    </List>
                                  </Card.Description>
                                </Card.Content>
                              </Card>
                            );
                          })}
                      </div>
                    );
                  })}
                </div>
                {/* </Card.Group> */}
              </div>
            )}
          </div>
        </div>
      </div>
      {this.state.openDlg && (
        <Modal
          onClose={() => this.setState({ openDlg: false })}
          open={true}
          size="tiny"
        >
          {this.state.dlgContents}
        </Modal>
      )}
      {this.state.showDeleteModal && (
        <MessageBoxYesNo
          title="Delete"
          action="Yes"
          CancelCaption="No"
          caption="Are you sure you want to delete this item?"
          onAction={() => this.handleDelete()}
          onClose={() => this.setState({ showDeleteModal: false })}
        />
      )}
    </>
  }
}

UserRightsManagement.getViewID = function () {
  return "UserRightsManagementModule";
};
UserRightsManagement.getCaption = function () {
  return "User Rights Management";
};
UserRightsManagement.getViewAccessKey = () => {
  return isTaskAvailable("USERRIGHTS_ACCESS");
};

UserRightsManagement.getIcon = () => {
  return (
    <Image
      style={{ height: "24px", width: "24px" }}
      src="/Icons/User_Rights.png"
      avatar
    />
  );
};

UserRightsManagement.getIconSrc = (
  size //size values: small (50x50), medium(150x150), large(300x300)
) => {
  return "/Icons/User_Rights.png";
};

export default UserRightsManagement;

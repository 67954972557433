import { useState, useEffect } from 'react';
import { Button, Header, Form, Modal  } from 'semantic-ui-react';
import 'semantic-ui-css/semantic.min.css';
import CutoffEmployeeSelection from '../../Commons/CutoffEmployeeSelection';

export default  function AddNewEmployeeForm(props) {
    let [isMobileView, setIsMobileView] = useState(false);
    let [show,setShow] = useState(false);
    let [employeeIds, setEmployeeId] = useState([]);
    useEffect(() =>{
        setShow(props.Show);
        const resizeListener = () => { 
            // change width from the state object
            setIsMobileView(window.innerWidth <= 812);
          };
        window.addEventListener('resize', resizeListener);

    }, [props.Show]) 
    const handleEmployeeIdsChange = (ids) => { 
        setEmployeeId(ids);
    }
    const handleAction = (isAdd) =>{
        if(isAdd && employeeIds.length === 0){
            alert("Please select employee/s.");
            return;
        }
        props.Action(isAdd, employeeIds);
        setShow(false);
    }
    return (
        <Modal open={show} size="tiny">
        <Header content="Add Employees" />
        <Modal.Content>
            <Form>
                <Form.Field>
                    <label>Employee</label>
                    <CutoffEmployeeSelection
                            update={handleEmployeeIdsChange.bind(this)}
                            companyId={props.Cutoff.CompanyId}
                            cutoffId={props.Cutoff._id}
                            type={props.Cutoff.Type}
                            status={props.Status}  
                            style={{ width: isMobileView ? '100%' : '250px', maxWidth: '300px', minWidth: '50px', marginRight: '3px' }}                             
                            refreshSelection={(refreshFunction) => {}}
                            isNew={true}
                     />  
                </Form.Field>
            </Form>
        </Modal.Content>
        <Modal.Actions>
            <Button basic icon="add" content="Add"
                onClick={handleAction.bind(this, true)}
            />
            <Button basic icon="cancel" content="Cancel"
                onClick={handleAction.bind(this, false)}
            />
        </Modal.Actions>
    </Modal>
    )
}
import React, { Component } from 'react';
import { Button, Modal, } from 'semantic-ui-react';
import 'semantic-ui-css/semantic.min.css';
import SelectFinger from '../Dialogs/SelectFinger';
import Select from 'react-select';
import { ajaxPost, authPost } from '../../ajax.js';

var { fnSearchEmployee,fnSearchEmployeeFilter } = require('../../utils');

function employeeSearchlist(list) {
    var employees = [];
    if (list != null) {
        list.map(x => {
            var fullname = x.LastName + ", " + x.FirstName + " " + x.MiddleName;

            return (
                employees.push({ label: fullname, value: x._id })
            )
        })
    }
    return employees;
}

class EnrollDialog extends Component {
    state =
        {
            busy: false,
            fingerIndex: 6,
            employee: null,
            device: null,
            accessNumber:'',
            Employeelist: [],
            EmployeeIds:[],
            searchQuery: ''
        }
    constructor(props) {
        super(props);

        if (props.device != null)
            this.state.device = props.device;
            fnSearchEmployeeFilter("", data =>{
                this.setState({ Employeelist: data, isLoading: false });
            } , 0, 20, false);
    }
    onFingerChange = (value) => {
        this.setState({ fingerIndex: value });
    }
    handleClose = () => {
        this.props.onClose(false);
    }
    handleChange = (value) => {
        this.setState({ employee: value });
    }
    handleSearchEmployeeChange = (value) => {
        var ids = [];
        if (value != null && !(value === "" || value.length === 0))
            ids = value.map(x => x.value);

        this.setState({ EmployeeIds: value, searchQuery: "" }, () => this.doSearch(""));
        //this.props.update(ids);
    }
    handleSearchChange = (text) => {
        clearTimeout(this.searchTimer);

        if(this.state.searchQuery !== text){
            this.setState({ searchQuery: text})
            this.searchTimer = setTimeout(this.doSearch, 400, text);
        }
    }
    doSearch = (str) => {
        const { EmployeeIds, Employeelist } = this.state;
        var selectedEmpIds = [];
        if (EmployeeIds !== null && EmployeeIds !== undefined && Employeelist !== undefined)
            selectedEmpIds = Employeelist.filter(x => EmployeeIds.map(x => x.value).includes(x._id));

        this.setState({ searchQuery: str, isLoading: true },
            () => fnSearchEmployeeFilter(str, data => {
                selectedEmpIds.forEach(x => { if (!data.includes(x["_id"])) data.push(x) })
                this.setState({ Employeelist: data, isLoading: false });
            }
                , 0, 20, false, EmployeeIds.map(x => x.value))
        )
    }
    // getData = (input, callback) => {
    //     fnSearchEmployee(input, data => {
    //         var emps = [];
    //         data.content.map(item => {
    //             emps.push({
    //                 key: item._id,
    //                 FullName: item.LastName + ', ' + item.FirstName + ' ' + item.MiddleName, accessNumber: item.AccessNumber
    //             });
    //         })
    //         callback(null, {
    //             options: emps
    //         });
    //     }, 0, 10, false);
    // }

    handleAction = async () => {
        this.setState({errModal: false});

        var accessNumber = this.state.Employeelist.filter(x => this.state.EmployeeIds.map(i=>i.value).includes(x._id)).map(x=>{return x.AccessNumber});
        this.setState({accessNumber:accessNumber[0]});
        if(accessNumber === undefined || accessNumber === null || accessNumber === ""){
            this.setState({errModal: true});
            return;
        }

        this.setState({ busy: true });

        var resp = await authPost(
            '/DeviceCommand/EnrollFingerprint',
            {
                serialNumber: this.state.device.SerialNumber,
                accessNumber: accessNumber[0],
                fingerID: this.state.fingerIndex,
            }
        );
        var result = await resp.text();

        this.setState({ busy: false });
        this.props.onSuccess();
    }
    render() {

        return (
            <Modal open size='small'>
                <Modal.Header>
                    Enroll Fingerprint
                </Modal.Header>
                <Modal.Content>
                    {/* <Select.Async disabled={this.state.started || this.state.selectAll}
                        value={this.state.employee}
                        onChange={this.handleChange.bind(this)}
                        loadOptions={this.getData.bind(this)}
                        backspaceRemoves={true}
                        isLoading={true}
                        labelKey="FullName"
                        valueKey="FullName"
                    /> */}
                    <Select disabled={this.state.started || this.state.selectAll}
                        placeholder ="Search Employee"
                        value={this.state.EmployeeIds}
                        onChange={this.handleSearchEmployeeChange.bind(this)}
                        options={employeeSearchlist(this.state.Employeelist)}
                        onInputChange={this.handleSearchChange.bind(this)}
                        isMulti
                    />
                    <br />
                    <SelectFinger value={this.state.fingerIndex} onChange={this.onFingerChange.bind(this)} />
                    <Modal open={this.state.errModal} size='mini'>
                        <Modal.Header>
                            Invalid
                        </Modal.Header>
                        <Modal.Content>
                            Please select an employee first.
                        </Modal.Content>
                        <Modal.Actions>
                            <Button size='mini' icon='check' content='Ok' onClick={()=> this.setState({errModal: false})}/>
                        </Modal.Actions>
                    </Modal>
                </Modal.Content>
                <Modal.Actions>
                    <Button
                        basic
                        icon='check'
                        content={this.props.action}
                        loading={this.state.busy}
                        onClick={this.handleAction.bind(this)}>
                    </Button>
                    <Button basic icon='cancel' content='Cancel' onClick={this.handleClose}></Button>
                </Modal.Actions>
            </Modal>
        );
    }
}

export default EnrollDialog
import React, { Component } from 'react';
import { Table, Menu, Input, Button, Icon, Pagination, Popup, Image, Card, List, Label, Divider } from 'semantic-ui-react';
import 'semantic-ui-css/semantic.min.css';
import { ajaxPost } from '../../ajax.js';
import AnnouncementForm from './AnnouncementForm';
import MessageBoxOkOnly from '../../Commons/MessageBoxOkOnly';
import MessageBoxYesNo from '../../Commons/MessageBoxYesNo';

import modStyles from './AnnouncementList.module.css'

var { isTaskAvailable, checkDateRange } = require('../../utils');
var moment = require('moment');

function getFirstDayOfTheMonth(date) {
    var date = new Date(date);
    return new Date(date.getFullYear(), date.getMonth(), 1).toLocaleDateString();
}

function getLastDayOfTheMonth(date) {
    var date = new Date(date);
    return new Date(date.getFullYear(), date.getMonth() + 1, 0).toLocaleDateString();
}

class AnnouncementList extends Component {
    dt = moment().format('YYYY-MM-DD');
    state = {
        startDate: moment(getFirstDayOfTheMonth(this.dt)).format('YYYY-MM-DD'),
        endDate: moment(getLastDayOfTheMonth(this.dt)).format('YYYY-MM-DD'),
        prevSDate: moment(getFirstDayOfTheMonth(this.dt)).format('YYYY-MM-DD'),
        prevEDate: moment(getLastDayOfTheMonth(this.dt)).format('YYYY-MM-DD'),
        loading: false,
        list: [],
        search: "",
        totalCount: 0,
        pageNumber: 0,
        loading: false,
        addDialog: false,
        editDialog: false,
        deleteDialog: false,
        selectedAnnouncement: null,
        allowAdd: true,
        allowEdit: true,
        allowDelete: true,
        isError: false,
        errMessage: ""
    }
    styles = {
        title: {
            color: '#606060',
            marginTop: '1%'
        },
        searchBox: {
            width: '300px'
        },
        tableHeader: {
            backgroundColor: 'gainsboro',
            fontSize: '12px'
        }
    }

    componentWillMount() {
        if (this.props.prevState != null)
            this.state = this.props.prevState;
    }

    componentDidMount() {
        this.setState({ allowAdd: isTaskAvailable("ANNOUNCEMENT_CREATE") });
        this.setState({ allowEdit: isTaskAvailable("ANNOUNCEMENT_MODIFY") });
        this.setState({ allowDelete: isTaskAvailable("ANNOUNCEMENT_DELETE") });
        this.handleClick();
    }

    componentWillUnmount = () => {
        this.props.unmountCallback(this.state, "AnnouncementModule");
    }

    getList = (callback, countOnly, start, max) => {
        this.setState({ loading: true });
        ajaxPost({
            url: countOnly ? 'api/Announcement/count' : 'api/Announcement/list',
            data: {
                SearchKey: this.state.search,
                StartDate: this.state.startDate,
                EndDate: this.state.endDate,
                StartIndex: start != null ? start : 0,
                MaxData: max != null ? max : 50
            },
            onSuccess: data => {
                if (countOnly) {
                    var res = Math.ceil(data / 50);
                    var count = parseInt(res);
                    this.setState({ pageNumber: count });
                }
                // if (res % count > 0)
                //     count += 1;

                this.setState({ loading: false });

                callback(data);
            },
            finally: () => { }
        });
    }

    handleClick = () => {
        var res = checkDateRange(this.state.startDate, this.state.endDate);
        if (!res.Result) {
            this.setState({ errMessage: res.Message, isError: !res.Result, loading: false, startDate: this.state.prevSDate, endDate: this.state.prevEDate })
            return;
        }

        this.setState({ loading: true });
        this.getList(data => {
            this.setState({ totalCount: data });
            this.handlePaginationChange(null, 1);
        }, true)
    }

    clickEnter = (e) => {
        if (e.key == 'Enter')
            this.handleClick();
    }

    onDateChange = (name, evt) => {
        var startDate = name === "startDate" ? evt.target.value : this.state.startDate;
        var endDate = name === "endDate" ? evt.target.value : this.state.endDate;
        if (checkDateRange(startDate, endDate).Result)
            this.setState({ prevSDate: JSON.parse(JSON.stringify(startDate)), prevEDate: JSON.parse(JSON.stringify(endDate)) });

        this.setState({ [name]: evt.target.value });
    }

    // handleStartDate=(e)=>{
    //     this.setState({ startDate: e.target.value})
    //     var res = checkDateRange(this.state.startDate, this.state.endDate);
    //     this.setState({prevSDate:(!res.Result ? this.state.startDate: this.state.prevSDate)})
    // }
    // handleEndDate=(e)=>{
    //     this.setState({ endDate: e.target.value})
    //     var res = checkDateRange(this.state.startDate, this.state.endDate);
    //     this.setState({prevEDate:(!res.Result ? this.state.endDate: this.state.prevEDate)})      
    // }
    onAddItem() {
        this.setState({ selectedAnnouncement: null });
        this.setState({ addDialog: true });
    }

    onEditItem(item) {
        this.setState({ selectedAnnouncement: item });
        this.setState({ editDialog: true });
    }

    onDeleteItem(item) {
        this.setState({ selectedAnnouncement: item });
        this.setState({ deleteDialog: true });
    }

    onCloseDialog() {
        this.setState({ addDialog: false });
        this.setState({ editDialog: false });
        this.setState({ deleteDialog: false });
    }

    handleSaveItem(announcement) {
        announcement.StartDate = announcement.StartDate === "" ? "1900-02-01" : announcement.StartDate;
        announcement.EndDate = announcement.EndDate === "" ? "9999-11-01" : announcement.EndDate;
        announcement.RecordDate = new Date();
        ajaxPost({
            url: 'api/Announcement/save',
            data: announcement,
            onSuccess: data => {
                this.setState({ list: [data.content] });
                this.onCloseDialog();
            },
            finally: () => { }
        });
    }
    handleDelete() {
        ajaxPost({
            url: 'api/Announcement/delete',
            data: this.state.selectedAnnouncement._id,
            onSuccess: data => {
                this.setState({ list: this.state.list.filter(item => item != this.state.selectedAnnouncement) });
                this.onCloseDialog();
            },
            finally: () => { }
        });
    }

    handlePaginationChange = (e, { activePage }) => {

        if (isNaN(activePage) || activePage == 0) {
            activePage = 1;
        }

        this.setState({ activePage })
        const rowsPerPage = 50;

        this.setState({ loading: true });

        this.getList(data => {
            this.setState({ loading: false });
            this.setState({ list: data });
        }, false, (activePage - 1) * rowsPerPage, rowsPerPage);
    }
    render() {
        var styles = this.styles;
        return (
            <div className={modStyles.module}>
                <div style={styles.title} >
                    <h2>Announcements</h2>
                </div>
                <br />
                <Menu secondary size='mini'>
                    <Button disabled={!this.state.allowAdd} floated='left' size='mini' onClick={this.onAddItem.bind(this)}><Icon name='plus' /> Create New</Button>
                    {
                        this.state.totalCount > 0 &&
                        <Pagination
                            floated='right'
                            pointing
                            secondary
                            size='mini'
                            siblingRange={2}
                            boundaryRange={0}
                            defaultActivePage={1}
                            firstItem={{ content: <Icon name='angle double left' />, icon: true }}
                            lastItem={{ content: <Icon name='angle double right' />, icon: true }}
                            prevItem={{ content: <Icon name='angle left' />, icon: true }}
                            nextItem={{ content: <Icon name='angle right' />, icon: true }}
                            totalPages={this.state.pageNumber}
                            onPageChange={this.handlePaginationChange} />
                    }
                </Menu>
                <div>
                    <Menu stackable secondary size='tiny'>
                        <Menu.Menu error position='right'>
                            <Input labelPosition='left'>
                                <Label style={{ width: '50px' }}>From</Label>
                                <input
                                    size='small'
                                    value={this.state.startDate}
                                    type="date"
                                    onChange={this.onDateChange.bind(this, "startDate")} />
                            </Input>
                            &nbsp;
                            <Input labelPosition='left'>
                                <Label style={{ width: '50px' }}>To</Label>
                                <input
                                    size='small'
                                    value={this.state.endDate}
                                    type="date"
                                    onChange={this.onDateChange.bind(this, "endDate")} />
                            </Input>
                            &nbsp;
                            <Input
                                placeholder='Search announcement...'
                                loading={this.state.loading}
                                onKeyPress={this.clickEnter.bind(this)}
                                onChange={(evt) => this.setState({ search: evt.target.value })}
                            />
                            &nbsp;
                            <Button size='mini' loading={this.state.loading} onClick={this.handleClick.bind(this)} content="Search" />
                        </Menu.Menu>
                    </Menu>
                </div>
                <Divider></Divider>
                <div className={modStyles.content}>
                <Card.Group>
                    {
                        this.state.list.map(item => {
                            return (
                                <Card color='olive' fluid>
                                    <Card.Content>
                                        <Button.Group vertical floated='right'>
                                            <Button style={{ 'font-size': '10px', height: '25px', margin: '2px' }} disabled={!this.state.allowEdit} onClick={this.onEditItem.bind(this, item)} content='Edit' />
                                            <Button style={{ 'font-size': '10px', height: '25px', margin: '2px' }} disabled={!this.state.allowDelete} onClick={this.onDeleteItem.bind(this, item)} content='Delete' />
                                        </Button.Group>
                                        <Card.Header>{item.Title}</Card.Header>
                                        <Card.Meta>{moment(item.RecordDate).format("YYYY-MM-DD")}</Card.Meta>
                                        <Card.Description>
                                            <List>
                                                <List.Item>
                                                    <Label>From</Label>&nbsp;{moment(item.StartDate).format("YYYY-MM-DD")}
                                                    <Label>To</Label>&nbsp;{moment(item.EndDate).format("YYYY-MM-DD")}
                                                </List.Item>
                                                <List.Item><Label>Message</Label>&nbsp;{item.Message}</List.Item>
                                            </List>
                                        </Card.Description>
                                    </Card.Content>
                                </Card>
                            )
                        })
                    }
                </Card.Group>
                </div>
                {
                    this.state.addDialog &&
                    <AnnouncementForm announcement={JSON.parse(JSON.stringify(this.state.selectedAnnouncement))}
                        onSave={this.handleSaveItem.bind(this)} onCancel={this.onCloseDialog.bind(this)} />
                }
                {
                    this.state.editDialog &&
                    <AnnouncementForm announcement={JSON.parse(JSON.stringify(this.state.selectedAnnouncement))}
                        onSave={this.handleSaveItem.bind(this)} onCancel={this.onCloseDialog.bind(this)} />
                }
                {
                    this.state.deleteDialog &&
                    <MessageBoxYesNo title="Delete Announcement"
                        action="Delete"
                        onClose={this.onCloseDialog.bind(this)}
                        onAction={this.handleDelete.bind(this)}
                        caption="Are you sure you want delete this announcement?"
                    />
                }
                {
                    this.state.isError &&
                    <MessageBoxOkOnly title="Warning"
                        action="OK"
                        onClick={() => this.setState({ isError: false })}
                        caption={this.state.errMessage}
                    />
                }
            </div>
        )
    }
}

AnnouncementList.getViewID = () => { return "AnnouncementModule" };
AnnouncementList.getCaption = () => { return "Announcement" };
AnnouncementList.getViewAccessKey = () => { return isTaskAvailable("ANNOUNCEMENT_VIEW") };
AnnouncementList.getIconSrc = (size) => {
    return '/Icons/Announcement.png';
}
AnnouncementList.getIcon = () => {
    return <Image style={{ "height": "24px", "width": "24px" }} src='/Icons/Announcement.png' avatar />
}

export default AnnouncementList
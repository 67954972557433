import React, { Component } from 'react';
import 'semantic-ui-css/semantic.min.css';
import { Modal, Button, Checkbox } from '../../../../node_modules/semantic-ui-react';
import Select from 'react-select';
import { ajaxPost } from '../../../ajax';
import { empty_id } from '../../../utils';

class EmployeeReportModalGroupByLocation extends Component {

    constructor(props) {
        super(props);
        this.state = {
            includeDeleted:false,
            selectedLocation: null,
            listLocations: [],
            includeInactive:false,
        }
    }

    convertToOptions(list){
        var options = [];
        //var unique = list.map(x=>x.Name).filter(onlyUnique); 
        if(list !=null){
            list.map(x =>{
               return(
                options.push({label: x.Name, value: x._id})
               )
           })
        }
        return options;
    }

    getParameters = () => {
        return {
            message: "from employee report group by location modal",
            CityID: this.state.selectedLocation === null? empty_id: this.state.selectedLocation.value,
            includeDeleted:this.state.includeInactive,
            startingIndex:0,
            itemCount:20,
            FilterDetails: "Location: " + (this.state.selectedLocation === null ?"ALL": this.state.selectedLocation.label)
        };
    }

    componentWillMount(){
        this.loadDepartments();
    }

    loadDepartments = () => {
        ajaxPost({
            url: "api/Group/search",
            data: {
                "Search": "",
                "Types":["CITY"],
                "includeDeleted": false,
                "startingIndex": 0,
                "itemCount": 1000
            },
            onSuccess: data => {
                this.setState({
                    listLocations: data.content,
                });
            },
            finally: () => { }
        })
    }

    handleComboChange = (value) => {
        this.setState({ selectedLocation : value})
    }
    onChkCheckChange = (e) => {
        this.setState({ includeInactive: !this.state.includeInactive })
    }

    render() {
        return (
            <Modal open={this.props.open} size="tiny">
                <Modal.Header>
                    <p>Employee Report Group By Location Filters</p>
                </Modal.Header>
                <Modal.Content>
                    <div class="ui grid">
                        <div class="left floated left aligned sixteen wide column">
                        <label>Location</label>
                            <Select placeholder='Location' name="LocationList"
                                onChange={this.handleComboChange.bind(this)}  
                                options={this.convertToOptions(this.state.listLocations)}                       
                                value={this.state.selectedLocation}
                                isClearable/>
                            <Checkbox size='mini' 
                                label="Include Resigned" 
                                onChange={this.onChkCheckChange.bind(this)} 
                                checked={this.state.includeInactive} />
                        </div>
                    </div>
                </Modal.Content>
                <Modal.Actions>
                    <Button basic content="Generate" onClick={this.props.onSave}></Button>
                    <Button basic content="Cancel" onClick={this.props.onCancel}></Button>
                </Modal.Actions>
            </Modal>
        );
    }

}

export default EmployeeReportModalGroupByLocation;
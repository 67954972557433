import React, { Component } from 'react';
import { Accordion, Button, Checkbox, Input, Dropdown, Grid, Table, Card, Header, Modal, Loader, Menu, TextArea, Message, Tab } from 'semantic-ui-react';
import 'semantic-ui-css/semantic.min.css';
import { ajaxPost } from '../../ajax';
import { empty_id, checkInt32, handleNumericOnKeyPress, handleNumericOnKeyDown } from '../../utils';

import modStyles from './PayrollRateProfileForm.module.css'
class PayrollRateProfileForm extends Component {
    state = {
        ItemData: {
            _id: empty_id,
            Name: "",
            Description: "",
            PayrollRates: []
        },
        saving: false,
        dialog: false,
        dialogContent: null,
        dialogTitle: '',
        conflict: [],
        showConflict: false,
        showRequiredField: false
    }
    constructor(props, context) {
        super(props, context);
        this.state.ItemData = props.selectedItem == null ? this.state.ItemData : props.selectedItem;
        if (props.selectedItem == null) {
            this.loadPayrollRates();
        } else {
            this.convertToInclusive();
        }


    }

    convertToInclusive = () => {
        var { ItemData } = this.state
        var payRates = ItemData.PayrollRates;
        if (payRates.find(x => x.Description == "DH") === undefined)
            payRates.push({ Description: "DH", Computation: "1 x 3 = 3.0 or 300%", Rate: 3.0000 });
        if (payRates.find(x => x.Description === "DHOT") === undefined)
            payRates.push({ Description: "DHOT", Computation: "3 x 1.3 = 3.9 or 390%", Rate: 3.9000 });
        if (payRates.find(x => x.Description === "DHND") === undefined)
            payRates.push({ Description: "DHND", Computation: "3 x 1.1 =3.3 or 330%", Rate: 0.3000 });
        if (payRates.find(x => x.Description === "DHNDOT") === undefined)
            payRates.push({ Description: "DHNDOT", Computation: "3.9 x 1.1 = 4.29 or 429%", Rate: 0.3900 });
        if (payRates.find(x => x.Description === "DHRD") === undefined)
            payRates.push({ Description: "DHRD", Computation: "3 x 1.3", Rate: 3.9000 });
        if (payRates.find(x => x.Description === "DHRDOT") === undefined)
            payRates.push({ Description: "DHRDOT", Computation: "3 x 1.3 x 1.3= 5.07 or 507%", Rate: 5.0700 });
        if (payRates.find(x => x.Description === "DHRDND") === undefined)
            payRates.push({ Description: "DHRDND", Computation: "3.9 x 1.1 = 4.29 or 429%", Rate: 0.3900 });
        if (payRates.find(x => x.Description === "DHRDNDOT") === undefined)
            payRates.push({ Description: "DHRDNDOT", Computation: "3.9 x 1.1 x 1.3 =5.577", Rate: 0.5070 });
        if (payRates.find(x => x.Description === "HDSD") === undefined)
            payRates.push({ Description: "HDSD", Computation: "260% or 2.6", Rate: 2.6000 });
        if (payRates.find(x => x.Description === "HDSDOT") === undefined)
            payRates.push({ Description: "HDSDOT", Computation: "2.6 x 1.3 = 3.38 or 338%", Rate: 3.3800 });
        if (payRates.find(x => x.Description === "HDSDND") === undefined)
            payRates.push({ Description: "HDSDND", Computation: "2.6 x 1.1 = 2.86 or 286%", Rate: 0.26 });
        if (payRates.find(x => x.Description === "HDSDNDOT") === undefined)
            payRates.push({ Description: "HDSDNDOT", Computation: "2.6 x 1.1 x 1.3 = 3.718", Rate: 0.338 });
        if (payRates.find(x => x.Description === "HDSDRD") === undefined)
            payRates.push({ Description: "HDSDRD", Computation: "2.6 x 1.3 =  3.38 or 338%", Rate: 3.3800 });
        if (payRates.find(x => x.Description === "HDSDRDOT") === undefined)
            payRates.push({ Description: "HDSDRDOT", Computation: "3.38 x 1.3 = 4.3940 or 439.4%", Rate: 4.3940 });
        if (payRates.find(x => x.Description === "HDSDRDND") === undefined)
            payRates.push({ Description: "HDSDRDND", Computation: "3.38 x 1.1 = 3.7180 or 371.8%", Rate: 0.3380 });
        if (payRates.find(x => x.Description === "HDSDRDNDOT") === undefined)
            payRates.push({ Description: "HDSDRDNDOT", Computation: "3.38 x 1.1 x 1.3 = 4.8334", Rate: 0.4394 });


        payRates.find(x => x.Description === "ND").Rate = parseFloat((payRates.find(x => x.Description === "RW").Rate + payRates.find(x => x.Description === "ND").Rate).toFixed(4))
        payRates.find(x => x.Description === "NDOT").Rate = parseFloat((payRates.find(x => x.Description === "OT").Rate + payRates.find(x => x.Description === "NDOT").Rate).toFixed(4))
        payRates.find(x => x.Description === "RDND").Rate = parseFloat((payRates.find(x => x.Description === "RDND").Rate + payRates.find(x => x.Description === "RD").Rate).toFixed(4))
        payRates.find(x => x.Description === "RDNDOT").Rate = parseFloat((payRates.find(x => x.Description === "RDNDOT").Rate + payRates.find(x => x.Description === "RDOT").Rate).toFixed(4))
        payRates.find(x => x.Description === "HDND").Rate = parseFloat((payRates.find(x => x.Description === "HDND").Rate + payRates.find(x => x.Description === "HD").Rate).toFixed(4))
        payRates.find(x => x.Description === "HDNDOT").Rate = parseFloat((payRates.find(x => x.Description === "HDNDOT").Rate + payRates.find(x => x.Description === "HDOT").Rate).toFixed(4))

        payRates.find(x => x.Description === "HDRDND").Rate = parseFloat((payRates.find(x => x.Description === "HDRDND").Rate + payRates.find(x => x.Description === "HDRD").Rate).toFixed(4))
        payRates.find(x => x.Description === "HDRDNDOT").Rate = parseFloat((payRates.find(x => x.Description === "HDRDNDOT").Rate + payRates.find(x => x.Description === "HDRDOT").Rate).toFixed(4))

        payRates.find(x => x.Description === "SDND").Rate = parseFloat((payRates.find(x => x.Description === "SDND").Rate + payRates.find(x => x.Description === "SD").Rate).toFixed(4))
        payRates.find(x => x.Description === "SDNDOT").Rate = parseFloat((payRates.find(x => x.Description === "SDNDOT").Rate + payRates.find(x => x.Description === "SDOT").Rate).toFixed(4))

        payRates.find(x => x.Description === "SDRDND").Rate = parseFloat((payRates.find(x => x.Description === "SDRDND").Rate + payRates.find(x => x.Description === "SDRD").Rate).toFixed(4))
        payRates.find(x => x.Description === "SDRDNDOT").Rate = parseFloat((payRates.find(x => x.Description === "SDRDNDOT").Rate + payRates.find(x => x.Description === "SDRDOT").Rate).toFixed(4))

        payRates.find(x => x.Description === "DHND").Rate = parseFloat((payRates.find(x => x.Description === "DHND").Rate + payRates.find(x => x.Description === "DH").Rate).toFixed(4))
        payRates.find(x => x.Description === "DHNDOT").Rate = parseFloat((payRates.find(x => x.Description === "DHNDOT").Rate + payRates.find(x => x.Description === "DHOT").Rate).toFixed(4))

        payRates.find(x => x.Description === "DHRDND").Rate = parseFloat((payRates.find(x => x.Description === "DHRDND").Rate + payRates.find(x => x.Description === "DHRD").Rate).toFixed(4))
        payRates.find(x => x.Description === "DHRDNDOT").Rate = parseFloat((payRates.find(x => x.Description === "DHRDNDOT").Rate + payRates.find(x => x.Description === "DHRDOT").Rate).toFixed(4))

        payRates.find(x => x.Description === "HDSDND").Rate = parseFloat((payRates.find(x => x.Description === "HDSDND").Rate + payRates.find(x => x.Description === "HDSD").Rate).toFixed(4))
        payRates.find(x => x.Description === "HDSDNDOT").Rate = parseFloat((payRates.find(x => x.Description === "HDSDNDOT").Rate + payRates.find(x => x.Description === "HDSDOT").Rate).toFixed(4))

        payRates.find(x => x.Description === "HDSDRDND").Rate = parseFloat((payRates.find(x => x.Description === "HDSDRDND").Rate + payRates.find(x => x.Description === "HDSDRD").Rate).toFixed(4))
        payRates.find(x => x.Description === "HDSDRDNDOT").Rate = parseFloat((payRates.find(x => x.Description === "HDSDRDNDOT").Rate + payRates.find(x => x.Description === "HDSDRDOT").Rate).toFixed(4))

        this.setState({ ItemData })
    }

    convertToExclusive(model) {
        var payRates = model.PayrollRates;
        payRates.find(x => x.Description === "ND").Rate = parseFloat((payRates.find(x => x.Description === "ND").Rate - payRates.find(x => x.Description === "RW").Rate).toFixed(4))
        payRates.find(x => x.Description === "NDOT").Rate = parseFloat((payRates.find(x => x.Description === "NDOT").Rate - payRates.find(x => x.Description === "OT").Rate).toFixed(4))
        payRates.find(x => x.Description === "RDND").Rate = parseFloat((payRates.find(x => x.Description === "RDND").Rate - payRates.find(x => x.Description === "RD").Rate).toFixed(4))
        payRates.find(x => x.Description === "RDNDOT").Rate = parseFloat((payRates.find(x => x.Description === "RDNDOT").Rate - payRates.find(x => x.Description === "RDOT").Rate).toFixed(4))
        payRates.find(x => x.Description === "HDND").Rate = parseFloat((payRates.find(x => x.Description === "HDND").Rate - payRates.find(x => x.Description === "HD").Rate).toFixed(4))
        payRates.find(x => x.Description === "HDNDOT").Rate = parseFloat((payRates.find(x => x.Description === "HDNDOT").Rate - payRates.find(x => x.Description === "HDOT").Rate).toFixed(4))

        payRates.find(x => x.Description === "HDRDND").Rate = parseFloat((payRates.find(x => x.Description === "HDRDND").Rate - payRates.find(x => x.Description === "HDRD").Rate).toFixed(4))
        payRates.find(x => x.Description === "HDRDNDOT").Rate = parseFloat((payRates.find(x => x.Description === "HDRDNDOT").Rate - payRates.find(x => x.Description === "HDRDOT").Rate).toFixed(4))

        payRates.find(x => x.Description === "SDND").Rate = parseFloat((payRates.find(x => x.Description === "SDND").Rate - payRates.find(x => x.Description === "SD").Rate).toFixed(4))
        payRates.find(x => x.Description === "SDNDOT").Rate = parseFloat((payRates.find(x => x.Description === "SDNDOT").Rate - payRates.find(x => x.Description === "SDOT").Rate).toFixed(4))

        payRates.find(x => x.Description === "SDRDND").Rate = parseFloat((payRates.find(x => x.Description === "SDRDND").Rate - payRates.find(x => x.Description === "SDRD").Rate).toFixed(4))
        payRates.find(x => x.Description === "SDRDNDOT").Rate = parseFloat((payRates.find(x => x.Description === "SDRDNDOT").Rate - payRates.find(x => x.Description === "SDRDOT").Rate).toFixed(4))

        payRates.find(x => x.Description === "DHND").Rate = parseFloat((payRates.find(x => x.Description === "DHND").Rate - payRates.find(x => x.Description === "DH").Rate).toFixed(4))
        payRates.find(x => x.Description === "DHNDOT").Rate = parseFloat((payRates.find(x => x.Description === "DHNDOT").Rate - payRates.find(x => x.Description === "DHOT").Rate).toFixed(4))

        payRates.find(x => x.Description === "DHRDND").Rate = parseFloat((payRates.find(x => x.Description === "DHRDND").Rate - payRates.find(x => x.Description === "DHRD").Rate).toFixed(4))
        payRates.find(x => x.Description === "DHRDNDOT").Rate = parseFloat((payRates.find(x => x.Description === "DHRDNDOT").Rate - payRates.find(x => x.Description === "DHRDOT").Rate).toFixed(4))

        payRates.find(x => x.Description === "HDSDND").Rate = parseFloat((payRates.find(x => x.Description === "HDSDND").Rate - payRates.find(x => x.Description === "HDSD").Rate).toFixed(4))
        payRates.find(x => x.Description === "HDSDNDOT").Rate = parseFloat((payRates.find(x => x.Description === "HDSDNDOT").Rate - payRates.find(x => x.Description === "HDSDOT").Rate).toFixed(4))

        payRates.find(x => x.Description === "HDSDRDND").Rate = parseFloat((payRates.find(x => x.Description === "HDSDRDND").Rate - payRates.find(x => x.Description === "HDSDRD").Rate).toFixed(4))
        payRates.find(x => x.Description === "HDSDRDNDOT").Rate = parseFloat((payRates.find(x => x.Description === "HDSDRDNDOT").Rate - payRates.find(x => x.Description === "HDSDRDOT").Rate).toFixed(4))

        return model;
    }

    loadPayrollRates = () => {

        var rates = [{ Description: "RW", DescriptionFormated: "( RW ) - Regular Hours Work", Computation: "1", Rate: 1.0000 },
        { Description: "OT", DescriptionFormated: "( OT ) - Overtime", Computation: "1 x 1.25 = 1.25 or 125%", Rate: 1.2500 },
        { Description: "ND", DescriptionFormated: "( ND ) - Night Differential", Computation: "1 x 1.1 = 1.1 or 110%", Rate: .10 },
        { Description: "NDOT", DescriptionFormated: "( NDOT ) - Night Differential Overtime", Computation: "1 x 1.1 x 1.375", Rate: 0.125 },
        { Description: "RD", DescriptionFormated: "( RD ) - Rest Day",  Computation: "1 x 1.3 = 1.3 or 130%", Rate: 1.3000 },
        { Description: "RDOT",DescriptionFormated: "( RDOT ) - Rest Day Overtime", Computation: "1.3 x 1.3 = 1.69 or 169%", Rate: 1.69 },
        { Description: "RDND",DescriptionFormated: "( RDND ) - Rest Day Night Differential",  Computation: "1.3 x 1.1 = 1.43 or 143%", Rate: 0.13 },
        { Description: "RDNDOT",DescriptionFormated: "( RDNDOT ) - Rest Day Night Differential Overtime", Computation: "1.3 x 1.1 x 1.3 = 1.859", Rate: 0.169 },
        { Description: "HD", DescriptionFormated: "( HD ) - Regular Holiday", Computation: "1 x 2 = 2.0 or 200%", Rate: 2.0000 },
        { Description: "HDOT",DescriptionFormated: "( HDOT ) - Regular Holiday Overtime", Computation: "2 x 1.3 = 2.6 or 260%", Rate: 2.6000 },
        { Description: "HDND",DescriptionFormated: "( HDND ) - Regular Holiday Overtime", Computation: "2 x 1.1 = 2.2 or 220%", Rate: 0.20 },
        { Description: "HDNDOT",DescriptionFormated: "( HDNDOT ) - Regular Holiday  Night Differential Overtime", Computation: "2.6 x 1.1 = 2.86 or 286%", Rate: 0.26 },
        { Description: "HDRD",DescriptionFormated: "( HDRD ) - Regular Holiday Rest Day", Computation: "260% or 2.6", Rate: 2.6000 },
        { Description: "HDRDOT",DescriptionFormated: "( HDRDOT ) - Regular Holiday Rest Day Overtime", Computation: "2.6 x 1.3 = 3.38 or 338%", Rate: 3.3800 },
        { Description: "HDRDND",DescriptionFormated: "( HDRDND ) - Regular Holiday Rest Day Night Differential", Computation: "2.6 x 1.1 = 2.86 or 286%", Rate: 0.26 },
        { Description: "HDRDNDOT",DescriptionFormated: "( HDRDNDOT ) - Regular Holiday Rest Day Night Differential Overtime",  Computation: "2.6 x 1.1 x 1.3 = 3.718", Rate: 0.338 },
        { Description: "SD", DescriptionFormated: "( SD ) - Special Holiday", Computation: "130% or 1.3", Rate: 1.3000 },
        { Description: "SDOT",DescriptionFormated: "( SDOT ) - Special Holiday Overtime", Computation: "1.3 x 1.3 = 1.69 or 169%", Rate: 1.69 },
        { Description: "SDND",DescriptionFormated: "( SDND ) - Special Holiday  Night Differential", Computation: "1.1 x 1.3 = 1.43", Rate: 0.13 },
        { Description: "SDNDOT",DescriptionFormated: "( SDNDOT ) - Special Holiday  Night Differential Overtime", Computation: "1.3 x 1.1 x 1.3 = 1.859", Rate: 0.169 },
        { Description: "SDRD",DescriptionFormated: "( SDRD ) - Special Holiday Rest Day", Computation: "150% or 1.5", Rate: 1.5000 },
        { Description: "SDRDOT",DescriptionFormated: "( SDRDOT ) - Special Holiday Rest Day Overtime", Computation: "1.5 x 1.3 = 1.95 or 195%", Rate: 1.9500 },
        { Description: "SDRDND",DescriptionFormated: "( SDRDND ) - Special Holiday Rest Day Night Differential", Computation: "1.5 x 1.1 = 1.65 or 165%", Rate: 0.15 },
        { Description: "SDRDNDOT",DescriptionFormated: "( SDRDNDOT ) - Special Holiday Rest Day Night Differential Overtime", Computation: "1.5 x 1.1 x 1.3 = 2.145", Rate: 0.195 },
        { Description: "HDSD",DescriptionFormated: "( HDSD ) - Regular Holiday Special Holiday", Computation: "260% or 2.6", Rate: 2.6000 },
        { Description: "HDSDOT",DescriptionFormated: "( HDSDOT ) - Regular Holiday Special Holiday Overtime", Computation: "2.6 x 1.3 = 3.38 or 338%", Rate: 3.3800 },
        { Description: "HDSDND",DescriptionFormated: "( HDSDND ) - Regular Holiday Special Holiday Night Differential", Computation: "2.6 x 1.1 = 2.86 or 286%", Rate: 0.26 },
        { Description: "HDSDNDOT",DescriptionFormated: "( HDSDNDOT ) - Regular Holiday Special Holiday Night Differential Overtime", Computation: "2.6 x 1.1 x 1.3 = 3.718", Rate: 0.338 },
        { Description: "HDSDRD",DescriptionFormated: "( HDSDRD ) - Regular Holiday Special Holiday Rest Day", Computation: "2.6 x 1.3 =  3.38 or 338%", Rate: 3.3800 },
        { Description: "HDSDRDOT",DescriptionFormated: "( HDSDRDOT ) - Regular Holiday Special Holiday Rest Day Overtime", Computation: "3.38 x 1.3 = 4.3940 or 439.4%", Rate: 4.3940 },
        { Description: "HDSDRDND",DescriptionFormated: "( HDSDRDND ) - Regular Holiday Special Holiday Rest Day Night Differential", Computation: "3.38 x 1.1 = 3.7180 or 371.8%", Rate: 0.3380 },
        { Description: "HDSDRDNDOT", DescriptionFormated: "( HDSDRDNDOT ) - Regular Holiday Special Holiday Rest Day Night Differential Overtime", Computation: "3.38 x 1.1 x 1.3 = 4.8334", Rate: 0.4394 },
        { Description: "DH",  DescriptionFormated: "( DH ) - Double Holiday", Computation: "1 x 3 = 3.0 or 300%", Rate: 3.0000 },
        { Description: "DHOT", DescriptionFormated: "( DHOT ) - Double Holiday Overtime", Computation: "3 x 1.3 = 3.9 or 390%", Rate: 3.9000 },
        { Description: "DHND", DescriptionFormated: "( DHND ) - Double Holiday Night Differential", Computation: "3 x 1.1 =3.3 or 330%", Rate: 0.3000 },
        { Description: "DHNDOT", DescriptionFormated: "( DHNDOT ) - Special Holiday Night Differential Overtime", Computation: "3.9 x 1.1 = 4.29 or 429%", Rate: 0.3900 },
        { Description: "DHRD", DescriptionFormated: "( DHRD ) - Double Holiday Rest Day",  Computation: "3 x 1.3", Rate: 3.9000 },
        { Description: "DHRDOT",DescriptionFormated: "( DHRDOT ) - Double Holiday Rest Day Overtime", Computation: "3 x 1.3 x 1.3= 5.07 or 507%", Rate: 5.0700 },
        { Description: "DHRDND",DescriptionFormated: "( DHRDND ) - Double Holiday Rest Day Night Differential",  Computation: "3.9 x 1.1 = 4.29 or 429%", Rate: 0.3900 },
        { Description: "DHRDNDOT",DescriptionFormated: "( DHRDNDOT ) - Double Holiday Rest Day Night Differential Overtime", Computation: "3.9 x 1.1 x 1.3 =5.577", Rate: 0.5070 },];


        var ItemData = this.state.ItemData;
        ItemData.PayrollRates = rates;
        this.setState({ ItemData: ItemData }, this.convertToInclusive());

    }
    save = (callback, model) => {
        var reModel = JSON.parse(JSON.stringify(model));
        ajaxPost({
            url: 'api/PayrollRateProfile/save',
            data: {
                "model": JSON.stringify(this.convertToExclusive(reModel)),
                "includeDeleted": false,
                "startingIndex": 0,
                "itemCount": 1
            },
            onSuccess: data => {
                callback(data);
            },
            onError: function (err) {
                alert(JSON.stringify(err));
            },
            finally: () => { this.setState({ saving: false }); }
        })
    }
    handleSave = (e, data) => {
        this.setState({ saving: true });
        var model = this.state.ItemData;
        var isSaving = true;
        model.PayrollRates.map(x => {
            if (!checkInt32(x.Rate).Result || x.Rate === "") {
                this.setState({ showRequiredField: true });
                isSaving = false;
            }
        });

        if (isSaving) {
            this.save(data => {
                this.setState({ saving: false });
                this.setState({ showConflict: false });
                if (data.content != null) {
                    this.setState({ ItemData: data.content });
                    this.dialogOnSuccess();
                }
                if (data.errors !== undefined) {
                    if (data.errors.length !== 0) {
                        this.setState({ showConflict: true });
                        this.setState({ conflict: data.errors });
                    }
                }

            }, model);
        } else {
            this.setState({ saving: false });
        }
    }
    handleChange = name => (trans, event) => {
        var current = this.state.ItemData;
        if (current[name] === undefined) {
            var payrollRates = current.PayrollRates;
            var index = payrollRates.indexOf(trans);
            var transaction = payrollRates[index];
            transaction[name] = event.target.value;
            payrollRates[index] = transaction;
            current.PayrollRates = payrollRates;
        } else {
            current[name] = event.target.value;
        }
        this.setState({ ItemData: current });
    };
    handleCheckChange = name => (trans, e) => {
        var current = this.state.ItemData;
        if (current[name] === undefined) {
            var payrollRates = current.PayrollRates;
            var index = payrollRates.indexOf(trans);
            var transaction = payrollRates[index];
            transaction[name] = transaction[name] === false ? true : false;
            payrollRates[index] = transaction;
            current.PayrollRates = payrollRates;
        } else {
            current[name] = current[name] === false ? true : false;
        }
        this.setState({ ItemData: current });
    }
    handleComboChange = (trans, name, val) => {
        var current = this.state.ItemData;
        if (current[val.name] === undefined) {
            var payrollRates = current.PayrollRates;
            var index = payrollRates.indexOf(trans);
            var transaction = payrollRates[index];
            transaction[val.name] = val.value;
            payrollRates[index] = transaction;
            current.PayrollRates = payrollRates;
        } else {
            current[val.name] = val.value;
        }
        this.setState({ ItemData: current });
    }
    onCloseDialog = () => {
        this.setState({ dialog: false });
        this.props.onUpdateComplete(this.state.ItemData);
    }
    dialogOnSuccess = () => {
        this.setState({ dialogTitle: "Save" });
        this.setState({
            dialogContent: (
                <div>
                    <p>Payroll Profile has been updated successfully</p>
                </div>
            )
        });
        this.setState({ dialog: true });
    }
    render() {
        const PayrollFrequencies = [
            { key: 1, text: 'MONTHLY', value: 'MONTHLY' },
            { key: 2, text: 'SEMI-MONTHLY', value: 'SEMIMONTHLY' },
            { key: 3, text: 'WEEKLY', value: 'WEEKLY' },
        ]
        const AppliedCutoffs = [
            { key: 1, text: 'EVERY CUTOFF', value: 'EVERY_CUTOFF' },
            { key: 2, text: 'LAST CUTOFF', value: 'LAST_CUTOFF' },
            { key: 3, text: 'FIRST CUTOFF', value: 'FIRST' },
            { key: 4, text: 'SECOND CUTOFF', value: 'SECOND' },
            { key: 5, text: 'THIRD CUTOFF', value: 'THIRD' },
            { key: 6, text: 'FOURTH CUTOFF', value: 'FOURTH' },
            { key: 7, text: 'FIFTH CUTOFF', value: 'FIFTH' },
        ]
        return <>
            <div className={modStyles.module}>
                <div style={{ overflow: "hidden" }}>
                    <h2 style={{ color: "#606060", marginTop: '1%', float: "left" }}>Payroll Rate Profile Details</h2>
                </div>
                <Menu size="mini" secondary stackable>
                    <Menu.Menu position="right" >
                        <Button.Group>
                            <Button content="Save" size="mini" icon="save"
                                onClick={this.handleSave.bind(this)} style={{ margin: "0px 3px 0px 0px" }} />
                            <Button content="Cancel" size="mini" icon="cancel"
                                onClick={this.props.onCloseForm.bind(this)} />
                        </Button.Group>
                    </Menu.Menu>
                </Menu>
                <Loader active={this.state.saving} />
                {this.state.showConflict &&
                    <Message negative>
                        <Message.Header>Conflict !</Message.Header>
                        <Message.Content>{this.state.conflict.map(n => {
                            return (<p>{n}</p>)
                        })}</Message.Content>
                    </Message>}
                <Grid stackable columns={4}>
                    <Grid.Row>
                        <Grid.Column> <label>Name</label></Grid.Column>
                        <Grid.Column>
                            <Input fluid placeholder='Name'
                                value={this.state.ItemData.Name}
                                onChange={this.handleChange("Name").bind(this, null)} />
                        </Grid.Column>
                        <Grid.Column><label>Description</label></Grid.Column>
                        <Grid.Column>
                            <Input fluid placeholder='Decription'
                                value={this.state.ItemData.Description}
                                onChange={this.handleChange("Description").bind(this, null)} />
                        </Grid.Column>

                    </Grid.Row>
                </Grid>
                <div>
                    <Card fluid>
                        <Card.Content>
                            <Card.Header>
                                Payroll Rates
                            </Card.Header>
                            <Table unstackable fluid>
                                <Table.Header>
                                    <Table.Row>
                                        <Table.HeaderCell width={8}>Description</Table.HeaderCell>
                                        <Table.HeaderCell width={4}>PAY equals</Table.HeaderCell>
                                        <Table.HeaderCell width={4}>Rate</Table.HeaderCell>
                                    </Table.Row>
                                </Table.Header>
                                <Table.Body>
                                    {this.state.ItemData.PayrollRates != null &&
                                        this.state.ItemData.PayrollRates.map(n => {
                                            return (
                                                <Table.Row>
                                                    <Table.Cell>{n.DescriptionFormated}</Table.Cell>
                                                    <Table.Cell>
                                                        <Input fluid placeholder='Computation'
                                                            value={n.Computation}
                                                            onChange={this.handleChange("Computation").bind(this, n)}
                                                        />
                                                    </Table.Cell>
                                                    <Table.Cell>
                                                        <Input fluid placeholder='Rate'
                                                            value={n.Rate}
                                                            onChange={this.handleChange("Rate").bind(this, n)}
                                                            error={this.state.showRequiredField && !checkInt32(n.Rate).Result || n.Rate == ""}
                                                            onKeyPress={(e) => { handleNumericOnKeyPress(e) }}
                                                            onKeyDown={(e) => { handleNumericOnKeyDown(e) }}
                                                        />
                                                    </Table.Cell>
                                                </Table.Row>
                                            )
                                        })
                                    }

                                </Table.Body>
                            </Table>
                        </Card.Content>
                    </Card>
                </div>
            </div>
            {/* Modal Form */}
            {<Modal size="mini" open={this.state.dialog} onClose={this.close} >
                <Header content={this.state.dialogTitle} />
                <Modal.Content>
                    {this.state.dialogContent}
                </Modal.Content>
                <Modal.Actions>
                    <Button basic icon="check" content="Yes"
                        onClick={this.onCloseDialog.bind(this)} />
                </Modal.Actions>
            </Modal>}
        </>
    }
}
export default PayrollRateProfileForm
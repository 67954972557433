import React, { Component } from 'react';
import { Loader } from 'semantic-ui-react';
import 'semantic-ui-css/semantic.min.css';
// import "react-table/react-table.css";
import { ajaxPost } from '../../ajax';
import DataGrid from 'react-data-grid';
import { TimeDisplayFormat, isTaskAvailable } from '../../utils';

import modStyles from './DTRSummaryList.module.css';
class DTRSummaryList extends Component {
  state = {
    Parameters: {
      CutoffId: "-1",
      needle: "",
      startingIndex: 0,
      itemCount: 20,
      totalPages: 1,
      advanceFilter: {
        companyIds: [],
        divisionIds: [],
        departmentIds: [],
        locationIds: [],
        positionIds: [],
        costcenterIds:[]
      },
    },
    loading: false,
    initialLoad: true,
    ActivePage: 1,
    PageSize: 100,
    DTRSummary: [],

    gridHeight: window.innerHeight - 320,
  }
  constructor(props) {
    super(props);
    this.state.Parameters = this.props.Parameters;
    this.state.initialLoad = this.props.initialLoad;
    this.state.DTRSummary = this.props.DTRSummary;
    this._columns = [
      { key: 'EmployeeNumber', name: 'Employee Number', locked: true, width: 150, resizable: true },
      { key: 'Employee', name: 'Employee', locked: true, width: 150, resizable: true },
      { key: 'Absent', name: 'Absent', width: 150 },
      // { key: 'LWP', name: 'Leave w/ Pay', width: 150},
      // { key: 'LWOP', name: 'Leave w/o Pay', width: 150},
      { key: 'HW', name: 'Reg Hours Work', width: 150 },
      { key: 'Late', name: 'Late', width: 150 },
      { key: 'UT', name: 'UT', width: 150 },
      // { key: 'OverBreak', name: 'OverBreak', width: 150},
    ];
    this._columns.push({ key: 'TotalOT', name: 'Total OT', width: 150 });
    this._columns.push(
      { key: 'OT', name: 'Reg OT', width: 100 },
      { key: 'ND', name: 'ND', width: 100 },
      { key: 'NDOT', name: 'NDOT', width: 100 },
      { key: 'RD', name: 'RD', width: 100 },
      { key: 'RDOT', name: 'RDOT', width: 100 },
      { key: 'RDND', name: 'RDND', width: 100 },
      { key: 'RDNDOT', name: 'RDNDOT', width: 100 },
      { key: 'HD', name: 'HD', width: 100 },
      { key: 'HDOT', name: 'HDOT', width: 100 },
      { key: 'HDND', name: 'HDND', width: 100 },
      { key: 'HDNDOT', name: 'HDNDOT', width: 100 },
      { key: 'HDRD', name: 'HDRD', width: 100 },
      { key: 'HDRDOT', name: 'HDRDOT', width: 100 },
      { key: 'HDRDND', name: 'HDRDND', width: 100 },
      { key: 'HDRDNDOT', name: 'HDRDNDOT', width: 100 },
      { key: 'SD', name: 'SD', width: 100 },
      { key: 'SDOT', name: 'SDOT', width: 100 },
      { key: 'SDND', name: 'SDND', width: 100 },
      { key: 'SDNDOT', name: 'SDNDOT', width: 100 },
      { key: 'SDRD', name: 'SDRD', width: 100 },
      { key: 'SDRDOT', name: 'SDRDOT', width: 100 },
      { key: 'SDRDND', name: 'SDRDND', width: 100 },
      { key: 'SDRDNDOT', name: 'SDRDNDOT', width: 100 },
      { key: 'DH', name: 'DH', width: 100 },
      { key: 'DHOT', name: 'DHOT', width: 100 },
      { key: 'DHND', name: 'DHND', width: 100 },
      { key: 'DHNDOT', name: 'DHNDOT', width: 100 },
      { key: 'DHRD', name: 'DHRD', width: 100 },
      { key: 'DHRDOT', name: 'DHRDOT', width: 100 },
      { key: 'DHRDND', name: 'DHRDND', width: 100 },
      { key: 'DHRDNDOT', name: 'DHRDNDOT', width: 100 },
      { key: 'TotalOT', name: 'Total OT', width: 150 });
    // }
  }
  componentWillMount() {
    window.addEventListener('resize', this.handleResize);
    this.handleResize = this.handleResize.bind(this);
    if (this.state.initialLoad === true) {
      var param = this.state.Parameters;
      this.onSearch(param);
    }
  }
  componentWillUnmount() {
    window.removeEventListener('resize', this.handleResize);
  }
  componentDidMount() {
    this.handleResize();
  }
  handleResize = () => {

    let headerMargin = 271;
    let footerMargin = 35;
    let gridTop = (document.getElementById("table")) == null ? 50 : (document.getElementById("table")).offsetTop;
    var gridHeight = window.innerHeight - (headerMargin + gridTop + footerMargin);
    this.setState({
      gridHeight,
    });
  }
  rowGetter = (i) => {
    return this.state.DTRSummary[i];
  };
  onSearch = (parameter) => {
    this.setState({ loading: true });
    this.LoadCutoffSummaries(data => {
      this.mapData(data.Content);
      var totalPages = data.Count / this.state.Parameters.itemCount;
      totalPages -= totalPages % 1;
      if (data.Count > totalPages * this.state.Parameters.itemCount) {
        totalPages++;
      }
      var parameters = this.state.Parameters;
      parameters.totalPages = totalPages;
      this.setState({
        loading: false,
        initialLoad: false,
        Parameters: parameters
      });
      this.props.updateState(this.state, "DTRSummaryList");
    }, parameter);
  }
  LoadCutoffSummaries = (callback, parameters, startIndex, itemCount) => {
    parameters["ViewTab"] = "DTR";
    ajaxPost({
      url: 'api/Cutoff/LoadCutoffSummary',
      data: {
        "Parameters": JSON.stringify(parameters)
      },
      onSuccess: data => {
        callback(data);
      },
      finally: () => { }
    })
  }
  mapData = (summaries) => {
    var SummaryViews = []
    var rowCount = 0;
    summaries.forEach(sum => {
      var summary =
      {
        EmployeeNumber: sum.EmployeeNumber,
        Employee: sum.LastName + ", " + sum.FirstName + " " + sum.MiddleName,
        Absent: sum.ABSENT,
        LWP: sum.LEAVEWITHPAY,
        LWOP: sum.LEAVEWITHOUTPAY,
        HW: TimeDisplayFormat(sum.RH),
        Late: TimeDisplayFormat(sum.LATE),
        UT: TimeDisplayFormat(sum.UNDERTIME),
        // OverBreak:TimeDisplayFormat(sum.OVERBREAK),
        TotalOT: TimeDisplayFormat(sum.TotalOT),
        OT: TimeDisplayFormat(sum.OT),
        ND: TimeDisplayFormat(sum.ND),
        NDOT: TimeDisplayFormat(sum.NDOT),
        RD: TimeDisplayFormat(sum.RD),
        RDOT: TimeDisplayFormat(sum.RDOT),
        RDND: TimeDisplayFormat(sum.RDND),
        RDNDOT: TimeDisplayFormat(sum.RDNDOT),
        HD: TimeDisplayFormat(sum.HD),
        HDOT: TimeDisplayFormat(sum.HDOT),
        HDND: TimeDisplayFormat(sum.HDND),
        HDNDOT: TimeDisplayFormat(sum.HDNDOT),
        HDRD: TimeDisplayFormat(sum.HDRD),
        HDRDOT: TimeDisplayFormat(sum.HDRDOT),
        HDRDND: TimeDisplayFormat(sum.HDRDND),
        HDRDNDOT: TimeDisplayFormat(sum.HDRDNDOT),
        SD: TimeDisplayFormat(sum.SD),
        SDOT: TimeDisplayFormat(sum.SDOT),
        SDND: TimeDisplayFormat(sum.SDND),
        SDNDOT: TimeDisplayFormat(sum.SDNDOT),
        SDRD: TimeDisplayFormat(sum.SDRD),
        SDRDOT: TimeDisplayFormat(sum.SDRDOT),
        SDRDND: TimeDisplayFormat(sum.SDRDND),
        SDRDNDOT: TimeDisplayFormat(sum.SDRDNDOT),
        DH: TimeDisplayFormat(sum.DH),
        DHOT: TimeDisplayFormat(sum.DHOT),
        DHND: TimeDisplayFormat(sum.DHND),
        DHNDOT: TimeDisplayFormat(sum.DHNDOT),
        DHRD: TimeDisplayFormat(sum.DHRD),
        DHRDOT: TimeDisplayFormat(sum.DHRDOT),
        DHRDND: TimeDisplayFormat(sum.DHRDND),
        DHRDNDOT: TimeDisplayFormat(sum.DHRDNDOT)
      }
      SummaryViews.push(summary);

    });
    this.setState({ DTRSummary: SummaryViews });
  }
  handleChangePage = (event, page) => {
    const startIndex = page.activePage - 1;
    this.setState({ Page: startIndex });
    this.setState({ ActivePage: page.ActivePage });
  }
  render() {
    return (
      <div className={modStyles.container}>
        <Loader active={this.state.loading} />
        <DataGrid
          columns={this._columns}
          rows={this.state.DTRSummary}
          rowsCount={this.state.DTRSummary.length}
          minHeight={this.state.gridHeight}
        />
      </div>
    );
  }
}
export default DTRSummaryList
import React, { Component } from 'react';
import { Button, Modal, Segment, Header, Message, List, ListItem } from 'semantic-ui-react';
import 'semantic-ui-css/semantic.min.css';
import Select from 'react-select';
import { ajaxPost } from '../../ajax';
var { fnSearchEmployeeFilter } = require('../../utils');
function empList(empList) {
    var list = [];
    if (empList != null) {
        empList.map(x => {
            return (
                list.push({ label: x.LastName + ', ' + x.FirstName + ' ' + (x.MiddleName === "" ? "" : x.MiddleName[0]) + "(" + x.EmployeeNo + ")", value: x.AccessNumber }))
        })
    }
    return list;
}
class UploadImageForm extends Component {
    state = {
        busy: false,
        changedPhoto: "",
        errorObj: {},
        employees: [],
        imageHeight: 0,
        imageWidth: 0,
        searchQuery: '',
        selectedAccessNumber: "",
        sequence: 0,
    }
    componentWillMount(){
        this.setState({ isLoading: true });
        fnSearchEmployeeFilter("", data => {
            this.setState({ employees: data, isLoading: false });
        }, 0, 20, false)
        this.searchTimer = null;
    }
    onSelectChange = (data) => {
        this.setState({ changedPhoto: "", selectedAccessNumber: data.value });
    }
    onEmpSearch = text => {
        clearTimeout(this.searchTimer);
        this.setState({ empSearch: text });
        this.searchTimer = setTimeout(this.doEmpSearch, 400, text);
    }
    doEmpSearch = (str, source) => {
        const { employees } = this.state;
        if (source != null && str != source.value) {
            setTimeout(this.doEmpSearch, 200, source.value, source);
            return;
        }
        this.setState({ loading: true });
        // var selectedAccessNumber = this.state.selectedAccessNumber ? this.state.selectedAccessNumber : "";
        // var selectedEmpIds = [];
        // if (selectedAccessNumber && employees != undefined)
        // {
        //     selectedEmpIds.push(selectedAccessNumber);
        // }
        fnSearchEmployeeFilter(str, data => {
            // selectedEmpIds.forEach(x => { if (!data.includes(x["_id"])) data.push(x) })
            this.setState({ employees: data, isLoading: false });
        }, 0, 20, false)
    }
    handleClose = () => {
        this.props.onClose(false);
    }
    handleAction = (event, data) => {
        event.preventDefault();
        var self = this;
        var errorObj = {};
        if (!this.state.selectedAccessNumber) {
            errorObj.Employee = "Select employee.";
        }
        this.setState(
            { errorObj },
            () => {
                if (Object.keys(errorObj).length > 0) {
                    return;
                }
                this.setState({ sequence: 1 }, () => {
                    const serialNumber = this.props.device.SerialNumber;
                    const accessNumber = this.state.selectedAccessNumber;
                    const changedPhoto = this.state.changedPhoto;
                    const url = "api/DeviceCommand/uploadComparisonPhoto";
        
                    var params = {
                        data: {
                            accessNumber, changedPhoto, serialNumber
                        },
                        url,
                        onSuccess: (result) => {
                            if (result === "NO PHOTO") {
                                errorObj.Photo = "Must first browse a NEW valid photo."
                                this.setState({ errorObj, sequence: 0 });
                            }
                            else if (!result) {
                                errorObj.Photo = "Error while saving photo."
                                this.setState({ errorObj, sequence: 0 });
                            }
                            else {
                                this.setState({ deviceCommandId: result, message: "Processing request..." }, () => {
                                    setTimeout(self.checkStatus, 100);                                    
                                });
                            }
                            // else {
                            //     this.setState({ deviceCommandId: result });
                            //     setTimeout(this2.checkStatus, 100);
                            // }
                        },
                        onFailed: (error) => {
                            errorObj.Error = "An error has occured.";
                            this.setState({ errorObj });
                        },
                        finally: () => {
                        }
                    }
                    ajaxPost(params);
                });
            }
        )
    }
    renderComparisonPhoto = () => {
        const imageUri =
        this.state.changedPhoto === "" ?
            (global.ApiHost + "api/zkuserinfo/getComparisonPhoto?accessNumber=" + this.state.selectedAccessNumber + "&serialNumber" + this.props.device.SerialNumber)
            : this.state.changedPhoto;
        return (
            // <Segment color={this.state.errors.has(Fields.PHOTO) ? 'red' : undefined}
                <Segment
                    textAlign="center"
                    style={
                        // this.props.windowSize !== WindowSize.SMALL ?
                            {
                                maxHeight: 300 + 36,
                                maxWidth: 300
                            }
                        //   :  {}
                    }
                >
                    <img
                        src={imageUri}
                        crossOrigin="anonymous"
                        style={{
                            height: this.state.imageHeight === 0 ? 250 : this.state.imageHeight,
                            width: this.state.imageWidth === 0 ? 250 : this.state.imageWidth
                        }}
                    />
                    <br />
                    <Button secondary={true} disabled={this.state.selectedAccessNumber === "" || this.state.sequence > 0} icon="upload" content="Browse..." onClick={this.onUploadPhoto} />
                </Segment>
        );
    }
    onUploadPhoto = () => {
        document.getElementById('myFile').click();
    }
    fileChangedHandler = (event) => {
        const img = event.target.files[0];
        this.readDataUrl(img, this.onLoadImage);
    }
    readDataUrl = (img, callback) => {
        if (!img) {
            return;
        }
        const reader = new FileReader();
        reader.onload = () => {
            const dataURL = reader.result;
            callback(dataURL);
        }
        reader.readAsDataURL(img);
    }
    onLoadImage = (result) => {
        const self = this;
        const image = new Image();
        image.addEventListener("load", function () {
            let width = this.naturalWidth;
            let height = this.naturalHeight;
            if (height > width) {
                // scale down based on height
                const scale = height / 250;
                width = width / scale;
                height = 250;
            }
            else {
                // scale down based on width
                const scale = width / 250;
                height = height / scale;
                width = 250;
            }
            self.setState({
                errorObj: {},
                imageHeight: height,
                imageWidth: width
            });
        });
        image.src = result;
        this.setState({ changedPhoto: result });
    }

    checkStatus = () => {
        var self = this;
        
		var searchParameter = {
			data: [this.state.deviceCommandId],
			url: "api/DeviceCommand/getStatus",
			onError: (error) => { },
			onSuccess: (data, sender) => {
                if (data === undefined) {
                    return;
                }
                const result = data[0];
                if (data.length === 0 || result.Pending === 0) {
                    const msg = "Uploading of image has completed " +
                        (result === undefined || result.Failed === 0 ? "successfully" : ("with " + result.Failed + " error"))
                    this.setState({ message: msg });
                }
                else {
                    this.setState(
                        { message: "Processing request... (" + result.Pending + " item/s)" },
                        () => {
                            setTimeout(self.checkStatus, 1000);
                        }
                    );
                }
			},
			finally: () => { }
		};
		ajaxPost(searchParameter);
    }


    render = () => {
        const { errorObj } = this.state; 

        return (
            <Modal open size='tiny'>
                <Modal.Header>Upload Comparison Photo</Modal.Header>
                <Modal.Content>
        <           Modal.Description>Upload to '{this.props.device.DeviceName}' device</Modal.Description>
                    <input type="file" id='myFile' onChange={this.fileChangedHandler} style={{ display: 'none' }} />
                    <Select 
                        isDisabled={this.props.isBusy || this.state.sequence > 0}
                        options={empList(this.state.employees)}
                        value={this.state.employees.filter(x => x.value === this.state.selectedAccessNumber)[0]}
                        onChange={this.onSelectChange}
                        onInputChange={this.onEmpSearch}
                        isLoading={this.state.isLoading} 
                    />
                    <Header as="h4">Preview</Header>
                    {this.renderComparisonPhoto()}
                    {
                        this.state.sequence > 0 && this.state.message &&
                        <p>{this.state.message}</p>
                    }
                    {
                        Object.keys(errorObj).length > 0 &&
                        <Message error={true}>
                            <List bulleted={true}>
                                {
                                    Object.keys(errorObj).map((key, index) => {
                                        return (
                                            <ListItem key={index}>{errorObj[key]}</ListItem>
                                        );
                                    })
                                }
                            </List>
                        </Message>
                    }

                </Modal.Content>
                <Modal.Actions>
                    {
                        this.state.sequence === 0 &&
                        <Button basic icon='check' disabled={this.state.busy}
                            content='Upload' oading={this.state.busy} onClick={this.handleAction.bind(this)}>
                        </Button>
                    }
                    {

                    }
                    <Button basic icon='cancel' content={this.state.sequence === 0 ? 'Cancel' : 'Close'} onClick={this.handleClose} disabled={this.state.busy}></Button>
                </Modal.Actions>
            </Modal>
        )
    }
}
export default UploadImageForm
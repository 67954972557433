import React, { Component } from "react";
import Select from "react-select";
import EmployeeImage from "../../Commons/EmployeeImage";
import {
  Button,
  Checkbox,
  Icon,
  Input,
  Label,
  List,
  Message,
  Modal,
  Tab,
  Sidebar,
  Menu,
  Header
} from "semantic-ui-react";
import { ajaxPost } from "../../ajax";
import MessageBoxOkOnly from "../../Commons/MessageBoxOkOnly";
import MessageBoxYesNo from "../../Commons/MessageBoxYesNo";
import DynamicSideBarForm from "./DynamicSideBarForm";
import {
  fnGetEmployeeImagePath,
  fnLoadZKUserInfoByAccessNumber,
  fnSearchEmployeeFilter,
  isTaskAvailable,
} from "../../utils";
import "../../w3.css";
import MessageBoxSaveClose from "../../Commons/MessageBoxSaveClose";

import { renderEmployeeDetails } from './EmployeeLoadDetails';

import {
  selectionList
} from './EmployeeCommon'

import modStyles from './EmployeeLoad.module.css'
import { renderEmployeePayroll } from "./EmployeeLoadPayroll";
import { renderEmployeeDevice } from "./EmployeeLoadDevice";
import { getEligibilityOptions, loadEligibilities, populateEligibilityDropDown } from "./Eligibilities";

require("dotenv").config({ path: "./.env" });
var moment = require("moment");
var {
  handleNumericOnKeyPress,
  handleNumericOnKeyDown,
  empty_id,
  customStyles,
  fullName,
  errorStyles,
  transformToTitle,
} = require("../../utils");


function replaceAt(str, index, replacement) {
  var endChar = "";
  if (str.length - 1 !== index) {
    endChar = str.slice(index + 1, str.length);
  }
  return str.slice(0, index) + replacement + endChar;
}

var isSubscribed = "";

const newGroup = {
  _id: empty_id,
  Name: "",
  Type: "",
  Deleted: false,
  OtherDetails: [],
};

const defaultDetails = {
  Code: "",
};

const BlankEmployee = {
  EmployeeNo: "",
  AccessNumber: "",
  LastName: "",
  FirstName: "",
  MiddleName: "",
  Suffix: "",
  Image: "default.jpg",
  BirthDate: "",
  DateHired: "",
  RegularizationDate: "",
  ResignationDate: "",
  TelephoneNo: "",
  MobileNo: "",
  Email: "",
  Deleted: false,
  Password: "",
  UserRoleId: "97b3bbba-8ec3-4474-af69-6d3c7b973546",
  LeaveProfileId: empty_id,
  CompanyId: empty_id,
  DivisionId: empty_id,
  DepartmentId: empty_id,
  PositionId: empty_id,
  CityId: empty_id,
  CostCenterId: empty_id,
  SupervisorId: empty_id,
  ReportingTo: empty_id,
  Nickname: "",
  Nationality: "",
  CivilStatus: "",
  Height: 0,
  Weight: 0,
  Gender: "",
  Religion: "",
  BloodType: "",
  ReferredBy: empty_id,
  InternalReferral: false,
  ReferralRemarks: "",
  ReferralPayoutDate: "",
  file: "",
  IsReset: false,
  Locked: false,
  EmploymentStatus: "",
};

const BlankZKUserInfo = {
  EmployeeId: "",
  AccessNumber: "",
  Password: "",
  RFID: "",
  MIFAREID: "",
  Privilege: 0,
  FPCount: 0,
  FaceCount: 0,
  PalmCount: 0,
  Name: "",
};
const BlankPayrollInfo = {
  _id: empty_id,
  EmployeeId: "",
  AlphanumericTaxCodeProfileId: empty_id,
  PayrollProfileId: empty_id,
  RateProfileId: empty_id,
  SalaryType: "",
  BasicPay: 0,
  Allowance: 0,
  HazardPay: 0,
  SpecialAllowance: 0,
  Deminimis: 0,
  Cola: 0,
  BankId: empty_id,
  BankAccountNumber: "",
  BankAccountType: "",
  PagibigBased: "GROSS",
  PhilHealthBased: "BASIC",
  SSSBased: "GROSS",
  GSISBased: "BASIC",
  TaxBased: "GROSS",
  Pagibig: 0,
  PagibigMP2: 0,
  PhilHealth: 0,
  SSS: 0,
  GSIS: 0,
  Tax: 0,
};


const companyDetails = {
  Code: "",
  TelephoneNo: "",
  MobileNo: "",
  Email: "",
  Website: "",
  UnitNo: "",
  BlockNo: "",
  BuildingName: "",
  StreetName: "",
  District: "",
  Subdivision: "",
  City: "",
  Province: "",
  ZipCode: "",
  TINNumber: "",
  SSSNumber: "",
  HDMFNumber: "",
  PhilhealthNumber: "",
  SSSBranchCode: "",
  HDMFBranchCode: "",
  PhilhealthBranchCode: "",
  RDOCode: "",
  ContactNumber: "",
  BankID: empty_id,
  AccountNumber: "",
  LineOfBusiness: "",
};


class EmployeeLoad extends Component {
  dt = moment().format("YYYY-MM-DD");

  focusRequiredField(fieldName) {
    this[fieldName].focus();
  }

  constructor(props) {
    super(props);

    isSubscribed = process.env.REACT_APP_CLIENT_SUBSCRIBED;

    this.state = {
      action: "Load",
      fullName: "",
      selectedEligibility: null,
      eligibilityIndex: -1,
      eligibilityDisplay: "",
      eligibilityOptions: [],
      eligibilityUsed: {},
      userRoleList: [],
      leaveProfileList: [],
      groups: [],
      supervisorList: [],
      reqFields: [],

      employeeNoIsError: false,
      lastNameIsError: false,
      firstNameIsError: false,
      accessNumberIsError: false,
      passwordIsError: false,
      emailIsError: false,

      payrollProfileError: false,
      payrollRateProfileError: false,

      isImageHover: false,
      modalOpen: false,

      isFirstLoad: false,
      employeeList: [],
      eligibilityList: [],
      eligibilityDeleteList: [],
      isZKUserInfoSave: false,
      isEligibilitiesSave: false,
      isEligibilitiesRemove: false,
      isPositionMovementSave: false,
      isPositionMovementRemove: false,

      payrollProfiles: [],
      payrollRateProfiles: [],
      banks: [],
      isPayrollInfoSave: false,

      eligibilityList: [],
      prevEligibilityList: [],
      payrollInfo: JSON.parse(JSON.stringify(BlankPayrollInfo)),
      prevPayrollInfo: JSON.parse(JSON.stringify(BlankPayrollInfo)),
      zkUserInfo: JSON.parse(JSON.stringify(BlankZKUserInfo)),
      prevZKUserInfo: JSON.parse(JSON.stringify(BlankZKUserInfo)),

      errModalOpen: false,
      errMessage: "",
      errTitle: "",

      isSuperAdmin: false,

      isReferralEmpLoading: false,
      referralList: [],
      showDeleteModal: false,

      focusField: "",
      activeIndex: 0,
      popUpContent: "",
      ShowPassword: false,
      ShowZkPassword: false,

      //state for position assignment modal
      positionMovement: [],
      posMovementTabIndex: 0,
      prevPositionMovement: [],
      positionMovementModalOpen: false,
      positionMovementIndex: -1,
      positionMovementDeleteList: [],
      selectedPositionMovement: {},
      positionProfileList: [],
      selectedPositionProfile: null,
      selectedPositionProfileOptions: {},
      selectedCityOptions: {},
      isPositionProfileLoading: false,
      atcProfileList: [],
      selectedATCProfile: null,
      cityList: [],
      selectedCity: empty_id,
      isCityLoading: false,
      supervisorList: [],
      supervisorIds: [],
      selectedSupervisor: null,
      isSupervisorLoading: false,

      activePage_PositionMovement: 1,

      selectedPayProfile: null,
      selectedRateProfile: null,
      selectedBank: null,
      payrollProfiles: [],
      payrollRateProfiles: [],
      banks: [],
      PerRate: "",
      showPagibigAmount: false,
      showPhilhealthAmount: false,
      showSssAmount: false,
      showGSISAmount: false,
      showTaxAmount: false,

      showFullPositionWarning: false,
      sideBarOpen: false,
      sideBarTitle: "",
      displayGroup: "",
      selectedGroup: null,
      showAskCloseDialog: false,
      redirectModule: "",
      setting: {},

      //warning modal
      warnModalOpen: false,
      warnTitle: "",
      warnMessage: "",
      skipValidation: false,
    };

    if (
      !(
        this.props.params.selectedEmployee === undefined ||
        this.props.params.selectedEmployee.length === 0
      )
    ) {
      this.state.selectedEmployee = JSON.parse(
        JSON.stringify(this.props.params.selectedEmployee)
      );
      this.state.selectedEmployee["Password"] = "";
      this.state.prevEmployee = JSON.parse(
        JSON.stringify(this.props.params.selectedEmployee)
      );
    } else {
      this.state.selectedEmployee = JSON.parse(JSON.stringify(BlankEmployee));
      this.state.prevEmployee = JSON.parse(JSON.stringify(BlankEmployee));
    }

    this.state.employeeList = JSON.parse(
      JSON.stringify(this.props.params.employeeList)
    );
    this.state.userId = this.props.params.userId;
    this.state.isSuperAdmin = this.props.params.isSuperAdmin;
    this.state.referredBy = this.state.selectedEmployee.ReferredBy;

    var elgbtyType = {
      IDENTIFICATION: window.innerWidth > 768 ? "Identifications" : "ID's",
      ADDRESS: "Address",
      FAMILY_BACKGROUND:
        window.innerWidth > 768 ? "Family Background" : "Fam. Backg.",
      EDUCATIONAL_BACKGROUND:
        window.innerWidth > 768 ? "Educational Background" : "Educ. Backg.",
      WORK_EXPERIENCE:
        window.innerWidth > 768 ? "Work Experience" : "Work Exp.",
      // MEDICAL_RECORD: window.innerWidth > 768 ? "Medical Record" : "Med. Rec.",
      DOCUMENTS: window.innerWidth > 768 ? "Documents" : "Docs",
      TRAINING: "Training",
      OTHER_INFO: "Other Info",
      CHARACTER_REFERENCE:
        window.innerWidth > 768 ? "Character Reference" : "Char. Ref.",
      //CERTIFICATES: "Certificates",
    };

    Object.keys(elgbtyType).map((type) => {
      return (this.state["activePage_" + type] = 1);
    });

    this.state.eligibilityType = elgbtyType;

    if (this.state.selectedEmployee !== undefined) {
      this.state.fullName =
        this.state.selectedEmployee.LastName +
        ", " +
        this.state.selectedEmployee.FirstName +
        " " +
        this.state.selectedEmployee.MiddleName;
    }

    if (this.props.params.mainState !== undefined) {
      this.state.mainState = this.props.params.mainState;
      this.state.mainState.openPopUp = false;
    } else this.state.mainState = this.props.params;
  }

  componentWillMount = () => {
    if (
      this.props.prevState !== undefined &&
      this.props.prevState !== null &&
      this.props.prevState.action !== "List"
    )
      this.state = this.props.prevState;

    if (this.props.prevState === undefined || this.props.prevState === null) {
      this.loadUserRoleList();
      this.loadLeaveProfileList();
    }

    this.setState({ showPopUp: false, errModalOpen: false });
    this.loadReferralList("");
    this.loadEmployeeDetails();

    this.loadPayrollProfiles((data) => {
      this.setState({
        payrollProfiles: data,
      });
    });
    this.loadATCProfile("");

    this.loadGroups();
    this.loadSupervisor("", this.state.selectedEmployee.SupervisorId);
    this.loadPayrollRateProfiles((data) => {
      this.setState({
        payrollRateProfiles: data,
      });
    });
    this.loadPayrollBanks((data) => {
      this.setState({
        banks: data,
      });
    });
    this.LoadSettings();
  };

  componentDidUpdate = () => {
    if (
      !this.state.errModalOpen &&
      !this.state.isSaving &&
      this.state.focusField !== ""
    ) {
      if (
        this.state.focusField === "AccessNumber" &&
        this.state.activeIndex !== 1
      ) {
        this.setState({ activeIndex: 1 });
      } else if (this.state.focusField === "AccessNumber") {
        this.focusRequiredField(this.state.focusField);
        this.setState({ focusField: "" });
      } else {
        if (this.state.activeIndex !== 0) {
          this.setState({ activeIndex: 0 });
        } else {
          this.focusRequiredField(this.state.focusField);
          this.setState({ focusField: "" });
        }
      }
    }
  };

  LoadSettings = () => {
    var searchParameter = {
      data: null,
      url: "api/Setting/search",
      onError: (error) => {
        alert("error\r\n" + JSON.stringify(error));
      },
      onSuccess: (data, sender) => {
        var content = data.content;
        content["AutoResetPassword"] =
          content["AutoResetPassword"].toLowerCase() === "false" ? false : true;
        this.setState({ setting: content });
      },
      finally: () => { },
    };
    ajaxPost(searchParameter);
  };

  componentWillUnmount = () => {
    this.state.prevState = this.state;
    this.props.unmountCallback(this.state, "EmployeeModule");
  };
  loadGroups = () => {
    ajaxPost({
      url: "api/Group/search",
      data: {
        Search: "",
        includeDeleted: false,
        startingIndex: 0,
        itemCount: 1000,
      },
      onSuccess: (data) => {
        this.setState({ groups: data.content });
      },
      finally: () => { },
    });
  };
  loadUserRoleList = () => {
    this.setState({
      userRoleList: [],
      isUserRoleLoading: true,
    });
    ajaxPost({
      url: "api/User/ListMinimal",
      data: {},
      onSuccess: (data) => {
        this.setState({ userRoleList: data });
      },
      finally: () => {
        this.setState({ isUserRoleLoading: false });
      },
    });
  };

  loadEmployeeDetails() {
    var employeeIds = [];
    var { selectedEmployee } = this.state;
    if ("_id" in selectedEmployee) {
      employeeIds.push(selectedEmployee._id);

      this.setState({
        isEligibilityLoading: true,
        isZKUserInfoLoading: true,
        isPayrollInfoLoading: true,
      });

      ajaxPost({
        url: "api/Eligibility/search",
        data: {
          Search: "",
          employeeId: selectedEmployee._id,
          startingIndex: 0,
          itemCount: 1000,
          includeDeleted: false,
        },
        onSuccess: (data) => {
          this.setState({
            eligibilityList: JSON.parse(JSON.stringify(data.content)),
            prevEligibilityList: JSON.parse(JSON.stringify(data.content)),
            isEligibilityLoading: false,
          });
        },
        finally: () => { },
      });
      ajaxPost({
        url: "api/PayrollInfo/loadByEmployeeId",
        data: {
          employeeId: selectedEmployee._id,
        },
        onSuccess: (data) => {
          if (data !== null) {
            this.setState({
              payrollInfo: JSON.parse(JSON.stringify(data)),
              prevPayrollInfo: JSON.parse(JSON.stringify(data)),
              showPhilhealthAmount: data.PhilHealthBased == "FIX_AMOUNT",
              showSssAmount: data.SSSBased == "FIX_AMOUNT",
              showPagibigAmount: data.PagibigBased == "FIX_AMOUNT",
              showTaxAmount: data.TaxBased == "FIX_AMOUNT",
              showGSISAmount: data.GSISBased == "FIX_AMOUNT",
            });
          }
          this.setState({
            isPayrollInfoLoading: false,
          });
        },
        finally: () => { },
      });

      fnLoadZKUserInfoByAccessNumber(
        selectedEmployee.AccessNumber,
        (zkInfo) => {
          if (zkInfo !== null) {
            this.setState({
              zkUserInfo: JSON.parse(JSON.stringify(zkInfo)),
              prevZKUserInfo: JSON.parse(JSON.stringify(zkInfo)),
            });
          }
          this.setState({ isZKUserInfoLoading: false });
        }
      );
    }
  }

  loadLeaveProfileList = () => {
    this.setState({
      leaveProfileList: [],
      isLeaveProfileLoading: true,
    });
    ajaxPost({
      url: "api/LeaveProfile/ListMinimal",
      data: {},
      onSuccess: (data) => {
        // data.unshift({ _id: "", Description: "None" }); // insert dummy
        this.setState({ leaveProfileList: data });
      },
      finally: () => {
        this.setState({ isLeaveProfileLoading: false });
      },
    });
  };

  handleChange = (name, isNumericOnly, errorState, event) => {
    var numberEntry = "1234567890.";
    var newValue = event.target.value;

    if (
      isNumericOnly &&
      event.target.value != null &&
      !numberEntry.includes(newValue[newValue.length - 1])
    ) {
      newValue = newValue.slice(0, newValue.length - 1);
    } else if (
      isNumericOnly &&
      event.target.value != null &&
      newValue.split(".").length - 1 > 1
    ) {
      newValue = newValue.slice(0, newValue.length - 1);
    }

    if (isNumericOnly && newValue.startsWith("0"))
      newValue = newValue.slice(1, newValue.length);

    if (isNumericOnly && newValue === "") newValue = 0;

    var current = this.state.selectedEmployee;
    current[name] = newValue;
    this.setState({ selectedEmployee: current });

    if (errorState !== "" && newValue === "")
      this.setState({ [errorState]: true });
    else if (errorState !== "") this.setState({ [errorState]: false });
  };

  handleContactNumberChange = (name, event) => {
    var allowedChar = "+1234567890; ";
    var newValue = event.target.value;

    for (let index = 0; index < newValue.length; index++) {
      if (allowedChar.includes(newValue.charAt(index)) === false)
        newValue = replaceAt(newValue, index, "");
    }

    if (event.target.value != null && newValue.split("+").length - 1 > 1) {
      newValue = newValue.slice(0, newValue.length - 1);
    }

    var current = this.state.selectedEmployee;
    current[name] = newValue;
    this.setState({ selectedEmployee: current });
  };

  handleDropDownChange = (name) => (event) => {
    var current = this.state.selectedEmployee;
    var newValue = "";
    if (
      name.includes("Id") ||
      name.includes("ID") ||
      name.includes("ReportingTo")
    ) {
      newValue = empty_id;
    }
    if (event != null) {
      if (event.value != null) {
        newValue = event.value;
      } 
    }

    if (name.indexOf(".") > -1) {
      const splittedName = name.split(".");
      current[splittedName[0]][splittedName[1]] = newValue;
    } else {
      current[name] = newValue;
    }

    if (name === "ReportingTo") {
      current.SupervisorId = empty_id;
    }

    this.setState({ selectedEmployee: current }, () => {
      if (name === "ReportingTo") {
        this.setState({ selectedSupervisor: null }, () => {
          this.loadSupervisor("");
        });
      }
    });
  };

  handleDropDownUserInfoChange = (name) => (event) => {
    var current = this.state.zkUserInfo;
    if (event != null) {
      current[name] = event.value;
    } else {
      current[name] = "";
    }

    this.setState({ zkUserInfo: current });
  };

  handleZKUserChange = (name) => (event) => {
    var current = this.state.zkUserInfo;
    current[name] = event.target.value;
    this.setState({ zkUserInfo: current });
  };

  onAccessNumberChange = (name) => (event) => {
    const allowedChar = "1234567890";
    var input = event.target.value;

    for (let index = 0; index < input.length; index++) {
      if (allowedChar.includes(input.charAt(index)) === false)
        input = replaceAt(input, index, "");
    }

    var current = this.state.zkUserInfo;
    current[name] = input;
    this.setState({ zkUserInfo: current });

    if (event.target.value === undefined || event.target.value === "") {
      this.setState({ accessNumberIsError: true });
    } else {
      this.setState({ accessNumberIsError: false });
    }

    var currEmployee = this.state.selectedEmployee;
    currEmployee[name] = input;
    this.setState({ selectedEmployee: currEmployee });
  };

  onEligibilityDetailChange = (name) => (event) => {
    var current = this.state.selectedEligibility;
    if (event === undefined || event.target.value === "") {
      current.Details[name] = "";
      if (this.state.eligibilityUsed[name].isRequired)
        this.setState({ ["isError" + current.Type + name]: true });
    } else {
      current.Details[name] = event.target.value;
      this.setState({ ["isError" + current.Type + name]: false });
    }

    this.setState({ selectedEligibility: current });
  };

  handleInternalReferralChange = (evt) => {
    var current = this.state.selectedEmployee;
    current["InternalReferral"] = !current["InternalReferral"];
    this.setState({ selectedEmployee: current });
  };

  loadReferralList = (str) => {
    this.setState({ isReferralEmpLoading: true });
    var referralList = [];

    if (!(this.state.referredBy === "" || this.state.referredBy === empty_id)) {
      referralList.push(this.state.referredBy);
    } else {
      referralList = null;
    }

    fnSearchEmployeeFilter(
      str,
      (data) => {
        this.setState({
          referralList: data,
          isReferralEmpLoading: false,
        });
      },
      0,
      10,
      false,
      null,
      referralList,
      true
    );
  };
  loadPayrollProfiles = (callback) => {
    ajaxPost({
      url: "api/PayrollProfile/payrollProfileNames",
      data: {
        ProfileName: 1,
        _id: 1,
      },
      onSuccess: (data) => {
        callback(data);
      },
      finally: () => { },
    });
  };
  loadPayrollRateProfiles = (callback) => {
    ajaxPost({
      url: "api/PayrollRateProfile/payrollRateProfileNames",
      data: {
        RateProfileNames: 1,
        _id: 1,
      },
      onSuccess: (data) => {
        callback(data);
      },
      finally: () => { },
    });
  };
  loadPayrollBanks = (callback) => {
    ajaxPost({
      url: "api/PayrollBank/payrollBankNames",
      data: {
        Description: 1,
        _id: 1,
      },
      onSuccess: (data) => {
        callback(data);
      },
      finally: () => { },
    });
  };
  handleReferralInputKeyDown = (source) => {
    clearTimeout(this.referralTimer);
    this.setState({ referredBy: "" });    
    if (!(source.target === undefined || source.target === "")) {
      this.referralTimer = setTimeout(
        this.loadReferralList,
        500,
        source.target.value
      );
    } else if (source.target !== undefined || source.target === "") {
      this.referralTimer = setTimeout(this.loadReferralList, 500, "");
    } 
    var current = this.state.selectedEmployee;
    current["ReferredBy"] = null;
    this.setState({ selectedEmployee: current }); 
  };

  handleReferralChange = (source) => {
    var current = this.state.selectedEmployee;
    if (source === undefined || source === null) {
      current["ReferredBy"] = empty_id;
      this.setState({ selectedEmployee: current }, this.loadReferralList(""));
      return;
    }

    if (!(source.value === undefined || source === null)) {
      current["ReferredBy"] = source.value;
      this.setState({ selectedEmployee: current });
    }
  };

  handleReferralSearch = (source) => {
    var current = this.state.selectedEmployee;
    if (source.target === undefined && source !== ""){
      fnSearchEmployeeFilter(
        source,
        (data) => {
          this.setState({
            referralList: data,
            isReferralEmpLoading: false,
          });
        },
        0,
        10,
        false,
        null,
        null,
        true
      );
    }
  };

  onDocChangedHandler = (event) => {
    var img = event.target.files[0];
    if (img === undefined) return;
    if (img.size > 3145728) {
      alert("File too large");
        return;
    } 
    else if(img.size === 0){
        alert("Image too small. File must be not 0MB");
        return;      
    }
    else this.docReadDataUrl(img, this.docOnload.bind(this));
  };

  docReadDataUrl(img, callback) {
    var reader = new FileReader();
    reader.onload = function () {
      var dataURL = reader;
      callback(dataURL, img.name);
    };
    reader.onerror = function (error) {
      console.log("Error: ", error);
    };
    reader.readAsDataURL(img);
  }

  docOnload(reader, name) {
    var current = this.state.selectedEligibility;
    if (reader === undefined) {
      current.Details["Source"] = "";
      current.Details["File"] = "";
    } else {
      current.Details["Source"] = reader.result;
      current.Details["File"] = name;
    }

    this.setState({ selectedEligibility: current });
  }

  downloadDoc(filename, origName) {
    window.location =
      global.ApiHost +
      "api/Eligibility/download?" +
      "fileName=" +
      filename +
      "&" +
      "originalName=" +
      origName;
  }

  onDropDownChange = (name) => (event) => {
    var current = this.state.selectedEligibility;
    if (
      event === undefined ||
      (event.length !== undefined && event.length === 0)
    ) {
      current.Details[name] = "";
      if (this.state.eligibilityUsed[name].isRequired)
        this.setState({ ["isError" + current.Type + name]: true });
    } else {
      current.Details[name] = event.value;
      this.setState({ ["isError" + current.Type + name]: false });
    }

    this.setState({ selectedEligibility: current });
  };

  eligibilityAdd = () => {
    var eligibility = this.state.selectedEligibility;
    var usedDetails = this.state.eligibilityUsed;
    var isError = false;
    Object.keys(usedDetails).forEach((item) => {
      if (
        eligibility.Details[item] === usedDetails[item].initialValue &&
        usedDetails[item].isRequired
      ) {
        isError = true;
        this.setState({ ["isError" + eligibility.Type + item]: true });
      }
    });

    if (isError) {
      this.setState({
        errModalOpen: true,
        errMessage: "Please fill up all required fields",
        errTitle: "Error",
      });
      return;
    }

    if (eligibility["Type"] == "EDUCATIONAL_BACKGROUND") {
      if (
        eligibility.Details.StartDate !== "" &&
        eligibility.Details.EndDate !== "" &&
        eligibility.Details.To_Date < eligibility.Details.From_Date
      ) {
        this.setState({
          errModalOpen: true,
          errMessage: "From Date must be less than To Date",
          errTitle: "Error",
        });
        return;
      }
    }

    var { eligibilityList } = this.state;
    if (this.state.eligibilityIndex === -1) {
      eligibilityList.push(eligibility);
    } else {
      eligibilityList[this.state.eligibilityIndex] = JSON.parse(
        JSON.stringify(eligibility)
      );
    }
    this.setState({ modalOpen: false, eligibilityList });
  };

  backClick = (action) => {
    var { mainState } = this.state;
    mainState.popUpContent = "";
    mainState.showPopUp = false;

    // if (action !== null) {
    //     this.setState({ popUpContent: "", showPopUp: false, mainState }, () => this.props.callback.raaaaaeturnData("", "", "", action, this.state.mainState));
    //     return;
    // }

    if (
      this.props.params.referer != null &&
      this.props.params.referer == "Dashboard"
    ) {
      action = "Dashboard";
    }
    this.setState(
      {
        showAskCloseDialog: false,
        popUpContent: "",
        showPopUp: false,
        mainState,
      },
      () =>
        this.props.callback.returnData("", "", "", action, this.state.mainState)
    );
  };

  test = () => {
    ajaxPost({
      url: "api/Employee/loadEmployeeBasicInfoById",
      data: "5b6c02341444682ee8607133",
      onSuccess: (data) => { },
      finally: () => { },
    });
  };

  saveClick = () => {
    this.setState({ showPopUp: false, errModalOpen: false }, () => {
      var newEmpList = this.state.employeeList;
      var hasMissingReqField = false;
      var content = "";
      var focusField = "";
      var email = this.state.selectedEmployee.Email;
      var emailInvalidMessage = "";

      if (this.state.selectedEmployee.LastName === "") {
        this.setState({ lastNameIsError: true });
        if (content !== "") content += " , ";

        if (focusField === "") focusField = "LastName";
        content += "Last Name";
        hasMissingReqField = true;
      }

      if (this.state.selectedEmployee.FirstName === "") {
        this.setState({ firstNameIsError: true });
        if (content !== "") content += " , ";

        if (focusField === "") focusField = "FirstName";
        content += "First Name";
        hasMissingReqField = true;
      }

      if (this.state.selectedEmployee.EmployeeNo === "") {
        this.setState({ employeeNoIsError: true });

        if (content !== "") content += " , ";

        if (focusField === "") focusField = "EmployeeNo";

        content += "Employee Number";
        hasMissingReqField = true;
      }

      if (
        this.state.selectedEmployee.AccessNumber === "" &&
        (isTaskAvailable("EMPLOYEE_DEVICE_EDIT") || this.state.isSuperAdmin)
      ) {
        this.setState({ accessNumberIsError: true });
        if (content !== "") content += " , ";

        if (focusField === "") focusField = "AccessNumber";

        content += "Access Number";
        hasMissingReqField = true;
      }

      if (
        this.state.selectedEmployee.Password === "" &&
        this.state.selectedEmployee._id === undefined
      ) {
        this.setState({ passwordIsError: true });
        if (content !== "") content += " , ";

        if (focusField === "") focusField = "Password";
        content += "Password";
        hasMissingReqField = true;
      }

      if (hasMissingReqField) {
        var blankError = "";
        if (content != "")
          blankError = "Please fill up required fields (" + content + ")";

        if (emailInvalidMessage != "") {
          if (blankError !== "") blankError += "\n";

          blankError += emailInvalidMessage;
        }

        this.setState({
          errModalOpen: true,
          errTitle: "Save Employee",
          errMessage: blankError,
        });
        this.setState({ focusField });
        return;
      }

      if (hasMissingReqField == false) {
        const requireFields = [];

        if (this.state.payrollInfo.PayrollProfileId === empty_id) {
          //   this.setState({ payrollProfileError: true });
          requireFields.push("Payroll Profile");
        }
        if (this.state.payrollInfo.RateProfileId === empty_id) {
          //   this.setState({ payrollRateProfileError: true });
          requireFields.push("Payroll Rate Profile");
        }

        if (requireFields.length > 0) {
          var blankError =
            "The following field(s) are required for the employee to be included in payroll computations.";
          this.setState({
            warnModalOpen: true,
            warnTitle: "Save Employee",
            warnMessage: blankError,
            reqFields: requireFields,
          });
          this.setState({ focusField });
          return;
        }
      }
      this.setState({ isSaving: true }, () => {
        this.saveAsAdmin(newEmpList);
      });
    });
  };

  saveAsAdmin(newEmpList) {
    var employee = this.state.selectedEmployee;
    var { mainState, zkUserInfo } = this.state;
    delete employee["FullName"];

    const capsCharacter = "QWERTYUIOPASDFGHJKLZXCVBNM";
    const regCharacter = "qwertyuiopasdfghjklzxcvbnm";
    const numeric = "1234567890";
    var hasCaps = false,
      hasRegChar = false,
      hasNumeric = false;

    if (!("_id" in employee)) {
      if (employee.Password.length < 8) {
        this.setState({
          isSaving: false,
          errModalOpen: true,
          errTitle: "Invalid",
          errMessage: "Password must contains atleast 8 characters",
        });
        this.setState({ focusField: "Password", passwordIsError: true });
        return;
      }

      for (let index = 0; index < employee.Password.length; index++) {
        const element = employee.Password.charAt(index);

        if (capsCharacter.search(element) !== -1) hasCaps = true;
        if (regCharacter.search(element) !== -1) hasRegChar = true;
        // if(spclCharacter.search(element) !== -1)
        //     hasSpclChar = true;
        if (numeric.search(element) !== -1) hasNumeric = true;
      }

      if (!hasCaps || !hasNumeric || !hasRegChar) {
        this.setState({
          errModalOpen: true,
          errTitle: "Invalid",
          errMessage:
            "The password must contains small and capital letter and number",
        });
        this.setState({ focusField: "Password", passwordIsError: true });
        return;
      }
    }

    var { payrollInfo } = this.state;
    if (payrollInfo.BasicPay === "") {
      this.setState({
        errModalOpen: true,
        errTitle: "Invalid",
        errMessage: "Basic Pay must have a value",
      });
      this.setState({ isBasicError: true });
      return;
    }
    if (payrollInfo.Cola === "") {
      this.setState({
        errModalOpen: true,
        errTitle: "Invalid",
        errMessage: "Cola must have a value",
      });
      this.setState({ isColaError: true });
      return;
    }
    if (payrollInfo.Allowance === "") {
      this.setState({
        errModalOpen: true,
        errTitle: "Invalid",
        errMessage: "Allowance must have a value",
      });
      this.setState({ isAllowanceError: true });
      return;
    }
    if (payrollInfo.Deminimis === "") {
      this.setState({
        errModalOpen: true,
        errTitle: "Invalid",
        errMessage: "Deminimis must have a value",
      });
      this.setState({ isDeminimisError: true });
      return;
    }

    if (payrollInfo.HazardPay === "") {
      this.setState({
        errModalOpen: true,
        errTitle: "Invalid",
        errMessage: "Hazard Pay must have a value",
      });
      this.setState({ isHazardPayError: true });
      return;
    }
    if (payrollInfo.PagibigMP2 === "") {
      this.setState({
        errModalOpen: true,
        errTitle: "Invalid",
        errMessage: "Pagibig MP2 must have a value",
      });
      this.setState({ isPagibigMP2Error: true });
      return;
    }

    var focusField = "";

    var eligibilities = this.state.eligibilityList;
    var prevEligibilities = this.state.prevEligibilityList;
    var eligibilityToSave = [];
    eligibilities.forEach((currEligibility) => {
      if (
        prevEligibilities.find(
          (prevEligibility) => prevEligibility._id === currEligibility._id
        ) === null ||
        JSON.stringify(
          prevEligibilities.find(
            (prevEligibility) => prevEligibility._id === currEligibility._id
          )
        ) !== JSON.stringify(currEligibility)
      )
        eligibilityToSave.push(currEligibility);
    });

    var deletedIDs = this.state.eligibilityDeleteList.map((x) => x._id);
    deletedIDs = deletedIDs.filter((x) => x !== empty_id);
    if (deletedIDs.length === 0) {
      deletedIDs = null;
    }

    employee.isSubscription = isSubscribed;
    zkUserInfo.AccessNumber = employee.AccessNumber;

    ajaxPost({
      url: "api/Employee/save",
      data: {
        employee: employee,
        zkUserInfo: zkUserInfo,
        payrollInfo: this.state.payrollInfo,
        eligibilities: eligibilities,
        eligibilityDelete: deletedIDs,
        server: global.ApiHost,
        skipValidation: this.state.skipValidation,
      },
      onSuccess: (data) => {
        employee = data.content;
      },
      finally: () => {
        var isError = false;
        var index = undefined;
        let errMessage = "";

        if ("errEmpNo" in employee) {
          this.setState({ employeeNoIsError: true });
          errMessage += "Duplicate Employee Number";
          isError = true;

          focusField = "EmployeeNo";
        }
        if ("errAccessNo" in employee) {
          this.setState({ accessNumberIsError: true });
          if (errMessage !== "") errMessage += " and ";
          errMessage += "Duplicate Access Number";
          isError = true;

          if (focusField === "") focusField = "AccessNumber";
        }
        if ("errEmailAddress" in employee) {
          if (errMessage !== "") errMessage += " and ";
          errMessage += "Email address already exists.";
          isError = true;
        }
        if ("errEmpCount" in employee) {
          if (errMessage !== "") errMessage += " and ";
          errMessage +=
            "Unable to add employee.Registered employee count has been reached.";
          isError = true;
        }

        if (isError) {
          this.setState({ focusField });
          this.setState({
            errModalOpen: true,
            errTitle: "Invalid",
            errMessage: errMessage,
          });
          return;
        }

        index = newEmpList.map((emp) => emp._id).indexOf(employee._id);

        if (index === -1) {
          newEmpList = [];
          newEmpList.push(employee);
        } else {
          newEmpList[index] = employee;
        }

        mainState.employeeList = newEmpList;

        mainState.popUpTitle = "Employee";
        mainState.popUpContent = "Successfully Save.";
        mainState.showPopUp = true;

        var action = "List";
        if (
          this.props.params.referer != null &&
          this.props.params.referer == "Dashboard"
        ) {
          action = "Dashboard";
        }
        this.setState(
          { prevState: undefined, mainState },
          this.props.callback.returnData(
            "",
            "",
            "",
            action,
            this.state.mainState
          )
        );
      },
    });
  }

  stateComparer(prevState, newState, changes, module, employeeId) {
    if (
      Array.isArray(newState) &&
      newState.length > 0 &&
      module != "ZKUserInfos"
    ) {
      newState.forEach((row) => {
        var existence = prevState.find((z) => z._id === row._id);
        if (existence === undefined) {
          delete row["Employee"];
          changes.push({
            TableName: module,
            UpdateId: null,
            EmployeeId: employeeId,
            OldValue: null,
            NewValue: row,
            Action: "NEW",
            FieldName: row.Type,
          });
        } else if (existence !== row) {
          var eligibilityChanges = [];
          this.stateComparer(
            existence,
            row,
            eligibilityChanges,
            module,
            employeeId
          );
          if (
            eligibilityChanges.filter((e) => e.FieldName !== "Employee")
              .length > 0
          ) {
            delete existence["Employee"];
            delete row["Employee"];
            changes.push({
              TableName: module,
              UpdateId: row._id,
              EmployeeId: employeeId,
              OldValue: existence,
              NewValue: row,
              Action: "UPDATE",
              FieldName: row.Type,
            });
          }
        }
      });
    } else {
      Object.keys(newState).forEach((item) => {
        if (item === "Password" && module == "Employees") {
          return;
        } else if (
          item === "FPCount" ||
          item === "OtherDetails" ||
          item === "Name"
        ) {
          return;
        } else if (item === "Details") {
          this.stateComparer(
            prevState[item],
            newState[item],
            changes,
            module,
            employeeId
          );
        } else if (item !== "file") {
          if (Array.isArray(newState[item])) {
            this.stateComparer(
              prevState[item],
              newState[item],
              changes,
              module
            );
          } else if (prevState[item] !== newState[item]) {
            changes.push({
              TableName: module,
              UpdateId: newState._id,
              EmployeeId: employeeId,
              OldValue: prevState[item],
              NewValue: newState[item],
              Action: "UPDATE",
              FieldName: item,
            });
          }
        }
      });
    }
  }

  onMouseEnter() {
    this.setState({ isImageHover: true });
  }

  onMouseLeave() {
    this.setState({ isImageHover: false });
  }

  fileChangedHandler = (event) => {
    var img = event.target.files[0];
    if (img === undefined) return; 
    if (!img.type.match( /image-*/)) {
      alert('File is not an image.');
      return;
    }
    if (img.size > 1572864) {
      alert("Image too large. Image file must be below 1.5MB");
      return;
    }
    if(img.size === 0){
      alert("Image too small. File must be not 0MB");
      return;
    }
    this.readDataUrl(img, this.onload.bind(this));
  };

  readDataUrl(img, callback) {
    var reader = new FileReader();
    reader.onload = function () {
      var dataURL = reader.result;
      callback(dataURL);
    };
    reader.onerror = function (error) {
      console.log("Error: ", error);
    };
    reader.readAsDataURL(img);
  }

  onload(result) {
    var current = this.state.selectedEmployee;
    current["file"] = result;
    current["Image"] = result;
    this.setState({ selectedEmployee: current });
  }
  onclearAvatar( ) {
    var current = this.state.selectedEmployee;
    current["file"] = undefined;
    current["Image"] = "default.jpg";
    this.setState({ selectedEmployee: current });
  }
  uploadHandler = () => {
    document.getElementById("myFile").click();
  };

  onCreateNewClick = (type) => {
    const BlankEligibilities = {
      _id: empty_id,
      Type: "",
      Details: [],
    };

    var blankEligibility = BlankEligibilities;
    blankEligibility.Type = type;

    populateEligibilityDropDown(this, type, blankEligibility);

    this.setState({ selectedEligibility: blankEligibility });
    this.setState({ eligibilityIndex: -1 });
    this.setState({ modalOpen: true });
  };

  onEditClick = (eligibility, index) => {
    var blankEligibilities = {
      Type: "",
      Details: [],
    };
    populateEligibilityDropDown(this,eligibility.Type, blankEligibilities);

    this.setState({
      eligibilityDisplay: eligibility.Type,
      selectedEligibility: eligibility,
      eligibilityIndex: index,
      modalOpen: true,
    });
  };

  onDeleteClick = (eligibility, index) => {
    var newList = this.state.eligibilityList;
    newList.splice(index, 1);
    var deleteList = this.state.eligibilityDeleteList;
    delete eligibility.Employee;

    if ("_id" in eligibility) {
      deleteList.push(eligibility);
    }

    this.setState({ eligibilityList: newList });
    this.setState({ eligibilityDeleteList: deleteList });
    this.setState({ showDeleteModal: false });
  };

  loadSupervisor = (str, supervisorId) => {
    this.setState({ isSupervisorLoading: true });

    var newSupervisorList = [];

    if (!(supervisorId === undefined || supervisorId === empty_id)) {
      newSupervisorList.push(supervisorId);
    }

    if (
      this.state.selectedEmployee.ReportingTo == undefined ||
      this.state.selectedEmployee.ReportingTo == null
    )
      return;

    ajaxPost({
      url: "api/employee/searchEmployee",
      data: {
        search: str,
        employeeIds: newSupervisorList,
        positionId: this.state.selectedEmployee.ReportingTo,
        excludeIds: this.state.selectedEmployee._id != null ? [this.state.selectedEmployee._id] : [],
        userId: global.userId
      },
      onSuccess: (data) => {
        if (!(supervisorId === undefined || supervisorId === empty_id)) {
          var selected = {
            label: data[0].FirstName + " " + data[0].LastName,
            value: data[0]._id,
          };
          this.setState({ selectedSupervisor: selected }, () => {
            this.loadSupervisor("");
          });
        } else {
          this.setState({
            supervisorList: data,
          });
        }
      },
      finally: () => {
        this.setState({ isSupervisorLoading: false });
      },
    });
  };

  loadCityList = (str, cityId) => {
    this.setState({
      cityList: [],
      isCityLoading: true,
    });
    ajaxPost({
      url: "api/Group/search",
      data: {
        Search: str,
        includeDeleted: false,
        startingIndex: 0,
        itemCount: 10,
        Types: ["CITY"],
        currentId: cityId,
      },
      onSuccess: (data) => {
        this.setState({
          cityList: data.content,
        });

        if (!(cityId === undefined || cityId === empty_id)) {
          this.setState(
            {
              selectedCityOptions: selectionList(
                data.content,
                ["Name"],
                "_id"
              ).find((x) => x.value === cityId),
            },
            () => this.loadCityList("")
          );
        }
      },
      finally: () => {
        this.setState({ isCityLoading: false });
      },
    });
  };

  loadATCProfile = (str, atcProfileId) => {
    this.setState({ isATCProfileLoading: true });
    ajaxPost({
      url: "api/alphanumericTaxCodeProfile/searchFiltered",
      data: {
        search: str,
        includeDeleted: false,
        selectedATCProfileId: atcProfileId,
      },
      onSuccess: (data) => {
        this.setState({ atcProfileList: data });
      },
      finally: () => {
        this.setState({ isATCProfileLoading: false });
      },
    });
  };

  positionMovementAdd = (ignoreWarning) => (event) => {
    const {
      positionMovement,
      positionMovementIndex,
      selectedPositionMovement,
      selectedPositionProfile,
      selectedSupervisor,
    } = this.state;
    var focusField = "";
    var posTabIndex = -1;
    let isError = false;
    if (
      selectedPositionProfile === null ||
      selectedPositionProfile === undefined
    ) {
      focusField = "PositionProfile";
      posTabIndex = 0;
      this.setState({ isAssignmentProfileError: true });
      isError = true;
    }

    if (
      selectedPositionMovement.StartDate === undefined ||
      selectedPositionMovement.StartDate === ""
    ) {
      if (focusField === "") {
        focusField = "startDate";
        posTabIndex = 0;
      }

      this.setState({ isStartDateError: true });
      isError = true;
    } else {
      var correctDateRangeStart = new Date(1753, 1, 1);
      var correctDateRangeEnd = new Date(9999, 12, 31);

      if (selectedPositionMovement.StartDate != null) {
        var dt = new Date(selectedPositionMovement.StartDate);
        if (dt < correctDateRangeStart || dt > correctDateRangeEnd) {
          alert(
            " Start Date: invalid date .Must be between 1/1/1753 and 12/31/9999"
          );
          if (focusField === "") {
            focusField = "startDate";
            posTabIndex = 0;
          }
          isError = true;
          this.setState({ isStartDateError: true });
        }
      }
    }

    if (
      selectedSupervisor !== null &&
      this.state.selectedEmployee._id === selectedSupervisor.value
    ) {
      alert("Error: Employee cannot subordinate himself");
      if (focusField === "") {
        focusField = "Supervisor";
        posTabIndex = 0;
      }
      isError = true;
      this.setState({ isSupervisorError: true });
    }
    if (
      selectedPositionMovement.BasicPay === "" ||
      isNaN(parseFloat(selectedPositionMovement.BasicPay))
    ) {
      if (focusField === "") {
        focusField = "BasicSalary";
        posTabIndex = 1;
      }
      this.setState({ isBasicError: true });
      isError = true;
      focusField = "BasicPay";
    }
    if (
      selectedPositionMovement.Cola === "" ||
      isNaN(parseFloat(selectedPositionMovement.Cola))
    ) {
      if (focusField === "") {
        focusField = "Cola";
        posTabIndex = 1;
      }
      this.setState({ isColaError: true });
      isError = true;
    }
    if (
      selectedPositionMovement.Allowance === "" ||
      isNaN(parseFloat(selectedPositionMovement.Allowance))
    ) {
      if (focusField === "") {
        focusField = "Allowance";
        posTabIndex = 1;
      }
      this.setState({ isAllowanceError: true });
      isError = true;
    }
    if (
      selectedPositionMovement.UnionDues === "" ||
      isNaN(parseFloat(selectedPositionMovement.UnionDues))
    ) {
      if (focusField === "") {
        focusField = "UnionDues";
        posTabIndex = 1;
      }
      this.setState({ isUnionDuesError: true });
      isError = true;
    }
    if (
      selectedPositionMovement.Deminimis === "" ||
      isNaN(parseFloat(selectedPositionMovement.Deminimis))
    ) {
      if (focusField === "") {
        focusField = "Deminimis";
        posTabIndex = 1;
      }
      this.setState({ isDeminimisError: true });
      isError = true;
      focusField = "Deminimis";
    }
    if (
      selectedPositionMovement.PagibigMP2 === "" ||
      isNaN(parseFloat(selectedPositionMovement.PagibigMP2))
    ) {
      if (focusField === "") {
        focusField = "PagibigMP2";
        posTabIndex = 1;
      }
      this.setState({ isPagibigMP2Error: true });
      isError = true;
      focusField = "PagibigMP2";
    }

    if (isError) {
      // this[focusField].focus();
      this.setState({
        posMovementTabIndex: posTabIndex,
        focusField: focusField,
      });
      return;
    }

    if (
      selectedPositionProfile._id !==
      selectedPositionMovement.PositionProfileId &&
      selectedPositionProfile.SlotAvailable <= 0 &&
      selectedPositionMovement.Primary &&
      ignoreWarning === false
    ) {
      this.setState({ showFullPositionWarning: true });
      return;
    }

    if (selectedPositionMovement.Primary === true) {
      //index 0 = talent movement
      //index 1 = index in talent movement
      var primaryPositionList = positionMovement
        .filter((x) => x.Primary === true)
        .map((x) => [x, positionMovement.indexOf(x)]);
      primaryPositionList.forEach((x) => {
        if (x[1] !== positionMovementIndex) {
          x[0].Primary = false;
          positionMovement[x[1]] = x[0];
        }
      });
    }

    selectedPositionMovement.PositionProfileId = selectedPositionProfile._id;

    selectedPositionMovement["PositionProfile"] = selectedPositionProfile;
    if (positionMovementIndex === -1) {
      positionMovement.push(selectedPositionMovement);
    } else {
      positionMovement[positionMovementIndex] = selectedPositionMovement;
    }

    this.setState({
      positionMovementModalOpen: false,
      positionMovement,
      selectedPositionMovement: {},
    });
  };

  handlePayProfileChange = (evt) => {
    var current = this.state.payrollInfo;
    if (evt !== null) current.PayrollProfileId = evt.value;
    else current.PayrollProfileId = empty_id;

    this.setState({ selectedPayProfile: evt, payrollInfo: current });
  };

  handlePayrollRateProfileChange = (evt) => {
    var current = this.state.payrollInfo;
    if (evt !== null) current.RateProfileId = evt.value;
    else current.RateProfileId = empty_id;

    this.setState({ selectedRateProfile: evt, payrollInfo: current });
  };

  handleBankChange = (evt) => {
    var current = this.state.payrollInfo;
    if (evt !== null) current.BankId = evt.value;
    else current.BankId = empty_id;

    this.setState({ selectedBank: evt, payrollInfo: current });
  };

  handleValueChange = (name, errFieldName, evt) => {
    var current = this.state.payrollInfo;

    if (evt !== null) {
      current[name] = evt.value;
    } else {
      current[name] = empty_id;
    }

    this.setState({ payrollInfo: current });

    if (errFieldName !== undefined) {
      if (evt === undefined) {
        this.setState({ [errFieldName]: true });
      } else {
        this.setState({ [errFieldName]: false });
      }
    }
  };

  updateRateDisplay = (SalaryType) => {
    var perRate = this.state.PerRate;
    if (SalaryType == "MONTHLY") {
      perRate = "(/mon)";
    } else if (SalaryType == "DAILY") {
      perRate = "(/day)";
    } else if (SalaryType == "HOURLY") {
      perRate = "(/hr)";
    }
    this.setState({ PerRate: perRate });
  };

  handlePayrollProfileChange = (name) => (event) => {
    var current = this.state.payrollInfo;
    current[name] = event.target.value;
    this.setState({ payrollInfo: current });
  };

  handleDropDownPayrollInfoChange = (name) => (event) => {
    var current = this.state.payrollInfo;
    var basedAmount = [
      "PagibigBased",
      "PhilHealthBased",
      "SSSBased",
      "GSISBased",
      "TaxBased",
    ];
    if (event != null) {
      current[name] = event.value;
    } else {
      current[name] = "";
    }
    if (name == "SalaryType") {
      this.updateRateDisplay(event.value);
    } else if (basedAmount.includes(name)) {
      this.showFixedAmount(name, event.value);
    }
    this.setState({ payrollInfo: current });
  };

  showFixedAmount = (BasedType, BasedValue) => {
    if (BasedType == "PagibigBased") {
      if (BasedValue == "FIX_AMOUNT")
        this.setState({ showPagibigAmount: true });
      else this.setState({ showPagibigAmount: false });
    } else if (BasedType == "PhilHealthBased") {
      if (BasedValue == "FIX_AMOUNT")
        this.setState({ showPhilhealthAmount: true });
      else this.setState({ showPhilhealthAmount: false });
    } else if (BasedType == "SSSBased") {
      if (BasedValue == "FIX_AMOUNT") this.setState({ showSssAmount: true });
      else this.setState({ showSssAmount: false });
    } else if (BasedType == "GSISBased") {
      if (BasedValue == "FIX_AMOUNT") this.setState({ showGSISAmount: true });
      else this.setState({ showGSISAmount: false });
    } else if (BasedType == "TaxBased") {
      if (BasedValue == "FIX_AMOUNT") this.setState({ showTaxAmount: true });
      else this.setState({ showTaxAmount: false });
    }
  };

  handleTextChange = (name, errFieldName, text) => {
    var current = this.state.selectedPositionMovement;
    current[name] = text;

    this.setState({ selectedPositionMovement: current });

    if (errFieldName !== undefined) {
      if (text === "") {
        this.setState({ [errFieldName]: true });
      } else {
        this.setState({ [errFieldName]: false });
      }
    }
  };

  handleCityChange = (evt) => {
    var current = this.state.selectedPositionMovement;
    if (evt === null) current.CityId = empty_id;
    else current.CityId = evt.value;

    this.setState(
      { selectedPositionMovement: current, selectedCityOptions: evt },
      () => this.loadCityList("")
    );
  };

  handleATCProfileChange = (evt) => {
    var current = this.state.payrollInfo;
    if (evt === null) current.AlphanumericTaxCodeProfileId = empty_id;
    else current.AlphanumericTaxCodeProfileId = evt.value;

    this.setState({ payrollInfo: current, selectedATCProfile: evt }, () =>
      this.loadATCProfile("")
    );
  };

  handlePrimaryChange = (evt) => {
    var current = this.state.selectedPositionMovement;
    current["Primary"] = !current["Primary"];
    this.setState({ selectedPositionMovement: current });
  };

  profileInputKeyDown = (text) => {
    var current = this.state.selectedPositionMovement;
    current.PositionProfileId = empty_id;
    this.setState(
      {
        selectedPositionProfile: null,
        selectedPositionProfileOptions: null,
        selectedPositionMovement: current,
      },
      () => {
        this.loadPositionProfileList(text);
      }
    );
  };

  handleCityInputKeyDown = (text, event) => {
    if (
      event.action === "input-blur" ||
      event.action === "set-value" ||
      event.action === "menu-close"
    )
      return;

    clearTimeout(this.searchTimer);
    this.setState({ isCityLoading: true, cityList: [] });
    this.searchTimer = setTimeout(this.cityInputKeyDown, 400, text);
  };

  cityInputKeyDown = (text) => {
    var current = this.state.selectedPositionMovement;
    current.CityId = empty_id;
    this.setState(
      {
        selectedCityOptions: null,
        selectedPositionMovement: current,
      },
      () => {
        this.loadCityList(text);
      }
    );
  };

  handleATCProfileInputKeyDown = (text, event) => {
    if (
      event.action === "input-blur" ||
      event.action === "set-value" ||
      event.action === "menu-close"
    )
      return;

    clearTimeout(this.searchTimer);
    this.setState({ isATCProfileLoading: true, atcProfileList: [] });
    this.searchTimer = setTimeout(this.atcProfileInputKeyDown, 400, text);
  };

  atcProfileInputKeyDown = (text) => {
    var current = this.state.selectedPositionMovement;
    current.AlphanumericTaxCodeProfileId = empty_id;
    this.setState(
      {
        selectedATCProfile: null,
        selectedPositionMovement: current,
      },
      () => {
        this.loadATCProfile(text);
      }
    );
  };

  handleAmountChange = (name, errFieldName, event) => {
    var current = this.state.selectedPositionMovement;

    var numberEntry = "1234567890.";
    var newValue = event.target != undefined ? event.target.value : event.value;

    for (let index = 0; index < newValue.length; index++) {
      if (numberEntry.includes(newValue.charAt(index)) === false)
        newValue = replaceAt(newValue, index, "");
    }

    if (newValue != null && newValue.split(".").length - 1 > 1) {
      newValue = newValue.slice(0, newValue.length - 1);
    }

    if (event.target !== undefined) {
      current[name] = newValue;
    } else {
      current[name] = null;
    }
    this.setState({ selectedPositionMovement: current });

    if (errFieldName !== undefined) {
      if (event.target.value === undefined || event.target.value === "") {
        this.setState({ [errFieldName]: true });
      } else {
        this.setState({ [errFieldName]: false });
      }
    }
  };

  handleSupervisorInputKeyDown = (text, event) => {
    if (
      event.action === "input-blur" ||
      event.action === "set-value" ||
      event.action === "menu-close"
    )
      return;

    clearTimeout(this.searchTimer);
    this.setState({ isSupervisorLoading: true, supervisorList: [] });
    this.searchTimer = setTimeout(this.supervisorInputKeyDown, 400, text);
  };

  supervisorInputKeyDown = (text) => {
    var current = this.state.selectedEmployee;
    current.SupervisorId = empty_id;
    this.setState(
      {
        selectedEmployee: current,
        selectedSupervisorOptions: null,
      },
      () => {
        this.loadSupervisor(text);
      }
    );
  };

  handleSupervisorChange = (source) => {
    if (source.type === "focus") return;

    var { selectedEmployee } = this.state;
    if (source === null) {
      selectedEmployee["SupervisorId"] = empty_id;
    } else {
      selectedEmployee["SupervisorId"] = source.value;
    }

    this.setState({ selectedSupervisor: source, selectedEmployee }, () =>
      this.loadSupervisor("")
    );
  };

  onHandleCheckChange = (name) => (event) => {
    var current = this.state.selectedEligibility;
    current.Details[name] = !this.state.selectedEligibility.Details[name];
    this.setState({ selectedEligibility: current });
  };


  convertToFieldName(text) {
    return text.replace(/_/g, " "); // replace all underline with a space [ ]
  }

  onTestAvatarPath() {
    var path = "";
    fnGetEmployeeImagePath(this.state.selectedEmployee._id, (data) => {
      path = data;
      alert(path);
    });
  }



  onCreateNewItemClick = (field, isDialog) => {
    var grp = Object.assign({}, newGroup);

    if (isDialog) {
      if (field == "COMPANY") {
        this.setState({ sideBarTitle: "Add Company" });
        this.setState({ displayGroup: field });
        grp["OtherDetails"] = Object.assign({}, companyDetails);
        this.setState({ selectedGroup: grp });
      } else if (field == "DIVISION") {
        this.setState({ sideBarTitle: "Add Division" });
        this.setState({ displayGroup: field });
        grp["OtherDetails"] = Object.assign({}, defaultDetails);
        this.setState({ selectedGroup: grp });
      } else if (field == "DEPARTMENT") {
        this.setState({ sideBarTitle: "Add Deparment" });
        this.setState({ displayGroup: field });
        grp["OtherDetails"] = Object.assign({}, defaultDetails);
        this.setState({ selectedGroup: grp });
      } else if (field == "POSITION") {
        this.setState({ sideBarTitle: "Add Position" });
        this.setState({ displayGroup: field });
        grp["OtherDetails"] = Object.assign({}, defaultDetails);
        this.setState({ selectedGroup: grp });
      } else if (field == "COST_CENTER") {
        this.setState({ sideBarTitle: "Add Cost Center" });
        this.setState({ displayGroup: "COST_CENTER" });
        grp["OtherDetails"] = Object.assign({}, defaultDetails);
        this.setState({ selectedGroup: grp });
      } else if (field == "CITY") {
        this.setState({ sideBarTitle: "Add City" });
        this.setState({ displayGroup: field });
        grp["OtherDetails"] = Object.assign({}, defaultDetails);
        this.setState({ selectedGroup: grp });
      }
    } else {
      this.setState({ showAskCloseDialog: true, redirectModule: field });
      return;
    }

    this.setState({ sideBarOpen: true });
  };

  onGroupDoneSave = (newGroup) => {
    var newGroupList = this.state.groups;
    var emp = this.state.selectedEmployee;
    newGroupList.push(newGroup);

    switch (newGroup.Type) {
      case "DIVISION":
        emp.DivisionId = newGroup._id;
        break;
      case "DEPARTMENT":
        emp.DepartmentId = newGroup._id;
        break;
      case "CITY":
        emp.CityId = newGroup._id;
        break;
      case "COST_CENTER":
        emp.CostCenterId = newGroup._id;
        break;
      case "COMPANY":
        emp.CompanyId = newGroup._id;
      case "POSITION":
        emp.PositionId = newGroup._id;
        break;
    }

    this.setState({ selectedEmployee: emp });
    this.setState({ groups: newGroupList, sideBarOpen: false });
  };

  handleTabChange = (e, { activeIndex }) =>
    this.setState({
      activeIndex,
      eligibilityUsed: getEligibilityOptions(activeIndex, "eligibility"),
    });

  render() {


    const {
      eligibilityUsed,
      eligibilityType,
      eligibilityList,
      selectedEligibility,
      eligibilityOptions,
    } = this.state;


    const { isEligibilityLoading, isZKUserInfoLoading, isPayrollInfoLoading } =
      this.state;


    var panel = [];

    const this2 = this;

    loadEligibilities(this2,panel);



    var panes = [
      {
        menuItem: "Details",
        render: () => renderEmployeeDetails(this2)
      },
      (isTaskAvailable("EMPLOYEE_DEVICE_EDIT") || this.state.isSuperAdmin) && {
        menuItem: "Device Info",
        render: () => renderEmployeeDevice(this2) 
      },
      isTaskAvailable("EMPLOYEE_PAYROLL_EDIT") && {
        menuItem: "Payroll Detail",
        render: () => renderEmployeePayroll(this2)
      },
    ];

    return (<>
      <div className={modStyles.module}> 
            {this.state.sideBarOpen && (
              <DynamicSideBarForm
                show={this.state.sideBarOpen}
                sideBarTitle={this.state.sideBarTitle}
                style={{ marginTop: "20px" }}
                selectedGroup={this.state.selectedGroup}
                onCloseSideBar={() => {
                  this.setState({ sideBarOpen: false });
                }}
                onClickSave={this.onGroupDoneSave.bind()}
                displayGroup={this.state.displayGroup}
              />
            )} 
        <div>
        <div>
            {this.props.params.noDataFields !== null &&
              this.props.params.noDataFields !== undefined &&
              this.props.params.noDataFields.length > 0 && (
                <Message negative size="small">
                  <Message.Header>
                    Please fill-up these necessary fields for this employee
                    to be included to payruns:
                  </Message.Header>
                  <List>
                    {this.props.params.noDataFields !== null &&
                      this.props.params.noDataFields !== undefined &&
                      this.props.params.noDataFields.map((item) => {
                        return <List.Item>{item}</List.Item>;
                      })}
                  </List>
                </Message>
              )}
          </div>
          <div>
            <div
              style={{
                verticalAlign: "middle",
                height: "75px",
                margin: "15px 0px",
                padding: "auto",
                float: "right",
              }}
            >
              <Button
                size="mini"
                onClick={this.saveClick.bind(this)}
                disabled={
                  isEligibilityLoading ||
                  isPayrollInfoLoading ||
                  isZKUserInfoLoading
                }
                loading={this.state.isSaving}
              >
                <Icon name="save"></Icon>Save
              </Button>
              <Button
                size="mini"
                onClick={this.backClick.bind(this, "List")}
              >
                <Icon name="cancel" />
                Cancel
              </Button>
              {/* <Button size='mini' onClick={this.test.bind(this)}><Icon name='cancel' />test</Button> */}
            </div>
            <div>
              <input
                type="file"
                id="myFile"
                onChange={this.fileChangedHandler}
                style={{ display: "none" }}
                accept="image/png, image/jpeg" 
              /> 
              <div style={{float: "left"}}>
              <EmployeeImage
                empId={this.state.selectedEmployee._id}
                fileName={this.state.selectedEmployee.Image}
                runtime={this.state.selectedEmployee.file}
                style={{ width: "75px", height: "75px", cursor: "pointer"}}
                onClick={this.uploadHandler}
                onMouseEnter={this.onMouseEnter.bind(this)}
                onMouseOut={this.onMouseLeave.bind(this)}
                onDelete={this.onclearAvatar.bind(this)}
                isPhoto
                avatar
              /> 
              {window.innerWidth > 768 && (
                <span style={{ fontSize: "1.6em", fontWeight: " bold" }}>
                  {this.state.selectedEmployee._id === undefined
                    ? "New Employee"
                    : this.state.selectedEmployee.FullName === undefined
                      ? fullName(this.state.selectedEmployee)
                      : this.state.selectedEmployee.FullName}
                </span>
              )}
              </div>
            </div>
          </div>
         
        </div>
        <Tab
          panes={!this.state.isSuperAdmin ? panes.concat(panel) : panes}
          activeIndex={this.state.activeIndex}
          onTabChange={this.handleTabChange}
          menu={{ fluid: true, vertical: true, tabular: true }}
          style={{ margin: "0px" }}
        />
      </div>
      <Modal
        size="mini"
        open={this.state.modalOpen}
        onClose={this.close}
        style={{ width: "340px" }}
      >
        <Modal.Header>
          {!(
            selectedEligibility === undefined ||
            selectedEligibility === null
          ) && selectedEligibility.Type.replace("_", " ")}
        </Modal.Header>
        <Modal.Content>
          {!(
            selectedEligibility === undefined ||
            selectedEligibility === null
          ) &&
            this.state.modalOpen &&
            Object.keys(selectedEligibility.Details).map((det) => {
              return (
                <div>
                  {det.includes("Sponsored") && (
                    <Checkbox
                      style={{ marginTop: "5px" }}
                      checked={
                        this.state.selectedEligibility.Details[det]
                      }
                      onChange={this.onHandleCheckChange(det).bind(this)}
                      label="Sponsored"
                      toggle
                    />
                  )}
                  {det.includes("File") && 
                    !det.includes("UniqueFilename") && (
                      <>
                      <Input
                        style={{ marginTop: "5px" }}
                        labelPosition="left"
                        type="text"
                        placeholder={this.convertToFieldName(det)}
                        error={
                          eligibilityUsed[det] !== undefined &&
                          eligibilityUsed[det].isRequired &&
                          this.state[
                          "isError" + selectedEligibility.Type + det
                          ]
                        }
                      >
                        <Label style={{ width: "130px" }}>
                          {this.convertToFieldName(det)}{" "}
                          {this.state.eligibilityUsed[det].isRequired &&
                            "*"}
                        </Label>
                        <label
                          style={{
                            padding: "15px",
                            "word-wrap": "break-word",
                            "word-break": "break-all",
                            overflow: "hidden",
                          }}
                          for="files"
                        >
                          <a>
                            {selectedEligibility.Details[det] === ""
                              ? "Choose file"
                              : selectedEligibility.Details[det]}
                          </a>
                        </label>
                        <input
                          id="files"
                          style={{ width: "170px", display: "none" }}
                          size="small"
                          name={selectedEligibility.Details[det]}
                          type="file"
                          onChange={this.onDocChangedHandler.bind(this)}
                        />
                      </Input>
                      <div style={{textAlign:'right'}}>*Max File Size: 3MB</div>
                      </>
                    )}
                  {det.includes("Date") && (
                    <Input
                      style={{
                        marginTop: "5px",
                        display: det.includes("Date_Created") && "none",
                      }}
                      labelPosition="left"
                      type="text"
                      placeholder={this.convertToFieldName(det)}
                      error={
                        eligibilityUsed[det] !== undefined &&
                        eligibilityUsed[det].isRequired &&
                        this.state[
                        "isError" + selectedEligibility.Type + det
                        ]
                      }
                    >
                      <Label style={{ width: "130px" }}>
                        {this.convertToFieldName(det)}{" "}
                        {this.state.eligibilityUsed[det].isRequired &&
                          "*"}
                      </Label>
                      <input
                        style={{ width: "170px" }}
                        size="small"
                        value={selectedEligibility.Details[det]}
                        type="date"
                        min="1900-01-01"
                        max="2999-12-31"
                        onChange={this.onEligibilityDetailChange(
                          det
                        ).bind(this)}
                      />
                    </Input>
                  )}
                  {(det.includes("Type") || det.includes("Relation")) && (
                    <Input
                      style={{ marginTop: "5px", display: "flex" }}
                      labelPosition="left"
                      type="text"
                      placeholder={this.convertToFieldName(det)}
                    >
                      <Label style={{ width: "130px" }}>
                        {this.convertToFieldName(det)}{" "}
                        {this.state.eligibilityUsed[det].isRequired &&
                          "*"}
                      </Label>
                      <div style={{ width: "170px" }}>
                        <Select
                          styles={
                            eligibilityUsed[det] !== undefined &&
                              eligibilityUsed[det].isRequired &&
                              this.state[
                              "isError" + selectedEligibility.Type + det
                              ]
                              ? errorStyles
                              : customStyles
                          }
                          placeholder="select type"
                          value={eligibilityOptions.find(
                            (x) =>
                              x.value === selectedEligibility.Details[det]
                          )}
                          options={eligibilityOptions}
                          onChange={this.onDropDownChange(det).bind(this)}
                        />
                      </div>
                    </Input>
                  )}
                  {!(
                    det.includes("Type") ||
                    det.includes("Date") ||
                    det.includes("File") ||
                    det.includes("Sponsored") ||
                    det.includes("Relation")
                  ) && (
                      <Input
                        style={{
                          marginTop: "5px",
                          display: det.includes("Source") && "none",
                        }}
                        labelPosition="left"
                        type="text"
                        placeholder={this.convertToFieldName(det)}
                        error={
                          eligibilityUsed[det] !== undefined &&
                          eligibilityUsed[det].isRequired &&
                          this.state[
                          "isError" + selectedEligibility.Type + det
                          ]
                        }
                      >
                        <Label style={{ width: "130px" }}>
                          {this.convertToFieldName(det)}{" "}
                          {this.state.eligibilityUsed[det].isRequired &&
                            "*"}
                        </Label>
                        <input
                          style={{ width: "170px" }}
                          size="small"
                          value={selectedEligibility.Details[det]}
                          onKeyPress={(e) => {
                            this.state.eligibilityUsed[det].isNumericOnly &&
                              handleNumericOnKeyPress(e);
                          }}
                          onKeyDown={(e) => {
                            this.state.eligibilityUsed[det].isNumericOnly &&
                              handleNumericOnKeyDown(e);
                          }}
                          onChange={this.onEligibilityDetailChange(
                            det
                          ).bind(this)}
                        />
                      </Input>
                    )}
                </div>
              );
            })}
        </Modal.Content>
        <Modal.Actions>
          <Button
            onClick={this.eligibilityAdd.bind(this)}
            icon="save"
            content="Save"
          />
          <Button
            onClick={() => this.setState({ modalOpen: false })}
            icon="cancel"
            content="Cancel"
          />
        </Modal.Actions>
      </Modal>
      {this.state.errModalOpen && (
        <MessageBoxOkOnly
          title={this.state.errTitle}
          caption={this.state.errMessage}
          onClick={() =>
            this.setState({ errModalOpen: false, isSaving: false })
          }
        />
      )}
      {this.state.showDeleteModal && (
        <MessageBoxYesNo
          title="Delete"
          caption="Are you sure you want to delete this item?"
          action="Yes"
          onAction={this.onDeleteClick.bind(
            this,
            selectedEligibility,
            this.state.eligibilityIndex
          )}
          onClose={() => this.setState({ showDeleteModal: false })}
        />
      )}

      {this.state.warnModalOpen && (
        <MessageBoxSaveClose
          title={this.state.warnTitle}
          caption={this.state.warnMessage}
          reqFields={this.state.reqFields}
          onClick={() =>
            this.setState({ warnModalOpen: false, isSaving: true, skipValidation: true }, () => {
              this.saveAsAdmin(this.state.employeeList);
            })
          }
          onClose={() => this.setState({ warnModalOpen: false, isSaving: false, skipValidation: false })}
        />
      )}

      {this.state.showAskCloseDialog && (
        <MessageBoxYesNo
          title="CREATE NEW"
          action="Yes"
          onClose={() => {
            this.setState({ showAskCloseDialog: false });
          }}
          onAction={this.backClick.bind(this, this.state.redirectModule)}
          caption="All unsaved changes will be lost. Do you wish to continue?"
        />
      )}
    </>
    );
  }
}

export default EmployeeLoad;

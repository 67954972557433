import React from 'react';
import { Card, Image, Icon } from 'semantic-ui-react';
import 'semantic-ui-css/semantic.min.css';
import SimpleTemplate from './SimpleTemplate'
import styles from './ErrorPage.module.css'

class ErrorPage extends SimpleTemplate {

    state = {
        description : "",
        header : "",
        icon : ""
    }
    constructor(props) {
        super(props);
        var showLogin = false;

        switch (props.id.toLowerCase()) {
            case "unknown_company":
                this.state.description = "Company is not recognized";
                this.state.header = "Invalid Company";
                this.state.icon = '/Icons/error.png';
                break;
            case "invalid_path":
                this.state.description = "You have entered an invalid path";
                this.state.header = "Invalid Path";
                this.state.icon = '/Icons/warning.png';
                break;
            case "expired":
                this.state.description = "Your login session has timed out. Please login again."
                this.state.header = "Session Timed Out";
                this.state.icon = '/Icons/warning.png';
                showLogin = true;
                break;
            case "no_session":
                this.state.description = "You are currently logged out.  Please login again to access the system."
                this.state.header = "No Session";
                this.state.icon = '/Icons/warning.png';
                showLogin = true;
                break;
            case "session_invalid":
                this.state.description = "The current session you are logged in is already invalid.  Please try to login again.";
                this.state.header = "Invalid Session";
                this.state.icon = '/Icons/warning.png';
                showLogin = true;
                break;
            case "exception":
                this.state.description = "An unhandled exception has occurred.  Please see logs for details";
                this.state.header = "Error";
                this.state.icon = '/Icons/error.png';
                break;
            default:
                this.state.description = "Invalid error code";
                this.state.header = "Error";
                this.state.icon = '/Icons/error.png';
                break;
        }

        this.state.child = (
            <div className={styles.module}>
                <div>
                    <Image floated='left' size='mini' src={this.state.icon} circular />
                    {this.state.header}
                </div>
                <div>
                    {this.state.description}
                </div>                    
                { showLogin === false &&
                <a href="https://www.zkteco.ph">
                    Please contact ZKTeco Philippines to report this issue
                </a>                    
                }
                { showLogin  &&
                <a href="/">
                    Go to login page
                </a>                    
                }
            </div>
        )
    }

}

export default ErrorPage;
import React, { Component } from 'react';
import 'semantic-ui-css/semantic.min.css';
import { Modal, Button, Checkbox } from '../../../../node_modules/semantic-ui-react';
import Select from 'react-select';

const moment = require('moment');

function civilStatList() {
    var CivilStatus = [];
    CivilStatus.push({ label: "All", value: "ALL" });
    CivilStatus.push({ label: "Unspecified", value: "UNSPECIFIED" });
    CivilStatus.push({ label: "Married", value: "MARRIED" });
    CivilStatus.push({ label: "Single", value: "SINGLE" });
    CivilStatus.push({ label: "Widowed", value: "WIDOWED" });
    CivilStatus.push({ label: "Separated", value: "SEPARATED" });
    return CivilStatus;
}
class EmployeeReportModalGroupByCivilStatus extends Component {

    constructor(props) {
        super(props);
        this.state = {
            includeDeleted: false,
            selectedCivilStatus: "",
            includeInactive: false,
        }
    }
    getParameters = () => {
        return {
            message: "from employee report group by civil status modal",
            CivilStatus: (this.state.selectedCivilStatus === "" ? null : this.state.selectedCivilStatus.value),
            includeDeleted: this.state.includeInactive,
            startingIndex: 0,
            itemCount: 20,
            FilterDetails: "Civil Status: " + (this.state.selectedCivilStatus === null || this.state.selectedCivilStatus === "" ? "ALL" : this.state.selectedCivilStatus.value)
        };
    }

    handleComboChange = (value) => {
        this.setState({ selectedCivilStatus: value })
    }

    onChkCheckChange = (e) => {
        this.setState({ includeInactive: !this.state.includeInactive })
    }
    render() {
        return (
            <Modal open={this.props.open} size="tiny">
                <Modal.Header>
                    <p>Employee Report Group By CivilStatus Filters</p>
                </Modal.Header>
                <Modal.Content>
                    <div class="ui grid">
                        <div class="left floated left aligned sixteen wide column">
                            <label>Civil Status</label>
                            <Select placeholder='Civil Status' name="CivilStatusList"
                                onChange={this.handleComboChange.bind(this)}
                                options={civilStatList()}
                                value={this.state.selectedCivilStatus} />
                            <Checkbox size='mini'
                                label="Include Resigned"
                                onChange={this.onChkCheckChange.bind(this)}
                                checked={this.state.includeInactive} />
                        </div>
                    </div>
                </Modal.Content>
                <Modal.Actions>
                    <Button basic content="Generate" onClick={this.props.onSave}></Button>
                    <Button basic content="Cancel" onClick={this.props.onCancel}></Button>
                </Modal.Actions>
            </Modal>
        );
    }

}

export default EmployeeReportModalGroupByCivilStatus;
import React, { Component, useEffect } from 'react';
import { propTypes } from 'react-recaptcha';
import { Icon, Item, Loader, Progress } from 'semantic-ui-react';

import modStyles from './StatusPanel.module.css';

export function StatusPanel (props) {
    // const [loaded,setLoaded] = React.useState(0);
    const [items, setItems] = React.useState([]);
    const panel = React.useRef(null);

    const getHeader = (item) => {
        var text = "Unknown Command to ";
        if( item.Command === "DOWNLOAD_USERS"){
            text = "Downloading users from ";
        }
        if( item.Command === "UPLOAD_USERS"){
            text = "Uploading users to ";
        }
        if( item.Command === "REMOVE_USERS"){
            text = "Removing users from ";
        }
        if( item.Command === "DOWNLOAD_DEVICEINFO"){
            text = "Downloading device information from ";
        }
        if( item.Command === "RESEND_ATTLOGS"){
            text = "Resending attendance logs from ";
        }
        text += item.DeviceName != null ? item.DeviceName : item.SerialNumber;

        return text;
    }

    const updateProgress = (updatedItems) =>{
        props.onChangeNotificationStatus(updatedItems.some( x => x.Status === "ACTIVE"));
    }

    const removeItem = (item, e) => {
        setItems( prev => {
            var index = prev.findIndex(x=>x==item);
            if( index !== -1){
                prev.splice(index, 1);
                updateProgress(prev);
                return [...prev];
            }
            return prev;
        })
    }

    const onBlur = (e) => {
        var isChild = panel.current?.contains(e.relatedTarget);
        if( !isChild ){
            props.onBlur();
        }
    }

    useEffect(()=>{
        global.AddNotificationToPanel = (newItems) => {
            setItems(prev => {
                var updatedItems = [...prev, ...newItems];
                updateProgress(updatedItems);
                return updatedItems;
            } );
        }
        global.ProcessNotification = (data) => {
            var updatedItem = JSON.parse(data);
            setItems( prev => {
                var index = prev.findIndex( x=>x.Id == updatedItem.Id);
                if( index !== -1){
                    var prevItem = prev[index];
                    if( prevItem.Status !== "DONE" ){
                        if( updatedItem.Type === "UPDATE_TOTAL"){
                            updatedItem = {...prevItem, Total:updatedItem.Total}
                        }
                        else{
                            updatedItem = {...prevItem, Progress:updatedItem.Progress}
                        }
                        if( updatedItem.Total !== 0 && updatedItem.Total === updatedItem.Progress){
                            updatedItem.Status = "DONE";
                        }
                        prev.splice( index, 1, updatedItem );
                        updateProgress(prev);
                    }
                    return [...prev];
                }

                return prev;
            })
        }
    },[]);

    if( props.visible === false ){
        return <div></div>
    }

    
    return <div ref={panel} className={modStyles.container} onBlur={onBlur} >
        <input autoFocus style={{opacity:0}} />
        {
            items.length === 0 &&
            <div>There are no device activity to display</div>
        }
        {
            items.map( (item,index) => {
                return <div className={modStyles.notification}>
                    {
                        item.Status === "DONE" &&
                        <Icon name="check circle" color="green" className={modStyles.statusIcon} />
                    }
                    {
                        item.Status === "ACTIVE" &&
                        <Icon name="spinner" color="blue" loading className={modStyles.statusIcon} />
                    }
                    <div>
                        <div className={modStyles.notificationText}>
                            <div>{getHeader(item)}</div>
                            {
                                item.Total > 0 &&
                                <div>{Math.trunc(item.Progress/item.Total*100).toString()+"%"}</div>
                            }
                        </div>
                        { item.Total > 0 &&
                            <Progress 
                                key={`notification${index}`}
                                total={item.Total} 
                                value={item.Progress} 
                                color="green"
                                size="tiny"
                            />
                        }       
                    </div>
                    <div tabIndex="0" onClick={removeItem.bind(this, item)}>
                        <Icon  link name="close" />
                    </div>
                </div>
            })
        }
    </div>
}
import 'react-big-calendar/lib/css/react-big-calendar.css';
import './CustomReactBigCalendar.css';
import { Calendar, EventCell } from 'react-big-calendar'
import React, { Component } from 'react';
import moment from 'moment';

export default class CustomReactBigCalendar extends Component {
    constructor(props) {
        super(props);
    }

    getComponents = () => {
        return {
            dateCellWrapper: (test) => {
                let holiday = this.props.holidays.find(x => x.Date === moment(test.value.toString()).format("MM/DD/YYYY"))
                return <div className={test.children.props.className}>
                    {holiday !== undefined && 
                        <div className='holiday-display'>
                            {holiday.HolidayName}
                        </div>
                    }
                    {this.props.restdays && this.props.restdays.includes(moment(test.value.toString()).format("MM/DD/YYYY")) &&
                        <div className='restday-display'>
                            Restday
                        </div>
                    }
                    
                </div>
            }
        }
    }

    render() {
        return (
        <div>
            <Calendar
              components={this.getComponents()}
              localizer={this.props.localizer}
              popup={this.props.popup}
              date={this.props.date}
              defaultDate={this.props.defaultDate}
              view={this.props.view}
              toolbar={this.props.toolbar}
              events={this.props.events}
              onEventDrop={this.props.onEventDrop}
              onEventResize={this.props.onEventResize}
              onSelectEvent={this.props.onSelectEvent}
              onNavigate={this.props.onNavigate}
              onView={this.props.onView}
              selectable={this.props.selectable}
              selected={this.props.selected}
              onDoubleClickEvent={this.props.onDoubleClickEvent}
              onSelectSlot={this.props.onSelectSlot}
              style={this.props.style}
              eventPropGetter={this.props.eventPropGetter}
            />
        </div>)
    }
  
}
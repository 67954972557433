import React, { Component } from 'react';
import 'semantic-ui-css/semantic.min.css';
import { Button, Table, Modal, Input, Label } from 'semantic-ui-react';
import { isTaskAvailable, handleNumeric } from '../../../utils';
const moment = require('moment');

function toTitle(title){
    var titleField = title.split("_").join(" ");
    titleField = titleField.replace(/([a-z])([A-Z])/g, '$1 $2');
    titleField = toTitleCase(titleField);
    return titleField;
}

function toTitleCase(str) {
    return str.replace(/\w\S*/g, function(txt){
        return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
    });
}

class ViewNotificationDetails extends Component {
    constructor(props) {
        super(props);
        var open = this.props.isOpen === undefined? true: this.props.isOpen;
        this.state = { isOpen: open, remarks: "", approvedOTHours: 0 };
        if (this.props.data.ActionTaken && this.props.data.ActionTaken !== "NONE") {
                this.props.viewData.Status = this.props.data.ActionTaken === "READ" ? this.props.data.Data["Status"] : this.props.data.ActionTaken;
        }
    }

    handleAction = (status) => {
        var data = this.props.data;
        if(status == "APPROVED" && this.props.hasApprovedOTField)
        {
            let approvedOTHours = this.state.approvedOTHours;
            //approvedOTHours = approvedOTHours && (approvedOTHours >= 0 && approvedOTHours * 60 <= data.Data["Minutes"]) ? approvedOTHours : 0;
            approvedOTHours = approvedOTHours && approvedOTHours >= 0 ? approvedOTHours : 0;
            data.Data["ApprovedHours"] = approvedOTHours;
        }

        if(data.Data !== undefined)
        {
            data.Data["ApproverRemarks"] = this.state.remarks;
            this.props.onAction(status, data, this.props.callback);
        }

        this.setState({isOpen: false});
    }
/*
    imagePath(uri) {
        return global.ApiHost + "api/Employee/avatarImageByFilename?filename=" + uri;
    }

    enlargeImage(uri) {
        return global.ApiHost + "api/Employee/employeeImageByFilename?filename=" + uri;
    }
*/
    render() {
        const data = {ActionTaken: "NONE", IsActionable: false};
        const viewData = this.props.viewData;
        return (
            <div>
                <Modal open={this.state.isOpen} onOpen={ ()=> this.setState({isOpen: true})} size='tiny' trigger={this.props.trigger} closeIcon={true} onClose={this.handleAction.bind(this,'CLOSE')}>
                    <Modal.Header>{this.props.Title}</Modal.Header>
                    <Modal.Content>
                        <Table basic='very' style={{ "width": "100%" }}>
                            {
                                    Object.keys(this.props.viewData).map((name, index) => (
                                        !(name === "Source" || name === "UniqueFilename" || name === "Application") &&
                                        <Table.Row>
                                            <Table.HeaderCell style={{ "width": "40%" }}>{toTitle(name)}</Table.HeaderCell>
                                            {((this.props.isMultipleValues !== true && this.props.viewData["Changed Field"] !== "Image") || !(name === 'Old Value' || name === 'New Value')) && 
                                                <Table.Cell style={{ "border-style": "none", "width": "60%"}}>
                                                    <p style={{overflowWrap: 'break-word', width: '300px'}}>
                                                        {name.includes("Date") && !(this.props.viewData[name] === null ||  this.props.viewData[name] === "")? moment(this.props.viewData[name]).format("MM/DD/YYYY"):this.props.viewData[name]}
                                                    </p>
                                                </Table.Cell>
                                            }
                                            {(name === 'Old Value' || name === 'New Value') && this.props.viewData["Changed Field"] === "Image" &&
                                                <Table.Cell style={{ "border-style": "none", cursor: 'zoom-in', "width": "60%", "backgroundImage": 'url(' + this.imagePath(this.props.viewData[name]) + ')','background-repeat': 'no-repeat', 'background-size': 'contain', color: 'transparent' }}
                                                    onClick={()=>this.setState({enlargePic:true, uri: this.props.viewData[name]})}> IMAGE 
                                                </Table.Cell>
                                            }
                                            {(this.props.isMultipleValues === true && (name === 'Old Value' || name === 'New Value')) && this.props.viewData[name] !== "" &&
                                                <Table.Cell style={{ "border-style": "none", "width": "60%" }}>
                                                    <ViewNotificationDetails isOpen={false} trigger={<Button>See details</Button>} Title={this.props.viewData[name].Type} viewData={this.props.viewData[name].Details} data={data}/>
                                                </Table.Cell>
                                            }
                                        </Table.Row>
                                        
                                    ))
                            }
                            { this.props.data.IsActionable && this.props.hasApprovedOTField &&
                            <Table.Row>
                                <Table.HeaderCell style={{ "width": "40%" }}>Approved OT Hours</Table.HeaderCell>
                                <Table.Cell style={{ "border-style": "none", "width": "60%" }}>
                                    <Input fluid value={this.state.approvedOTHours} 
                                        onChange={(event) => { this.setState({ approvedOTHours: handleNumeric(event) }); }} 
                                    />
                                </Table.Cell>
                            </Table.Row>
                            }
                            { this.props.data.IsActionable &&
                            <Table.Row>
                                <Table.HeaderCell style={{ "width": "40%" }}>Approver's Remarks</Table.HeaderCell>
                                <Table.Cell style={{ "border-style": "none", "width": "60%" }}><Input fluid value={this.state.remarks} onChange={(evt) => { this.setState({ remarks: evt.target.value }) } } /></Table.Cell>
                            </Table.Row>
                            }
                        </Table>
                    </Modal.Content>
                    { this.props.viewData.Status === "PENDING" && this.props.data.IsActionable &&
                    <Modal.Actions>
                        { this.props.data.ActionSelection.filter(a => a.Action === "Approve" && isTaskAvailable(a.AccessRight)).length === 1 && <Button content="Approve" onClick={this.handleAction.bind(this,'APPROVED')}></Button> }
                        { this.props.data.ActionSelection.filter(a => a.Action === "Reject" && isTaskAvailable(a.AccessRight)).length === 1 && <Button content="Reject" onClick={this.handleAction.bind(this,'REJECTED')}></Button> }
                        { this.props.data.ActionSelection.filter(a => a.Action === "Cancel" && isTaskAvailable(a.AccessRight)).length === 1 && <Button content="Cancel" onClick={this.handleAction.bind(this,'CANCELLED')}></Button> }
                    </Modal.Actions>
                    }
                </Modal>
            </div>
        );
    }
}

export default ViewNotificationDetails
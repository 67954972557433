import React, { Component } from "react";
import Select from "react-select";

import {
    Checkbox,
    Icon,
    Input,
    Popup,
} from "semantic-ui-react";

import {
    isTaskAvailable,
} from "../../utils";

import {
    selectionList
} from './EmployeeCommon'

function groupMap(groupList, groupType) {
    var list = [];
    if (groupList != null) {
        if (groupType !== "SALARY_GRADE") {
            groupList.map((x) => {
                return x.Type === groupType
                    ? list.push({ label: x.Name, value: x._id })
                    : [];
            });
        } else {
            groupList.map((x) => {
                return x.Type === groupType
                    ? list.push({
                        label: x.Name + " (" + x.OtherDetails.Amount + ")",
                        value: x._id,
                    })
                    : [];
            });
        }
    }
    return list;
}

var {
    handleNumericOnKeyPress,
    handleNumericOnKeyDown,
    customStyles,
    fullName,
    transformToTitle,
} = require("../../utils");

const EmploymentStatusOption = [
    { label: "Probationary", value: "PROBATIONARY" },
    { label: "Regular", value: "REGULAR" },
    { label: "Contractual", value: "CONTRACTUAL" },
];


export function renderHRInfo(this2) {

    const {
        _id,
        EmployeeNo,
        UserRoleId,
        LeaveProfileId,
        DateHired,
        RegularizationDate,
        ResignationDate,
        Password,
    } = this2.state.selectedEmployee;

    const {
        userRoleList,
        leaveProfileList,
        referralList,
    } = this2.state;

    const {
        supervisorList,
    } = this2.state;

    const {
        ReferredBy,
        InternalReferral,
        ReferralRemarks,
        ReferralPayoutDate,
        EmploymentStatus,
    } = this2.state.selectedEmployee;

    const noAccess = !(isTaskAvailable("EMPLOYEE_HRINFO_EDIT") || this2.state.isSuperAdmin)

    return (
        <div className="w3-row" style={{ width: "100%" }} disabled={true}>
            <div className="w3-col m12 l6">
                <div className="w3-row" style={{ paddingTop: "10px" }}>
                    <div
                        className="w3-col s12 m5 l5"
                        style={{ paddingLeft: "10px" }}
                    >
                        <label> Employee Number *</label>
                    </div>
                    <div className="w3-col s12 m7 l7">
                        <Input
                            disabled={noAccess}
                            fluid
                            placeholder="Employee Number"
                            value={EmployeeNo}
                            tabIndex={16}
                            error={this2.state.employeeNoIsError}
                            onChange={this2.handleChange.bind(
                                this,
                                "EmployeeNo",
                                false,
                                "employeeNoIsError"
                            )}
                            ref={(input) => (this2.EmployeeNo = input)}
                        />
                    </div>
                </div>

                <div className="w3-row" style={{ paddingTop: "10px" }}>
                    <div
                        className="w3-col s12 m5 l5"
                        style={{ paddingLeft: "10px" }}
                    >
                        <label>User Role</label>
                    </div>
                    <div className="w3-col s12 m7 l7">
                        <Select
                            isClearable
                            isDisabled={noAccess}
                            styles={customStyles}
                            placeholder="User Role"
                            value={selectionList(
                                userRoleList,
                                ["UserRole"],
                                "_id"
                            ).find((x) => x.value == UserRoleId)}
                            options={selectionList(userRoleList, ["UserRole"], "_id")}
                            onChange={this2.handleDropDownChange("UserRoleId").bind(
                                this
                            )}
                            tabIndex={17}
                        />
                        {isTaskAvailable("USERRIGHTS_ACCESS") && !noAccess && (
                            <a
                                as="a"
                                href="#"
                                onClick={this2.onCreateNewItemClick.bind(
                                    this,
                                    "USERROLE",
                                    false
                                )}
                            >
                                Create New
                            </a>
                        )}
                    </div>
                </div>

                {!this2.state.isSuperAdmin && (
                    <div className="w3-row" style={{ paddingTop: "10px" }}>
                        <div
                            className="w3-col s12 m5 l5"
                            style={{ paddingLeft: "10px" }}
                        >
                            <label>Employment Status</label>
                        </div>
                        <div className="w3-col s12 m7 l7">
                            <Select
                                isClearable
                                isDisabled={noAccess}
                                styles={customStyles}
                                placeholder="Employment Status"
                                value={EmploymentStatusOption.find(
                                    (x) => x.value == EmploymentStatus
                                )}
                                options={EmploymentStatusOption}
                                onChange={this2.handleDropDownChange(
                                    "EmploymentStatus"
                                ).bind(this)}
                                tabIndex={17}
                            />
                        </div>
                    </div>
                )}

                {!this2.state.isSuperAdmin && (
                    <div className="w3-row" style={{ paddingTop: "10px" }}>
                        <div
                            className="w3-col s12 m5 l5"
                            style={{ paddingLeft: "10px" }}
                        >
                            <label> Date Hired</label>
                        </div>
                        <div className="w3-col s12 m7 l7">
                            <Input
                                disabled={noAccess}
                                size="small"
                                value={DateHired}
                                fluid
                                type="date"
                                min="1900-01-01"
                                max="2999-12-31"
                                onChange={this2.handleChange.bind(
                                    this,
                                    "DateHired",
                                    false,
                                    ""
                                )}
                                tabIndex={18}
                            />
                        </div>
                    </div>
                )}

                {!this2.state.isSuperAdmin && (
                    <div className="w3-row" style={{ paddingTop: "10px" }}>
                        <div
                            className="w3-col s12 m5 l5"
                            style={{ paddingLeft: "10px" }}
                        >
                            <label> Regularization Date</label>
                        </div>
                        <div className="w3-col s12 m7 l7">
                            <Input
                                disabled={noAccess}
                                size="small"
                                value={RegularizationDate}
                                fluid
                                min="1900-01-01"
                                max="2999-12-31"
                                type="date"
                                onChange={this2.handleChange.bind(
                                    this,
                                    "RegularizationDate",
                                    false,
                                    ""
                                )}
                                tabIndex={19}
                            />
                        </div>
                    </div>
                )}

                {!this2.state.isSuperAdmin && (
                    <div className="w3-row" style={{ paddingTop: "10px" }}>
                        <div
                            className="w3-col s12 m5 l5"
                            style={{ paddingLeft: "10px" }}
                        >
                            <label> Resignation Date</label>
                        </div>
                        <div className="w3-col s12 m7 l7">
                            <Input
                                disabled="true"
                                size="small"
                                value={ResignationDate}
                                fluid
                                type="date"
                                min="1900-01-01"
                                max="2999-12-31"
                                onChange={this2.handleChange.bind(
                                    this,
                                    "ResignationDate",
                                    false,
                                    ""
                                )}
                                tabIndex={20}
                            />
                        </div>
                    </div>
                )}

                {_id === undefined && (
                    <div className="w3-row" style={{ paddingTop: "10px" }}>
                        <div
                            className="w3-col s12 m5 l5"
                            style={{ paddingLeft: "10px" }}
                        >
                            <label>
                                {"Password (max 16) *"}
                                <Popup
                                    trigger={
                                        <Icon
                                            size="small"
                                            name="question circle"
                                            circular
                                        />
                                    }
                                    content="Note: Password must be within 8 to 16 characters with at least one (1) Uppercase, lowercase and numeric."
                                    on="click"
                                    hideOnScroll
                                />
                            </label>
                        </div>
                        <div className="w3-col s12 m7 l7">
                            <Input
                                fluid
                                placeholder="Password"
                                value={Password}
                                tabIndex={21}
                                type={this2.state.ShowPassword ? "text" : "password"}
                                maxLength="16"
                                onChange={this2.handleChange.bind(
                                    this,
                                    "Password",
                                    false,
                                    "passwordIsError"
                                )}
                                ref={(input) => (this2.Password = input)}
                                icon={
                                    <Icon
                                        name={"eye"}
                                        onMouseUp={() => {
                                            this2.setState({ ShowPassword: false });
                                        }}
                                        onMouseDown={() => {
                                            this2.setState({ ShowPassword: true });
                                        }}
                                        link
                                    />
                                }
                                error={this2.state.passwordIsError === true}
                            />
                        </div>
                    </div>
                )}
                {!this2.state.isSuperAdmin && (
                    <div className="w3-row" style={{ paddingTop: "10px" }}>
                        <div
                            className="w3-col s12 m5 l5"
                            style={{ paddingLeft: "10px" }}
                        >
                            <label> Internal Referral </label>
                        </div>
                        <div className="w3-col s12 m7 l7">
                            <Checkbox
                                disabled={noAccess}
                                toggle
                                checked={InternalReferral}
                                onChange={this2.handleInternalReferralChange.bind(this)}
                                tabIndex={22}
                            />
                        </div>
                    </div>
                )}

                {!this2.state.isSuperAdmin && (
                    <div className="w3-row" style={{ paddingTop: "10px" }}>
                        <div
                            className="w3-col s12 m5 l5"
                            style={{ paddingLeft: "10px" }}
                        >
                            <label> Referred By </label>
                        </div>
                        <div className="w3-col s12 m7 l7">
                            <Select
                                isClearable
                                isDisabled={!InternalReferral || noAccess}
                                styles={customStyles}
                                placeholder="Referred By"
                                value={selectionList(
                                    referralList,
                                    ["FirstName", "LastName"],
                                    "_id"
                                ).find((x) => x.value == ReferredBy)}
                                options={selectionList(
                                    referralList,
                                    ["FirstName", "LastName"],
                                    "_id"
                                )}
                                onChange={this2.handleReferralChange.bind(this)}
                                isLoading={this2.state.isReferralEmpLoading}
                                onInputKeyDown={this2.handleReferralInputKeyDown.bind(
                                    this
                                )}
                                onInputChange={this2.handleReferralSearch.bind(this)}
                                tabIndex={23}
                            />
                        </div>
                    </div>
                )}

                {!this2.state.isSuperAdmin && (
                    <div className="w3-row" style={{ paddingTop: "10px" }}>
                        <div
                            className="w3-col s12 m5 l5"
                            style={{ paddingLeft: "10px" }}
                        >
                            <label> Payout Date</label>
                        </div>
                        <div className="w3-col s12 m7 l7">
                            <Input
                                disabled={noAccess}
                                size="small"
                                value={ReferralPayoutDate}
                                fluid
                                type="date"
                                min="1900-01-01"
                                max="2999-12-31"
                                onChange={this2.handleChange.bind(
                                    this,
                                    "ReferralPayoutDate",
                                    false,
                                    ""
                                )}
                                tabIndex={24}
                            />
                        </div>
                    </div>
                )}

                {!this2.state.isSuperAdmin && (
                    <div className="w3-row" style={{ paddingTop: "10px" }}>
                        <div
                            className="w3-col s12 m5 l5"
                            style={{ paddingLeft: "10px" }}
                        >
                            <label> Referral Remarks</label>
                        </div>
                        <div className="w3-col s12 m7 l7">
                            <Input
                                disabled={noAccess}
                                fluid
                                placeholder="Referral Remarks"
                                value={ReferralRemarks}
                                tabIndex={25}
                                onChange={this2.handleChange.bind(
                                    this,
                                    "ReferralRemarks",
                                    false,
                                    ""
                                )}
                            />
                        </div>
                    </div>
                )}
            </div>

            <div className="w3-col m12 l6">
                {!this2.state.isSuperAdmin && (
                    <div className="w3-row" style={{ paddingTop: "10px" }}>
                        <div
                            className="w3-col s12 m5 l5"
                            style={{ paddingLeft: "10px" }}
                        >
                            <label> Leave Profile </label>
                        </div>
                        <div className="w3-col s12 m7 l7">
                            <Select
                                isClearable
                                isDisabled={noAccess}
                                size="mini"
                                styles={customStyles}
                                placeholder="Leave Profile"
                                value={selectionList(
                                    leaveProfileList,
                                    ["Description"],
                                    "_id"
                                ).find((x) => x.value == LeaveProfileId)}
                                options={selectionList(
                                    leaveProfileList,
                                    ["Description"],
                                    "_id"
                                )}
                                onChange={this2.handleDropDownChange(
                                    "LeaveProfileId"
                                ).bind(this)}
                                tabIndex={17}
                            />
                            {isTaskAvailable("LEAVE_PROFILE_CREATE")  && !noAccess && (
                                <a
                                    as="a"
                                    href="#"
                                    onClick={this2.onCreateNewItemClick.bind(
                                        this,
                                        "LEAVEPROFILE",
                                        false
                                    )}
                                >
                                    Create New
                                </a>
                            )}
                        </div>
                    </div>
                )}
                {!this2.state.isSuperAdmin && (
                    <div className="w3-row" style={{ paddingTop: "10px" }}>
                        <div
                            className="w3-col s12 m5 l5"
                            style={{ paddingLeft: "10px" }}
                        >
                            <label> Company </label>
                        </div>
                        <div className="w3-col s12 m7 l7">
                            <Select
                                isClearable
                                isDisabled={noAccess}
                                size="mini"
                                styles={customStyles}
                                placeholder="Company"
                                value={selectionList(
                                    groupMap(this2.state.groups, "COMPANY"),
                                    ["label"],
                                    "value"
                                ).find(
                                    (x) =>
                                        x.value === this2.state.selectedEmployee.CompanyId
                                )}
                                options={groupMap(
                                    this2.state.groups,
                                    "COMPANY",
                                    ["label"],
                                    "value"
                                )}
                                onChange={this2.handleDropDownChange("CompanyId").bind(
                                    this
                                )}
                                tabIndex={17}
                            />
                            {isTaskAvailable("GROUP_ADD") && !noAccess && (
                                <a
                                    as="a"
                                    href="#"
                                    onClick={this2.onCreateNewItemClick.bind(
                                        this,
                                        "COMPANY",
                                        true
                                    )}
                                >
                                    Create New
                                </a>
                            )}
                        </div>
                    </div>
                )}
                {!this2.state.isSuperAdmin && (
                    <div className="w3-row" style={{ paddingTop: "10px" }}>
                        <div
                            className="w3-col s12 m5 l5"
                            style={{ paddingLeft: "10px" }}
                        >
                            <label> Division </label>
                        </div>
                        <div className="w3-col s12 m7 l7">
                            <Select
                                isClearable
                                isDisabled={noAccess}
                                size="mini"
                                styles={customStyles}
                                placeholder="Division"
                                value={selectionList(
                                    groupMap(this2.state.groups, "DIVISION"),
                                    ["label"],
                                    "value"
                                ).find(
                                    (x) =>
                                        x.value === this2.state.selectedEmployee.DivisionId
                                )}
                                options={groupMap(
                                    this2.state.groups,
                                    "DIVISION",
                                    ["label"],
                                    "value"
                                )}
                                onChange={this2.handleDropDownChange("DivisionId").bind(
                                    this
                                )}
                                tabIndex={17}
                            />
                            {isTaskAvailable("GROUP_ADD")  && !noAccess && (
                                <a
                                    as="a"
                                    href="#"
                                    onClick={this2.onCreateNewItemClick.bind(
                                        this,
                                        "DIVISION",
                                        true
                                    )}
                                >
                                    Create New
                                </a>
                            )}
                        </div>
                    </div>
                )}
                {!this2.state.isSuperAdmin && (
                    <div className="w3-row" style={{ paddingTop: "10px" }}>
                        <div
                            className="w3-col s12 m5 l5"
                            style={{ paddingLeft: "10px" }}
                        >
                            <label> Department </label>
                        </div>
                        <div className="w3-col s12 m7 l7">
                            <Select
                                isClearable
                                isDisabled={noAccess}
                                size="mini"
                                styles={customStyles}
                                placeholder="Department"
                                value={selectionList(
                                    groupMap(this2.state.groups, "DEPARTMENT"),
                                    ["label"],
                                    "value"
                                ).find(
                                    (x) =>
                                        x.value === this2.state.selectedEmployee.DepartmentId
                                )}
                                options={groupMap(
                                    this2.state.groups,
                                    "DEPARTMENT",
                                    ["label"],
                                    "value"
                                )}
                                onChange={this2.handleDropDownChange(
                                    "DepartmentId"
                                ).bind(this)}
                                tabIndex={17}
                            />
                            {isTaskAvailable("GROUP_ADD") && !noAccess && (
                                <a
                                    as="a"
                                    href="#"
                                    onClick={this2.onCreateNewItemClick.bind(
                                        this,
                                        "DEPARTMENT",
                                        true
                                    )}
                                >
                                    Create New
                                </a>
                            )}
                        </div>
                    </div>
                )}
                {!this2.state.isSuperAdmin && (
                    <div className="w3-row" style={{ paddingTop: "10px" }}>
                        <div
                            className="w3-col s12 m5 l5"
                            style={{ paddingLeft: "10px" }}
                        >
                            <label> Position </label>
                        </div>
                        <div className="w3-col s12 m7 l7">
                            <Select
                                isClearable
                                isDisabled={noAccess}
                                size="mini"
                                styles={customStyles}
                                placeholder="Position"
                                value={selectionList(
                                    groupMap(this2.state.groups, "POSITION"),
                                    ["label"],
                                    "value"
                                ).find(
                                    (x) =>
                                        x.value === this2.state.selectedEmployee.PositionId
                                )}
                                options={groupMap(
                                    this2.state.groups,
                                    "POSITION",
                                    ["label"],
                                    "value"
                                )}
                                onChange={this2.handleDropDownChange("PositionId").bind(
                                    this
                                )}
                                tabIndex={17}
                            />
                            {isTaskAvailable("GROUP_ADD") && !noAccess && (
                                <a
                                    as="a"
                                    href="#"
                                    onClick={this2.onCreateNewItemClick.bind(
                                        this,
                                        "POSITION",
                                        true
                                    )}
                                >
                                    Create New
                                </a>
                            )}
                        </div>
                    </div>
                )}
                {!this2.state.isSuperAdmin && (
                    <div className="w3-row" style={{ paddingTop: "10px" }}>
                        <div
                            className="w3-col s12 m5 l5"
                            style={{ paddingLeft: "10px" }}
                        >
                            <label> Cost Center </label>
                        </div>
                        <div className="w3-col s12 m7 l7">
                            <Select
                                isClearable
                                isDisabled={noAccess}
                                size="mini"
                                styles={customStyles}
                                placeholder="Cost Center"
                                value={selectionList(
                                    groupMap(this2.state.groups, "COST_CENTER"),
                                    ["label"],
                                    "value"
                                ).find(
                                    (x) =>
                                        x.value === this2.state.selectedEmployee.CostCenterId
                                )}
                                options={groupMap(
                                    this2.state.groups,
                                    "COST_CENTER",
                                    ["label"],
                                    "value"
                                )}
                                onChange={this2.handleDropDownChange(
                                    "CostCenterId"
                                ).bind(this)}
                                tabIndex={17}
                            />
                            {isTaskAvailable("GROUP_ADD") && !noAccess && (
                                <a
                                    as="a"
                                    href="#"
                                    onClick={this2.onCreateNewItemClick.bind(
                                        this,
                                        "COST_CENTER",
                                        true
                                    )}
                                >
                                    Create New
                                </a>
                            )}
                        </div>
                    </div>
                )}
                {!this2.state.isSuperAdmin && (
                    <div className="w3-row" style={{ paddingTop: "10px" }}>
                        <div
                            className="w3-col s12 m5 l5"
                            style={{ paddingLeft: "10px" }}
                        >
                            <label> City </label>
                        </div>
                        <div className="w3-col s12 m7 l7">
                            <Select
                                isClearable
                                isDisabled={noAccess}
                                size="mini"
                                styles={customStyles}
                                placeholder="City"
                                value={selectionList(
                                    groupMap(this2.state.groups, "CITY"),
                                    ["label"],
                                    "value"
                                ).find(
                                    (x) => x.value === this2.state.selectedEmployee.CityId
                                )}
                                options={groupMap(
                                    this2.state.groups,
                                    "CITY",
                                    ["label"],
                                    "value"
                                )}
                                onChange={this2.handleDropDownChange("CityId").bind(
                                    this
                                )}
                                tabIndex={17}
                            />
                            {isTaskAvailable("GROUP_ADD") && !noAccess && (
                                <a
                                    as="a"
                                    href="#"
                                    onClick={this2.onCreateNewItemClick.bind(
                                        this,
                                        "CITY",
                                        true
                                    )}
                                >
                                    Create New
                                </a>
                            )}
                        </div>
                    </div>
                )}
                {!this2.state.isSuperAdmin && (
                    <div className="w3-row" style={{ paddingTop: "10px" }}>
                        <div
                            className="w3-col s12 m5 l5"
                            style={{ paddingLeft: "10px" }}
                        >
                            <label> Reporting To </label>
                        </div>
                        <div className="w3-col s12 m7 l7">
                            <Select
                                isClearable
                                isDisabled={noAccess}
                                size="mini"
                                styles={customStyles}
                                placeholder="Reporting To"
                                value={selectionList(
                                    groupMap(this2.state.groups, "POSITION"),
                                    ["label"],
                                    "value"
                                ).find(
                                    (x) =>
                                        x.value === this2.state.selectedEmployee.ReportingTo
                                )}
                                options={groupMap(
                                    this2.state.groups,
                                    "POSITION",
                                    ["label"],
                                    "value"
                                )}
                                onChange={this2.handleDropDownChange("ReportingTo").bind(
                                    this
                                )}
                                tabIndex={17}
                            />
                        </div>
                    </div>
                )}
                {!this2.state.isSuperAdmin && (
                    <div className="w3-row" style={{ paddingTop: "10px", paddingBottom: "120px" }}>
                        <div
                            className="w3-col s12 m5 l5"
                            style={{ paddingLeft: "10px" }}
                        >
                            <label> Supervisor </label>
                        </div>
                        <div className="w3-col s12 m7 l7">
                            <Select
                                isClearable
                                isDisabled={noAccess}
                                maxMenuHeight="100px"
                                styles={customStyles}
                                placeholder="Select Supervisor"
                                value={this2.state.selectedSupervisor}
                                options={selectionList(
                                    supervisorList,
                                    ["FirstName", "LastName"],
                                    "_id"
                                )}
                                onFocus={this2.handleSupervisorChange.bind(this)}
                                onChange={this2.handleSupervisorChange.bind(this)}
                                isLoading={this2.state.isSupervisorLoading}
                                onInputChange={this2.handleSupervisorInputKeyDown.bind(
                                    this
                                )}
                                ref={(input) => (this2.Supervisor = input)}
                            />
                        </div>
                    </div>
                )}
            </div>
        </div>
    )
}
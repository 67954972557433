import React from 'react';
import 'semantic-ui-css/semantic.min.css';
import ReportModelBase from '../ReportModelBase';
import { Table } from 'semantic-ui-react';
const moment = require('moment');

class LoanPaymentSummaryReport extends ReportModelBase {

    constructor(props){
        super(props);
        this.searchUrl = "api/Reports/createLoanPaymentSummaryReport";
        this.title = "Loan Payment Summary Report";
        this.exportToExcelUrl = "api/Reports/ExportLoanPaymentSummaryReportToExcel";
        this.exportToExcelDataUrl = "api/Reports/createExportLoanPaymentSummaryReportToExcel";
        this.exportToPdfUrl = "api/Reports/createLoanPaymentSummaryReportToPDF";
    }

    toTableRow = (rowData) => {
        return (
            <Table.Row>
                <Table.Cell>{rowData.Company}</Table.Cell>
                <Table.Cell>{rowData.Department}</Table.Cell>
                <Table.Cell>{rowData["Employee No"]}</Table.Cell>
                <Table.Cell>{rowData.Employee}</Table.Cell>
                <Table.Cell>{rowData.LoanType}</Table.Cell>
                <Table.Cell>{rowData["Principal Amount"]}</Table.Cell>
                <Table.Cell>{rowData.Deduction}</Table.Cell>
                <Table.Cell>{rowData.Balance}</Table.Cell>
            </Table.Row>
        );
    }

    createColumnHeaders = () => {
        return (
            <Table.Row>
                <Table.HeaderCell>Company</Table.HeaderCell>
                <Table.HeaderCell>Department</Table.HeaderCell>
                <Table.HeaderCell>Emp No</Table.HeaderCell>
                <Table.HeaderCell>Name</Table.HeaderCell>
                <Table.HeaderCell>Loan Type</Table.HeaderCell>
                <Table.HeaderCell>Principal</Table.HeaderCell>
                <Table.HeaderCell>Deduction</Table.HeaderCell>
                <Table.HeaderCell>Balance</Table.HeaderCell>
            </Table.Row>
        );
    }

}

export default LoanPaymentSummaryReport;
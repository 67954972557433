import React, { Component } from 'react';
import { Image } from 'semantic-ui-react';
import 'semantic-ui-css/semantic.min.css';
import { isTaskAvailable, checkInt32 } from '../../utils';
import PayrollTransactionBaseWithDate from '../Commons/PayrollTransactionBaseWithDate';
import OffCyclePayForm from './Forms/OffCyclePayForm';

const moment = require('moment');

const ViewID = "OffCyclePay";
const ViewCaption = "OffCycle Pay";

const userRightsKeys = {
    view: "OFFCYCLE_PAY_VIEW",
    create: "OFFCYCLE_PAY_CREATE",
    edit: "OFFCYCLE_PAY_EDIT",
    delete: "OFFCYCLE_PAY_DELETE",
    //export: "OFFCYCLE_PAY_IMPORT_FROM_EXCEL",
    import: "OFFCYCLE_PAY_IMPORT_FROM_EXCEL",
    template: "OFFCYCLE_PAY_IMPORT_FROM_EXCEL",
}

class OffCyclePayList extends Component {

    constructor(props) {
        super(props);
        this.state = {
            modalVisible: false
        }
        this.modalRef = React.createRef();
        this.parentRef = React.createRef();
    }

    createUrls = () => {
        return {
            search: "api/OffCyclePay/search",
            setDeleted: "api/OffCyclePay/setDeleted",
            save: "api/OffCyclePay/save",
            load: "api/OffCyclePay/load",
            //exportToExcel: "api/OffCyclePay/exportToExcel",
            downloadTemplate: "api/OffCyclePay/downloadTemplate",
            importToExcel: "api/OffCyclePay/importOffyclePays",
        };
    }

    toCard = (model) => {
        return {
            _id: model._id,
            employee: model.Employee,
            type: model.OffCyclePayType,
            transactionType: model.TransactionType,
            month: moment(model.Month + '/1/' + model.Year).format("MMMM YYYY"),
            cutOffNumber: model.CutOffNumber,
            amount: model.Amount,
            remarks: model.Remarks,
            deleted: model.IsDeleted === null ? false : model.IsDeleted,
            editable: true,
            idle: false
        };
    };

    toModel = (card) => {
        return {
            _id: card.id,
            Employee: card.employee,
            OffCyclePayType: card.OffCyclePayType,
            TransactionType: card.transactionType,
            Amount: card.amount,
            Remarks: card.remarks,
        };
    };

    createFields = () => {
        // must have only one valueAs: 'header' and 'desciption',
        // 'content' can be none or many
        let fields = [
            { key: 'employee', name: 'Employee', valueAs: 'header' },
            { key: 'type', name: 'Type', valueAs: 'description' },
            { key: 'transactionType', name: 'Transaction Type', valueAs: 'content' },
            { key: 'month', name: 'Month', valueAs: 'content' },
            { key: 'cutOffNumber', name: 'CutOff Number', valueAs: 'content' },
            { key: 'amount', name: 'Amount', valueAs: 'content' },
            { key: 'remarks', name: 'Remarks', valueAs: 'content' },
            // { key: '', name: '', valueAs: 'content' },
        ];
        return fields;
    }

    validate = (data) => {
        var errTitle = "", errCaption = "";
        if (this.isNullOrEmpty(data.employeeIds)
            || this.isNullOrEmpty(data.OffCyclePayTypeID)
            || this.isNullOrEmpty(data.TransactionType)
            || this.isNullOrEmpty(data.Year)
            || this.isNullOrEmpty(data.Month)
            || this.isNullOrEmpty(data.CutOffNumber)
            || this.isNullOrEmpty(data.Amount)
            || this.isNullOrEmpty(data.Remarks)
        ) {
            errCaption = "Please fill up all field/s";
            errTitle = "Invalid";
        }
        else if (data.CutOffNumber < 1) {
            errCaption = "Invalid CutOff Number";
            errTitle = "Invalid";
        }
        else if (!checkInt32(data.Amount).Result) {
            errCaption = "Invalid Amount";
            errTitle = "Invalid";
        }
        else {
            this.parentRef.current.setState({ showRequiredField: false });
            return true;
        }
        this.parentRef.current.setState({ showRequiredField: true, errTitle, errCaption });
        return false;
    };

    showModal = (data, visible) => {
        visible && this.modalRef.current.setContent(data);
        this.setState({ modalVisible: visible });
    }

    isNullOrEmpty(val) {
        if (val === null || val === undefined || val === "" || val.length === 0) 
        {
            return true;
        }
        else 
        {
            return false;
        }
    }

    render() {
        let self = this;
        return (
            <PayrollTransactionBaseWithDate
                ref={this.parentRef}
                viewID={ViewID}
                title={ViewCaption}
                fields={this.createFields()}
                toModel={this.toModel}
                toCard={this.toCard}
                urls={this.createUrls()}
                validate={this.validate}
                props={this.props}
                showModal={this.showModal.bind(this)}
                userRightsKeys={userRightsKeys}
                hasExportToExcel={true}
            >
                <OffCyclePayForm
                    ref={self.modalRef}
                    open={self.state.modalVisible}
                    errTitle={self.parentRef.current ? self.parentRef.current.state.errTitle : ""}
                    errCaption={self.parentRef.current ? self.parentRef.current.state.errCaption : ""}
                    showRequiredField={self.parentRef.current ? self.parentRef.current.state.showRequiredField : ""}
                    isBusy={self.parentRef.current ? self.parentRef.current.state.isSaving : false}
                    onCancel={() => 
                        { 
                            self.setState({ modalVisible: false }, ()=>self.parentRef.current.setState({ showRequiredField: false })); 
                        }
                    }
                    onCreateEmpClick={() => {
                        this.setState({ modalVisible: false });
                        this.props.createEmpCallback();
                    }}
                    onSave={() => {
                        self.parentRef.current.saveFromModal(
                            self.modalRef.current.getContent()
                        );
                    }}
                />
            </PayrollTransactionBaseWithDate>
        );
    }
}

OffCyclePayList.getViewID = () => { return ViewID };
OffCyclePayList.getCaption = () => { return ViewCaption }
OffCyclePayList.getIcon = () => {
    // return (<Image style={{"height":"24px","width":"24px"}} src='Icons/customtransaction_small.png' avatar/>); 
    return (<Image style={{ "height": "24px", "width": "24px" }} src='/Icons/Off_Cycle_Pay.png' avatar />);
}
OffCyclePayList.getIconSrc = (size) => {
    // return 'Icons/tr_customtransaction_' + size + '.png' 
    return '/Icons/Off_Cycle_Pay.png'
}
OffCyclePayList.getViewAccessKey = function () {
    var count = Object.keys(userRightsKeys).filter(name => isTaskAvailable(userRightsKeys[name]) === true).length;
    return count > 0 ? true : false;
};
export default OffCyclePayList
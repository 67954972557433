import React from 'react';
import 'semantic-ui-css/semantic.min.css';
import ReportModelBase from './ReportModelBase';
import { Table } from '../../../node_modules/semantic-ui-react';
// const moment = require('moment');

class OvertimeDetailedReportModel extends ReportModelBase {

    constructor(props){
        super(props);
        this.searchUrl = "api/Reports/createDetailReport";
        this.title = "Overtime Detailed Report";
        this.exportToExcelUrl = "api/Reports/ExportDetailedReportToExcel";
        this.exportToExcelDataUrl = "api/Reports/createExportDetailedReportToExcel";
        this.exportToPdfUrl = "api/Reports/createExportDetailedReportToPDF";
    }

    toTableRow = (rowData) => {
        return (
            <Table.Row>
                <Table.Cell>{rowData.EmployeeNo}</Table.Cell>
                <Table.Cell>{rowData.Employee}</Table.Cell>
                <Table.Cell>{rowData.Date}</Table.Cell>
                <Table.Cell>{rowData.Total}</Table.Cell>
                {(
                    this.includeAmount &&
                    <Table.Cell>{rowData.Amount}</Table.Cell>
                )}
            </Table.Row>
        );
    }

    createColumnHeaders = () => {
        return (
            <Table.Row>
                <Table.HeaderCell>Employee No</Table.HeaderCell>
                <Table.HeaderCell>Employee</Table.HeaderCell>
                <Table.HeaderCell>Date</Table.HeaderCell>
                <Table.HeaderCell>Total</Table.HeaderCell>
                {(
                    this.includeAmount &&
                    <Table.HeaderCell>Amount</Table.HeaderCell>
                )}
            </Table.Row>
        );
    }

}

export default OvertimeDetailedReportModel;
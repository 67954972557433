import React, { Component } from 'react';
import { Button, Modal, Input } from 'semantic-ui-react';
import 'semantic-ui-css/semantic.min.css';

var moment = require('moment');


class DateRangePicker extends Component {
    dt = moment().format('YYYY-MM-DD');

    state = {
        startDate: this.dt,
        endDate: this.dt,
        busy: false
    }

    handleClose = () => {
        this.props.onClose(false);
    }

    handleAction = () => {
        this.setState({ busy: true });
        var fromDate = moment(this.state.startDate);
        var toDate = moment(this.state.endDate);
        this.props.onAction(fromDate, toDate);
    }
    render() {
        return (
            <Modal open size='tiny'>
                <Modal.Content>
                    <Input
                        size='small'
                        value={this.state.startDate}
                        label="From"
                        type="date"
                        onChange={(evt) => this.setState({ startDate: evt.target.value })} />
                    &nbsp;
                    <Input
                        size='small'
                        value={this.state.endDate}
                        label="To"
                        type="date"
                        onChange={(evt) => this.setState({ endDate: evt.target.value })} />
                </Modal.Content>
                <Modal.Actions>
                    <Button
                        basic
                        icon='check'
                        loading={this.state.busy}
                        content={this.props.action}
                        onClick={this.handleAction.bind(this)}>
                    </Button>
                    <Button basic icon='cancel' content='Cancel' onClick={this.handleClose}></Button>
                </Modal.Actions>
            </Modal>
        );
    }
}

export default DateRangePicker
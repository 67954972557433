import React, { Component } from 'react';
import { Table, TableHeader, TableRow, TableCell, Modal, TableBody, Button } from 'semantic-ui-react';

class SelectableRow extends Component {

    // https://stackoverflow.com/questions/48290915/ref-is-null-semantic-ui-react-table-row
    
    onClick = (e) => {
        const { onClick, rowId } = this.props;
        onClick(rowId, e);
    }
    
      render() {
        const { columns, data, active } = this.props;
        return (
          <Table.Row onClick={this.onClick} active={active}>
            {
                columns.map(col => { 
                return( <TableCell>{data[col.key]}</TableCell> )})
            } 
          </Table.Row>
        );
    }
}


class LeaveProfileDetailsTable extends Component {
    constructor(props){
        super(props);
        this.state = {
            selectedId: ''
        }
    }

    onRowClick = (rowId, e) => {
        let selectedId = this.state.selectedId !== rowId ? rowId : '';
        this.setState({ selectedId });
        // const { activeRows } = this.state;
        // const nextRows = {
        //   ...activeRows,
        //   [id]: !activeRows[id]
        // }
        // this.setState({ activeRows: nextRows });
    }

    loadTable(columns, details) {
        return(
            <Table celled selectable>
                <Table.Header>
                    <Table.Row>
                        { columns.map(col => { return( <Table.Cell>{col.name}</Table.Cell> )}) }
                    </Table.Row>
                </Table.Header>
                <Table.Body>
                {
                    details.map((data, index) => { return(
                        <SelectableRow
                            active={data.leaveTypeId === this.state.selectedId}
                            key={index}
                            rowId={data.leaveTypeId}
                            data={data}
                            columns={columns}
                            onClick={this.onRowClick.bind(this)}
                        />
                    )})
                }
                </Table.Body>
            </Table>
        );
    }

    render() {
        let detailsCol = this.props.detailsCol;
        let details = this.props.details;
        return(
            <div>
            {
                this.props.showAsModal ?
                <Modal size="tiny" open={this.props.open}>
                    <Modal.Header>Leave Profile Details</Modal.Header>
                    <Modal.Content>{this.loadTable(detailsCol, details)}</Modal.Content>
                    <Modal.Actions>
                        <Button basic icon='check' content='OK' onClick={this.props.onClick}></Button>
                    </Modal.Actions>
                </Modal> :
                <div>
                    {this.loadTable(detailsCol, details)}
                </div>
            }
            </div>
        );
    }
} 
export default LeaveProfileDetailsTable
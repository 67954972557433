import React, {Component } from 'react';
import { Image, Button } from 'semantic-ui-react';
import 'semantic-ui-css/semantic.min.css';
import { isTaskAvailable } from '../../../utils';
import PayrollTransactionBase from '../../../Payroll/Commons/PayrollTransactionBase';
import LeaveProfileForm from './LeaveProfileForm';
import LeaveProfileDetailsTable from './LeaveProfileDetailsTable';
import { ajaxPost } from '../../../ajax';

const ViewID = "LeaveProfiles";
const ViewCaption = "Leave Profile Management";

const userRightsKeys = {
	view: "LEAVE_PROFILE_VIEW",
	create: "LEAVE_PROFILE_CREATE",
	edit: "LEAVE_PROFILE_EDIT",
	delete: "LEAVE_PROFILE_DELETE",
}

class LeaveProfileList extends Component{

    constructor(props){
        super(props);
        this.state = {
            modalVisible: false,
            detailsTableVisible: false,
            selectedDetails: [],
            // detailsTableVisible
		}
		this.modalRef = React.createRef();
        this.parentRef = React.createRef();
        // this.detailsTable = React.createRef();
    }
    
    createUrls = () => {
        return {
            search: "api/LeaveProfile/search",
            setDeleted: "api/LeaveProfile/setDeleted",
            save: "api/LeaveProfile/save",
            load: "api/LeaveProfile/load",
			// exp  ortToExcel: "api/LeaveProfile/exportToExcel",
        };
    }

	toCard = (model) => {
		return {
            _id: model._id,
			description: model.Description,
			resetMonth: model.ResetMonth,
            resetDay: model.ResetDay,
            TenureMonth:model.TenureMonth,
			details: model.Details.length > 0 ? 
                        <Button 
                            style={{'margin': '15px 10px 0px 0px'}}
                            basic
                            taggedId={model._id}
                            size='mini'
                            content="View Details"
                            onClick={() => { 
                                this.showDetailsTable(true, model._id);
                                // this.setState({
                                //     selectedId: model._id, 
                                // },
                                //     () => { this.showDetailsTable(true); }
                                // );
                            }}
                        />
                        : "",
			deleted: model.IsDeleted === undefined ? false : model.IsDeleted,
			editable: true,
            idle: false            
		};
    };

	toModel = (card) => {
		return {
			_id: card.id,
            Description: card.description,
            ResetMonth: card.resetMonth,
            ResetDay : card.resetDay,
            TenureMonth: card.TenureMonth,
		};
	};

    showDetailsTable = (visible, selectedId = "") => {
        var self = this;
        if(this.isNullOrEmpty(selectedId)) {
            self.showDetailsTableCallback(visible);
        }
        else {
            this.setState({ selectedDetails: [] }, this.loadProfileDetails(selectedId, this.showDetailsTableCallback.bind(this, visible)) ); 
        }
    }

    showDetailsTableCallback = (visible) => {
        // if(this.modalRef.current !== null || this.parentRef.current !== null)
        // {
            var self = this;
            this.setState({ detailsTableVisible: visible }, 
                () => { 
                    var isVisible = self.state.detailsTableVisible;
                }
            );
        // }
        // else
        // {
        //     this.state.detailsTableVisible = visible;
        // }
    }

    createFields = () => {
        // must have only one valueAs: 'header' and 'desciption',
        // 'content' can be none or many
        let fields = [
			{ key: 'description', name: 'Description',  valueAs: 'header'},
            // { key: 'description', name: 'Description', valueAs: 'description' },
            { key: 'resetMonth', name: 'Reset Month', valueAs: 'content' },
            { key: 'resetDay', name: 'Reset Day', valueAs: 'content' },
            { key: 'details', name: '', valueAs: 'content' },
            // { key: '', name: '', valueAs: 'content' },
        ];
        return fields;
    }

    createDetailsFields = () => {
        let fields = [
			{ key: 'leaveType', name: 'Leave Type'},
            { key: 'accrued', name: 'Is Accrued' },
            { key: 'year', name: 'Year' },
            { key: 'grants', name: 'Grants' },
            { key: 'convertible', name: 'Convertible' },
            { key: 'carryOver', name: 'Carry Over' },
            { key: 'taxable', name: 'Non Taxable' },
        ];
        return fields;
    }

    createDetailsRow = (data, isFromAccruedData = false) => {
        return {
            leaveTypeId: data.LeaveTypeId,
			leaveType: data.LeaveType,
            accrued: data.Accrued ? "YES" : isFromAccruedData ? "" : "NO",
            year: isFromAccruedData ? data.Year : "-", 
            grants: data.Accrued && !isFromAccruedData ? "" : data.Grants,
            carryOver: data.Accrued && !isFromAccruedData ? "" : data.CarryOver,
            convertible: data.Accrued  && !isFromAccruedData ? "" : data.Convertible,
            taxable: data.Accrued  && !isFromAccruedData ? "" : data.Taxable,
        }
    }

    generateRowDetails = (selectedDetails) => {
        var details = [];
        selectedDetails.forEach(item => {
            if (item.Accrued) {
                let AccrualDetails = item.AccrualDetailString.split(',').map(
                    accrualDetail => {
                        let values = accrualDetail.split(':');
                        let Convertible = values[2] ? values[2] : 0;
                        let CarryOver = values[3] ? values[3] : 0;
                        let Taxable = values[4] ? values[4] : 0;
                        return { Year: values[0], Grants: values[1], Convertible, CarryOver, Taxable }
                    });
    
                AccrualDetails.forEach((accrualDetail, index) => {
                    let tmp = Object.assign({}, accrualDetail, index === 0 ? {LeaveType: item.LeaveType, Accrued: true} : {});
                    tmp = Object.assign({}, tmp, {LeaveTypeId: item.LeaveTypeId});
                    details.push(this.createDetailsRow(tmp, true));
                });
            }
            else {
                details.push(this.createDetailsRow(item));
            }
        });

        return details;
    }

    loadProfileDetails = (leaveProfileId, callback = () => {}) => {
        let self = this;
        var parameter = {
            _id: leaveProfileId,
        };
        var searchParameter = {
            data: parameter,
            url: this.createUrls().load, // url: "api/leaveprofile/loadprofiledetails",
            onError: (error) => {
            },
            onSuccess: (data, sender) => {
                self.setState({
                    selectedDetails: data.Details, 
                }, 
                    self.showDetailsTableCallback(true)
                );
            },
            finally: () => { }
        };
        ajaxPost(searchParameter);
    }

	validate = (data)  => {
		var errTitle = "", errCaption = "";
		if (this.isNullOrEmpty(data.Description)
            || this.isNullOrEmpty(data.ResetMonth)
            || this.isNullOrEmpty(data.ResetDay)
        ) {
            errTitle="Invalid"
            errCaption="Fill up all required fields."
        } else if(isNaN(data.TenureMonth) || data.TenureMonth.toString().includes(".")) {
            errTitle="Invalid"
            errCaption="Invalid value of tenure months."
        } else if(this.isNullOrEmpty(data.Details)) {
            errTitle="Invalid"
            errCaption="Please add details."
        }
		else{
			this.parentRef.current.setState({ showRequiredField: false });
			return true;
		}
        this.parentRef.current.setState({ showRequiredField: true, errTitle, errCaption });
		return false;
	};

	showModal = (data, visible) => {
		visible && this.modalRef.current.setContent(data);
		this.setState({ modalVisible: visible });
	}

	isNullOrEmpty(val) {
		if(val === null || val === undefined || val === "" || val.length === 0) return true;
		else return false;
    }
    
    render()
    {
        let self = this;
        return (
            // <div>
                <PayrollTransactionBase
                    ref={this.parentRef}
                    viewID={ViewID} 
                    title={ViewCaption}
                    fields={this.createFields()}
                    toModel={this.toModel}
                    toCard={this.toCard}
                    urls={this.createUrls()}
                    validate={this.validate}
                    props={this.props}    
                    showModal={this.showModal.bind(this)}
                    userRightsKeys = {userRightsKeys}
                    // hasExportToExcel={true}
                >

                <LeaveProfileForm
                    ref={self.modalRef}
                    open={self.state.modalVisible}
                    errTitle={self.parentRef.current ? self.parentRef.current.state.errTitle : ""}
                    errCaption={self.parentRef.current ? self.parentRef.current.state.errCaption : ""}
                    showRequiredField={self.parentRef.current ? self.parentRef.current.state.showRequiredField : ""}
                    isBusy={self.parentRef.current ? self.parentRef.current.state.isSaving : false}
                    onCancel={() => { 
                        self.setState({ modalVisible: false }, self.parentRef.current.setState({ showRequiredField: false }));
                     }}
                    onSave={() => { 
                        self.parentRef.current.saveFromModal(
                            self.modalRef.current.getContent()
                        );
                    }}
                    createDetailsFields={this.createDetailsFields}
                    createDetailsRow={this.createDetailsRow}
                    generateRowDetails={this.generateRowDetails}
                />
                {
                    self.state.detailsTableVisible &&
                    <LeaveProfileDetailsTable
                        // ref={self.detailsTable}
                        open={self.state.detailsTableVisible}
                        showAsModal={true}
                        detailsCol={this.createDetailsFields()}
                        // details={this.state.selectedDetails.map(x => this.createDetailsRow(x))}
                        details={this.generateRowDetails(this.state.selectedDetails)}
                        onClick={() => { this.showDetailsTable(false); }}
                    />
                }
                </PayrollTransactionBase>
            // </div>
        );
    }
}

LeaveProfileList.getViewID = () => { return ViewID };
LeaveProfileList.getCaption = () => { return ViewCaption }
LeaveProfileList.getIcon = () => { 
	// return (<Image style={{"height":"24px","width":"24px"}} src='Icons/customtransaction_small.png' avatar/>); 
    return (<Image style={{ "height": "24px", "width": "24px" }} src='/Icons/Leave_Profile_Management.png' avatar />);
}
LeaveProfileList.getIconSrc = (size) => { 
	// return 'Icons/tr_customtransaction_' + size + '.png' 
    return '/Icons/Leave_Profile_Management.png'
}
LeaveProfileList.getViewAccessKey = function() {
	var count = Object.keys(userRightsKeys).filter(name => isTaskAvailable(userRightsKeys[name]) === true ).length;
	return count > 0 ? true : false;
};
export default LeaveProfileList
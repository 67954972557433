import React from 'react';
import ReactDOM from 'react-dom';
import ErrorPage from './Dashboard/ErrorPage';

import './index.css';


import registerServiceWorker from './registerServiceWorker';

import 'semantic-ui-css/semantic.min.css';

import Navigate from './navigate'

const url = require('url');
const querystring = require('querystring');

var info = require('./buildInfo.json');

global.StorageAccountUrl = "http://127.0.0.1:10000/devstoreaccount1";
global.SignalRUrl = "https://pay5-signalr-test.service.signalr.net/client/?hub=notifications";
global.CommonUrl = "http://localhost:5000";

global.ApiHost = "http://localhost:5000/";
global.ApiHost2 = "http://localhost:5000";
global.logo_url = "/Images/zkpayrollLogo-dark.png";
global.light_logo_url = "/Images/zkteco_white.png";
global.CORSeal_url = "/Images/CORSeal.png";
global.IsDevelopment = true;

if (process.env.NODE_ENV === "production"){
    global.StorageAccountUrl = info.storageUrl;
	global.IsDevelopment = false;
    global.SignalRUrl = info.signalRUrl;
    global.CommonUrl = info.commonUrl;
}



const uri = url.parse(window.location.href);
const pathName = uri.pathname.toLowerCase();
const qs = querystring.parse(uri.query);

if( pathName.indexOf('/site/') === 0 )
{
    const coyId = pathName.substring( pathName.indexOf('/site/') + 6);

    window.fetch(`${global.CommonUrl}/api/setting/validatecompany?company=${coyId}`,
    {
        headers: {
            'Accept': 'application/json, text/plain, */*',
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${localStorage.getItem("Token")}`
        },
        method: 'get'
    }).then( response => {
        return response.json()
    }).then( result => {
        if( result === true ){
            localStorage.setItem('company_id', coyId);
            window.location.href = "/";
        }
        else{
            window.location.href = "/error?id=unknown_company";
        }
    })


}
else if( pathName == "/error")
{
    ReactDOM.render(<ErrorPage id={qs.id} />, document.getElementById('root'));
}
else
{
    var coyId = localStorage.getItem('company_id');

    if( process.env.NODE_ENV !== "production"){
        coyId = "devsite";
    }

    if( coyId === undefined || coyId == '' || coyId == null  )
    {
        window.location.href = "/error?id=unknown_company";
    }
    else
    {
        global.CompanyId = coyId;
        if( !global.IsDevelopment)
        {
            global.ApiHost = `https://${coyId}.zkpayroll.com/`;
            global.ApiHost2 = `https://${coyId}.zkpayroll.com`;
        }
        Navigate();
    }
}

//registerServiceWorker();

import React, { Component } from 'react';
import { Image } from 'semantic-ui-react';
import PayrollLoanList from './PayrollLoanList';
import PayrollLoanForm from './PayrollLoanForm';
import { isTaskAvailable } from '../../utils';

class PayrollLoanModule extends Component {

    constructor(props) {
        super(props);
        this.onUpdateComplete = this.onUpdateComplete.bind(this);
    }

    state = {
        mode: "search",
        searchState: {
            initialLoad: true,
            selectedItem: null,
            list: [],
            search: '',
            Parameters: {
                EmployeeIds: [],
                LoanTypeId: "",
                FromDate: "",
                ToDate: "",
                page: 1,
                rowPerPage: 10,
                totalPages: 1,
                Status : ""
            },
            messageBoxOkOnly: {
                show: false,
                title: "",
                caption: "",
            },
            total: 0,
        },
        selectedItem: null
    }
    componentWillMount() {
        if (this.props.prevState != null) {
            this.state = this.props.prevState;
            this.state.searchState.initialLoad = false;
        }

    }
    componentWillUnmount = () => {
        this.props.unmountCallback(this.state, "PayrollLoans");
    }
    onUpdateState = (searchState) => {
        this.setState({ searchState: searchState });
    }
    onSelect = (item) => {
        this.setState({ selectedItem: item });
    }
    onAdd = (searchState) => {
        //set blank model for selectedItem
        this.setState({ selectedItem: null });
        this.setState({ searchState });
        this.setState({ mode: "add" });
    }
    onEdit = (searchState, selectedItem) => {
        this.setState({ searchState: searchState });
        this.setState({ selectedItem: selectedItem });
        this.setState({ mode: "edit" });
    }
    onCloseForm = (schedule) => {
        this.setState({ mode: "search" });
    }
    onUpdateComplete = (models) => {
        let searchState = this.state.searchState;
        let list = searchState.list;
        let selectedItem = this.state.selectedItem;

        models.forEach(model => {
            if (this.state.mode === "add") {
                if (list) {
                    list.push(model);
                }
                else {
                    list = [model];
                }
                searchState.selectedItem = model;
                searchState.page = 1;
            }
            else {
                // not supported cause by ui display
                let loanDetailList = [];
                list.map(function (v) {
                    let detail = [];
                    v.LoanDetails.map(x => {
                        detail.push(x);
                    });
                    var itemIndex = detail.indexOf(selectedItem);
                    if (itemIndex > -1) {
                        v.LoanDetails[itemIndex] = model.LoanDetails[0];
                    }
                });
                // searchState.list = list;
            }
        }
        );

        searchState.list = list;
        this.setState({ mode: "search", searchState });
    }

    render() {
        return this.state.mode === "search" ?
            <PayrollLoanList
                searchState={this.state.searchState}
                onSelect={this.onSelect.bind(this)}
                onAdd={this.onAdd.bind(this)}
                onEdit={this.onEdit.bind(this)}
                onUpdateState={this.onUpdateState.bind(this)} />
            :
            <PayrollLoanForm selectedItem={JSON.parse(JSON.stringify(this.state.selectedItem))}
                onCloseForm={this.onCloseForm.bind(this)}
                onUpdateComplete={this.onUpdateComplete.bind(this)}
                onCreateEmpClick={() => {
                    this.setState({ modalVisible: false });
                    this.props.createEmpCallback();
                }} />
    }
}
PayrollLoanModule.getViewID = () => { return "PayrollLoans" };
PayrollLoanModule.getCaption = () => { return "Payroll Loan" }
PayrollLoanModule.getViewAccessKey = () => {
    return isTaskAvailable("PAYROLL_LOAN_VIEW") || isTaskAvailable("PAYROLL_LOAN_CREATE")
        || isTaskAvailable("PAYROLL_LOAN_MODIFY") || isTaskAvailable("PAYROLL_LOAN_DELETE")
        ? true : false
}
PayrollLoanModule.getIcon = () => {
    return (<Image style={{ "height": "24px", "width": "24px" }} src='/Icons/Payroll_Loan.png' avatar />);
}
PayrollLoanModule.getIconSrc = (size) => //size values: small (50x50), medium(150x150), large(300x300)
{
    return '/Icons/Payroll_Loan.png'
}
export default PayrollLoanModule
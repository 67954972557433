import React, { Component, createRef } from 'react';
import 'semantic-ui-css/semantic.min.css';
import { Button, Menu, Loader, Input, Icon, Card, Image, Visibility, Modal, Checkbox, Dropdown, Popup, Message, Table, TableRow, TableCell } from 'semantic-ui-react';
import { ajaxPost, authGet, authPost } from '../../ajax';

import { customStyles, isTaskAvailable } from '../../utils';
import ViewNotificationDetails from '../../TA/Transactions/Commons/ViewNotificationDetails';
import EmployeeLoad from './EmployeeLoad';
import Select, { SelectBase } from 'react-select';
import MessageBoxOkOnly from '../../Commons/MessageBoxOkOnly';
import EmployeeFilterModal from '../../Commons/EmployeeFilterModal';
import PDFE201 from './PDFE201';
import EmployeeImage from '../../Commons/EmployeeImage';

import { parse as uuidParse } from 'uuid';

require('dotenv').config({ path: './.env' });
const fileDialog = require('file-dialog');
const moment = require('moment');

const batchCount = 30;

var { fnSearchEmployee,getUnique, loadGroups, fnSearchEmployeeFilter, empty_id, checkDate, fullName, ajaxPostDownload } = require('../../utils');

// const deptOptions = [
//     {label: 'Departments', value: 'all' },
//     {label: 'Development Operations', value: 'devOps' },
//     {label: 'Custumer Service Representative', value: 'csr' },
//   ]

function empSearchList(empList) {
    var list = [];
    if (empList !== undefined) {
        empList.map(x => {
            var fullname = x.LastName + ', ' + x.FirstName + ' ' + (x.MiddleName === "" ? "" : x.MiddleName[0]);

            if (fullname.length > 45)
                fullname = fullname.slice(0, 45) + "..." + " (" + x.EmployeeNo + ")";
            else
                fullname = fullname + " (" + x.EmployeeNo + ")";

            return (
                list.push({ label: fullname, value: x._id }))
        })
    }
    return list;
}
function calculateAge(birthday) {
    if (birthday === "")
        return "";
    var today = new Date();
    var birthDate = new Date(birthday);
    var age = today.getFullYear() - birthDate.getFullYear();
    var m = today.getMonth() - birthDate.getMonth();
    if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
        age--;
    }
    return age;
}


const toDataURL = url => fetch(url)
    .then(response => response.blob())
    .then(blob => new Promise((resolve, reject) => {
        const reader = new FileReader()
        reader.onloadend = () => resolve(reader.result)
        reader.onerror = reject
        reader.readAsDataURL(blob)
    }))

const separationTypeList = [
    { text: 'Resignation', value: 'RESIGNATION' },
    { text: 'Termination', value: 'TERMINATION' },
    { text: 'Retirement', value: 'RETIREMENT' }
];

var isSubscribed = "";

class EmployeeList extends Component {

    state = {
        isFirstLoad: true,
        isLoading: false,
        importLoading: false,
        exportTemplateLoading: false,
        exportLoading: false,
        isSearchLoading: false,
        action: "List",
        search: "",
        searchBoxPick: [],
        //selectedEmployee: [],
        //selectedZKUserInfo: [],
        //selectedPayrollInfo: [],
        employeeList: [],
        eligibilityList: [],
        empSearchList: [],
        empCount: 0,
        selectAll: 0,
        open: false,
        isSuperAdmin: false,
        userId: "",
        prevState: undefined,
        subordinateIds: [],
        listViewState: true,
        includeInactive: false,
        inactiveOnly: false, // inactiveOnly

        isPerformingAction: false,
        idPerformingAction: "",
        actionPerformed: "",

        deptSelected: "",
        deptOptions: [],

        userCanAdd: false,
        userCanEdit: false,
        userCanDelete: false,

        dtInputEmployee: "",
        reasonForLeaving: "",
        separationType: separationTypeList[0].value,
        advanceFilter: {
            employeeIds: [],
            companyIds: [],
            divisionIds: [],
            departmentIds: [],
            locationIds: [],
            costcenterIds: [],
            positionIds: [],
        },
        hasAdvanceFilter: false,

        isRestoring: false,
        isDeleting: false,
        isError: false,

        //isMaximumScrollReach: false,
        //isMinimumScrollReach: false,
        //maxIndex: 0,
        //minIndex: 0,
        listIndex: 0,
        isMaxEmployeeCapacity: false,
        maxEmpCount: 0,
        referer: 'EmployeeList',
        noDataFields: [],
        deleteEnrollNotice: false,
        reachedEnd: false
    }

    constructor() {
        super();


        isSubscribed = process.env.REACT_APP_CLIENT_SUBSCRIBED;
        this.actualSearch = this.actualSearch.bind(this);
    }

    handleContextRef = (contextRef) => this.setState({ contextRef })

    componentDidMount = () => {
        if (this.state.empCount == 0){
            this.doSearch("");
        }
        if (this.state.isFirstLoad) {
            this.loadSearchEmployee("");
            this.loadDepartments();
            this.setState({ isFirstLoad: false });
            this.getMaxEmployeeCapacity();
            if (isSubscribed !== 'YES') {
                this.getProdKeyInfo();
            }
        }
        this.setState({ openPopUp: false });
    }

    componentWillMount = () => {
        //check for previous state
        if (this.props.prevState !== undefined) {
            this.state = this.props.prevState;
        }

        // if (this.props.prevState !== undefined && this.props.prevState.action === "List") {
        //     this.setState({employeeList: []}, ()=> this.doSearch(""));
        // }
    }

    componentWillUpdate(newProps, newState) {
        var abc = 0;
    }

    componentWillUnmount = () => {
        this.props.unmountCallback(this.state, "EmployeeModule");

    }
    getProdKeyInfo = () => {
        ajaxPost({
            url: "api/License/getProductKeyInfo",
            data: {},
            onSuccess: (data) => {
                if (data != null) {
                    this.setState({ maxEmpCount: data["EmpCount"] });
                } else {
                    this.setState({ maxEmpCount: 10 });
                }
            },
            finally: () => { },
        });
    };
    getMaxEmployeeCapacity = () => {
        if (isSubscribed === 'YES') {
            return;
        }
        ajaxPost({
            url: "api/License/maxEmployeeCapacity",
            data: {},
            onSuccess: (data) => {
                this.setState({ isMaxEmployeeCapacity: data });
            },
            finally: () => {
            },
        });
    };
    loadDepartments() {
        loadGroups("", ["DEPARTMENT"],
            (data) => {
                var newList = [];
                data.content.forEach(item => {
                    newList.push({ label: item["Name"], value: item["_id"] });
                });
                this.setState({ deptOptions: newList })
            })
    }

    confirmDelete = () => {

        this.setState({ isDeleting: true })
        var { includeDeleted } = this.state;

        ajaxPost({
            url: "api/Employee/delete",
            data: {
                "employeeID": this.state.selectedEmployee._id,
                "deleted": true,
                "dateInput": this.state.dtInputEmployee,
                "reason": this.state.reasonForLeaving,
                "separationType": this.state.separationType
            },
            onSuccess: data => {
                var empList = this.state.employeeList;
                if (includeDeleted)
                    empList[empList.map(emp => emp._id).indexOf(this.state.selectedEmployee._id)] = data.content;
                else
                    empList.splice(empList.map(emp => emp._id).indexOf(this.state.selectedEmployee._id), 1);

                this.getMaxEmployeeCapacity();
                this.setState({
                    deleteEnrollNotice: false,
                    open: false,
                    employeeList: empList,
                    dtInputEmployee: "",
                    reasonForLeaving: "",
                    separationType: separationTypeList[0].value,
                    openPopUp: true,
                    popUpTitle: "Employees",
                    popUpContent: "Employee has been successfully set as inactive",
                    isDeleting: false,
                })
            },
            finally: () => {
                //this.doSearch("");
            }
        })
    }

    restoreClick = (employee) => {
        if (this.state.dtInputEmployee === "") {
            alert("All fields are required")
            return;
        }
        if (!checkDate(this.state.dtInputEmployee).Result) {
            this.setState({ isError: true });
            return;
        }

        this.setState({ isRestoring: true })

        ajaxPost({
            url: "api/Employee/delete",
            data: {
                "employeeID": employee._id,
                "deleted": false,
                "dateInput": this.state.dtInputEmployee,
                "reason": this.state.reasonForLeaving,
                "separationType": this.state.separationType
            },
            onSuccess: data => {
                var empList = this.state.employeeList;
                empList[empList.map(emp => emp._id).indexOf(employee._id)] = data.content;
                this.getMaxEmployeeCapacity();
                //empList.splice(empList.map(emp => emp._id).indexOf(employee._id), 1);
                this.setState({
                    openRestore: false,
                    employeeList: empList,
                    dtInputEmployee: "",
                    reasonForLeaving: "",
                    separationType: separationTypeList[0].value,
                    openPopUp: true,
                    popUpTitle: "Employees",
                    popUpContent: "Employee has been successfully set as active",
                    isRestoring: false,
                })

            },
            finally: () => {
                //this.doSearch("");
            }
        })
    }

    deleteClick = (employee) => {
        if (this.state.dtInputEmployee === "" || this.state.reasonForLeaving.trim() === "") {
            this.setState({ isError: true });
            return;
        }
        if (!checkDate(this.state.dtInputEmployee).Result) {
            this.setState({ isError: true });
            return;
        }

        this.setState({ deleteEnrollNotice: true });
    }

    onSearch = text => {
        clearTimeout(this.searchTimer);
        this.setState({ search: text });
        this.searchTimer = setTimeout(this.loadSearchEmployee, 400, text);
    }

    loadSearchEmployee = (str) => {
        const { empSearchList, searchBoxPick, hasAdvanceFilter } = this.state;

        this.setState({ isSearchLoading: true });
        var ids = (searchBoxPick === "" || searchBoxPick === undefined || searchBoxPick.length === 0) ? [] : searchBoxPick.map(x => x.value);
        var selectedEmpIds = empSearchList === undefined || empSearchList === null ? [] : empSearchList.filter(x => ids.includes(x._id));
        if (hasAdvanceFilter)
            this.onAdvanceEmployeeSearchFilter(0, selectedEmpIds.map(x => x._id), this.actualSearchEmployee.bind(this))
        else
            this.actualSearchEmployee(str, 0, selectedEmpIds.map(x => x._id), null);

    }

    actualSearchEmployee(str, startIndex, excludedIds, includedEmployee) {
        var { empSearchList } = this.state;
        var excludedEmployee = [];
        if (excludedIds != null)
            excludedEmployee = empSearchList.filter(x => excludedIds.includes(x._id));

        fnSearchEmployeeFilter(str, data => {
            if (excludedIds != null)
                excludedEmployee.forEach(x => { if (!data.map(y => y._id).includes(x._id)) data.push(x) })

            this.setState({
                isSearchLoading: false,
                isLoading: false,
                empSearchList: data,
                search: "",
            })
        }, startIndex, 20, this.state.includeInactive , excludedIds, includedEmployee != null ? includedEmployee.map(x => x._id) : null, this.state.inactiveOnly)
    }

    onClickSearch = (event) => {
        if (this.state.searchBoxPick !== undefined && this.state.searchBoxPick.length === 1 && this.state.searchBoxPick[0] === empty_id)
            this.setState({ reachedEnd:false, searchBoxPick: undefined, listIndex: 0, employeeList: [] }, () => this.doSearch(""))
        else
            this.setState({ reachedEnd: false, listIndex: 0, employeeList: [] }, () => this.doSearch(""));
    }

    doSearch = (str) => {
        var searchBoxPick = this.state.searchBoxPick;

        var startIndex = this.state.listIndex;
        var ids = (searchBoxPick === "" || searchBoxPick === undefined || searchBoxPick.length == 0) ? null : searchBoxPick.map(x => x.value);

        this.setState({ isLoading: true }, () => {
            if (this.state.hasAdvanceFilter) {
                this.onAdvanceEmployeeFilter(startIndex, ids, this.actualSearch);
            }
            else {
                this.actualSearch(str, startIndex, ids);
            }
        })

    }

    actualSearch(str, startIndex, ids, totalCount) {
        const { employeeList } = this.state;

        fnSearchEmployee(str, data => {
            if( data.content.length > 0)
            {
                let empList = [...employeeList, ...data.content]; 
                empList = getUnique(empList,'_id');
                this.setState({
                    userId: data["userId"],
                    employeeList: empList,
                    empCount: totalCount !== undefined ? totalCount : data.count,
                    isLoading: false,
                    listIndex: this.state.listIndex + batchCount
                }, () => {
    
                 var superAdminId = empty_id;
                    var canAdd = isTaskAvailable("EMPLOYEE_ADD") || data["userId"] === superAdminId;
                    var canEdit = isTaskAvailable("EMPLOYEE_EDIT") || data["userId"] === superAdminId;
                    var canDelete = isTaskAvailable("EMPLOYEE_DELETE");
                    var canExport = isTaskAvailable("EMPLOYEE_EXPORT_TO_EXCEL");
                    var canImport = isTaskAvailable("EMPLOYEE_IMPORT_TEMPLATE");
                    var canDownload = isTaskAvailable("EMPLOYEE_DOWNLOAD_TEMPLATE");
                    var viewAllEmployee = isTaskAvailable("VIEW_ALL_EMPLOYEES");
                    this.setState({
                        userCanAdd: canAdd,
                        userCanEdit: canEdit,
                        userCanDelete: canDelete,
                        userCanExport : canExport,
                        userCanImport : canImport,
                        userCanDownload : canDownload,
                        userViewAllEmployee : viewAllEmployee,
                        isSuperAdmin: data["userId"] === superAdminId,
                    });
                });
    
            }
            else
            {
                this.setState({isLoading:false, reachedEnd:true})
            }


        }, startIndex, batchCount, this.state.includeInactive, ids, this.state.inactiveOnly)
    }

    onChkInactiveCheckChange = (e) => {
        this.setState({
            inactiveOnly: !this.state.inactiveOnly, 
            includeInactive: false, 
            employeeList: [],
            reachedEnd: false,
            listIndex:0
        }, function () { this.doSearch(this.state.search) })
    }


    onChkCheckChange = (e) => {
        this.setState({
            includeInactive: !this.state.includeInactive,
            employeeList: [],
            reachedEnd: false,
            listIndex:0
        }, function () { this.doSearch(this.state.search) })
    }

    onBadgeClick(emp) {
        if (!this.state.isPerformingAction) {
            this.setState({
                isPerformingAction: true,
                idPerformingAction: emp["_id"],
                actionPerformed: "BADGE"
            });

            emp.Badge = !emp.Badge;
            var arrEmployee = this.state.employeeList;
            var index = undefined;
            index = arrEmployee.map(emp => emp._id).indexOf(emp._id);

            delete emp["FullName"];

            ajaxPost({
                url: "api/Employee/save",
                data: {
                    "employee": emp
                },
                onSuccess: data => {
                    emp = data.content;
                    arrEmployee[index] = emp;
                },
                onError: data => {
                    alert("There's an error in saving data.")
                },
                finally: () => {
                    this.setState({
                        isPerformingAction: false,
                        employeeList: arrEmployee,
                        idPerformingAction: "",
                        actionPerformed: ""
                    });
                }
            });
        }
        else {
            alert("Please wait other actions to be perform.")
        }
    }

    onResetPasswordClick(emp) {
        if (!this.state.isPerformingAction) {
            this.setState({
                selectedEmployee: emp,
                isPerformingAction: true,
                idPerformingAction: emp["_id"],
                actionPerformed: "Reset Password",
                modalResetPasswordOpen: true,
                openPopUp: false,
            });
        } else {
            alert("Please wait other actions to be perform.")
        }
    }

    onResetPasswordModalClose() {
        this.setState({ isPerformingAction: false, selectedEmployee: [] })
        this.close();
    }

    async resetPassword() {
        var resp = await authGet(`/Employee/resetPassword?id=${this.state.selectedEmployee._id}`);
        var password = await resp.text();

        this.setState({ 
            openPopUp: true,
            popUpTitle: "Reset Password", 
            popUpContent: "New Password: " + 
            password, 
            selectedEmployee: [],
            modalResetPasswordOpen: false,
            isPerformingAction: false
         });
    }

    onCreateNewEmpClick() {
        if (!this.state.isPerformingAction) {
            this.setState({ action: "Load" });
        }
        else {
            alert("Please wait other actions to be perform.")
        }

    }

    onEditClick(emp) {
        if (!this.state.isPerformingAction) {
            this.setState({
                action: "Load",
                selectedEmployee: emp,
            });
        }
        else {
            alert("Please wait other actions to be perform.")
        }
    }

    onViewClick(isListViewState) {
        this.setState({ listViewState: isListViewState });
    }

    onViewFilterClick(isOpen) {
        this.setState({ isFilterActive: isOpen });
    }

    onEmployeeSelect = (value) => {
        var ids = [];
        if (value != null && !(value === "" || value.length === 0))
            ids = value.map(x => x.value);

        var { hasAdvanceFilter } = this.state;
        this.setState({ searchBoxPick: value })

        if (hasAdvanceFilter)
            this.onAdvanceEmployeeSearchFilter(0, ids, this.actualSearchEmployee.bind(this), true)
        else
            this.actualSearchEmployee("", 0, ids, null);

        // var maxData = ids === null ? 12 : 9999;
        // fnSearchEmployee("", data => {
        //     this.setState({
        //         employeeList: data["content"],
        //         empCount: data["count"],
        //         isLoading: false,
        //         search: ""
        //     }, this.loadSearchEmployee(""))
        // }, 0, maxData, this.state.includeInactive, ids)
    }

    onAdvanceEmployeeSearchFilter(startIndex, excludedIds, callback) {
        var { advanceFilter } = this.state;
        var totalCount = 0;
        var employees = [];
        ajaxPost({
            url: "api/Employee/searchFilteredEmployee",
            data: {
                "Search": "",
                "includeDeleted": this.state.includeInactive,
                "inactiveOnly": this.state.inactiveOnly,
                // "includeInactive": this.state.includeInactive, // includeInactive
                "startingIndex": startIndex,
                "itemCount": batchCount,
                "excludedEmployeeIds": excludedIds,
                "CompanyIDs": advanceFilter.companyIds,
                "DivisionIDs": advanceFilter.divisionIds,
                "DepartmentIDs": advanceFilter.departmentIds,
                "PositionIDs": advanceFilter.positionIds,
                "CityIDs": advanceFilter.locationIds,
                "CostCenterIDs": advanceFilter.costcenterIds,
                
            },
            onSuccess: data => {
                totalCount = data.count;
                employees = data.content;

                if (employees.length === 0)
                    employees.push({ "_id": empty_id });
            },
            finally: () => {
                callback("", 0, excludedIds, employees);
            }
        })
    }

    onAdvanceEmployeeFilter(startIndex, includedEmpIds, callback) {
        var { advanceFilter } = this.state;
        var totalCount = 0;
        var employees = [];
        ajaxPost({
            url: "api/Employee/searchFilteredEmployee",
            data: {
                "Search": "",
                "includeDeleted": this.state.includeInactive,
                "inactiveOnly": this.state.inactiveOnly,
                // "includeInactive": this.state.includeInactive, // includeInactive
                "startingIndex": startIndex,
                "itemCount": batchCount,
                "employeeIds": includedEmpIds,
                "CompanyIDs": advanceFilter.companyIds,
                "DivisionIDs": advanceFilter.divisionIds,
                "DepartmentIDs": advanceFilter.departmentIds,
                "PositionIDs": advanceFilter.positionIds,
                "CityIDs": advanceFilter.locationIds,
                "CostCenterIDs": advanceFilter.costcenterIds,
            },
            onSuccess: data => {
                totalCount = data.count;
                employees = data.content.map(x => x._id)

                if (employees.length === 0)
                    employees.push(empty_id)
            },
            finally: () => {
                callback("", 0, employees, totalCount);
            }
        })
    }

    returnData(selectedEmployee, selectedZKUserInfo, selectedPayrollInfo, action, objState) {
        this.state = objState;

        if (action === "Dashboard") {
            if (this.state.showPopUp) {
                this.setState({
                    external: <MessageBoxOkOnly
                        onClick={() => this.setState({ external: null })}
                        title={this.state.popUpTitle}
                        caption={this.state.popUpContent}
                    />
                })
            }

            this.setState({ selectedModule: null });
            this.setState({ loadEmp: false });
        } if (action === 'List' || action === 'Load') {
            this.setState({
                employeeList: objState.employeeList,
                empSearchList: objState.empSearchList,
                searchBoxPick: objState.searchBoxPick,
                selectedEmployee: selectedEmployee,
                includeInactive: objState.includeInactive,
                eligibilityList: [],
                action: action,
                listViewState: objState.listViewState,
                isFirstLoad: objState.isFirstLoad,
                empCount: objState.empCount,
                userId: objState.userId,
                isSuperAdmin: objState.isSuperAdmin,
                popUpTitle: objState.popUpTitle,
                popUpContent: objState.popUpContent,
                openPopUp: objState.showPopUp,
                activePage: objState.activePage,
                advanceFilter: objState.advanceFilter,
                userCanAdd: objState.userCanAdd,
                userCanDelete: objState.userCanDelete,
                userCanEdit: objState.userCanEdit,
                userCanExport : objState.userCanExport,
                userCanImport : objState.userCanImport,
                userCanDownload : objState.userCanDownload,
                userViewAllEmployee : objState.userViewAllEmployee,
                isRestoring: objState.isRestoring,
                isLoading: objState.isLoading,
                prevState: undefined
            });
        }
        else {
            this.setState({ selectedModule: null });
            this.setState({ loadEmp: false });
            this.props.moduleSelectCallback(action);
        }
    }

    show = (size, selectedEmployee) => {
        this.setState({ size, selectedEmployee, open: true });
    }
    close = () => this.setState({ open: false, modalResetPasswordOpen: false, openRestore: false, deleteEnrollNotice: false, isPerformingAction: false, reasonForLeaving: "", separationType: separationTypeList[0].value, dtInputEmployee: "", selectedEmployee: [] })
    showRestore = (selectedEmployee) => () => this.setState({ selectedEmployee, openRestore: true })
    advanceFilterCallBack = (advanceFilter, includeDeleted, inactiveOnly) => {
        var hasAdvFilter = true;
        if ((advanceFilter.companyIds === undefined || advanceFilter.companyIds === null || advanceFilter.companyIds.length === 0) &&
            (advanceFilter.departmentIds === undefined || advanceFilter.departmentIds === null || advanceFilter.departmentIds.length === 0) &&
            (advanceFilter.divisionIds === undefined || advanceFilter.divisionIds === null || advanceFilter.divisionIds.length === 0) &&
            (advanceFilter.locationIds === undefined || advanceFilter.locationIds === null || advanceFilter.locationIds.length === 0) &&
            (advanceFilter.costcenterIds === undefined || advanceFilter.costcenterIds === null || advanceFilter.costcenterIds.length === 0) &&
            (advanceFilter.positionIds === undefined || advanceFilter.positionIds === null || advanceFilter.positionIds.length === 0)) 
            hasAdvFilter = false

        this.setState({
            employeeList: [],
            advanceFilter: advanceFilter,
            hasAdvanceFilter: hasAdvFilter,
            isFilterActive: false,
            includeInactive: includeDeleted,
            inactiveOnly: inactiveOnly, // InActive Only
            empSearchList: [],
            listIndex: 0
        }, () => {
            // this.onClickSearch(null); 
             this.loadSearchEmployee("");
             this.doSearch("");
        })
    }


    onDownloadTemplateClick = () => {
        if (this.state.exportTemplateLoading)
            return;
        this.setState({ exportTemplateLoading: true })
        ajaxPostDownload(
            "api/Employee/downloadTemplate",
            this.state.advanceFilter,
            "Employee Template.xlsx",
            (data, sender) => {
                this.setState({ exportTemplateLoading: false });
            },
            (error) => {
                alert("Error:\r\n" + JSON.stringify(error));
            },
            () => {
                // alert("@finally");
                this.setState({ exportTemplateLoading: false });
            }

        )
    }

    onExportClick = () => {
        if (this.state.exportLoading)
            return;
        this.setState({ exportLoading: true });
        var empIDs = this.state.searchBoxPick.length > 0 ? this.state.employeeList.map(x => x._id) : "";
        var isViewAllEmployee = this.state.userViewAllEmployee;
        var { advanceFilter } = this.state;
        ajaxPostDownload(
            "api/Employee/exportEmployee",
            {
                "employeeIds": empIDs,
                "includeDeleted": this.state.includeInactive,
                "inactiveOnly": this.state.inactiveOnly,
                "userId" : this.state.userId,
                "isViewAllEmployee" : isViewAllEmployee,
                "CompanyIDs": advanceFilter.companyIds,
                "DivisionIDs": advanceFilter.divisionIds,
                "DepartmentIDs": advanceFilter.departmentIds,
                "PositionIDs": advanceFilter.positionIds,
                "CityIDs": advanceFilter.locationIds,
                "CostCenterIDs": advanceFilter.costcenterIds,
            },
            "Employee Template.xlsx",
            (data, sender) => {
                this.setState({ exportLoading: false });
            },
            (error) => {
                alert("Error:\r\n" + JSON.stringify(error));
            },
            () => {
                // alert("@finally");
                this.setState({ exportLoading: false });
            }

        )
    }

    onImportClick = () => {
        if (this.state.importLoading)
            return;

        fileDialog().then(file => {
            const data = new FormData();
            data.append('file', file[0]);
            data.append('RequestClass', JSON.stringify({
                SessionKey: 'HRIS_SessionId',
                SessionId: global.sessionId
            }
            )
            );
            this.setState({ importLoading: true });
            fetch(global.ApiHost + 'api/Employee/importEmployees', {
                method: 'POST',
                credentials: 'include',
                body: data
            }).then((resp) => {
                this.setState({ importLoading: false });
                return resp.json();
            }).then((json) => {
                var data = JSON.parse(json.JsonData);
                var hasErrors = data.hasOwnProperty("errors") && data.errors.length > 0;
                // TODO alert(msg);
                this.setState({
                    openPopUp: true,
                    popUpTitle: "Import Employees",
                    popUpContent:
                        <div>
                            <p>{data.content}</p>
                            {data.errors.map(err => { return (<span>{err}<br /></span>) })}
                        </div>
                    // (!hasErrors ? data.content : data.errors.map(err => { return(<span>{err}<br /></span>) })),
                })
                this.setState({ importLoading: false });
            })
        });
    }

    printE201 = (empID) => {
        ajaxPost({
            url: "api/Employee/GetResultmployeeE201",
            data: {
                "EmployeeID": empID,
            },
            onSuccess: data => {
                this.processPdfData(data);
            },
            finally: () => {
            }
        })
    }

    processPdfData = (pdfData) => {
        toDataURL(global.ApiHost + "api/Employee/employeeImage?empID=" + pdfData.Employee._id)
            .then(dataUrl => {
                pdfData.Employee.ImageURI = dataUrl
                PDFE201(pdfData);
            })
    }

    visibilityUpdate = () => {

        if (this.state.isLoading || this.state.isFilterActive) {
            return;
        }

        this.setState({isLoading:true}, ()=>{
            this.doSearch(this.state.search)
        });

    }
    /*
        onBottomVisible = () => {
            if ((this.state.maxIndex) * 12 <= this.state.empCount) {
                this.setState({ isMaximumScrollReach: true })
            }
        }
    
        onTopVisible = () => {
            if (this.state.minIndex !== 0 && (this.state.employeeList.length > 24 || this.state.isMaximumScrollReach)) {
                this.setState({ isMinimumScrollReach: true });
            }
        }
    */
    onSeparationTypeChange = (e, data) => {
        this.setState({ separationType: data.value });
    }

    render() {
        const { open, openRestore, size, activePage, searchBoxPick } = this.state
        return (
            <>
                {this.state.action === "Load" &&
                    <EmployeeLoad params={this.state} callback={this} unmountCallback={this.props.unmountCallback} prevState={this.state.prevState} />
                }
                {this.state.action === "List" &&
                    <div>
                        <div style={{ color: "#606060" }}>
                            <h2>Employees</h2>
                        </div>

                        <div className='w3-row' style={{ marginTop: '15px' }}>
                            <div className='w3-hide-small'>
                                <div className='w3-col m6 l6'>

                                    <Button floated='left' size='mini' onClick={this.onCreateNewEmpClick.bind(this, true)}
                                        style={{ 'max-height': '31px' }} disabled={!(this.state.isSuperAdmin || this.state.userCanAdd) || this.state.isMaxEmployeeCapacity}>
                                        <Icon name='plus' /> Add </Button>

                                    <Button floated='left' size='mini' loading={this.state.exportLoading} onClick={() => this.onExportClick()}
                                        style={{ 'max-height': '31px' }} disabled={!(this.state.isSuperAdmin || this.state.userCanExport)}> <Icon name='file excel' />Export</Button>

                                    <Button floated='left' size='mini' loading={this.state.importLoading} onClick={() => this.onImportClick()}
                                        style={{ 'max-height': '31px' }} disabled={!(this.state.isSuperAdmin || this.state.userCanImport)}> <Icon name='file excel' />Import Template</Button>

                                    <Button floated='left' size='mini' loading={this.state.exportTemplateLoading} onClick={() => this.onDownloadTemplateClick()}
                                        style={{ 'max-height': '31px' }} disabled={!(this.state.isSuperAdmin || this.state.userCanDownload)}> <Icon name='download' />Download Template</Button>

                                </div>
                                <div className='w3-col m6 l6' style={{ display: 'flex', justifyContent: 'flex-end' }}>
                                    <Menu secondary size='mini' style={{ width: '100%' }}>
                                        <div style={{ display: 'flex', justifyContent: 'flex-end', width: '100%' }}>
                                            <Button size="mini" icon="filter" onClick={this.onViewFilterClick.bind(this, true)} style={{ 'max-height': '31px', marginRight: '6px' }} />
                                            <div style={{ width: '300px' }}>
                                                <Select
                                                    placeholder='Search...'
                                                    value={searchBoxPick}
                                                    options={empSearchList(this.state.empSearchList)}
                                                    onChange={this.onEmployeeSelect.bind(this)}
                                                    onInputChange={this.onSearch}
                                                    isMulti
                                                    styles={customStyles}
                                                    isLoading={this.state.isSearchLoading}
                                                />
                                            </div>
                                            <Button size='mini' type='submit' style={{ 'max-height': '31px', marginLeft: '6px' }} onClick={this.onClickSearch.bind(this)}>Search</Button>
                                        </div>
                                    </Menu>
                                </div>
                            </div>
                        </div>

                        <div className='w3-hide-medium w3-hide-large' style={{ marginTop: '5px' }}>
                            <table style={{ width: '100%' }}>
                                <tr>
                                    <td style={{ paddingTop: '5px' }} colSpan='2'>
                                        <Button style={{ width: '100%', 'max-height': '31px', marginTop: '5px' }} floated='left' size='mini' onClick={this.onCreateNewEmpClick.bind(this, true)}
                                            disabled={!(this.state.isSuperAdmin || this.state.userCanAdd) || this.state.isMaxEmployeeCapacity} icon='plus' content='Add' />
                                    </td>
                                </tr>
                                <tr>
                                    <td style={{ paddingTop: '5px' }} colSpan='2'>
                                        <Button fluid floated='left' size='mini' loading={this.state.exportLoading} onClick={() => this.onExportClick()}
                                            style={{ 'max-height': '31px', marginRight: '5px' }} disabled={!(this.state.isSuperAdmin || this.state.userCanAdd)}> <Icon name='file excel' />Export</Button>
                                    </td>
                                </tr>
                                <tr>
                                    <td style={{ paddingTop: '5px' }} colSpan='2'>
                                        <Button fluid floated='left' size='mini' loading={this.state.importLoading} onClick={() => this.onImportClick()}
                                            style={{ 'max-height': '31px', marginRight: '5px' }} disabled={!(this.state.isSuperAdmin || this.state.userCanAdd)}> <Icon name='file excel' />Import</Button>
                                    </td>
                                </tr>
                                <tr>
                                    <td style={{ paddingTop: '5px' }} colSpan='2'>
                                        <Button fluid floated='left' size='mini' loading={this.state.exportTemplateLoading} onClick={() => this.onDownloadTemplateClick()}
                                            style={{ 'max-height': '31px', marginRight: '5px' }} disabled={!(this.state.isSuperAdmin || this.state.userCanAdd)}> <Icon name='download' />Template</Button>
                                    </td>
                                </tr>
                                <tr>
                                    <td style={{ paddingTop: '5px' }} colSpan='2'>
                                        <Button style={{ width: '100%', 'max-height': '31px' }} size="mini" icon="filter" content='Filter' onClick={this.onViewFilterClick.bind(this, true)} />
                                    </td>
                                </tr>
                                <tr>
                                    <td style={{ paddingTop: '5px', width: '90%' }}>
                                        <Select
                                            placeholder='Search...'
                                            value={this.state.searchBoxPick}
                                            options={empSearchList(this.state.empSearchList)}
                                            onChange={this.onEmployeeSelect.bind(this)}
                                            onInputChange={this.onSearch}
                                            isMulti
                                            styles={customStyles}
                                            isLoading={this.state.isSearchLoading}
                                        />
                                    </td>
                                    <td style={{ width: '10%' }}>
                                        <Button fluid size='mini' icon='search' type='submit' onClick={this.onClickSearch.bind(this)} />
                                    </td>
                                </tr>
                            </table>
                        </div>
                        {this.state.isMaxEmployeeCapacity &&
                            <div style={{ margin: '5px 0px 0px 0px;' }}>
                                <Message content="" size='tiny' color='yellow' >
                                    <Message.Content>
                                        <p>You have reached the maximum employee count for the current version of your ZKTeco Payroll <br />
                                            Please contact your administrator or purchase a license to extend the employee count.</p>
                                    </Message.Content>
                                </Message>
                            </div>
                        }
                        <div style={{ margin: '.5%', paddingTop: '10px', 'border-width': '0px 0px 1px 0px', 'border-style': 'solid', 'border-color': '#DDDCDC', verticalAlign: 'middle' }}>
                            <Menu secondary size='mini'>
                                {isSubscribed !== 'YES' && <Menu.Item>
                                    <label>Maximum Employee Count: {this.state.maxEmpCount}</label>
                                </Menu.Item>}
                                <Menu.Item>
                                    {this.state.empCount > 0 && <label>Total Results Count: {this.state.empCount}</label>} &nbsp;
                                </Menu.Item>
                                <Menu.Item position='right'>
                                {!this.state.inactiveOnly && (
                                    <Checkbox style={{marginRight:'1em'}} size='mini' label="Include Resigned" onChange={this.onChkCheckChange.bind(this)} checked={this.state.includeInactive} />
                                )}
                                    <Checkbox size='mini' label="Inactive Only" onChange={this.onChkInactiveCheckChange.bind(this)} checked={this.state.inactiveOnly} />
                                </Menu.Item>
                            </Menu>
                        </div>

                        <div style={{ height: window.innerHeight - 250, overflowY: 'auto', overflowX: 'hidden' }}>
                            {this.state.employeeList !== undefined && this.state.employeeList.length > 0 &&
                                <Card.Group>
                                    {this.state.employeeList.map(details => {
                                        return (
                                            <Card style={{ minWidth: '340px' }}>
                                                <Card.Content>
                                                    <EmployeeImage floated='right' empId={details._id} fileName={details.Image} style={{ width: '75px', height: '75px' }} />
                                                    <Card.Header style={{ width: '300px', overflowWrap: 'break-word' }}><label style={{ color: details.Deleted && 'red' }}>{details.FullName === undefined ? fullName(details) : details.FullName}</label></Card.Header>
                                                    <Card.Meta>
                                                        {details.Position}
                                                    </Card.Meta>
                                                    <Card.Meta>
                                                        {details.Department !== "" && <span><span style={{ 'font-weight': 'bold', fontSize: '11px' }}><Icon name='users' /> {details.Department}</span><br /></span>}
                                                        {details.Email !== "" && <span><span style={{ 'font-weight': 'bold', fontSize: '11px' }}><Icon name='mail' /> {details.Email}</span><br /></span>}
                                                        {/* {<span><a style={{ 'font-weight': 'bold', fontSize: '11px' }} onClick={() => this.printE201(details["_id"])}><Icon name='print' /> {"Print"}</a></span>} */}
                                                    </Card.Meta>
                                                    <Card.Description>
                                                        <span className='date'><span style={{ 'font-weight': 'bold', fontSize: '11px' }}>Date Hired: {details.DateHired !== "" ? moment(details.DateHired).format("MM-DD-YYYY") : ""}</span></span><br />
                                                        <span className='date'><span style={{ 'font-weight': 'bold', fontSize: '11px' }}>Birth Date: {details.BirthDate !== "" ? moment(details.BirthDate).format("MM-DD-YYYY") : ""}</span></span><br />
                                                        <span><span style={{ 'font-weight': 'bold', fontSize: '11px' }}>Age: {calculateAge(details.BirthDate)}</span></span>
                                                    </Card.Description>
                                                </Card.Content>
                                                <Card.Content extra>
                                                    <Button.Group float='right'>
                                                        <Button style={{ 'font-size': '10px', height: '25px', minWidth: '90px', margin: '2px' }} onClick={this.onEditClick.bind(this, details)} disabled={!this.state.userCanEdit}
                                                            loading={this.state.isPerformingAction && this.state.idPerformingAction === details["_id"] && this.state.actionPerformed === "UPDATE"}>
                                                            <Icon name='edit' />
                                                            View
                                                        </Button>
                                                        {!details.Deleted &&
                                                            <Button style={{ 'font-size': '10px', height: '25px', minWidth: '90px', margin: '2px' }} onClick={() => this.show('mini', details)} disabled={!this.state.userCanDelete || global.userId === details._id}
                                                                loading={this.state.isPerformingAction && this.state.idPerformingAction === details["_id"] && this.state.actionPerformed === "DELETE"}>
                                                                <Icon name='delete calendar' />
                                                                Set Inactive
                                                            </Button>}

                                                        {details.Deleted && !this.state.isMaxEmployeeCapacity &&
                                                            <Button style={{ 'font-size': '10px', height: '25px', minWidth: '90px', margin: '2px' }} onClick={this.showRestore(details)} disabled={!this.state.userCanDelete}
                                                                loading={this.state.isPerformingAction && this.state.idPerformingAction === details["_id"] && this.state.actionPerformed === "DELETE"}>
                                                                <Icon name='delete calendar' />
                                                                Set Active
                                                            </Button>
                                                        }
                                                        {(this.state.isSuperAdmin || isTaskAvailable('EMPLOYEE_RESET_PASSWORD')) && <Button style={{ 'font-size': '10px', height: '25px', minWidth: '90px', margin: '2px' }} onClick={this.onResetPasswordClick.bind(this, details)}
                                                            loading={this.state.isPerformingAction && this.state.idPerformingAction === details["_id"] && this.state.actionPerformed === "UPDATE"}>
                                                            <Icon name='key' />
                                                            Reset Password
                                                        </Button>}
                                                    </Button.Group>
                                                </Card.Content>

                                            </Card>
                                        );
                                    })}
                                    {/* </div> */}
                                </Card.Group>}
                            {this.state.isLoading && <Loader active inline='centered' style={{marginTop:"2em"}} />}
                            {this.state.isLoading === false && this.state.reachedEnd === false &&
                                <Visibility offset={[10, 10]} once={true}
                                    onTopVisible={this.visibilityUpdate.bind(this)}
                                    updateOn='repaint'
                                >
                                    <div style={{height:"5em"}}>&nbsp;</div>
                                </Visibility>
                            }
                        </div>

                        {
                            this.state.employeeList === undefined &&
                            <center> <h2>No employee to be shown</h2> </center>
                        }
                        < Modal size='mini' open={open} onClose={this.close} >
                            <Modal.Header>Set Inactive Employee</Modal.Header>
                            <Modal.Content>
                                <div className="ui one column grid">
                                    <div className="column">
                                        <div>Type of Separation</div>
                                        <Dropdown
                                            fluid
                                            selection
                                            value={this.state.separationType}
                                            options={separationTypeList}
                                            onChange={this.onSeparationTypeChange}
                                        // styles={customStyles}
                                        // isLoading={this.state.isSearchLoading}
                                        />
                                    </div>
                                </div>
                                <div className="ui one column grid">
                                    <div className="column">
                                        <div>Reason for Leaving</div>
                                        <Input
                                            fluid
                                            placeholder='Reason'
                                            value={this.state.reasonForLeaving}
                                            // style={{ width: '300px' }}
                                            error={this.state.isError && this.state.reasonForLeaving === ""}
                                            onChange={(e, { value }) => this.setState({ reasonForLeaving: value })}
                                        />
                                    </div>
                                </div>
                                <div className="ui one column grid">
                                    <div className="column">
                                        <div>Resignation Date</div>
                                        <Input
                                            fluid
                                            error={this.state.isError && (this.state.dtInputEmployee === "" || !checkDate(this.state.dtInputEmployee).Result)}
                                            size='small'
                                            value={this.state.dtInputEmployee}
                                            // style={{ width: '150px' }}
                                            min="1753-01-01"
                                            max="2999-12-31"
                                            type="date"
                                            onChange={(e, { value }) => this.setState({ dtInputEmployee: value })}
                                        />
                                    </div>
                                </div>


                            </Modal.Content>
                            <Modal.Actions>
                                <Button basic onClick={this.deleteClick.bind(this, this.state.selectedEmployee)} loading={this.state.isDeleting} disabled={this.state.isDeleting} icon='check' content='Yes' />
                                <Button basic onClick={this.close} disabled={this.state.isDeleting} icon='cancel' content='No' />
                            </Modal.Actions>
                        </Modal>

                        <Modal size='mini' open={openRestore} onClose={this.close}>
                            <Modal.Header>Set Active Employee</Modal.Header>
                            <Modal.Content>
                                <label style={{ width: '130px' }}> Date Hired </label>
                                <Input
                                    error={this.state.isError && (this.state.dtInputEmployee === "" || !checkDate(this.state.dtInputEmployee).Result)}
                                    size='small'
                                    value={this.state.dtInputEmployee}
                                    style={{ width: '180px' }}
                                    min="1753-01-01"
                                    max="2999-12-31"
                                    type="date"
                                    onChange={(e, { value }) => this.setState({ dtInputEmployee: value })}
                                />
                            </Modal.Content>
                            <Modal.Actions>
                                <Button basic onClick={this.restoreClick.bind(this, this.state.selectedEmployee)} loading={this.state.isRestoring} disabled={this.state.isRestoring} icon='check' content='Yes' />
                                <Button basic onClick={this.close} disabled={this.state.isRestoring} icon='cancel' content='No' />
                            </Modal.Actions>
                        </Modal>

                        {this.state.selectedEmployee != null &&
                            <Modal size="mini" open={this.state.deleteEnrollNotice}>
                                <Modal.Header>Set Inactive Notice</Modal.Header>
                                <Modal.Content>
                                    <p>
                                        You're about to set &nbsp;
                                        <span style={{ color: 'red' }}><b>{this.state.selectedEmployee.FullName}</b></span>&nbsp;
                                        as resign/inactive. Personnel's information will be removed permanently
                                        from the assigned biometric device.
                                    </p>
                                </Modal.Content>
                                <Modal.Actions>
                                    <Button basic onClick={this.confirmDelete.bind(this)} loading={this.state.isDeleting} disabled={this.state.isDeleting} icon='check' content='Yes' />
                                    <Button basic onClick={this.close} disabled={this.state.isRestoring} icon='cancel' content='No' />
                                </Modal.Actions>
                            </Modal>
                        }

                        {this.state.selectedEmployee != null &&
                            <Modal size={size} open={this.state.modalResetPasswordOpen} onClose={this.close}>
                                <Modal.Header>Reset Password</Modal.Header>
                                <Modal.Content>
                                    <p>Are you sure you want to reset password of '{this.state.selectedEmployee.LastName + ', ' +
                                        this.state.selectedEmployee.FirstName + ' '}'? </p>
                                </Modal.Content>
                                <Modal.Actions>
                                    <Button basic onClick={this.resetPassword.bind(this)} icon='check' content='Yes' />
                                    <Button basic onClick={this.onResetPasswordModalClose.bind(this)} icon='cancel' content='No' />
                                </Modal.Actions>
                            </Modal>
                        }

                        <EmployeeFilterModal
                            advFilter={this.state.advanceFilter}
                            open={this.state.isFilterActive}
                            onCancel={this.onViewFilterClick.bind(this, false)}
                            callback={this.advanceFilterCallBack.bind(this)}
                            hideFilter={["EMPLOYEE", "STATUS"]}
                            // showFilter={["INACTIVE_ONLY"]}
                        />
                    </div>

                }
                {this.state.openPopUp && <MessageBoxOkOnly title={this.state.popUpTitle} caption={this.state.popUpContent} onClick={() => this.setState({ openPopUp: false })} />}
            </>
        )
    }
}

function guidToBytes(guid) {
    var bytes = [];
    guid.split('-').map((number, index) => {
        var bytesInChar = index < 3 ? number.match(/.{1,2}/g).reverse() : number.match(/.{1,2}/g);
        bytesInChar.map((byte) => { bytes.push(parseInt(byte, 16)); })
    });
    return bytes;
}
/*
async function getImageSrc(empId, fileName){
    if( fileName === 'default.jpg'){
        return "/Images/default.png"
    }
    else{
        var url = global.StorageAccountUrl + "/uploads/avatars/" + fileName;
        return await window.fetch( url )
            .then( resp => resp.arrayBuffer())
            .then( buffer => {
                const data = new Uint8Array(buffer);
                const iv = data.slice(0, 16);
                const cipher = new Buffer(data.slice(16), 'hex');

                const hash = crypto.createHash('sha256');
                const binKey = guidToBytes(empId);
                const key = hash.update( binKey ).digest('hex');

                const bufferKey = new Buffer(key, 'hex');

                const decrypter = crypto.createDecipheriv("aes-256-cbc", bufferKey, iv);
                let plain =  decrypter.update(cipher);
                plain = Buffer.concat([plain, decrypter.final()]);

                const base64 = plain.toString('base64');
                return 'data:image/jpeg;base64,' + base64;
                
            }).catch( reason => {
                var x = 0;
                return "/Images/default.png";
            })
            
    }

}
*/
function toTitleCase(str) {
    return str.replace(/\w\S*/g, function (txt) {
        return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
    });
}

EmployeeList.getViewID = () => { return "EmployeeModule" };
EmployeeList.getCaption = () => { return "Employees" }
EmployeeList.getViewAccessKey = () => {
    return isTaskAvailable("EMPLOYEE_VIEW") || isTaskAvailable("EMPLOYEE_ADD");
}
EmployeeList.getIcon = () => {
    return (<Image style={{ "height": "24px", "width": "24px" }} src='/Icons/Employees.png' avatar />);
}
EmployeeList.getIconSrc = (size) => {
    return '/Icons/Employees.png';
}


export default EmployeeList;
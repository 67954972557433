import React, { Component } from 'react';
import { Button, Table, ButtonGroup, Modal, Input, Label, Tab, Checkbox } from 'semantic-ui-react';
import { ajaxPost } from '../../ajax';
import { handleNumericOnKeyDown, handleNumericOnKeyPress } from '../../utils';
import Select from 'react-select';
import PdfMake from 'pdfmake/build/pdfmake';
import vfsFonts from 'pdfmake/build/vfs_fonts';

const moment = require('moment');

export default class Form1603 extends Component {

    constructor() {
        super();

        this.state = {
            isCompanyLoading: false,
            isGenerateLoading: false,
            isExportLoading: false,
            companyList: [],
            selectedCompany: null,
            quarter: this.checkQuarter(moment().format('MM')),
            year: moment().format('YYYY'),

            generatedData: [],

            totalField: {
                totalFringeBenefit: 0.0,
                totalFringeTax: 0.0,
                taxStillDue: 0.0,
                totalPenalties: 0.0,
                totalAmountStillDue: 0.0,
                totalRemitanceMade:0.0
            },

            taxRemittedReturnPreviouslyFiled: 0.0,
            otherRemittanceMade: '',
            otherRemittanceMadeAmount: 0.0,
            surcharge: 0.0,
            interest: 0.0,
            compromise: 0.0,

            isAmendedReturn: false,
            isAnyTaxWithheld: false,
            isUnderSpecialLaw: false,
            specifySpecialLaw: '',

            isModalOpen: false
        }

        this.loadCompany('');
    }

    componentWillMount = () => {
        if(this.props.prevState !== null)
            this.state = this.props.prevState;
    }

    componentWillUnmount = () => {
        this.props.unmountCallback(this.state, 'Form1603');
    }

    checkQuarter(month) {
        month = parseInt(month);    

        if(month >= 1 && month <= 3)
            return 1;
        if(month >= 4 && month <= 6)
            return 2;
        if(month >= 7 && month <= 9)
            return 3;
        if(month >= 10 && month <= 12)
            return 4;
    }

    onTextChange = (field, e) => {
        this.setState({ [field]: e.target.value }, () => this.computeTotalFields());
    }

    computeTotalFields() {
        var { totalField, generatedData } = this.state;
        var totalFringeBenefit = 0.0;
        var totalFringeTax = 0.0;

        generatedData.forEach(x => {
            totalFringeBenefit += x.FringeBenefit;
            totalFringeTax += x.FringeTax;
        });

        var penaltySurcharge = this.state.surcharge === ''? 0: parseFloat(this.state.surcharge).toFixed(2);
        var penaltyInterest = this.state.interest === ''? 0: parseFloat(this.state.interest).toFixed(2);
        var penaltyCompromise = this.state.compromise === ''? 0: parseFloat(this.state.compromise).toFixed(2);

        var taxRemittedReturnPreviouslyFiled = this.state.taxRemittedReturnPreviouslyFiled === ''? 0: this.state.taxRemittedReturnPreviouslyFiled;

        totalField.totalFringeBenefit = parseFloat(totalFringeBenefit);
        totalField.totalFringeTax = parseFloat(totalFringeTax);
        totalField.totalRemitanceMade = parseFloat(taxRemittedReturnPreviouslyFiled) + parseFloat(this.state.otherRemittanceMadeAmount);
        totalField.taxStillDue = parseFloat(totalField.totalFringeTax) - parseFloat(totalField.totalRemitanceMade);
        totalField.totalPenalties = parseFloat(penaltySurcharge) + parseFloat(penaltyInterest) + parseFloat(penaltyCompromise);
        totalField.totalAmountStillDue = parseFloat(totalField.taxStillDue) + parseFloat(totalField.totalPenalties);
        
        this.setState({ totalField });
    }

    loadCompany() {
        this.setState({ isCompanyLoading: true });
        ajaxPost({
            url: 'api/group/search',
            data: {
                Search: '',
                startingIndex: 0,
                itemCount: 999,
                Types: ['COMPANY']
            },
            onSuccess: (data) => {
                var list = data.content.map(x => { return ({ label: x.Name, value: x._id }) });
                this.setState({ companyList: list });
            },
            finally: () => {
                this.setState({ isCompanyLoading: false });
            }
        })
    }

    createTableRow(label, value, disable) {
        var curr = this.state[value];
        return (
            <tr>
                <td style={{ width: '75%' }}>
                    {label}
                </td>
                {disable === false && <td style={{ width: '25%' }}>
                    <Input style={{ border: '1px solid gray' }} disabled={disable} fluid
                        onChange={this.onTextChange.bind(this, value)} value={curr}
                        onKeyPress={(e) => handleNumericOnKeyPress(e)}
                        onKeyDown={(e) => handleNumericOnKeyDown(e)}
                    />
                </td>}
                {disable === true && <td style={{ width: '25%' }}>
                    <Input style={{ border: '1px solid gray' }} disabled={disable} fluid value={value}/>
                </td>}
            </tr>
        )
    }

    onGenerateClick = () => {
        if(this.state.isGenerateLoading)
            return;

        this.setState({isGenerateLoading: true});
        this.generateForm1603( (processId) =>
            ajaxPost({
                url: 'api/governmentForms/GetForm1603',
                data: processId,
                onSuccess: (data) => {
                    this.setState({generatedData: data.content, isGenerateLoading: false}, () => {this.computeTotalFields()});
                },
                finally: () => {}
            })
        )
    }

    generateForm1603 = (callback) => {
        if (this.state.selectedCompany === null) {
            this.setState({isGenerateLoading: false});
            alert("Please select a company");
            return;
        }

        if(this.state.quarter === '' || this.state.quarter > 4 || this.state.quarter < 1) {
            this.setState({isGenerateLoading: false});
            alert("Invalid value of quarter");
            return;
        }

        if(this.state.year === '' || this.state.year > 9999 || this.state.year < 1990) {
            this.setState({isGenerateLoading: false});
            alert("Invalid value of year");
            return;
        }

        ajaxPost({
            url: 'api/governmentforms/GenerateForm1603',
            data: {
                companyId: this.state.selectedCompany.value,
                quarter: this.state.quarter,
                year: this.state.year
            },
            onSuccess: (value) => {
                this.checkStatus(value, callback);
            },
            finally: () => { }
        })
    }

    checkStatus(processId, callback) {
        ajaxPost({
            data: { processId: processId },
            url: 'api/BackgroundProcess/GetStatus',
            onSuccess: data => {
                if (data.Status != "PROCESSING") {
                    callback(processId)
                }
                else {
                    this.checkStatus(processId, callback);
                }
            },
            finally: () => { }
        })
    }
    AddText =(text, x, y, fontSize = 6,alignment = undefined, colSpan = undefined) => {
        let item =  {
            text: text,
            style: { fontSize: fontSize }, 
        }
        if(alignment !== undefined)
            item.alignment = alignment;
        else
            item.absolutePosition =  { x: x, y: y } 
        if(colSpan !== undefined)
            item.colSpan = colSpan;

        return item;
    }
    onExportPDFClick = () => {
        if(this.state.isExportLoading)
            return;

        var {quarter, year, totalField, generatedData} = this.state;
        if(this.state.selectedCompany === null){
            alert("Please select a company");
            return;
        }

        if(this.state.quarter === '' || this.state.quarter > 4 || this.state.quarter < 1) {
            alert("Invalid value of quarter");
            return;
        }

        if(this.state.year === '' || this.state.year > 9999 || this.state.year < 1990) {
            alert("Invalid value of year");
            return;
        }

        this.setState({isExportLoading: true});
        let xnumbers = [];
        for(let i = 0; i < 200; i++) {
            xnumbers.push(i);
        } 
        ajaxPost({
            url: 'api/governmentforms/Form1603ExportToPDF',
            data: {
                companyId: this.state.selectedCompany.value
            },
            onSuccess: (data) => {
                const { vfs } = vfsFonts.pdfMake;
                PdfMake.vfs = vfs;

                if(data.TIN.length < 12)
                    data.TIN += '0'.repeat(12 - data.TIN.length)

                const tin1 = data.TIN.substring(0,3).split('').join('     ');
                const tin2 = data.TIN.substring(3,6).split('').join('     ');
                const tin3 = data.TIN.substring(6,9).split('').join('     ');
                const tin4 = data.TIN.substring(9).split('').join('     ');

                year = year.split('').join('     '); 

                var pdfData = {
                    pageSize: 'LETTER',
                    pageOrientation: 'portrait',
                    content: [ 
                        {
                            image: data.FirstPageImage,
                            width: 610,
                            height: 792,
                            absolutePosition: { x: 0, y: 0 }
                        },
                        this.AddText(year,38 ,133, 8),
                        this.AddText('X',quarter.toString() === '1'? 121: quarter.toString() === '2'? 167: quarter.toString() === '3'? 208: 252 ,132,7), 
                        this.AddText(tin1,237 ,164, 8),
                        this.AddText(tin2,294 ,164, 8),
                        this.AddText(tin3,352 ,164, 8),
                        this.AddText(tin4,408 ,164, 8), 
                        data.CompanyName.toString().split('').map((x, index) => {
                            return  this.AddText(x,24 + index * 14.4,188, 8)
                        }),
                        data.Address.substring(0,39).toString().split('').map((x, index) => {
                            return  this.AddText(x,24 + index * 14.4,212, 8)
                        }),
                        data.Address.substring(39,69).toString().split('').map((x, index) => {
                            return  this.AddText(x,24 + index * 14.4,228, 8)
                        }), 
                        data.ContactNo.split('').map((x, index) => {
                            return this.AddText(x, 108 + index * 14.4,246, 8 )
                        }),
                        this.AddText('X', 450,244,8),
                        data.Email.split('').map((x, index) => {
                            return this.AddText(x, 108 + index * 14.4,262, 8 )
                        }),
                        this.AddText(this.state.otherRemittanceMade,196 ,340, 8), 
                        {
                            absolutePosition: { x: 385, y: 308 },
                            layout: 'noBorders',
                            table: {
                                widths: [200],
                                heights: [12.8, 12.8,12.8,12.8,12.8,12.8,12.8,12.8,12.8,12.8],
                                body:[
                                    [
                                        this.AddText(totalField.totalFringeTax.toFixed(2).split('').join('     '),0,0,8,'right')                                       
                                        // this.AddText(totalField.totalFringeTax.toFixed(2).split('').join('     '),0,0,8,'right')                                       
                                    ],   
                                    [
                                        this.AddText(parseFloat(this.state.taxRemittedReturnPreviouslyFiled).toFixed(2).split('').join('     '),0,0,8,'right')                                       
                                    ],   
                                    [
                                        this.AddText(parseFloat(this.state.otherRemittanceMadeAmount).toFixed(2).split('').join('     '),0,0,8,'right')                                       
                                    ],   
                                    [
                                        this.AddText(totalField.totalRemitanceMade.toFixed(2).split('').join('     '),0,0,8,'right')                                       
                                    ],   
                                    [
                                        this.AddText(totalField.taxStillDue.toFixed(2).split('').join('     '),0,0,8,'right')                                       
                                    ],   
                                    [
                                        this.AddText(parseFloat(this.state.surcharge).toFixed(2).split('').join('     '),0,0,8,'right')                                       
                                    ],   
                                    [
                                        this.AddText(parseFloat(this.state.interest).toFixed(2).split('').join('     '),0,0,8,'right')                                       
                                    ],   
                                    [
                                        this.AddText(parseFloat(this.state.compromise).toFixed(2).split('').join('     '),0,0,8,'right')                                       
                                    ],   
                                    [
                                        this.AddText(totalField.totalPenalties.toFixed(2).split('').join('     '),0,0,8,'right')                                       
                                    ],   
                                    [
                                        this.AddText(totalField.totalAmountStillDue.toFixed(2).split('').join('     '),0,0,8,'right')                                       
                                    ],   
                                ]
                            }
                        }, 
                        // xnumbers.map((index,num) =>{
                        //     return {
                        //         text:num,
                        //         absolutePosition: { x: num * 10 , y: 5 },
                        //         fontSize: 4
                        //     }
                        // }),
                        // xnumbers.map((index,num) =>{
                        //     return {
                        //         text:'|',
                        //         absolutePosition: { x: num * 10 , y: 10 }
                        //     }
                        // }),
                        // xnumbers.map((index,num) =>{
                        //     return {
                        //         text:num,
                        //         absolutePosition: { x: 5, y: num * 10 },
                        //         fontSize: 4
                        //     }
                        // }),
                        // xnumbers.map((index,num) =>{
                        //     return {
                        //         text:'_',
                        //         absolutePosition: { x: 10, y: num * 10 }
                        //     }
                        // }),
                        {
                            pageBreak: 'before',
                            image: data.SecondPageImage,
                            width: 610,
                            height: 792,
                            absolutePosition: { x: 0, y: 0 }
                        },
                        data.TIN.split('').map((n,index) => {

                            return  this.AddText(n,22 + index * 14.4,80, 8)
                        }),
                        data.CompanyName.toString().split('').map((x, index) => {
                            return  this.AddText(x,222 + index * 14.4,80, 8)
                        }), 
                        {
                            absolutePosition: { x: 380, y: 132 },
                            layout: 'noBorders',
                            table: {
                                widths: [206],
                                heights: [12.5, 12.5],
                                body:[
                                    [
                                        this.AddText((generatedData.length > 0? generatedData[0].FringeBenefit.toFixed(2): '').split('').join('     '),0,0,8,'right')                                           
                                    ],   
                                ]
                            }
                        },
                        {
                            absolutePosition: { x: 120.5, y: 193 },
                            layout: 'noBorders',
                            table: {
                                widths: [206],
                                heights: [12.5, 12.5],
                                body:[
                                    [
                                        this.AddText((generatedData.length > 0? generatedData[0].TaxBasedGrossUp.toFixed(2): '').split('').join('     '),0,0,8,'right')                                       
                                    ],   
                                ]
                            }
                        },
                        {
                            absolutePosition: { x: 380, y: 193 },
                            layout: 'noBorders',
                            table: {
                                widths: [206],
                                heights: [12.5, 12.5],
                                body:[
                                    [
                                        this.AddText((generatedData.length > 0? generatedData[0].FringeTax.toFixed(2): '').split('').join('     '),0,0,8,'right')                                                                            
                                    ],  
                                    [
                                        this.AddText(''.split('').join('     '),0,0,8,'right')                                       
                                    ],  
                                    [
                                        this.AddText(totalField.totalFringeTax.toFixed(2).split('').join('     '),0,0,8,'right')                                                                               
                                    ],  
                                ]
                            }
                        },

                    ]
                }

                PdfMake.createPdf(pdfData).open();
            },
            finally: () => {
                this.setState({isExportLoading: false});
            }
        })
    }

    render() {

        var { generatedData, totalField } = this.state;

        const tabSelection = [
            {
                menuItem: 'Fringe Summary', pane:
                    <Tab.Pane attached={false} style={{ border: '0px none', width: '100%' }}>
                        <div style={{ marginTop: 10 }}>
                            <Table fixed striped padded size='small'>
                                <Table.Header>
                                    <Table.Row style={{ 'backgroundColor': 'gainsboro', 'fontSize': '11px', padding: '0px' }}>
                                        <Table.HeaderCell>Recepients</Table.HeaderCell>
                                        <Table.HeaderCell>ATC</Table.HeaderCell>
                                        <Table.HeaderCell>Monetary Value of Fringe Benefit</Table.HeaderCell>
                                        <Table.HeaderCell>Percentage Divisor</Table.HeaderCell>
                                        <Table.HeaderCell>Tax Base Grossed-Up Monetary Value</Table.HeaderCell>
                                        <Table.HeaderCell>Tax Rate</Table.HeaderCell>
                                        <Table.HeaderCell>Tax Required To Be Withheld</Table.HeaderCell>
                                    </Table.Row>
                                </Table.Header>
                                <Table.Body>
                                    {generatedData.map(list => {
                                        return (
                                            <Table.Row>
                                                <Table.Cell verticalAlign='top'>{list.Recipients}</Table.Cell>
                                                <Table.Cell verticalAlign='top'>{list.TaxCode}</Table.Cell>
                                                <Table.Cell verticalAlign='top'>{list.FringeBenefit.toFixed(2)}</Table.Cell>
                                                <Table.Cell verticalAlign='top'>{list.PercentageDivisor + '%'}</Table.Cell>
                                                <Table.Cell verticalAlign='top'>{list.TaxBasedGrossUp.toFixed(2)}</Table.Cell>
                                                <Table.Cell verticalAlign='top'>{list.TaxRate + '%'}</Table.Cell>
                                                <Table.Cell verticalAlign='top'>{list.FringeTax.toFixed(2)}</Table.Cell>
                                            </Table.Row>
                                        )
                                    })}
                                </Table.Body>
                            </Table>
                        </div>

                    </Tab.Pane>
            },
            {
                menuItem: 'Adjustments', pane:
                    <Tab.Pane attached={false} style={{ border: '0px none', width: '100%' }}>
                        <table style={{ minWidth: 675, width: '100%' }}>
                            {this.createTableRow('14 - Total Taxes Withheld', totalField.totalFringeTax.toFixed(2), true)} 
                            {this.createTableRow('15 - Less Tax Remitted in Return Previously Filed, if this is an amended return', 'taxRemittedReturnPreviouslyFiled', false)}
                            <tr>
                                <td>
                                <label style={{fontWeight: 'bold'}}>16</label> Others Remitances Made
                                </td>
                            </tr>
                            <tr>
                                <td style={{ width: '50%' }}>
                                    <Input style={{ border: '1px solid gray' }} disable fluid onChange={this.onTextChange.bind(this, 'otherRemittanceMade')} value={this.state.otherRemittanceMade} />
                                </td>
                                <td style={{ width: '25%' }}>
                                    <Input style={{ border: '1px solid gray' }} disable fluid
                                        onChange={this.onTextChange.bind(this, 'otherRemittanceMadeAmount')}
                                        onKeyPress={(e) => handleNumericOnKeyPress(e)}
                                        onKeyDown={(e) => handleNumericOnKeyDown(e)}
                                        value={this.state.otherRemittanceMadeAmount} />
                                </td>
                            </tr>
                            {this.createTableRow('17 - Total Remitances Made', totalField.totalRemitanceMade.toFixed(2), true)} 
                            {this.createTableRow('18 - Tax Still Due / (Overremittance)', totalField.taxStillDue.toFixed(2), true)}
                            <tr>
                                <td colSpan={2}>
                                Add: Penalties 
                                </td>
                            </tr>
                            {this.createTableRow(<div> &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; <label> 19 - Surcharge</label></div>, 'surcharge', false)}
                            {this.createTableRow(<div> &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; <label>20 - Interest</label></div>, 'interest', false)}
                            {this.createTableRow(<div> &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; <label>21 - Compromise</label></div>, 'compromise', false)}
                            {this.createTableRow('22 - Total Penalties', totalField.totalPenalties.toFixed(2), true)}
                            {this.createTableRow('23 - Total Amount Still Due (Overremittance)', totalField.totalAmountStillDue.toFixed(2), true)}
                        </table>
                    </Tab.Pane>

            },
        ]



        return (
            <div>
                <h1>Form 1603</h1>

                <div style={{ display: 'flex' }}>
                    <div style={{ height: window.innerHeight - 203, marginRight: '10px', width: 300 }}>
                        <div>
                            <label>Company</label>
                            <Select value={this.state.selectedCompany} isLoading={this.state.isCompanyLoading} options={this.state.companyList}
                                onChange={(e) => this.setState({ selectedCompany: e, selectedEmployee: null }, this.loadEmployees)} />
                        </div>

                        <div style={{ marginTop: 5 }}>
                            <label>Quarter</label>
                            <Input fluid value={this.state.quarter}
                                onChange={(e) => this.setState({ quarter: e.target.value })}
                                onKeyPress={(e) => handleNumericOnKeyPress(e)}
                                onKeyDown={(e) => handleNumericOnKeyDown(e)}
                            />
                        </div>

                        <div style={{ marginTop: 5 }}>
                            <label>Year</label>
                            <Input fluid value={this.state.year}
                                onChange={(e) => this.setState({ year: e.target.value })}
                                onKeyPress={(e) => handleNumericOnKeyPress(e)}
                                onKeyDown={(e) => handleNumericOnKeyDown(e)}
                            />
                        </div>

                        <div style={{ display: 'flex', float: 'right', right: 0 }}>
                            <Button style={{ marginTop: 10 }} loading={this.state.isGenerateLoading} content='Generate' floated='right' onClick={this.onGenerateClick.bind(this)} />
                            <Button style={{ marginTop: 10 }} loading={this.state.isExportLoading} content='Export To PDF' floated='right' onClick={this.onExportPDFClick.bind(this)} />
                        </div>

                    </div>

                    <div style={{ width: window.innerWidth - 600, height: window.innerHeight - 203, overflowX: 'auto', overflowY: 'auto' }}>
                        <Tab renderActiveOnly={false} style={{ minWidth: 700 }} menu={{ color: 'green', tabular: false, attached: false }}
                            panes={tabSelection} activeIndex={this.state.activeIndex} onTabChange={this.handleTabChange} />
                    </div>
                </div>
            </div>
        )
    }

}
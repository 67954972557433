import React, { Component } from 'react';
import { Table } from 'semantic-ui-react';
import GovernmentTableBase from './GovernmentTableBase';

const tableHeaders = ['Range', 'Type', 'EE', 'ER', 'Total'];
const loadTableUrl = 'api/PhilhealthTable/load'
const saveTableUrl = 'api/PhilhealthTable/save'
const resetTableUrl = 'api/PhilhealthTable/reset'

export default class PhilhealthTable extends Component {
    constructor(props) {
        super(props);
    }

    render() {
        return (
            <div style={{ minWidth: '500px', width: '100%' }}>
                <div style={{ color: "#606060", marginTop: '1%' }}>
                    <h2>Philhealth Table</h2>
                </div>

                <GovernmentTableBase
                    ref={(x) => this.baseTable = x}
                    headers={tableHeaders}
                    loadTableUrl={loadTableUrl}
                    saveTableUrl={saveTableUrl}
                    resetTableUrl={resetTableUrl}
                    year={this.props.year}
                    resetButtonEnable={this.props.resetButtonEnable}
                />
            </div>
        )
    }
}
import React, { Component } from "react";
import { Select } from "semantic-ui-react";
import {
  Pagination,
  Table,
  Container,
  Segment,
  Menu,
  Input,
  Button,
  Icon,
  Popup,
  Checkbox,
  Modal,
  Form,
  FormGroup,
  FormRadio,
} from "semantic-ui-react";
import "semantic-ui-css/semantic.min.css";
import { ajaxPost } from "../../ajax.js";
import {
  customStyles,
  handleIPOnKeyPress,
  handleNumeric,
  handleNumericOnKeyDown,
} from "../../utils";

var moment = require("moment");

const BlankDevice = {
  DeviceCode: "",
  DeviceName: "",
  SerialNumber: "",
  IPAddress: "",
  PushVersion: "",
  TimeZone: "8",
  LastUpdate: null,
  DeviceInformation: null,
  Deleted: false,
  FaceSupported: false,
  PalmSupported: false,
  DeviceFunction:"Default"
};

function IPAddressKeyOnly(e) {
  var keyCode = e.keyCode == 0 ? e.charCode : e.keyCode;
  if (keyCode != 46 && keyCode > 31 && (keyCode < 48 || keyCode > 57))
    return false;
  return true;
}

class DeviceLoad extends Component {
  state = {
    device: null,
    saving: false,
    errorMsg: "",
  };

  constructor(props) {
    super(props);

    if (props.device === null) {
      this.state.device = JSON.parse(JSON.stringify(BlankDevice));
    } else {
      this.state.device = props.device;
    }

    if (props.getRef != null) props.getRef(this);
  }

  handleChange = (name) => (event) => {
    var current = this.state.device;
    current[name] = event.target.value;
    this.setState({ device: current });
  };

  onClickSave = () => 
  {
    if( this.state.device.DeviceCode.trim() == ''){
      this.setState({ errorMsg: "Device code must not be blank" });
      return;
    }
    if( this.state.device.DeviceName.trim() == ''){
      this.setState({ errorMsg: "Device name must not be blank" });
      return;
    }
    if( this.state.device.SerialNumber.trim() == ''){
      this.setState({ errorMsg: "Serial number must not be blank" });
      return;
    }
    this.setState({ saving: true, errorMsg: "" });
    ajaxPost({
      url: "api/Device/save",
      data: this.state.device,
      onSuccess: (data) => {
        if (data.errors != undefined && data.errors.length > 0) {
          this.setState({ errorMsg: data.errors[0] });
        } else {
          this.props.onUpdateComplete(data);
        }
        this.setState({ saving: false });
      },
      finally: () => {},
    });
  };

  onClickFaceSupported = (event, data) => {
    var device = this.state.device;
    device["FaceSupported"] = data.checked;
    this.setState({ device });
  };
  onClickPalmSupported = (event, data) => {
    var device = this.state.device;
    device["PalmSupported"] = data.checked;
    this.setState({ device });
  };

  onDeviceFunctionChange = (event, data) => {
    var device = this.state.device;
    device["DeviceFunction"] = data.label;
    this.setState({ device });
  };

  
  render() {
    return (
      <Modal open size="small">
        <Modal.Header>Modify Device</Modal.Header>
        <Modal.Content>
          <Container>
            <Form widths="equal">
              <Form.Group>
                <Form.Field
                  id="txtDeviceCode"
                  control={Input}
                  label="Device Code"
                  placeholder="Device Code"
                  value={this.state.device["DeviceCode"]}
                  onChange={this.handleChange("DeviceCode").bind(this)}
                />
                <Form.Field
                  id="txtDeviceName"
                  control={Input}
                  label="Device Name"
                  placeholder="Device Name"
                  value={this.state.device["DeviceName"]}
                  onChange={this.handleChange("DeviceName").bind(this)}
                />
              </Form.Group>
              <Form.Group>
                <Form.Field
                  id="txtSerialNumber"
                  control={Input}
                  label="Serial Number"
                  placeholder="Serial Number"
                  value={this.state.device["SerialNumber"]}
                  onChange={this.handleChange("SerialNumber").bind(this)}
                />
                <Form.Field
                  id="txtIPAddress"
                  control={Input}
                  label="IP Address"
                  placeholder="IP Address"
                  value={this.state.device["IPAddress"]}
                  //onChange = {(e) => handleIPAddress(e)}
                  onKeyPress={(e) => {
                    handleIPOnKeyPress(e);
                  }}
                  onKeyDown={(e) => {
                    handleNumericOnKeyDown(e);
                  }}
                  onChange={this.handleChange("IPAddress").bind(this)}
                />
              </Form.Group>
              <FormGroup>
                <Form.Field>
                  <label>
                    <b>Function</b>
                  </label>
                  <FormGroup inline={true}>
                    <FormRadio
                      label="Default"
                      defaultChecked={true}
                      checked={
                        this.state.device["DeviceFunction"] === "Default"
                      }
                      onChange={this.onDeviceFunctionChange}
                    />
                    <FormRadio
                      label="IN"
                      defaultChecked={true}
                      checked={this.state.device["DeviceFunction"] === "IN"}
                      onChange={this.onDeviceFunctionChange}
                    />
                    <FormRadio
                      label="OUT"
                      defaultChecked={true}
                      checked={this.state.device["DeviceFunction"] === "OUT"}
                      onChange={this.onDeviceFunctionChange}
                    />
                  </FormGroup>
                </Form.Field>
              </FormGroup>
              <div style={{ color: "red" }}>{this.state.errorMsg}</div>
            </Form>
          </Container>
        </Modal.Content>
        <Modal.Actions>
          <Button
            basic
            icon="save"
            content="Save"
            loading={this.state.saving}
            disabled={this.state.saving}
            onClick={this.onClickSave.bind(this)}
          ></Button>
          <Button
            basic
            icon="cancel"
            content="Cancel"
            onClick={this.props.onClose}
          ></Button>
        </Modal.Actions>
      </Modal>
    );
  }
}

export default DeviceLoad;

export function getDeviceInfoValue(devInfo, field){
    if( devInfo == null ){
        return null;
    }
    var startIndex = devInfo.indexOf(field + "=");
    if( startIndex != -1)
    {
        startIndex += field.length + 1;
        var splitIndex = devInfo.indexOf(",", startIndex);
        if( splitIndex != -1)
        {
            if( field === "FaceFunOn"){
                var xx = devInfo.substring(startIndex, splitIndex);    
                var abc = 0;
            }
            return devInfo.substring(startIndex, splitIndex);    
        }
        else
        {
            return devInfo.substring(startIndex);
        }
    }

    return null;
}

import React, { Component } from 'react';
import Select from 'react-select'
import { Icon, Input, Button, Table, Pagination } from 'semantic-ui-react';
import { ajaxPost } from '../../ajax';
import { handleNumericOnKeyPress, handleNumericOnKeyDown } from '../../utils';
import PdfMake from 'pdfmake/build/pdfmake';
import vfsFonts from 'pdfmake/build/vfs_fonts';

const moment = require('moment');

const quarterList = {
    1: 1, 2: 1, 3: 1, 4: 2, 5: 2, 6: 2, 7: 3, 8: 3, 9: 3, 10: 4, 11: 4, 12: 4
}
const quarterEnding = {
    1: '03', 2: '06', 3: '09', 4: '12'
}

export default class SSSR3 extends Component {
    constructor(props) {
        super(props);

        this.state = {
            selectedCompany: null,
            year: moment().format('YYYY'),
            quarter: quarterList[moment().format('M')],

            isCompanyLoading: false,
            isGenerateLoading: false,
            isExportLoading: false,

            activePage: 1,
            itemCount: 15,
            totalCount: 0,

            companyList: [],

            data: {
                EmployerNo: '',
                EmployerName: '',
                QuarterEnding: '',
                TelephoneNo: '',
                Address: '',
                ContributionList: []
            },
        }

        this.loadCompanies();
    }

    componentWillMount = () => {
        if (this.props.prevState !== null)
            this.state = this.props.prevState;
    }

    componentWillUnmount = () => {
        this.props.unmountCallback(this.state, 'SSSR3');
    }

    loadCompanies() {
        this.setState({ isCompanyLoading: true })
        ajaxPost({
            url: 'api/group/search',
            data: {
                Search: '',
                startingIndex: 0,
                itemCount: 999,
                Types: ['COMPANY']
            },
            onSuccess: (data) => {
                this.setState({
                    companyList: data.content.map(x => { return ({ label: x.Name, value: x._id }) }),
                    isCompanyLoading: false
                });
            },
            finally: () => { }
        })
    }

    checkStatus(processId, callback) {
        ajaxPost({
            data: { processId: processId },
            url: 'api/BackgroundProcess/GetStatus',
            onSuccess: data => {
                if (data.Status != "PROCESSING") {
                    callback(processId)
                }
                else {
                    this.checkStatus(processId, callback);
                }
            },
            finally: () => { }
        })
    }

    onExportPDFClick = () => {
        const { data, quarter, year, selectedCompany, isExportLoading } = this.state;
        if (isExportLoading)
            return;

        if (selectedCompany === null) {
            alert("Please select an employee");
            return;
        }

        if (year === '' || isNaN(year) || year > 9999 || year < 1990) {
            alert("Invalid value of year")
            return;
        }

        if (quarter === '' || isNaN(quarter) || quarter < 1 || quarter > 4) {
            alert("Invalid value of quarter")
            return;
        }

        if(this.state.totalCount === 0) {
            alert("No data to be exported")
            return;
        }

        this.setState({ isExportLoading: true });

        ajaxPost({
            url: 'api/governmentforms/SSSR3ExportToPDF',
            data: {},
            onSuccess: (img) => {
                const { vfs } = vfsFonts.pdfMake;
                PdfMake.vfs = vfs;

                var employerNo = data.EmployerNo.replace(/-/g, '');
                var qtrEnding = quarterEnding[quarter] + year;

                var indexes = [];
                var totalFirstQuarterAmountEE = 0;
                var totalSecondQuarterAmountEE = 0;
                var totalThirdQuarterAmountEE = 0;
                var totalFirstQuarterAmountEC = 0;
                var totalSecondQuarterAmountEC = 0;
                var totalThirdQuarterAmountEC = 0;
                for (var i = 0; i < Math.ceil(this.state.totalCount / 15); i++) {
                    var items = JSON.parse(JSON.stringify(data.ContributionList)).splice(i * 15, 15);
                    var subTotalFirstQuarterAmountEE = 0;
                    var subTotalSecondQuarterAmountEE = 0;
                    var subTotalThirdQuarterAmountEE = 0;
                    var subTotalFirstQuarterAmountEC = 0;
                    var subTotalSecondQuarterAmountEC = 0;
                    var subTotalThirdQuarterAmountEC = 0;
                    items.forEach(x => {
                        subTotalFirstQuarterAmountEE += x.FirstQuarterAmountEE;
                        subTotalSecondQuarterAmountEE += x.SecondQuarterAmountEE;
                        subTotalThirdQuarterAmountEE += x.ThirdQuarterAmountEE
                        subTotalFirstQuarterAmountEC += x.FirstQuarterAmountEC
                        subTotalSecondQuarterAmountEC += x.SecondQuarterAmountEC
                        subTotalThirdQuarterAmountEC += x.ThirdQuarterAmountEC
                    });

                    totalFirstQuarterAmountEE += subTotalFirstQuarterAmountEE;
                    totalSecondQuarterAmountEE += subTotalSecondQuarterAmountEE;
                    totalThirdQuarterAmountEE += subTotalThirdQuarterAmountEE
                    totalFirstQuarterAmountEC += subTotalFirstQuarterAmountEC
                    totalSecondQuarterAmountEC += subTotalSecondQuarterAmountEC
                    totalThirdQuarterAmountEC += subTotalThirdQuarterAmountEC

                    indexes.push({
                        content: items, subTotalFirstQuarterAmountEE, subTotalSecondQuarterAmountEE, subTotalThirdQuarterAmountEE,
                        subTotalFirstQuarterAmountEC, subTotalSecondQuarterAmountEC, subTotalThirdQuarterAmountEC
                    });
                }

                if (employerNo.length > 13)
                    employerNo = employerNo.substring(0, 13);

                var pdfData = {
                    pageSize: { width: 594, height: 846 },
                    pageOrientation: 'landscape',
                    background: [{
                        image: img.Image,
                        width: 766,
                        height: 514,
                        absolutePosition: { x: 45, y: 35 }
                    }],
                    content:
                        indexes.map((currPageItems, currPageIndex) => {
                            return ({
                                stack: [
                                    employerNo.split('').map((x, index) => {
                                        return (
                                            {
                                                text: x,
                                                style: { fontSize: 7 },
                                                absolutePosition: { x: 56 + index * 11.6, y: 109 }
                                            }
                                        )
                                    }),
                                    {
                                        text: data.EmployerName.toUpperCase(),
                                        style: { fontSize: 8 },
                                        absolutePosition: { x: 220, y: 109 }
                                    },
                                    qtrEnding.split('').map((x, index) => {
                                        return (
                                            {
                                                text: x,
                                                style: { fontSize: 7 },
                                                absolutePosition: { x: 731 + index * 11.6, y: 110 }
                                            }
                                        )
                                    }),
                                    {
                                        text: data.TelephoneNo,
                                        style: { fontSize: 8 },
                                        absolutePosition: { x: 57, y: 135 }
                                    },
                                    {
                                        text: data.Address.toUpperCase(),
                                        style: { fontSize: 8 },
                                        absolutePosition: { x: 220, y: 135 }
                                    },
                                    {
                                        text: '.',
                                        style: { fontSize: 48 },
                                        absolutePosition: { x: 645.4, y: 100 }
                                    },
                                    currPageItems.content.map((x, xIndex) => {
                                        return (
                                            {
                                                stack: [
                                                    (x.SssNumber.substring(0, 10).split('').map((y, yIndex) => {
                                                        return (
                                                            {
                                                                text: y,
                                                                style: { fontSize: 6 },
                                                                absolutePosition: { x: 56 + (yIndex * 11.6), y: 188 + (xIndex * 15.2 + (xIndex > 11 ? 1 : xIndex > 5 ? -1.3 : 0)) }
                                                            }
                                                        )
                                                    })),
                                                    {
                                                        text: x.LastName,
                                                        style: { fontSize: 6 },
                                                        absolutePosition: { x: 180, y: 188 + (xIndex * 15.2 + (xIndex > 11 ? 1 : xIndex > 5 ? -1.3 : 0)) }
                                                    },
                                                    {
                                                        text: x.FirstName,
                                                        style: { fontSize: 6 },
                                                        absolutePosition: { x: 260, y: 188 + (xIndex * 15.2 + (xIndex > 11 ? 1 : xIndex > 5 ? -1.3 : 0)) }
                                                    },
                                                    {
                                                        text: x.MiddleInitial,
                                                        style: { fontSize: 6 },
                                                        absolutePosition: { x: 370, y: 188 + (xIndex * 15.2 + (xIndex > 11 ? 1 : xIndex > 5 ? -1.3 : 0)) }
                                                    },
                                                    (Math.round(x.FirstQuarterAmountEE).toString().replace('.', '').split('').reverse().map((y, yIndex) => {
                                                        return (
                                                            {
                                                                text: y,
                                                                style: { fontSize: 6 },
                                                                absolutePosition: { x: 459 - (yIndex * 13), y: 188 + (xIndex * 15.2 + (xIndex > 11 ? 1 : xIndex > 5 ? -1.3 : 0)) }
                                                            }
                                                        )
                                                    })),
                                                    (Math.round(x.SecondQuarterAmountEE).toString().replace('.', '').split('').reverse().map((y, yIndex) => {
                                                        return (
                                                            {
                                                                text: y,
                                                                style: { fontSize: 6 },
                                                                absolutePosition: { x: 525 - (yIndex * 13), y: 188 + (xIndex * 15.2 + (xIndex > 11 ? 1 : xIndex > 5 ? -1.3 : 0)) }
                                                            }
                                                        )
                                                    })),
                                                    (Math.round(x.ThirdQuarterAmountEE).toString().replace('.', '').split('').reverse().map((y, yIndex) => {
                                                        return (
                                                            {
                                                                text: y,
                                                                style: { fontSize: 6 },
                                                                absolutePosition: { x: 589 - (yIndex * 13), y: 188 + (xIndex * 15.2 + (xIndex > 11 ? 1 : xIndex > 5 ? -1.3 : 0)) }
                                                            }
                                                        )
                                                    })),
                                                    (Math.round(x.FirstQuarterAmountEC).toString().split('').reverse().map((y, yIndex) => {
                                                        return (
                                                            {
                                                                text: y,
                                                                style: { fontSize: 6 },
                                                                absolutePosition: { x: 627 - (yIndex * 13), y: 188 + (xIndex * 15.2 + (xIndex > 11 ? 1 : xIndex > 5 ? -1.3 : 0)) }
                                                            }
                                                        )
                                                    })),
                                                    (Math.round(x.SecondQuarterAmountEC).toString().split('').reverse().map((y, yIndex) => {
                                                        return (
                                                            {
                                                                text: y,
                                                                style: { fontSize: 6 },
                                                                absolutePosition: { x: 662 - (yIndex * 13), y: 188 + (xIndex * 15.2 + (xIndex > 11 ? 1 : xIndex > 5 ? -1.3 : 0)) }
                                                            }
                                                        )
                                                    })),
                                                    (Math.round(x.ThirdQuarterAmountEC).toString().split('').reverse().map((y, yIndex) => {
                                                        return (
                                                            {
                                                                text: y,
                                                                style: { fontSize: 6 },
                                                                absolutePosition: { x: 697 - (yIndex * 13), y: 188 + (xIndex * 15.2 + (xIndex > 11 ? 1 : xIndex > 5 ? -1.3 : 0)) }
                                                            }
                                                        )
                                                    })),
                                                    (x.SeparationDate.split('').map((y, yIndex) => {
                                                        return (
                                                            {
                                                                text: y,
                                                                style: { fontSize: 6 },
                                                                absolutePosition: { x: 709 + (yIndex * 11.3), y: 188 + (xIndex * 15.2 + (xIndex > 11 ? 1 : xIndex > 5 ? -1.3 : 0)) }
                                                            }
                                                        )
                                                    })),
                                                ]
                                            }
                                        )

                                    }),
                                    currPageItems.subTotalFirstQuarterAmountEE.toFixed(2).replace('.', '').split('').reverse().map((x, xIndex) => {
                                        return (
                                            {
                                                text: x,
                                                style: { fontSize: 6 },
                                                absolutePosition: { x: 459 - (xIndex * 13), y: 415 }
                                            }
                                        )
                                    }),
                                    currPageItems.subTotalSecondQuarterAmountEE.toFixed(2).replace('.', '').split('').reverse().map((x, xIndex) => {
                                        return (
                                            {
                                                text: x,
                                                style: { fontSize: 6 },
                                                absolutePosition: { x: 525 - (xIndex * 13), y: 415 }
                                            }
                                        )
                                    }),
                                    currPageItems.subTotalThirdQuarterAmountEE.toFixed(2).replace('.', '').split('').reverse().map((x, xIndex) => {
                                        return (
                                            {
                                                text: x,
                                                style: { fontSize: 6 },
                                                absolutePosition: { x: 589 - (xIndex * 13), y: 415 }
                                            }
                                        )
                                    }),
                                    currPageItems.subTotalFirstQuarterAmountEC.toFixed().replace('.', '').split('').reverse().map((x, xIndex) => {
                                        return (
                                            {
                                                text: x,
                                                style: { fontSize: 6 },
                                                absolutePosition: { x: 627 - (xIndex * 11.3), y: 415 }
                                            }
                                        )
                                    }),
                                    currPageItems.subTotalSecondQuarterAmountEC.toFixed().replace('.', '').split('').reverse().map((x, xIndex) => {
                                        return (
                                            {
                                                text: x,
                                                style: { fontSize: 6 },
                                                absolutePosition: { x: 662 - (xIndex * 11.3), y: 415 }
                                            }
                                        )
                                    }),
                                    currPageItems.subTotalThirdQuarterAmountEC.toFixed().replace('.', '').split('').reverse().map((x, xIndex) => {
                                        return (
                                            {
                                                text: x,
                                                style: { fontSize: 6 },
                                                absolutePosition: { x: 697 - (xIndex * 11.3), y: 415 }
                                            }
                                        )
                                    }),
                                    {
                                        absolutePosition: { x: 90, y: 445 },
                                        table: {
                                            widths: [60, 53, 58],
                                            heights: 6.3,
                                            body:
                                                [[
                                                    {
                                                        text: totalFirstQuarterAmountEE.toFixed(2),
                                                        style: { fontSize: 6 },
                                                        alignment: 'right'
                                                    },
                                                    {
                                                        text: totalFirstQuarterAmountEC.toFixed(2),
                                                        style: { fontSize: 6 },
                                                        alignment: 'right'
                                                    },
                                                    {
                                                        text: (totalFirstQuarterAmountEE + totalFirstQuarterAmountEC).toFixed(2),
                                                        style: { fontSize: 6 },
                                                        alignment: 'right'
                                                    },
                                                ],
                                                [
                                                    {
                                                        text: totalSecondQuarterAmountEE.toFixed(2),
                                                        style: { fontSize: 6 },
                                                        alignment: 'right'
                                                    },
                                                    {
                                                        text: totalSecondQuarterAmountEC.toFixed(2),
                                                        style: { fontSize: 6 },
                                                        alignment: 'right'
                                                    },
                                                    {
                                                        text: (totalSecondQuarterAmountEE + totalSecondQuarterAmountEC).toFixed(2),
                                                        style: { fontSize: 6 },
                                                        alignment: 'right'
                                                    },
                                                ],
                                                [
                                                    {
                                                        text: totalThirdQuarterAmountEE.toFixed(2),
                                                        style: { fontSize: 6 },
                                                        alignment: 'right'
                                                    },


                                                    {
                                                        text: totalThirdQuarterAmountEC.toFixed(2),
                                                        style: { fontSize: 6 },
                                                        alignment: 'right'
                                                    },
                                                    {
                                                        text: (totalThirdQuarterAmountEE + totalThirdQuarterAmountEC).toFixed(2),
                                                        style: { fontSize: 6 },
                                                        alignment: 'right'
                                                    },
                                                ]],
                                        },
                                        layout: 'noBorders',

                                    },
                                    (currPageIndex + 1).toFixed().split('').reverse().map((x, xIndex) => {
                                        return (
                                            {
                                                text: x,
                                                style: { fontSize: 6 },
                                                absolutePosition: { x: 772 - (xIndex * 12.5), y: 430 },

                                            }
                                        )
                                    }),
                                    indexes.length.toFixed().split('').reverse().map((x, xIndex) => {
                                        return (
                                            {
                                                text: x,
                                                style: { fontSize: 6 },
                                                absolutePosition: { x: 772 - (xIndex * 12.5), y: 454 },
                                                pageBreak: (currPageIndex === indexes.length - 1 && (currPageIndex + 1).toFixed().split('').length !== xIndex - 1 ? '' : 'after')
                                            }
                                        )
                                    }),

                                ]
                            })
                        })
                }

                PdfMake.createPdf(pdfData).open();
            },
            finally: () => { this.setState({ isExportLoading: false }) }
        })

    }

    onGenerateClick = () => {
        if (this.state.isGenerateLoading)
            return;

        const { year, quarter } = this.state;
        if (this.state.selectedCompany === null) {
            alert("No Company to be Generated");
            return;
        }

        if (this.state.year === '' || isNaN(year) || year > 9999 || year < 1990) {
            alert("Invalid value of year")
            return;
        }

        if (this.state.quarter === '' || isNaN(quarter) || quarter < 1 || quarter > 4) {
            alert("Invalid value of quarter")
            return;
        }

        this.setState({ isGenerateLoading: true });

        this.generateSSSR3((processId) => {
            ajaxPost({
                url: 'api/governmentforms/GetSSSR3',
                data: processId,
                onSuccess: (data) => {
                    this.setState({ data, totalCount: data.ContributionList.length, isGenerateLoading: false });
                },
                finally: () => { }
            })
        })
    }

    generateSSSR3 = (callback) => {
        ajaxPost({
            url: 'api/governmentforms/GenerateSSSR3',
            data: {
                companyId: this.state.selectedCompany.value,
                year: this.state.year,
                quarter: this.state.quarter
            },
            onSuccess: (value) => {
                this.checkStatus(value, callback);
            },
            finally: () => { }
        })
    }

    handlePageChange(event, { activePage }) {
        var page = parseInt(activePage, 10);
        if (page < activePage) {
            page++;
        }
        this.setState({ activePage: page });
    }

    render() {
        return (
            <div>
                <h1>SSS R3 </h1>

                <div style={{ display: 'flex', overflowY: 'auto', height: window.innerHeight - 203 }}>
                    <div style={{ marginRight: '10px', width: 300 }}>
                        <div>
                            <label>Company</label>
                            <Select value={this.state.selectedCompany} isLoading={this.state.isCompanyLoading} options={this.state.companyList} onChange={(e) => this.setState({ selectedCompany: e })} />
                        </div>

                        <div style={{ marginTop: 5 }}>
                            <label>Year</label>
                            <Input fluid value={this.state.year}
                                onChange={(e) => this.setState({ year: e.target.value })}
                                onKeyPress={(e) => handleNumericOnKeyPress(e)}
                                onKeyDown={(e) => handleNumericOnKeyDown(e)}
                            />
                        </div>

                        <div style={{ marginTop: 5 }}>
                            <label>Quarter</label>
                            <Input fluid value={this.state.quarter}
                                onChange={(e) => this.setState({ quarter: e.target.value })}
                                onKeyPress={(e) => handleNumericOnKeyPress(e)}
                                onKeyDown={(e) => handleNumericOnKeyDown(e)}
                            />
                        </div>

                        <div style={{ display: 'flex', float: 'right', right: 0 }}>
                            <Button style={{ marginTop: 10 }} content='Generate' floated='right' loading={this.state.isGenerateLoading} onClick={this.onGenerateClick.bind(this)} />
                            <Button style={{ marginTop: 10 }} content='Export To PDF' floated='right' loading={this.state.isExportLoading} onClick={this.onExportPDFClick.bind(this)} />
                        </div>
                    </div>

                    <div style={{ width: window.innerWidth - 600, height: window.innerHeight - 203, overflowX: 'auto', overflowY: 'auto' }}>
                        <Pagination
                            activePage={this.state.activePage}
                            defaultActivePage={1}
                            pointing
                            secondary
                            //ellipsisItem={{ content: <Icon name='ellipsis horizontal' />, icon: true }}
                            firstItem={{ content: <Icon name='angle double left' />, icon: true }}
                            lastItem={{ content: <Icon name='angle double right' />, icon: true }}
                            prevItem={{ content: <Icon name='angle left' />, icon: true }}
                            nextItem={{ content: <Icon name='angle right' />, icon: true }}
                            size='mini'
                            siblingRange={2}
                            boundaryRange={0}
                            totalPages={Math.ceil(this.state.totalCount / this.state.itemCount)}
                            onPageChange={this.handlePageChange.bind(this)}
                            floated='right'
                        /><Table>
                            <Table.Header>
                                <Table.Row>
                                    <Table.Cell rowspan={2}>No.</Table.Cell>
                                    <Table.Cell rowspan={2}>Last Name</Table.Cell>
                                    <Table.Cell rowspan={2}>First Name</Table.Cell>
                                    <Table.Cell rowspan={2}>Middle Initial</Table.Cell>
                                    <Table.Cell colspan={3}>Social Security </Table.Cell>
                                    <Table.Cell colspan={3}>Employees Compensation </Table.Cell>
                                </Table.Row>
                                <Table.Row>
                                    <Table.Cell>1st Month </Table.Cell>
                                    <Table.Cell>2nd Month </Table.Cell>
                                    <Table.Cell>3rd Month </Table.Cell>
                                    <Table.Cell>1st Month </Table.Cell>
                                    <Table.Cell>2nd Month </Table.Cell>
                                    <Table.Cell>3rd Month </Table.Cell>
                                </Table.Row>

                            </Table.Header>
                            <Table.Header></Table.Header>
                            <Table.Body>
                                {JSON.parse(JSON.stringify(this.state.data.ContributionList)).splice((this.state.activePage - 1) * this.state.itemCount, this.state.itemCount).map(x => {
                                    return (
                                        <Table.Row>
                                            <Table.Cell>{x.PagibigNo}</Table.Cell>
                                            <Table.Cell>{x.LastName}</Table.Cell>
                                            <Table.Cell>{x.FirstName}</Table.Cell>
                                            <Table.Cell>{x.MiddleInitial}</Table.Cell>
                                            <Table.Cell>{x.FirstQuarterAmountEE.toFixed(2)}</Table.Cell>
                                            <Table.Cell>{x.SecondQuarterAmountEE.toFixed(2)}</Table.Cell>
                                            <Table.Cell>{x.ThirdQuarterAmountEE.toFixed(2)}</Table.Cell>
                                            <Table.Cell>{x.FirstQuarterAmountEC.toFixed(2)}</Table.Cell>
                                            <Table.Cell>{x.SecondQuarterAmountEC.toFixed(2)}</Table.Cell>
                                            <Table.Cell>{x.ThirdQuarterAmountEC.toFixed(2)}</Table.Cell>
                                        </Table.Row>
                                    )
                                })}

                            </Table.Body>
                        </Table>
                    </div>
                </div>
            </div>
        )
    }
}
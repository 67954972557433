import React, { Component } from 'react';
import { Image, Button } from 'semantic-ui-react';
import 'semantic-ui-css/semantic.min.css';
import TransactionModuleBase from './TransactionModuleBase';
import LeaveAdjustmentForm from './Forms/LeaveAdjustmentForm';
import { isTaskAvailable, checkDate, checkYear, checkInt32, momentDateFormat } from '../../utils';
import { SelectColumn } from 'react-data-grid';

var ViewID = "LeaveAdjustment";
var ViewCaption = "Leave Adjustment";

const moment = require('moment');
// const { Editors: { DropDownEditor } } = require('react-data-grid');
const userRightsKeys = {
	view: "LEAVE_BALANCE_ADJUSTMENT_VIEW",
	create: "LEAVE_BALANCE_ADJUSTMENT_CREATE",
	edit: "LEAVE_BALANCE_ADJUSTMENT_EDIT",
	delete: "LEAVE_BALANCE_ADJUSTMENT_DELETE",
	restore: "LEAVE_BALANCE_ADJUSTMENT_RESTORE",
	export: "LEAVE_BALANCE_ADJUSTMENT_EXPORT_TO_EXCEL",
	import: "LEAVE_BALANCE_ADJUSTMENT_IMPORT"
};

const leaveGrantType = ["Grants", "Deductions"];

class LeaveAdjustmentList extends Component {

	constructor(props) {
		super(props);
		this.state = {
			modalVisible: false
		}
		this.modalRef = React.createRef();
		this.parentRef = React.createRef();
	}

	createUrls = () => {
		return {
			search: "api/LeaveAdjustment/search",
			setDeleted: "api/LeaveAdjustment/setDeleted",
			saveMultiple: "api/LeaveAdjustment/saveMultiple",
			save: "api/LeaveAdjustment/save",
			load: "api/LeaveAdjustment/load",
			searchEmployees: "api/LeaveAdjustment/searchEmployees",
			exportToExcel: "api/LeaveAdjustment/exportToExcel",
			downloadTemplate: "api/LeaveAdjustment/downloadTemplate",
			importFromExcel: "api/LeaveAdjustment/importFromExcel"

		};
	}

	toRow = (model) => {
		return {
			id: model._id,
			leaveTypeId: model.LeaveTypeId,
			leaveTypeCode: model.LeaveTypeCode,
			employeeId: model.EmployeeId,
			deleted: model.IsDeleted === undefined ? false : model.IsDeleted,
			employee: model.Employee,
			date: moment(model.Date).format(momentDateFormat),
			leaveGrantType: model.LeaveGrantType,
			effectivityYear: Number(model.EffectivityYear),
			//count: model.Count === 0.5 ? "Half Day" : "Whole Day",
			count: model.Count.toFixed(2),
			remarks: model.Remarks,
			requestDate: moment(model.RequestDate).format(momentDateFormat),
			editable: false,
			idle: false
		};
	};

	toModel = (row) => {
		return {
			_id: row.id,
			EmployeeId: row.employeeId,
			LeaveTypeId: row.leaveTypeId,
			LeaveTypeCode: row.leaveTypeCode,
			Date: moment(row.date).toJSON(),
			LeaveGrantType: row.leaveGrantType,
			EffectivityYear: Number(row.effectivityYear),
			//Count : row.count === "Half Day" ? 0.5 : 1,
			Count: row.count,
			Remarks: row.remarks
		};
	};

	createColumns = () => {
		let self = this;
		let hasHrAccess = isTaskAvailable("HR_ROLE");
		let columns = [
			SelectColumn,
			{ key: 'date', name: 'Date', width: 90, resizable: true},
			{ key: 'leaveTypeCode', name: 'Code', width: 70, resizable: true },
			{ key: 'leaveGrantType', name: 'Grant Type', width: 110, resizable: true},
			{ key: 'effectivityYear', name: 'Eff. Year', width: 110, resizable: true},
			{ key: 'count', name: 'Day Count', width: 100, resizable: true},
			{ key: 'remarks', name: 'Reasons', resizable: true},
			{ key: 'requestDate', name: 'Date Filed', width: 90, resizable: true },
		];
		// if(viewType === "hr"){
		columns.unshift({
			key: 'employee', name: 'Employee', width: 220, resizable: true
		});
		// }

		return columns;
	}

	validate = (data) => {
		var errTitle = "", errCaption = "";
		var checkDateParam = checkDate(data.Date);
		var checkYearParam = checkYear(data.EffectivityYear, "Effectivity Year");
		var checkInt32DayCountParam = checkInt32(data.Count, false)

		if (this.isNullOrEmpty(data.employeeIds) ||
			this.isNullOrEmpty(data.Remarks) ||
			this.isNullOrEmpty(data.LeaveTypeId) ||
			data.Count === 0
		) {
			errCaption = "Please fill up all required field/s";
			errTitle = "Invalid";
		}


		else if (!checkDateParam.Result) {
			errCaption = checkDateParam.Message;
			errTitle = checkDateParam.Title;
		}
		// else if(this.isNullOrEmpty(data.LeaveTypeId)) {
		// 	errCaption = "Select Leave Type";
		// 	errTitle = "Invalid";
		// }
		else if (!checkYearParam.Result) {
			errCaption = checkYearParam.Message;
			errTitle = checkYearParam.Title;
		}
		else if (!checkInt32DayCountParam.Result) {
			errCaption = checkInt32DayCountParam.Message;
			errTitle = checkInt32DayCountParam.Title;
		}
		else if (Number(data.Count) <= 0) {
			errTitle = "Invalid"
			errCaption = "Day Count " + data.Count + " is not valid ";
		}
		else {
			this.parentRef.current.setState({ showRequiredField: false });
			return true;
		}
		this.parentRef.current.setState({ showRequiredField: true, errTitle, errCaption });
		return false;
	};

	showModal = (data, visible) => {
		visible && this.modalRef.current.setContent(data);
		this.setState({ modalVisible: visible });
	}

	isNullOrEmpty(val) {
		if (val === null || val === undefined || val === "" || val.length === 0) return true;
		else return false;
	}

	render() {
		let self = this;
		return (
			<TransactionModuleBase
				ref={this.parentRef}
				module="Leave Adjustments"
				viewID={ViewID}
				title={ViewCaption}
				columns={this.createColumns()}
				toModel={this.toModel}
				toRow={this.toRow}
				urls={this.createUrls()}
				validate={this.validate}
				props={this.props}
				showModal={this.showModal.bind(this)}
				userRightsKeys={userRightsKeys}
				hasStatusField={false}
				hasExportToExcel={true}
			>
					<LeaveAdjustmentForm
						ref={this.modalRef}
						open={this.state.modalVisible}
						errTitle={self.parentRef.current ? self.parentRef.current.state.errTitle : ""}
						errCaption={self.parentRef.current ? self.parentRef.current.state.errCaption : ""}
						showRequiredField={self.parentRef.current ? self.parentRef.current.state.showRequiredField : ""}
						updateEmployeeIds={(Ids) => { self.parentRef.current.updateEmployeeIds(Ids) }}
						isBusy={self.parentRef.current ? self.parentRef.current.state.isSaving : false}
						onCancel={() => { 
							this.setState({ modalVisible: false }, self.parentRef.current.setState({ showRequiredField: false }));
						 }}
						onCreateEmpClick={() => {
							this.setState({ modalVisible: false });
							this.props.createEmpCallback();
						}}
						onSave={() => {
							if (self.modalRef.current.state._id) {
								self.parentRef.current.editFromModal(
									self.modalRef.current.getContent()
								)
							}
							else {
								self.parentRef.current.saveFromModal(
									self.modalRef.current.getContent()
								);
							}
						}}
					>
					</LeaveAdjustmentForm>
			</TransactionModuleBase>
		);
	}
}

LeaveAdjustmentList.getViewID = () => { return ViewID };
LeaveAdjustmentList.getCaption = () => { return ViewCaption };
LeaveAdjustmentList.getIcon = () => { return (<Image style={{ "height": "24px", "width": "24px" }} src='/Icons/Leave_Adjustment.png' avatar />); }
LeaveAdjustmentList.getIconSrc = (size) => { return '/Icons/Leave_Adjustment.png' }
LeaveAdjustmentList.getViewAccessKey = function () {
	var count = Object.keys(userRightsKeys).filter(name => isTaskAvailable(userRightsKeys[name]) === true).length;
	return count > 0 ? true : false;
};

export default LeaveAdjustmentList;
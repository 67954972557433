import React, { Component } from 'react';
import { Menu, Button, Modal, Header, Loader, Image, Input, Dropdown, Checkbox, Message, Label } from 'semantic-ui-react';
import 'semantic-ui-css/semantic.min.css';
// import Select from 'react-select';
import moment from 'moment';
import { ajaxPost } from '../../ajax';
import { fnSearchEmployeeFilter, isTaskAvailable, empty_id, checkDate, checkTime } from '../../utils';
import SelectEmployees from '../../Commons/SelectEmployees';
import EmployeeFilterModal from '../../Commons/EmployeeFilterModal';
import {Calendar, momentLocalizer} from 'react-big-calendar'
import 'react-big-calendar/lib/css/react-big-calendar.css';


const localizer = momentLocalizer(moment)

// Calendar.setLocalizer(Calendar.momentLocalizer(moment));

function selectionList(empList, fields, value) {
    var list = [];
    if (empList.content != null) {
        empList.content.map(x => {
            return (
                list.push({ text: fields.map(z => { return (x[z] + " ") }), value: x[value] }))
        })
    }
    return list;
}
class Holiday extends Component {
    state = {
        initialLoad: true,
        ToDate: new Date(),
        FromDate: new Date(),
        currentDate: new Date(),
        currentMonth: "",
        Holidays: [],
        Holiday: {
            _id: empty_id,
            RecordDate: moment(new Date()).format("YYYY-MM-DD"),
            HolidayName: "",
            HolidayType: "HOLIDAY",
            StartTime: moment().format("HH:mm"),
            EndTime: moment().format("HH:mm"),
            IsUniversal: true,
            HolidayMembers: [],
            CityId: empty_id,
        },
        until: false,
        dialog: false,
        deletedialog: false,
        disabled: true,
        searchQuery: '',
        Employeelist: [],
        cityList: [],
        showConflict: false,
        conflict: [],
        UserCanAdd: false,
        UserCanEdit: false,
        UserCanDelete: false,
        CanEdit: false,
        CanDelete: false,
        IsShowError: false,
        isFilterActive: false,
        advanceFilter: {
            employeeIds: [],
            companyIds: [],
            divisionIds: [],
            departmentIds: [],
            locationIds: [],
            positionIds: []
        },
        hasAdvanceFilter: false,
    };
    constructor(props) {
        super(props);
        var monthData = moment().format("YYYY-MM");
        var daysInMonth = moment(monthData, "YYYY-MM").daysInMonth();
        var startOfMonth = moment(monthData + "-01").format("YYYY-MM-DD");
        var endOfMonth = monthData + "-" + daysInMonth;

        this.state.FromDate = startOfMonth;
        this.state.ToDate = endOfMonth;
        this.state.currentDate = new Date(startOfMonth);
        this.state.currentMonth = moment(this.state.currentDate).format("MMMM YYYY");
        this.selectEmployeeRef = React.createRef();
    }

    componentWillMount() {
        if (this.props.prevState != null)
            this.state = this.props.prevState;
        else {
            if (this.state.initialLoad === true) {
                var fromDate = this.state.FromDate;
                var toDate = this.state.ToDate;
                this.setState({ initialLoad: false });
                this.setState({ loading: true });
                this.LoadHoliday(data => {
                    this.mapData(data.content);
                    this.setState({ loading: false });

                }, fromDate, toDate);
            }

        }
        fnSearchEmployeeFilter("", data => { this.setState({ Employeelist: data }); }, 0, 1000, false)
        this.loadCityList("");
        var canAdd = !isTaskAvailable("HOLIDAY_CREATE");
        var canEdit = !isTaskAvailable("HOLIDAY_MODIFY");
        var canDelete = !isTaskAvailable("HOLIDAY_DELETE");
        if (this.state.disabled) {
            canEdit = canEdit ? canEdit : this.state.disabled;
            canDelete = canDelete ? canDelete : this.state.disabled;
        }

        this.setState({
            UserCanAdd: canAdd,
            CanEdit: !isTaskAvailable("HOLIDAY_MODIFY"),
            CanDelete: !isTaskAvailable("HOLIDAY_DELETE"),
            UserCanEdit: canEdit,
            UserCanDelete: canDelete
        });
    }
    componentWillUnmount = () => {
        this.props.unmountCallback(this.state, "HolidayManagement");
    }
    mapData = (data) => {
        var list = this.state.Holidays;

        data.forEach(holiday => {
            this.AddData(list, holiday);
        });

        this.setState({ Holidays: list });
    }
    AddData = (list, holiday) => {
        var type = holiday.HolidayType === "HOLIDAY" ? "(HD) - " :
            holiday.HolidayType === "SPECIAL" ? "(SD) - " : "SUSP - "
        var title = type + holiday.HolidayName;
        list.push({
            _id: holiday._id,
            RecordDate: moment(holiday.RecordDate).format("YYYY-MM-DD"),
            HolidayName: holiday.HolidayName,
            HolidayType: holiday.HolidayType,
            StartTime: moment(holiday.StartTime).format("HH:mm"),
            EndTime: moment(holiday.EndTime).format("HH:mm"),
            IsUniversal: holiday.IsUniversal,
            HolidayMembers: holiday.HolidayMembers,
            CityId: holiday.CityId,
            start: holiday.RecordDate,
            end: holiday.RecordDate,
            title: title,
        });
    }
    LoadHoliday = (callback, FromDate, ToDate) => {


        ajaxPost({
            url: 'api/Holiday/search',
            data: {
                "fromDate": FromDate,
                "toDate": ToDate,
            },
            onSuccess: data => {
                callback(data);
            },
            finally: () => { }
        })
    }
    loadCityList = () => {
        this.setState({
            cityList: [],
        })
        ajaxPost({
            url: 'api/Group/search',
            data: {
                Search: "",
                includeDeleted: false,
                startingIndex: 0,
                itemCount: 1000,
                Types: ["CITY"]
            },
            onSuccess: data => {
                this.setState({ cityList: data });
            },
            finally: () => {
                this.setState({ isCityLoading: false });
            }
        })
    }
    SaveHoliday = (callback, model) => {

        ajaxPost({
            url: 'api/Holiday/save',
            data: {
                "model": JSON.stringify(model),
            },
            onSuccess: data => {
                callback(data);
            },
            onError: function (err) {
                alert(JSON.stringify(err));
            },
            finally: () => { this.setState({ saving: false }); }
        })
    }
    DeleteHoliday = (id) => {


        ajaxPost({
            url: 'api/Holiday/setDeleted',
            data: {
                "_id": id,
                "IsDeleted": true,
            },
            onSuccess: data => {
                var list = this.state.Holidays;
                list = list.filter(item => item._id !== id);
                this.setState({ Holidays: list });
            },
            finally: () => { }
        })
    }

    onEventResize = (type, { event, start, end, allDay }) => {
        this.setState(state => {
            state.events[0].start = start;
            state.events[0].end = end;
            return { events: state.events };
        });
    };
    onEventDrop = ({ event, start, end, allDay }) => {
        console.log(start);
    };
    onSelectSlot = (event) => {
        if (event.action === "doubleClick") {
            if (this.state.UserCanAdd)
                return;
            var holiday = {
                _id: empty_id,
                RecordDate: moment(event.start.toLocaleString()).format("YYYY-MM-DD"),
                HolidayName: "",
                HolidayType: "HOLIDAY",
                StartTime: moment().format("HH:mm"),
                EndTime: "00:00",
                IsUniversal: true,
                HolidayMembers: [],
                CityId: empty_id
            }
            this.setState({ Holiday: holiday });
            this.setState({ dialog: true });
            if (!this.state.CanEdit)
                this.setState({ UserCanEdit: true });
            if (!this.state.CanDelete)
                this.setState({ UserCanDelete: true });
        }
    }
    onDoubleClickEvent = (event) => {
        if (this.state.CanEdit)
            return;
        var holiday = this.state.Holiday;
        this.setState(Object.assign(this.employeeFilterDefaults(), { Holiday: holiday, dialog: true }));
    }
    onSelectEvent = (event) => {
        this.setState({ Holiday: event });
        if (!this.state.CanEdit)
            this.setState({ UserCanEdit: false });
        if (!this.state.CanDelete)
            this.setState({ UserCanDelete: false });


    }
    handleView = () => {

    }
    onNavigate = (date) => {
        this.setState({ date })
    }
    handleAdd = (event) => {
        var holday = moment(new Date()).format("YYYY-MM-DD");
        var holiday = {
            _id: empty_id,
            RecordDate: holday,
            HolidayName: "",
            HolidayType: "HOLIDAY",
            StartTime: moment().format("HH:mm"),
            EndTime: moment(holday + " 12:00 AM").format("HH:mm"),
            IsUniversal: true,
            HolidayMembers: [],
            CityId: empty_id
        }
        this.setState(Object.assign(this.employeeFilterDefaults(), { Holiday: holiday, dialog: true }));
    }
    handleEdit = (event) => {
        var holiday = this.state.Holiday;
        this.setState(Object.assign(this.employeeFilterDefaults(), { Holiday: holiday, dialog: true }));
    }
    handleDelete = (event) => {
        var holiday = this.state.Holiday;
        this.setState({ Holiday: holiday, deletedialog: true });
    }

    handleClick = (val, event) => {

        var currentDate = this.state.currentDate;
        currentDate = moment(currentDate).add(val, 'M');
        var curentMonth = moment(currentDate).format("MMMM YYYY");
        var monthData = moment(currentDate).format("YYYY-MM");
        var daysInMonth = moment(monthData, "YYYY-MM").daysInMonth();
        var endOfMonth = monthData + "-" + daysInMonth;
        var fromDate = currentDate;
        var toDate = endOfMonth;
        this.setState({ currentDate: new Date(currentDate) });
        this.setState({ currentMonth: curentMonth });
        this.setState({ FromDate: fromDate });
        this.setState({ ToDate: toDate });
        this.LoadHoliday(data => {
            this.setState({ Holidays: [] });
            this.mapData(data.content);
            this.setState({ loading: false });
            this.setState({ UserCanEdit: true });
            this.setState({ UserCanDelete: true });
        }, fromDate, toDate);
    }

    handleChange = name => event => {
        var current = this.state.Holiday;
        current[name] = event.target.value;
        this.setState({ Holiday: current });
    };
    updateEmployeeIds = (ids) => {
        var current = this.state.Holiday;
        current.HolidayMembers = ids;
        this.setState({ Holiday: current })
    }
    handleCheckChange = name => (e) => {
        var current = this.state.Holiday;
        current[name] = current[name] === false ? true : false;
        this.setState({ Holiday: current });
    }
    handleComboChange = (name, val) => {
        var current = this.state.Holiday;
        current[val.name] = val.value;
        this.setState({ Holiday: current });
    }

    onDialogClose = (saving) => {
        let self = this;
        if (saving) {
            let IsError = false;
            var conflicts = [];
            //check nullOrEmpty
            if (!checkDate(this.state.Holiday.RecordDate).Result || this.state.Holiday.RecordDate == "") {
                conflicts.push("RecordDate required!");
            }
            if (this.state.Holiday.HolidayName == null || this.state.Holiday.HolidayName == "") {
                conflicts.push("Holiday Name required!");
            }
            if (this.state.Holiday.HolidayType == null || this.state.Holiday.HolidayType == "") {
                conflicts.push("Holiday Type required!");
            }
            if (this.state.hasAdvanceFilter && this.state.Holiday.HolidayMembers.length === 0 &&
                (this.state.includedEmpIds === null || this.state.includedEmpIds.length === 0)) {
                conflicts.push("Selected employee filter has no result. Select new filter or clear current selections.");
            }
            if (conflicts.length > 0) {
                this.setState({ conflict: conflicts, IsShowError: true, showConflict: true });
                return;
            }

            this.setState({ IsShowError: false });

            if (!this.state.until)
                this.state.Holiday.EndTime = "00:00";
            if (this.state.Holiday.HolidayType != "SUSPENDED") {
                this.state.Holiday.StartTime = "00:00";
                this.state.Holiday.EndTime = "00:00";;
            } else {
                // if(!checkTime(this.state.Holiday.StartTime).Result)
                //     this.setState({IsShowError:true});
                // if(this.state.until && !checkTime(this.state.Holiday.EndTime).Result)
                //     this.setState({IsShowError:true});
            }

            if (this.state.Holiday.StartTime != null) {
                if (this.state.Holiday.StartTime != "")
                    this.state.Holiday.StartTime = moment(self.state.Holiday.RecordDate + " " + this.state.Holiday.StartTime).format("HH:mm");
            } else {
                this.state.Holiday.StartTime = null;
            }
            if (this.state.Holiday.EndTime != null) {
                if (this.state.Holiday.EndTime != "")
                    this.state.Holiday.EndTime = moment(self.state.Holiday.RecordDate + " " + this.state.Holiday.EndTime).format("HH:mm");
            } else {
                this.state.Holiday.EndTime = "00:00";;
            }
            // this.state.Holiday.StartTime = this.state.Holiday.StartTime != null || this.state.Holiday.StartTime != "" ? moment(self.state.Holiday.RecordDate + " " + this.state.Holiday.StartTime).format("HH:mm") : null;
            //  this.state.Holiday.EndTime = this.state.Holiday.EndTime != null || this.state.Holiday.EndTime !="" ? moment(self.state.Holiday.RecordDate + " " + this.state.Holiday.EndTime).format("HH:mm") : null;
            var model = this.state.Holiday;
            if (this.state.hasAdvanceFilter) {
                var selectedEmpIds = [];
                if (model.HolidayMembers !== null && model.HolidayMembers.length > 0) {
                    model.HolidayMembers.map(x => selectedEmpIds.push(x));
                }
                else if (this.state.includedEmpIds !== null && this.state.includedEmpIds.length > 0) {
                    this.state.includedEmpIds.map(x => selectedEmpIds.push(x));
                }
                model.HolidayMembers = selectedEmpIds;
            }
            if (!IsError) {
                this.SaveHoliday(data => {
                    if (data.content != null) {
                        var list = this.state.Holidays;
                        list = list.filter(item => item._id !== data.content._id);
                        this.AddData(list, data.content);
                        this.setState({ Holidays: list });
                        this.setState({ dialog: false });
                    } else if (data.errors != null) {
                        if (data.errors.length != 0) {
                            this.setState({ showConflict: true });
                            this.setState({ conflict: data.errors });
                        }
                    }

                }, model);
            }
        } else {

            this.setState({ dialog: false, conflict: [], showConflict: false });
        }
    }
    onDeleteDialogClose = (deleting) => {
        this.setState({ deletedialog: false });
        if (deleting) {
            if (!this.state.CanEdit)
                this.setState({ UserCanEdit: true });
            if (!this.state.CanDelete)
                this.setState({ UserCanDelete: true });
            var id = this.state.Holiday._id;
            this.DeleteHoliday(id);
        }
    }

    employeeFilterDefaults = () => {
        return {
            isFilterActive: false,
            advanceFilter: {
                employeeIds: [],
                companyIds: [],
                divisionIds: [],
                departmentIds: [],
                locationIds: [],
                positionIds: []
            },
            hasAdvanceFilter: false
        };
    }

    onViewFilterClick(isOpen) {
        this.setState({ isFilterActive: isOpen });
    }

    advanceFilterCallBack = (advanceFilter) => {
        const self = this;
        var hasAdvFilter = true;

        const callback = (disableIncludedEmployees) => {
            if (self.selectEmployeeRef && self.selectEmployeeRef.current) {
                self.selectEmployeeRef.current.updateEmployeeOption("", disableIncludedEmployees);
            }
        }

        if ((advanceFilter.companyIds === undefined || advanceFilter.companyIds === null || advanceFilter.companyIds.length == 0) &&
            (advanceFilter.departmentIds === undefined || advanceFilter.departmentIds === null || advanceFilter.departmentIds.length == 0) &&
            (advanceFilter.locationIds === undefined || advanceFilter.locationIds === null || advanceFilter.locationIds.length == 0) &&
            (advanceFilter.positionIds === undefined || advanceFilter.positionIds === null || advanceFilter.positionIds.length == 0))
            hasAdvFilter = false

        this.setState({
            advanceFilter: advanceFilter,
            hasAdvanceFilter: hasAdvFilter,
            includedEmpIds: null
        }, () => {
            if (hasAdvFilter) {
                let selectedEmpIds = [];
                const holiday = self.state.Holiday;
                if (!(holiday == null || holiday === undefined)) {
                    selectedEmpIds = holiday.HolidayMembers === null || holiday.HolidayMembers === undefined ? [] : holiday.HolidayMembers;
                }
                self.onAdvanceEmployeeSearchFilter(0, selectedEmpIds, callback);
            }
            else {
                self.setState({ isFilterActive: false }, () => {
                    callback(true); // disableIncludedEmployees override props to solve problem regarding selectemployee props
                });
            }
        });
    }

    onAdvanceEmployeeSearchFilter(startIndex, excludedIds, callback) {
        var { advanceFilter } = this.state;
        var totalCount = 0;
        var employees = [];
        ajaxPost({
            url: "api/Employee/searchFilteredEmployee",
            data: {
                "Search": "",
                "includeDeleted": false,
                "startingIndex": startIndex,
                "itemCount": 1000,
                "excludedEmployeeIds": excludedIds,
                "CompanyIDs": advanceFilter.companyIds,
                "DivisionIDs": advanceFilter.divisionIds,
                "DepartmentIDs": advanceFilter.departmentIds,
                "PositionIDs": advanceFilter.positionIds,
                "CityIDs": advanceFilter.locationIds,
            },
            onSuccess: data => {
                totalCount = data.count;
                employees = data.content;

                var includedEmpIds = employees != null ? employees.map(x => x._id) : null;
                this.setState({ includedEmpIds });
            },
            finally: () => {
                this.setState({ isFilterActive: false }, callback(false));
            }
        });
    }


    render() {
        const holidays = [
            { key: 1, text: 'LEGAL HOLIDAY', value: "HOLIDAY" },
            { key: 2, text: 'SPECIAL HOLIDAY', value: "SPECIAL" },
            { key: 3, text: 'SUSPENDED', value: "SUSPENDED" }
        ];
        let self = this;
        return (
            <div>
                <div style={{ color: "#606060", marginTop: '1%' }}>
                    <h2>Holidays</h2>
                </div>
                <Menu size="mini" secondary style={{ overflow: 'hidden', width: '100%' }} stackable>
                    {!this.state.UserCanAdd &&
                        <Button size="mini" icon="add" content="Create New"
                            disabled={this.state.UserCanAdd}
                            onClick={this.handleAdd.bind(this)} />
                    }
                    {!this.state.CanEdit && <Button size="mini" icon="edit" content="Edit"
                        disabled={this.state.UserCanEdit}
                        onClick={this.handleEdit.bind(this)} />
                    }
                    {!this.state.CanDelete && <Button size="mini" icon="delete" content="Delete"
                        disabled={this.state.UserCanDelete}
                        onClick={this.handleDelete.bind(this)} />
                    }
                    <Menu.Menu position="right">
                        <Button.Group>
                            <Button basic icon="angle left" size="mini"
                                onClick={this.handleClick.bind(this, -1)} />
                            <Button basic width="200">
                                {this.state.currentMonth}
                            </Button>
                            <Button basic icon="angle right" size="mini"
                                onClick={this.handleClick.bind(this, 1)} />
                        </Button.Group>
                    </Menu.Menu>
                </Menu>
                <Loader active={this.state.loading} />
                <Calendar
                    localizer={localizer}
                    date={this.state.currentDate}
                    defaultDate={new Date()}
                    selectable
                    view="month"
                    toolbar={false}
                    events={this.state.Holidays}
                    onEventDrop={this.onEventDrop}
                    onEventResize={this.onEventResthisthisthisize}
                    onSelectEvent={this.onSelectEvent.bind(this)}
                    onDoubleClickEvent={this.onDoubleClickEvent.bind(this)}
                    onNavigate={this.onNavigate.bind(this)}
                    onView={this.handleView.bind(this)}
                    onSelectSlot={this.onSelectSlot.bind(this)}
                    style={{ height: "70vh" }}
                />
                {/* Modal Form */}
                {
                    this.state.dialog && <Modal size="tiny" open={true} closeOnDimmerClick={false} onClose={this.onDialogClose.bind(this, false)}>
                        <Header content="Holiday" />
                        <Modal.Content>

                            <div style={{ color: "#606060", marginTop: '1%' }}>
                                <h3>Holiday Details</h3>
                            </div>
                            {this.state.showConflict &&
                                <Message negative>
                                    <Message.Header>Conflict !</Message.Header>
                                    <Message.Content>{this.state.conflict.map(n => {
                                        return (<p>{n}</p>)
                                    })}</Message.Content>
                                </Message>}
                            <div>
                                <div>
                                    <label>Date</label> <br />
                                    <Input type="date" fluid placeholder='Holiday Date'
                                        value={this.state.Holiday.RecordDate}
                                        error={this.state.IsShowError && !checkDate(this.state.Holiday.RecordDate).Result}
                                        onChange={this.handleChange("RecordDate").bind(this)} />
                                </div>
                                <div>
                                    <label>Title</label><br />
                                    <Input fluid placeholder='Holiday Name'
                                        value={this.state.Holiday.HolidayName}
                                        error={this.state.IsShowError && this.state.Holiday.HolidayName == ""}
                                        onChange={this.handleChange("HolidayName").bind(this)} />

                                </div>
                                <div>
                                    <label>Holiday Type</label> <br />
                                    <Dropdown fluid selection options={holidays} placeholder="Holiday Type"
                                        value={this.state.Holiday.HolidayType} name="HolidayType"
                                        onChange={this.handleComboChange.bind(this)} />

                                </div>


                                {this.state.Holiday.HolidayType == "SUSPENDED" &&
                                    <div className="ui grid" style={{ "margin": "0px", "padding": "0px" }}>
                                        <div className="left floated two wide column">
                                            <div style={{ "margin": "10px 0px 0px 0px" }}>
                                                <label >as of</label>
                                            </div>
                                        </div>
                                        <div className="six wide column">
                                            <Input fluid type="time" value={this.state.Holiday.StartTime}
                                                error={this.state.IsShowError && !checkTime(this.state.Holiday.StartTime).Result}
                                                onChange={this.handleChange("StartTime").bind(this)} />
                                        </div>
                                        <div className="two wide column">
                                            <Checkbox fluid
                                                style={{ marginTop: 10 }}
                                                label="until"
                                                checked={this.state.until}
                                                onChange={(event, data) => { self.setState({ until: data.checked }); }}
                                            />
                                        </div>
                                        <div className="right floated six wide column">
                                            <Input fluid type="time" value={this.state.Holiday.EndTime}
                                                disabled={!this.state.until}
                                                error={this.state.IsShowError && !checkTime(this.state.Holiday.EndTime).Result}
                                                onChange={this.handleChange("EndTime").bind(this)} />
                                        </div>
                                    </div>
                                }
                                <div >
                                    <Checkbox toggle fluid label="Universal"
                                        style={{ margin: "20px 20px 20px 0" }}
                                        checked={this.state.Holiday.IsUniversal}
                                        onClick={this.handleCheckChange("IsUniversal").bind(this)} />
                                </div>
                                {this.state.Holiday.IsUniversal === false &&
                                    <div>
                                        <label> City </label>
                                        <Dropdown fluid selection search
                                            placeholder="Select City"
                                            value={this.state.Holiday.CityId}
                                            options={selectionList(this.state.cityList, ["Name"], "_id")}
                                            name="CityId"
                                            onChange={this.handleComboChange.bind(this)}
                                        />
                                    </div>
                                }
                                {
                                    this.state.Holiday.IsUniversal === false &&
                                    <div>
                                        <label>Employee</label><br />
                                        <div style={{ display: 'flex', justifyContent: 'flex-end', width: '100%' }}>
                                            <div style={{ width: '100%' }}>
                                                <SelectEmployees
                                                    ref={this.selectEmployeeRef}
                                                    includedEmpIds={this.state.hasAdvanceFilter ? this.state.includedEmpIds : null}
                                                    Ids={this.state.Holiday.HolidayMembers}
                                                    update={this.updateEmployeeIds.bind(this)}
                                                />
                                            </div>
                                            <Button size="mini" icon="filter" onClick={this.onViewFilterClick.bind(this, true)} style={{ 'max-height': '31px', marginLeft: '6px' }} />
                                        </div>
                                    </div>
                                }

                                {
                                    this.state.isFilterActive &&
                                    <EmployeeFilterModal
                                        advFilter={this.state.advanceFilter}
                                        open={this.state.isFilterActive}
                                        onCancel={this.onViewFilterClick.bind(this, false)}
                                        callback={this.advanceFilterCallBack.bind(this)}
                                        action="OK"
                                        hideFilter={["EMPLOYEE", "STATUS"]}
                                    />
                                }

                            </div>
                        </Modal.Content>
                        <Modal.Actions>
                            <Button basic icon="save" content="Save"
                                onClick={this.onDialogClose.bind(this, true)} />
                            <Button basic icon="cancel" content="Cancel"
                                onClick={this.onDialogClose.bind(this, false)} />
                        </Modal.Actions>
                    </Modal>}
                {this.state.deletedialog && <Modal size="mini" open={true} onClose={this.onDeleteDialogClose.bind(this, false)}>
                    <Header content="Delete Holiday" />
                    <Modal.Content>
                        <div style={{ color: "#606060", marginTop: '1%' }}>
                            <p>Are you sure you want to delete Holiday?</p>
                        </div>
                    </Modal.Content>
                    <Modal.Actions>
                        <Button basic icon="check" content="Yes"
                            onClick={this.onDeleteDialogClose.bind(this, true)} />
                        <Button basic icon="cancel" content="No"
                            onClick={this.onDeleteDialogClose.bind(this, false)} />
                    </Modal.Actions>
                </Modal>}
            </div>
        );
    }
}
Holiday.getViewID = () => { return "HolidayManagement" };
Holiday.getCaption = () => { return "Holiday Management" }
Holiday.getViewAccessKey = () => { return isTaskAvailable("HOLIDAY_VIEW") || isTaskAvailable("HOLIDAY_CREATE") || isTaskAvailable("HOLIDAY_MODIFY") || isTaskAvailable("HOLIDAY_DELETE") ? true : false }
Holiday.getIcon = () => {
    return (<Image style={{ "height": "24px", "width": "24px" }} src='/Icons/Holiday.png' avatar />);
}
Holiday.getIconSrc = (size) => //size values: small (50x50), medium(150x150), large(300x300)
{
    return '/Icons/Holiday.png'
}
export default Holiday
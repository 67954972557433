import React, { useEffect, useState } from "react";
import { Button, Table, Modal, Icon, Menu, Pagination, Popup, Input, Image, Tab, Dropdown, DropdownMenu } from 'semantic-ui-react';
import styles from './PieceWork.module.css'
import SelectEmployees from '../../Commons/SelectEmployees';
import moment from "moment";
import { authDelete, authPost } from "../../ajax";
import ErrorMessage from '../../Commons/ErrorMessage';
import MessageBoxOkOnly from "../../Commons/MessageBoxOkOnly";
import MessageBoxYesNo from "../../Commons/MessageBoxYesNo";
import { isTaskAvailable } from '../../utils';

const momentFormat = "YYYY-MM-DD";
const searchModel ={
    StartDate : moment().format(momentFormat).toString(),
    EndDate : moment().format(momentFormat).toString(),
    EmployeeIds : [],
    Skip : 0,
    Take : 10,
}
const initialStatePieceWork = {
    Id : "",
    EmployeeIds : [],
    Employee : "",
    Date : moment().format(momentFormat).toString(),
    RecordDate : moment().format(momentFormat).toString(),
    Remarks : "",
    PieceRates : []
}
const initialStatePieceRate = {
    Id : "00000000-0000-0000-0000-000000000000",
    PieceWorkId : "00000000-0000-0000-0000-000000000000",
    PieceRateTypeId : "00000000-0000-0000-0000-000000000000",
    Quantity : 0,
    Total : 0.00,
    IsDeleted : false
}
const msgBoxModel = {
    Title : "",
    Message : ""
}
const errorModel = {
    errTitle : "",
    errCaption : ""
}

export default function PieceWork(){
    //User Rights
    const [canAdd, setCanAdd] = useState(false);
    const [canEdit, setCanEdit] = useState(false);
    const [canDelete, setCanDelete] = useState(false);

    //Map Controls
    const [pieceWork, setPiecework] = useState({
        Id : "",
        EmployeeIds : [],
        Employee : "",
        Date : moment().format(momentFormat).toString(),
        RecordDate : moment().format(momentFormat).toString(),
        Remarks : "",
        PieceRates : []
    });
    const [pieceWorkList, setPieceWorkList] = useState([]);
    const [pieceRateList, setPieceRateList] = useState([]);
    const [pieceWorkTypeList, setPieceWorkTypeList] = useState([]);

    //Search Controls
    const [searchParam, setSearchParam] = useState(searchModel);
    const [activePage, setIsActivePage] = useState(1);
    const [totalPages, setIsTotalPages] = useState(0);

    //Delete Controls
    const [selectedId, setSelectedId] = useState();

    //UI Controls
    const [isPopUp, setIsPopUp] = useState(false);
    const [isMsgOk, setIsMsgOk] = useState(false);
    const [isMsgYesNo, setIsMsgYesNo] = useState(false);
    const [msgBox, setMsgBox] = useState(msgBoxModel);
    const [isError, setIsError] = useState(false);
    const [errModel, setErrModel] = useState(errorModel);

    //State Change
    const togglePopAdd = () => {
        setPiecework({...initialStatePieceWork});
        setPieceRateList([{...initialStatePieceRate}]);
        setIsError(false);
        setIsPopUp(true);
    }

    const handleDateChange = (e) =>{
        const name = e.target.name;
        const value = e.target.value;
        setSearchParam(prevState =>({ 
            ...prevState,
            [name]: value
        }));
    }
    const handlePieceWorkChange = (e) => {
        const name = e.target.name;
        const value = e.target.value;
        setPiecework(prevState => ({
            ...prevState,
            [name] : value
        }));
    }
    const handlePieceRateChange = index => e =>{
        const value = e.target.value;
        let newArr = [...pieceRateList];
        newArr[index].Quantity = value;
        newArr[index].Total = value * newArr[index].RatePerUnit;
        setPieceRateList(newArr);
    }
    const AddPieceRateRow = () =>{
        setPieceRateList([...pieceRateList, {...initialStatePieceRate}]);
    }
    const onPageChange = (currentPage) =>{
        setIsActivePage(currentPage);
    }
    const onRateTypeChange = (e , index) =>{
        var id = e.target.id;
        var selected = pieceWorkTypeList.filter(x=>x.id === id);
        let newArr = [...pieceRateList];
        newArr[index].PieceRateTypeId = selected[0].id;
        newArr[index].RatePerUnit = selected[0].RatePerUnit;
        newArr[index].UnitOfMeasurement = selected[0].UnitOfMeasurement;
        setPieceRateList(newArr);
    }

    //UI Change Controls
    const onEdit = async (data) =>{
        setPiecework(prevState =>({
            ...prevState,
            Id : data.id,
            EmployeeIds : [data.employeeId],
            Employee : data.employee,
            Date : data.date,
        }));
        setPieceRateList([]);
        data.pieceRates.map((x) =>{
            setPieceRateList(prevState => [
                ...prevState,
                {
                    Id : x.id,
                    PieceWorkId : x.pieceWorkId,
                    PieceRateTypeId : x.pieceRateTypeId,
                    Quantity : x.quantity,
                    RatePerUnit : x.ratePerUnit,
                    Total : x.total,
                    UnitOfMeasurement : x.unitOfMeasurement,
                    IsDeleted : x.isDeleted
                }
            ])
        });
        setIsPopUp(true);
    }
    const onDelete = (data) =>{
        setSelectedId(data.id);
        setIsMsgYesNo(true);
    }
    const onDeletePieceRate = (index) =>{
        let newArr = [...pieceRateList];
        newArr[index].IsDeleted = true;
        setPieceRateList(newArr);
    }

    //OnInitialized
    useEffect(()=>{
        getPieceWorkType();
    }, []);

    useEffect(()=>{
        search();
    }, [activePage])

    // User Rights
    useEffect(()=>{
        const canAddValue = isTaskAvailable("PIECE_WORK_CREATE");
        const canEditValue = isTaskAvailable("PIECE_WORK_EDIT");
        const canDeleteValue = isTaskAvailable("PIECE_WORK_DELETE");
        
        setCanAdd(canAddValue);
        setCanEdit(canEditValue);
        setCanDelete(canDeleteValue);
    }, []);

    //API Controllers
    const getPieceWorkType = async () =>{
        const searchModelParam = {
            Needle : "",
            Skip : 0,
            Take : 99999,
        }
        const response = await authPost('/pieceratetype/search', searchModelParam);
        const result = await response.json();
        var dataList = result.data;
        dataList.map((data) =>(
            setPieceWorkTypeList(prevState => [
                ...prevState,
                {
                    id : data.id,
                    text : data.code,
                    value : data.id,
                    Description : data.description,
                    RatePerUnit : data.ratePerUnit,
                    UnitOfMeasurement : data.unitOfMeasurement
                }
            ])
        ))
    }
    const AddPieceRate = async () =>{
        pieceWork.PieceRates = pieceRateList;
        if(pieceWork.EmployeeIds.length == 0 || pieceRateList.Quantity == 0 || pieceRateList.PieceRateTypeId == '' || pieceRateList.length == 0){
            errModel.errCaption = "Please fill up all required field/s";
            errModel.errTitle = "Invalid";
            setIsError(true);
        }
        else{
            const response = await authPost('/piecework/save', pieceWork);
            if(response.status == 200)
            {
                msgBox.Title = "Add New Piece Rate";
                msgBox.Message = "Successfully Saved !";
                const result = await response.json();
                result.map((x) => {
                setPieceWorkList([...pieceWorkList, x]);
            })
            setIsPopUp(false);
            setIsMsgOk(true);
        }
        }
    }
    const search = async () =>{
        searchParam.Skip = (activePage - 1) * 10;
        const response = await authPost ('/piecework/search', searchParam);
        const result = await response.json();
        setPieceWorkList(result.data);
        setIsTotalPages(result.count);
    }
    const editRecord = async () =>{
        pieceWork.PieceRates = pieceRateList;
        const response = await authPost('/piecework/edit', pieceWork);
        if(response.status == 200){
            msgBox.Title = "Edit Piece Rate";
            msgBox.Message = "Successfully Edited !";
            var result = await response.json();
            setPieceWorkList(pieceWorkList.map(x => x.id === pieceWork.Id ? {
                ...x,
                date : result.date,
                remarks : result.remarks,
                pieceRates : result.pieceRates
            }: x));
            setIsPopUp(false);
            setIsMsgOk(true);
        }
    }
    const deleteRecord = async () =>{
        const response = await authDelete('/piecework/delete', selectedId);
        if(response.status == 200){
            msgBox.Title = "Delete Piece Work";
            msgBox.Message = "Successfully Deleted !";
            setPieceWorkList(pieceWorkList.filter(x=>x.id !== selectedId));
            setIsMsgYesNo(false);
            setIsMsgOk(true);
        }
    }

    return(
       <div className={styles.module}>
            <div className={styles.headerLabel}>
                <h2>Piece Work</h2>
            </div>
            <div className={styles.controlContainer}>
                <div className={styles.leftHeader}>
                    {canAdd &&
                        <Button size='mini' fluid={window.innerWidth < 768}  style={{ 'max-height': '31px' }} onClick={togglePopAdd}>
                            <Icon name='plus' /> New
                        </Button>
                    }
                </div>
                <div className={styles.rightHeader}>
                    <Input name="StartDate" autoFocus required fluid size="mini" type='date' label='Start Date' value ={searchParam.StartDate}
                        onChange={handleDateChange} style={{ marginRight: 3, minWidth: '50px', width: '20%', padding: '0px 5px 0px 0px' }}/>
                    <Input name="EndDate" autoFocus required fluid size="mini" type='date' label='End Date' value ={searchParam.EndDate}
                        onChange={handleDateChange} style={{ marginRight: 3, minWidth: '50px', width: '20%', padding: '0px 5px 0px 0px' }}/>
                    <SelectEmployees includeDeleted={false} width={window.innerWidth < 500 ? '100%' : '300px'} Ids ={searchParam.EmployeeIds}
                        update = {(ids) => searchParam.EmployeeIds = ids}/>
                    <Button size='mini' fluid={window.innerWidth < 768} type='submit' onClick={search} style={window.innerWidth >= 768 ? { 'max-height': '30px', marginLeft: '10px' } : { 'max-height': '30px' }} content='Search' />
                </div>
            </div>
            <div className={styles.paginationContainer}>
                <Pagination
                    style={{alignSelf:"end"}}
                    defaultActivePage={1}
                    pointing
                    secondary
                    firstItem={{ content: <Icon name='angle double left' />, icon: true }}
                    lastItem={{ content: <Icon name='angle double right' />, icon: true }}
                    prevItem={{ content: <Icon name='angle left' />, icon: true }}
                    nextItem={{ content: <Icon name='angle right' />, icon: true }}
                    size='mini'
                    siblingRange={2}
                    boundaryRange={0}
                    totalPages={Math.ceil(totalPages / 10)}
                    activePage={activePage}
                    onPageChange={(e, { activePage }) => onPageChange(activePage)}
                />
            </div>
            <div className={styles.bodyWrapper}>
                    <Table stackable selectable striped size='small'>
                        <Table.Header>
                            <Table.Row className={styles.tableDesign}>
                                <Table.HeaderCell>Employee</Table.HeaderCell>
                                <Table.HeaderCell>Date</Table.HeaderCell>
                                <Table.HeaderCell>Record Date</Table.HeaderCell>
                                <Table.HeaderCell>Actions</Table.HeaderCell>
                            </Table.Row>
                        </Table.Header>
                        <Table.Body>
                            { pieceWorkList.map((data, index) =>(
                                <Table.Row key={data.id}>
                                    <Table.Cell>{data.employee}</Table.Cell>
                                    <Table.Cell>{moment(data.date).format(momentFormat).toString() }</Table.Cell>
                                    <Table.Cell>{moment(data.recordDate).format(momentFormat).toString() }</Table.Cell>
                                    <Table.Cell>
                                        <div>
                                            {canEdit &&
                                                <Popup trigger={
                                                    <Button icon='edit' circular onClick={()=> onEdit(data)}></Button>} content='Edit' inverted />
                                            }
                                            {canDelete &&
                                                <Popup trigger={
                                                    <Button icon='delete' circular onClick={()=> onDelete(data)}></Button>} content='Delete' inverted />
                                            }
                                        </div>
                                    </Table.Cell>
                                </Table.Row>
                            ))
                            }
                        </Table.Body>
                    </Table>
            </div>

            { isPopUp &&
                    <Modal open={true} size='large'>
                        <Modal.Header>Piece Rate</Modal.Header>
                        <Modal.Content>
                        {
                            isError &&
                                <div className="ui one column grid" style={{"margin": "0px", "padding": "0px"}} >
                                    <div className="column" style={{"margin": "0px 0px 10px 0px", "padding": "0px"}}>
                                        <ErrorMessage
                                            title={errModel.errTitle}
                                            caption={errModel.errCaption}
                                            open={isError}
                                        />
                                    </div>
                                </div>
                        }
                            <div>
                                <div className={styles.empDetails}>
                                    <div className={styles.column}>
                                    {
                                        pieceWork.Id &&
                                        <div className={styles.employeeContainer}>
                                            <span>Employee</span>
                                            <label>{pieceWork.Employee}</label>
                                        </div>
                                    }
                                    {
                                        !pieceWork.Id &&
                                        <SelectEmployees includeDeleted={false} Ids={ pieceWork.EmployeeIds } update= {(ids) => [pieceWork.EmployeeIds = ids]} error={isError && !pieceWork.EmployeeIds}/>
                                    }
                                    </div>
                                    <div className={styles.optionContainer}>
                                        <div className={styles.column}>
                                            <Input name="Date" type="date" fluid size="mini" autoFocus required label='Date' value={moment(pieceWork.Date).format(momentFormat).toString()}
                                                onChange={handlePieceWorkChange}></Input>
                                        </div>
                                        <div className={styles.addContainer}>
                                            <Button size='mini' fluid={window.innerWidth < 768}  style={{ 'max-height': '31px' }} onClick={AddPieceRateRow}>
                                                <Icon name='plus' /> Add
                                            </Button>
                                        </div>
                                    </div>
                                </div>
                                <div className={styles.tableContainer}>
                                <Table>
                                    <Table.Header>
                                        <Table.Row className={styles.tableDesign}>
                                            <Table.HeaderCell>Type</Table.HeaderCell>
                                            <Table.HeaderCell>Quantity</Table.HeaderCell>
                                            <Table.HeaderCell>Unit</Table.HeaderCell>
                                            <Table.HeaderCell>Rate</Table.HeaderCell>
                                            <Table.HeaderCell>Total</Table.HeaderCell>
                                            <Table.HeaderCell>Action</Table.HeaderCell>
                                        </Table.Row>
                                    </Table.Header>
                                    <Table.Body>
                                        { pieceRateList.filter(x=>x.IsDeleted == false).map((data, index) => (
                                            <Table.Row>
                                                <Table.Cell>
                                                    <Dropdown selection value={data.PieceRateTypeId} search fluid options={pieceWorkTypeList} error={isError && !pieceWork.PieceRateTypeId} onChange={(e) => onRateTypeChange(e ,index)}></Dropdown>
                                                </Table.Cell>
                                                <Table.Cell><Input fluid={true} value={ data.Quantity } onChange={handlePieceRateChange(index)} error={isError && !pieceWork.Quantity}/></Table.Cell>
                                                <Table.Cell><Input fluid={true} value={ data.UnitOfMeasurement } name='UnitOfMeasurement' disabled/></Table.Cell>
                                                <Table.Cell><Input fluid={true} value={ data.RatePerUnit } name='RatePerUnit'  disabled/></Table.Cell>
                                                <Table.Cell><Input fluid={true} value={ data.Total } name='Total' disabled/></Table.Cell>
                                                <Table.Cell>
                                                    <Popup trigger={
                                                        <Button icon='delete' circular onClick={()=> onDeletePieceRate(index)}></Button>} content='Delete' inverted />
                                                </Table.Cell>
                                            </Table.Row>
                                        ))}
                                    </Table.Body>
                                </Table>
                                </div>
                            </div>
                        </Modal.Content>
                        <Modal.Actions>
                            <Button icon='save' content='Save' onClick={pieceWork.Id == "" ?  AddPieceRate : editRecord} />
                            <Button content='Cancel' onClick={() => setIsPopUp(false)} />
                        </Modal.Actions>
                    </Modal>
            }

            { isMsgOk &&
                    <MessageBoxOkOnly title={msgBox.Title} action="Yes"
                        caption={msgBox.Message}
                        onClick={()=> setIsMsgOk(false)}
                    />
            }

            { isMsgYesNo &&
                <MessageBoxYesNo title="Delete Piece Work" action="Yes" CancelCaption="No"
                    caption="Are you sure you want to delete this Record ?" 
                    onClose = { () => setIsMsgYesNo(false) }
                    onAction = {deleteRecord}/>
            }
       </div>
    )
}

PieceWork.getViewID = () => { return "PieceWork" };
PieceWork.getCaption = () => { return "Piece Work Management" };
PieceWork.getViewAccessKey = () => {
    return isTaskAvailable("PIECE_WORK_VIEW");
}

PieceWork.getIcon = () => {
    return (<Image style={{ "height": "24px", "width": "24px" }} src='/Icons/Fringe_Benefits.png' avatar />);
}
PieceWork.getIconSrc = (size) => {
    return '/Icons/Fringe_Benefits.png';
}
import React, { Component } from 'react';
import {Label,Button} from 'semantic-ui-react';
//import 'semantic-ui-css/semantic.min.css';
import { ajaxPost } from '../ajax';

class NotificationBell extends Component
{
    state = 
    {
        unread: 0
    }

    componentWillMount = () => setTimeout(this.checkNotifications, 1000);

    setupCheck = () => setTimeout(this.checkNotifications, 60000);

    checkNotifications = () => {
        var searchParameter = 
        {
            data: [],
            updateSession: false,
            url: "api/Notification/check",
            onError: (error) => {},
            onSuccess: (data, sender) => this.setState({ unread: data.TotalUnread }),
            finally: () => this.setupCheck()
        };
        ajaxPost(searchParameter);
    };
    

    render()
    {
        return (
            <div style={{ display: "inline-block", position: "relative", alignSelf: "center" }}>
            <Button
              id="btnNotification"
              circular
              icon="bell"
              size="large"
              onClick={this.props.onClick}
              inverted
            />
            {this.state.unread > 0 && (
              <Label
                id="lblNotification"
                size="tiny"
                color="red"
                floating
                circular
              >
                {this.state.unread.toString()}
              </Label>
            )}
          </div>
        )
    }
}

export default NotificationBell;
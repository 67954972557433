
export function ajaxPost(params) {
    var data =
    {
        Payload: params.data,
        SessionId: global.sessionId,
        SessionKey: "HRIS_SessionId",
        UpdateSession: params.updateSession == null ? true : params.updateSession,
    };
    return window.fetch(global.ApiHost + params.url,
        {
            credentials: 'include', //params.credentials != null ? 'include' : 'same-origin',
            headers: {
                'Accept': 'application/json, text/plain, */*',
                'Content-Type': 'application/json',
                'Timezone' : new Date().getTimezoneOffset().toString(),
            },
            method: 'post',
            body: JSON.stringify(data)
        }).catch((err) => {
            if (params.onError != null)
                params.onError(err);

            return { then: params.finally };
        })
        .then((response) => {
            if (!response.ok) {
                if (params.onError != null)
                    params.onError(response);

                return { then: params.finally }; // end/break the chain
            }
            return response.json();
        }).then((json) => {
            if (json.Status === "OK")
                return params.onSuccess(JSON.parse(json.JsonData), params.sender);
            else if (json.Status === "MAINTENANCE")
                window.location = "/maintenance";
            else
                window.location = "error?id=" + json.Status;
        }).then(() => {
            params.finally();
        });
}

export function ajaxGet(params) {
    window.fetch(global.ApiHost + params.url,
        {
            credentials: 'include',//params.credentials != null ? 'include' : 'same-origin',
            headers: {
                'Accept': 'application/json, text/plain, */*',
                'Content-Type': 'application/json'
            },
            method: 'get',
        })
        .then((response) => {
            if (!response.ok) {
                if (params.onError != null)
                    params.onError(response);

                return { then: params.finally }; // end/break the chain
            }
            return response.json();
        }).then((json) => {
            params.onSuccess(json);
        }).then(() => {
            params.finally();
        });
}

export function fetchGet(url){
    return window.fetch(`${global.ApiHost2}/api/${global.CompanyId}${url}`,
        {
            headers: {
                'Accept': 'application/json, text/plain, */*',
                'Content-Type': 'application/json'
            },
            method: 'get'
        });
}

export function fetchPost(url, payload){
    return window.fetch(`${global.ApiHost2}/api/${global.CompanyId}${url}`,
        {
            credentials:'include',
            headers: {
                'Accept': 'application/json, text/plain, */*',
                'Content-Type': 'application/json'
            },
            method: 'post',
            body: JSON.stringify(payload)
        });
}

export async function authPost(url, payload){
    var resp = await window.fetch(
        `${global.ApiHost2}/api/${global.CompanyId}${url}`,
        {
            headers: {
                'Accept': 'application/json, text/plain, */*',
                'Content-Type': 'application/json',
                'Timezone' : new Date().getTimezoneOffset().toString(),
                'Authorization': `Bearer ${localStorage.getItem("Token")}`
            },
            method: 'post',
            body: JSON.stringify(payload)
        });

    if( resp.status == 401 ){
        window.location.href = "/login";
    }

    return resp;
}

export async function authGet(url){
    var resp = await window.fetch(
        `${global.ApiHost2}/api/${global.CompanyId}${url}`,
        {
            headers: {
                'Accept': 'application/json, text/plain, */*',
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${localStorage.getItem("Token")}`
            },
            method: 'get'
        });
    if( resp.status == 401 ){
        window.location.href = "/login";
    }

    return resp;
}

export async function authDelete(url, payload){
    var resp = await window.fetch(
        `${global.ApiHost2}/api/${global.CompanyId}${url}/?Id=${payload}`,
        {
            headers: {
                'Accept': 'application/json, text/plain, */*',
                'Content-Type': 'application/json',
                'Timezone' : new Date().getTimezoneOffset().toString(),
                'Authorization': `Bearer ${localStorage.getItem("Token")}`
            },
            method: 'delete',
        });

    if( resp.status == 401 ){
        window.location.href = "/login";
    }

    return resp;
}
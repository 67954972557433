import React, { Component } from "react";
import Select from "react-select";

import {
    Icon,
    Input,
    Tab,
} from "semantic-ui-react";

const DeviceUserType = [
    { label: "User", value: 0 },
    { label: "Enroller", value: 4 },
    { label: "Administrator", value: 14 },
  ];
  
var {
    handleNumericOnKeyPress,
    handleNumericOnKeyDown,
    customStyles,
    empty_id,
} = require("../../utils");


export function renderEmployeeDevice(this2) {
    const {
        AccessNumber,
      } = this2.state.selectedEmployee;
  

    const { RFID, Privilege, FPCount, FaceCount, PalmCount } =
      this2.state.zkUserInfo;

    return <Tab.Pane style={{ border: "none" }}>
    <label
      style={{
        fontWeight: "bold",
        fontSize: "1.3em",
        color: "#606060",
      }}
    >
      Device Information
    </label>
    <hr style={{ color: "#606060", "border-width": "0.8px" }} />
    <div className="w3-row" style={{ width: "100%" }}>
      <div className="w3-col m12 l6">
        <div className="w3-row" style={{ paddingTop: "10px" }}>
          <div
            className="w3-col s12 m5 l5"
            style={{ paddingLeft: "10px" }}
          >
            <label> Access Number *</label>
          </div>
          <div className="w3-col s12 m7 l7">
            <Input
              placeholder="Access Number"
              fluid
              value={AccessNumber}
              error={this2.state.accessNumberIsError}
              onChange={this2.onAccessNumberChange("AccessNumber").bind(
                this2
              )}
              ref={(input) => (this2.AccessNumber = input)}
              onKeyPress={(e) => {
                handleNumericOnKeyPress(e);
              }}
              onKeyDown={(e) => {
                handleNumericOnKeyDown(e);
              }}
              maxLength={this2.state.setting.MaxAccessNumberLength}
            />
          </div>
        </div>

        <div className="w3-row" style={{ paddingTop: "10px" }}>
          <div
            className="w3-col s12 m5 l5"
            style={{ paddingLeft: "10px" }}
          >
            <label> Pin </label>
          </div>
          <div className="w3-col s12 m7 l7">
            <Input
              icon={
                <Icon
                  name="eye"
                  onMouseUp={() => {
                    this2.setState({ ShowZkPassword: false });
                  }}
                  onMouseDown={() => {
                    this2.setState({ ShowZkPassword: true });
                  }}
                  link
                />
              }
              placeholder="Pin"
              type={this2.state.ShowZkPassword ? "text" : "password"}
              fluid
              value={this2.state.zkUserInfo.Password}
              onChange={this2.handleZKUserChange("Password").bind(this2)}
              onKeyPress={(e) => {
                handleNumericOnKeyPress(e);
              }}
              onKeyDown={(e) => {
                handleNumericOnKeyDown(e);
              }}
            />
          </div>
        </div>

        <div className="w3-row" style={{ paddingTop: "10px" }}>
          <div
            className="w3-col s12 m5 l5"
            style={{ paddingLeft: "10px" }}
          >
            <label> Card Number </label>
          </div>
          <div className="w3-col s12 m7 l7">
            <Input
              placeholder="Card Number"
              fluid
              value={RFID === "-1" ? "" : RFID}
              onChange={this2.handleZKUserChange("RFID").bind(this2)}
            />
          </div>
        </div>

        <div className="w3-row" style={{ paddingTop: "10px" }}>
          <div
            className="w3-col s12 m5 l5"
            style={{ paddingLeft: "10px" }}
          >
            <label> User Type </label>
          </div>
          <div className="w3-col s12 m7 l7">
            <Select
              isClearable
              styles={customStyles}
              placeholder="User Type"
              value={DeviceUserType.find((x) => x.value == Privilege)}
              options={DeviceUserType}
              onChange={this2.handleDropDownUserInfoChange(
                "Privilege"
              ).bind(this2)}
            />
          </div>
        </div>

        <div className="w3-row" style={{ paddingTop: "10px" }}>
          <div
            className="w3-col s12 m5 l5"
            style={{ paddingLeft: "10px" }}
          >
            <label> Finger User Count </label>
          </div>
          <div className="w3-col s12 m7 l7">
            <label> {FPCount} </label>
          </div>
        </div>

        <div className="w3-row" style={{ paddingTop: "10px" }}>
          <div
            className="w3-col s12 m5 l5"
            style={{ paddingLeft: "10px" }}
          >
            <label> Face User Count </label>
          </div>
          <div className="w3-col s12 m7 l7">
            <label> {FaceCount} </label>
          </div>
        </div>
        <div className="w3-row" style={{ paddingTop: "10px" }}>
          <div
            className="w3-col s12 m5 l5"
            style={{ paddingLeft: "10px" }}
          >
            <label> Palm User Count </label>
          </div>
          <div className="w3-col s12 m7 l7">
            <label> {PalmCount} </label>
          </div>
        </div>
      </div>
    </div>
  </Tab.Pane>

}
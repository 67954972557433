import React, { Component } from 'react';
import 'semantic-ui-css/semantic.min.css';
import { Modal, Button, Input } from '../../../node_modules/semantic-ui-react';
import Select from 'react-select';
import {fnSearchEmployee } from '../../utils';
import {fnSearchEmployeeFilter} from '../../utils';
import SelectEmployees from '../../Commons/SelectEmployees'

const moment = require('moment');

function employeeSearchlist(list){
    var employees = [];
    if(list !=null){
       list.map(x =>{
           return(
            employees.push({label:x.LastName + ", " + x.FirstName + " " + x.MiddleName,value:x._id})
           )
       })
    }
    return employees;
}
class PositionMovementModal extends Component {

    constructor(props) {
        super(props);
        this.state = {
            fromDate: moment().startOf('day').format("YYYY-MM-DD"),
            toDate: moment().startOf('day').format("YYYY-MM-DD"),
            includeDeleted:false,
            EmployeeIds:[],
            searchQuery:'',
            Employeelist:[]
        }
    }
    componentWillMount(){
        fnSearchEmployee("", data => {this.setState({ Employeelist: data["content"]});},0,20, false)
    }

    // onSearch = (event) => {
    //     var str = event.target.value;
    //     this.setState({searchQuery: str })
    //     setTimeout(this.doSearch.bind(this), 200, str, event.target);
    // }

    // doSearch = (str, source) => {
    //     this.setState({searchQuery:str})
    //     fnSearchEmployeeFilter(str, data => {this.setState({ Employeelist: data});},0,20, false,this.state.EmployeeIds)
    // }

    getParameters = () => {
        return {
            fromDate: this.state.fromDate,
            toDate: this.state.toDate,
            message: "from position movement modal",
            EmployeeIds:this.state.EmployeeIds,
            includeDeleted:false,
            startingIndex:0,
            itemCount:20
        };
    }
    // handleSearchChange =(e,{searchQuery}) => 
    // this.setState({searchQuery:searchQuery}
    // );
    handleComboChange = (value) => {
        var ids = value === "" ? null : value.split(',');
        this.setState({ EmployeeIds: ids})
        this.state.searchQuery = "";
    }
    render() {
        return (
            <Modal open={this.props.open} size="tiny">
                <Modal.Header>
                    <p>Position Movement Report Filters</p>
                </Modal.Header>
                <Modal.Content>
                    <div class="ui grid">
                        <div class="left floated left aligned eight wide column">
                            <Input type="date" label="From Date" size="mini" fluid value={this.state.fromDate} onChange={(event, data)=>{this.setState({fromDate:data.value})}} />
                        </div>
                        <div class="right floated right aligned eight wide column">
                            <Input type="date" label="To Date" size="mini" fluid value={this.state.toDate} onChange={(event, data)=>{this.setState({toDate:data.value})}} />   
                        </div>
                    </div>
                    <div class="ui grid">
                        <div class="left floated left aligned sixteen wide column">
                        <label>Employee</label>
                            {/* <CustomSelect fluid placeholder='Employee.' size="mini" name="EmployeeIds"
                                onChange={this.handleComboChange.bind(this)}  
                                options={employeeSearchlist(this.state.Employeelist)}
                                onInputKeyDown={this.onSearch.bind(this)}
                                multi
                                simpleValue                                                      
                                searchQuery={this.state.searchQuery}
                                value={this.state.EmployeeIds}/>  */}
                            <SelectEmployees Ids={this.state.EmployeeIds} update={(ids)=> this.setState({EmployeeIds: ids})}/>
                        </div>
                    </div>
                </Modal.Content>
                <Modal.Actions>
                    <Button positive content="Generate" onClick={this.props.onSave}></Button>
                    <Button negative content="Cancel" onClick={this.props.onCancel}></Button>
                </Modal.Actions>
            </Modal>
        );
    }

}

export default PositionMovementModal;
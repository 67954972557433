import React, { Component } from 'react';
import { Image } from 'semantic-ui-react';
import Schedule from './Schedule'
import ScheduleForm from './ScheduleForm';
import { isTaskAvailable } from '../../utils';

class ScheduleModule extends Component {
    state = {
        mode: "search",
        searchState: {
            initialLoad: true,
            selectedSchedule: null,
            listSchedule: [],
            search: '',
            page: 1,
            rowPerPage: 10,
            totalPages: 1,
            total: 0,
            messageBoxOkOnly: {
                show: false,
                title: "",
                caption: "",
            },
        },
        selectedSchedule: null
    }
    componentWillMount() {
        if (this.props.prevState != null) {
           
            if(this.props.prevState.searchState.initialLoad !== true){
                this.state = this.props.prevState;
            }
            this.state.searchState.initialLoad = false;
        }

    }
    componentWillUnmount = () => {
        this.props.unmountCallback(this.state, "ScheduleProfiles");
    }
    onUpdateState = (searchState) => {
        this.setState({ searchState: searchState });
    }
    onSelect = (item) => {
        this.setState({ selectedSchedule: item });
    }
    onAdd = (searchState) => {
        //set blank model for schedule
        this.setState({ selectedSchedule: null });
        this.setState({ searchState });
        this.setState({ mode: "add" });
    }
    onEdit = (searchState, selectedSchedule) => {
        this.setState({ searchState: searchState });
        this.setState({ selectedSchedule: selectedSchedule });
        this.setState({ mode: "edit" });
    }
    onCloseForm = (schedule) => {
        this.setState({ mode: "search" });
    }
    onUpdateComplete = (model) => {
        var list = this.state.searchState.listSchedule;
        var searchState = this.state.searchState;

        if (this.state.mode === "add") {
            if (list.length > 0) {
                list.push(model);
                searchState.listSchedule = list;
            }
            else {
                searchState.listSchedule = [model];
            }
            searchState.selectedSchedule = model;
            searchState.page = 1;
        } else {
            var itemIndex = list.indexOf(this.state.selectedSchedule);
            if (itemIndex >= 0)
                list[itemIndex] = model;
        }
        this.setState({ searchState: this.state.searchState });
        this.setState({ mode: "search" });
    }
    render() {

        return this.state.mode === "search" ?
            <Schedule
                searchState={this.state.searchState}
                onSelect={this.onSelect.bind(this)}
                onAdd={this.onAdd.bind(this)}
                onEdit={this.onEdit.bind(this)}
                onUpdateState={this.onUpdateState.bind(this)} />
            :

            <ScheduleForm schedule={JSON.parse(JSON.stringify(this.state.selectedSchedule))}
                onCloseForm={this.onCloseForm.bind(this)}
                onUpdateComplete={this.onUpdateComplete.bind(this)} />
    }

}
ScheduleModule.getViewID = () => { return "ScheduleProfiles" };
ScheduleModule.getCaption = () => { return "Schedule Profile" }
ScheduleModule.getViewAccessKey = () => { return isTaskAvailable("SCHEDULE_PROFILE_VIEW") || isTaskAvailable("SCHEDULE_PROFILE_CREATE") || isTaskAvailable("SCHEDULE_PROFILE_MODIFY") || isTaskAvailable("SCHEDULE_PROFILE_DELETE") ? true : false }
ScheduleModule.getIcon = () => {
    return (<Image style={{ "height": "24px", "width": "24px" }} src='/Icons/Schedule_Profile.png' avatar />);
}
ScheduleModule.getIconSrc = (size) => //size values: small (50x50), medium(150x150), large(300x300)
{
    return '/Icons/Schedule_Profile.png'
}
export default ScheduleModule
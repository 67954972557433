import React from 'react';
import 'semantic-ui-css/semantic.min.css';
import ReportModelBase from './ReportModelBase';
import { Table, Modal, Button } from '../../../node_modules/semantic-ui-react';
const moment = require('moment');

class PositionMovementReportModel extends ReportModelBase {

    constructor(props){
        super(props);
        this.searchUrl = "api/Reports/positionMovement";
        this.title = "Employee Position Movement Report";
        this.exportToExcelUrl = "api/Reports/ExportPositionMovementReportToExcel";
        this.exportToPdfUrl = "api/Reports/ExportPositionMovementReportToPDF";
    }

    toTableRow = (rowData) => {
        return (
            <Table.Row>
                <Table.Cell>{rowData["Employee No"]}</Table.Cell>
                <Table.Cell>{rowData["Employee"]}</Table.Cell>
                <Table.Cell>{rowData["Position Name"]}</Table.Cell>
                <Table.Cell>{rowData["Department"]}</Table.Cell>
                <Table.Cell>{rowData["Job Level"]}</Table.Cell>
                <Table.Cell>{rowData["Salary Amount"]}</Table.Cell>
                <Table.Cell>{rowData["Supervisor"]}</Table.Cell>
                <Table.Cell>{rowData["Start Date"]}</Table.Cell>
                <Table.Cell>{rowData["End Date"]}</Table.Cell>
                <Table.Cell>{rowData["Job"]}</Table.Cell>
                <Table.Cell>{rowData["Remarks"]}</Table.Cell>
            </Table.Row>
        );
    }

    createColumnHeaders = () => {
        return (
            <Table.Row>
                <Table.HeaderCell>Employee No</Table.HeaderCell>
                <Table.HeaderCell>Employee</Table.HeaderCell>
                <Table.HeaderCell>Position</Table.HeaderCell>
                <Table.HeaderCell>Department</Table.HeaderCell>
                <Table.HeaderCell>Job Level</Table.HeaderCell>
                <Table.HeaderCell>Salary Amount</Table.HeaderCell>
                <Table.HeaderCell>Supervisor</Table.HeaderCell>
                <Table.HeaderCell>Start Date</Table.HeaderCell>
                <Table.HeaderCell>End Date</Table.HeaderCell>
                <Table.HeaderCell>Jobs</Table.HeaderCell>
                <Table.HeaderCell>Remarks</Table.HeaderCell>
            </Table.Row>
        );
    }

}

export default PositionMovementReportModel;
import React, { Component } from "react";
import { Button, Input, Modal, Popup, Icon, Checkbox } from "semantic-ui-react";
import "semantic-ui-css/semantic.min.css";
import ErrorMessage from "../../../Commons/ErrorMessage";
import Select from "react-select";
import LeaveProfileDetailsTable from "./LeaveProfileDetailsTable";
import LeaveProfileDetailsForm from "./LeaveProfileDetailsForm";
import {
  checkInt32,
  findDuplicates,
  handleNumericOnKeyPress,
  handleNumericOnKeyDown,
  isNullOrEmpty,
  empty_id,
} from "../../../utils";

const someMarginNoPadding = { margin: "0px 0px 10px 0px", padding: "0px" };
const noMarginNoPadding = { margin: "0px", padding: "0px" };
const marginCheckBox = { margin: "0px", padding: "4.5px 0 0 10px" };

const monthList = [
  { label: "January", value: 1 },
  { label: "February", value: 2 },
  { label: "March", value: 3 },
  { label: "April", value: 4 },
  { label: "May", value: 5 },
  { label: "June", value: 6 },
  { label: "July", value: 7 },
  { label: "August", value: 8 },
  { label: "September", value: 9 },
  { label: "October", value: 10 },
  { label: "November", value: 11 },
  { label: "December", value: 12 },
];

const tenureDateBasis = [
  { label: "Date Hired", value: "DATEHIRED" },
  { label: "Regularization Date", value: "REGULARIZATION" },
];

const moment = require("moment");

function populateDayOptions(month) {
  var tempMonthList = [];
  var daysInMonth = moment(2011 + "-" + month, "YYYY-MM").daysInMonth();
  for (var i = 1; i <= daysInMonth; i++) {
    tempMonthList.push({ label: i, value: i });
  }
  return tempMonthList;
}

class LeaveProfileForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      Model: this.getDefaultValues(),
      mode: "add",
      selectedId: "",
    };
    this.tableRef = React.createRef();
    this.formRef = React.createRef();
  }

  getDefaultValues = () => {
    return {
      _id: undefined,
      Description: "",
      ResetMonth: 1,
      ResetDay: 1,
      TenureMonth: 12,
      Details: [],
      IsEarnedLeave: false,
      TenureDateBasis: "DATEHIRED",
      ValidPriorFilingDays: 0,
      ValidLateFilingDays: 0
    };
  };

  setContent = (data) => {
    this.setState({
      Model:
        data === null || data === undefined ? this.getDefaultValues() : data,
    });
  };

  getContent = () => {
    return this.state.Model;
  };

  handleChange = (name) => (event) => {
    var current = this.state.Model;
    current[name] = event.target.value;
    this.setState({ Model: current });
  };

  handleSelectChange = (name, value) => {
    var current = this.state.Model;
    if (value !== null) {
      current[name] = value.value;
    } else {
      current[name] = null;
    }
    this.setState({ Model: current });
  };

  btnAddDetail_Click() {
    let data = null;
    this.setState({ showDetailForm: true, mode: "add", selectedId: "" }, () => {
      // () => { this.formRef.current.setContent(data) }
    });
  }

  btnEditDetail_Click(id) {
    let data = this.state.Model.Details.filter((x) => x.LeaveTypeId === id)[0];
    this.setState(
      { showDetailForm: true, selectedId: id, mode: "edit" }
      // () => { this.formRef.current.setContent(data) }
    );
  }

  btnDeleteDetail_Click(id) {
    let Model = this.state.Model;
    let index = Model.Details.findIndex((x) => x.LeaveTypeId === id);
    Model.Details.splice(index, 1);
    this.tableRef.current.setState({ selectedId: "" });
    this.setState({ Model });
  }

  validatePayrollDetail = (data) => {
    var errTitle = "",
      errCaption = "";
    var isAddNew = this.state.selectedId === "";
    var fromData = isAddNew
      ? {}
      : this.state.Model.Details.filter(
        (x) => x.LeaveTypeId === this.state.selectedId
      )[0];
    var checkGrants = checkInt32(data.Grants);
    var checkConvertible = checkInt32(data.Convertible);
    var checkCarryOver = checkInt32(data.CarryOver);
    var checkTaxable = checkInt32(data.Taxable);
    var checkValidPriorFilingDays = checkInt32(data.ValidPriorFilingDays);
    var checkValidLateFilingDays = checkInt32(data.ValidLateFilingDays);

    if (
      isNullOrEmpty(data.LeaveTypeId) ||
      isNullOrEmpty(data.Grants) ||
      isNullOrEmpty(data.Convertible) ||
      isNullOrEmpty(data.CarryOver) ||
      isNullOrEmpty(data.Taxable) ||
      isNullOrEmpty(data.ValidPriorFilingDays) ||
      isNullOrEmpty(data.ValidLateFilingDays)
    ) {
      errCaption = "Please fill up all required field/s";
      errTitle = "Invalid";
    } else if (!checkGrants.Result) {
      errCaption = checkGrants.Message;
      errTitle = checkGrants.Title;
    } else if (!checkConvertible.Result) {
      errCaption = checkConvertible.Message;
      errTitle = checkConvertible.Title;
    } else if (!checkCarryOver.Result) {
      errCaption = checkCarryOver.Message;
      errTitle = checkCarryOver.Title;
    } else if (!checkTaxable.Result) {
      errCaption = checkTaxable.Message;
      errTitle = checkTaxable.Title;
    } else if (!checkValidPriorFilingDays.Result) {
      errCaption = checkValidPriorFilingDays.Message;
      errTitle = checkValidPriorFilingDays.Title;
    } else if (!checkValidLateFilingDays.Result) {
      errCaption = checkValidLateFilingDays.Message;
      errTitle = checkValidLateFilingDays.Title;
    } else if (data.Accrued) {
      let AccrualDetails = data.AccrualDetailString.split(",").map((item) => {
        let values = item.split(":");
        return {
          Year: values[0],
          Grants: values[1],
          Convertible: values[2],
          CarryOver: values[3],
          Taxable: values[4],
        };
      });

      AccrualDetails.forEach((item, index) => {
        if (errCaption === "") {
          if (item.Year === undefined || item.Year <= 0) {
            errCaption =
              "Year must be greater than zero. See row " + (index + 1);
            return;
          } else if (item.Grants === undefined || item.Grants <= 0) {
            errCaption =
              "Grants must be greater than zero. See row " + (index + 1);
            return;
          }
          // else if (Number(item.Convertible) > Number(item.Grants)) {
          //     errCaption = "Convertible must be equal or less than Leave Grants. See row " + (index + 1);
          // }
          else if (Number(item.Convertible) < 0) {
            errCaption =
              "Convertible must be zero or a positive number. See row " +
              (index + 1);
          }
          // else if (Number(item.Convertible) + Number(item.CarryOver) > Number(item.Grants))
          // {
          //     errCaption ="Grants must be greater than or equal to the sum of Convertible and Carry Over. See row " + (index + 1);
          // }
          else if (Number(item.Taxable) > Number(item.Convertible)) {
            errCaption =
              "Non Taxable must be lest than or equal to Convertible. See row " +
              (index + 1);
          }
        }
      });

      if (errCaption === "") {
        let arrYear = AccrualDetails.map((x) => x.Year);
        let duplicates = findDuplicates(arrYear);
        if (duplicates.length > 0) {
          errCaption = "Duplicate accrual year [" + duplicates.join(",") + "]";
        }
      }

      errTitle = errCaption.length > 0 ? "Invalid" : "";
    } else if (!data.Accrued) {
      if (Number(data.Grants) < 1) {
        errCaption = "Grants must be equal or greater than 1.";
      }
      // else if (Number(data.Convertible) > Number(data.Grants)) {
      //     errCaption = "Convertible must be equal or less than Leave Grants.";
      // }
      else if (Number(data.Convertible) < 0) {
        errCaption = "Convertible must be zero or a positive number.";
      }
      // else if (Number(data.Convertible) + Number(data.CarryOver) > Number(data.Grants)) {
      //     errCaption = "Grants must be greater than or equal to the sum of Convertible and Carry Over.";
      // }
      else if (Number(data.Taxable) > Number(data.Convertible)) {
        errCaption = "Non Taxable must be lest than or equal to Convertible.";
      }
      errTitle = errCaption.length > 0 ? "Invalid" : "";
    }

    if (errCaption.length > 0) {
      this.formRef.current.setState({
        showRequiredField: true,
        errTitle,
        errCaption,
      });
      return false;
    } else {
      this.formRef.current.setState({ showRequiredField: false });
      return true;
    }
  };

  onSaveDetail() {
    this.formRef.current.setState(
      {
        Model: this.formRef.current.getContent(),
      },
      () => {
        let data = this.formRef.current.state.Model;
        if (!this.validatePayrollDetail(data)) {
          return;
        }

        let addNew = this.state.mode === "add";
        let Model = this.state.Model;
        let index = Model.Details.findIndex(
          (x) => x.LeaveTypeId === this.state.selectedId
        );

        if (addNew) {
          Model.Details.push(data);
        } else {
          Model.Details[index] = data;
        }
        
        this.setState(
          {
            Model,
            selectedId: addNew
              ? Model.Details.length - 1
              : this.state.selectedId,
          },
          () => {
            this.onCloseDetailForm();
          }
        );
      }
    );
  }

  onEarnedLeavesChecked = () => {
    var { Model } = this.state;
    Model.IsEarnedLeave = !Model.IsEarnedLeave;
    this.setState({ Model });
  };

  onCloseDetailForm() {
    this.setState({
      mode: "",
      showDetailForm: false,
    });
  }

  render() {
    let model = this.state.Model;
    let tableRef = this.tableRef.current;
    return (
      <Modal size="tiny" open={this.props.open}>
        <Modal.Header>
          {this.state.Model._id === "" ||
            this.state.Model._id === empty_id ||
            this.state.Model._id == undefined
            ? "Create"
            : "Edit"}{" "}
          Leave Profile
        </Modal.Header>
        <Modal.Content>
          {this.props.showRequiredField && (
            <div className="ui one column grid" style={noMarginNoPadding}>
              <div className="column" style={someMarginNoPadding}>
                <ErrorMessage
                  title={this.props.errTitle}
                  caption={this.props.errCaption}
                  open={this.props.showRequiredField}
                />
              </div>
            </div>
          )}
          <div className="ui one column grid" style={noMarginNoPadding}>
            <div className="column" style={someMarginNoPadding}>
              <div>Description</div>
              <Input
                placeholder="Description"
                fluid
                value={model.Description}
                //disabled={this.props.isBusy}
                error={
                  this.props.showRequiredField && !this.state.Model.Description
                }
                onChange={this.handleChange("Description").bind(this)}
              />
            </div>
          </div>
          <div className="ui one column grid" style={noMarginNoPadding}>
            <div className="column" style={someMarginNoPadding}>
              <div>Tenure Date Basis</div>
              <Select
                placeholder="Tenure Date Basis"
                value={tenureDateBasis.find(
                  (x) => x.value === model.TenureDateBasis
                )}
                options={tenureDateBasis}
                onChange={this.handleSelectChange.bind(this, "TenureDateBasis")}
              />
            </div>
          </div>
          <div className="ui one column grid" style={noMarginNoPadding}>
            <div className="column" style={someMarginNoPadding}>
              <div>Reset Month</div>
              <Select
                placeholder="ResetMonth"
                value={monthList.find((x) => x.value === model.ResetMonth)}
                options={monthList}
                onChange={this.handleSelectChange.bind(this, "ResetMonth")}
              />
            </div>
          </div>
          <div className="ui one column grid" style={noMarginNoPadding}>
            <div className="column" style={someMarginNoPadding}>
              <div>Reset Day</div>
              <Select
                placeholder="Reset Day"
                value={populateDayOptions(model.ResetMonth).find(
                  (x) => x.value === model.ResetDay
                )}
                options={populateDayOptions(model.ResetMonth)}
                onChange={this.handleSelectChange.bind(this, "ResetDay")}
              />
            </div>
          </div>
          <div className="ui one column grid" style={noMarginNoPadding}>
            <div className="column" style={someMarginNoPadding}>
              <div>Tenure Months</div>
              <Input
                placeholder="Tenure Month"
                fluid
                value={model.TenureMonth}
                //disabled={this.props.isBusy}
                error={
                  this.props.showRequiredField && !this.state.Model.TenureMonth
                }
                onChange={this.handleChange("TenureMonth").bind(this)}
                onKeyPress={(e) => {
                  handleNumericOnKeyPress(e);
                }}
                onKeyDown={(e) => {
                  handleNumericOnKeyDown(e);
                }}
              />
            </div>
          </div>
          <div className="ui one column grid" style={noMarginNoPadding}>
            <div className="column" style={someMarginNoPadding}>
              <div style={{ display: "inline-block", width: "91px" }}>
                Earned Leaves
              </div>
              <Popup
                trigger={<Icon size="small" name="question circle" circular />}
                content="Note: Total leave grants will be divided by the total months per year, the system will be automatically adding the result to the total leave grants per month."
                on="click"
                hideOnScroll
              />
              <Checkbox style={marginCheckBox}
                checked={this.state.Model.IsEarnedLeave}
                onChange={this.onEarnedLeavesChecked}
              />
            </div>
          </div>
          <div className="ui two column grid" style={noMarginNoPadding}>
            <div className="column">
              <div>DETAILS</div>
            </div>
            <div className="column">
              <div>
                <Popup
                  content="Delete"
                  trigger={
                    <Button
                      basic
                      floated="right"
                      icon="trash"
                      size="mini"
                      disabled={
                        tableRef === null || tableRef.state.selectedId === ""
                      }
                      onClick={this.btnDeleteDetail_Click.bind(
                        this,
                        tableRef === null ? "" : tableRef.state.selectedId
                      )}
                    />
                  }
                />
                <Popup
                  content="Edit"
                  disabled={
                    tableRef === null || tableRef.state.selectedId === ""
                  }
                  trigger={
                    <Button
                      basic
                      floated="right"
                      icon="edit"
                      size="mini"
                      disabled={
                        tableRef === null || tableRef.state.selectedId === ""
                      }
                      onClick={this.btnEditDetail_Click.bind(
                        this,
                        tableRef === null ? "" : tableRef.state.selectedId
                      )}
                    />
                  }
                />
                <Popup
                  content="Add"
                  trigger={
                    <Button
                      basic
                      floated="right"
                      icon="add"
                      size="mini"
                      onClick={this.btnAddDetail_Click.bind(this)}
                    />
                  }
                />
              </div>
            </div>
          </div>
          <div className="ui one column grid" style={noMarginNoPadding}>
            <div className="column" style={someMarginNoPadding}>
              <div>
                {model.Details.length > 0 && (
                  <LeaveProfileDetailsTable
                    ref={this.tableRef}
                    showAsModal={false}
                    // details={model.Details.map(x =>
                    //     this.props.createDetailsRow(x)
                    // )}
                    details={this.props.generateRowDetails(model.Details)}
                    detailsCol={this.props.createDetailsFields()}
                  />
                )}
                {this.state.showDetailForm && (
                  <LeaveProfileDetailsForm
                    ref={this.formRef}
                    open={this.state.showDetailForm}
                    data={
                      this.state.selectedId !== ""
                        ? this.state.Model.Details.filter(
                          (x) => x.LeaveTypeId === this.state.selectedId
                        )[0]
                        : undefined
                    }
                    existingLeaveIds={this.state.Model.Details.map(
                      (x) => x.LeaveTypeId
                    )}
                    onSubmit={this.onSaveDetail.bind(this)}
                    onCancel={this.onCloseDetailForm.bind(this)}
                  />
                )}
              </div>
            </div>
          </div>
        </Modal.Content>
        <Modal.Actions>
          <Button
            basic
            icon="save"
            content="Submit"
            disabled={this.props.isBusy}
            loading={this.props.isBusy}
            onClick={this.props.onSave}
          ></Button>
          <Button
            basic
            icon="cancel"
            content="Cancel"
            disabled={this.props.isBusy}
            onClick={this.props.onCancel}
          ></Button>
        </Modal.Actions>
      </Modal>
    );
  }
}
export default LeaveProfileForm;

import React, { Component } from 'react';
import { Button, Radio, Modal } from 'semantic-ui-react';
import 'semantic-ui-css/semantic.min.css';

class MessageBoxYesNo extends Component {
    state = {
        busy: false
    }
    handleClose = () => {
        this.props.onClose();
    }
    handleAction = () => {
        this.setState({ busy: true });
        this.props.onAction();
    }
    render() {
        return (
            <Modal open size='tiny'>
                <Modal.Header>{this.props.title}</Modal.Header>
                <Modal.Content>
                    <Modal.Description>
                        {this.props.caption}
                    </Modal.Description>
                </Modal.Content>
                <Modal.Actions>
                    <Button disabled={this.state.busy}
                        basic icon='check' content={this.props.action} loading={this.state.busy} onClick={this.handleAction.bind(this)}>
                    </Button>
                    <Button basic icon='cancel' content={this.props.CancelCaption || 'Cancel'} onClick={this.handleClose} disabled={this.state.busy}></Button>
                </Modal.Actions>
            </Modal>
        )
    }
}

export default MessageBoxYesNo
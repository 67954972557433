import moment from 'moment';
import React, { Component } from 'react';
import DataGrid from 'react-data-grid';
import 'semantic-ui-css/semantic.min.css';
import { Button, Divider, Dropdown, Form, Header, Input, Loader, Menu, Message, Modal, Table, TableCell, TableRow, TextArea } from 'semantic-ui-react';
import { ajaxPost } from '../../ajax';
import MessageBoxYesNo from '../../Commons/MessageBoxYesNo';
import SelectEmployees from '../../Commons/SelectEmployees';
import { checkDate, empty_id, fnSearchEmployeeFilter, isTaskAvailable, isNullOrEmpty } from '../../utils';
import CommonTypeForm from '../Commons/CommonTypeEditForm';

import modStyles from './PayrollLoanForm.module.css'

function dropdownMapping(list) {
    var profiles = [];
    if (list != null) {
        list.map(x => {
            return (
                profiles.push({ text: x.ProfileName, value: x.id })
            )
        })
    }
    return profiles;
}
function dropdownMappingEmployee(list) {
    var employees = [];
    if (list != null) {
        list.map(x => {
            var fullname = x.LastName + ", " + x.FirstName + " " + x.MiddleName;
            if (fullname.length > 45)
                fullname = fullname.slice(0, 45) + "...";

            return (
                employees.push({ label: fullname, value: x._id })
            )
        })
    }
    return employees;
}
const LoanStatus = [
    { text: "ACTIVE", value: "ACTIVE" },
    { text: "CLOSED", value: "CLOSED" }
]
const months = [
    { key: 1, text: 'January', value: 1 },
    { key: 2, text: 'Febuary', value: 2 },
    { key: 3, text: 'March', value: 3 },
    { key: 4, text: 'April', value: 4 },
    { key: 5, text: 'May', value: 5 },
    { key: 6, text: 'June', value: 6 },
    { key: 7, text: 'July', value: 7 },
    { key: 8, text: 'August', value: 8 },
    { key: 9, text: 'September', value: 9 },
    { key: 10, text: 'October', value: 10 },
    { key: 11, text: 'November', value: 11 },
    { key: 12, text: 'December', value: 12 },
];

const cutoffs = [
    { key: 1, text: 'Every Cutoff', value: "EVERY_CUTOFF" },
    { key: 2, text: 'Last Cutoff', value: "LAST_CUTOFF" },
    { key: 3, text: 'First Cutoff', value: "FIRST_CUTOFF" },
    { key: 4, text: 'Second Cutoff', value: "SECOND_CUTOFF" },
    { key: 5, text: 'Third Cutoff', value: "THIRD_CUTOFF" },
    { key: 6, text: 'Fourth Cutoff', value: "FOURTH_CUTOFF" },
    { key: 7, text: 'Fifth Cutoff', value: "FIFTH_CUTOFF" },

];

const cutoffNumbers = [
    { key: 1, text: '1', value: 1 },
    { key: 2, text: '2', value: 2 },
    { key: 3, text: '3', value: 3 },
    { key: 4, text: '4', value: 4 },
    { key: 5, text: '5', value: 5 },
];
class PayrollLoanForm extends Component {
    state = {
        ItemData: {
            _id: "",
            EmployeeIds: [],
            EmployeeId: empty_id,
            PayrollLoanTypeId: empty_id,
            LoanDate: moment().format("YYYY-MM-DD"),
            PrincipalAmount: 0,
            PayableAmount: 0,
            AmortizationAmount: 0,
            InheritedBalanceAmount: 0,
            Year: moment().format("YYYY"),
            Month: moment().format("MM"),
            CutoffNumber: 1,
            Remarks: "",
            Status: "ACTIVE",
            RecordDate: moment().format("YYYY-MM-DD"),
            PreviousLoanId: empty_id,
            IsRenewal: false,
            LoanPaymentSkips: [],
            AppliedCutOff: "EVERY_CUTOFF"
        },
        loanTypes: [],
        Employeelist: [],
        foreCast: [],
        editing: false,
        saving: false,
        loadForecast: false,
        dialogForecast: false,
        dialog: false,
        dialogContent: null,
        dialogTitle: '',
        conflict: [],
        showConflict: false,
        loanPayments: [],
        disableRemove: false,
        skipPaymentLoading: false,
        loanBalance: 0,

        selectedSkipPayment: null,
        modalSkipOpen: false,
        modalSkipErrorMessage: '',
        skipLoanError: false,
        blankSkipLoanModel: {
            Year: moment().format("YYYY"),
            Month: moment().format("M"),
            CutoffNumber: 1,
            Remarks: '',
            Posted: false
        },
        showLoanTypeForm: false,
        RemarksRemainingCount : 150
    }

    componentDidMount(){
        this.setState({ RemarksRemainingCount : 150 - this.state.ItemData.Remarks.length  });
    }

    constructor(props, context) {
        super(props, context);
        this.state.ItemData = props.selectedItem == null ? this.state.ItemData : props.selectedItem;
        var itemData = this.state.ItemData;
        itemData.LoanDate = moment(itemData.LoanDate).format("YYYY-MM-DD");
        if (props.selectedItem != null)
            itemData.EmployeeIds = [props.selectedItem.EmployeeId];
        this.setState({ ItemData: itemData });

        this.state.editing = props.selectedItem == null ? false : true;

        fnSearchEmployeeFilter("", data => { this.setState({ Employeelist: data }); }, 0, 20, false);
        this.loadLoanTypes(data => {
            this.setState({ loanTypes: data });
        });

        if (this.state.ItemData._id != '') {
            this.loadLoanPayments();
            this.getLoanBalance();
        }
        this._columns = [
            { key: 'Year', name: 'Year', width: 120, resizable: true },
            { key: 'MonthString', name: 'Month', width: 120, resizable: true },
            { key: 'CutoffNumber', name: 'Cutoff No', width: 100, resizable: true },
            { key: 'Amount', name: 'Amount', width: 150, resizable: true },
            { key: 'Status', name: 'Status', width: 150, resizable: true },
        ];
        this.modalRef = React.createRef();
        this.parentRef = React.createRef();
    }
    loadLoanPayments = () => {
        ajaxPost({
            url: 'api/PayrollLoanPayment/getLoanPaymentsById',
            data: {
                "_id": this.state.ItemData._id
            },
            onSuccess: data => {
                this.setState({ loanPayments: data });
            },
            finally: () => { }
        })
    }
    loadLoanTypes = (callback) => {
        ajaxPost({
            url: 'api/PayrollLoanType/LoanTypeNames',
            data: {
                "Name": 1,
                "_id": 1,
            },
            onSuccess: data => {
                callback(data);
            },
            finally: () => { }
        })
    }

    getLoanBalance = () => {
        ajaxPost({
            url: 'api/PayrollLoanPayment/getLoanBalanceById',
            data: {
                "ID": this.state.ItemData._id
            },
            onSuccess: balance => {
                this.setState({ loanBalance: balance });
            },
            finally: () => { }
        })
    }

    isSkipPaymentPosted = (skipPayment) => {

        if (skipPayment == null)
            return;

        this.setState({ skipPaymentLoading: true });

        ajaxPost({
            url: 'api/PayrollLoan/isSkipPaymentPosted',
            data: {
                "Year": skipPayment.Year,
                "Month": skipPayment.Month,
                "CutoffNumber": skipPayment.CutoffNumber,
                "EmployeeId": this.state.ItemData.EmployeeId
            },
            onSuccess: isPosted => {
                this.setState({ disableRemove: isPosted, skipPaymentLoading: false });
            },
            finally: () => { }
        })
    }

    generateLoanForeCast = (callback, model) => {
        ajaxPost({
            url: 'api/PayrollLoan/generateLoanForeCast',
            data: {
                "model": JSON.stringify(model),
                "includeDeleted": false,
                "startingIndex": 0,
                "itemCount": 1
            },
            onSuccess: data => {
                callback(data);
            },
            onError: function (err) {
                alert(JSON.stringify(err));
            },
            finally: () => { this.setState({ saving: false }); }
        })
    }
    save = (callback, model) => {

        model.PrincipalAmount = model.PrincipalAmount === "" ? 0 : model.PrincipalAmount;
        model.AmortizationAmount = model.AmortizationAmount === "" ? 0 : model.AmortizationAmount;
        model.PayableAmount = model.PayableAmount === "" ? 0 : model.PayableAmount;
        model.CutoffNumber = model.CutoffNumber === 0 ? 1 : model.CutoffNumber;

        ajaxPost({
            url: 'api/PayrollLoan/save',
            data: {
                "model": JSON.stringify(model),
                "includeDeleted": false,
                "startingIndex": 0,
                "itemCount": 1
            },
            onSuccess: data => {
                callback(data);
            },
            onError: function (err) {
                alert(JSON.stringify(err));
            },
            finally: () => { this.setState({ saving: false }); }
        })
    }
    rowGetter = (i) => {
        return this.state.foreCast[i];
    };

    handleSave = (e, data) => {
        this.setState({ saving: true });
        var model = this.state.ItemData;

        if(isNullOrEmpty(model.CutoffNumber) || isNullOrEmpty(model.PrincipalAmount) || isNullOrEmpty(model.PayableAmount) || isNullOrEmpty(model.AmortizationAmount)){
            this.setState({
                showConflict : true,
                saving : false,
                editing : false,
                conflict : ["All the fields are required."]
            });
            return;
        }
        this.save(data => {
            this.setState({
                saving: false,
                editing: false,
                showConflict: false
            }, () => {
                if (data.content != null) {
                    this.onCloseDialog(data.content);
                }
                if (data.errors != null) {
                    this.setState({
                        showConflict: true,
                        conflict: data.errors
                    });
                }
            });
        }, model);
    }
    handleGenerateForeCast = (e, data) => {
        this.setState({ loadForecast: true });
        var model = this.state.ItemData;
        this.generateLoanForeCast(data => {
            this.setState({ loadForecast: false, showConflict: false });
            if (data.content != null) {
                this.setState({
                    dialogForecast: true,
                    foreCast: data.content
                });

            }
            else {
                if (data.errors != null) {
                    this.setState({
                        showConflict: true,
                        conflict: data.errors
                    });
                }
            }

        }, model);
    }
    handleChange = name => event => {
        var current = this.state.ItemData;
        current[name] = event.target.value;
        this.setState({ ItemData: current });
        this.setState({RemarksRemainingCount : 150 - event.target.value.length  });

    };
    handleCheckChange = name => (trans, e) => {
        var current = this.state.ItemData;
        if (current[name] == undefined) {
            var transactions = current.Transactions;
            var index = transactions.indexOf(trans);
            var transaction = transactions[index];
            transaction[name] = transaction[name] === false ? true : false;
            transactions[index] = transaction;
            current.Transactions = transactions;
        } else {
            current[name] = current[name] === false ? true : false;
        }
        this.setState({ ItemData: current });
    }
    handleComboChange = (trans, name, val) => {
        var current = this.state.ItemData;
        if (current[val.name] == undefined) {
            var transactions = current.Transactions;
            var index = transactions.indexOf(trans);
            var transaction = transactions[index];
            transaction[val.name] = val.value;
            transactions[index] = transaction;
            current.Transactions = transactions;
        } else {
            current[val.name] = val.value;
        }
        this.setState({ ItemData: current });
    }
    onCloseDialog = (updatedModels) => {
        this.setState({ dialog: false });

        this.props.onUpdateComplete(updatedModels);
    }
    onCloseDialogForeCast = () => {
        this.setState({ dialogForecast: false });
    }
    dialogOnSuccess = () => {
        this.setState({ dialogTitle: "Save" });
        this.setState({
            dialogContent: (
                <div>
                    <p>Payroll Loan has been updated successfully</p>
                </div>
            )
        });
        this.setState({ dialog: true });
    }
    handleComboChange = (trans, name, val) => {
        var current = this.state.ItemData;
        if (current[val.name] == undefined) { //
            var payments = current.LoanPaymentSkips;
            var index = payments.indexOf(trans);
            var payment = payments[index];
            payment[val.name] = val.value;
            payments[index] = payment;
            current.LoanPaymentSkips = payments;
        } else {
            current[val.name] = val.value;
        }
        this.setState({ ItemData: current });
    }
    updateSearchIds = (ids) => {
        var itemData = this.state.ItemData;
        itemData.EmployeeIds = ids;
        itemData.EmployeeId = ids[0];
        this.setState({ ItemData: itemData });
    }

    createSkipPayment() {
        this.setState({
            selectedSkipPayment: JSON.parse(JSON.stringify(this.state.blankSkipLoanModel)),
            modalSkipOpen: true,
            modalSkipErrorMessage: '',
            skipLoanError: false
        })
    }

    deleteSkipPayment = () => {
        let { ItemData, selectedSkipPayment } = this.state;

        if (this.state.selectedSkipPayment === null) {
            return;
        }

        ItemData.LoanPaymentSkips = ItemData.LoanPaymentSkips.filter(x => !(x.Year === selectedSkipPayment.Year && x.Month === selectedSkipPayment.Month && x.CutoffNumber === selectedSkipPayment.CutoffNumber))
        this.setState({
            ItemData,
            selectedSkipPayment: null
        })
    }

    addSkipPayment = () => {
        const { selectedSkipPayment, ItemData, loanPayments } = this.state;
        if (selectedSkipPayment.Year === '' || selectedSkipPayment.Month === '' || selectedSkipPayment.CutoffNumber === '' || selectedSkipPayment.Remarks === '') {
            this.setState({
                modalSkipErrorMessage: 'Please fill up all fields.',
                skipLoanError: true
            });
            return;
        }

        const payment = loanPayments.find(x => x.Year == selectedSkipPayment.Year && x.Month == selectedSkipPayment.Month && x.CutoffNumber == selectedSkipPayment.CutoffNumber &&
            x.EmployeeId == ItemData.EmployeeId);

        if (payment != null) {
            this.setState({
                modalSkipErrorMessage: 'Unable to add skip. Cutoff period already paid',
                skipLoanError: true
            });
            return;
        }

        var skipPaymentDate = moment(selectedSkipPayment.Year + '-' + selectedSkipPayment.Month + '-' + selectedSkipPayment.CutoffNumber);
        var loanDate = moment(ItemData.Year + '-' + ItemData.Month + '-' + ItemData.CutoffNumber);
        if (skipPaymentDate < loanDate) {
            this.setState({
                skipLoanError: true,
                modalSkipErrorMessage: 'Skip payment must be greater than the loan date.'
            })
            return;
        }

        if (ItemData.LoanPaymentSkips.filter(x => x.Year === selectedSkipPayment.Year && x.Month === selectedSkipPayment.Month && x.CutoffNumber === selectedSkipPayment.CutoffNumber).length > 0) {
            this.setState({
                skipLoanError: true,
                modalSkipErrorMessage: 'Duplicate entry detected.'
            })
            return;
        }


        ItemData.LoanPaymentSkips.push(selectedSkipPayment);
        this.setState({
            ItemData,
            modalSkipOpen: false,
            selectedSkipPayment: null,
        })
    }

    closeSkipPayment = () => {
        this.setState({ modalSkipOpen: false })
    }

    yearValidation = (e) => {
        var year = e.target.value;
        var text = /^[0-9]+$/;
        if (e.type == "blur" || year.length == 4 && e.keyCode != 8 && e.keyCode != 46) {
            if (year != 0) {
                if ((year != "") && (!text.test(year))) {
                    e.preventDefault();
                }

                if (year.length != 4) {
                    e.preventDefault();
                }
                var current_year = new Date().getFullYear();
                if ((year < 1920) || (year > current_year)) {
                    e.preventDefault();
                }
            }
        }
    }

    skipPaymentOnChange = (field, e) => {
        let { selectedSkipPayment } = this.state;
        selectedSkipPayment[field] = e.target.value;

        this.setState({ selectedSkipPayment });
    }

    skipPaymentComboBoxhange = (field, e, val) => {
        let { selectedSkipPayment } = this.state;
        selectedSkipPayment[field] = val.value;

        this.setState({ selectedSkipPayment });
    }

    loanSkipComparer(x, y) {
        if (y === null)
            return false;

        if (x.Year === y.Year && x.Month === y.Month && x.CutoffNumber === y.CutoffNumber)
            return true;

        return false;
    }

    onTableClick = (model, e) => {
        this.setState({ selectedSkipPayment: model });

        // if (this.loanSkipComparer(model, selectedSkipPayment) === false)
        //     this.setState({ selectedSkipPayment: model });
        // else
        //     this.setState({ selectedSkipPayment: null });

        this.isSkipPaymentPosted(model);
    }

    onCreateNewItemClick = () => {
        this.setState({ showAskCloseDialog: true });
    }

    onCreateNewTypeClick = () => {
        this.setState({ showLoanTypeForm: true });
    }

    messageBoxOkOnlyOpen = (isOpen, title = "", caption = "") => {
        var messageBoxOkOnly = {
            show: isOpen,
            title: title,
            caption: caption,
        };
        this.setState({ messageBoxOkOnly });
    }

    validate = (data) => {
        var errTitle = "", errCaption = "";
        // if (this.isNullOrEmpty(data.Name)
        // 	|| this.isNullOrEmpty(data.Description)
        // ) {
        // 	errCaption = "Please fill up all required field/s";
        // 	errTitle = "Invalid";
        // }
        if (isNullOrEmpty(data.Name)) {
            errCaption = "'Name' field is required";
            errTitle = "Invalid";
        }
        else {
            this.setState({ showRequiredField: false });
            return true;
        }
        this.setState({ showRequiredField: true, errTitle, errCaption });
        return false;
    };

    saveFromModal = (data) => {
        let self = this;
        let isValid = false;

        isValid = this.validate(data);
        if (!isValid) {
            return;
        }

        this.setState({ isSaving: true });
        let addNew = isNullOrEmpty(data._id) || data._id == empty_id;

        var parameter = {
            data: {
                model: JSON.stringify(data)
            },
            url: "api/PayrollLoanType/save",
            onSuccess: (data, sender) => {
                if (data.hasOwnProperty("errors") && data.errors.length > 0) {
                    isValid = false;
                    self.setState({
                        showRequiredField: true,
                        errTitle: "Invalid",
                        errCaption: data.errors.map(err => { return (<p>{err}</p>) })
                    }, () => {
                        self.messageBoxOkOnlyOpen(true, "Failed", "Data not successfully saved");
                    });
                    return;
                }
                isValid = true;

                var model = this.state.ItemData;
                model.PayrollLoanTypeId = data.content["_id"];
                this.setState({ ItemData: model });

                this.loadLoanTypes(data => {
                    this.setState({ loanTypes: data });
                })

                if (this.state.ItemData._id != '') {
                    this.loadLoanPayments();
                    this.getLoanBalance();
                }
            },
            onError: (error) => {
                isValid = false;
                var errorMessage = "An error has occured";
                if (error) {
                    errorMessage += ":\r\n" + JSON.stringify(error);
                }
                this.setState({ showRequiredField: true, errTitle: "Error", errCaption: errorMessage });
            },
            finally: () => {
                this.setState({ isSaving: false });
                // close modal 
                if (isValid) {
                    self.setState({ showLoanTypeForm: false });
                };
            }
        }
        ajaxPost(parameter);
    }

    render() {
        const self = this;
        return <>
            <div className={modStyles.module}>
                {
                    <CommonTypeForm Title="Create New Type" open={this.state.showLoanTypeForm}
                        ref={self.modalRef}
                        errTitle={self.state.errTitle}
                        errCaption={self.state.errCaption}
                        showRequiredField={self.state.showRequiredField}
                        isBusy={self.state.isSaving}
                        onCancel={() => { 
                            self.setState({ showLoanTypeForm: false }, self.setState({ showRequiredField: false })); 
                        }}
                        onSave={() => {
                            self.saveFromModal(
                                self.modalRef.current.getContent()
                            );
                        }} />
                }
                <div style={{ overflow: "hidden" }}>
                    <h2 style={{ color: "#606060", marginTop: '1%', float: "left" }}>Payroll Loan Details</h2>
                </div>
                <Menu size="mini" secondary stackable>
                    <Menu.Menu position="right" >
                        <Button basic content="Save" size="mini" icon="save"
                            onClick={this.handleSave.bind(this)} style={{ margin: "0px 3px 0px 0px" }} />
                        <Button basic content="Cancel" size="mini" icon="cancel"
                            onClick={this.props.onCloseForm.bind(this)} />
                    </Menu.Menu>
                </Menu>
                <Loader active={this.state.saving} />
                {this.state.showConflict && this.state.conflict &&
                    <Message negative>
                        <Message.Header>Errors</Message.Header>
                        <Message.Content>{this.state.conflict.map(n => {
                            return (<p>{n}</p>)
                        })}</Message.Content>
                    </Message>}
                <Divider></Divider>
                {
                    this.state.showAskCloseDialog &&
                    <MessageBoxYesNo title="Create New Employee"
                        action="Yes"
                        onClose={() => { this.setState({ showAskCloseDialog: false }) }}
                        onAction={() => { this.props.onCreateEmpClick() }}
                        caption="All unsaved changes will be lost. Do you wish to continue?"
                    />
                }
                <div>
                <Table >
                    <Table.Row>
                        <Table.Cell>Employee</Table.Cell>
                        <Table.Cell>
                            <SelectEmployees disabled={this.state.editing} Ids={this.state.ItemData.EmployeeIds} update={this.updateSearchIds.bind(this)} />
                            {
                                isTaskAvailable("EMPLOYEE_ADD") && this.state.ItemData._id == '' &&
                                <a as='a' href='#' onClick={this.onCreateNewItemClick.bind(this)}>Create New</a>
                            }
                        </Table.Cell>
                        <Table.Cell>Loan Status</Table.Cell>
                        <Table.Cell>
                            <Dropdown fluid selection options={LoanStatus}
                                placeholder="Status"
                                value={this.state.ItemData.Status} name="Status"
                                onChange={this.handleComboChange.bind(this, "")}
                            />
                        </Table.Cell>
                    </Table.Row>
                    <Table.Row>
                        <Table.Cell>Loan Date</Table.Cell>
                        <Table.Cell>
                            <Input fluid required
                                size="mini"
                                type='date'
                                value={this.state.ItemData.LoanDate}
                                error={!checkDate(this.state.ItemData.LoanDate).Result}
                                onChange={this.handleChange("LoanDate").bind(this)}
                            //onChange={(evt) => this.setState({ fromDate: evt.target.value })}
                            />
                        </Table.Cell>
                        <Table.Cell>Principal Amount</Table.Cell>
                        <Table.Cell>
                            <Input fluid placeholder='Principal Amount' type='number'
                                value={this.state.ItemData.PrincipalAmount}
                                onChange={this.handleChange("PrincipalAmount").bind(this)}
                            />
                        </Table.Cell>
                    </Table.Row>
                    <Table.Row>
                        <Table.Cell>Loan Type</Table.Cell>
                        <Table.Cell>
                            {!this.state.editing ?
                                <div>
                                    <Dropdown fluid selection options={dropdownMapping(this.state.loanTypes)}
                                        placeholder="Loan Type"
                                        value={this.state.ItemData.PayrollLoanTypeId} name="PayrollLoanTypeId"
                                        onChange={this.handleComboChange.bind(this, "")}
                                    />
                                    {
                                        isTaskAvailable("PAYROLL_LOAN_TYPE_CREATE") && this.state.ItemData._id == '' &&
                                        <a as='a' href='#' onClick={this.onCreateNewTypeClick.bind(this)}>Create New</a>
                                    }
                                </div>
                                : <label>{this.state.ItemData.LoanType}</label>}

                        </Table.Cell>
                        <Table.Cell>Payable Amount</Table.Cell>
                        <Table.Cell>
                            <Input fluid placeholder='Payable Amount' type='number'
                                value={this.state.ItemData.PayableAmount}
                                onChange={this.handleChange("PayableAmount").bind(this)}
                            />
                        </Table.Cell>
                    </Table.Row>
                    <Table.Row>
                        <Table.Cell>Month</Table.Cell>
                        <Table.Cell>
                            <Dropdown fluid selection options={months}
                                placeholder="Month"
                                value={parseInt(this.state.ItemData.Month)} name="Month"
                                onChange={this.handleComboChange.bind(this, "")}
                            />
                        </Table.Cell>
                        <Table.Cell>Amortization</Table.Cell>
                        <Table.Cell>
                            <Input fluid placeholder='Amortization' type='number'
                                value={this.state.ItemData.AmortizationAmount}
                                onChange={this.handleChange("AmortizationAmount").bind(this)}
                            />
                        </Table.Cell>
                    </Table.Row>
                    <Table.Row>
                        <Table.Cell>Year</Table.Cell>
                        <Table.Cell>
                            <Input fluid placeholder='Year'  type='number'
                                value={this.state.ItemData.Year}
                                onChange={this.handleChange("Year").bind(this)}
                            />
                        </Table.Cell>
                        <Table.Cell>Applied Cutoff</Table.Cell>
                        <Table.Cell>
                            <Dropdown fluid selection options={cutoffs}
                                placeholder="Applied Cutoff"
                                value={this.state.ItemData.AppliedCutOff} name="AppliedCutOff"
                                onChange={this.handleComboChange.bind(this, "")}
                            />
                        </Table.Cell>
                    </Table.Row>
                    <Table.Row>
                        <Table.Cell>Cutoff No</Table.Cell>
                        <Table.Cell>
                            <Dropdown fluid selection options={cutoffNumbers}
                                placeholder="Cutoff Number"
                                value={this.state.ItemData.CutoffNumber} name="CutoffNumber"
                                onChange={this.handleComboChange.bind(this, "")}
                            />
                        </Table.Cell>
                        <Table.Cell colSpan={2}>
                            <Button content="Forecast Payment" size="mini" onClick={this.handleGenerateForeCast.bind(this)} />
                        </Table.Cell>
                    </Table.Row>
                    <Table.Row>
                        <Table.Cell>Remarks</Table.Cell>
                        <Table.Cell>
                            <div style={{display:'flex', flexDirection:'column', alignItems: 'flex-end'}}>
                            <TextArea fluid style={{ width: '100%' }}
                                value={this.state.ItemData.Remarks}
                                onChange={this.handleChange("Remarks").bind(this)}
                                maxLength={150}
                            />
                            <label><i>{this.state.RemarksRemainingCount} / 150 Characters</i></label>
                            </div>
                        </Table.Cell>
                    </Table.Row>
                    <Table.Row>
                        <Table.Cell colSpan={4}>
                            <div style={{ display: 'flex', width: '100%' }}>
                                <div>
                                    <p style={{ marginTop: 'auto', marginBottom: 'auto' }}>Skip Payments</p>
                                </div>
                                <div style={{ display: 'flex', marginLeft: 'auto', right: 0 }}>
                                    <Button icon='add' onClick={this.createSkipPayment.bind(this)}>Add</Button>
                                    <Button icon='delete' loading={this.state.skipPaymentLoading} onClick={this.deleteSkipPayment.bind(this)} disabled={this.state.disableRemove}>Remove</Button>
                                </div>
                            </div>
                            <Table>
                                <Table.Header>
                                    <Table.Row>
                                        <Table.HeaderCell>Year</Table.HeaderCell>
                                        <Table.HeaderCell>Month</Table.HeaderCell>
                                        <Table.HeaderCell>Cutoff</Table.HeaderCell>
                                        <Table.HeaderCell>Remarks</Table.HeaderCell>
                                        {/* <Table.HeaderCell>Posted</Table.HeaderCell> */}
                                    </Table.Row>
                                </Table.Header>
                                <Table.Body>
                                    {this.state.ItemData.LoanPaymentSkips !== undefined && this.state.ItemData.LoanPaymentSkips.map(x => {
                                        return (
                                            <Table.Row style={this.loanSkipComparer(x, this.state.selectedSkipPayment) ? { backgroundColor: 'lightgray' } : {}}
                                                onClick={this.onTableClick.bind(this, x)}>
                                                <Table.Cell>{x.Year}</Table.Cell>
                                                <Table.Cell>{x.Month}</Table.Cell>
                                                <Table.Cell>{x.CutoffNumber}</Table.Cell>
                                                <Table.Cell>{x.Remarks}</Table.Cell>
                                                {/* <Table.Cell>{x.Posted? 'Yes': 'No'}</Table.Cell> */}
                                            </Table.Row>
                                        )
                                    })}
                                </Table.Body>
                            </Table>
                        </Table.Cell>
                    </Table.Row>
                    <Table.Row>
                        <Table.Cell colSpan={4}>
                            <div style={{ display: 'flex', width: '100%' }}>
                                <div>
                                    <p style={{ marginTop: 'auto', marginBottom: 'auto' }}>Amortization History</p>
                                </div>
                            </div>
                            <Table>
                                <Table.Header>
                                    <Table.Row>
                                        <Table.HeaderCell>Year</Table.HeaderCell>
                                        <Table.HeaderCell>Month</Table.HeaderCell>
                                        <Table.HeaderCell>Cutoff</Table.HeaderCell>
                                        <Table.HeaderCell>Amount</Table.HeaderCell>
                                        {/* <Table.HeaderCell>Posted</Table.HeaderCell> */}
                                    </Table.Row>
                                </Table.Header>
                                <Table.Body>
                                    {this.state.loanPayments.map(x => {
                                        return (
                                            <Table.Row onClick={this.onTableClick.bind(this, x)}>
                                                <Table.Cell>{x.Year}</Table.Cell>
                                                <Table.Cell>{x.Month}</Table.Cell>
                                                <Table.Cell>{x.CutoffNumber}</Table.Cell>
                                                <Table.Cell>{x.Amount}</Table.Cell>
                                                {/* <Table.Cell>{x.Posted? 'Yes': 'No'}</Table.Cell> */}
                                            </Table.Row>
                                        )
                                    })}
                                </Table.Body>
                            </Table>
                        </Table.Cell>
                    </Table.Row>
                </Table>
                </div>
            </div>

                {/* Modal Form */}
                {<Modal size="mini" open={this.state.dialog} onClose={this.close} >
                    <Header content={this.state.dialogTitle} />
                    <Modal.Content>
                        {this.state.dialogContent}
                    </Modal.Content>
                    <Modal.Actions>
                        <Button basic icon="check" content="Yes"
                            onClick={this.onCloseDialog.bind(this, [this.state.ItemData])} />
                    </Modal.Actions>
                </Modal>}
                {
                    <Modal size="small" open={this.state.dialogForecast} onClose={this.close} >
                        <Header content="Loan Forecast" />
                        <Modal.Content>
                            <div style={{ display: 'flex' }}>
                                <h5 style={{ marginLeft: 'auto' }}>Balance: {this.state.loanBalance}</h5>
                            </div>
                            <br />
                            <div style={{ width: '100%' }}>
                                <DataGrid
                                    columns={this._columns}
                                    rows={this.state.foreCast}
                                    rowsCount={this.state.foreCast.length}
                                    minHeight={12 * 30}
                                />
                            </div>
                        </Modal.Content>
                        <Modal.Actions>
                            <Button basic icon="check" content="Close"
                                onClick={this.onCloseDialogForeCast.bind(this)} />
                        </Modal.Actions>
                    </Modal>
                }
                {this.state.modalSkipOpen &&
                    <Modal open size='mini'>
                        <Header content='Skip Payment' />
                        <Modal.Content>
                            <Form error={this.state.skipLoanError}>
                                <Message
                                    error
                                    header='Invalid'
                                    content={this.state.modalSkipErrorMessage}
                                />
                                <Form.Input label='Year *' value={this.state.selectedSkipPayment.Year} onChange={this.skipPaymentOnChange.bind(this, 'Year')} onKeyPress={this.yearValidation} placeholder='year' />
                                <Form.Dropdown fluid selection options={months} value={parseInt(this.state.selectedSkipPayment.Month)} defaultValue={this.state.selectedSkipPayment.Month} onChange={this.skipPaymentComboBoxhange.bind(this, 'Month')} label='Month *' placeholder='month' />
                                <Form.Input label='Cutoff *' value={this.state.selectedSkipPayment.CutoffNumber} onChange={this.skipPaymentOnChange.bind(this, 'CutoffNumber')} placeholder='cutoff' />
                                <Form.Input label='Remarks *' value={this.state.selectedSkipPayment.Remarks} onChange={this.skipPaymentOnChange.bind(this, 'Remarks')} placeholder='remarks' maxLength={50}/>
                                <label style={{marginLeft:'65%'}}><i>{this.state.selectedSkipPayment.Remarks.length} / 50 Characters</i></label>
                            </Form> 
                        </Modal.Content>
                        <Modal.Actions>
                            <Button floated='right' onClick={this.closeSkipPayment}>Close</Button>
                            <Button style={{ marginBottom: '10px' }} floated='right' onClick={this.addSkipPayment}>Add</Button>
                        </Modal.Actions>
                    </Modal>
                }
            </>
    }
}
export default PayrollLoanForm;
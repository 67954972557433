import React, { Component } from 'react';
import { Button, Header, Icon, Label, List, Loader, Tab, Menu, Pagination, Card, Table, Divider, Modal, TableRow, TableCell, Container } from 'semantic-ui-react';
import 'semantic-ui-css/semantic.min.css';
import Select from 'react-select'
import { ajaxPost } from '../../ajax';
import moment from 'moment';
import { isTaskAvailable } from '../../utils';
import MessageBoxYesNo from '../../Commons/MessageBoxYesNo';
import AddNewEmployeeForm from './AddNewEmployeeForm';
import modStyles from './CutoffList.module.css'
class CutoffList extends Component {
    state = {
        lstStatus: [],
        year: parseInt(moment().format("YYYY")),
        initialLoad: true,
        initialDTRLoad: true,
        showAddEmployee: false,
        loading: false,
        list: [],
        cutoffType: 'STANDARD',
        activeTabindex: 0,
        selected: null,
        page: 1,
        rowPerPage: 12,
        totalPages: 1,
        addPayrollCutoffDialog: false,
        addCutoffsDialog: false,
        addCutoffDialog: false,
        addCutoffs: false,
        deleteDialog: false,
        deleteDialogContent: null,

        UserAdd: false,
        UserCanView: false,
        UserCanDelete: false,
        selectedCompany: null,
        companyList: [],
        isCompanyLoading: false,
        selectedStatus: "ALL",
    }
    constructor(props) {
        super(props);
        this.state = props.searchState;
        this.loadCompanies();
    }
    componentWillMount() {

        if (this.state.initialLoad === true) {
            this.setState({ initialLoad: false });
            const page = this.state.page;
            let startIndex = page - 1;
            startIndex = startIndex < 0 ? 0 : startIndex;
            const rowPerPage = this.state.rowPerPage;
            const currentYear = this.state.year;
            this.setState({ loading: true });
            this.LoadCutoffs(data => {
                var totalPages = data.count / this.state.rowPerPage;
                totalPages -= totalPages % 1;
                if (data.count > totalPages * this.state.rowPerPage) {
                    totalPages++;
                }

                this.setState({
                    list: data.content,
                    totalPages: totalPages,
                    loading: false
                });
                this.props.onUpdateState(this.state, [], []);
            }, currentYear, 'STANDARD', startIndex, rowPerPage);
            this.loadStatus();
        }
        var canAdd = isTaskAvailable("CUTOFF_CREATE");
        var canView = isTaskAvailable("CUTOFF_VIEW");
        var canDelete = isTaskAvailable("CUTOFF_DELETE");
        this.setState({
            UserCanAdd: canAdd,
            UserCanView: canView,
            UserCanDelete: canDelete
        });


    }
    componentWillUnmount = () => {
        this.props.onUpdateState(this.state, [], []);
    }

    loadCompanies() {
        this.setState({ isCompanyLoading: true })
        ajaxPost({
            url: 'api/group/search',
            data: {
                Search: '',
                startingIndex: 0,
                itemCount: 999,
                Types: ['COMPANY']
            },
            onSuccess: (data) => {
                this.setState({
                    companyList: data.content.map(x => { return ({ label: x.Name, value: x._id }) }),
                    isCompanyLoading: false
                });
            },
            finally: () => { }
        })
    }

    loadStatus = () => {
        const statuses = ["ALL", "PREVIEW", "POSTED"];
        this.setState({ lstStatus: statuses.map(x => { return ({ label: x, value: x }) }) });
    }

    LoadCutoffs = (callback, year, type, startIndex, itemCount) => {
        ajaxPost({
            url: 'api/Cutoff/search',
            data: {
                "Year": year,
                "Type": type,
                "CompanyId": this.state.selectedCompany != null ? this.state.selectedCompany.value : undefined,
                "StartIndex": startIndex,
                "MaxData": itemCount,
                "Status": this.state.selectedStatus.value
            },
            onSuccess: data => {
                callback(data);
            },
            finally: () => { }
        })
    }
    deleteCutoff = (id) => {


        ajaxPost({
            url: 'api/Cutoff/delete',
            data: {
                "_id": id,
            },
            onSuccess: data => {
                this.setState({ list: this.state.list.filter(item => item._id !== id) })
                this.setState({ selected: null });
            },
            finally: () => { }
        })
    }
    handleChangePage = (event, { activePage }) => {
        if (isNaN(activePage))
            return;
        let startIndex = activePage - 1;
        startIndex = startIndex < 0 ? 0 : startIndex;
        this.setState({ page: activePage });
        this.setState({ loading: true });
        const currentYear = this.state.year;
        const rowPerPage = this.state.rowPerPage;
        this.LoadCutoffs(data => {
            var totalPages = data.count / this.state.rowPerPage;
            totalPages -= totalPages % 1;
            if (data.count > totalPages * this.state.rowPerPage) {
                totalPages++;
            }
            this.setState({
                loading: false, totalPages: totalPages,
                list: data.content
            });
            this.props.onUpdateState(this.state, [], []);

        }, currentYear, this.state.cutoffType, startIndex * rowPerPage, rowPerPage);

    }

    handleAdd = () => {
        this.onAddPayrollCutoff();
        // this.setState({ addPayrollCutoffDialog: true });//change this to use other cutoff form
    }
    onAddPayrollCutoff = () => {
        this.setState({ addPayrollCutoffDialog: false },
            () => {
                this.props.onAddPayrollCutoff();
            });
    }
    onAddCutoffs = () => {
        this.setState({ addCutoffsDialog: false },
            () => {
                this.props.onAddCutoffs();
            }
        );
    }
    onAddCutoff = () => {
        this.setState({ addCutoffDialog: false }, () => {
            this.props.onAdd();
        });
    }
    onCancelCutoffs = () => {
        this.setState({ addCutoffsDialog: false }, () => {
            this.setState({ addCutoffDialog: true });
        });
    }
    handleClick = (val, event) => {
        var year = this.state.year + val;
        const rowPerPage = this.state.rowPerPage;
        this.setState({ year: year });
        this.setState({ loading: true });
        this.LoadCutoffs(data => {
            var totalPages = data.count / this.state.rowPerPage;
            totalPages -= totalPages % 1;
            if (data.count > totalPages * this.state.rowPerPage) {
                totalPages++;
            }
            this.setState({
                loading: false, totalPages: totalPages,
                list: data.content
            });
            this.props.onUpdateState(this.state, [], []);
        }, year, this.state.cutoffType, 0, rowPerPage);
    }
    handleChange = (e, data) => {
        var selectedType = '';
        switch (data.activeIndex) {
            case 0:
                selectedType = 'STANDARD';
                break;
            case 1:
                selectedType = 'OFFCYCLE';
                break;
            case 2:
                selectedType = '13TH MONTH PAY';
                break;
            case 3:
                selectedType = 'LEAVE CONVERSION';
                break;
            case 4:
                selectedType = 'QUIT CLAIM';
                break;

        }
        this.setState({ activeTabindex: data.activeIndex });
        this.setState({ loading: true });
        this.LoadCutoffs(data => {
            var totalPages = data.count / this.state.rowPerPage;
            totalPages -= totalPages % 1;
            if (data.count > totalPages * this.state.rowPerPage) {
                totalPages++;
            }
            this.setState({
                loading: false, totalPages: totalPages,
                list: data.content,
                cutoffType: selectedType,
                loading: false
            });
            this.props.onUpdateState(this.state, [], []);
        }, this.state.year, selectedType, 0, this.state.rowPerPage);
    }
    handlePress = event => {
    }
    handleDelete = (model) => {
        this.setState({ deleteDialog: true });
        this.setState({
            deleteDialogContent: (
                <div>
                    <p>Are you sure you want to delete Cutoff :</p>
                    <p> {moment(model.Year + "-" + model.Month + "-01").format("YYYY MMMM") + " , " + (model.CutoffNumber === 1 ? "1st" : "2nd")}?</p>
                </div>
            )
        });
        this.setState({ selected: model });
    }
    onDeleteDialogClose = (IsDeleted) => {
        this.setState({ deleteDialog: false });
        if (IsDeleted) {
            var id = this.state.selected._id;
            if (id != null)
                this.deleteCutoff(id);
        }

    }
    handleShowAddEmployee = (model) =>{
        this.setState({selected : model},() => {
            this.setState({ showAddEmployee: true});
        });
    }
    handleAddEmployee = (sending,ids) => {
        if(sending){ 
            ajaxPost({
                url: 'api/Cutoff/addNewCutoffEmployee',
                data: {
                "EmployeeIds":  ids ,
                "CutoffId": this.state.selected._id
            },
            onSuccess: data => {
                alert("successfully added.");
            },
            onError: function (err) {
                alert(JSON.stringify(err));
            },
            finally: () => {this.setState({saving:false});}
            })
        }
        this.setState({ showAddEmployee: false});
    }
    render() {
        const panes = [
            { menuItem: 'STANDARD' },
            { menuItem: 'OFFCYCLE PAY' },
            { menuItem: '13TH MONTH PAY' },
            { menuItem: 'LEAVE CONVERSION' },
            { menuItem: 'QUIT CLAIM' },
        ]
        return (
            <div className={modStyles.module}>
                <div style={{ color: "#606060", marginTop: '1%' }}>
                    <h2>Cutoff List</h2>
                </div>

                <Menu size="small" secondary style={{ width: '100%' }}>
                    <Menu.Item>
                        {this.state.UserCanAdd && <Button size="mini" icon="add" content="Create New"
                            // disabled={this.state.UserCanAdd}
                            onClick={this.handleAdd.bind(this)} />
                        }
                    </Menu.Item>
                    {/* <Button size='mini' icon='refresh' content='Refresh' onClick={this.handleClick.bind(this, 0)} ></Button> */}
                    <Menu.Menu position="right">
                        <Menu.Item>
                            <div style={{ width: '20em' }}>
                                <Select value={this.state.selectedCompany} isLoading={this.state.isCompanyLoading} options={this.state.companyList}
                                    onChange={(e) => this.setState({ selectedCompany: e })} isClearable />
                            </div> &nbsp;
                            <div style={{ width: '10em' }}>
                                <Select value={this.state.selectedStatus} isLoading={this.state.loading} options={this.state.lstStatus}
                                    onChange={(e) => this.setState({ selectedStatus: e })} />
                            </div>
                            <Button type='submit' style={{ marginLeft: '6px' }} onClick={this.handleClick.bind(this, 0)}>Search</Button>
                        </Menu.Item>
                        <Button.Group>
                            <Button basic icon="angle left" size="mini"
                                onClick={this.handleClick.bind(this, -1)} />
                            <Button basic width="200">
                                {this.state.year}
                            </Button>
                            <Button basic icon="angle right" size="mini"
                                onClick={this.handleClick.bind(this, 1)} />
                        </Button.Group>
                    </Menu.Menu>
                </Menu>
                <Divider />
                <Loader active={this.state.loading} />
                <Tab menu={{ stackable: true, tabular: true }} panes={panes} activeIndex={this.state.activeTabindex} onTabChange={this.handleChange.bind(this)}></Tab>
                <br />
                <Card.Group itemsPerRow={3} stackable >
                    {this.state.list != null && this.state.list.map(n => {
                        return (
                            <Card fluid color='olive' style={{height:'20em'}}>
                                <Card.Content>
                                    <Label style={n.Status == "POSTED" ? { backgroundColor: '#00B5AD', color: 'white' } : { backgroundColor: '#fc3232', color: 'white' }} ribbon='right'>{n.Status}</Label>
                                    <Card.Header>
                                        {moment(n.Year + "-" + n.Month + "-01").format("MMMM YYYY")}
                                    </Card.Header>
                                    <Card.Meta>{n.Company}<br />{n.Type}</Card.Meta>
                                    <Card.Description>
                                        Cutoff No. {n.CutoffNumber} <br />
                                        Period : {moment(n.DateStart).format("MMM DD")} - {moment(n.DateEnd).format("MMM DD")} <br />
                                        Frequency : {n.CutoffFrequency == "SEMIMONTHLY" ? "SEMI-MONTHLY" : n.CutoffFrequency}
                                    </Card.Description>
                                </Card.Content>
                                <Card.Content extra>
                                    <List divided horizontal link>
                                        {this.state.UserCanView && <List.Item as="a" onClick={this.props.onView.bind(this, this.state, n)}
                                        // disabled={this.state.UserCanView}
                                        >[View]</List.Item>
                                        }
                                        {/* {n.Status =="PREVIEW" && <List.Item as="a" onClick={this.props.onEdit.bind(this,this.state,n)}>[Edit]</List.Item>} */}
                                        {n.Status === "PREVIEW" && this.state.UserCanDelete && <List.Item as="a" onClick={this.handleDelete.bind(this, n)}
                                        // disabled={this.state.UserCanDelete}
                                        >[Delete]</List.Item>}
                                        {n.Status === "PREVIEW" && this.state.UserCanAdd &&
                                            <List.Item as="a" onClick={this.handleShowAddEmployee.bind(this,n)} content={"[Add Employee]"} /> }
                                    </List>
                                </Card.Content>
                            </Card>
                        )
                    })}
                </Card.Group>


                {/* <Table sigleLine selectable striped size="small">
                <Table.Header style={{ backgroundColor: 'gainsboro' }}>
                    <Table.Row>
                        <Table.HeaderCell >Month</Table.HeaderCell>
                        <Table.HeaderCell width="80" >Cutoff Number</Table.HeaderCell>
                        <Table.HeaderCell >Date</Table.HeaderCell>
                        <Table.HeaderCell >Status</Table.HeaderCell>
                        <Table.HeaderCell >Action</Table.HeaderCell>
                    </Table.Row>
                </Table.Header>
            <Table.Body>
                {this.state.list != null && this.state.list.map(n => {
                    return(
                        <TableRow style={{ 'font-size': '12px' }}>
                            <TableCell>{moment(n.Year +"-"+ n.Month +"-01").format("YYYY MMMM") }</TableCell>
                            <TableCell>{n.CutoffNumber}</TableCell>
                            <TableCell>{moment(n.DateStart).format("MMM DD")} - {moment(n.DateEnd).format("MMM DD")}</TableCell>
                            <TableCell>{n.Status}</TableCell>
                            <TableCell>
                                 <List divided horizontal link>
                                    <List.Item as="a" onClick={this.props.onView.bind(this,this.state,n)} disabled={this.state.UserCanView}>[View]</List.Item>
                                     {n.Status =="PREVIEW" && <List.Item as="a" onClick={this.props.onEdit.bind(this,this.state,n)}>[Edit]</List.Item>} 
                                   {n.Status ==="PREVIEW" &&  <List.Item as="a" onClick={this.handleDelete.bind(this,n)} disabled={this.state.UserCanDelete}>[Delete]</List.Item>}
                                </List>
                            </TableCell>
                        </TableRow>
                    );
                })}  
            </Table.Body>
        </Table> */}

                {/* Modal Form */}
                {this.state.deleteDialog && <Modal size="mini" open={true} onClose={this.onDeleteDialogClose.bind(this, false)}>
                    <Header content="Delete Cutoff" />
                    <Modal.Content>
                        {this.state.deleteDialogContent}
                    </Modal.Content>
                    <Modal.Actions>
                        <Button basic icon="check" content="Yes"
                            onClick={this.onDeleteDialogClose.bind(this, true)} />
                        <Button basic icon="cancel" content="No"
                            onClick={this.onDeleteDialogClose.bind(this, false)} />
                    </Modal.Actions>
                </Modal>}
                {this.state.addCutoffsDialog && <MessageBoxYesNo title="Create Cutoff"
                    caption="Do you want to create cutoff for whole year.  Do you wish to proceed?" onAction={this.onAddCutoffs.bind(this)} onClose={this.onCancelCutoffs.bind(this)} action="YES" CancelCaption="NO" />}
                {this.state.addCutoffDialog && <MessageBoxYesNo title="Create Cutoff"
                    caption="Do you want to create new cutoff for Specific month. Do you wish to proceed?" onAction={this.onAddCutoff.bind(this)} onClose={() => { this.setState({ addCutoffDialog: false }) }} action="YES" CancelCaption="NO" />}
                {this.state.addPayrollCutoffDialog && <MessageBoxYesNo title="Create Cutoff"
                    caption="Do you want to create new cutoff?" onAction={this.onAddPayrollCutoff.bind(this)} onClose={() => this.setState({ addPayrollCutoffDialog: false })} action="YES" CancelCaption="NO" />}
                {this.state.showAddEmployee &&
                 <AddNewEmployeeForm Show={this.state.showAddEmployee} Cutoff={this.state.selected} Action={this.handleAddEmployee.bind(this)} />}
            </div>
        );
    }
}

export default CutoffList
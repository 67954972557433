import React, { Component } from 'react';
import { Modal, Button, Input, Dropdown, TextArea, Checkbox, Form, Header, Grid } from 'semantic-ui-react';
import ErrorMessage from '../../Commons/ErrorMessage';
import { empty_id } from '../../utils';
import { unmountComponentAtNode } from 'react-dom';

const semanticErrorStyle = { 'background-color': '#FFF6F6', 'border-color': '#E0B4B4', 'color': '#9F3A38', '-webkit-box-shadow': 'none', 'box-shadow': 'none' }

class LeaveTypeModule extends Component {

    constructor(props) {
        super(props);
        this.state =
        {
            _id: empty_id,
            Code: "",
            Name: "",
            Description: "",
            Editable: true,
            UseCalendarDays: false,
            showRequiredField: false,
        }
    }

    setContent = (data) => {
        this.setState({
            _id: data._id,
            Code: data.Code,
            Name: data.Name,
            Description: data.Description,
            Editable: data.Editable,
            UseCalendarDays: data.UseCalendarDays,
        });
    }

    getContent = () => {
        return {
            _id: this.state._id,
            Code: this.state.Code,
            Name: this.state.Name,
            Description: this.state.Description,
            Editable: this.state.Editable,
            UseCalendarDays: this.state.UseCalendarDays,
        }
    }

    render() {
        return (
            <Modal size="tiny" open={this.props.open}>
                <Modal.Header>
                    {this.state._id ? "Edit " : "Create New "}Leave Type
                </Modal.Header>
                <Modal.Content>
                    <Grid columns={1} stackable>
                        {this.props.showRequiredField &&
                            <Grid.Column>
                                <ErrorMessage
                                    title={this.props.errTitle}
                                    caption={this.props.errCaption}
                                    open={this.props.showRequiredField}
                                />
                            </Grid.Column>
                        }
                        <Grid.Column>
                            <div>Code</div>
                            <Input fluid value={this.state.Code}
                                onChange={(evt) => { this.setState({ Code: evt.target.value }); }}
                                disabled={this.props.isBusy}
                                error={this.state.Code === "" && this.props.showRequiredField}
                            />
                        </Grid.Column>
                        <Grid.Column>
                            <div>Name</div>
                            <Input fluid value={this.state.Name}
                                onChange={(evt) => { this.setState({ Name: evt.target.value }); }}
                                disabled={this.props.isBusy}
                                error={this.state.Name === "" && this.props.showRequiredField}
                            />
                        </Grid.Column>
                        <Grid.Column>
                            <div>Description</div>
                            <Form>
                                <TextArea
                                    fluid
                                    disabled={this.props.isBusy}
                                    value={this.state.Description}
                                    onChange={(event, data) => { this.setState({ Description: data.value }); }}
                                // style={this.props.showRequiredField && !this.state.Description ? semanticErrorStyle : []} 
                                />
                            </Form>
                        </Grid.Column>
                        <Grid.Column>
                            {/* <div></div> */}
                            <Checkbox disabled={this.props.isBusy} style={{ marginTop: 10 }} checked={this.state.UseCalendarDays}
                                onChange={(event, data) => { this.setState({ UseCalendarDays: data.checked }); }}
                                label='Use Calendar Days?'
                            ></Checkbox>

                        </Grid.Column>
                    </Grid>
                </Modal.Content>
                <Modal.Actions>
                    <Button basic icon='save' content="Save" disabled={this.props.isBusy} loading={this.props.isBusy} onClick={this.props.onSave}></Button>
                    <Button basic icon='cancel' content="Cancel" disabled={this.props.isBusy} onClick={this.props.onCancel}></Button>
                </Modal.Actions>
            </Modal >
        );
    }

}

export default LeaveTypeModule;
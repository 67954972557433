import React, { Component } from 'react';
import { Image } from 'semantic-ui-react';
import 'semantic-ui-css/semantic.min.css';
import { isTaskAvailable } from '../../utils';
import PayrollTransactionBase from '../Commons/PayrollTransactionBase';
import PayrollAdjustmentTypeForm from './Forms/PayrollAdjustmentTypeForm';


const ViewID = "PayrollAdjustmentTypes";
const ViewCaption = "Payroll Adjustment Type";

const userRightsKeys = {
    view: "PAYROLL_ADJUSTMENT_TYPE_VIEW",
    create: "PAYROLL_ADJUSTMENT_TYPE_CREATE",
    edit: "PAYROLL_ADJUSTMENT_TYPE_EDIT",
    delete: "PAYROLL_ADJUSTMENT_TYPE_DELETE",
}

class PayrollAdjustmentTypeList extends Component {

    constructor(props) {
        super(props);
        this.state = {
            modalVisible: false
        }
        this.modalRef = React.createRef();
        this.parentRef = React.createRef();
    }

    createUrls = () => {
        return {
            search: "api/PayrollAdjustmentType/search",
            setDeleted: "api/PayrollAdjustmentType/setDeleted",
            save: "api/PayrollAdjustmentType/save",
            load: "api/PayrollAdjustmentType/load",
            // exportToExcel: "api/PayrollAdjustmentType/exportToExcel",
        };
    }

    toCard = (model) => {
        return {
            _id: model._id,
            name: model.Name,
            description: model.Description,
            deleted: model.Deleted === null ? false : model.Deleted,
            editable: model.IsSystemDefault == false,
            idle: false
        };
    };

    toModel = (card) => {
        return {
            _id: card.id,
            Name: card.name,
            Description: card.description
        };
    };

    createFields = () => {
        // must have only one valueAs: 'header' and 'desciption',
        // 'content' can be none or many
        let fields = [
            { key: 'name', name: 'Name', valueAs: 'header' },
            { key: 'description', name: 'Description', valueAs: 'description' },
            // { key: '', name: '', valueAs: 'content' },
        ];
        return fields;
    }

    validate = (data) => {
        var errTitle = "", errCaption = "";
        // if (this.isNullOrEmpty(data.Name)
        // 	|| this.isNullOrEmpty(data.Description)
        // ) {
        // 	errCaption = "Please fill up all required field/s";
        // 	errTitle = "Invalid";
        // }
        if (this.isNullOrEmpty(data.Name)) {
            errCaption = "'Name' field is required";
            errTitle = "Invalid";
        }
        else {
            this.parentRef.current.setState({ showRequiredField: false });
            return true;
        }
        this.parentRef.current.setState({ showRequiredField: true, errTitle, errCaption });
        return false;
    };

    showModal = (data, visible) => {
        visible && this.modalRef.current.setContent(data);
        this.setState({ modalVisible: visible });
    }

    isNullOrEmpty(val) {
        if (val === null || val === undefined || val === "" || val.length === 0) return true;
        else return false;
    }

    render() {
        let self = this;
        return (
            <PayrollTransactionBase
                ref={this.parentRef}
                viewID={ViewID}
                title={ViewCaption}
                fields={this.createFields()}
                toModel={this.toModel}
                toCard={this.toCard}
                urls={this.createUrls()}
                validate={this.validate}
                props={this.props}
                showModal={this.showModal.bind(this)}
                userRightsKeys={userRightsKeys}
            // hasExportToExcel={true}
            >
                <PayrollAdjustmentTypeForm
                    ref={self.modalRef}
                    open={self.state.modalVisible}
                    errTitle={self.parentRef.current ? self.parentRef.current.state.errTitle : ""}
                    errCaption={self.parentRef.current ? self.parentRef.current.state.errCaption : ""}
                    showRequiredField={self.parentRef.current ? self.parentRef.current.state.showRequiredField : ""}
                    isBusy={self.parentRef.current ? self.parentRef.current.state.isSaving : false}
                    onCancel={() => { 
                        self.setState({ modalVisible: false }, self.parentRef.current.setState({ showRequiredField: false }));
                     }}
                    onSave={() => {
                        self.parentRef.current.saveFromModal(
                            self.modalRef.current.getContent()
                        );
                    }}
                />
            </PayrollTransactionBase>
        );
    }
}

PayrollAdjustmentTypeList.getViewID = () => { return ViewID };
PayrollAdjustmentTypeList.getCaption = () => { return ViewCaption }
PayrollAdjustmentTypeList.getIcon = () => {
    // return (<Image style={{"height":"24px","width":"24px"}} src='Icons/payrolladjustment_small.png' avatar/>); 
    return (<Image style={{ "height": "24px", "width": "24px" }} src='/Icons/Payroll_Adjustment_Type.png' avatar />);
}
PayrollAdjustmentTypeList.getIconSrc = (size) => {
    // return 'Icons/tr_payrolladjustment_' + size + '.png' 
    return '/Icons/Payroll_Adjustment_Type.png'
}
PayrollAdjustmentTypeList.getViewAccessKey = function () {
    var count = Object.keys(userRightsKeys).filter(name => isTaskAvailable(userRightsKeys[name]) === true).length;
    return count > 0 ? true : false;
};
export default PayrollAdjustmentTypeList
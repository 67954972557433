import React, {Component } from 'react';
import {Button,Input,Dropdown,Header,Modal,Loader, Message} from 'semantic-ui-react';
import 'semantic-ui-css/semantic.min.css';
import {ajaxPost} from '../../ajax';
import moment from 'moment';
import {checkDate} from '../../utils';

class CutoffForm extends Component{
    state ={
        Cutoff:{
            _id:"",
            Year:2018,
            Month:1,
            CutoffNumber:1,
            DateStart:moment(new Date()).format("YYYY-MM-DD"),
            DateEnd:moment(new Date()).format("YYYY-MM-DD"),
            CutoffGroupCode:"DEFAULT",
            Status:"PREVIEW",
            CutoffOptions:"{}",
        },
        showConflict:false,
        conflict:[],
        saving:false,
        dialog:false
    }
    constructor(props){
        super(props);    
        var today = new Date();
        this.state.Cutoff.Year = parseInt(today.getFullYear());
        this.state.Cutoff.Month =today.getMonth() + 1;
        this.state.Cutoff = this.props.Cutoff ==null ? this.state.Cutoff : this.props.Cutoff;
        this.state.Cutoff.DateStart = this.state.Cutoff.DateStart !=null ? moment(this.state.Cutoff.DateStart).format("YYYY-MM-DD") : this.state.Cutoff.DateStart;
        this.state.Cutoff.DateEnd = this.state.Cutoff.DateEnd !=null ? moment(this.state.Cutoff.DateEnd).format("YYYY-MM-DD") : this.state.Cutoff.DateEnd;

    }
    handleChange = name => event => {
        var current = this.state.Cutoff;
        current[name] = event.target.value;
        this.setState({ Cutoff: current });
    };
    handleComboChange = (name, val) => {
        var current = this.state.Cutoff;
        current[val.name] = val.value;
        this.setState({ CutoffCutCutoff: current });
    }
    handleSave =(e,data) =>{
       
        this.setState({saving:true});
        var model =this.state.Cutoff;
        if (!checkDate(model.DateStart).Result ||
            !checkDate(model.DateEnd).Result		
            ) {
              this.setState({showConflict:true,conflict:["Incorrect Date Range"]});
            }
            else{

            this.Save(data =>{
                this.setState({saving:false});
                this.setState({showConflict:false});
                if(data.content !=null)
                {
                    this.setState({Cutoff:data.content});
                    this.DialogOnSuccess();
                }
                if(data.errors !=null)
                {
                    this.setState({showConflict:true});
                    this.setState({conflict:data.errors.conflict});
                    this.setState({Cutoff:model});
                }        
        
            },model);
        }
    }
    Save =(callback,model) =>{
    // model.Year = parseInt(model.Year);
    // model.DateStart =moment(model.DateStart);
    // model.DateEnd =moment(model.DateEnd);
        ajaxPost({
            url: 'api/Cutoff/save',
            data: {
            "model":JSON.stringify( model),
        },
        onSuccess: data => {
            callback(data);
        },
        onError: function (err) {
            alert(JSON.stringify(err));
        },
        finally: () => {this.setState({saving:false});}
        })
    }
    DialogOnSuccess =() =>{
        this.setState({ dialogTitle: "Save" });
        this.setState({
            dialogContent: (
                <div>
                    <p>Cutoff has been created successfully</p>
                </div>
            )
        });
        this.setState({dialog: true });
    }
    onCloseDialog = () => {
        this.setState({ dialog: false });
        this.props.onUpdateComplete(this.state.Cutoff);
    }
  
 render(){
    const months = [
        { key: 1, text: 'January', value: 1 },
        { key: 2, text: 'Febuary', value: 2 },
        { key: 3, text: 'March', value:3 },
        { key: 4, text: 'April', value: 4 },
        { key: 5, text: 'May', value: 5 },
        { key: 6, text: 'June', value: 6 },
        { key: 7, text: 'July', value: 7 },
        { key: 8, text: 'August', value: 8 },
        { key: 9, text: 'September', value: 9 },
        { key: 10, text: 'October', value: 10 },
        { key: 11, text: 'November', value: 11 },
        { key: 12, text: 'December', value: 12 },
      ];
    const cutoffs =[
        {key:1,text:'Cutoff 1',value:1},
        {key:1,text:'Cutoff 2',value:2}
    ];
     return(
         <Modal size='tiny' open>
             <Modal.Header>
                    <div style={{overflow:"hidden"}}>
                    <h4 style={{color: "#606060",marginTop: '1%',float:"left"} }>
                    Cutoff Information
                    </h4>
                    </div>
                 </Modal.Header>
                 <Modal.Content>
                 <div>
            <Loader active={this.state.saving} />
               
                    {/* <Menu size="mini" secondary>
                        <Menu.Menu position="right" >
                        <Button content="Save" size="mini" icon="save" color="green"
                            onClick={this.handleSave.bind(this)}/>
                        <Button content="Cancel" size="mini" icon="cancel" color="grey"
                            onClick={this.props.onCloseForm.bind(this)}/>
                        </Menu.Menu>
                    </Menu> */}
                {this.state.showConflict &&
                 <Message negative>
                     <Message.Header>Conflict !</Message.Header>
                     <Message.Content>{this.state.conflict.map(n =>{
                         return(<p>{n}</p>)
                     })}</Message.Content>
                     </Message>}
                <div>
                    <div>
                    <label>Year</label> <br/>
                    <Input fluid placeholder='Year'
                                   value={this.state.Cutoff.Year}
                                   onChange={this.handleChange("Year").bind(this)}  />
                    </div>
               
                <div>    
                <label>Month</label><br/>
                    <Dropdown  fluid selection options={months} placeholder="Month"
                    value={this.state.Cutoff.Month}  name="Month"                              
                    onChange={this.handleComboChange.bind(this)}/>                                                     
                </div>
                <div>   
                <label>Cutoff Number</label>
                    <Dropdown fluid selection options={cutoffs} placeholder="Cutoff Number"
                    value={this.state.Cutoff.CutoffNumber}  name="CutoffNumber"                              
                    onChange={this.handleComboChange.bind(this)}/>                                                     
                </div>
                <div>
                <label>Date Start</label>
                <Input fluid type="date"  placeholder='Date Start'
                        value={this.state.Cutoff.DateStart}
                        onChange={this.handleChange("DateStart").bind(this)} />
                </div>
                <div>
                <label>Date End</label>
                <Input fluid type="date"  placeholder='Date End'
                        value={this.state.Cutoff.DateEnd}
                        error={ !checkDate(this.state.Cutoff.DateEnd).Result }
                        onChange={this.handleChange("DateEnd").bind(this)}             />
                </div>
                </div>
                 {/* Modal Form */}
                 { <Modal size="mini" open={this.state.dialog} onClose={this.close} >
                    <Header content={this.state.dialogTitle} />
                    <Modal.Content>
                        {this.state.dialogContent}
                    </Modal.Content>
                    <Modal.Actions>
                        <Button basic icon="check" content="Yes" 
                        onClick={this.onCloseDialog.bind(this)}/>
                    </Modal.Actions>
                </Modal>} 
        </div>
                     </Modal.Content>
                <Modal.Actions>
                        <Button basic content="Save" size="mini" icon="save"
                            onClick={this.handleSave.bind(this)}/>
                        <Button basic content="Cancel" size="mini" icon="cancel"
                            onClick={this.props.onCloseForm.bind(this)}/>
                </Modal.Actions>
             </Modal>
        
     );
 }
}
export default CutoffForm
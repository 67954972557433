import React from 'react';
import 'semantic-ui-css/semantic.min.css';
import ReportModelBase from '../ReportModelBase';
import { Table } from 'semantic-ui-react';
const moment = require('moment');

class RegularEmployeeReportModel extends ReportModelBase {

    constructor(props){
        super(props);
        this.searchUrl = "api/Reports/createRegularEmployeeReport";
        this.title = "Regular Employee Report";
        this.exportToExcelUrl = "api/Reports/exportRegularEmployeeReportToExcel";
        this.exportToExcelDataUrl = "api/Reports/createRegularEmployeeReportToExcel";
        this.exportToPdfUrl = "api/Reports/createRegularEmployeeReportToPDF";
    }

    toTableRow = (rowData) => {
        return (
            <Table.Row>
                <Table.Cell>{rowData["Employee No"]}</Table.Cell>
                <Table.Cell>{rowData["Employee Name"]}</Table.Cell>
                <Table.Cell>{rowData["Department"]}</Table.Cell>
                <Table.Cell>{rowData["Position"]}</Table.Cell>
                <Table.Cell>{rowData["Job Level"]}</Table.Cell>
                <Table.Cell>{moment(rowData["Date of Hired"]).format('MM/DD/YYYY')}</Table.Cell>
                <Table.Cell>{moment(rowData["Date of Regularization"]).format('MM/DD/YYYY')}</Table.Cell>
            </Table.Row>
        );
    }

    createColumnHeaders = () => {
        return (
            <Table.Row>
                <Table.HeaderCell>Employee No</Table.HeaderCell>
                <Table.HeaderCell>Employee Name</Table.HeaderCell>
                <Table.HeaderCell>Department</Table.HeaderCell>
                <Table.HeaderCell>Position</Table.HeaderCell>
                <Table.HeaderCell>Job Level</Table.HeaderCell>
                <Table.HeaderCell>Date of Hired</Table.HeaderCell>
                <Table.HeaderCell>Date of Regularization</Table.HeaderCell>
            </Table.Row>
        );
    }

}
export default RegularEmployeeReportModel;
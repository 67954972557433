import React from 'react';
import 'semantic-ui-css/semantic.min.css';
import ReportModelBase from '../ReportModelBase';
import { Table } from 'semantic-ui-react';
const moment = require('moment');

class SeparatedEmployeeReportModel extends ReportModelBase {

    constructor(props){
        super(props);
        this.searchUrl = "api/Reports/SeparatedEmployeeReport";
        this.title = "Employee Report Group by Position";
        this.exportToExcelUrl = "api/Reports/ExportSeparatedEmployeeReportToExcel";
        this.exportToPdfUrl = "api/Reports/ExportSeparatedEmployeeReportToPDF";
    }

    toTableRow = (rowData) => {
        return (
            <Table.Row>
                <Table.Cell>{rowData["Employee No"]}</Table.Cell>
                <Table.Cell>{rowData["Name"]}</Table.Cell>
                <Table.Cell>{rowData["Job Title"]}</Table.Cell>
                <Table.Cell>{rowData["Department"]}</Table.Cell>
                <Table.Cell>{rowData["Type of Separation"]}</Table.Cell>
                <Table.Cell>{rowData["Date Of Resignation"]}</Table.Cell>
                <Table.Cell>{rowData["Reason"]}</Table.Cell>
            </Table.Row>
        );
    }

    createColumnHeaders = () => {
        return (
            <Table.Row>
                <Table.HeaderCell>Employee No</Table.HeaderCell>
                <Table.HeaderCell>Name</Table.HeaderCell>
                <Table.HeaderCell>Job Title</Table.HeaderCell>
                <Table.HeaderCell>Department</Table.HeaderCell>
                <Table.HeaderCell>Type of Separation</Table.HeaderCell>
                <Table.HeaderCell>Date Of Resignation</Table.HeaderCell>
                <Table.HeaderCell>Reason</Table.HeaderCell>
            </Table.Row>
        );
    }

}
export default SeparatedEmployeeReportModel;
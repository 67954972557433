import React from 'react';
import 'semantic-ui-css/semantic.min.css';
import ReportModelBase from '../ReportModelBase';
import { Table } from 'semantic-ui-react';
import { numberWithCommas } from '../../../utils';
import moment from 'moment';
// const moment = require('moment');

class PagibigMP2ReportModel extends ReportModelBase {

    constructor(props){
        super(props);
        this.searchUrl = "api/Reports/createPagibigMP2Report";
        this.title = "Pagibig MP2 Report";
        this.exportToExcelUrl = "api/Reports/ExportPagibigMP2ReportToExcel";
        this.exportToExcelDataUrl = "api/Reports/createExportPagibigMP2ReportToExcel";
        this.exportToPdfUrl = "api/Reports/createPagibigMP2ReportToPDF";
    }

    toTableRow = (rowData) => { 
        return (
            <Table.Row>
                <Table.Cell>{moment(rowData.Month).format("MMM YYYY")}</Table.Cell>
                <Table.Cell>{rowData.Company}</Table.Cell>
                <Table.Cell>{rowData.Department}</Table.Cell>
                <Table.Cell>{rowData.Employee}</Table.Cell> 
                <Table.Cell>{numberWithCommas(parseFloat(rowData.Amount).toFixed(2))}</Table.Cell> 
            </Table.Row>
        );
    }

    createColumnHeaders = () => {
        return (
            <Table.Row>
                <Table.HeaderCell>Month</Table.HeaderCell>
                <Table.HeaderCell>Company</Table.HeaderCell>
                <Table.HeaderCell>Department</Table.HeaderCell>
                <Table.HeaderCell>Employee</Table.HeaderCell>
                <Table.HeaderCell>Amount</Table.HeaderCell>  
            </Table.Row>
        );
    }

}

export default PagibigMP2ReportModel;
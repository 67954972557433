import React, { Component } from 'react';
import 'semantic-ui-css/semantic.min.css';
import { Modal, Button, Checkbox, Input } from 'semantic-ui-react';
import Select from 'react-select';
import { ajaxPost } from '../ajax';
import { customStyles } from '../utils';

const moment = require('moment');

function employeeSearchlist(list) {
    var employees = [];
    if (list != null) {
        list.map(x => {
            return (
                employees.push({ label: x.LastName + ", " + x.FirstName + " " + x.MiddleName, value: x._id })
            )
        })
    }
    return employees;
}

// function getFirstDayOfTheMonth(date) {
//     var date = new Date(date);
//     return new Date(date.getFullYear(), date.getMonth(), 1).toLocaleDateString();
// }

// function getLastDayOfTheMonth(date) {
//     var date = new Date(date);
//     return new Date(date.getFullYear(), date.getMonth() + 1, 0).toLocaleDateString();
// }

function positionSearchlist(list) {
    var positions = [];
    if (list != null) {
        list.map(x => {
            return (
                positions.push({ label: x.Name, value: x._id })
            )
        })
    }
    return positions;
}

function groupMap(groupList, groupType, ids) {
     if (groupType == "CITY"){
         ids = ids != null && ids.length > 0 ? ids.map(x => x.CityId) : null;
     }
    if (groupType == "DEPARTMENT")
        ids = ids != null  && ids.length > 0 ? ids.map(x => x.DepartmentID) : null;
    var list = [];
    if (groupList != null) {
        if (groupType != "SALARY_GRADE") {
            groupList.map(x => {
                return (
                    (x.Type == groupType) ?
                        ((ids != null && ids.length > 0 ? ids.includes(x._id) : true) &&
                            list.push({ label: x.Name == "" ? "Name Not Set" : x.Name, value: x._id })) : [])
            })
        } else {
            groupList.map(x => {
                return (
                    (x.Type == groupType) ?
                        list.push({ label: x.Name + " (" + x.OtherDetails.Amount + ")", value: x._id }) : [])
            })
        }

    }
    return list;
}

function onlyUnique(value, index, self) {
    return self.indexOf(value) === index;
}

class GroupFilterModal extends Component {
    dt = moment().format('YYYY-MM-DD');

    constructor(props) {
        super(props);
        this.state = {
            Employeelist: [],
            EmployeeIds: [],

            searcEmployeehQuery: '',
            groupList: [],
            
            positionList: [],
            companies:[],
            locationList: [],
            departmentList:[],
            divisionList :[],
            
            generateContent : props.generateContent!== undefined ?  props.generateContent : "Generate",
            CompanyIds: props.advFilter !== null ? props.advFilter == undefined ? [] : props.advFilter.companyIds : [],
            searcCompanyQuery: '',

            DivisionIds:props.advFilter != null ? props.advFilter == undefined ? [] : props.advFilter.divisionIds : [],
            searchDivisionQuery:'',

            DepartmentIds: props.advFilter !== null ? props.advFilter == undefined ? [] :props.advFilter.departmentIds : [],
            searcDepartmentQuery: '',

            PositionIds: props.advFilter !== null ? props.advFilter == undefined ? [] : props.advFilter.positionIds : [],
            searchPositionQuery: '',

            LocationIds: props.advFilter !== null ? props.advFilter == undefined ? [] : props.advFilter.locationIds : [],
            searcLocationsQuery: '',

            // fromDate: moment(getFirstDayOfTheMonth(this.dt)).format('YYYY-MM-DD'),
            // toDate: moment(getLastDayOfTheMonth(this.dt)).format('YYYY-MM-DD'),
            includeDeletedEmployee: false,
            hideGroups:props.hideGroups != null ? props.hideGroups : [],

            onGenerateCallback: props.advFilter !== null ? props.onGenerateCallback : null,
        }
        this.loadGroups();
    }

    onGenerate = () => {
        var ids = this.state.EmployeeIds;
        // if(ids.length == 0)
        //     ids.push(empty_id);

        if ((this.state.CompanyIds === undefined || this.state.CompanyIds == null) && (this.state.DepartmentIds === undefined || this.state.DepartmentIds == null) && (this.state.LocationIds === undefined || this.state.LocationIds == null) && (this.state.PositionIds === undefined || this.state.PositionIds == null) && (this.state.EmployeeIds === undefined || this.state.EmployeeIds == null))
            ids = [];
        // else
        //     ids = this.state.EmployeeIds != null && this.state.EmployeeIds !== "" ? this.state.EmployeeIds.map(x => x.value) : [];

        var retValueDictionary = {
            //employeeIds: ids,
            companyIds: this.state.CompanyIds,
            divisionIds: this.state.DivisionIds,
            departmentIds: this.state.DepartmentIds,
            locationIds: this.state.LocationIds,
            positionIds: this.state.PositionIds,

        };

        this.props.callback(retValueDictionary, this.state.includeDeletedEmployee, this.state.onGenerateCallback, this.state.fromDate, this.state.toDate)
    }

    loadGroups = () => {
        this.setState({ isDepartmentLoading: true });
        ajaxPost({
            url: "api/Group/search",
            data: {
                "Search": "",
                "includeDeleted": this.state.includeDeletedEmployee,
                "startingIndex": 0,
                "itemCount": 1000
            },
            onSuccess: data => {
                this.setState({ groupList: data.content, isDepartmentLoading: false });
            },
            finally: () => { }
        })
    }

    
    loadEmployees = (value) => {
        var ids = [];
        if (value != null && !(value === "" || value.length === 0)) {
            ids = value.map(x => x.value)
        }

        this.setState({ isEmployeeLoading: true, EmployeeIds: value });
        ajaxPost({
            url: "api/Employee/searchFilteredEmployee",
            data: {
                "Search": "",
                "includeDeleted": this.state.includeDeletedEmployee,
                "startingIndex": 0,
                "itemCount": 10,
                "excludedEmployeeIds": ids,
                "CompanyIDs": this.state.CompanyIds,
                "DepartmentIDs": this.state.DepartmentIds,
                "PositionIDs": this.state.PositionIds,
                "CityIDs": this.state.LocationIds,
            },
            onSuccess: data => {
                this.setState({ Employeelist: data.content, isEmployeeLoading: false });
            },
            finally: () => { }
        })
    }

    onSearch = (text) => {
        clearTimeout(this.employeeSearchTimer);
        this.setState({ search: text });
        this.employeeSearchTimer = setTimeout(this.loadSearchEmployee, 400, text);
    }

    loadSearchEmployee = (str) => {
        var { EmployeeIds } = this.state;
        var ids = (EmployeeIds === "" || EmployeeIds === undefined || EmployeeIds.length == 0) ? [] : EmployeeIds.map(x=> x.value);

        this.setState({ isEmployeeLoading: true });
        ajaxPost({
            url: "api/Employee/searchFilteredEmployee",
            data: {
                "Search": str,
                "includeDeleted": this.state.includeDeletedEmployee,
                "startingIndex": 0,
                "itemCount": 10,
                "excludedEmployeeIds": ids,
                "CompanyIDs": this.state.CompanyIds,
                "DepartmentIDs": this.state.DepartmentIds,
                "PositionIDs": this.state.PositionIds,
                "CityIDs": this.state.LocationIds,
            },
            onSuccess: data => {
                this.setState({ Employeelist: data.content, isEmployeeLoading: false });
            },
            finally: () => { }
        })
    }

    componentWillMount() {
        this.loadGroups();
        // this.loadPositions();
    }

    // handleSearchDepartmentChange = (event) => {
    //     var str = event.target.value;
    //     this.setState({ searchDepartmentQuery: str })
    // }

    // handleSearchLocationChange = (event) => {
    //     var str = event.target.value;
    //     this.setState({ searchLocationQuery: str })
    // }
    // handleSearchPositionChange = (event) => {
    //     var str = event.target.value;
    //     this.setState({ searchPositionQuery: str })
    // }
    handleSearchEmployeeChange = (value) => {
        var ids = [];
        if (value != null && !(value === "" || value.length === 0))
            ids = value.map(x => x.value);

        this.setState({ EmployeeIds: ids, searchQuery: "" });
    }

    handleCompanyChange = (value) => {
        var ids = [];
        if (value != null && !(value === "" || value.length === 0))
            ids = value.map(x => x.value);

        this.setState({ CompanyIds: ids, searcCompanyQuery: '', });
    }
    handleDivisionChange = (value) => {
        var ids = [];
        if (value != null && !(value === "" || value.length === 0))
            ids = value.map(x => x.value);

        this.setState({ DivisionIds: ids, searchDivisionQuery: '' });
    }

    handleDepartmentChange = (value) => {
        var ids = [];
        if (value != null && !(value === "" || value.length === 0))
            ids = value.map(x => x.value);

        this.setState({ DepartmentIds: ids, searcDepartmentQuery: '' });
    }
    
    handleLocationChange = (value) => {
        var ids = [];
        if (value != null && !(value === "" || value.length === 0))
            ids = value.map(x => x.value);

        this.setState({ LocationIds: ids, searcLocationQuery: '' });
    }
    handlePositionChange = (value) => {
        var ids = [];
        if (value != null && !(value === "" || value.length === 0))
            ids = value.map(x => x.value);

        this.setState({ PositionIds: ids, searchPositionQuery: '' });
    }
    // onChkCheckChange = (e) => {
    //     this.setState({ includeDeletedEmployee: !this.state.includeDeletedEmployee }, function () { this.loadEmployees() })
    // }

    canGenerate() {
        return (!this.state.isEmployeeLoading && !this.state.isDepartmentLoading && !this.state.isPositionLoading && !this.state.isLocationLoading)
    }

    handleStartDate = (e) => {
        this.setState({ fromDate: e.target.value })
    }
    handleEndDate = (e) => {
        this.setState({ toDate: e.target.value })
    }

    render() {
        return (
            <Modal open={this.props.open} size="tiny">
                <Modal.Header>
                    <p>Employee Filter</p>
                </Modal.Header>
                <Modal.Content>
                    <div>
                        {!this.state.hideGroups.includes("COMPANY") &&
                        <div>
                            <label>Company</label>
                            <Select placeholder='Company'
                                onChange={this.handleCompanyChange.bind(this)}
                                options={groupMap(this.state.groupList, "COMPANY", null)}
                                styles={customStyles}
                                isMulti
                                isLoading={this.state.isDepartmentLoading}
                                value={groupMap(this.state.groupList, "COMPANY", null).filter(x => this.state.CompanyIds.includes(x.value))} />
                        </div>
                        }
                        <div>
                            <label>Division</label>
                            <Select placeholder='Division'
                                onChange={this.handleDivisionChange.bind(this)}
                                options={groupMap(this.state.groupList, "DIVISION", null)}
                                styles={customStyles}
                                isMulti
                                isLoading={this.state.isDepartmentLoading}
                                value={groupMap(this.state.groupList, "DIVISION", null).filter(x => this.state.DivisionIds.includes(x.value))} />
                        </div>
                        <div>
                            <label>Department</label>
                            <Select placeholder='Departments'
                                onChange={this.handleDepartmentChange.bind(this)}
                                options={groupMap(this.state.groupList, "DEPARTMENT", null)}
                                styles={customStyles}
                                isMulti
                                isLoading={this.state.isDepartmentLoading}
                                value={groupMap(this.state.groupList, "DEPARTMENT", null).filter(x => this.state.DepartmentIds.includes(x.value))} />
                        </div>
                        <div>
                            <label>Position</label>
                            <Select placeholder='Positions'
                                onChange={this.handlePositionChange.bind(this)}
                                options={groupMap(this.state.groupList, "POSITION", null)} 
                                styles={customStyles}
                                isMulti
                                isLoading={this.state.isDepartmentLoading}
                                backspaceRemoves={true}
                                value={groupMap(this.state.groupList, "POSITION", null).filter(x => this.state.PositionIds.includes(x.value))}
                                 />
                        </div>
                        <div>
                            <label>Location</label>
                            <Select placeholder='Locations'
                                onChange={this.handleLocationChange.bind(this)}
                                options={groupMap(this.state.groupList, "CITY", null)}
                                styles={customStyles}
                                isMulti
                                isLoading={this.state.isDepartmentLoading}
                                backspaceRemoves={true}
                                value={groupMap(this.state.groupList, "CITY", null).filter(x => this.state.LocationIds.includes(x.value))} />
                        </div> 
                    </div>
                </Modal.Content>
                <Modal.Actions>
                    <Button basic disabled={!this.canGenerate()} content={this.state.generateContent} onClick={this.onGenerate.bind(this)}></Button>
                    <Button basic content="Cancel" onClick={this.props.onCancel}></Button>
                </Modal.Actions>
            </Modal>
        );
    }

}

export default GroupFilterModal;
import React, { Component } from 'react';
import { Input, Button, Modal, Table, Tab, Checkbox, FormGroup, FormRadio, Form, Message, List, ListItem } from 'semantic-ui-react';
import 'semantic-ui-css/semantic.min.css';
import { isNullOrEmpty } from '../../utils';
import { getDeviceInfoValue } from '../Devices/DeviceTools.js';

var GetBlankDevice = require('../BlankDevice');

class DeviceNameDialog extends Component {
    state = {
        device: "",
        errorObj: {}
    }

    constructor(props) {
        super(props);
        this.state.device = GetBlankDevice();
    }

    handleChange = name => event => {
        var current = this.state.device;
        current[name] = event.target.value;
        this.setState({ device: current });
    };

    onDeviceFunctionChange = (event, data) => {
        var device = this.state.device;
        device["DeviceFunction"] = data.label;
        this.setState({ device });
    }

    handleClose = () => {
        this.props.onClose();
    }
    handleAction = () => {
        let errorObj = {}; 
        let device = Object.assign({}, this.state.device);
        if (isNullOrEmpty(device.DeviceCode)) {
            errorObj.DeviceCode = "Device Code is required";
        }
        if (isNullOrEmpty(device.DeviceName)) {
            errorObj.DeviceName = "Device Name is required";
        }
        this.setState({ errorObj }, () => {
            if (Object.keys(errorObj).length === 0) {
                this.props.onAction(this.state.device);
            }
        });

    }

    render() {
        const { errorObj } = this.state; 
        return (
            <Modal open size='mini'>
                <Modal.Header>
                    Register Unknown Device
                </Modal.Header>
                <Modal.Content>
                    <Table>
                        <Table.Body>
                            <Table.Row>
                                <Table.Cell>
                                    <Input 
                                        fluid 
                                        focus 
                                        placeholder='Device Code...' 
                                        value={this.state.device["DeviceCode"]} 
                                        onChange={this.handleChange("DeviceCode").bind(this)} 
                                        error={Object.keys(errorObj).length > 0 && errorObj.hasOwnProperty('DeviceCode')}
                                    />
                                </Table.Cell>
                            </Table.Row>
                            <Table.Row>
                                <Table.Cell>
                                    <Input 
                                        fluid 
                                        focus 
                                        placeholder='Device Name...' 
                                        value={this.state.device["DeviceName"]} 
                                        onChange={this.handleChange("DeviceName").bind(this)} 
                                        error={Object.keys(errorObj).length > 0 && errorObj.hasOwnProperty('DeviceName')}
                                    />
                                </Table.Cell>
                            </Table.Row>
                            <Table.Row>
                                <Table.Cell>
                                    <Form>
                                        <Form.Field>
                                            <label><b>Function</b></label>
                                            <FormGroup inline={true}>
                                                {/* <label><b>Function</b></label> */}
                                                <FormRadio 
                                                    label="Default" 
                                                    defaultChecked={true} 
                                                    checked={this.state.device["DeviceFunction"] === "Default"} 
                                                    onChange={this.onDeviceFunctionChange}
                                                />
                                                <FormRadio 
                                                    label="IN" 
                                                    defaultChecked={true} 
                                                    checked={this.state.device["DeviceFunction"] === "IN"} 
                                                    onChange={this.onDeviceFunctionChange}
                                                />
                                                <FormRadio 
                                                    label="OUT" 
                                                    defaultChecked={true} 
                                                    checked={this.state.device["DeviceFunction"] === "OUT"} 
                                                    onChange={this.onDeviceFunctionChange}
                                                />
                                            </FormGroup>                                
                                        </Form.Field>
                                    </Form>
                                </Table.Cell>
                            </Table.Row>
                            <Table.Row>
                                <Table.Cell>
                                    <div style={{   marginBottom: '0.3em' }}>{"Face Supported: " + (getDeviceInfoValue(this.props.deviceInfo, "FaceFunOn") == "1" ? "YES" : "NO") } </div>
                                    <div style={{   marginBottom: '0.3em' }}>{"Palm Supported: " + (getDeviceInfoValue(this.props.deviceInfo, "PalmFunOn") == "1" ? "YES" : "NO") } </div>
                                </Table.Cell>
                            </Table.Row>
                        </Table.Body>
                    </Table>
                    {
                        Object.keys(errorObj).length > 0 &&
                        <Message error={true}>
                            <List bulleted={true}>
                                {
                                    Object.keys(errorObj).map((key, index) => {
                                        return (
                                            <ListItem key={index}>{errorObj[key]}</ListItem>
                                        );
                                    })
                                }
                            </List>
                        </Message>
                    }
                </Modal.Content>
                <Modal.Actions>
                    <Button
                        basic
                        icon='check'
                        content={this.props.action}
                        primary
                        loading={this.state.busy}
                        onClick={this.handleAction.bind(this)}>
                    </Button>
                    <Button basic icon='cancel' content='Cancel' onClick={this.handleClose}></Button>
                </Modal.Actions>
            </Modal>
        )
    }
}
export default DeviceNameDialog
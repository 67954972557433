import React, {Component} from 'react';
import { Modal, Button, Checkbox, Input } from 'semantic-ui-react';

var moment = require('moment');

export default class DateRangeModal extends Component {
    constructor(props) {
        super(props);
        this.state = {
            startDate: '',
            endDate: '',
            isStartDateError: false,
            isEndDateError: false
        }
    }
    getParameters = () => {
        return {
            startDate: this.state.startDate,
            endDate: this.state.endDate
        };
    }

    onSave = () => {
        if(this.state.startDate === null || this.state.startDate === '') {
            this.setState({isStartDateError: true});
            return;
        }

        if(this.state.endDate === null || this.state.endDate === '') {
            this.setState({isEndDateError: true});
            return;
        }

        this.props.onSave();
    }

    startDateOnChange = (e) => {
        this.setState({
            isStartDateError: false,
            startDate: e.target.value
        });
    }

    endDateOnChange = (e) => {
        this.setState({
            isEndDateError: false,
            endDate: e.target.value
        });
    }

    render () {
        return (
            <Modal size='mini' open={this.props.open}>
                <Modal.Header> Date Range </Modal.Header>
                <Modal.Content>
                    <div className='w3-row'>
                        <div className='w3-col s4 m4 l4' style={{padding: '10px 0'}}>
                            <label>Start Date</label>
                        </div>
                        <div className='w3-col s7 m7 l7'>
                            <Input type='date' value={this.state.startDate} error={this.state.isStartDateError} onChange={this.startDateOnChange.bind(this)}/>
                        </div>
                    </div>
                    <div className='w3-row' style={{marginTop: '5px'}}>
                        <div className='w3-col s4 m4 l4' style={{padding: '10px 0'}}>
                            <label>End Date</label>
                        </div>
                        <div className='w3-col s7 m7 l7'>
                            <Input type='date' value={this.state.endDate} error={this.state.isEndDateError} onChange={this.endDateOnChange.bind(this)}/>
                        </div>
                    </div>
                </Modal.Content>
                <Modal.Actions>
                    <Button basic content="Generate" onClick={this.onSave}></Button>
                    <Button basic content="Cancel" onClick={this.props.onCancel}></Button>
                </Modal.Actions>
            </Modal>
        )
    }
}
import React, { Component } from 'react';
import { Card, Label, Pagination, Icon, Menu, Button, Input, Image, Divider, List, Modal } from 'semantic-ui-react';
import 'semantic-ui-css/semantic.min.css';
import { ajaxPost } from '../ajax';
import Select from 'react-select';
import MessageBoxOkOnly from './MessageBoxOkOnly';
import { customStyles } from '../utils'

var moment = require('moment');
var { isTaskAvailable, checkDateRange } = require('../utils');

function getFirstDayOfTheMonth(date) {
    var date = new Date(date);
    return new Date(date.getFullYear(), date.getMonth(), 1).toLocaleDateString();
}

function getLastDayOfTheMonth(date) {
    var date = new Date(date);
    return new Date(date.getFullYear(), date.getMonth() + 1, 0).toLocaleDateString();
}

function mapModule(modules) {
    var list = [];
    if (modules !== undefined) {
        modules.map(x => {
            return (
                list.push({ label: x.replace(/([a-z0-9])([A-Z])/g, '$1 $2'), value: x }))
        })
    }
    return list;
}  
class AuditTrail extends Component {
    dt = moment().format('YYYY-MM-DD');
    state = {
        list: [],
        totalCount: 0,
        startDate: moment(getFirstDayOfTheMonth(this.dt)).format('YYYY-MM-DD'),
        endDate: moment(getLastDayOfTheMonth(this.dt)).format('YYYY-MM-DD'),
        prevSDate: moment(getFirstDayOfTheMonth(this.dt)).format('YYYY-MM-DD'),
        prevEDate: moment(getLastDayOfTheMonth(this.dt)).format('YYYY-MM-DD'),
        selectedModule: "",
        loading: false,
        modules: [],
        allowExport: false,
        isError: false,
        errMessage: "",
        isOpenDetails: false,
        auditDetails: {}
    }
    styles = {
        title: {
            color: '#606060',
            marginTop: '1%'
        },
        searchBox: {
            width: '300px'
        },
        tableHeader: {
            backgroundColor: 'gainsboro',
            fontSize: '12px'
        }
    }

    componentWillMount = () => {
        if (this.props.prevState !== undefined) {
            this.state = this.props.prevState;
        }
        this.getModules();
    }

    componentDidMount() {
        this.setState({ allowExport: isTaskAvailable("AUDITTRAIL_EXPORT_EXCEL") });
    }

    componentWillUnmount = () => {
        this.props.unmountCallback(this.state, "AuditTrailModule");
    }

    getList = (callback, countOnly, start, max) => {
        this.setState({ loading: true });
        var sModule = this.state.selectedModule != "" ? this.state.selectedModule.value : this.state.selectedModule;
        ajaxPost({
            url: countOnly ? 'api/AuditTrail/count' : 'api/AuditTrail/list',
            data: {
                Module: sModule,
                StartDate: this.state.startDate,
                EndDate: this.state.endDate,
                StartIndex: start != null ? start : 0,
                MaxData: max != null ? max : 50
            },
            onSuccess: data => {
                // var res = mathdata / 50;
                // var count = parseInt(res);
                if (countOnly) {
                    var res = Math.ceil(data / 50);
                    var count = parseInt(res);
                    this.setState({ pageNumber: count });
                }
                // if (res % count > 0)
                // count += 1;

                // this.setState({ pageNumber: count });

                this.setState({ loading: false });

                callback(data);
            },
            finally: () => { }
        });
    }

    getModules() {
        ajaxPost({
            url: 'api/Database/getCollectionNames',
            onSuccess: data => {
                this.setState({ modules: data })
                // var ops = [];
                // data.map(item => {
                //     ops.push({
                //         ModuleName: item.toUpperCase()
                //     });
                // })
                // callback(null, {
                //     options: ops
                // })
            },
            finally: () => { }
        });
    }

    handleModuleFilterChange = (value) => {
        this.setState({ selectedModule: value == null ? "" : value });
    }

    handleClick = () => {
        var res = checkDateRange(this.state.startDate, this.state.endDate);
        if (!res.Result) {
            this.setState({ errMessage: res.Message, isError: !res.Result, loading: false, startDate: this.state.prevSDate, endDate: this.state.prevEDate })
            return;
        }
        this.setState({ loading: true });
        this.getList(data => {
            this.setState({ totalCount: data });
            this.handlePaginationChange(null, 1);
        }, true)
    }

    onExport = () => {

        let selectedModule = "";
        if(!(this.state.selectedModule === "" || this.state.selectedModule.length !== undefined && this.state.selectedModule.length === 0)) {
            selectedModule = this.state.selectedModule.value;
        }

        this.setState({ exportLoading: true });
        let api = global.ApiHost + "api/AuditTrail/exportToExcel?" +
            "module=" + selectedModule + "&" +
            "startDate=" + this.state.startDate + "&" +
            "endDate=" + this.state.endDate;
        window.location = api;
        this.setState({ exportLoading: false });
    }

    handlePaginationChange = (e, { activePage }) => {
        if (isNaN(activePage) || activePage == 0) {
            activePage = 1;
        }

        this.setState({ activePage })
        const rowsPerPage = 50;

        this.setState({ loading: true });

        this.getList(data => {
            this.setState({ loading: false });
            this.setState({ list: data });
        }, false, (activePage - 1) * rowsPerPage, rowsPerPage);

    }

    handleStartDate = (e) => {
        this.setState({ startDate: e.target.value }, () => {
            var res = checkDateRange(this.state.startDate, this.state.endDate);
            this.setState({ prevSDate: (res.Result ? this.state.startDate : this.state.prevSDate) })
        })

    }
    handleEndDate = (e) => {
        this.setState({ endDate: e.target.value }, () => {
            var res = checkDateRange(this.state.startDate, this.state.endDate);
            this.setState({ prevEDate: (res.Result ? this.state.endDate : this.state.prevEDate) })
        })
    }

    render() {
        var styles = this.styles;
        const { auditDetails } = this.state;
        return (
            <div>
                <div style={styles.title} >
                    <h2>Audit Trail</h2>
                </div>
                <br />
                <Menu secondary size='mini'>
                    <Button floated='left' size='mini' disabled={this.state.exportLoading || !this.state.allowExport} loading={this.state.exportLoading} onClick={this.onExport.bind(this)} ><Icon name='file excel' />Export</Button>
                    {
                        this.state.totalCount > 0 &&
                        <Pagination size='tiny'
                            floated='right'
                            pointing
                            secondary
                            defaultActivePage={1}
                            siblingRange={2}
                            boundaryRange={0}
                            ellipsisItem={null}
                            firstItem={{ content: <Icon name='angle double left' />, icon: true }}
                            lastItem={{ content: <Icon name='angle double right' />, icon: true }}
                            prevItem={{ content: <Icon name='angle left' />, icon: true }}
                            nextItem={{ content: <Icon name='angle right' />, icon: true }}
                            totalPages={this.state.pageNumber}
                            onPageChange={this.handlePaginationChange}
                        />
                    }
                </Menu>
                <div>
                    <Menu stackable size='mini' secondary >
                        <Menu.Menu position='right'>
                            <Input labelPosition='left'>
                                <Label style={{ width: '50px' }}>From</Label>
                                <input
                                    size='small'
                                    value={this.state.startDate}
                                    type="date"
                                    onChange={this.handleStartDate.bind(this)} />
                            </Input>
                            &nbsp;
                            <Input labelPosition='left'>
                                <Label style={{ width: '50px' }}>To</Label>
                                <input
                                    size='small'
                                    value={this.state.endDate}
                                    type="date"
                                    onChange={this.handleEndDate.bind(this)} />
                            </Input>
                            &nbsp;
                            <div style={{ width: '300px' }}>
                                <Select
                                    value={this.state.selectedModule}
                                    onChange={this.handleModuleFilterChange.bind(this)}
                                    options={mapModule(this.state.modules)}
                                    placeholder='Select a module...'
                                    styles={customStyles}
                                    isClearable
                                //style={{minWidth:'50px', maxWidth:'100%', width:'250px'}}
                                />
                            </div>
                            &nbsp;
                            &nbsp;
                            <Button icon='search' size='mini' onClick={this.handleClick.bind(this)}>Search</Button>
                        </Menu.Menu>
                    </Menu>
                </div>
                <Divider></Divider>
                <div style={{ height: window.innerHeight - 250, overflowY: 'auto', overflowX: 'hidden' }}>
                <Card.Group>
                    {
                        this.state.list.map(item => {
                            var formattedIpAddress = item.IPAddress == "::1" ? "SERVER" : item.IPAddress;
                            return (
                                <Card fluid color='olive'
                                    onClick={
                                        () => this.setState({ isOpenDetails: true, auditDetails: item.Details })
                                    }>
                                    <Card.Content>
                                        <Card.Meta>
                                            <br />{moment(item.TimeStamp).format("YYYY-MM-DD h:mm A")}
                                        </Card.Meta>
                                        <Card.Description>
                                            <List>
                                                <List.Item><Label>Reference ID</Label> {item.TransactionID}</List.Item>
                                                <List.Item><Label>Module</Label> {item.Module}</List.Item>
                                                <List.Item><Label>User Name</Label> {item.UserName}</List.Item>
                                                <List.Item><Label>Description</Label> {item.Description}</List.Item>
                                                <List.Item> <Label>IP Address</Label>{formattedIpAddress}</List.Item>
                                            </List>
                                        </Card.Description>
                                    </Card.Content>
                                </Card>
                            )
                        })
                    }
                </Card.Group>
                </div>
                {
                    this.state.isError &&
                    <MessageBoxOkOnly title="Warning"
                        action="OK"
                        onClick={() => this.setState({ isError: false })}
                        caption={this.state.errMessage}
                    />
                }
              
                {this.state.isOpenDetails &&
                    <Modal open>
                        <Modal.Header>
                            Details
                    </Modal.Header>
                        <Modal.Content>
                            {Object.keys(auditDetails).map(key => {
                                if (key === "Deleted") {

                                }
                                else if (key === "FingerprintDetails") {
                                    const fingerDetails = JSON.parse(JSON.stringify(auditDetails[key]));
                                    return (<div>
                                        {Object.keys(fingerDetails).map(subKey => {
                                            const fingerTemplate = JSON.parse(fingerDetails[subKey]);
                                            return (
                                                <div>
                                                    <div>{subKey}</div>
                                                    {fingerTemplate.map(templateKey => {
                                                        return (<div>
                                                            {Object.keys(templateKey).map(templateKeyName => {
                                                                if (templateKeyName === "Data")
                                                                    return (
                                                                        <div>
                                                                            &nbsp; &nbsp; {templateKeyName} : SENSITIVE INFO
                                                                    </div>
                                                                    )

                                                                return (
                                                                    <div>
                                                                        &nbsp; &nbsp; {templateKeyName} : {templateKey[templateKeyName]}
                                                                    </div>
                                                                )
                                                            })}
                                                        </div>)
                                                    })}
                                                </div>
                                            )
                                        })}
                                    </div>)

                                }
                                else if (typeof (auditDetails[key]) === "object" && key !== "Password" && auditDetails[key] !== null && key !== "RequestParameters") {
                                    return (<div>
                                        <div>{key} :</div>
                                        {Object.keys(auditDetails[key]).map(subKey => { 
                                           if(typeof (auditDetails[key][subKey]) === "object"){
                                               return(<div/>)
                                           }else{  
                                            return (
                                                    <div>
                                                     &nbsp; {subKey} : 
                                                     <pre style={{whiteSpace: "pre-wrap"}}> &nbsp;&nbsp;{ auditDetails[key][subKey]}</pre>
                                                 </div>
                                            ) }
                                        })}
                                    </div>)
                                }
                                else if (key !== "Password")
                                    return (<div>
                                        {key} : {auditDetails[key]}
                                    </div>)
                            })}
                        </Modal.Content>
                        <Modal.Actions>
                            <Button onClick={() => this.setState({ isOpenDetails: false })}>Close</Button>
                        </Modal.Actions>
                    </Modal>}
            </div>
        )
    }
}

AuditTrail.getViewID = () => { return "AuditTrailModule" };
AuditTrail.getCaption = () => { return "Audit Trail" };
AuditTrail.getViewAccessKey = () => { return isTaskAvailable("AUDITTRAIL_VIEW") };
AuditTrail.getIconSrc = (size) => {
    return '/Icons/Audit_Trail.png';
}
AuditTrail.getIcon = () => {
    return <Image style={{ "height": "24px", "width": "24px" }} src='/Icons/Audit_Trail.png' avatar />
}

export default AuditTrail
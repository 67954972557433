import React, { Component } from 'react';
import {Visibility,Loader,Header,Button} from 'semantic-ui-react';
import 'semantic-ui-css/semantic.min.css';
import { ajaxPost } from '../ajax';
import {NotificationPopupControl} from './MasterPageControls'

const signalR = require('@microsoft/signalr');

class Notifications extends Component
{
    state = 
    {
        notifications:[],
        startIndex:0,
        loading:false,
        eof:false,
    }

    updateList = () =>
    {
        var max = 20;
		var searchParameter = {
            data:
            {
                StartIndex:this.state.startIndex,
                MaxData:max,
            },
			url: "api/Notification/list",
			onError : (error) => {
			},
			onSuccess : (data, sender) => {
                if( data.length === 0 )
                    this.setState({eof : true});

                this.setState({startIndex : this.state.startIndex + max});
                this.setState({
                    notifications:this.state.notifications.concat(data
                        .map( x => {return {data:x,enabled:false}})), 
                    loading:false
                });
            },
            finally: () => 
            {
            }
		};
        ajaxPost(searchParameter);
        this.setState({loading:true});

    }

    componentWillMount = () =>
    {
        this.updateList();
    }

    styles = 
    {
        row:
        {
            borderBottom:"1px solid #dddfe2",
            padding:"0px",
            cursor:"pointer",
            ":hover":
            {
                backgroundColor:"lightgray",
            }
        },
        desc:
        {
            display:"table-cell"
        },
        avatar:
        {
            display:"table-cell",
            padding:"5px",
        },
        group:
        {
            marginLeft:"20px"
        },
        notificationContainer:
       {
           display:"flex",
           flexDirection : "column",
           height: "85vh",
           margin: "1% 0 0 5%",
           overflowY: "auto"
       }
    }

    onScrollBottom = () =>
    {
        if( this.state.eof )
            return;

        if( this.state.loading === false )
            this.updateList();
    }
    
    render()
    {
        return (
        <div style={{"width":"100%"}}>
            <Header>Notifications</Header>
            <Button icon='refresh' content='refresh' onClick={() => this.setState({startIndex: 0, notifications: [], loading: true}, () => this.updateList())}/>
           <div style={this.styles.notificationContainer}>
                <NotificationPopupControl 
                                notifications={this.state.notifications}
                                notificationItemClick={this.props.parent.notificationItemClick}
                                parent={this}
                                loading={this.state.loading}
                            />
                    <Visibility continuous="true" onTopVisible={this.onScrollBottom.bind(this)}>
                 <div style={{"width":"100%","height":"50px","margin-top":"20px"}}>
                <Loader active={this.state.loading} inline="centered"/>
                </div>
                </Visibility>
           </div>
        </div>
        )
    }
}

Notifications.getViewID = function() {return "NotificationsModule"};
Notifications.getCaption =  function() {return "Notifications"};

export default Notifications;